<h2 mat-dialog-title>Edit Question</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <app-question-template [question]="question" [questionSetControl]="questionSetControl"></app-question-template>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
</mat-dialog-actions>