<header>
    <mat-toolbar color="primary">
        <span><button mat-button [routerLink]="['/']">Abarca</button></span>
        <span class="spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Page options">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/decisiontree']">
                <span>Decision Tree</span>
            </button>
            <button mat-menu-item [routerLink]="['/hierarchy']">
                <span>Client Configuration</span>
            </button>
            <button mat-menu-item [routerLink]="['/questionset']">
                <span>Question Catalog</span>
            </button>
            <button mat-menu-item [routerLink]="['/hierarchy/test']">
                <span>Configuration Validation</span>
            </button>
            <button mat-menu-item [routerLink]="['/login']">
                <span>Logout</span>
            </button>
        </mat-menu>
    </mat-toolbar>
</header>
