import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService {

    constructor() { }

    public sortByMultiple(keys: string[], direction: string) {
        const sortOrder = direction === 'desc' ? -1 : 1;
        return (a: any, b: any) => {
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const comparison = sortOrder * this.compare(a[key], b[key]);
                if (comparison !== 0) {
                    return comparison;
                }
            }
            return 0;
        };
    }

    private compare(a: any, b: any) {
        if (a < b) {
            return -1;
        } else if (a > b) {
            return 1;
        } else {
            return 0;
        }
    }
}
