import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environments';

@Injectable()

export class HttpBaseInterceptor implements HttpInterceptor {
    constructor(
        private translate: TranslateService,
        private oauthService: OAuthService,
    ) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let nReq: HttpRequest<any>;
        const tenantId = this.getTenantId();

        if ((!req.headers.get('Authorization') || !req.headers.get('tenantId')) && (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken())) {
            //console.log("In auth check handling");
            //console.log(req)
            var token = this.oauthService.getAccessToken();
            token = decodeURIComponent(escape(token));
            var headerz = req.headers;
            
            headerz.set('Authorization', 'Bearer  ' + token)
                .set('tenantId', tenantId ? tenantId : '1');
            
            
            nReq = req.clone(
                {
                    headers: headerz
                });
        } else {
            console.log("clones without headers");
            nReq = req.clone();
        }

        if (!environment.production && environment.logHttpRequests) {
            console.log(nReq.url);
            console.log('%c' + nReq.headers.get('Authorization') + ' ', 'background: #222; color: #bada55');
        }
        //console.log("nReq::", JSON.stringify(nReq));
        return next.handle(nReq)
            .pipe(
                tap(event => {
                    if (!environment.production && environment.logHttpRequests) {
                        console.log(event);
                    }
                }),

                catchError(response => {

                    const error: IHttpError = { status: response.status, error: response, errorcode: 'errors.ErrUnknown' } as IHttpError;
                    try {

                        if (response.status === 0 && response.url === null) {
                            error.errorcode = this.translate.instant('errors.ErrServerUnavailable');
                            return throwError(() => error);
                        } else if (response.status === 400) {
                            if (response.error && response.error.errorcode) {
                                error.errorcode = this.translate.instant(`errors.${response.error.errorcode}`);
                            }
                            return throwError(() => error);
                        } else if (response.status === 401) {

                            // TODO: Change on switch to Identity v2 

                            error.errorcode = this.translate.instant('errors.ErrUnauthorized');
                            return throwError(() => error);
                        } else if (response.status === 403) {
                            error.errorcode = this.translate.instant('errors.ErrForbidden');
                            return throwError(() => error);
                        } else if (response.status >= 500) {
                            error.errorcode = this.translate.instant('errors.ErrInternalServer');
                            return throwError(() => error);
                        }

                        return throwError(() => error);
                    } catch (ex) {

                        // Log Ex 

                        error.errorcode = this.translate.instant('errors.errorsErrUnexpected');
                        return throwError(() => error);
                    }
                })
            );
    }

    private getTenantId() {
        const cname = 'darwin-tenant=';
        const ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }

            if (c.indexOf(cname) === 0) {
                const tenantObject = JSON.parse(decodeURIComponent(c.substring(cname.length, c.length)));
                return tenantObject.sessionValue;
            }
        }
        return null;
    }
}

export interface IHttpError {
    error: any;
    errorcode: string;
    status: any;
}

export function isIHttpError(object: any): object is IHttpError {
    const ihttperror = object as IHttpError;
    return ihttperror.errorcode !== undefined && ihttperror.errorcode !== undefined && ihttperror.status !== undefined;
} 
