import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { HierarchyService } from '../hierarchy.service';
import { Hierarchy } from '../../classes/hierarchy';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { Client } from '../../classes/client';
import { DecisionTreeRXDM } from '../../classes/decisiontree';
import { SortService } from '../../shared/services/sort.service';


@Component({
    selector: 'app-hierarchy-test',
    standalone: true,
    imports: [MatDatepickerModule, MatNativeDateModule, MatPaginatorModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatCheckboxModule, MatButtonModule, MatDividerModule, CommonModule, NgIf, NgFor, FormsModule, ReactiveFormsModule, RouterLink],
  templateUrl: './hierarchy-test.component.html',
  styleUrls: ['./hierarchy-test.component.scss']
})
export class HierarchyTestComponent implements AfterViewInit, OnInit {
    title = 'Client Configuration Validation';

    displayedColumns: string[] = ['priority', 'hierarchy', 'tree', 'start', 'end'];
    dataSource: MatTableDataSource<DecisionTreeRXDM> = new MatTableDataSource<DecisionTreeRXDM>([]);

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;

    user!: User;
    clientsList: Client[] = [];
    selectedClient!: Client;
    rxdmTreeList: DecisionTreeRXDM[] = [];
    noResults: boolean = true;
    firstLoad: boolean = true;

    testForm = this.fb.group({
        client: ['', Validators.required],
        pcn: [''],
        plan: [''],
        group: [''],
        type: [''],
        subtype: [''],
        drugqualifier: ['GPI'],
        drug: [''],
        requestDate: [new Date()],
    });

    constructor(public hierarchyService: HierarchyService,
        /*private modalService: NgbModal,*/
        private router: Router, private authService: AuthService, private fb: FormBuilder, private sortService: SortService)
    {
        this.user = this.authService.getUserLoginInfo();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.populateClients();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    populateClients() {
        this.clientsList = this.user.clients!;
        this.onSelectClient();
    }

    onSelectClient() {
        if (this.clientsList === undefined) {
            console.error("Client List Undefined");
            return;
        }
        this.selectedClient = this.clientsList.find(x => x.clientID?.toLowerCase() === this.selectedClient.clientID!.toLowerCase())!;
        //set the users selected client
        this.authService.setClient(this.selectedClient.clientID!);
    }

    openEdit(dtID: string) {
        this.router.navigate(['decisiontree/editquestions'], { queryParams: { dt: dtID } });
    }

    runTest() {
        if (this.testForm.valid) {
            this.firstLoad = false;
            const date = this.testForm.value.requestDate!;
            const submitDate = date.toISOString();
            console.log(submitDate);
            this.hierarchyService.getRXDMDecisionTree(
                this.testForm.value.client!, this.testForm.value.pcn ?? ""
                , this.testForm.value.plan ?? "", this.testForm.value.group ?? "", this.testForm.value.type ?? ""
                , this.testForm.value.subtype ?? "", this.testForm.value.drugqualifier!, this.testForm.value.drug ?? ""
                , submitDate ?? ""
            ).subscribe(resp => {
                if (resp.body) {
                    this.rxdmTreeList = this.hierarchyService.mapRXDMDecisionTree(resp);
                    console.log(this.rxdmTreeList);
                    if (this.rxdmTreeList.length >= 1) {
                        this.noResults = false;
                        this.dataSource = new MatTableDataSource(this.rxdmTreeList);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                    } else {
                        this.noResults = true;
                    }
                }
            });
        }
    }
}
