import { query } from '@angular/animations';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '../classes/client';
import { DecisionTree, DecisionTreeHeader, DecisionTreeVersionInfo } from '../classes/decisiontree';
import { CaseSettings, DecisionTreeSettings, DecisionTreeSettingsDetail, DeterminationSettings, DrugDisplay, DrugSettings, HierarchySettings } from '../classes/decisiontreesettings';
import { DT, TreeOnly } from '../classes/questionset';
import { QuestionSetData } from '../classes/questionsetdashboard';
import { DrugType } from '../classes/solr-drug';
import { ApiPaths } from '../shared/environments/routes';
import { environment } from '../shared/environments/environments';
import { AuthService } from '../shared/auth/auth.service';
import { DecisionTreeOverride, DecisionTreeOverrideRequest } from '../classes/overrides';

@Injectable({
  providedIn: 'root'
})
export class DecisionTreeService {
    

    constructor(private http: HttpClient,
        private authService: AuthService
    ) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getAllDecisionTreeFromService(clientID: string, pcns: number[] = [], caseTypes: string[] = [], determinations: number[] = []): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);
        if (pcns.length > 0) {
            pcns.forEach(function (p) {
                queryParams = queryParams.append('PCNList', p);
            }); 
        }
        if (caseTypes.length > 0) {
            caseTypes.forEach(function (c) {
                queryParams = queryParams.append('CaseTypeList', c);
            }); 
        }
        if (determinations.length > 0) {
            determinations.forEach(function (d) {
                queryParams = queryParams.append('DeterminationLevelList', d);
            }); 
        }
        
        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeTableViewList, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public getDecisionTreeByID(decisionTreeID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeByID, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public getDecisionTreeSettingsByID(decisionHeaderTreeID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeHeaderID', decisionHeaderTreeID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeSettingsByID, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public getDecisionTreeHeaderByID(decisionTreeHeaderID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeHeaderID', decisionTreeHeaderID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeHeaderByID, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
    public getDecisionTreeOverridesHeaderByID(decisionTreeHeaderID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeHeaderID', decisionTreeHeaderID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeOverrides, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
    public getHierarchyTiesByID(decisionTreeID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetHierarchyTiesToDecisionTreeByID, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public getQuestionSetsByDecisionTreeID(decisionTreeID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetQuestionSetByDecisionTreeID, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public getCompletedDecisionTree(decisionTreeID: number, applicationID: number) {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);
        queryParams = queryParams.append('ApplicationID', applicationID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetCompleteDecisionTreeQuesitonSet, { headers: httpHeaders, params: queryParams, observe: 'response' });
        //return this.http.post(Environment.baseUrl + ApiPaths.AddDecisionTree, body, { headers: httpHeaders, observe: 'response' })
        //    .pipe(map((response: any) => response));
    }
    public addDecisionTree(applicationUserID: number, clientID: string, decisionTreeName: string, decisionTreeDescription: string, comments: string, startDate: string, endDate: string, questions: unknown[]): Observable<any> {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});

        //populate the orderedQuestionSet
        const orderedQuestionSet: { questionSetID: number, initialOrder: number }[] = [];

        questions.forEach((id: unknown, index: number) => {
            orderedQuestionSet.push({
                questionSetID: (id as { questionSetID: unknown }).questionSetID as number,
                initialOrder: index + 1
            });
        });

        const body = {
            applicationUserID: applicationUserID,
            clientid: clientID,
            decisionTreeName: decisionTreeName,
            decisionTreeDescription: decisionTreeDescription,
            comments: comments,
            startDate: startDate,
            endDate: endDate,
            orderedQuestionSet: orderedQuestionSet
        };

        return this.http.post(environment.baseUrl + ApiPaths.AddDecisionTree, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public deleteUnapprovedTree(decisionTreeID: number, applicationUserID: number) {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);
        queryParams = queryParams.append('ApplicationUserID', applicationUserID);
        
        return this.http.get<any>(
            environment.baseUrl + ApiPaths.DeleteUnapprovedTree, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }


    public addGuidedDecisionTree(dt: DecisionTreeSettings) {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});
        dt.hierarchy.forEach(fef =>
            fef.cases.forEach(fec => { if (fec.caseSubType == 'All') fec.caseSubType = ''; })
        )
        const body = dt;
        return this.http.post(environment.baseUrl + ApiPaths.AddGuidedDecisionTree, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public updateGuidedDecisionTree(dt: DecisionTreeSettings) {
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });

        dt.hierarchy.forEach(fef => {
            fef.cases.forEach(fec => { if (fec.caseSubType == 'All') fec.caseSubType = ''; })
        });

        const body = dt;
        return this.http.post(environment.baseUrl + ApiPaths.UpdateGuidedDecisionTree, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public saveDecisionTree(decisionTreeID: number, applicationUserID: number, comments: string, startDate: string, endDate: string, questions: unknown[]): Observable<any> {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});

        //populate the orderedQuestionSet
        const orderedQuestionSet: { questionSetID: number, initialOrder: number }[] = [];

        questions.forEach((id: unknown, index: number) => {
            orderedQuestionSet.push({
                questionSetID: parseInt((id as { questionSetID: unknown }).questionSetID as string),
                initialOrder: index + 1
            });
        });

        const body = {
            decisionTreeID: decisionTreeID,
            applicationUserID: applicationUserID,
            comments: comments,
            startDate: startDate,
            endDate: endDate,
            orderedQuestionSet: orderedQuestionSet
        };

        return this.http.post(environment.baseUrl + ApiPaths.SaveDecisionTree, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public submitForApproval(decisionTreeID: number, applicationUserID: number, comments: string, startDate: string, endDate: string, questionList: unknown[]): Observable<any> {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});
        const body = {
            decisionTreeID: decisionTreeID,
            applicationUserID: applicationUserID,
            comments: comments,
            startDate: startDate,
            endDate: endDate,
            orderedQuestionSet: questionList
        };
        return this.http.post(environment.baseUrl + ApiPaths.SubmitForApproval, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }
    public addDecisionTreeForEdit(decisionTreeID: number, applicationUserID: number,
        majorVersion: number, minorVersion: number,
        startDate: Date, endDate: Date
    ): Observable<any> {
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });
        const body = {
            decisionTreeID: decisionTreeID,
            applicationUserID: applicationUserID,
            majorVersion: majorVersion,
            minorVersion: minorVersion,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post(environment.baseUrl + ApiPaths.addDecisionTreeForEdit, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public unsetApprovalRequest(decisionTreeID: number, applicationUserID: number, comments: string, startDate: Date, endDate: Date, questionList: unknown[]): Observable<any> {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});
        const body = {
            decisionTreeID: decisionTreeID,
            applicationUserID: applicationUserID,
            comments: comments,
            startDate: startDate,
            endDate: endDate,
            orderedQuestionSet: questionList
        };
        return this.http.post(environment.baseUrl + ApiPaths.UnsetApprovalRequest, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }
    public approveDecisionTree(decisionTreeID: number, applicationUserID: number, startDate: string, endDate: string, comments:string) {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});
        
        const body = {
            decisionTreeID: decisionTreeID.toString(),
            applicationUserID: applicationUserID,
            startDate: startDate,
            endDate: endDate,
            comments: comments
        };

        return this.http.post(environment.baseUrl + ApiPaths.ApproveDecisionTree, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public exportTable(clientID: string) {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);
        return this.http.get(
            environment.baseUrl + ApiPaths.ExportDecisionTreeTableViewList,
            {
                headers: this.getHeaders(),
                params: queryParams,
                observe: 'events',
                responseType: 'blob'
            }
        );
    }

    public exportDecisionTreeToJSONByID(decisionTreeID: number) {
        var httpHeaders = this.getHeaders();
        var url = environment.baseUrl + ApiPaths.GetDecisionTreeJsonByID;
        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);

        return this.http.get(
            url,
            {
                headers: httpHeaders,
                params: queryParams,
                observe: 'events',
                responseType: 'blob'
            }
        );
    }

    public exportDecisionTreeToPDFByID(decisionTreeID: number, applicationID: number) {
        var url = environment.baseUrl + ApiPaths.GetDecisionTreePDFByID;
        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeID', decisionTreeID);
        queryParams = queryParams.append('ApplicationID', applicationID);

        return this.http.post(url, '', {
            headers: this.getHeaders(),
            params: queryParams,
            observe: 'events',
            responseType: 'blob'
        }).pipe(map((response: any) => response));
    }

    public mapDecisionTreeSettingsFromDecisionTree(response: HttpResponse<any>) {
        const decisionTreeSettings = new DecisionTreeSettings();

        decisionTreeSettings.applicationUserID = response.body.applicationUserID;

        const client = new Client();
        client.clientID = response.body.client.clientID;
        client.clientName = response.body.client.clientName;
        decisionTreeSettings.client = client;

        const decisionTree = new DecisionTreeSettingsDetail();
        decisionTree.decisionTreeHeaderId = response.body.decisionTree.decisionTreeHeaderId;
        decisionTree.decisionTreeName = response.body.decisionTree.decisionTreeName.trim();
        decisionTree.decisionTreeDescription = response.body.decisionTree.decisionTreeDescription;
        decisionTree.decisionTreeID = response.body.decisionTree.decisionTreeID;
        decisionTree.startDate = new Date(response.body.decisionTree.startDate);
        decisionTree.endDate = new Date(response.body.decisionTree.endDate);
        decisionTreeSettings.decisionTree = decisionTree;

        for (const hierarchyData of response.body.hierarchy) {
            const hierarchySettings = new HierarchySettings();
            hierarchySettings.hierarchyId = hierarchyData.hierarchyId;
            hierarchySettings.pcnReferenceID = hierarchyData.pcnReferenceID;
            hierarchySettings.pcn = hierarchyData.pcn;
            hierarchySettings.pcnDescription = hierarchyData.pcnDescription;
            hierarchySettings.planReferenceID = hierarchyData.planReferenceID;
            hierarchySettings.benefitPlanId = hierarchyData.benefitPlanId;
            hierarchySettings.plan = hierarchyData.plan;
            hierarchySettings.benefitGroupId = hierarchyData.benefitGroupId;
            hierarchySettings.group = hierarchyData.group;
            hierarchySettings.groupReferenceID = hierarchyData.groupReferenceID;
            hierarchySettings.isSelected = hierarchyData.isSelected;
            hierarchySettings.settingsStartDate = hierarchyData.settingsStartDate;
            hierarchySettings.settingsEndDate = hierarchyData.settingsEndDate;
            hierarchySettings.medicarePart = hierarchyData.medicarePart;

            for (const drugData of hierarchyData.drugs) {
                const drugSettings = new DrugSettings();
                drugSettings.drugDisplay = new DrugDisplay();
                drugSettings.drugIDQualifier = drugData.drugIDQualifier;
                drugSettings.drugID = drugData.drugID;
                drugSettings.startDate = drugData.startDate;
                drugSettings.endDate = drugData.endDate;
                drugSettings.multiSourceCodes = drugData.multiSourceCodes;

                if (drugData.drugDisplay) {
                    drugSettings.drugDisplay.drugDesc = JSON.parse(drugData.drugDisplay).drugDesc;
                    drugSettings.drugDisplay.drugGPI = JSON.parse(drugData.drugDisplay).drugGPI;
                    drugSettings.drugDisplay.drugDisplayGPI = JSON.parse(drugData.drugDisplay).drugDisplayGPI;
                    drugSettings.drugDisplay.drugNDC = JSON.parse(drugData.drugDisplay).drugNDC;

                    if (drugSettings.drugDisplay.drugGPI) {
                        drugSettings.drugDisplay.type = DrugType.GPI;
                    } else if (drugSettings.drugDisplay.drugNDC){
                        drugSettings.drugDisplay.type = DrugType.NDC;
                    }
                } else {
                    drugSettings.drugDisplay.drugDesc = '';
                    drugSettings.drugDisplay.drugGPI = '';
                }
                
                hierarchySettings.drugs.push(drugSettings);
            }

            for (const caseData of hierarchyData.cases) {
                const caseSettings = new CaseSettings();
                if (caseData.caseType != '') { //&& caseData.caseSubType != ''
                    caseSettings.caseType = caseData.caseType;
                    caseSettings.caseSubType = caseData.caseSubType == "All" ? "" : caseData.caseSubType;
                    hierarchySettings.cases.push(caseSettings);
                }
            }

            for (const determinationData of hierarchyData.determination) {
                const determinationSettings = new DeterminationSettings();
                determinationSettings.determinationLevel = determinationData.determinationLevel;
                hierarchySettings.determination.push(determinationSettings);
            }

            decisionTreeSettings.hierarchy.push(hierarchySettings);
        }

        return decisionTreeSettings;
    }

    public mapQuestionSetDataToDecisionTreeResponse(response: HttpResponse<any>) {
        const questions: QuestionSetData[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const q = new QuestionSetData();
            if (v instanceof Object) {
                q.decisionTreeID = parseInt(v['decisionTreeID' as keyof typeof v].toString());
                q.decisionTreeToQuestionSetID = parseInt(v['decisionTreeToQuestionSetID' as keyof typeof v].toString());
                q.questionSetHeaderID = parseInt(v['questionSetHeaderID' as keyof typeof v].toString());
                q.questionSetID = parseInt(v['questionSetID' as keyof typeof v].toString());
                q.initialOrder = parseInt(v['initialOrder' as keyof typeof v].toString());
                q.questionSetTypeID = parseInt(v['questionSetTypeID' as keyof typeof v].toString());
                q.questionSetName = v['questionSetName' as keyof typeof v].toString();
                q.questionSetDescription = v['questionSetDescription' as keyof typeof v].toString();
                q.questionSetData = v['questionSetData' as keyof typeof v].toString();
                q.comments = (v['comments' as keyof typeof v] != null ? v['comments' as keyof typeof v].toString() : "");
                q.clientID = v['clientID' as keyof typeof v].toString();
                q.majorVersion = parseInt(v['majorVersion' as keyof typeof v].toString());
                q.minorVersion = parseInt(v['minorVersion' as keyof typeof v].toString());
                q.questionSetStatus = parseInt(v['questionSetStatus' as keyof typeof v].toString());
                var al:any = v["applicationsEnabledList" as keyof typeof v];
                q.applicationsEnabledList = al;
                var qsl: any = v["questionSetLanguage" as keyof typeof v];
                q.questionSetLanguage = qsl;
                questions.push(q);
            }
        }
        return questions;
    }

    public mapSingleDecisionTreeHeaderResponse(response: HttpResponse<any>) {
        const decisiontreeheader: DecisionTreeHeader = new DecisionTreeHeader();

        decisiontreeheader.decisionTreeHeaderId = parseInt(response.body['decisionTreeHeaderID'].toString());
        decisiontreeheader.clientID = response.body['clientID'].toString();
        decisiontreeheader.decisionTreeName = response.body['decisionTreeName'].toString();
        decisiontreeheader.decisionTreeDescription = response.body['decisionTreeDescription'].toString();
        //console.log("decisiontreeheader", decisiontreeheader)
        return decisiontreeheader;
    }

    public mapSingleDecisionTreeResponse(response: HttpResponse<any>) {
        const decisiontree: DecisionTree = new DecisionTree();

        decisiontree.decisionTreeID = parseInt(response.body['decisionTreeID'].toString());
        decisiontree.decisionTreeHeaderId = parseInt(response.body['decisionTreeHeader'].toString());
        decisiontree.majorVersion = parseInt(response.body['majorVersion'].toString());
        decisiontree.minorVersion = parseInt(response.body['minorVersion'].toString());
        decisiontree.decisionTreeStatus = parseInt(response.body['decisionTreeStatus'].toString());
        decisiontree.comments = response.body['comments'].toString();
        decisiontree.startDate = response.body['startDate'].toString();
        decisiontree.endDate = response.body['endDate'].toString();

        return decisiontree;
    }
    public mapDecisionTreeResponseVersionMerge(response: HttpResponse<any>) {
        const decisiontrees: DecisionTree[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            var dtv = new DecisionTreeVersionInfo();
            var d = new DecisionTree();
            if (v instanceof Object) {
                let decisionTreeHeaderId = parseInt(v['decisionTreeHeaderId' as keyof typeof v].toString());
                //if (decisionTreeHeaderId == 27) { console.log("version", v)}
                let fdt: DecisionTree[] = decisiontrees.filter(i => { return i.decisionTreeHeaderId == decisionTreeHeaderId; });
                dtv.decisionTreeID = parseInt(v['decisionTreeID' as keyof typeof v].toString());
                dtv.majorVersion = parseInt(v['majorVersion' as keyof typeof v].toString());
                dtv.minorVersion = parseInt(v['minorVersion' as keyof typeof v].toString());
                dtv.decisionTreeStatus = parseInt(v['decisionTreeStatus' as keyof typeof v].toString());
                //dtv.determinationLevel = parseInt(v['determinationLevel' as keyof typeof v].toString());
                dtv.ties = parseInt(v['ties' as keyof typeof v].toString());
                //if (dtv.decisionTreeStatus == 3) {
                    dtv.startDate = new Date(v['startDate' as keyof typeof v].toString());
                    dtv.endDate = new Date(v['endDate' as keyof typeof v].toString());
                //} else {
                //    dtv.startDate = undefined;
                //    dtv.endDate = undefined;
                //}
                if (fdt != null && fdt.length > 0) {
                    d = fdt[0];
                    d.versionItems!.push(dtv);
                    if (d.decisionTreeStatus! > dtv.decisionTreeStatus)
                        d.decisionTreeStatus = dtv.decisionTreeStatus;
                    if (d.decisionTreeID! < dtv.decisionTreeID)
                        d.decisionTreeID = dtv.decisionTreeID;
                    if (d.startDate! > dtv.startDate)
                        d.startDate = dtv.startDate;
                    if (d.endDate! < dtv.endDate)
                        d.endDate = dtv.endDate;
                } else {
                    d.decisionTreeHeaderId = decisionTreeHeaderId;
                    d.decisionTreeName = v['decisionTreeName' as keyof typeof v].toString().trim();
                    d.decisionTreeDescription = v['decisionTreeDescription' as keyof typeof v].toString();
                    d.clientID = v['clientID' as keyof typeof v].toString();
                    d.decisionTreeID = parseInt(v['decisionTreeID' as keyof typeof v].toString());
                    d.decisionTreeStatus = parseInt(v['decisionTreeStatus' as keyof typeof v].toString());
                    //d.determinationLevel = parseInt(v['determinationLevel' as keyof typeof v].toString());
                    d.ties = parseInt(v['ties' as keyof typeof v].toString());
                    //if (d.decisionTreeStatus == 3) {
                        d.startDate = new Date(v['startDate' as keyof typeof v].toString());
                        d.endDate = new Date(v['endDate' as keyof typeof v].toString());
                    //} else {
                    //    d.startDate = undefined;
                    //    d.endDate = undefined; 
                    //}
                    d.versionItems = [];
                    d.versionItems.push(dtv)
                    decisiontrees.push(d);
                }
            }
        }
        return decisiontrees;
    }
    public mapDecisionTreeResponse(response: HttpResponse<any>) {
        const decisiontrees: DecisionTree[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const d = new DecisionTree();
            if (v instanceof Object) {
                d.decisionTreeHeaderId = parseInt(v['decisionTreeHeaderId' as keyof typeof v].toString());
                d.decisionTreeName = v['decisionTreeName' as keyof typeof v].toString().trim();
                d.decisionTreeDescription = v['decisionTreeDescription' as keyof typeof v].toString();
                d.clientID = v['clientID' as keyof typeof v].toString();
                d.decisionTreeID = parseInt(v['decisionTreeID' as keyof typeof v].toString());
                d.majorVersion = parseInt(v['majorVersion' as keyof typeof v].toString());
                d.minorVersion = parseInt(v['minorVersion' as keyof typeof v].toString());
                //d.determinationLevel = parseInt(v['determinationLevel' as keyof typeof v].toString());
                d.ties = parseInt(v['ties' as keyof typeof v].toString());
                d.decisionTreeStatus = parseInt(v['decisionTreeStatus' as keyof typeof v].toString());
                d.startDate = new Date(v['startDate' as keyof typeof v].toString());
                d.endDate = new Date(v['endDate' as keyof typeof v].toString());
                decisiontrees.push(d);
            }
        }
        return decisiontrees;
    }


    public importDecisionTree(DecisionTreeName: string, clientId: string,
        tree: DT, treeOnly: TreeOnly[], filename: string): Observable<HttpResponse<any>> {
        tree.treeOnly = treeOnly;

        var dts = JSON.stringify(tree);
        var url = environment.baseUrl + ApiPaths.ImportDecisionTree;
        const body = {
            'ClientID': clientId,
            'DecisionTreeName': DecisionTreeName,
            'dts': dts,
            'filename': filename,
            'UserID': this.authService.user.id!
        };
        var headers = new HttpHeaders({'Content-Type': "application/json"});

        return this.http.post(url, body, { headers: headers, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public applyHeader(dt: DecisionTree, dtH: DecisionTreeHeader) {

        dt.decisionTreeHeaderId = dtH.decisionTreeHeaderId;
        dt.decisionTreeName = dtH.decisionTreeName;
        dt.decisionTreeDescription = dtH.decisionTreeDescription;
        dt.clientID = dtH.clientID;

        return dt;
    }
    public getDecisionTreeHistoryByDecsionTreeHeaderID(decisionTreeHeaderID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeHeaderID', decisionTreeHeaderID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeHistory, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
    public getDecisionTreeVersionHistoryByDecsionTreeHeaderID(decisionTreeHeaderID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('DecisionTreeHeaderID', decisionTreeHeaderID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeVersionHistory, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
    
    
    public DeactivateAdditionalComment(additionalCommentID: number): Observable<any> {
        var headers = new HttpHeaders({ 'Content-Type': "application/json" });
        
        const body = {
            'additionalCommentID': additionalCommentID
        }
        return this.http.post<any>(
            environment.baseUrl + ApiPaths.DeactivateAdditionalComment, body, { headers: headers, observe: 'response' });
    }
    public UpdateDecisionTreeOverrides(overrideRequest: DecisionTreeOverrideRequest): Observable<any> {
        var headers = new HttpHeaders({ 'Content-Type': "application/json" });
        const body = overrideRequest;
        return this.http.post<DecisionTreeOverrideRequest>(
            environment.baseUrl + ApiPaths.UpdateDecisionTreeOverrides, body, { headers: headers, observe: 'response' });
    }
    

    public GetAdditionComments(decisionTreeHeaderID: number, additionalCommentTypeID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams
            .append('ID', decisionTreeHeaderID)
            .append('additionalCommentTypeID', additionalCommentTypeID)
            ;

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetAdditionComments, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
    fetchRXPOverrideResults(): Observable<any> {
        const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

        const url = `${environment.rxpApiUrl}${ApiPaths.OverrideSearchRXP}`;

        return this.http.get<any>(url, { headers: httpHeaders, observe: 'response' });
    }

}
