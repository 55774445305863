<form>
    <mat-form-field class="medication-chip-list" style="width:100%" appearance="outline" color="accent">
        <mat-label>Medications</mat-label>
        <mat-chip-grid #chipGrid aria-label="medication selection">
            <!--@for (medication of medications; track medication) {-->
            <section *ngFor="let medication of medications">
                <mat-chip-row (removed)="remove(medication)"
                              [title]="medication.Qualifier+': '+ medication.ID">
                    {{medication.Desc}}
                    <!--{{medication.ID}} {{medication.Qualifier}}-->
                    <button matChipRemove [attr.aria-label]="'remove ' + medication">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-chip-row>
            </section>
            <!--}-->
        </mat-chip-grid>


        <input placeholder="New medication..." #medicationInput [formControl]="medicationCtrl"
               [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="add($event)" 
               (focus)="focusInput()"
               required />
        <!--<mat-hint style="color:#B03000">Please select drugs</mat-hint>-->
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <!--@for (medication of filteredmedications | async; track medication) {-->

            <mat-option *ngFor="let medication of filteredmedications | async"
                        (click)="autocompleteTrigger.openPanel()"
                        [value]="medication.value">
                <!---->
                {{medication.Desc}}
                {{medication.ID}}
                {{medication.Qualifier}}
            </mat-option>

            <!--}-->
        </mat-autocomplete>
    </mat-form-field>
</form>