export class GroupSimple {
    id: number | undefined;
    desc: string | undefined;
}

export class Group {
    groupReferenceID: number | undefined;
    benefitGroupId: string | undefined;
    groupName: string | undefined;
    pcn: string | undefined;
    benefitPlanId: string | undefined;
}