
<div class="override-error-comments" *ngIf="errorMessages.length > 0">
    <div class="header">
        <span style="flex: 24px 0 0;" class="material-icons">info</span>
        <span style="flex: 30px 1 1"> &nbsp; Override Errors</span>
        <span style="flex: 24px 0 0" class="material-icons midown" onclick="document.querySelector('.override-error-comments').classList.remove('hide')">arrow_drop_up</span>
        <span style="flex: 24px 0 0" class="material-icons miup" onclick="document.querySelector('.override-error-comments').classList.add('hide')">arrow_drop_down</span>
    </div>
    <div class="content" style="position:absolute">
        <div *ngFor="let i of errorMessages">
            <div style="display:flex;align-items:center; padding:2px;margin:5px;" (click)="scrollToOverride(i.index)">
                <span style="flex: 24px 0 0;" class="material-icons">circle</span>
                <span style="flex: 100px 1 1;"> &nbsp; {{i.message}}</span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="errorMessages.length > 0" style="height:45px;"></div>
<div *ngFor="let oli of decisionTreeOverrides; index as idx"
     class="override-item" [attr.data-override-index]="idx">
    <div class="override-header">Override</div>
    <div style="padding:12px;">
        <mat-form-field color="accent" class="full-width" appearance="outline" style="margin-bottom:10px;">
            <mat-label for="text">Description</mat-label>
            <textarea matInput
                      class="full-width"
                      required
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAuthsizeMinRows="1"
                      cdkAuthsizeMaxRows="10"
                      [(ngModel)]="oli.OverrideDescription"
                      name="text"></textarea>
            <mat-error>Please enter description</mat-error>
        </mat-form-field>
        <div class="drugselections expand">
            <div class="collapsable collapsed"
                 onclick="this.closest('.drugselections').classList.add('expand')">
                <span class="material-icons section-error-indication">info</span>
                <span style="display:flex;flex:1 1 100px">
                    Applicable Drugs
                </span>
                <span style="flex: 0 0 24px" class="material-icons">arrow_drop_up</span>
            </div>
            <div class="collapsable expanded"
                 onclick="this.closest('.drugselections').classList.remove('expand')">
                <span class="material-icons section-error-indication">info</span>
                <span style="display:flex;flex:1 1 100px">
                    Applicable Drugs
                </span>
                <span style="flex: 0 0 24px" class="material-icons">arrow_drop_down</span>
            </div>
            <div class="content expanded">
                <div class="all-drugs-switch">
                    All Drugs in the Decision Tree
                    <mat-slide-toggle [checked]="oli.AllDrugs"
                                      (change)="oli.AllDrugs = !oli.AllDrugs"></mat-slide-toggle>
                </div>
                <section *ngIf="!oli.AllDrugs && settings !== undefined">
                    <app-medication-chips [settings]="settings" [medications]="oli.DrugDetails"></app-medication-chips>
                </section>
            </div>
        </div>
        <div class="overrideconfiguration expand">
            <div class="collapsable expanded"
                 onclick="this.closest('.overrideconfiguration').classList.remove('expand')">
                <span class="material-icons section-error-indication">info</span>
                <span style="display:flex;flex:1 1 100px">
                    Override Configuration
                </span>
                <span style="flex: 0 0 24px" class="material-icons">arrow_drop_up</span>
            </div>
            <div class="collapsable collapsed" onclick="this.closest('.overrideconfiguration').classList.add('expand')">
                <span class="material-icons section-error-indication">info</span>
                <span style="display:flex;flex:1 1 100px">
                    Override Configuration
                </span>
                <span style="flex: 0 0 24px " class="material-icons">arrow_drop_down</span>
            </div>
            <div class="content expanded">
                <mat-form-field appearance="outline" style="margin-top:10px;width: 300px;" color="accent">
                    <mat-label>Authorization Period</mat-label>
                    <mat-select (selectionChange)="authPeriodChange($event, oli)"
                                required
                                [(ngModel)]="oli.authPeriodLabel">
                        <!--period.value == oli.AuthorizationPeriod.Value && period.unit == oli.AuthorizationPeriod.Unit-->
                        <mat-option *ngFor="let period of possibleAuthorizationPeriods" [value]="period.label">
                            {{ period.label }}
                        </mat-option>
                        <mat-option value="Custom" (click)="openCustomPeriodModal(oli)">
                            Custom
                            <span *ngIf="oli.authPeriodLabel == 'Custom'">
                                ({{oli.AuthorizationPeriod.Value }} {{oli.AuthorizationPeriod.Unit }})
                            </span>
                        </mat-option>
                    </mat-select>
                    <mat-error>required</mat-error>
                </mat-form-field>

                <div>
                    Override Codes
                    <div *ngIf="!oli.OverrideDetails.enableMultiTimeOverrideCode && !oli.OverrideDetails.enableOneTimeOverrideCode"
                         style="display:inline-block;color:#B03000">*</div>
                </div>
                <mat-checkbox [(ngModel)]="oli.OverrideDetails.enableOneTimeOverrideCode">
                    One Time Processing Only
                </mat-checkbox>
                <div *ngIf="oli.OverrideDetails.enableOneTimeOverrideCode && (overrideCodeList.length > 0)">
                    <app-override-chips [overrides]="oli.OverrideDetails.OneTimeOverrideCodes" [allOverrides]="overrideCodeList"></app-override-chips>
                    <div *ngIf="oli.OverrideDetails.OneTimeOverrideCodes.length == 0"
                         class="error-hint-line" style="margin-top:-20px;">
                        Please Add Override code.
                    </div>
                </div>
                <mat-checkbox [(ngModel)]="oli.OverrideDetails.enableMultiTimeOverrideCode">
                    Multiple Time Processing
                </mat-checkbox>
                <div *ngIf="oli.OverrideDetails.enableMultiTimeOverrideCode && (overrideCodeList.length > 0)">
                    <app-override-chips [overrides]="oli.OverrideDetails.MultipleTimeOverrideCodes" [allOverrides]="overrideCodeList"></app-override-chips>
                    <div *ngIf="oli.OverrideDetails.MultipleTimeOverrideCodes.length == 0"
                         class="error-hint-line" style="margin-top:-20px;">
                        Please Add Override code.
                    </div>
                </div>
                <div *ngIf="!oli.OverrideDetails.enableMultiTimeOverrideCode && !oli.OverrideDetails.enableOneTimeOverrideCode"
                     class="error-hint-line">
                    Please select the override codes.
                </div>

                <hr />
                Overrides Criteria<br />
                <div class="override-param-list">
                    <div class="override-param" *ngIf="showMinDaysSupply(oli)">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label for="text">Min Days Supply</mat-label>
                            <input matInput autocomplete="off"
                                   min="1" max="99999" maxlength="5"
                                   (keydown)="keyDownChuck($event, 5)"
                                   [(ngModel)]="oli.OverrideDetails.MinDaysSupply"
                                   type="number">
                            <mat-error>Days Supply should be 1-99999</mat-error>
                            <mat-hint *ngIf="(oli.OverrideDetails.MinDaysSupply ?? 0) > (oli.OverrideDetails.MaxDaysSupply ?? 999999)"
                                      style="color:#B03000;white-space:nowrap">
                                Cannot Exceed Max Days Supply
                            </mat-hint>
                            <mat-hint style="color:#b03000" *ngIf="!((oli.OverrideDetails.MinDaysSupply ?? 0) > (oli.OverrideDetails.MaxDaysSupply ?? 999999)) && showInputError=='Min Days Supply'">Days Supply should be 1-99999</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="override-param">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label for="text">Max Days Supply</mat-label>
                            <input matInput autocomplete="off"
                                   min="1" max="99999" maxlength="5"
                                   (keydown)="keyDownChuck($event, 5)"
                                   [(ngModel)]="oli.OverrideDetails.MaxDaysSupply"
                                   type="number">
                            <mat-error>Days Supply should be 1-99999</mat-error>
                            <mat-hint style="color:#b03000" *ngIf="showInputError=='Max Days Supply'">Days Supply should be 1-99999</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="override-param">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label for="text">Max Quantity Dispensed</mat-label>
                            <input matInput autocomplete="off"
                                   min="0.01" max="99999" maxlength="8"
                                   (keydown)="keyDownChuck($event, 8)"
                                   [(ngModel)]="oli.OverrideDetails.MaxQuantityDispensed"
                                   type="number">
                            <mat-error>Quantity should be 0.01-99999</mat-error>
                            <mat-hint style="color:#b03000" *ngIf="showInputError=='Max Quantity Dispensed'">Quantity should be 0.01-99999</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="override-param" *ngIf="showThreshold(oli)">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label for="text">Threshold</mat-label>
                            <input matInput autocomplete="off"
                                   min="1" max="999" maxlength="3"
                                   required
                                   (keydown)="keyDownChuck($event, 3)"
                                   [(ngModel)]="oli.OverrideDetails.Threshold"
                                   type="number">
                            <mat-error>Threshold should be 1-999</mat-error>
                            <mat-hint style="color:#b03000" *ngIf="showInputError=='Threshhold'">Threshold should be 1-999</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="override-param">
                        <mat-form-field color="accent" appearance="outline">
                            <mat-label for="text">Number Of Refills</mat-label>
                            <input matInput autocomplete="off"
                                   min="1" max="20" maxlength="2"
                                   [(ngModel)]="oli.OverrideDetails.NumberOfRefills"
                                   (keydown)="keyDownChuck($event, 2)"
                                   type="number">
                            <mat-error>Refills should be 1-20</mat-error>
                            <mat-hint style="color:#b03000" *ngIf="showInputError=='Number Of Refills'">Refills should be 1-20</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="override-footer" style="text-align:right;">
            <button mat-icon-button color="primary" (click)="remove(oli)"><mat-icon>delete</mat-icon></button>
        </div>
    </div>
</div>

<div style="width: 100%; text-align: center; margin-top: 10px; margin-bottom: 10px;">
    <button mat-mini-fab color="accent" (click)="addNewOverride()"
            style="pointer-events:auto; opacity:unset">
        <span class="material-icons">add</span>
    </button>
</div>
