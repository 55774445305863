import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirm-dialog',
    templateUrl: './delete-confirm-dialog.component.html',
    styleUrls: ['./delete-confirm-dialog.component.scss']
})
export class DeleteConfirmDialogComponent {
    public showDelete: boolean = true;
    public title: string = "Are you sure";
    public submitButtonText: string = "Yes, Delete";
    public dialogText: string = "This may not be undone";
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<DeleteConfirmDialogComponent>
    ) {
        if (Object.keys(data).indexOf("title") > -1) {
            this.title = data.title;
        }
        if (Object.keys(data).indexOf("submitButtonText") > -1) {
            this.submitButtonText = data.submitButtonText;
        }
        if (Object.keys(data).indexOf("dialogText") > -1) {
            this.dialogText = data.dialogText;
        }
        if (Object.keys(data).indexOf("showDelete") > -1) {
            this.showDelete = data.showDelete;
        }
    }
    afterOpened() {
        //var a: any = document.querySelector(".delete-confirm-dialog");
        //a.parentNode.parentNode.style.borderRadius = "26px";
    }
    close() {
        this.dialogRef.close("0");
    }
    confirm() {
        this.dialogRef.close("1");
    }
}