<h2 mat-dialog-title>{{title}}</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="justificationAddForm">
        <section>
            <h4>Justification Details</h4>
            <div class="column-12">
                <mat-form-field appearance="outline" class=" full-width">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="desc" type="text">
                </mat-form-field>
            </div>
            <div class="column-4">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Decision</mat-label>
                    <mat-select formControlName="decision" (selectionChange)="onDecisionChange()" placeholder="Select a decision">
                        <mat-option value="Approve">Approve</mat-option>
                        <mat-option value="Deny">Deny</mat-option>
                        <mat-option value="Partial Approval">Partial Approval</mat-option>
                        <mat-option value="RFI">Missing Information</mat-option>
                        <mat-option value="2Rph">Needs Pharmacist Review</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="column-8">
                <mat-form-field appearance="outline" class=" full-width">
                    <mat-label>Reason</mat-label>
                    <input matInput formControlName="decisionReason" type="text">
                </mat-form-field>
            </div>
            <div class="column-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Client</mat-label>
                    <mat-select name="clientList" formControlName="client" (selectionChange)="updateClient($event.value)" placeholder="Select a client">
                        <mat-option *ngFor="let c of clients; index as i; first as isFirst" [value]="c.clientID">
                            {{c.clientName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="column-6">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Line of Business</mat-label>
                    <mat-select name="pcnList" formControlName="pcn" (selectionChange)="updatePCN()" placeholder="Select a line of business">
                        <mat-option *ngFor="let p of pcns; index as i; first as isFirst" [value]="p.pcnReferenceID">
                            {{p.pcnDescription}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </section>
    </form>
    <br />
    <form [formGroup]="casesForm">
        <section>
            <h4>Cases</h4>
            <mat-checkbox [formControlName]="'allCases'" (change)="toggleAllCases()">All</mat-checkbox>
            <mat-checkbox *ngFor="let caseItem of cases" [formControlName]="'case-' + caseItem.pcnCaseTypeID">{{ caseItem.caseType }}</mat-checkbox>
        </section>
    </form>
    <br />
    <form [formGroup]="levelForm">
        <section>
            <h4>Determination Level</h4>
            <mat-checkbox [formControlName]="'allLevels'" (change)="toggleAllLevels()">All</mat-checkbox>
            <mat-checkbox formControlName="firstDeterminationChecked" style="margin-bottom:0px!important;">First Determination</mat-checkbox>
            <mat-checkbox formControlName="firstAppealChecked">First Appeal</mat-checkbox>
            <mat-checkbox formControlName="secondAppealChecked">Second Appeal</mat-checkbox>
            <mat-checkbox formControlName="thirdAppealChecked">Third Appeal</mat-checkbox>
        </section>
    </form>
    <br />
    <mat-divider></mat-divider>
    <br />
    <!-- Material tabs for English and Spanish decision justifications -->
    <section>
        <h4>Decision Justifications</h4>
        <mat-tab-group color="accent">
            <mat-tab label="English">
                <br />
                <form [formGroup]="decisionJustificationsForm">
                    <!-- English decision justifications form controls -->
                    <div style="text-align:right;">
                        <button (click)="applyToAll('eng')" [disabled]="isViewMode" mat-button color="accent">
                            Apply To all
                        </button>
                    </div>
                    <mat-form-field color="accent" appearance="outline" class="full-width">
                        <mat-label>Justification for Prescriber</mat-label>
                        <textarea matInput
                               cdkTextareaAutosize
                               #autosize="cdkTextareaAutosize"
                               cdkAuthsizeMinRows="1"
                               cdkAuthsizeMaxRows="10"
                               formControlName="prescriberEng" type="text"></textarea>
                    </mat-form-field>
                    <div style="text-align:right;">
                        <button (click)="sameAsPrescriber('eng','pharmacyEng')" [disabled]="isViewMode" mat-button color="accent">
                            Same as Prescriber
                        </button>
                    </div>
                    <mat-form-field color="accent" appearance="outline" class="full-width">
                        <mat-label>Justification for Pharmacy</mat-label>
                        <textarea matInput
                               cdkTextareaAutosize
                               #autosize="cdkTextareaAutosize"
                               cdkAuthsizeMinRows="1"
                               cdkAuthsizeMaxRows="10"
                               formControlName="pharmacyEng" type="text"></textarea>
                    </mat-form-field>
                    <div style="text-align:right;">
                        <button (click)="sameAsPrescriber('eng','beneficiaryEng')" [disabled]="isViewMode" mat-button color="accent">
                            Same as Prescriber
                        </button>
                    </div>
                    <mat-form-field color="accent" appearance="outline" class="full-width">
                        <mat-label>Justification for Beneficiary/Representative</mat-label>
                        <textarea matInput
                               cdkTextareaAutosize
                               #autosize="cdkTextareaAutosize"
                               cdkAuthsizeMinRows="1"
                               cdkAuthsizeMaxRows="10"
                               formControlName="beneficiaryEng" type="text"></textarea>
                    </mat-form-field>

                    <!-- Add similar fields for other English controls -->
                </form>
            </mat-tab>
            <mat-tab label="Spanish">
                <br />
                <form [formGroup]="decisionJustificationsForm">
                    <!-- Spanish decision justifications form controls -->
                    <div style="text-align: right;">
                        <button (click)="applyToAll('spa')" mat-button color="accent" [disabled]="isViewMode" style="text-align: right;">
                            Apply To all
                        </button>
                    </div>
                    <mat-form-field color="accent" appearance="outline" class="full-width">
                        <mat-label>Justification for Prescriber</mat-label>
                        <textarea matInput
                               cdkTextareaAutosize
                               #autosize="cdkTextareaAutosize"
                               cdkAuthsizeMinRows="1"
                               cdkAuthsizeMaxRows="10"
                               formControlName="prescriberSpa" type="text"></textarea>
                    </mat-form-field>
                    <div style="text-align: right;">
                        <button (click)="sameAsPrescriber('spa','pharmacySpa')" [disabled]="isViewMode" mat-button color="accent">
                            Same as Prescriber
                        </button>
                    </div>
                    <mat-form-field color="accent" appearance="outline" class="full-width">
                        <mat-label>Justification for Pharmacy</mat-label>
                        <textarea matInput
                               cdkTextareaAutosize
                               #autosize="cdkTextareaAutosize"
                               cdkAuthsizeMinRows="1"
                               cdkAuthsizeMaxRows="10"
                               formControlName="pharmacySpa" type="text"></textarea>
                    </mat-form-field>
                    <div style="text-align: right;">
                        <button (click)="sameAsPrescriber('spa','beneficiarySpa')" [disabled]="isViewMode" mat-button color="accent">
                            Same as Prescriber
                        </button>
                    </div>
                    <mat-form-field color="accent" appearance="outline" class="full-width">
                        <mat-label>Justification for Beneficiary/Representative</mat-label>
                        <textarea matInput
                               cdkTextareaAutosize
                               #autosize="cdkTextareaAutosize"
                               cdkAuthsizeMinRows="1"
                               cdkAuthsizeMaxRows="10"
                               formControlName="beneficiarySpa" type="text"></textarea>
                    </mat-form-field>

                    <!-- Add similar fields for other Spanish controls -->
                </form>
            </mat-tab>
        </mat-tab-group>
    </section>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="close()" style="border-radius: 24px;">
        Cancel
    </button>
    <div *ngIf="isCreateMode; else viewEditMode">
        <button (click)="save()" [disabled]="!isFormsValid()" mat-flat-button color="accent" style="border-radius: 24px;">
            Create
        </button>
    </div>
    <ng-template #viewEditMode>
        <button *ngIf="isViewMode; else createButton" (click)="toggleEditMode()" mat-flat-button color="accent" style="border-radius: 24px;">
            Edit
        </button>
        <ng-template #createButton>
            <button *ngIf="!isViewMode" (click)="update()" [disabled]="!isFormsValid()" mat-flat-button color="accent" style="border-radius: 24px;">
                Save
            </button>
        </ng-template>
    </ng-template>

</mat-dialog-actions>
