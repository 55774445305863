import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { DecisionTreeService } from '../../decision-tree/decision-tree.service';
import { HttpResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../auth/auth.service';
import { User } from '../../classes/user';
import { ApplicationStatus } from '../../classes/questionset';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent {
    user!: User;
    @Input() inputObject!: any;
    @Input() title!: string;
    @Input() isQS!: boolean;
    @Input() isDT!: boolean;
    @Input() inputID!: number;
    @Output() event: EventEmitter<any> = new EventEmitter();
    applications: ApplicationStatus[] = [];
    
    exportForm = this.fb.group({
        fileName: ['', Validators.required],
        fileRadio: ['', Validators.required],
        applicationRadio: ['1', Validators.required]
    });
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ExportModalComponent>,
        private fb: FormBuilder,
        private questionSetService: QuestionSetDashboardService,
        private decisionTreeService: DecisionTreeService,
        private authService: AuthService
    ) {
        if (data !== undefined) {
            this.inputObject = data.inputObject;
            this.title = data.title; 
            this.isQS = data.isQS;
            this.isDT = data.isDT;
            this.inputID = data.inputID;
        }
        this.user = this.authService.getUserLoginInfo();
    }

    ngOnInit() {
        this.authService.getApplicationList().subscribe(resp => {
            if (resp.body) {
                this.applications = [];
                var a = [];
                a = resp.body;
                a.forEach((e: any) => { this.applications.push(new ApplicationStatus(e)) });
            }
            this.exportForm.patchValue({
                fileName: this.title
            });
            this.exportForm.get('fileName')?.updateValueAndValidity();
        });
        
    }

    onSubmit(): void {
        if (this.isQS) {
            switch (this.exportForm.value.fileRadio) {
                case "pdf":
                    this.questionSetService.exportQuestionSetToPDFByID(this.inputID!).subscribe(
                        (event) => {
                            let data = event as HttpResponse<Blob>;
                            console.log(data);
                            const downloadedFile = new Blob([data.body as BlobPart], {
                                type: data.body?.type
                            });
                            if (downloadedFile.type != "") {
                                const a = document.createElement('a');
                                a.setAttribute('style', 'display:none;');
                                document.body.appendChild(a);
                                a.download = this.exportForm.value.fileName! + ".pdf";
                                a.href = URL.createObjectURL(downloadedFile);
                                a.target = '_blank';
                                a.click();
                                document.body.removeChild(a);
                            }
                        }
                    );
                    break;
                case "json":
                    this.questionSetService.exportQuestionSetToJSONByID(this.inputID!).subscribe(
                        (event) => {
                            let data = event as HttpResponse<Blob>;
                            console.log(data);
                            const downloadedFile = new Blob([data.body as BlobPart], {
                                type: data.body?.type
                            });
                            if (downloadedFile.type != "") {
                                const a = document.createElement('a');
                                a.setAttribute('style', 'display:none;');
                                document.body.appendChild(a);
                                a.download = this.exportForm.value.fileName! + ".json";
                                a.href = URL.createObjectURL(downloadedFile);
                                a.target = '_blank';
                                a.click();
                                document.body.removeChild(a);
                            }
                        }
                    );
                    break;
                default:
                    alert("No file type selected");
                    break;
            }
        }

        if (this.isDT) {
            switch (this.exportForm.value.fileRadio) {
                case "pdf":
                    this.decisionTreeService.exportDecisionTreeToPDFByID(this.inputID!, parseInt(this.exportForm.value.applicationRadio!)).subscribe(
                        (event) => {
                            let data = event as HttpResponse<Blob>;
                            console.log(data);
                            const downloadedFile = new Blob([data.body as BlobPart], {
                                type: data.body?.type
                            });
                            if (downloadedFile.type != "") {
                                const a = document.createElement('a');
                                a.setAttribute('style', 'display:none;');
                                document.body.appendChild(a);
                                a.download = this.exportForm.value.fileName! + ".pdf";
                                a.href = URL.createObjectURL(downloadedFile);
                                a.target = '_blank';
                                a.click();
                                document.body.removeChild(a);
                            }
                        }
                    );
                    break;
                case "json":
                    this.decisionTreeService.exportDecisionTreeToJSONByID(this.inputID!).subscribe(
                        (event) => {
                            let data = event as HttpResponse<Blob>;
                            console.log(data);
                            const downloadedFile = new Blob([data.body as BlobPart], {
                                type: data.body?.type
                            });
                            if (downloadedFile.type != "") {
                                const a = document.createElement('a');
                                a.setAttribute('style', 'display:none;');
                                document.body.appendChild(a);
                                a.download = this.exportForm.value.fileName! + ".json";
                                a.href = URL.createObjectURL(downloadedFile);
                                a.target = '_blank';
                                a.click();
                                document.body.removeChild(a);
                            }
                        }
                    );
                    break;
                default:
                    break;
            }
        }

        this.dialogRef.close("1");
    }

    close() {
        this.dialogRef.close("0");
    }
}
