import { Component, EventEmitter, Input, Output, Inject, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../classes/client';
import { DecisionTree } from '../../classes/decisiontree';
import { Hierarchy, HierarchyTies } from '../../classes/hierarchy';
import { SolrDrug } from '../../classes/solr-drug';
import { User } from '../../classes/user';
import { DecisionTreeService } from '../../decision-tree/decision-tree.service';
import { AuthService } from '../../shared/auth/auth.service';
import { HierarchyTerminateModalComponent } from '../hierarchy-terminate-modal/hierarchy-terminate-modal.component';
import { HierarchyService } from '../hierarchy.service';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DateService } from '../../shared/services/date.service';

@Component({
  selector: 'app-hierarchy-details-modal',
  templateUrl: './hierarchy-details-modal.component.html',
  styleUrls: ['./hierarchy-details-modal.component.scss']
})
export class HierarchyDetailsModalComponent implements OnInit, AfterViewInit {
    hierarchy!: Hierarchy;
    client!: Client;
    closeResult = '';

    title = 'Hierarchy Details';

    displayedColumns: string[] = ['tree', 'qualifier', 'id', 'type', 'subtype', 'tier', 'start', 'end', 'terminate'];
    dataSource: MatTableDataSource<HierarchyTies> = new MatTableDataSource<HierarchyTies>([]);

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;
    searchInput: string = '';
    
    user!: User;
    hierarchyTies: HierarchyTies[] | undefined = [];
    decisionTrees: DecisionTree[] = [];
    solrDrugs: SolrDrug[] = [];
    selectedDecisionTree!: DecisionTree;
    selectedDrug: SolrDrug | undefined;
    showDetails: boolean = true;
    showAdd: boolean = false;
    showDrugSearch: boolean = true;
    

    solrSearchForm = this.fb.group({
        drugSearchTerm: ['', Validators.required],
    });

    addTieForm = this.fb.group({
        decisionTreeList: ['', Validators.required],
        drugQualifierInput: ['', Validators.required],
        drugIDInput: [''],
        caseInput: [''],
        caseSubTypeInput: [''],
        tierInput: [''],
        start: [new Date()],
        end: [new Date()],
    });

    effectiveDate = this.fb.group({
        past: true,
        current: true,
        future: true,
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<HierarchyDetailsModalComponent>,
        private authService: AuthService,
        private hierarchyService: HierarchyService,
        private decisionTreeService: DecisionTreeService,
        private dialog: MatDialog,
        private dateService: DateService,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.hierarchy = data.hierarchy;
        this.client = data.client;
        this.getTies(this.hierarchy.hierarchyId);
    }

    ngOnInit() {
        
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    getTies(hierarchyId: number | undefined) {
        if (hierarchyId) {
            this.hierarchyTies = [];
            this.hierarchyService.getAllHierarchyToDecisionTreeFromService(this.hierarchy.hierarchyId!).subscribe(resp => {
                if (resp.body) {
                    this.hierarchyTies = this.hierarchyService.mapHierarchyTiesResponse(resp);
                    console.log(this.hierarchyTies);
                    if (this.hierarchyTies.length <= 0) {
                        this.getDecisionTrees();
                        //this.showDetails = false;
                    } else {
                        this.dataSource = new MatTableDataSource(this.hierarchyTies);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                    }
                }
            });
        }
    }

    switchForm() {
        if (this.showDetails) {
            this.getDecisionTrees();
            //this.showDetails = false;
            this.showDrugSearch = true;
            this.showAdd = false;
        } else {
            this.dataSource = new MatTableDataSource(this.hierarchyTies);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.showDetails = true;

        }
    }

    openTerminateModal(h: HierarchyTies) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            hierarchy : this.hierarchy,
            client : this.client,
            hierarchyTie : h
        }
        const dialogRef = this.dialog.open(HierarchyTerminateModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) this.getTies(this.hierarchy.hierarchyId); }
        );
    }

    applyFilter(event: MatCheckboxChange | Event) {
        const filterValue = this.searchInput.toLowerCase();
        const pastEffective = this.effectiveDate.get('past')?.value;
        const currentEffective = this.effectiveDate.get('current')?.value;
        const futureEffective = this.effectiveDate.get('future')?.value;

        this.dataSource.filterPredicate = (data: HierarchyTies, filter: string): boolean => {
            const isPast = this.dateService.isPastEffective(data);
            const isCurrent = this.dateService.isCurrentlyEffective(data);
            const isFuture = this.dateService.isFutureEffective(data);

            // Filter by search input
            const includesSearch = filter === '' || (data.decisionTreeName?.toLowerCase().includes(filter) ?? false);

            // Filter by effective date checkboxes
            const meetsEffectiveDateCriteria =
                ((pastEffective && isPast) ||
                    (currentEffective && isCurrent) ||
                    (futureEffective && isFuture)) ?? false;

            return includesSearch && meetsEffectiveDateCriteria;
        };

        this.dataSource.filter = filterValue.trim();

        // Manually trigger the filter update
        this.dataSource._filterData = (data: HierarchyTies[]) => {
            this.dataSource.filteredData = data.filter(obj => this.dataSource.filterPredicate(obj, this.dataSource.filter));
            return this.dataSource.filteredData;
        };
        this.dataSource._updateChangeSubscription();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    onEffectiveDateCheckboxChange(event: MatCheckboxChange | Event) {
        // Trigger filterPredicate when checkbox state changes
        this.applyFilter(event);
    }

    deleteHierarchyTie(tie: HierarchyTies) {
        //const dateToEnd = this.calendar.getToday();
        const dateToEnd = new Date().toISOString();
        this.hierarchyService.terminateHierarchy(this.hierarchy.hierarchyId!, tie.decisionTreeHeaderID!, this.user.id!, dateToEnd!)//this.dateToString(dateToEnd))
            .subscribe(
                (data) => {
                    if (data.status == 200) {
                        //successfully saved
                        this.getTies(this.hierarchy.hierarchyId);
                    }
                },  //changed
                (err) => {
                    console.log(err)
                },
                () => console.log("Done")
            );
    }

    getDecisionTrees() {
        this.decisionTrees = [];
        const pcns: number[] = [];
        this.decisionTreeService.getAllDecisionTreeFromService(this.client.clientID!, pcns).subscribe(resp => {
            if (resp.body) {
                this.decisionTrees = this.decisionTreeService.mapDecisionTreeResponse(resp);
                console.log(this.decisionTrees);
            }
        });
    }

    searchDrugs() {
        this.hierarchyService.searchSolrDrugs(this.solrSearchForm.value.drugSearchTerm!).subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.solrDrugs = this.hierarchyService.mapSolrDrugsResponse(resp);
                //console.log(this.solrDrugs);
            }
        });
    }

    onSelectDecisionTree() {
        this.selectedDecisionTree = this.decisionTrees.find(x => x.decisionTreeID?.toString() == this.addTieForm.value.decisionTreeList)!;
    }

    onSelectNoDrug() {
        const inputQualifier = this.addTieForm.controls["drugQualifierInput"];
        inputQualifier.setValue("N/A");
        this.showAdd = true;
        this.showDrugSearch = false;
    }

    onSelectDrug(d: SolrDrug) {
        const inputID = this.addTieForm.controls["drugIDInput"];
        inputID.setValue(d.gpi!.toString());
        const inputQualifier = this.addTieForm.controls["drugQualifierInput"];
        inputQualifier.setValue("GPI-14");
        this.showAdd = true;
        this.showDrugSearch = false;
    }

    save() {
        if (this.addTieForm.valid) {

            const parsedFromDate = this.addTieForm.value.start?.toISOString();
            const parsedToDate = this.addTieForm.value.end?.toISOString();
        
            this.hierarchyService.addHierarchyToDecisionTree(this.hierarchy.hierarchyId!, this.selectedDecisionTree.decisionTreeHeaderId!, this.addTieForm.value.drugQualifierInput!, this.addTieForm.value.drugIDInput, this.addTieForm.value.caseInput, this.addTieForm.value.caseSubTypeInput, this.addTieForm.value.tierInput, this.user.id!, parsedFromDate!, parsedToDate!)
                .subscribe(
                    (data) => {
                        if (data.status == 200) {
                            //successfully saved
                            this.dialogRef.close("1");
                        }
                    },  //changed
                    (err) => {
                        console.log(err)
                    },
                    () => console.log("Done")
                );
        }
    }

    close() {
        this.dialogRef.close("0");
    }
}
