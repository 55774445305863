import { Component } from '@angular/core';

@Component({
  selector: 'app-override-item',
  templateUrl: './override-item.component.html',
  styleUrls: ['./override-item.component.scss']
})
export class OverrideItemComponent {

}
