export class PlanSimple {
    id: number | undefined;
    desc: string | undefined;
}

export class Plan {
    planReferenceID: number | undefined;
    benefitPlanId: string | undefined;
    planDescription: string | undefined;
    pcn: string | undefined;

}