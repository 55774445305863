<!--<h2 mat-dialog-title>Alter Routing from: {{currrentEndpointLabel}}</h2>-->
<h2 mat-dialog-title class="route-title">Select Path</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-dialog-content>
    <!--Select existing question to Route to:-->
    <div class="route-search">
        <mat-form-field shape="large" class="full-width" color="accent">
            <!--<mat-label >Search</mat-label>-->
            <mat-icon matPrefix class="material-icons">search</mat-icon>
            <input matInput class="route-search" (keyup)="filter(this)" [(ngModel)]="routeSearch" #input
                   placeholder="Search">
        </mat-form-field>
    </div>

    <!--<input type="text" class="full-width" [(ngModel)]="routeSearch" (keyup)="filter(this)" />-->
    <div class="route-options">

        <section *ngIf="showDefault">
            <div class="option-filterable option-selectable" (click)="SetNextId('Default')">Default Path</div>
        </section>


        <section *ngFor="let rli of routeList">
            
            <div *ngIf="!rli.enabled" class="option-filterable option-unselectable"
                 [class.is-section-row]="rli.isSection"
                 disabled>
                <div *ngIf="rli.isCurrentQuestion" style="width:100%; color:#800088;margin-left:32px; font-size:12px;">Current Question</div>
                <span [class.section-option]="rli.isSection" class="option-offset">&nbsp;</span>
                <span *ngIf="!rli.isSection">{{rli.order}}: </span>
                <span class="display-detail">{{rli.val}}</span>
            </div>
            <div *ngIf="rli.enabled && rli.key == nextId" class="option-filterable option-selectable  option-iscurrent  "
                 [class.is-section-row]="rli.isSection"
                 (click)="SetNextId(rli.key)">
                <span [class.section-option]="rli.isSection" class="option-offset">&nbsp;</span>
                <span *ngIf="!rli.isSection">{{rli.order}}: </span>
                <span class="display-detail">{{rli.val}}</span>
            </div>
            <div *ngIf="rli.enabled && rli.key != nextId" class="option-filterable option-selectable"
                 [class.is-section-row]="rli.isSection"
                 (click)="SetNextId(rli.key)">
                <span [class.section-option]="rli.isSection" class="option-offset">&nbsp;</span>
                <span class="display-number" *ngIf="!rli.isSection">{{rli.order}}: </span>
                <div class="display-detail">{{rli.val}}</div>
            </div>
        </section>

        <div class="option-selectable" (click)="SetNextId('End')">Next Question Set</div>
        <div class="option-selectable" (click)="SetNextId('Final')">End</div>
        <!--<button class="full-width option-selectable" (click)="AddQuestion()">Insert Question</button>-->
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="accent" style="border-radius: 26px; padding: 10px 24px 10px 24px; height:40px; " (click)="close()">
        Cancel
    </button>
</mat-dialog-actions>
    
