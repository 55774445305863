import { Pipe, PipeTransform } from '@angular/core';
import { QuestionSetControl } from '../question-set.control';

@Pipe({
    name: 'sortByOrder',
    pure:false
})
export class SortByOrderPipe implements PipeTransform {

    transform(value: QuestionSetControl[]): QuestionSetControl[] {
        return value.sort((n1, n2) => {
            return n1.initialOrder! - n2.initialOrder!;
        });
  }

}
