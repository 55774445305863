import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HierarchyTestComponent } from './hierarchy/hierarchy-test/hierarchy-test.component';
import { LoginComponent } from './login/login.component';
import { QuestionEditComponent } from './question/question-edit/question-edit.component';
import { QuestionSetDetailsComponent } from './question/question-set/question-set-details/question-set-details.component';
import { QuestionSetDashboardComponent } from './question/question-set/question-set-dashboard.component';
import { AuthGuardService as AuthGuard } from './shared/auth/auth-guard.service';
import { RouteToRxplatformService as RouteToRxplatform } from './shared/auth/route-to-rxplatform.service';
import { DecisionTreeEditComponent } from './decision-tree/decision-tree-edit/decision-tree-edit.component';
import { TestComponent } from './rxdm/test/test.component';
import { HierarchyDashboardComponent } from './hierarchy/hierarchy-dashboard.component';
import { DecisionTreeDashboardComponent } from './decision-tree/decision-tree-dashboard.component';
import { EditQuestionsComponent } from './decision-tree/edit-questions/edit-questions.component';
import { EditQuestionSetComponent } from './question/edit-question-set/edit-question-set.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { permissionResolver } from './shared/auth/permissions.resolver';
import { JustificationsDashboardComponent } from './justifications/justifications-dashboard.component';
import { EditCatalogComponent } from './question/edit-catalog/edit-catalog.component';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        // canActivate: [AuthGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent,
    },
    {
        path: 'justification',
        resolve: { permissions: permissionResolver },
        children: [
            {
                path: '',
                component: JustificationsDashboardComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'hierarchy',
        resolve: { permissions: permissionResolver },
        children: [
            {
                path: '',
                component: HierarchyDashboardComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'test',
                component: HierarchyTestComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'questionset',
        resolve: { permissions: permissionResolver },
        children: [
            {
                path: '',
                component: QuestionSetDashboardComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'catalog',
                component: QuestionEditComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'edit',
                component: EditCatalogComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'details',
                component: QuestionSetDetailsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'decisiontree',
        resolve: { permissions: permissionResolver },
        children: [
            {
                path: '',
                component: DecisionTreeDashboardComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'edit',
                component: DecisionTreeEditComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'editquestions',
                component: EditQuestionsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
    {
        path: 'rxdm',
        resolve: { permissions: permissionResolver },
        component: TestComponent,
        canActivate: [AuthGuard]
    }
    //,
    //{
    //    path: '**',
    //    canActivate: [RouteToRxplatform],
    //    component: DecisionTreeDashboardComponent,
    //}

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
