import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DecisionTreeService } from '../../decision-tree/decision-tree.service';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { AuthService } from '../../shared/auth/auth.service';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { EditQuestionsCatalogModalComponent } from '../../decision-tree/edit-questions-catalog-modal/edit-questions-catalog-modal.component';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { EditQuestionSettingsModalComponent } from '../../decision-tree/edit-question-settings-modal/edit-question-settings-modal.component';
import { QuestionSetControl } from '../../shared/question-set.control';
import { ApplicationStatus, QuestionLanguage, QuestionSet, QuestionTypeSelect } from '../../classes/questionset';
import { DecisionTree, DecisionTreeHeader } from '../../classes/decisiontree';

declare var startQuestionSetReview: any;
//declare var svgHandler: any;

@Component({
  selector: 'app-edit-question-set',
  templateUrl: './edit-question-set.component.html',
  styleUrls: ['./edit-question-set.component.scss']
})
export class EditQuestionSetComponent implements OnInit {
    public decisionTree!: DecisionTree;
    public decisionTreeHeader!: DecisionTreeHeader;
    //public svgQuestionDisplay: SVGQuestionDisplay;
    public decisionTreeId: number = 0;
    public questionSetControls: QuestionSetControl[] = [];
    public selectedTabIndex: number = 0;
    private applications: ApplicationStatus[] = [];
    private questionSet?: QuestionSet;
    private questionSetID: number = 0;
    constructor(
        private location: Location,
        private e: ElementRef,
        private route: ActivatedRoute,
        private authService: AuthService,
        public questionSetService: QuestionSetService,
        public decisiontreeService: DecisionTreeService,
        public hierarchyService: HierarchyService,
        public questionSetDashboardService: QuestionSetDashboardService,
        private dialog: MatDialog
    ) {

    }
    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.questionSetID = params['id'];
        });
        this.authService.getApplicationList().subscribe(resp => {
            if (resp.body) {
                this.applications = [];
                var a = [];
                a = resp.body;
                a.forEach((e: any) => { this.applications.push(new ApplicationStatus(e)) });
            }
        });
    }

    getQuestionSetByID() {

    }

    showDecisions() {
        this.selectedTabIndex = 1;
    }

    showQuestions() {
        this.selectedTabIndex = 0;
    }

    removeQuestionSet(order: number) {
        this.questionSetControls = this.questionSetControls.filter
            ((qsc: QuestionSetControl) => { return qsc.initialOrder != order; });
        this.questionSetControls.forEach((qsc: QuestionSetControl) => {
            if (qsc.initialOrder! > order) { --qsc.initialOrder!; }
        });
        this.saveDecisionTreeAdditions([]);
    }

    moveQuestionSet(order: number, direction: string) {
        if (direction == "up") {
            if (order == 1) return;
            let a = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == order; });
            let b = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == (order - 1); });
            if (a.length && b.length == 1) {
                a[0].initialOrder! -= 1;
                b[0].initialOrder! += 1;
                this.saveDecisionTreeAdditions([]);
            }
        }
        if (direction == "down") {
            if (order == (this.questionSetControls.length + 1)) return;
            let a = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == order; });
            let b = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == (order + 1); });
            if (a.length && b.length == 1) {
                a[0].initialOrder! += 1;
                b[0].initialOrder! -= 1;
                this.saveDecisionTreeAdditions([]);
            }
        }
    }

    getDecisionTreeToQuestionSets() {
        this.decisiontreeService.getQuestionSetsByDecisionTreeID(this.decisionTreeId!).subscribe(resp => {
            if (resp.body) {
                var questionSets = this.decisiontreeService.mapQuestionSetDataToDecisionTreeResponse(resp);
                questionSets.forEach(qsd => {
                    if (qsd.questionSetData != undefined) {
                        var qsc = new QuestionSetControl(this.dialog);
                        var json = JSON.parse(qsd.questionSetData!);
                        qsc.LoadQuestionSet(json);
                        qsc.GetQuestionSet()!.questionSetHeader!.id = qsd.questionSetID;
                        qsc.initialOrder = qsd.initialOrder;
                        qsc.questionSetTypeId = qsd.questionSetTypeID;
                        if (qsd.questionSetTypeID != 0) {
                            qsc.showQuestions = false;
                        }
                        if (qsc.GetQuestionSet() !== undefined && qsc.GetQuestionSet()?.questionSetHeader !== undefined) {
                            qsc.GetQuestionSet()!.questionSetHeader!.id = qsd.questionSetID;
                        }
                        this.questionSetControls.push(qsc);
                        this.applications.forEach((al) => {
                            var newA = new ApplicationStatus({});
                            newA.id = al.id;
                            newA.description = al.description;
                            newA.name = al.name;
                            newA.enabled = ((qsd.applicationsEnabledList ?? [])!.indexOf(al.id) > -1);
                            qsc.applicationStatusList.push(newA);
                        });
                        qsc.CalculateQuestionDetail();
                    }
                });
            }
        });
    }

    openCatalog() {
        //const dialogConfig = new MatDialogConfig();
        //dialogConfig.disableClose = true;
        //dialogConfig.autoFocus = true;
        //dialogConfig.data = {
        //    clientId: this.decisionTreeHeader.clientID
        //}
        //const dialogRef = this.dialog.open(EditQuestionsCatalogModalComponent, dialogConfig);
        //dialogRef.afterClosed().subscribe(
        //    (data: string[]) => {
        //        if (data.length > 0) {
        //            this.saveDecisionTreeAdditions(data);
        //        }
        //    }
        //);
    }

    addNewQuestionSet() {
        //console.log("Add New Question Set");
        //var name = this.decisionTreeHeader.decisionTreeName!;
        //if (name.length > 44) {
        //    name = name.substring(0, 43);
        //}
        //name += new Date().toISOString().substring(0, 16);
        //this.questionSetDashboardService.addQuestionSet(
        //    this.decisionTreeHeader.clientID!,
        //    name,
        //    "Exclusive question set for  " + this.decisionTreeHeader.decisionTreeName,
        //    0
        //).subscribe(
        //    (data) => {
        //        if (data.status == 200) {
        //            if (data.body.result != -1) {
        //                let ioi = 1;
        //                this.addExistingQuestionSetToTree(data.body.result, this.questionSetControls.length + 1, this.decisionTreeHeader.decisionTreeName!);
        //                var questionList: any[] = [];
        //                this.questionSetControls
        //                    .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
        //                    .forEach(qsc => {
        //                        questionList.push(
        //                            {
        //                                questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id,
        //                                InitialOrder: ioi++
        //                            }
        //                        )
        //                    });
        //                questionList.push(
        //                    {
        //                        questionSetID: data.body.result,
        //                        InitialOrder: ioi++
        //                    }
        //                );
        //                this.saveOrderedDecisionTree(questionList);
        //            } else {
        //                alert(data.body.message);
        //            }
        //        }
        //    },
        //    (err) => { console.log(err) }, () => console.log("Done")
        //);
    }

    save() {
        //if (this.decisionTree.decisionTreeStatus == 3) {
        //    this.saveDecisionTreeAdditions([], true);

        //} else {
        //    this.saveEachQuestionSet();
        //}
    }

    saveEachQuestionSet() {
        //this.decisionTree.decisionTreeStatus = 1;
        //var self = this;
        //this.questionSetControls.forEach(
        //    function (qsc) {
        //        if (qsc.questionSetTypeId == 0) {
        //            self.saveQuestionSet(qsc);
        //        }
        //    }
        //);
    }

    saveQuestionSet(qsc: QuestionSetControl) {
        let langResult: any = null;
        let langRoot: any = document.getElementById("tree-view-language");
        if (langRoot !== null) {
            langResult = [];
            let inputArray: any = langRoot.querySelectorAll("input");
            inputArray.forEach((e: any) => {
                if (e.value != '') {
                    langResult.push({
                        languageTextGuid: e.dataset.guid,
                        languageText: e.value,
                        languageCode: e.dataset.code,
                        recipient: e.dataset.recipient,
                    });
                }
            });
        }
        var appsEnabled: number[] = [];
        qsc.applicationStatusList.forEach(
            (ali: ApplicationStatus) => {
                if (ali.enabled) appsEnabled.push(ali.id);
            }
        )

        console.log("APPLIATION STATUS LIST IN SAVE", appsEnabled,
            JSON.stringify(qsc.applicationStatusList)
        )
        //document.getElementsByName("applicationList").forEach((e: any) => {
        //    if (e.checked) appsEnabled.push(e.value);
        //});
        //console.log(this.enabledApplicationForm.value);

        var qs = qsc.GetQuestionSet();
        if (qs !== undefined) {
            var currentId = qsc.GetQuestionSet()!.questionSetHeader!.id;
            console.log("SAVING " + currentId)
            this.questionSetService.SaveQuestionSet(currentId, appsEnabled, qs, langResult).subscribe(resp => {
                if (resp.body.message != "" && resp.body.message != null) {
                    alert("Save Failed");
                } else {
                    var newQuestionSetID = resp.body.result;
                    qsc.GetQuestionSet()!.questionSetHeader!.id = newQuestionSetID;
                    console.log("SAVED", currentId);
                    var updates = JSON.parse(resp.body.extension);
                    console.log(updates);
                    if (updates != null) {
                        Object.keys(updates).forEach((e: any) => {
                            var t = updates[e].split("-");
                            var q = qsc.GetQuestionByID(t[1]);
                            console.log(t, q);
                            if (q != null) {
                                console.log(t[0]);
                                switch (t[0]) {
                                    case "q":
                                        console.log(t[0] + " language set to " + e);
                                        q.languageGuid = e;
                                        break;
                                    //case "qa":
                                    //    break;
                                    case "qc":
                                        var choice = (<QuestionTypeSelect>q.questionType).choice.filter(
                                            (c: any) => { return t[2] == c.id; })
                                        console.log(t[0] + " Choice " + t[2] + " language set to " + e);
                                        if (choice != null) {
                                            choice[0].choiceTextGuid = e;
                                        }
                                        break;
                                    //case "qca":
                                    //    break;
                                    //case "qsnqc":
                                    //    break;
                                    //case "qsdtc":
                                    //    break;
                                    //case "qsnmc":
                                    //    break;
                                    default:
                                        console.log("Error loading language guid")
                                        break;
                                }
                            }
                        });
                    }
                }
            });
        }
    }

    saveDecisionTreeAdditions(newIds: string[], saveAfterComplete: boolean = false) {
        var questionList: any[] = [];
        let ioi = 1;
        this.questionSetControls
            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
            .forEach(qsc => {
                questionList.push(
                    {
                        questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id,
                        InitialOrder: ioi++
                    }
                )
            });
        newIds.forEach(qid => {
            this.addExistingQuestionSetToTree(parseInt(qid), this.questionSetControls.length + 1, "");
            questionList.push(
                {
                    questionSetID: parseInt(qid),
                    InitialOrder: ioi++
                }
            );
        });
        this.saveOrderedDecisionTree(questionList, saveAfterComplete);
    }

    addExistingQuestionSetToTree(qid: number, order: number, name: string) {
        this.questionSetService.getQuestionSetDetailFromService((qid))
            .subscribe(resp => {
                console.log(resp);
                var qsc = new QuestionSetControl(this.dialog);
                var qsJson = JSON.parse(resp.body.questionSet.questionSetData);
                qsc.LoadQuestionSet(qsJson);
                qsc.GetQuestionSet()!.questionSetHeader!.id = (qid);
                qsc.initialOrder = order;

                qsc.questionSetTypeId = resp.body.questionSet.typeID;
                if (qsc.GetQuestionSet() !== undefined && qsc.GetQuestionSet()?.questionSetHeader !== undefined) {
                    qsc.GetQuestionSet()!.questionSetHeader!.id = (qid);
                    if (name != "") {
                        qsc.GetQuestionSet()!.questionSetHeader!.title = name;
                    }
                }
                this.questionSetControls.push(qsc);
            });
    }

    saveOrderedDecisionTree(questionList: any[], saveAfterComplete: boolean = false) {
        //const start = new Date(this.decisionTree.startDate!);
        //const parsedFromDate = start.toISOString();
        //const end = new Date(this.decisionTree.endDate!);
        //const parsedToDate = end.toISOString();
        //this.decisionTree.comments = "";
        //this.decisiontreeService.saveDecisionTree(
        //    this.decisionTree?.decisionTreeID!, this.authService.user.id!
        //    , ""!, parsedFromDate!, parsedToDate!
        //    , questionList
        //).subscribe(
        //    (data) => {
        //        if (data.body.message != "" && data.body.message != null) {
        //            alert("Save Failed");
        //        } else {
        //            console.log("SAVED", data.body.result);
        //            this.decisionTreeId = data.body.result;
        //            this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
        //            if (saveAfterComplete) {
        //                this.saveEachQuestionSet();
        //            }
        //        }
        //    },  //changed
        //    (err) => {
        //        console.log(err)
        //    },
        //    () => console.log("Done")
        //);
    }

    getOrderedQuestionSetList(): number[] {
        let ioi = 1;
        var questionList: any[] = [];
        this.questionSetControls
            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
            .forEach(qsc => {
                questionList.push(
                    {
                        questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id,
                        InitialOrder: ioi++
                    }
                )
            });

        console.log(questionList);
        return questionList;
    }

    approveRequest() {
        //const parsedFromDate = this.decisionTree?.startDate!.toString();
        //const parsedToDate = this.decisionTree?.endDate!.toString();
        //let questionList = this.getOrderedQuestionSetList();
        //this.decisiontreeService.submitForApproval(
        //    this.decisionTree?.decisionTreeID!, this.authService.user.id!
        //    , "Approval Request Submitted", parsedFromDate!, parsedToDate!
        //    , questionList
        //).subscribe(
        //    (data) => {
        //        console.log(data);
        //        if (data.body.result == -1) {
        //            alert(data.body[0].message);
        //        } else {
        //            console.log("approval submision set");
        //            this.decisionTree.decisionTreeStatus = 2;
        //            this.decisionTreeId = data.body.result;
        //            this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
        //        }
        //    });
    }

    unsetApprovalRequest() {
        //const parsedFromDate = this.decisionTree?.startDate!.toString();
        //const parsedToDate = this.decisionTree?.endDate!.toString();
        //let questionList = this.getOrderedQuestionSetList();
        //this.decisiontreeService.unsetApprovalRequest(
        //    this.decisionTree?.decisionTreeID!, this.authService.user.id!
        //    , "Approval Request Rejected", parsedFromDate!, parsedToDate!
        //    , questionList
        //).subscribe(
        //    (data) => {
        //        console.log(data);
        //        if (data.body.result == -1) {
        //            alert(data.body.message);
        //        } else {
        //            console.log("Approval submit redacted");
        //            this.decisionTree.decisionTreeStatus = 1;
        //            this.decisionTreeId = data.body.result;
        //            this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
        //        }
        //    });
    }

    approve() {

        //const dialogConfig = new MatDialogConfig();
        //dialogConfig.disableClose = true;
        //dialogConfig.autoFocus = true;
        //dialogConfig.data = {
        //    minStartTomorrow: this.decisionTree.majorVersion != 1
        //}
        //const dialogRef = this.dialog.open(ApproveDialogComponent, dialogConfig);
        //dialogRef.afterClosed().subscribe(
        //    data => {
        //        console.log(data);
        //        this.approvalSubmit(data);
        //    }
        //);
    }

    approvalSubmit(data: any) {
        //const parsedFromDate = data.start;
        //const parsedToDate = data.end;
        //this.decisionTree.comments = data.comment;
        //this.decisiontreeService.approveDecisionTree(this.decisionTree?.decisionTreeID!, this.authService.user.id!, parsedFromDate!, parsedToDate!).subscribe(
        //    (data) => {
        //        console.log(data);
        //        if ((data.body.message != "" && data.body.message != null) || data.body.result == -1) {
        //            if (data.body != null) {
        //                alert(data.body.message);
        //            } else {
        //                alert("Approval failed");
        //            }
        //        } else {
        //            console.log("SAVED");
        //            this.decisionTree.decisionTreeStatus = 3;
        //            //this.decisionTreeId = data.body.result;
        //            //this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
        //        }
        //    },  //changed
        //    (err) => {
        //        console.log(err)
        //    },
        //    () => console.log("Done")
        //);
    }

    test() {
        //const dialogConfig = new MatDialogConfig();
        //dialogConfig.disableClose = true;
        //dialogConfig.autoFocus = true;
        //const dialogRef = this.dialog.open(TestDecisiontreeModalComponent, dialogConfig);
        //dialogRef.afterClosed().subscribe(
        //    data => {
        //        this.openinteractiveQuestionAnswerModal(data)
        //    }
        //);

    }

    openinteractiveQuestionAnswerModal(applicationId: number) {
        //this.decisiontreeService.getCompletedDecisionTree(
        //    this.decisionTree.decisionTreeID!, applicationId
        //)
        //    .subscribe(resp => {
        //        if (resp.body) {
        //            startQuestionSetReview(this.decisionTree?.decisionTreeName, resp.body);
        //        } else {
        //            alert('No question set available for the selected application');
        //        }
        //    });
    }

    dragOver(event: any) {
        let eox = event.pageX - 10;
        this.e.nativeElement.querySelector("#leftSide").style.width = eox + "px";
    }

    dragStart(event: any) {
    }

    dragDrop(event: any) {
    }
}
