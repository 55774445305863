import { Pipe, PipeTransform } from '@angular/core';
import { Status } from '../environments/environments';

@Pipe({
    name: 'status',
    standalone: true,
    pure: false
})
export class StatusPipe implements PipeTransform {

    transform(value: number | undefined, start: Date | undefined, end: Date | undefined): string {

        switch (value!) {
            case Status.Deleted:
                return 'Deleted';
            case Status.Approved:
                if (start != undefined && end != undefined) {
                    var d = new Date().toISOString().split('T')[0];
                    var sd: string, ed: string;
                    if (typeof (start) == "string") { sd = start; } else { sd = start!.toISOString(); }
                    if (typeof (end) == "string") { ed = end; } else { ed = end!.toISOString(); }
                    if (d > ed.split('T')[0]) {
                        return 'Inactive';
                    } else if (d < sd.split('T')[0]) {
                        return 'Future Active';
                    }
                }
                return 'Active';
            case Status.Draft:
                return 'Draft';
            case Status.Pending:
                return 'Pending Approval';
            default:
                return '';
        }
    }

}
