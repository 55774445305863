
export class DecisionTree {
    decisionTreeHeaderId: number | undefined;
    decisionTreeName: string | undefined;
    decisionTreeDescription: string | undefined;
    decisionTreeID: number | undefined;
    clientID: string | undefined;
    majorVersion: number | undefined;
    minorVersion: number | undefined;
    decisionTreeStatus: number | undefined;
    //determinationLevel: number | undefined;
    ties: number | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    comments: string | undefined;
    versionItems: DecisionTreeVersionInfo[] | undefined;
}
export class DecisionTreeVersionInfo {
    decisionTreeHeaderId: number | undefined;
    decisionTreeName: string | undefined;
    decisionTreeDescription: string | undefined;
    decisionTreeID: number | undefined;
    clientID: string | undefined;
    majorVersion: number | undefined;
    minorVersion: number | undefined;
    decisionTreeStatus: number | undefined;
    //determinationLevel: number | undefined;
    ties: number | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    comments: string | undefined;
}



export class VersionHistoryItem {
    decisionTreeHeaderID: number;
    clientID: string;
    decisionTreeID: number;
    majorVersion: number;
    minorVersion: number;
    decisionTreeStatus: number;
    comments: string;
    startDate: Date;
    endDate: Date;
    updateDate: Date;
    userIdentifier: string;
    constructor(data: any) {
        
        this.decisionTreeHeaderID = data.decisionTreeHeaderID;
        this.clientID = data.clientID; this.decisionTreeID = data.decisionTreeID;
        this.majorVersion = data.majorVersion; this.minorVersion = data.minorVersion;
        this.decisionTreeStatus = data.decisionTreeStatus; this.comments = data.comments;
        this.startDate = new Date(data.startDate);
        this.endDate = new Date(data.endDate);
        this.updateDate = data.updateDate; this.userIdentifier = data.userIdentifier;
    }
}


export class DecisionTreeHeader {
    decisionTreeHeaderId: number | undefined;
    decisionTreeName: string | undefined;
    decisionTreeDescription: string | undefined;
    clientID: string | undefined;
}

export class DecisionTreeRXDM {
    hierarchyID: number | undefined;
    clientID: string | undefined;
    pcnReferenceID: number | undefined;
    planReferenceID: number | undefined;
    groupReferenceID: number | undefined;
    hierarchyDescription: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    isDeleted: boolean | undefined;
    clientName: string | undefined;
    pcn: string | undefined;
    pcnDescription: string | undefined;
    benefitPlanID: string | undefined;

    planDescription: string | undefined;
    benefitGroupID: string | undefined;

    groupName: string | undefined;
    hierarchyToDecisionTreeID: number | undefined;
    decisionTreeHeaderID: number | undefined;
    drugIDQualifier: string | undefined;
    drugID: string | undefined;
    caseType: string | undefined;
    caseSubType: string | undefined;
    tier: string | undefined;
    priority: number | undefined;
    decisionTreeName: string | undefined;
    decisionTreeDescription: string | undefined;
    decisionTreeID: string | undefined;
}