import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { SortBySequencePipe } from '../../shared/pipes/sort-by-sequence.pipe';
import { QuestionSetControl, QuestionSetRouting } from '../../shared/question-set.control';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { DeleteConfirmDialogComponent } from '../../shared/delete-confirm-dialog/delete-confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HoverMenuComponent } from '../../shared/hover-menu/hover-menu.component';
@Component({
    selector: 'app-edit-questions-list',
    templateUrl: './edit-questions-list.component.html',
    styleUrls: ['./edit-questions-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditQuestionsListComponent {
    @Input() questionSetControl!: QuestionSetControl;
    @Input() p: any;
    @Input() questionSetRoutingList?: QuestionSetRouting[];
    positionOptions: TooltipPosition[] = ['above'];
    position = new FormControl(this.positionOptions[0]);
    
    constructor(
        public questionSetDashboardService: QuestionSetDashboardService,
        private dialog: MatDialog
    ) { }
    removeQuestionSet() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "delete-confirm-dialog";
        dialogConfig.data = {
            showDelete: true,
            submitButtonText: "Yes, Delete",
            title: "Are you sure you want to delete this section?",
            dialogText: "This action cannot be undone, this section will be permantently deleted from the tree."
        };
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != 0) {
                    this.removeQuestionSetConfirmed()
                }
            }
        );
        this.p.setInternalOrderOffsets();
        this.p.setQuestionSetRouting();
    }
    removeQuestionSetConfirmed() {
        this.p.removeQuestionSet(this.questionSetControl.initialOrder);
    }

    moveSet(direction:string) {
        this.p.moveQuestionSet(this.questionSetControl.initialOrder, direction)
    }
    
    addQuestion(internalOrder:number) {
        this.questionSetControl.addQuestionQuick(internalOrder);
        this.p.setInternalOrderOffsets();
        this.p.setQuestionSetRouting();
    }
    unlock() {
        if (confirm("Would you like to create a Decision Tree specific set for this catalog item?")) {
            this.addAsNewQuestionSet();
        }
    }
    addNewQuestionSet() {
        this.p.addNewQuestionSet(this.questionSetControl.initialOrder);
    }
    insertQuestionSet(internalOrder: number) {
        this.p.insertQuestionSet(this.questionSetControl.initialOrder, internalOrder);
    }
    splitQuestionSet(internalOrder:number) {
        this.p.splitQuestionSet(this.questionSetControl.initialOrder, internalOrder);
    }

    copyQuestionSet() {
        this.p.copyQuestionSet(this.questionSetControl.initialOrder);
    }
    addAsNewQuestionSet() {
        //console.log("Add New Question Set for given question set");
        var name = this.questionSetControl.getTitle();
        if (name.length > 44) {
            name = name.substring(0, 43);
        }
        name += new Date().toISOString().substring(0, 16);
        this.questionSetDashboardService.addQuestionSet(
            this.p.decisionTreeHeader.clientID!,
            name,
            "Exclusive question set for  " + this.p.decisionTreeHeader.decisionTreeName,
            0
        ).subscribe(
            (data) => {
                if (data.status == 200) {
                    if (data.body.result != -1) {
                        this.questionSetControl.questionSetTypeId = 0;
                        this.questionSetControl.GetQuestionSet()!.questionSetHeader!.id = data.body.result;
                        this.p.saveDecisionTreeAdditions([], true);
                        var self = this;
                        var io = this.questionSetControl!.initialOrder ?? "";

                        setTimeout(function () {
                            self.toggleShowQuestions(true);
                            var a: any = document.getElementById("question-set-" + io);
                            a.classList.remove("catalogQuestion");
                        }, 250);
                        //this.p.save();
                    } else {
                        alert(data.body.message);
                    }
                }
            },
            (err) => { console.log(err) }, () => console.log("Done")
        );
    }
    toggleShowQuestions(b: boolean) {
        this.questionSetControl.showQuestions = b;
    }

    setQuestionSetRouting() {
        this.p.setQuestionSetRouting();
    }
}
