<h2 mat-dialog-title>Import Decision Tree</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-dialog-content>
    <form id="importForm" [formGroup]="importDTForm" (ngSubmit)="onSubmit()">
        <section class="column-12">
            <button type="button" mat-stroked-button (click)="fileInput.click()">Choose File</button>
            <input hidden (change)="onFileSelected($event)" #fileInput formControlName="file" type="file">
            <span class="file-name">{{selectedFile?.name}}</span>
        </section>
        <br />
        <mat-divider></mat-divider>
        <br />
        <mat-form-field class="column-6" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput type="text" autocomplete="off" id="newName" formControlName="newName" placeholder="Enter a name">
        </mat-form-field>

        <mat-form-field class="column-6" appearance="outline">
            <mat-label>Client</mat-label>
            <mat-select formControlName="clientSelect" name="selectedClient" (selectionChange)="onSelectClient()">
                <mat-option *ngFor="let c of clients" [value]="c.clientID">
                    {{c.clientName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div formArrayName="questions">
            <div *ngIf="questionFormArray.length > 0">

                <div *ngFor="let question of questionFormArray.controls; let i = index" [formGroupName]="i">
                    <ng-container  *ngIf="question.get('question')">
                        <mat-checkbox [formControlName]="'question'">{{ questionSetNames[i] }}</mat-checkbox>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="questionFormArray.length === 0">
                <!-- Placeholder content or any appropriate message -->
                No questions available.
            </div>
        </div>
        <!--<div *ngFor="let name of questionSetNames">
            {{name}} should be used for this tree only
            <input type="checkbox" name="{{name}}" />
        </div>-->


        <!--<p>Form Status: {{ importDTForm.status }}</p>-->
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="importForm" [disabled]="!importDTForm.valid"
            mat-raised-button color="primary">Import</button>
</mat-dialog-actions>
