import { Client } from "./client";
import { DrugType } from "./solr-drug";

export class DecisionTreeSettings {
    applicationUserID: number | undefined;
    copyfrom?: number = undefined;
    client: Client = new Client; //regular client object, clientName, clientID
    decisionTree: DecisionTreeSettingsDetail = new DecisionTreeSettingsDetail;
    hierarchy: HierarchySettings[] = [];
    noDrug: boolean = false; //front end only
    noCase: boolean = false; //front end only
}

export class DecisionTreeSettingsDetail {
    decisionTreeHeaderId: number | undefined;
    decisionTreeName: string | undefined;
    decisionTreeDescription: string | undefined;
    decisionTreeID: number | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
}

export class HierarchySettings {
    hierarchyId: number | undefined;
    pcnReferenceID: number | undefined;
    pcn: string | undefined;
    pcnDescription: string | undefined;
    planReferenceID: number | undefined;
    benefitPlanId: string | undefined;
    plan: string | undefined;
    benefitGroupId: string | undefined;
    group: string | undefined;
    groupReferenceID: number | undefined;
    medicarePart: string = "";
    isSelected: boolean = false; //front end only
    noDrug: boolean = false; //front end only
    noCase: boolean = false; //front end only
    settingsStartDate: Date | undefined;
    settingsEndDate: Date | undefined;
    drugs: DrugSettings[] = [];
    cases: CaseSettings[] = [];
    determination: DeterminationSettings[] = [];
}

export class DrugSettings {
    //drug database id?
    drugIDQualifier: string | undefined;
    drugID: string | undefined;
    drugDisplay: DrugDisplay | undefined;
    multiSourceCodes: any | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
}
export class DrugDisplay {
    drugDesc: string | undefined;
    drugGPI: string | undefined;
    drugNDC: string | undefined;
    drugDisplayGPI: string | undefined;
    type: DrugType | undefined;
}

export class CaseSettings {
    //case record id
    caseType: string | undefined;
    caseSubType: string | undefined;
}
export class DeterminationSettings {
    //determination level id
    determinationLevel: number | undefined;
}

export class JustificationSettings {
    JustificationTemplateID: number | undefined;
    JustificationDescription: string | undefined;
    JustificationDecision: string | undefined;
    JustificationDecisionReason: string | undefined;
    ClientID: string | undefined;
    PCN: string | undefined;
    pcnReferenceID: number | undefined;
    PCNDescription: string | undefined;
    CaseType: JustificationCaseSettings[] = [];
    DeterminationLevel: JustificationDeterminationLevelSettings[] = [];
    JustificationReasonJson: string | undefined;
    OverridesJson: string | undefined;
    OverrideCriteria: string | undefined;
}

export class JustificationCaseSettings {
    JustificationTemplateToCaseTypeID: number | undefined;
    CaseTypeID: number | undefined;
    CaseType: string | undefined;
}

export class JustificationDeterminationLevelSettings {
    JustificationTemplateToDeterminationLevelID: number | undefined;
    DeterminationLevelID: number | undefined;
    DeterminationLevel: string | undefined;
}