<h2 mat-dialog-title style="width:400px;">{{title}}</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content class="dialog-detail">
    <section *ngIf="dialogText != ''">
        <p>{{dialogText}}</p>
    </section>
    <form [formGroup]="approveForm">
        <mat-form-field appearance="outline">
            <mat-label>Effectiveness Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate [min]="minStartDate" placeholder="Start date" formControlName="start">
                <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field *ngIf="showComment" class="full-width" appearance="outline">
            <mat-label for="text">Comment</mat-label>
            <textarea matInput class="full-width" id="text"
                      formControlName="comment"></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="close()" style="border-radius:24px;">
        Cancel
    </button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!approveForm.valid" style="border-radius:24px;">
        {{submitButtonText}}
    </button>
</mat-dialog-actions>

