<h2 mat-dialog-title style="min-width:600px;">History</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-dialog-content>

    <div class="column-3" style="font-weight:500;">Name</div>
    <div class="column-1" style="font-weight:500;">version</div>
    <div class="column-5" style="font-weight:500;">comments</div>
    <div class="column-1" style="font-weight:500;">Date</div>
    <div class="column-1" style="font-weight:500;">user</div>
    <div class="column-1" style="font-weight:500;">A</div>
    <section *ngFor="let r of records">
        <div class="column-3">{{r.decisionTreeName}}</div>
        <div class="column-1">{{r.majorVersion}}.{{r.minorVersion}}</div>
        <div class="column-5">{{r.comments}}</div>
        <div class="column-1">{{r.updateDate.toString().replace('T',' ')}}</div>
        <div class="column-1">{{r.userIdentifier}}</div>
        <div class="column-1"></div>
    </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
</mat-dialog-actions>