
<div class="cntr" (dragover)="dragOver($event)" (drop)="dragDrop($event)">
    <div id="leftSide" class=" lside">
        <div class="infoSection">
            <section *ngIf="decisionTreeHeader !== undefined && decisionTree !== undefined && decisionTree.majorVersion !== undefined">
                <span style="font-size:22px; font-weight: 400;display:inline-flex;align-items:center;cursor:pointer;"
                      [matMenuTriggerFor]="majorVersionSwitch">
                    {{decisionTreeHeader.decisionTreeName}} v{{decisionTree.majorVersion}}
                    <span style="flex: 22px 0 0;margin-top:5px;" class="material-icons midown">arrow_drop_down</span>
                </span>
                &nbsp;
                <span [class]="'status-pill ' + (decisionTree.decisionTreeStatus | statusClass: decisionTree.startDate: decisionTree.endDate)">
                    {{decisionTree.decisionTreeStatus | status: decisionTree.startDate: decisionTree.endDate}}
                </span>
                <mat-menu #majorVersionSwitch="matMenu">
                    <span style="font-weight: 500; margin-left: 10px; margin-right: 10px; font-size: 16px; line-height: 24px;">Decision Tree Versions</span>
                    <div *ngFor="let i of getMajorVersions()" (click)="switchMajorVersion(i)"
                         class="major-version-menu">
                        <hr style="margin:10px;" />
                        <div [style]="i == decisionTree.majorVersion ? 'background-color:#dddddd' : ''"
                             style="padding:5px;">
                            <section *ngFor="let v of historyRecords; index as idx" class="minor-versions">
                                <div style="display: flex; align-items: center; "
                                     *ngIf="v.majorVersion == i && showInVersionMenu(v, i) && ( v.decisionTreeStatus != 3 || v.endDate >= v.startDate)" 
                                     >
                                    <!--&& !isNotMaxVersion(v)-->
                                    <div *ngIf="isNotMaxVersion(v)"
                                         style="flex-grow: 0; flex-shrink: 0; font-size: 48px;">
                                        &bull;
                                    </div>
                                    <div style="flex-grow: 10; flex-shrink: 10;  ">
                                        <span style="font-weight: 500;">
                                            {{decisionTreeHeader.decisionTreeName ?? ""}} v{{v.majorVersion}}.{{v.minorVersion}} &nbsp;
                                        </span>
                                        <div *ngIf="v.decisionTreeStatus!=3" style="font-weight: 400; font-style:italic;">
                                            {{v.startDate | date}} - {{v.endDate | date}} &nbsp;
                                        </div>
                                        <div *ngIf="v.decisionTreeStatus==3" style="font-weight: 400;">
                                            {{v.startDate | date}} - {{v.endDate | date}} &nbsp;
                                        </div>
                                    </div>
                                    <div style="flex-grow: 0; flex-shrink: 0; font-size: 8px;">
                                        <span *ngIf="v !== undefined"
                                              [class]="'status-pill ' + (v.decisionTreeStatus | statusClass: v.startDate: v.endDate)">
                                            {{v.decisionTreeStatus | status: v.startDate: v.endDate}}
                                        </span>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </mat-menu>
                <!--<span *ngIf="!treeIsCurrent">
                    <mat-icon>history</mat-icon>
                </span>-->
            </section>
        </div>


        <div id="questionEditArea" style="position:relative; margin-top:-15px;">
            <mat-tab-group [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false" mat-align-tabs="start" color="accent" id="questionlisttabs">
                <mat-tab label="Questions">
                    <div *ngIf="isLoading" style="width:100%; text-align:center;margin-top:40vh">
                        <ngx-skeleton-loader count="3"
                                             appearance="circle"
                                             animation="progress-dark"></ngx-skeleton-loader>
                    </div>
                    <div class="errorComments" *ngIf="errorMessages.length > 0">
                        <div class="header">
                            <span style="flex: 24px 0 0" class="material-icons">info</span>
                            <span style="flex: 30px 1 1"> &nbsp; Decision Tree Errors</span>
                            <span style="flex: 24px 0 0" class="material-icons midown" onclick="document.querySelector('.errorComments').classList.remove('hide')">arrow_drop_up</span>
                            <span style="flex: 24px 0 0" class="material-icons miup" onclick="document.querySelector('.errorComments').classList.add('hide')">arrow_drop_down</span>
                        </div>
                        <div class="content" style="position:absolute">
                            <div *ngFor="let i of errorMessages">
                                <div style="display:flex;align-items:center; padding:2px;margin:5px;">
                                    <span style="flex: 24px 0 0;" class="material-icons">circle</span>
                                    <span style="flex: 100px 1 1;"> &nbsp; {{i}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="questionSection" (click)="showPendingApprovalBlockEditSnak()" *ngIf="!isLoading">
                        <div *ngIf="errorMessages.length > 0" style="height:45px;">
                        </div>
                        <div *ngIf="questionSetControls.length == 0" style="text-align:center;background-color:white; width:100%;height:100%">
                            <br />
                            <button mat-button color="accent" (click)="addNewQuestionSet(0)">
                                <mat-icon>add</mat-icon> Add question
                            </button><br />
                            or<br />
                            <button mat-button (click)="openCatalog()" color="accent">
                                Choose from Questions Catalog
                            </button><br />
                        </div>
                        <div *ngFor="let qsc of questionSetControls">
                            <app-edit-questions-list [p]="this" [questionSetControl]="qsc" [questionSetRoutingList]="questionSetRoutingList" [attr.data-set-anchor]="qsc.GetQuestionSet()!.qsGuid"></app-edit-questions-list>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Decision">
                    <div class="questionSection" *ngIf="decisionTreeHeader!=undefined &&  decisionTreeHeader.decisionTreeHeaderId != undefined &&  settings != undefined">
                        <div *ngFor="let qsc of questionSetControls | sortByOrder">
                            <app-edit-question-decision-list [questionSetControl]="qsc"></app-edit-question-decision-list>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="overrideTabLabel" labelClass="override-tab-label">
                    <!--<mat-prefix>overrideList.</mat-prefix>-->
                    <div *ngIf="decisionTreeHeader!=undefined &&  decisionTreeHeader.decisionTreeHeaderId != undefined" class="overrideSection">
                        <!--{{overrideList?.errorMessages?.length}}-->
                        <app-override-list [decisionTreeOverrides]="decisionTreeOverrides" [settings]="settings"></app-override-list>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div style="position:absolute; top:0px; right:0px;display:flex; height:48px; align-items:center; justify-content:center;">
                <button mat-button (click)="openCatalog()" color="accent" style="height: 48px; ">
                    <mat-icon>search</mat-icon> Questions Catalog
                </button>
                <button mat-button color="accent" (click)="test()" style="height: 48px; ">
                    Test
                </button>
                <button mat-mini-fab color="accent" [matMenuTriggerFor]="editBurger"
                        style="width:40px; height:40px;z-index:5">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #editBurger="matMenu">
                    <button mat-button aria-label="View decision tree history" (click)="openHistory()">
                        <mat-icon fontIcon="history"
                                  style="font-size:20px; width:20px; height:20px;">
                        </mat-icon>
                        Version History
                    </button>
                    <br />
                    <button mat-button aria-label="View decision tree settings" (click)="openSettingsModal()">
                        <mat-icon fontIcon="settings"
                                  style="font-size:20px; width:20px; height:20px;">
                        </mat-icon>
                        Settings
                    </button>
                    <br />
                    <button mat-button aria-label="Switch View" (click)="switchView()" *ngIf="showGridView">
                        <mat-icon fontIcon="view_list"
                                  style="font-size:20px; width:20px; height:20px;">
                        </mat-icon>
                        Show Preview
                    </button>
                    <button mat-button aria-label="Switch View" (click)="switchView()" *ngIf="!showGridView">
                        <mat-icon fontIcon="account_tree"
                                  style="font-size:20px; width:20px; height:20px;">
                        </mat-icon>
                        Show Diagram
                    </button>
                    <br />
                    <button mat-button aria-label="Alter Approval Range" (click)="AlterApprovalRange(decisionTreeId)"
                            [disabled]="decisionTree == null || decisionTree.decisionTreeStatus==3">
                        <mat-icon fontIcon="edit_calendar" style="font-size:20px; width:20px; height:20px;">

                        </mat-icon>
                        Edit Approval Range
                    </button>
                    <br />
                    <button mat-button aria-label="Delete Tree" (click)="deleteUnapprovedTree(decisionTreeId)"
                            [disabled]="decisionTree == null || decisionTree.decisionTreeStatus==3" style="color: #AB2a03;">
                        <mat-icon fontIcon="delete"
                                  style="font-size:20px; width:20px; height:20px;">
                        </mat-icon>
                        Delete
                    </button>
                </mat-menu>
            </div>
        </div>




        <div *ngIf="!isLoading"
             class="decison_tree_actions" style="width:100%; text-align:right;margin-top:3px;">
            <button (click)="showDecisions()" style="display:none;">switch to decision view</button>
            <button (click)="showQuestions()" style="display:none;">switch to Questions view</button>
            <button id="btnResolveComments" style="display:none;" (click)="resolveComments()">Resolve Comments</button>
            <section *ngIf="decisionTree.decisionTreeStatus != undefined">
                <button mat-stroked-button color="accent" (click)="returnToDraft()"
                        *ngIf="decisionTree.decisionTreeStatus == 2 && selectedTabIndex != 2">
                    Return to Draft
                </button> &nbsp;
                <button mat-flat-button style="background-color:#7BF8D9" (click)="approve()"
                        *ngIf="decisionTree.decisionTreeStatus == 2 && selectedTabIndex != 2">
                    Approve
                </button> &nbsp;
                <button mat-stroked-button color="accent" (click)="submitForApproval()"
                        [disabled]="savingSet >0"
                        *ngIf="decisionTree.decisionTreeStatus == 1 && selectedTabIndex != 2">
                    Submit For Approval
                </button> &nbsp;
                <button mat-raised-button color="accent" (click)="save()"
                        [disabled]="savingSet >0"
                        *ngIf="decisionTree.decisionTreeStatus == 1 && selectedTabIndex != 2">
                    Save
                </button> &nbsp;
                <button mat-raised-button color="accent" (click)="save()"
                        *ngIf="decisionTree.decisionTreeStatus == 3 && selectedTabIndex != 2">
                    Edit
                </button> &nbsp;
                <button mat-raised-button color="accent" (click)="saveOverridesOnly()"
                        [disabled]="savingSet >0"
                        *ngIf="selectedTabIndex == 2">
                    Save Overrides
                </button> &nbsp;
                
                &nbsp; &nbsp; &nbsp;
            </section>



        </div>

    </div>
    <div class="mvr">
        <!--draggable="true" (dragstart)="dragStart($event)">
        <div class="dotz">
            ..<br />
            ..<br />
            ..<br />
        </div>-->
    </div>

    <!--<div id="rightSide" class=" rside">
        <div class="errorComments" *ngIf="errorMessages.length > 0">
            <div class="header">
                <span style="flex: 24px 0 0" class="material-icons">info</span>
                <span style="flex: 30px 1 1"> &nbsp; Decision Tree Errors</span>
                <span style="flex: 24px 0 0" class="material-icons midown" onclick="document.querySelector('.errorComments').classList.remove('hide')">arrow_drop_down</span>
                <span style="flex: 24px 0 0" class="material-icons miup" onclick="document.querySelector('.errorComments').classList.add('hide')">arrow_drop_up</span>
            </div>
            <div class="content" style="position:absolute">
                <div *ngFor="let i of errorMessages">
                    <div style="display:flex;align-items:center; padding:2px;margin:5px;">
                        <span style="flex: 24px 0 0; color: #ff4900; " class="material-icons">circle</span>
                        <span style="flex: 100px 1 1;"> &nbsp; {{i}}</span>
                    </div>
                </div>
            </div>
        </div>
        <app-edit-questions-tree [p]="this"></app-edit-questions-tree>
    </div>-->


    <div id="rightSide" class=" rside">
        <div *ngIf="!isLoading">

            <app-edit-questions-tree [p]="this" *ngIf="showGridView"></app-edit-questions-tree>

            <app-inline-question-test [p]="this" *ngIf="!showGridView"></app-inline-question-test>
        </div>
    </div>

    <div id="history" class="history-side-bar hide-history" style="">
        <div style="display:flex; align-items:center; width:100%; border-bottom:1px solid #d1c2cb;">
            <div style="font-size: 20px; display: flex; flex: 1px 1 1;">Version History</div>
            <button mat-icon-button style="display: flex; flex: 24px 0 0;" (click)="closeHistory()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <button mat-flat-button color="primary" (click)="restoreVersion()"
                [disabled]="treeIsHistoric ? null : true" style="margin:8px 16px 8px 16px;border-radius:26px">
            Restore Version
        </button>
        <div class="version-item-list">

            <span *ngIf="treeIsCurrent" style="color:#800088; margin-left:14px;">Current Version</span>
            <ul>
                <section *ngFor="let v of historyRecords | sortByMajorVersion">
                <li (click)="switchVersion(v)"
                    *ngIf="v.majorVersion == decisionTree.majorVersion"
                    [className]=" v.decisionTreeID == decisionTree.decisionTreeID? 'current-version-item' : 'version-item'" title="{{v.decisionTreeID}} - {{v.startDate  | date : 'MMM d, yyyy'}} to {{v.endDate | date : 'MMM d, yyyy'}}">
                    <div *ngIf="v.decisionTreeStatus != 3"
                         style="position:absolute; top:0px; right:0px;">
                        <mat-icon (click)="deleteUnapprovedTree(v.decisionTreeID)" style="cursor:pointer;">delete</mat-icon>
                    </div>
                    <b>{{v.updateDate | date : 'MMM d, yyyy h:mm a'}}  </b><br />
                    {{v.majorVersion}}.{{v.minorVersion}}
                    <span [class]="'status-pill ' + (v.decisionTreeStatus | statusClass: v.startDate : v.endDate )">
                        {{ v.decisionTreeStatus | status: v.startDate: v.endDate}}
                    </span><br />
                    {{v.userIdentifier}}<br />
                    {{v.comments}}
                </li>
                </section>
            </ul>
        </div>
    </div>
</div>

<div id="interactiveQuestionAnswerModal">
    <div id="modalOperationControls">
        <button mat-icon-button color="accent" class="btn btn-sm btn-outline-primary" onclick='hideReviewQuestionArea()'>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>

    <div id="interactiveQuestionAnswerSection"></div>
    <hr />
    <div class="simpleright">
        <button class="reset-button" onclick='resetReviewQuestionArea()'>
            Reset
        </button>
        <button class="close-button" onclick='hideReviewQuestionArea()'>
            Close
        </button>
    </div>
    <!--<button onclick='alert(JSON.stringify(getDecisionTreeAnswers())); return false;'>Test Save</button>-->
</div>
