<div class="table-dashboard">

    <div class="column-8 filter-section" style="margin-top: 10px; margin-right: 10px;">
        <section class="column-5">
            <mat-form-field appearance="outline">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchInput" placeholder="Ex. Name of PCN" #input>
            </mat-form-field>
        </section>

        <section class="column-5">
            <mat-form-field appearance="outline">
                <mat-label>Client</mat-label>
                <mat-select [(ngModel)]="selectedClient" name="selectedClient" (selectionChange)="onSelectClient()">
                    <mat-option *ngFor="let c of clientsList" [value]="c">
                        {{c.clientName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
    </div>
    <div class="column-right" style="margin-top: 10px; margin-right: 10px;">
        <button mat-flat-button color="accent" class="createbutton" (click)="openCreateJustificationModal()">
            <span class="material-icons">add</span>
            Create Justification
        </button>
    </div>
   
    <br /><br />

    <section>
        <div class="">
            <table mat-table [dataSource]="dataSource" matSort>

                <!-- desc Column -->
                <ng-container matColumnDef="desc" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.JustificationDescription}} </td>
                </ng-container>

                <!--decision Column-->
                <ng-container matColumnDef="decision" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Decision </th>
                    <td mat-cell *matCellDef="let row"> {{row.JustificationDecision}} </td>
                </ng-container>

                <!--client Column-->
                <ng-container matColumnDef="client" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                    <td mat-cell *matCellDef="let row"> {{row.ClientID}} </td>
                </ng-container>

                <!--line of business Column-->
                <ng-container matColumnDef="lob" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Line of Business </th>
                    <td mat-cell *matCellDef="let row"> {{row.PCNDescription}} </td>
                </ng-container>

                <!--action Column-->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="right-align">
                            <button mat-button [matMenuTriggerFor]="tableactionmenu" (click)="onActionButtonClick($event)">
                                <span class="material-icons table-more-icon" style="font-size: 1.75em;">more_vert</span>
                            </button>
                            <mat-menu #tableactionmenu="matMenu" class="column-3">
                                <button mat-button color="primary" class="full-width" (click)="openAddJustificationViewModeModal(row.JustificantionTemplateID)">View</button>
                                <button mat-button color="primary" class="full-width" (click)="openDeleteJustificationModal(row.JustificantionTemplateID)">Delete</button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of justifications"></mat-paginator>
        </div>
    </section>
</div>