

<div class="question"
     [attr.data-questionid]="question.id"
     [attr.data-languageguid]="question.languageGuid">
    <div class="column-3">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label for="label">
                {{question.internalOrder}} <!--{{question.id}}-->
                <span *ngIf="GetQuestionType() == 'Select'">
                    {{GetSelectType().isMultiple ? "Multi-Select" : "Select"}}
                </span>
                <span *ngIf="GetQuestionType() == 'FreeText'">
                    Free Text
                </span>
                <span *ngIf="GetQuestionType() == 'Date'">
                    {{GetDateType().isDateTimeRequired ? "Date Time" : "Date"}}
                </span>
                <span *ngIf="GetQuestionType() == 'Numeric'">
                    Numeric
                </span>
            </mat-label>
            <input matInput type="text" class="full-width" id="label"
                   required
                   [(ngModel)]="question.label" name="label" />
        </mat-form-field>
    </div>
    <div class="column-1">
        <!--<mat-form-field class="full-width">
            <mat-label for="level">Level</mat-label>
            <input matInput type="number" class="full-width" id="level"
                   required
                   [(ngModel)]="question.level" name="level">
        </mat-form-field>-->
    </div>
    <div class="column-4">
    </div>
    <div class="column-3">
        <label>Default Next:</label>
        <br />
        <button  mat-flat-button color="accent" class="fake-select" 
                (click)="openRoutingModal()">
            {{GetNextQuestionLabel()}} <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>
    </div>
    <div class="column-1">
        <button mat-flat-button [matMenuTriggerFor]="qmenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #qmenu="matMenu">
            <button class="full-width" mat-raised-button (click)="openActionResultModal()">Add Action</button>
            <button class="full-width" mat-raised-button (click)="openCodedReferenceModal()">Add Coded Reference</button>
            <button class="full-width" *ngIf="question.questionComment===undefined || question.questionComment.length == 0"
                    mat-raised-button (click)="openCommentText()">
                Add Internal Comment
            </button>

            <section *ngIf="GetQuestionType() == 'Select'">
                <section *ngIf="GetSelectType().isMultiple">
                    <button class="full-width" mat-raised-button (click)="AddNextQuestionCondition('Single')">Add Single Compare</button>
                    <button class="full-width" mat-raised-button (click)="AddNextQuestionCondition('Range')">Add Range Compare</button>
                </section>
            </section>
            <section *ngIf="GetQuestionType() == 'Numeric'">
                <button class="full-width" mat-raised-button  (click)="AddNextQuestionCondition('Single')">Add Single Compare</button>
                <button class="full-width" mat-raised-button  (click)="AddNextQuestionCondition('Range')">Add Range Compare</button>
            </section>
            <section *ngIf="GetQuestionType() == 'Date'">
                <button class="full-width" mat-raised-button  (click)="AddNextQuestionCondition('Single')">Add Single Compare</button>
                <button class="full-width" mat-raised-button  (click)="AddNextQuestionCondition('Range')">Add Range Compare</button>
            </section>
            <button class="full-width" mat-flat-button color="warn" (click)="RemoveQuestion()">Remove</button>
        </mat-menu>
    </div>
    <mat-form-field class="full-width" appearance="outline">
        <mat-label for="text">Question Text</mat-label>
        <input matInput type="text" class="full-width" id="text"
               required
               [(ngModel)]="question.text" name="text">
    </mat-form-field>
    <div *ngIf="(question.text.length ?? 0) < 3" class="alert alert-warning">
            Question text must be filled in.
        </div>
    <mat-form-field class="full-width" appearance="outline"
        *ngIf="question.questionComment!==undefined && question.questionComment.length >= 1"
    >
        <mat-label for="questionComment">Comment for internal users only</mat-label>
        <input matInput type="text" class="full-width" id="questionComment"
               required
               [(ngModel)]="question.questionComment" name="questionComment">
    </mat-form-field>
    <span *ngFor="let cr of question.codedReference" style="width:unset;margin:4px;">
        <app-codedreference-template [codedReference]="cr" [p]="question"></app-codedreference-template>
    </span>
    <div *ngFor="let ar of question.action" style="width: unset; margin: 4px;">
        <app-actionresult-template [actionResult]="ar" [p]="this"></app-actionresult-template>
    </div>
    <div *ngIf="GetQuestionType() == 'Select'">
        <div class="row">
            <div class="column-6">Answer Text</div>
            <div class="column-2">Addl. Text</div>
            <div class="column-2">Override Next</div>
            <div class="column-2"></div>
        </div>
        <div *ngFor="let c of GetSelectType().choice">
            <app-answerchoice-template [answerChoice]="c" [p]="this" [questionSetControl]="questionSetControl"></app-answerchoice-template>
        </div>
        <div *ngIf="GetSelectType().choice.length < 2" class="alert alert-warning">
            Select options must have at least 2 choices. <button class="btn btn-sm btn-primary " (click)="AddAnswer()">Add Answer</button>
        </div>
        <button mat-raised-button color="primary" (click)="AddAnswer()">Add Answer</button>
        <!--<div *ngIf="GetSelectType().nextQuestionCondition.length > 0">
        <p style="margin-left:4vw;">Comparison based routing</p>
    </div>-->
        <div *ngFor="let nqc of GetSelectType().nextQuestionCondition; ; let idx=index" class="">
            <app-nextquestioncondition-template [nextQuestionCondition]="nqc" [comparisonSource]="GetQuestionType()" [question]="question" [questionSetControl]="questionSetControl"  [orderIndex]="idx" [questionSetRoutingList]="questionSetRoutingList"></app-nextquestioncondition-template>
        </div>
    </div>
    <div *ngIf="GetQuestionType() == 'Date'">
        <div *ngFor="let nqc of  GetDateType().nextQuestionCondition; ; let idx=index" class="">
            <app-nextquestioncondition-template [nextQuestionCondition]="nqc" [comparisonSource]="GetQuestionType()" [timerequired]="GetDateType().isDateTimeRequired" [question]="question" [questionSetControl]="questionSetControl"  [orderIndex]="idx" [questionSetRoutingList]="questionSetRoutingList"></app-nextquestioncondition-template>
        </div>
    </div>
    <div *ngIf="GetQuestionType() == 'Numeric'">
        <div *ngFor="let nqc of  GetNumericType().nextQuestionCondition; ; let idx=index" class="">
            <app-nextquestioncondition-template [nextQuestionCondition]="nqc" [comparisonSource]="GetQuestionType()" [question]="question" [questionSetControl]="questionSetControl"  [orderIndex]="idx" [questionSetRoutingList]="questionSetRoutingList"></app-nextquestioncondition-template>
        </div>
    </div>

</div>

