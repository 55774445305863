import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-edit-question-display',
  templateUrl: './edit-question-display.component.html',
  styleUrls: ['./edit-question-display.component.scss']
})
export class EditQuestionDisplayComponent {
    @Input() p: any;
}
