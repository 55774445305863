<section>
    <justification-detail [justification]="justification" [clients]="clients"></justification-detail>
</section>
<section>
    <justification-case [justification]="justification"></justification-case>
</section>
<section>
    <justification-determination [justification]="justification"></justification-determination>
</section>
<mat-divider></mat-divider>
<section>
    <justification-decisions [justification]="justification"></justification-decisions>
</section>