import { Component } from '@angular/core';

@Component({
  selector: 'app-hover-menu',
  templateUrl: './hover-menu.component.html',
  styleUrls: ['./hover-menu.component.scss']
})
export class HoverMenuComponent {
    public timedOutCloser :any;

    constructor() { }

    mouseEnter(trigger: any) {
        if (this.timedOutCloser) {
            clearTimeout(this.timedOutCloser);
        }
        trigger.openMenu();
    }

    mouseLeave(trigger: any) {
        this.timedOutCloser = setTimeout(() => {
            trigger.closeMenu();
        }, 50);
    }
}
