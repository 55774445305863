import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { ApiPaths } from '../environments/routes';
import { environment } from '../environments/environments';
import { User } from '../../classes/user';
import { Client } from '../../classes/client';
import { SortService } from '../services/sort.service';
import { ApplicationStatus } from '../../classes/questionset';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './auth.config';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy, OnInit {
    user: User = new User;
    token: string = "";
    oauthData: any;
    checkSessionChanged$!: Observable<boolean>;
    checkSessionChanged: any;
    showConfig!: boolean;
    private localStorageKey = 'userLoginInfo';

    constructor(
        private http: HttpClient,
        private sortService: SortService,
        private oauthService: OAuthService,
        private router: Router
    ) {}

    ngOnInit(): void { }

    ngOnDestroy(): void { }

    public configureOAuth() {
        this.oauthService.events.subscribe(error => { console.log(error); })
        this.oauthService.configure(authConfig);
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        this.oauthService.loadDiscoveryDocumentAndLogin({
        }).then((success) => {
            if (success) {
                this.oauthService.setupAutomaticSilentRefresh();
                this.router.initialNavigation();
                this.navi();
            }
        });
        //{ disableOAuth2StateCheck: true }
    }
    
    public navi() {
        if (this.getUserLoginInfo() == null || Object.keys(this.user).length == 0) {
            this.getUserInfo().subscribe(
                d => {
                    this.updateAuthenticationStatus(true);
                    this.checknav();
                });
        } else {
            this.updateAuthenticationStatus(true);
            this.checknav();
        }
    }

    public checknav() {
        //debugger;
        //console.log(window.location.pathname);
        if (window.location.pathname == "/") {
            this.router.navigate(["/decisiontree"]);
        }
  
    }
    public login() {
        if (this.isAuthenticated()) {
//            this.getUserInfo().subscribe();
        } else {
            this.initCodeFlowAsync();
            //.then(() => {
            //    this.getUserInfo().subscribe();
            //});
        }
    }

   initCodeFlowAsync() {
    return new Promise<void>((resolve, reject) => {
        this.oauthService.initCodeFlow();
        setTimeout(() => {
            resolve();
        }, 2000);
    });
}

    public logout() {
        this.oauthService.logOut();
    }

    public getToken() {
        return this.oauthService.getAccessToken();
    }

    public isAuthenticated(): boolean {
        return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
    }

    setUserLoginInfo(loginInfo: User): void {
        localStorage.setItem(this.localStorageKey, JSON.stringify(loginInfo));
    }

    getUserLoginInfo(): User {
        const loginInfo = localStorage.getItem(this.localStorageKey);
        if (loginInfo == null || Object.keys(this.user).length == 0) {
            
            this.getUserInfo().subscribe(
                d => { this.checknav(); }
            );
        }
        return loginInfo ? JSON.parse(loginInfo) : null;
    }

    clearUserLoginInfo(): void {
        localStorage.removeItem(this.localStorageKey);
    }

    setClient(id: string) {
        this.user.lastSelectedClientID = id;
        this.setUserLoginInfo(this.user);
    }

    getUserInfo(): Observable<any> {
        return this.getCurrentUser().pipe(
            map(resp => {
                // Process the response and return the user object or necessary data
                const user = new User();
                this.user.userName = resp.body['userName'].toString();
                this.user.id = parseInt(resp.body['id'].toString());
                var clients = this.mapClientResponse(resp, true);
                //remove duplicate clients
                clients = clients.filter((obj, index, self) =>
                    index === self.findIndex((o) => o.clientID === obj.clientID)
                );
                //sort the clients by name
                clients = clients?.sort(this.sortService.sortByMultiple(['clientName'], 'asc'));
                if (this.user.id) {
                    this.user.clients = clients;
                    this.user.lastSelectedClientID = clients[0].clientID;
                    //console.log(this.user);
                }
                
                this.setUserLoginInfo(this.user);
                return this.user;
            })
        );
    }

    getUserObject() {
        return this.user;
    }

    getCurrentUser(): Observable<HttpResponse<any>> {
        //var httpHeaders;

        //var token = this.getToken();
        //httpHeaders = new HttpHeaders({
        //    'Content-Type': 'text/plain',
        //    'Authorization': `Bearer ${token}`,
        //});
        //headers: httpHeaders,

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetCurrentUserInfo, {  observe: 'response' },);
    }

    public getApplicationList(): Observable<HttpResponse<any>> {
        var httpHeaders = new HttpHeaders();
        var queryParams = new HttpParams();
            return this.http.get<any>(
                environment.baseUrl + ApiPaths.GetApplicationList, { headers: httpHeaders, params: queryParams, observe: 'response' }
            );
    }
    public mapClientResponse(response: HttpResponse<any>, fromUser = false) {
        const clients: Client[] = [];
        if (fromUser) {
            for (var [k, v] of Object.entries(response.body['clientInfoList'])) {
                const c = new Client();
                if (v instanceof Object) {
                    c.clientID = v['clientID' as keyof typeof v].toString();
                    c.clientName = v['clientName' as keyof typeof v].toString();
                    clients.push(c);
                }
            }
        } else {
            for (var [k, v] of Object.entries(response.body)) {
                const c = new Client();
                if (v instanceof Object) {
                    c.clientID = v['clientID' as keyof typeof v].toString();
                    c.clientName = v['clientName' as keyof typeof v].toString();
                    clients.push(c);
                }
            }
        }

        return clients;
    }

    getPermissions() {
        return this.http.get(`${environment.securityApiUrl}/applications/${environment.securityApplicationId}/user/permissions/${environment.securityModuleId}`).pipe(catchError((err) => { console.log(err); return throwError(() => err) }));
    }

    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

    // Method to update authentication status
    updateAuthenticationStatus(isAuthenticated: boolean) {
        this.isAuthenticatedSubject.next(isAuthenticated);
    }

    // Observable for authentication status changes
    get isAuthenticated$(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }
}




