export class Hierarchy {
    isSelected: boolean = false;
    hierarchyId: number | undefined;
    clientCompany: string | undefined;
    lobPcn: string | undefined;
    pcn: string | undefined;
    pcnDescription: string | undefined;
    benefitPlanId: string | undefined;
    plan: string | undefined;
    benefitGroupId: string | undefined;
    group: string | undefined;
    desc: string | undefined;
    activeLinks: string | undefined;
    futureLinks: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    ties: HierarchyTies[] | undefined;
}

export class HierarchyTies {
    hierarchyId: number | undefined;
    clientName: string | undefined;
    pcn: string | undefined;
    pcnDescription: string | undefined;
    planDescription: string | undefined;
    groupName: string | undefined;
    decisionTreeHeaderID: number | undefined;
    decisionTreeName: string | undefined;
    drugIDQualifier: string | undefined;
    drugID: string | undefined;
    caseType: string | undefined;
    caseSubType: string | undefined;
    determinationLevel: string | undefined;
    tier: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
}


