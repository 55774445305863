import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DecisionTree, DecisionTreeHeader, VersionHistoryItem } from '../../classes/decisiontree';
import { ApplicationStatus, Question, QuestionLanguage, QuestionSet, QuestionTypeSelect } from '../../classes/questionset';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { QuestionSetControl, QuestionSetRouting } from '../../shared/question-set.control';
import { AuthService } from '../../shared/auth/auth.service';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { DecisionTreeService } from '../decision-tree.service';
import { EditQuestionsCatalogModalComponent } from '../edit-questions-catalog-modal/edit-questions-catalog-modal.component';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { EditQuestionSettingsModalComponent } from '../edit-question-settings-modal/edit-question-settings-modal.component';
import { ApproveDialogComponent } from '../../shared/approve-dialog/approve-dialog.component';
import { TestDecisiontreeModalComponent } from '../test-decisiontree-modal/test-decisiontree-modal.component';
import { EditHistoryModalComponent } from '../edit-history-modal/edit-history-modal.component';
import { CommentDialogComponent } from '../../shared/comment-dialog/comment-dialog.component';
import { DeleteConfirmDialogComponent } from '../../shared/delete-confirm-dialog/delete-confirm-dialog.component';
import { DecisionTreeSettings } from '../../classes/decisiontreesettings';
import { ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { DecisionTreeOverride, DecisionTreeOverrideRequest } from '../../classes/overrides';
import { request } from 'http';
import { OverrideListComponent } from '../override-list/override-list.component';


declare var startQuestionSetReview: any;
//declare var svgHandler: any;


@Component({
    selector: 'app-edit-questions',
    templateUrl: './edit-questions.component.html',
    styleUrls: ['./edit-questions.component.scss'],
})
export class EditQuestionsComponent implements OnInit {
    public decisionTree!: DecisionTree;
    public decisionTreeHeader!: DecisionTreeHeader;
    public decisionTreeOverrides: DecisionTreeOverride[] = [];
    //public svgQuestionDisplay: SVGQuestionDisplay;
    public decisionTreeId: number = 0;
    public questionSetRoutingList: QuestionSetRouting[] = [];
    public questionSetControls: QuestionSetControl[] = [];
    public settings: DecisionTreeSettings | undefined;
    public selectedTabIndex: number = 0;
    public errorMessages: string[] = [];
    public applications: ApplicationStatus[] = [];
    private activeComments: number[] = [];
    public historyRecords: VersionHistoryItem[] = [];
    public treeIsCurrent: boolean = false;
    public treeIsHistoric: boolean = false;
    public currentVersion: number | undefined;
    public savingSet: number = 0;
    public asRequestID: any = null;
    private autoSaveDelay = 60 * 15 * 1000;
    public isLoading: boolean = true;
    public showGridView: boolean = false;
    public overrideTabLabel: string = "Override";
    @ViewChild(OverrideListComponent) overrideList!: OverrideListComponent;
    constructor(
        private router: Router,
        private location: Location,
        private e: ElementRef,
        private route: ActivatedRoute,
        private authService: AuthService,
        public questionSetService: QuestionSetService,
        public decisiontreeService: DecisionTreeService,
        public hierarchyService: HierarchyService,
        public questionSetDashboardService: QuestionSetDashboardService,
        private dialog: MatDialog
    ) {
        //this.svgQuestionDisplay = new SVGQuestionDisplay();
    }
    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            this.decisionTreeId = params['dt'];
        });
        this.authService.getApplicationList().subscribe(resp => {
            if (resp.body) {
                this.applications = [];
                var a = [];
                a = resp.body;
                a.forEach((e: any) => { this.applications.push(new ApplicationStatus(e)) });
            }
            this.getDecisionTree();
            //this.getDecisionTreeToQuestionSets();
        });
        this.setErrorMessageList();
        // temp use for accessing tabs during development
        //this.selectedTabIndex = 2;
    }
    getTreeSettings() {
        this.decisiontreeService.getDecisionTreeSettingsByID(this.decisionTreeHeader.decisionTreeHeaderId!).subscribe(resp => {
            if (resp.body) {
                this.settings = this.decisiontreeService.mapDecisionTreeSettingsFromDecisionTree(resp);
                this.questionSetControls.forEach(qsc => qsc.settings = this.settings);
            }
        });
    }
    resolveComments() {
        //document.querySelector(".draftComments")?.remove();
        document.querySelector(".draftComments")?.remove();
        this.activeComments.forEach(commentId => {
            this.decisiontreeService.DeactivateAdditionalComment(commentId)
                .subscribe(resp => {
                    if (resp.body.result != 1) {
                        alert("an error occurred in deactivating the comment")
                    }
                });
        });
        this.activeComments = [];
    }
    showReturnedToDraftDialog(cl: any[]) {
        if (cl.length > 0) {
            document.querySelector(".draftComments")?.remove();
            var content = '<div class="content">Reason<br />';
            cl.forEach((fec) => {
                var d = new Date(fec.updateDate);
                content += fec.comment + "<br />"
                    + "<span style='font-size: 12px;margin-top:5px;'>" + fec.userIdentifier + " " + d.toLocaleDateString() + " at " + d.toLocaleTimeString() + "</span><br />";
                this.activeComments.push(fec.additionalCommentID)
            });
            content += '</div>';
            var dc = document.createElement("div");
            dc.innerHTML = '<div class="header">'
                + '<span style="flex: 24px 0 0" class="material-icons">info</span>'
                + '<span style="flex: 30px 1 1">This decision tree was returned to Draft</span>'
                + '<span style="flex: 60px 0 0; color:#800088; cursor:pointer;" onclick="document.getElementById(\'btnResolveComments\').click()">Resolved</span>'
                + '<span style="flex: 24px 0 0" class="material-icons midown" onclick="document.querySelector(\'.draftComments\').classList.remove(\'hide\')">arrow_drop_up</span>'
                + '<span style="flex: 24px 0 0" class="material-icons miup" onclick="document.querySelector(\'.draftComments\').classList.add(\'hide\')">arrow_drop_down</span>'
                + '</div>';
            dc.innerHTML += content;
            dc.classList.add("draftComments");
            var rs = document.getElementById("rightSide");
            rs!.insertBefore(dc, rs!.firstChild);
        }
    }
    showNewlyCreatedSnak() {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.innerHTML = '<span class="material-icons">check_circle</span><div class="snakcontent">Decision Tree Created!</div><span class="material-icons postfix" onclick="document.querySelector(\'.fakesnak\').remove()">cancel</span>';
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
        setTimeout(function () {
            document.querySelector(".fakesnak")?.remove();
        }, 15000);
    }
    showApprovedSnak() {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.innerHTML = '<span class="material-icons">check_circle</span>'
            + '<div class="snakcontent">'
            + 'Decision Tree Has been Approved</div>'
        //+ '<span class="material-icons postfix" onclick="document.querySelector(\'.fakesnak\').remove()">cancel</span>';
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
        setTimeout(function () {
            document.querySelector(".fakesnak")?.remove();
        }, 4000);
    }
    showPendingApprovalSnak() {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.style.backgroundColor = '#000';
        fakesnak.style.color = '#fff';
        fakesnak.innerHTML = ''
            + '<div class="snakcontent" style="font-size:14px;">'
            + 'Decision Tree is now Pending Approval</div>';
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
        var t1 = new Date().getTime();
        /*console.warn("triggering timeout.......................", t1);*/
        setTimeout(function () {
            var t2 = new Date().getTime();
            //console.warn("timeout happened.......................", t2, ((t2 - t1) / 1000));
            document.querySelector(".fakesnak")?.remove();
        }, 4000);
    }
    showPendingApprovalBlockEditSnak() {
        if (this.decisionTree.decisionTreeStatus != 1) {
            document.querySelector(".fakesnak")?.remove();
            var fakesnak = document.createElement("div");
            fakesnak.style.backgroundColor = '#000';
            fakesnak.style.color = '#fff';
            if (this.decisionTree.decisionTreeStatus != 2) {
                fakesnak.innerHTML = ''
                    + '<div class="snakcontent" style="font-size:14px;">'
                    + 'To edit the tree, click "Return to Draft"</div>'
                    + '<span class="material-icons postfix" style="background-color:#800088" onclick="document.querySelector(\'.fakesnak\').remove()">close</span>';
            } else {
                fakesnak.innerHTML = ''
                    + '<div class="snakcontent" style="font-size:14px;">'
                    + 'To edit the tree, click "Edit"</div>'
                    + '<span class="material-icons postfix" style="background-color:#800088" onclick="document.querySelector(\'.fakesnak\').remove()">close</span>';
            }
            fakesnak.classList.add("fakesnak");
            document.body.appendChild(fakesnak);
        }
    }
    showRemovedSetClearedPathwaySnak(changeType: string) {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.style.backgroundColor = '#000';
        fakesnak.style.color = '#fff';
        fakesnak.innerHTML = ''
            + '<div class="snakcontent" style="font-size:14px;">'
            + "Pathway links to " + changeType + " set have been cleared."
            + '</div>'
            + '<span class="material-icons postfix" style="background-color:#800088" onclick="document.querySelector(\'.fakesnak\').remove()">close</span>';
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
    }
    showDecisions() {
        this.selectedTabIndex = 1;
    }
    showQuestions() {
        this.selectedTabIndex = 0;
    }
    removeQuestionSet(order: number) {
        if (this.decisionTree.decisionTreeStatus != 1) {
            this.showPendingApprovalBlockEditSnak();
            return;
        }
        var cleared = false;
        var removing = this.questionSetControls.filter
            ((qsc: QuestionSetControl) => { return qsc.initialOrder == order; });
        var qsName = removing[0].GetQuestionSet()!.questionSetHeader!.title;
        this.questionSetControls.forEach((qsc: QuestionSetControl) => {
            qsc.clearLines();
        });
        this.questionSetControls = this.questionSetControls.filter
            ((qsc: QuestionSetControl) => { return qsc.initialOrder != order; });
        this.questionSetControls.forEach((qsc: QuestionSetControl) => {
            if (qsc.ClearNextSetTo(removing[0].GetQuestionSet()!.qsGuid ?? "")) {
                cleared = true;
            }
            if (qsc.initialOrder! > order) { --qsc.initialOrder!; }
        });
        this.saveDecisionTreeAdditions([], cleared);
        this.setInternalOrderOffsets();
        this.setQuestionSetRouting();
        if (cleared) {
            this.showRemovedSetClearedPathwaySnak("removed");
        }
    }
    moveQuestionSet(order: number, direction: string) {
        
        if (this.decisionTree.decisionTreeStatus != 1) {
            this.showPendingApprovalBlockEditSnak();
            return;
        }
        var qscTarget: QuestionSetControl;
        var cleared = false;
        if (direction == "up") {
            if (order == 1) return;
            this.questionSetControls.forEach((qsc: QuestionSetControl) => {
                qsc.clearLines();
            });
            let a = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == order; });
            qscTarget = a[0];
            let b = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == (order - 1); });
            if (a.length && b.length == 1) {
                a[0].initialOrder! -= 1;
                b[0].initialOrder! += 1;
                if (b[0].ClearNextSetTo(a[0].GetQuestionSet()!.qsGuid ?? "")) {
                    cleared = true;
                }
                this.saveDecisionTreeAdditions([], cleared);
            }
        }
        if (direction == "down") {
            if (order == (this.questionSetControls.length + 1)) return;
            this.questionSetControls.forEach((qsc: QuestionSetControl) => {
                qsc.clearLines();
            });
            let a = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == order; });
            qscTarget = a[0];
            let b = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == (order + 1); });
            if (a.length && b.length == 1) {
                a[0].initialOrder! += 1;
                b[0].initialOrder! -= 1;
                if (a[0].ClearNextSetTo(b[0].GetQuestionSet()!.qsGuid ?? "")) {
                    cleared = true;
                }
                this.saveDecisionTreeAdditions([], cleared);
            }
        }
        this.setInternalOrderOffsets();
        this.setQuestionSetRouting();
        
        
        if (cleared) {
            this.showRemovedSetClearedPathwaySnak("moved");
        }
        var selector = '[data-set-anchor="' + qscTarget!.GetQuestionSet()?.qsGuid + '"]';
        var setAnchorElement = document.querySelector(selector);
        setTimeout(() => {
            if (setAnchorElement != null) {
                setAnchorElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
            }, 50);
         
    }
    copyQuestionSet(order: number) {
        var currentQS = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == order; })[0].GetQuestionSet();
        var nameCopy = currentQS!.questionSetHeader?.title ?? "";
        if (nameCopy.length > 38) {
            nameCopy = nameCopy.substring(0, 37);
        }
        nameCopy += " Copy";

        var qCopy = JSON.stringify(currentQS!.questions);
        qCopy = qCopy.replaceAll("TextGuid", "0000");
        var data: any = {
            "questionSetHeader": {
                id: 0,
                title: nameCopy + " " + new Date().toISOString(),
                description: "",
                attachmentRequired: false
            },
            "questions": JSON.parse(qCopy)
        };
        var qsCopy = new QuestionSet(data);
        
        var questionsJson = JSON.stringify(qsCopy)
        this.questionSetDashboardService.addQuestionSet(
            this.decisionTreeHeader.clientID!,
            nameCopy,
            "Exclusive question set for  " + this.decisionTreeHeader.decisionTreeName,
            0
        ).subscribe(
            (data) => {
                if (data.status == 200) {
                    if (data.body.result != -1) {
                        let ioi = 1;
                        var nqsOrder = 999;
                        //insert between
                        this.addExistingQuestionSetToTree(data.body.result, order + 1, nameCopy, questionsJson!); //Name copy----
                        var questionList: any[] = [];

                        this.questionSetControls
                            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
                            .forEach(qsc => {
                                var newOrder = ioi++;
                                if (newOrder == order + 1) {
                                    nqsOrder = newOrder;
                                    questionList.push({ questionSetID: data.body.result, InitialOrder: newOrder }); newOrder = ioi++;
                                }
                                qsc.initialOrder = newOrder;
                                questionList.push({ questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id, InitialOrder: newOrder })
                            });
                        var newOrder = ioi++;
                        if (newOrder == order + 1) {
                            nqsOrder = newOrder;
                            questionList.push({ questionSetID: data.body.result, InitialOrder: newOrder });
                        }
                        this.setInternalOrderOffsets();
                        this.setQuestionSetRouting();
                        this.saveOrderedDecisionTree(questionList);
                    } else {
                        alert(data.body.message);
                    }
                }
            },
            (err) => { console.log(err) }, () => console.log("Done")
        );
    }
    addNewQuestionSet(setOrder: number) {
        //console.log("Add New Question Set");
        var name = this.decisionTreeHeader.decisionTreeName!;
        if (name.length > 44) {
            name = name.substring(0, 43);
        }
        this.questionSetDashboardService.addQuestionSet(
            this.decisionTreeHeader.clientID!,
            name + " " + new Date().toISOString(),
            "Exclusive question set for  " + this.decisionTreeHeader.decisionTreeName,
            0
        ).subscribe(
            (data) => {
                if (data.status == 200) {
                    if (data.body.result != -1) {
                        let ioi = 1;
                        //insert between
                        this.addExistingQuestionSetToTree(data.body.result, setOrder + 1, " "); // No Name
                        var questionList: any[] = [];
                        //debugger;
                        this.questionSetControls
                            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
                            .forEach(qsc => {
                                var newOrder = ioi++;
                                if (newOrder == setOrder + 1) {
                                    questionList.push({questionSetID: data.body.result,InitialOrder: newOrder});newOrder = ioi++;
                                }
                                qsc.initialOrder = newOrder;
                                questionList.push({questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id,InitialOrder: newOrder})
                            });
                        //debugger;
                        var newOrder = ioi++;
                        if (newOrder == setOrder + 1) {
                            questionList.push({questionSetID: data.body.result,InitialOrder: newOrder});
                        }
                        this.setQuestionSetRouting();
                        this.setInternalOrderOffsets();
                        //debugger;
                        this.saveOrderedDecisionTree(questionList);
                    } else {
                        alert(data.body.message);
                    }
                }
            },
            (err) => { console.log(err) }, () => console.log("Done")
        );
    }

    splitQuestionSet(setOrder: number, questionOrder: number) {
        var currentQSControl = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == setOrder; })[0]
        var currentQS = currentQSControl.GetQuestionSet();
        var splitName = currentQS!.questionSetHeader?.title ?? "";
        if (splitName.length > 38) {
            splitName = splitName.substring(0, 37);
        }
        splitName += " split ";

        var qCopy = JSON.stringify(currentQS!.questions);
        var splitData: Question[] = JSON.parse(qCopy);
        splitData = splitData.filter(f => { return f.internalOrder > questionOrder });
        //qCopy = qCopy.replaceAll("TextGuid", "0000");
        var data: any = { "questionSetHeader": { id: 0, title: splitName, description: "", attachmentRequired: false }, "questions": splitData };
        var qsCopy = new QuestionSet(data);

        var questionsJson = JSON.stringify(qsCopy)
      
        this.questionSetDashboardService.addQuestionSet(
            this.decisionTreeHeader.clientID!,
            splitName + " " + new Date().toISOString(),
            "Exclusive question set for  " + this.decisionTreeHeader.decisionTreeName,
            0
        ).subscribe(
            (data) => {
                if (data.status == 200) {
                    let questionSetSplitID = data.body.result;
                    if (questionSetSplitID != -1) {

                        let ioi = 1;
                        var nqsOrder = 999;
                        //insert between
                        this.addExistingQuestionSetToTree(questionSetSplitID, setOrder + 1, " ", questionsJson!);// No name ----
                        var questionList: any[] = [];

                        this.questionSetControls
                            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
                            .forEach(qsc => {
                                var newOrder = ioi++;
                                if (newOrder == setOrder + 1) {
                                    nqsOrder = newOrder;
                                    questionList.push({ questionSetID: questionSetSplitID, InitialOrder: newOrder });
                                    newOrder = ioi++;
                                }
                                qsc.initialOrder = newOrder;
                                questionList.push({ questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id, InitialOrder: newOrder })
                            });
                        var newOrder = ioi++;
                        if (newOrder == setOrder + 1) {
                            questionList.push({ questionSetID: questionSetSplitID, InitialOrder: newOrder });
                        }
                        //clear post initial ordered questions in initial question set
                        console.log(currentQS, questionOrder);
                        currentQS!.questions.forEach(q => {
                            if (q.internalOrder > questionOrder) {
                                currentQSControl.RemoveQuestionByID(q.id, true);
                            }
                        });

                        this.setInternalOrderOffsets();
                        this.setQuestionSetRouting();
                        this.saveOrderedDecisionTree(questionList);


                    } else {
                        alert(data.body.message);
                    }
                }
            },
            (err) => { console.log(err) }, () => console.log("Done")
        );
                   
    }
    insertQuestionSet(setOrder: number, questionOrder: number) {
        var currentQSControl = this.questionSetControls.filter((qsc: QuestionSetControl) => { return qsc.initialOrder == setOrder; })[0]
        var currentQS = currentQSControl.GetQuestionSet();
        var splitName = currentQS!.questionSetHeader?.title ?? "";
        if (splitName.length > 38) {
            splitName = splitName.substring(0, 37);
        }
        splitName += " split ";
        var newName = currentQS!.questionSetHeader?.title ?? "";
        if (newName.length > 38) {
            newName = newName.substring(0, 37);
        }
        
        var qCopy = JSON.stringify(currentQS!.questions);
        var splitData: Question[] = JSON.parse(qCopy);
        splitData = splitData.filter(f => { return f.internalOrder > questionOrder });
        //qCopy = qCopy.replaceAll("TextGuid", "0000");
        var data: any = { "questionSetHeader": { id: 0, title: splitName,description: "",attachmentRequired: false},"questions": splitData};
        var qsCopy = new QuestionSet(data);

        var questionsJson = JSON.stringify(qsCopy)
        this.questionSetDashboardService.addQuestionSet(
            this.decisionTreeHeader.clientID!,
            newName + " " + new Date().toISOString(),
            "Exclusive question set for  " + this.decisionTreeHeader.decisionTreeName,
            0
        ).subscribe(
            (data) => {
                if (data.status == 200) {
                    let questionSetNewID =  data.body.result;
                    if (questionSetNewID != -1) {
                        this.questionSetDashboardService.addQuestionSet(
                            this.decisionTreeHeader.clientID!,
                            splitName + " " + new Date().toISOString(),
                            "Exclusive question set for  " + this.decisionTreeHeader.decisionTreeName,
                            0
                        ).subscribe(
                            (data) => {
                                if (data.status == 200) {
                                    let questionSetSplitID = data.body.result;
                                    if (questionSetSplitID != -1) {

                                        let ioi = 1;
                                        var nqsOrder = 999;
                                        //insert between
                                        
                                        this.addExistingQuestionSetToTree(questionSetNewID, setOrder + 1, newName);// New Name ?!? ---
                                        this.addExistingQuestionSetToTree(questionSetSplitID, setOrder + 2, " ", questionsJson!); // No Split Name
                                        var questionList: any[] = [];

                                        this.questionSetControls
                                            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
                                            .forEach(qsc => {
                                                var newOrder = ioi++;
                                                if (newOrder == setOrder + 1) {
                                                    nqsOrder = newOrder;
                                                    questionList.push({ questionSetID: questionSetNewID, InitialOrder: newOrder });
                                                    newOrder = ioi++;
                                                    questionList.push({ questionSetID: questionSetSplitID, InitialOrder: newOrder });
                                                    newOrder = ioi++;
                                                }
                                                qsc.initialOrder = newOrder;
                                                questionList.push({ questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id, InitialOrder: newOrder })
                                            });
                                        var newOrder = ioi++;
                                        if (newOrder == setOrder + 1) {
                                            questionList.push({ questionSetID: questionSetNewID, InitialOrder: newOrder });
                                            newOrder = ioi++;
                                            questionList.push({ questionSetID: questionSetSplitID, InitialOrder: newOrder });
                                        }
                                        //clear post initial ordered questions in initial question set
                                        console.log(currentQS, questionOrder);
                                        currentQS!.questions.forEach(q => {
                                            if (q.internalOrder > questionOrder) {
                                                currentQSControl.RemoveQuestionByID(q.id);
                                            }
                                        });

                                        this.setInternalOrderOffsets();
                                        this.setQuestionSetRouting();
                                        this.saveOrderedDecisionTree(questionList);


                                    } else {
                                        alert(data.body.message);
                                    }
                                }
                            },
                            (err) => { console.log(err) }, () => console.log("Done")
                        );
                    } else {
                        alert(data.body.message);
                    }
                }
            },
            (err) => { console.log(err) }, () => console.log("Done")
        );
    }
    setInternalOrderOffsets() {
        var i = 0;
        var sortedQSC = this.questionSetControls.sort((a: QuestionSetControl, b: QuestionSetControl) => { return a.initialOrder! - b.initialOrder!; })
        sortedQSC.forEach((qsc: QuestionSetControl) => {
            var io = 1;
            qsc.GetQuestions().sort((a: Question, b: Question) => { return a.internalOrder - b.internalOrder })
                .forEach((fe) => { fe.internalOrder = io++; });

            qsc.internalOrderOffset = i;
            var questionCounter = qsc.GetQuestions().length;
            //console.log("iOO", qsc.GetQuestionSet()!.questionSetHeader!.title,
            //    qsc.initialOrder, qsc.internalOrderOffset, questionCounter,
            //    "Expected Start: " + (qsc.internalOrderOffset + 1)
            //);
            i += questionCounter;
        });
        this.questionSetControls.forEach((qsc: QuestionSetControl) => {
            //qsc.redrawLines();
            var self = qsc;
            setTimeout(function () {
                self.drawTree();
            }, 250);
        });
    }
    getDecisionTreeToQuestionSets() {
        this.decisiontreeService.getQuestionSetsByDecisionTreeID(this.decisionTreeId!).subscribe(resp => {
            if (resp.body) {
                var questionSets = this.decisiontreeService.mapQuestionSetDataToDecisionTreeResponse(resp);
                questionSets.forEach(qsd => {
                    if (qsd.questionSetData != undefined) {
                        var qsc = new QuestionSetControl(this.dialog);
                        var json = JSON.parse(qsd.questionSetData!);
                        qsc.LoadQuestionSet(json);
                        qsc.GetQuestionSet()!.questionSetHeader!.id = qsd.questionSetID;
                        qsc.initialOrder = qsd.initialOrder;
                        qsc.questionSetTypeId = qsd.questionSetTypeID;
                        qsc.questionSetHeaderId = qsd.questionSetHeaderID
                        if (qsd.questionSetTypeID != 0) {
                            qsc.showQuestions = false;
                        }
                        if (qsc.GetQuestionSet() !== undefined && qsc.GetQuestionSet()?.questionSetHeader !== undefined) {
                            qsc.GetQuestionSet()!.questionSetHeader!.id = qsd.questionSetID;
                        }
                        var a: any[] = [];
                        qsc.questionSetLanguage = qsd.questionSetLanguage ?? a;
                        qsc.settings = this.settings;

                        this.questionSetControls.push(qsc);
                        this.applications.forEach((al) => {
                            var newA = new ApplicationStatus({});
                            newA.id = al.id;
                            newA.description = al.description;
                            newA.name = al.name;
                            newA.enabled = ((qsd.applicationsEnabledList ?? [])!.indexOf(al.id) > -1);
                            qsc.applicationStatusList.push(newA);
                        });
                        qsc.CalculateQuestionDetail();
                        this.setInternalOrderOffsets();
                        this.setQuestionSetRouting();
                    }
                });
                this.isLoading = false;
            }
        });
    }
    getDecisionTree() {
        this.cancelAutoSave();
        this.decisiontreeService.getDecisionTreeByID(this.decisionTreeId).subscribe(resp => {
            if (resp.body) {
                this.decisionTree = this.decisiontreeService.mapSingleDecisionTreeResponse(resp);
                var d = new Date().toISOString();

                var ted = new Date(this.decisionTree!.endDate!).toISOString();
                this.treeIsHistoric = ted < d;

                //get the header with the id from the fetched decision tree
                if (this.decisionTree != undefined) {
                    this.getDecisionTreeHeader(this.decisionTree);
                }
                if (this.decisionTree.majorVersion == 1 && 1 == this.decisionTree.minorVersion) {
                    var snc = '';
                    this.route.queryParams.subscribe(params => {
                        snc = params['snc'];
                    });
                    if (snc == 'Y') {
                        this.showNewlyCreatedSnak();
                    }
                }
                if (this.decisionTree.decisionTreeStatus != 1) {
                    document.getElementById("questionEditArea")?.classList.add("submit-for-approval")
                    //} else if (this.decisionTree.comments != "") {
                    //    alert("comments " + this.decisionTree.comments);
                }
                if (this.decisionTree.decisionTreeStatus == 2) {
                    //this.showPendingApprovalSnak();
                }
                this.startAutoSave();
            }
        });
    }
    getDecisionTreeHeader(decisionTree: DecisionTree) {
        this.decisiontreeService.getDecisionTreeHeaderByID(decisionTree.decisionTreeHeaderId!).subscribe(resp => {
            if (resp.body) {
                this.decisionTreeHeader = this.decisiontreeService.mapSingleDecisionTreeHeaderResponse(resp);
                this.getTreeSettings();
                //console.log("DTH:", this.decisionTreeHeader);
                this.decisiontreeService.GetAdditionComments(this.decisionTreeHeader!.decisionTreeHeaderId!, 1)
                    .subscribe(resp => {
                        if (resp.body) {
                            this.showReturnedToDraftDialog(resp.body);
                        }
                    });
                this.getDecisionTreeToQuestionSets();
                this.getDecisionTreeOverrides();
            }
        });
        this.updateVersionInfo();
    }
    getDecisionTreeOverrides() {
        this.decisionTreeOverrides = [];
        this.decisiontreeService.getDecisionTreeOverridesHeaderByID(this.decisionTree.decisionTreeHeaderId!)
            .subscribe(resp => {
                if (resp.body) {
                    resp.body.forEach((fe:any) => {
                        var dto = new DecisionTreeOverride(fe);
                        //console.log(fe, dto);
                        this.decisionTreeOverrides.push(
                            dto
                        )
                    });
                    const self = this;
                    setTimeout(function () {
                        self.validateOverrides();
                    }, 100);
                    
            }
        });
    }
    validateOverrides() {
        document.querySelectorAll(".show-override-error-indication-tab").forEach(fe => { fe.classList.remove("show-override-error-indication-tab"); })
        this.overrideList.validateOverrides();
        //this.overrideTabLabel = "Override";
        if (this.overrideList.errorMessages.length > 0) {
            //this.overrideTabLabel =  Override";
            var tl:any = document.querySelector(".override-tab-label .mdc-tab__text-label");
            if (tl !== undefined)
                tl.classList.add("show-override-error-indication-tab");
        }
    }

    updateVersionInfo() {
        this.decisiontreeService.getDecisionTreeVersionHistoryByDecsionTreeHeaderID(this.decisionTree.decisionTreeHeaderId!)
            .subscribe((resp: any) => {
                if (resp.body) {
                    //console.log(resp.body);
                    try {
                        //Histroy record Get
                        var r: any = resp.body;

                        if (r.length > 0) {
                            var h = r.sort((a: any, b: any) =>
                                (b.majorVersion + .001 * b.minorVersion) - (a.majorVersion + .001 * a.minorVersion)
                            );
                            this.treeIsCurrent = this.decisionTreeId == h[0].decisionTreeID;
                            this.currentVersion = h[0].decisionTreeID;

                        }
                        //console.log("Version History", r);
                        this.historyRecords = [];
                        var hr = this.historyRecords;
                        r.forEach(
                            function (d: any) {
                                let n = new VersionHistoryItem(d);
                                hr.push(n);
                            }
                        );
                        //console.log("Vh2", this.historyRecords);
                    } catch (e) {
                        console.log(e);
                        alert("Failed to access history");
                    }
                }
            });
    }
    openDecisionTreeHistory() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            decisionTreeHeaderID: this.decisionTreeHeader.decisionTreeHeaderId,
        }
        const dialogRef = this.dialog.open(EditHistoryModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            (data) => {
                console.log(data)
            }
        );
    }
    openCatalog() {
        if (this.decisionTree.decisionTreeStatus != 1) {
            this.showPendingApprovalBlockEditSnak();
            return;
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            clientId: this.decisionTreeHeader.clientID
        }
        const dialogRef = this.dialog.open(EditQuestionsCatalogModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            (data: string[]) => {
                if (data.length > 0) {
                    this.saveDecisionTreeAdditions(data);
                }
            }
        );
    }
    save() {
        if (this.decisionTree.decisionTreeStatus == 3) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
                title: "Are you sure",
                dialogText: "Are you sure you want to edit this Decision Tree?",
                submitButtonText: "Yes, Edit",
                minStartTomorrow: this.decisionTree.majorVersion != 1,
                defaultStartDate: this.decisionTree.startDate,
                defaultEndDate: this.decisionTree.endDate,
                showComment: false
            }
            const dialogRef = this.dialog.open(ApproveDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(
                data => {
                    if (data != "0") {
                        var newMin: number, newMaj: number;
                        if (this.treeIsCurrent) {
                            newMaj = this.decisionTree.majorVersion! + 1;
                            newMin = 1;
                        } else {

                            var filt = this.historyRecords.filter(f => {
                                return f.majorVersion == this.decisionTree.majorVersion
                            });
                            var sort = filt.sort((a, b) => (b.minorVersion - a.minorVersion));
                            newMaj = this.decisionTree.majorVersion!;
                            newMin = filt[0].minorVersion + 1;
                        }
                        this.decisiontreeService.addDecisionTreeForEdit(
                            this.decisionTree.decisionTreeID!,
                            this.authService.user.id!, newMaj, newMin,
                            data.start, data.end
                        ).subscribe(resp => {
                            console.log("open for edit", resp);
                            if (resp.body.message == "" || resp.body.message == null) {
                                this.decisionTree.startDate = data.start;
                                this.decisionTree.endDate = data.end;

                                this.decisionTreeId = resp.body.result;
                                this.decisionTree.decisionTreeID = this.decisionTreeId;
                                this.decisionTree.decisionTreeStatus = 1;
                                this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
                                document.getElementById("questionEditArea")?.classList.remove("submit-for-approval");
                                this.updateVersionInfo();
                            } else {
                                alert(resp.body.message);
                            }
                        });
                    }
                }
            );
        } else {
            this.saveQuestions();
            this.UpdateDecisionTreeOverrides(this.decisionTreeOverrides);
        }
    }
    AlterApprovalRange(decisionTreeID: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Alter pending approve date",
            dialogText: "Update date range to approve for:",
            submitButtonText: "Update dates",
            minStartTomorrow: this.decisionTree.majorVersion != 1,
            defaultStartDate: this.decisionTree.startDate,
            defaultEndDate: this.decisionTree.endDate,
            showComment: false
        }
        const dialogRef = this.dialog.open(ApproveDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != 0) {
                    this.decisionTree.startDate = data.start;
                    this.decisionTree.endDate = data.end;
                    this.saveDecisionTreeAdditions([]);
                }
            });
    }
    deleteUnapprovedTree(decisionTreeID: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "delete-confirm-dialog";
        dialogConfig.data = {
            showDelete: true,
            submitButtonText: "Yes, Delete",
            title: "Are you sure you want to delete this version?",
            dialogText: "This action cannot be undone, this version will be permantently deleted from the Decision Tree."
        };
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != 0) {
                    this.deleteUnapprovedTreeConfirmed(decisionTreeID);
                }
            }
        );
    }
    deleteUnapprovedTreeConfirmed(decisionTreeID: number) {
        var curMaj = this.decisionTree.majorVersion ?? -1;
        var cid = this.decisionTree.decisionTreeID!;
        this.decisiontreeService.deleteUnapprovedTree(decisionTreeID, this.authService.user.id!)
            .subscribe(resp => {
                if (this.historyRecords.length == 1) {
                    this.router.navigateByUrl('/decisiontree');
                } else if (cid == decisionTreeID) {
                    var filt = this.historyRecords.filter(f => {
                        return f.majorVersion == curMaj && f.decisionTreeID != cid
                    });
                    if (filt.length > 0) {
                        this.switchVersion(filt[0]);
                    } else {
                        this.switchMajorVersion(this.historyRecords[0].majorVersion);
                    }
                } else {
                    this.updateVersionInfo();
                }
            });

    }
    saveQuestions() {
        if (this.decisionTree.decisionTreeStatus == 3) {
            this.saveDecisionTreeAdditions([], true);
        } else {
            this.saveEachQuestionSet();
        }
    }

    saveEachQuestionSet() {
        this.decisionTree.decisionTreeStatus = 1;
        var self = this;
        this.questionSetControls.forEach(
            function (qsc) {
                if (qsc.questionSetTypeId == 0) {
                    self.savingSet++;
                    self.saveQuestionSet(qsc);
                }
            }
        );
        this.setErrorMessageList();
    }

    saveQuestionSet(qsc: QuestionSetControl) {
        let langResult: any = qsc.questionSetLanguage;
        var appsEnabled: number[] = [];
        qsc.applicationStatusList.forEach(
            (ali: ApplicationStatus) => {
                if (ali.enabled) appsEnabled.push(ali.id);
            }
        )
        var qs = qsc.GetQuestionSet();
        if (qs !== undefined) {
            var currentId = qsc.GetQuestionSet()!.questionSetHeader!.id;

            this.questionSetService.SaveQuestionSet(currentId, appsEnabled, qs, langResult)
                .subscribe(
                    resp => {
                        if (resp.status != 200) {
                            alert("an unknown error has occurred.");
                        } else if (resp.body.message != "" && resp.body.message != null) {
                            alert("Save Failed");
                        } else {
                            this.savingSet--;
                            var newQuestionSetID = resp.body.result;
                            qsc.GetQuestionSet()!.questionSetHeader!.id = newQuestionSetID;
                        }
                    }
                );
        }
    }

    saveDecisionTreeAdditions(newIds: string[], saveAfterComplete: boolean = false) {
        var questionList: any[] = [];
        let ioi = 1;
        this.questionSetControls
            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
            .forEach(qsc => {
                questionList.push(
                    {
                        questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id,
                        InitialOrder: ioi++
                    }
                )
            });
        newIds.forEach(qid => {
            this.addExistingQuestionSetToTree(parseInt(qid), this.questionSetControls.length + 1, ""); // No Name ---
            questionList.push(
                {
                    questionSetID: parseInt(qid),
                    InitialOrder: ioi++
                }
            );
        });
        this.saveOrderedDecisionTree(questionList, saveAfterComplete);
    }

    addExistingQuestionSetToTree(qid: number, order: number, name: string, dataOverride: string | undefined = undefined) {
        this.questionSetService.getQuestionSetDetailFromService((qid))
            .subscribe(resp => {
                //console.log(resp);
                var qsc = new QuestionSetControl(this.dialog);
                //console.log(resp.body.applicationDetails);
                var saveSetAfterLoad = false;
                this.applications.forEach((al) => {
                    var enabled = false;
                    var appid = resp.body.applicationDetails.filter((f: any) => f.applicationID == al.id);
                    if (appid.length > 0) {
                        enabled = appid[0].enabled;
                    }
                    var newA = new ApplicationStatus({});
                    newA.id = al.id;
                    newA.description = al.description;
                    newA.name = al.name;
                    newA.enabled = enabled;
                    qsc.applicationStatusList.push(newA);
                });

                if (dataOverride === undefined) {
                    dataOverride = resp.body.questionSet.questionSetData;
                } else {
                    saveSetAfterLoad = true;
                }

                var qsJson = JSON.parse(dataOverride!);
                qsc.LoadQuestionSet(qsJson);
                qsc.GetQuestionSet()!.questionSetHeader!.id = (qid);
                qsc.initialOrder = order;
                qsc.questionSetTypeId = resp.body.questionSet.typeID;
                qsc.questionSetHeaderId = resp.body.questionSet.questionSetHeaderID;
                qsc.settings = this.settings;
                if (qsc.questionSetTypeId == 0) {
                    if (qsc.GetQuestions().length == 0) {
                        qsc.addQuestionQuick(0);
                        this.setQuestionSetRouting();
                    }
                }
                if (qsc.GetQuestionSet() !== undefined && qsc.GetQuestionSet()?.questionSetHeader !== undefined) {
                    qsc.GetQuestionSet()!.questionSetHeader!.id = (qid);
                    if (name != "") {
                        qsc.GetQuestionSet()!.questionSetHeader!.title = name;
                    }
                }
                this.questionSetControls.push(qsc);
                qsc.CalculateQuestionDetail();
                this.setInternalOrderOffsets();
                this.setQuestionSetRouting();

                if (saveSetAfterLoad) {
                    this.saveEachQuestionSet();
                }

            });
    }

    saveOrderedDecisionTree(questionList: any[], saveAfterComplete: boolean = false) {
        const start = new Date(this.decisionTree.startDate!);
        const parsedFromDate = start.toISOString();
        const end = new Date(this.decisionTree.endDate!);
        const parsedToDate = end.toISOString();
        this.decisionTree.comments = "";
        this.decisiontreeService.saveDecisionTree(
            this.decisionTree?.decisionTreeID!, this.authService.user.id!
            , ""!, parsedFromDate!, parsedToDate!
            , questionList
        ).subscribe(
            (data) => {
                if (data.body.message != "" && data.body.message != null) {
                    alert("Save Failed");
                } else {
                    //console.log("SAVED", data.body.result);
                    this.decisionTreeId = data.body.result;
                    this.decisionTree.decisionTreeID = this.decisionTreeId;
                    this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
                    if (saveAfterComplete) {
                        this.setQuestionSetRouting();
                        this.saveEachQuestionSet();
                    }
                    this.updateVersionInfo();
                }
            },  //changed
            (err) => {
                console.log(err)
            },
            () => console.log("Done")
        );
    }

    openSettingsModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            treeId: this.decisionTreeHeader.decisionTreeHeaderId,
            p: this
        }
        const dialogRef = this.dialog.open(EditQuestionSettingsModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            (data) => {
                this.getTreeSettings();
            }
        );
    }

    getOrderedQuestionSetList(): number[] {
        let ioi = 1;
        var questionList: any[] = [];
        this.questionSetControls
            .sort((a, b) => ((a.initialOrder ?? 909) > (b.initialOrder ?? 909)) ? 1 : -1)
            .forEach(qsc => {
                questionList.push(
                    {
                        questionSetID: qsc.GetQuestionSet()!.questionSetHeader!.id,
                        InitialOrder: ioi++
                    }
                )
            });

        //console.log(questionList);
        return questionList;
    }

    submitForApproval() {
        var self = this;
        this.saveEachQuestionSet();
        setTimeout(function () {
            self.submitForApprovalReady();
        }, 100);

    }
    submitForApprovalReady() {
        var self = this;
        if (this.savingSet > 0) {
            setTimeout(function () {
                self.submitForApprovalReady();
            }, 100);
        } else {
            var validRequest = true;
            var hasQuestion = false;
            var errorMessage = '';
            this.questionSetControls.forEach(qsc => {
                var qs = qsc.GetQuestionSet();
                if (qs != null && qs!.questions.length > 0) {
                    hasQuestion = true;
                }
            });
            if (!hasQuestion) {
                errorMessage = "You need to have at least 1 question to appove a decision tree."
                validRequest = false;
            }
            if (!validRequest) {
                alert(errorMessage);
                return;
            }

            if (this.activeComments.length > 0) {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data = {
                    title: "Are you sure?",
                    dialogText: "Before submitting for approval has the reason for returning to the draft been resolved?",
                    submitButtonText: "Yes, Submit",
                    showComment: false
                }

                /// Switch to componant with Date and Time

                const dialogRef = this.dialog.open(CommentDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(
                    data => {
                        if (data != "0") {
                            this.submitForApprovalRequest();
                        }
                    }
                );
            } else {
                this.submitForApprovalRequest()
            }
        }
    }
    submitForApprovalRequest() {
        this.resolveComments();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Approval Submit",
            dialogText: "Please review the pending date range to approve for:",
            submitButtonText: "Submit for Approval",
            minStartTomorrow: this.decisionTree.majorVersion != 1,
            defaultStartDate: this.decisionTree.startDate,
            defaultEndDate: this.decisionTree.endDate,
            showComment: false
        }
        const dialogRef = this.dialog.open(ApproveDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != 0) {
                    const parsedFromDate = data.start;
                    const parsedToDate = data.end;
                    this.decisionTree.startDate = data.start;
                    this.decisionTree.endDate = data.end;
                    let questionList = this.getOrderedQuestionSetList();
                    this.decisiontreeService.submitForApproval(
                        this.decisionTree?.decisionTreeID!, this.authService.user.id!
                        , "Approval Request Submitted", parsedFromDate!, parsedToDate!
                        , questionList
                    ).subscribe(
                        (data) => {
                            //console.log(data);
                            if (data.body.result == -1) {
                                this.showErrorDialog(data.body.message)
                            } else {
                                //console.log("approval submision set " + data);
                                this.decisionTree.decisionTreeStatus = 2;
                                this.decisionTreeId = data.body.result;
                                this.decisionTree.decisionTreeID = this.decisionTreeId;
                                this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
                                document.getElementById("questionEditArea")?.classList.add("submit-for-approval");
                                this.showPendingApprovalSnak();
                                this.setErrorMessageList();
                                this.updateVersionInfo();
                            }
                        },
                        (e) => {
                            alert('An error occurred submitting for Approval');
                        }
                    );
                }
            });
    }

    returnToDraft() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Return Decision Tree to Draft",
            labelForComment: "Enter reason for return",
            submitButtonText: "Return to Draft"
        }
        const dialogRef = this.dialog.open(CommentDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != "0") {
                    this.unsetApprovalRequest(data);
                }
            }
        );
    }
    unsetApprovalRequest(comment: string) {
        const parsedFromDate = this.decisionTree?.startDate!;
        const parsedToDate = this.decisionTree?.endDate!;
        let questionList = this.getOrderedQuestionSetList();
        this.decisionTree.comments = comment;
        //comment comment comment comment comment 
        if (comment == "") {
            comment = "Approval Request Returned to Draft"
        }
        this.decisiontreeService.unsetApprovalRequest(
            this.decisionTree?.decisionTreeID!, this.authService.user.id!
            , comment, parsedFromDate!, parsedToDate!
            , questionList
        ).subscribe(
            (data) => {
                //console.log(data);
                if (data.body.result == -1) {
                    alert(data.body.message);
                } else {
                    //console.log("Approval submit redacted");
                    this.decisionTree.decisionTreeStatus = 1;
                    this.decisionTreeId = data.body.result;
                    this.decisionTree.decisionTreeID = this.decisionTreeId;
                    this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
                    document.getElementById("questionEditArea")?.classList.remove("submit-for-approval");
                    this.decisiontreeService.GetAdditionComments(this.decisionTreeHeader!.decisionTreeHeaderId!, 1)
                        .subscribe(resp => {
                            if (resp.body) {
                                document.querySelector(".fakesnak")?.remove();

                                this.showReturnedToDraftDialog(resp.body);
                            }
                        });
                    this.setErrorMessageList();
                }
            });
    }

    approve() {
        //console.log(this.decisionTreeId);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            minStartTomorrow: this.decisionTree.majorVersion != 1,
            defaultStartDate: this.decisionTree.startDate,
            defaultEndDate: this.decisionTree.endDate
        }
        const dialogRef = this.dialog.open(ApproveDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                //console.log(data);
                if (data != 0) {
                    this.approvalSubmit(data);
                }
            }
        );
    }
    approvalSubmit(data: any) {
        const parsedFromDate = data.start;
        const parsedToDate = data.end;
        this.decisionTree.comments = data.comment;
        var id = this.decisionTreeId;
        //console.log("Approving " + id)
        this.decisiontreeService.approveDecisionTree(id, this.authService.user.id!, parsedFromDate!, parsedToDate!, data.comment).subscribe(
            (data) => {
                //console.log(data);
                if ((data.body.message != "" && data.body.message != null) || data.body.result == -1) {
                    if (data.body != null) {
                        this.showErrorDialog(data.body.message)

                    } else {
                        alert("Approval failed");
                    }
                } else {
                    //console.log("SAVED")Date;
                    this.decisionTree.startDate = parsedFromDate;
                    this.decisionTree.endDate = parsedToDate;
                    this.setErrorMessageList();
                    this.decisionTree.decisionTreeStatus = 3;
                    this.showApprovedSnak();
                    this.updateVersionInfo();
                    //this.decisionTreeId = data.body.result;
                    //this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
                }
            },  //changed
            (err) => {
                console.log(err)
                alert("Error Approving tree");
            },
            () => console.log("Done")
        );
    }
    showErrorDialog(text: string) {

        let el = text.split("\n")
        console.log(el)
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Error occurred in request",
            dialogText: "",
            submitButtonText: "Ok",
            showComment: false,
            errorList: el
        }
        /// Switch to componant with Date and Time
        const dialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != "0") {
                }
            }
        );

    }
    setErrorMessageList() {
        var self = this;
        setTimeout(function () {
            var em: string[] = [];
            self.questionSetControls.forEach(qsc => {
                if (qsc.GetQuestions().length == 0) {
                    em.push("You need to have at least 1 question to appove a decision tree.");
                }

                //console.log(qsc.initialOrder, qsc.questionErrorMessages);
                Object.keys(qsc.questionErrorMessages)
                    .forEach(k => {
                        em.push.apply(em, qsc.questionErrorMessages[k])
                    });
            });
            self.errorMessages = em;
        }, 1000);
    }

    test() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            applications: this.applications,
        }
        const dialogRef = this.dialog.open(TestDecisiontreeModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                this.openinteractiveQuestionAnswerModal(data)
            }
        );
    }
    closeHistory() {
        document.querySelector('.history-side-bar')!.classList.add('hide-history')
    }
    openHistory() {
        document.querySelector('.history-side-bar')!.classList.remove('hide-history')
    }
    showHistory(decisionTreeID: number) {
        alert(decisionTreeID);
    }
    getMajorVersions(): number[] {
        var majorVersionList: number[] = [];
        this.historyRecords.forEach(fe => {
            if (majorVersionList.indexOf(fe.majorVersion) == -1) {
                majorVersionList.push(fe.majorVersion);
            }
        });
        majorVersionList = majorVersionList.sort((a, b) =>
            a < b ? 1 : -1
        );
        return majorVersionList;
    }
    showInVersionMenu(vhi: VersionHistoryItem, currentVersion: number): boolean {
        if (currentVersion != vhi.majorVersion) { return false; }
        var filt = this.historyRecords.filter(f => currentVersion == f.majorVersion);
        var test = filt.sort((a, b) => b.decisionTreeID - a.decisionTreeID);
        var d = new Date().toISOString().split('T')[0];
        var ed = vhi.endDate.toISOString().split('T')[0];
        var sd = vhi.startDate.toISOString().split('T')[0];
        if (vhi.decisionTreeID == test[0].decisionTreeID) {
            return true;
        } else if (d <= ed && d >= sd) {
            return true;
        }

        return false;
    }


    isNotMaxVersion(vhi: VersionHistoryItem): boolean {
        var test = this.historyRecords.filter(f => f.majorVersion == vhi.majorVersion)
            .sort((a, b) => b.decisionTreeID - a.decisionTreeID)[0];
        return vhi.decisionTreeID != test.decisionTreeID;
    }
    switchMajorVersion(mv: number) {
        var mvi = this.historyRecords.filter(f => f.majorVersion == mv)
            .sort((a, b) => b.decisionTreeID - a.decisionTreeID)[0];
        this.switchVersion(mvi);
    }

    switchVersion(dt: any) {
        if (dt.decisionTreeID == this.decisionTreeId) {
            return;
        }
        this.isLoading = true;
        this.decisionTree = new DecisionTree();
        this.decisionTreeId = 0;
        this.selectedTabIndex = 0;
        this.errorMessages = [];
        this.activeComments = [];
        this.historyRecords = [];
        this.treeIsCurrent = false;

        this.questionSetControls.forEach(fe => fe.clearLines());
        this.questionSetControls = [];

        if (document.querySelectorAll(".errorComments .content").length > 0)
            document.querySelectorAll(".errorComments .content")[0].innerHTML = "";
        //xxxxxxx
        //console.log("ROUTING", dt.decisionTreeID);
        this.decisionTreeId = dt.decisionTreeID;
        this.location.replaceState('decisiontree/editquestions?dt=' + this.decisionTreeId);
        document.getElementById("questionEditArea")?.classList.remove("submit-for-approval")
        this.getDecisionTree();
        //this.getDecisionTreeToQuestionSets();
        this.setErrorMessageList();
    }
    restoreVersion() {
        var sorted = this.historyRecords.sort((a: any, b: any) =>
            (b.majorVersion + .001 * b.minorVersion) - (a.majorVersion + .001 * a.minorVersion)
        );
        var c = sorted[0];
        var newMin = 1;
        var newMaj = c.majorVersion + 1;
        this.decisiontreeService.addDecisionTreeForEdit(
            this.decisionTree.decisionTreeID!,
            this.authService.user.id!, newMaj, newMin
            , this.decisionTree.startDate!, this.decisionTree.endDate!
        ).subscribe(resp => {
            //console.log("Generated", resp);
            if (resp.body.message == "" || resp.body.message == null) {
                this.decisionTreeId = resp.body.result;
                this.decisionTree.decisionTreeID = this.decisionTreeId;
                this.decisionTree.decisionTreeStatus = 1;
                this.location.replaceState('/decisiontree/editquestions?dt=' + this.decisionTreeId);
                //document.getElementById("questionEditArea")?.classList.remove("submit-for-approval");
                //this.updateVersionInfo();
                this.historyRecords.push(new VersionHistoryItem({
                    decisionTreeHeaderID: this.decisionTree.decisionTreeHeaderId,
                    clientID: this.decisionTree.clientID,
                    decisionTreeID: this.decisionTreeId,
                    majorVersion: newMaj,
                    minorVersion: newMin,
                    decisionTreeStatus: 1,
                    comments: "",
                    startDate: this.decisionTree.startDate,
                    endDate: this.decisionTree.endDate,
                    updateDate: new Date(),
                    userIdentifier: ""
                }));
                this.switchMajorVersion(newMaj);
            } else {
                alert(resp.body.message);
            }
        });
    }
    startAutoSave() {
        //console.log(">>>>>>> Auto Save Start");
        var self = this;
        this.asRequestID = setTimeout(function () {
            self.autoSave();
        }, self.autoSaveDelay);
    }
    cancelAutoSave() {
        this.asRequestID = null;
    }
    autoSave() {
        console.log("AUTOSAVE");
        if (this.asRequestID == null) {
            return;
        }

        var self = this;
        var inEditPage: boolean = window.location.pathname.includes("editquestions");

        if (this.decisionTree.decisionTreeStatus == 1 && inEditPage) {
            //console.log("------ Auto Save");
            this.saveEachQuestionSet();
            this.cancelAutoSave();
            this.asRequestID = setTimeout(function () {
                self.autoSave();
            }, self.autoSaveDelay);
        } else if (inEditPage) {
            //console.log("------ Auto Save Skipped, not in draft");    
            this.cancelAutoSave();
            this.asRequestID = setTimeout(function () {
                self.autoSave();
            }, self.autoSaveDelay);
        } else if (!inEditPage) {
            this.cancelAutoSave();
            //console.log("------ Auto Save suspended no longer in edit page");    
        }

    }

    openinteractiveQuestionAnswerModal(applicationId: number) {
        var name = this.decisionTreeHeader?.decisionTreeName
        this.decisiontreeService.getCompletedDecisionTree(
            this.decisionTree.decisionTreeID!, applicationId
        )
            .subscribe(resp => {
                if (resp.body) {
                    startQuestionSetReview(name, resp.body);
                } else {
                    alert('No question set available for the selected application');
                }
            },
                (e) => {
                    alert('An error occurred generating Test for Application');
                }
            );
    }

    setQuestionSetRouting() {
        let qsrl: QuestionSetRouting[] = [];
        this.questionSetControls.forEach(qsc => {
            var qs = qsc.GetQuestionSet();
            var item = new QuestionSetRouting({
                qsIndex: qsc.initialOrder,
                qsGuid: qs?.qsGuid,
                title: qs?.questionSetHeader?.title ,// + ": " + qs?.questions[0]?.text,
                internalOrderOffset: qsc.internalOrderOffset,
            });
            qsrl.push(item);
        });
        this.questionSetRoutingList = qsrl;
        //console.log("RTING", qsrl)
    }
    saveOverridesOnly() {
        this.UpdateDecisionTreeOverrides(this.decisionTreeOverrides);
    }

    UpdateDecisionTreeOverrides(overrides: DecisionTreeOverride[]) {
        this.validateOverrides();
        var req: DecisionTreeOverrideRequest = new DecisionTreeOverrideRequest();
        req.DecisionTreeHeaderID = this.decisionTreeHeader!.decisionTreeHeaderId!;
        console.log(overrides);
        req.DecisionTreeOverride = overrides;

        this.decisiontreeService.UpdateDecisionTreeOverrides(req)
            .subscribe(resp => {
            if (resp.body.result != 1) {
                alert("an error occurred in saving the overrides");
            }
                //this.getDecisionTreeOverrides();
        });
    }
    switchView() {
        if (this.showGridView) {
            this.showGridView = false;
        } else {
            this.showGridView = true;
            this.questionSetControls.forEach((qsc: QuestionSetControl) => {
                var self = qsc;
                setTimeout(function () {
                    self.drawTree();
                }, 50);
            });
        }
    }
    dragOver(event: any) {
        let eox = event.pageX - 10;
        if (eox < 670) {
            eox = 650;
        }
        this.e.nativeElement.querySelector("#leftSide").style.width = eox + "px";
    }

    dragStart(event: any) {
    }

    dragDrop(event: any) {
    }

}
