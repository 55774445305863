import { Component, OnDestroy, OnInit } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { UserSessionService } from 'abca-layout';
import { filter } from 'rxjs/operators';
import { AuthService } from "./shared/auth/auth.service";
import { datadogRum } from '@datadog/browser-rum';
import { environment } from "./shared/environments/environments";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'DecisionTree.UI';
    currentLanguage: string = '';

    constructor
    (
        private userSessionService: UserSessionService,
        private oauthService: OAuthService,
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService,
        private cookieService: CookieService)
    {
        this.setLanguage('en');
        this.configureWithNewConfigApi();
        if (this.isAuthorized()) {
            // Subscribe to tenant and language changes from abca layout.
            //this.onLanguageChanged();
            this.onTenantChanged();
        } else {
            // Wait until user is authenticated before getting initial tenant and language.
            this.oauthService.events.pipe(filter(e => e.type === 'token_received')).subscribe(() => {
                //this.onLanguageChanged();
                this.onTenantChanged();
            });
        }
    }

    ngOnInit() {
        if (environment.production === true) {
            // run datadog
            datadogRum.init({
                applicationId: environment.datadog.applicationId,
                clientToken: environment.datadog.clientToken,
                site: environment.datadog.site,
                service: environment.datadog.service,
                env: environment.datadog.env,
                //applicationId: '298f8221-e403-4f31-8634-f2196c4cfa87',
                //clientToken: 'pub8a251e29bec99a46291aba00e10eeb23',
                //site: 'datadoghq.com',
                //service: 'decisiontree2-ui',
                //env: 'production',
                // Specify a version number to identify the deployed version of your application in Datadog 
                // version: '1.0.0', 
                sessionSampleRate: 100,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
            });
        }
    }
    ngAfterViewInit() {
        //document.querySelectorAll("head")[0].innerHTML = document.querySelectorAll("head")[0].innerHTML.replace("div,svg,input,select,textarea,span,img,table,label,td,th,p,a,button,ul,code,pre,li{border-radius:0!important}", "");
        //var a: any = document.querySelectorAll(".page-content")[0];
        //if (a != null) {
        //    a.style.minHeight = null;
        //}
    }

    ngOnDestroy() {
    
    }


    public isAuthorized(): boolean {
        return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
    }

    private setLanguage(lang: string) {
        this.translate.setDefaultLang(lang);
        this.currentLanguage = lang;
    }

    private onLanguageChanged() {
        // Capture changes to update current language.
        this.userSessionService.OnLanguageChanged().subscribe({
            next: (userSession) => {
                if (userSession && userSession.sessionValue) {
                    //this.setLanguage(userSession.sessionValue);
                }
            }
        });
    }

    private onTenantChanged() {
        // Capture changes to update current tenant connection.
        this.userSessionService.OnTenantChanged()
            .subscribe(userSession => {
                if (userSession && userSession.sessionValue) {
                    var currentTenant = JSON.parse(this.cookieService.get('darwin-tenant'));
                    if (userSession.sessionValue != currentTenant.sessionValue) {
                        this.router.navigate(['/'])
                        setTimeout(() => location.reload(), 500)
                    }
                }
            });
    }

    private configureWithNewConfigApi() {
        this.authService.configureOAuth();
        //this.oauthService.events.subscribe((event) => {
        //    console.log(event);
        //    //if (!environment.production) {
        //    //    console.log(event);
        //    //}
        //});
        //
        //this.oauthService.configure(authConfig);
        //this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        //this.oauthService.loadDiscoveryDocumentAndLogin({
        //    disableOAuth2StateCheck: true
        //}).then((success) => {
        //    if (success) {
        //        this.router.initialNavigation();
        //        this.oauthService.setupAutomaticSilentRefresh();
        //    }
        //});
    }

    public logout(): void {
        this.oauthService.logOut();
    }
}
