<section>
    <h4>Determination Level</h4>
    <form [formGroup]="determinationForm">
        <mat-checkbox formControlName="allChecked" (change)="toggleAll()">All</mat-checkbox>
        <mat-checkbox formControlName="firstDeterminationChecked" style="margin-bottom:0px!important;">First Determination</mat-checkbox>
        <mat-checkbox formControlName="firstAppealChecked">First Appeal</mat-checkbox>
        <mat-checkbox formControlName="secondAppealChecked">Second Appeal</mat-checkbox>
        <mat-checkbox formControlName="thirdAppealChecked">Third Appeal</mat-checkbox>
    </form>
</section>

