<div class="row">
    <div class="col">
        <h2>IEX</h2>
    </div>
</div>
<div id="rxdmtest" class="card" style="display:none;">
    <button class="btn btn-primary" style="float:right;" onclick="document.getElementById('lcdtest').style.display = 'inline';document.getElementById('rxdmtest').style.display = 'none';">switch to legacy</button>
    <h3>GetDecisionTreeList</h3>
    <form id="testForm" [formGroup]="testForm" (ngSubmit)="runTest()">

        <div class="row">
            <div class="col-md-4">
                <label for="ClientSelect">Client:</label>
                <select class="form-select" id="ClientSelect" (change)="onSelectClient()" formControlName="clientList" placeholder="Select a Client">
                    <option>ACAA</option>
                    <option>ASES</option>
                    <option *ngFor="let c of clients; index as i; first as isFirst" value="{{c.clientID}}">{{c.clientName}}</option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="determinationLevel">Determination Level:</label>
                <input type="number" class="full-width" id="determinationLevelInput" formControlName="determinationLevel" placeholder="1">
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="PCNInput">PCN:</label>
                <input type="text" class="full-width" id="PCNInput" formControlName="pcn" placeholder="Enter a PCN"
                       value="007">
            </div>
            <div class="col-md-4">
                <label for="PlanInput">Benefit Plan:</label>
                <input type="text" class="full-width" id="PlanInput" formControlName="plan" placeholder="Enter a Plan">
            </div>
            <div class="col-md-4">
                <label for="GroupInput">Benefit Group:</label>
                <input type="text" class="full-width" id="GroupInput" formControlName="group" placeholder="Enter a Group">
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="TypeInput">Type:</label>
                <input type="text" class="full-width" id="TypeInput" formControlName="type" placeholder="Enter a Type">
            </div>
            <div class="col-md-4">
                <label for="SubtypeInput">SubType:</label>
                <input type="text" class="full-width" id="SubtypeInput" formControlName="subtype" placeholder="Enter a Sub Type">
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <label for="drugQualifierInput">Drug Qualifier:</label>
                <select class="full-width" id="drugQualifierInput" formControlName="drugQualifierInput">
                    <option>GPI</option>
                    <option>NDC</option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="DrugInput">Drug ID:</label>
                <input type="text" class="full-width" id="DrugInput" formControlName="drug" placeholder="Enter a Drug ID"
                       value="90669902803725" />
            </div>
            <div class="mb-2 my-4 text-center">
                <button type="submit" form="testForm" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
    <div class="table-responsive" *ngIf="rxdmTreeList.length >= 1">
        <table class="table table-striped table-sm">
            <thead>
                <tr>
                    <th scope="col">Priority</th>
                    <th scope="col">Hierarchy</th>
                    <th scope="col">Tree</th>
                    <th scope="col">Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let h of rxdmTreeList;  index as i; first as isFirst">
                    <td>{{h.priority}}</td>
                    <td>
                        {{h.clientName}} >
                        {{h.pcn}} >
                        {{h.planDescription}} >
                        {{h.groupName}}
                    </td>
                    <td>{{h.decisionTreeName}} ({{h.decisionTreeID}})</td>
                    <td>

                        <button (click)="SetDTIDInput(h.decisionTreeID)">
                            select
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <h3>SetCaseToDecisionTree</h3>
    <form id="caseToDecisionTreeForm" [formGroup]="caseToDecisionTreeForm" (ngSubmit)="SetCaseToDecisionTree()">
        <div class="row">
            <div class="col-md-4">
                <label for="CaseIDInput">CaseID:</label>
                <input type="number" class="full-width" id="CaseIDInput" formControlName="CaseID" placeholder="">
            </div>
            <div class="col-md-4">
                <label for="DTIDInput">DecisionTreeID:</label>
                <input type="number" class="full-width" id="DTIDInput" formControlName="DTID" placeholder="">
            </div>
            <div class="col-md-4">
                <br />
                <button type="submit" form="caseToDecisionTreeForm" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>

    <h3>GetDecisionTreeForCase</h3>
    <form id="getDecisionTreeForCaseForm" [formGroup]="getDecisionTreeForCaseForm" (ngSubmit)="GetDecisionTreeForCase()">
        <div class="row">
            <div class="col-md-4">
                <label for="CaseIDInput2">CaseID:</label>
                <input type="number" class="full-width" id="CaseIDInput2" formControlName="CaseID" placeholder="">
            </div>
            <div class="col-md-4">
                <br />
                <button type="submit" form="getDecisionTreeForCaseForm" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
    <button (click)="SaveDecisionTreeAnswers()">SAVE BELOW QUESTION SET</button>
</div>

<div id="lcdtest" class="card" style="display:inline">
    <button class="btn btn-primary" style="float:right;" onclick="document.getElementById('rxdmtest').style.display = 'inline';document.getElementById('lcdtest').style.display = 'none';">switch to RxPath</button>
    <h2>Legacy</h2>
    <form id="testFormLCD" [formGroup]="testFormLCD" (ngSubmit)="runTestLCD()">

        <div class="row">
            <div class="col-md-4">
                <label for="client">Client:</label>
                <input type="text" class="full-width" id="client" formControlName="client" placeholder="Enter Client ID"
                       value="MMM">
            </div>
            <div class="col-md-4">
                <label for="CoverageDeterminationID">CovDetID:</label>
                <input type="number" class="full-width" id="CoverageDeterminationIDInput" formControlName="CoverageDeterminationID" placeholder="">
            </div>
            <div class="col-md-4">
                <label for="determinationLevel">Determination Level:</label>
                <input type="number" class="full-width" id="determinationLevelInput" formControlName="determinationLevel" placeholder="1">
            </div>
            
        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="PCNInput">PCN:</label>
                <input type="text" class="full-width" id="PCNInput" formControlName="pcn" placeholder="Enter a PCN"
                       value="007">
            </div>
            <div class="col-md-4">
                <label for="PlanInput">Benefit Plan:</label>
                <input type="text" class="full-width" id="PlanInput" formControlName="plan" placeholder="Enter a Plan">
            </div>
            <div class="col-md-4">
                <label for="GroupInput">Benefit Group:</label>
                <input type="text" class="full-width" id="GroupInput" formControlName="group" placeholder="Enter a Group">
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="TypeInput">Type:</label>
                <input type="text" class="full-width" id="TypeInput" formControlName="type" placeholder="Enter a Type">
            </div>
            <div class="col-md-4">
                <label for="SubtypeInput">SubType:</label>
                <input type="text" class="full-width" id="SubtypeInput" formControlName="subtype" placeholder="Enter a Sub Type">
            </div>
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <label for="drugQualifierInput">Drug Qualifier:</label>
                <select class="full-width" id="drugQualifierInput" formControlName="drugQualifierInput">
                    <option>DDID</option>
                    <option>GPI</option>
                    <option>NDC</option>
                </select>
            </div>
            <div class="col-md-4">
                <label for="DrugInput">Drug ID:</label>
                <input type="text" class="full-width" id="DrugInput" formControlName="drug" placeholder="Enter a Drug ID"
                       value="90669902803725" />
            </div>
            <div class="mb-2 my-4 text-center">
                <button type="submit" form="testFormLCD" class="btn btn-primary">Submit</button>
            </div>
        </div>
    </form>
    Results;
    <input type="text" id="DecisionTreeResults" style="width:100%; height:100px;" />
    <button (click)="SaveDecisionTreeLCD()">SAVE LEGACY RESULTS</button>
</div>


<h3>Q&A Section</h3>
<div style="border:3px solid black">
    <div id="interactiveQuestionAnswerSection">

    </div>
</div>
<div style="width:100%; text-align:center;">

    <button onclick="lockInteractiveQuestionAnswerSection()">Lock</button>
    <button onclick="unlockInteractiveQuestionAnswerSection()">Unlock</button>
    
</div>