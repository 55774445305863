import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActionResult, QuestionLanguage } from '../../classes/questionset';
import { QuestionSetControl } from '../../shared/question-set.control';
import { QuestionSetService } from '../../shared/services/question-set.service';

@Component({
  selector: 'app-edit-question-decision-item-justification',
  templateUrl: './edit-question-decision-item-justification.component.html',
  styleUrls: ['./edit-question-decision-item-justification.component.scss']
})
export class EditQuestionDecisionItemJustificationComponent {
    @Input() questionSetControl!: QuestionSetControl;
    @Input() actionResult!: ActionResult;
    @Input() supportedLanguages!: any[];
    
    public providerLanguage: any = {};
    public patientLanguage: any = {};
    public pharmacyLanguage: any = {};

    activeTab: number = 0;

    constructor(public questionSetService: QuestionSetService, private cdr: ChangeDetectorRef) {
        
    }
    ngOnInit() {
        //debugger;
        //if (this.questionSetControl.questionSetTypeId != 0) {
        //    return;
        //}
        if ((this.actionResult.actionTextGuid ?? "") == "" && this.questionSetControl.questionSetHeaderId != undefined) {
            this.questionSetService.GetLanguageTextGUID(
                this.questionSetControl.questionSetHeaderId!
            )
                .subscribe((resp: any) => {
                    if (resp.body.result.toString() == "1") {
                        this.actionResult.actionTextGuid = resp.body.extension;
                        this.setupLanguages();
                    }
                });
                
        } else if (this.actionResult.actionTextGuid != null ) {
            this.setupLanguages();
        }
    }

    setupLanguages() {
        //this.supportedLanguages[].languageText = "";
        //if (this.questionSetControl.questionSetLanguage == null) {
        //    this.questionSetControl.questionSetLanguage = [];
        //}

        //LanguageTextGuid: this.actionResult.actionTextGuid,
        //LanguageText: "",
        //LanguageCode: sl.code,
        //recipient: "patient"
        this.supportedLanguages.forEach(sl => {
            //console.log("Loading Lang " + sl.code);
            var r = this.questionSetControl.questionSetLanguage.filter(f => {
                return (f.languageTextGuid ?? "").toUpperCase() == (this.actionResult.actionTextGuid ?? "").toUpperCase()
                    && (f.languageCode ?? "").toUpperCase() == (sl.code ?? "").toUpperCase();
            });
            let providerf = r.filter(f => f.recipient == "provider");
            let patientf = r.filter(f => f.recipient == "patient");
            let pharmacyf = r.filter(f => f.recipient == "pharmacy");
            var provider: QuestionLanguage;
            var pharmacy: QuestionLanguage;
            var patient: QuestionLanguage;

            if (providerf.length == 0) {
                var nql = new QuestionLanguage({
                    LanguageTextGuid: this.actionResult.actionTextGuid,
                    LanguageText: "",
                    LanguageCode: sl.code,
                    recipient: "provider"
                });
                this.questionSetControl.questionSetLanguage.push(nql);
                provider = <QuestionLanguage>nql;
            } else {
                provider = providerf[0];
            }
            this.providerLanguage[sl.code] = provider;

            if (patientf.length == 0) {
                var nql = new QuestionLanguage({
                    LanguageTextGuid: this.actionResult.actionTextGuid,
                    LanguageText: "",
                    LanguageCode: sl.code,
                    recipient: "patient"
                });
                this.questionSetControl.questionSetLanguage.push(nql);
                patient = <QuestionLanguage>nql;
            } else {
                patient = patientf[0];
            }
            this.patientLanguage[sl.code] = patient;


            if (pharmacyf.length == 0) {
                var nql = new QuestionLanguage({
                    LanguageTextGuid: this.actionResult.actionTextGuid,
                    LanguageText: "",
                    LanguageCode: sl.code,
                    recipient: "pharmacy"
                });
                this.questionSetControl.questionSetLanguage.push(nql);
                pharmacy = <QuestionLanguage>nql;
            } else {
                pharmacy = pharmacyf[0];
            }
            this.pharmacyLanguage[sl.code] = pharmacy;

            this.cdr.detectChanges();
        });


        

        //console.log("actionResult lang", this.actionResult.actionTextGuid, r);
        //r.forEach((fe: any) => {
        //    console.log(fe);
        //    let lc = fe.languageCode.toUpperCase();
        //    let recipient = fe.recipient;
        //    if (!this.decisionLangauge.hasOwnProperty(recipient)) {
        //        this.decisionLangauge[recipient] = {};
        //    }
        //    this.decisionLangauge[recipient][lc] = fe.languageText;
        //});
        //languageCode: 'SPA', languageText: 'pat spa', recipient:
     }

    //GetCurrentValueForTextField(code: string, recipient: string, guid: string, currentInput: string = ''):string {
    //    var r = this.questionSetControl.questionSetLanguage.filter(f => {
    //        return f.languageTextGuid == guid
    //            && (f.languageCode ?? "").toUpperCase() == (code ?? "").toUpperCase()
    //            && (f.recipient ?? "").toUpperCase() == (recipient ?? "").toUpperCase()
    //    });
    //    console.log(r, recipient, guid);
    //    return r.length >= 1 ? r[0].languageText : "";
    //}
}
