import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../classes/client';
import { Hierarchy, HierarchyTies } from '../../classes/hierarchy';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { HierarchyService } from '../hierarchy.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-hierarchy-terminate-modal',
  templateUrl: './hierarchy-terminate-modal.component.html',
  styleUrls: ['./hierarchy-terminate-modal.component.scss']
})
export class HierarchyTerminateModalComponent {
    hierarchy!: Hierarchy;
    hierarchyTie!: HierarchyTies;
    client!: Client;
    @Output() event: EventEmitter<any> = new EventEmitter();
    user!: User;

    terminateForm = this.fb.group({
        end: [new Date(), Validators.required],
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<HierarchyTerminateModalComponent>,
        private authService: AuthService,
        private hierarchyService: HierarchyService,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserObject();
        if (data !== undefined) {
            this.hierarchy = data.hierarchy;
            this.hierarchyTie = data.hierarchyTie;
            this.client = data.client;
        }
    }

    save() {
        this.deleteHierarchyTie();
    }

    deleteHierarchyTie() {
        const parsedToDate = this.terminateForm.value.end?.toISOString();
        this.hierarchyService.terminateHierarchy(this.hierarchy.hierarchyId!, this.hierarchyTie.decisionTreeHeaderID!, this.user.id!, parsedToDate!)
            .subscribe(
                (data) => {
                    if (data.status == 200) {
                        this.dialogRef.close("1");
                    }
                },  //changed
                (err) => {
                    console.log(err)
                },
                () => console.log("Done")
            );
    }

    close() {
        this.dialogRef.close("0");
    }
}
