export const environment = {
    production: false,
    logHttpRequests: false,
    baseUrl: 'https://app-decisiontree-api-dev.azurewebsites.net/api',
    solrUrl: 'https://searchdev01.darwinrx.com:8983/solr/#',
    securityApiUrl: 'https://apidev.darwinrx.com/Security/api',
    layoutOriginPathUrl: 'https://abarcadev.darwinrx.com',
    layoutSecurityApiUrl: 'https://apidev.darwinrx.com/Security/api',
    layoutApiUrl: 'https://apidev.darwinrx.com/rxp/api/',
    rXPlatformUrl: 'https://abarcadev.darwinrx.com/clients/',
    rxpApiUrl: 'https://apidev.darwinrx.com/cdrevamp/api/',
    allowedUrlHttp: 'http://iddev.darwinrx.com',
    allowedUrlHttps: 'https://iddev.darwinrx.com',
    assetsApiUrl: './assets/themes/themeVars.json',
    securityApplicationId: 2,
    securityModuleId: 3500,
    version: '2.1.0-alpha.41',
    openid: {
        //  //issuer: 'https://idtest.darwinrx.com/identity/',
        //  //tokenUrl: 'https://idtest.darwinrx.com/identity/connect/token',
        issuer: 'https://iddev.darwinrx.com/identity/',
        tokenUrl: 'https://iddev.darwinrx.com/identity/connect/token',
        clientId: 'decisiontree2',
        clientSecret: '52cc012454fa49579c19dd70fd89daf1',
        scope: 'openid profile offline_access rxplatform_api security_api dtree_api',
        silentRequestTimeout: 3600,
        redirectUriPath: '',
        skipIssuerCheck: true,
        responseType: 'code',
        disablePKCE: true,
        disableIdTokenTimer: false,
        showDebugInformation: false,
        requestAccessToken: true,
        sendAccessToken: true,
        requirehttps: false
    },
    datadog: {
        applicationId: '298f8221-e403-4f31-8634-f2196c4cfa87',
        clientToken: 'pub8a251e29bec99a46291aba00e10eeb23',
        site: 'datadoghq.com',
        service: 'decisiontree2-ui',
        env: 'dev',
    }
};


export const DefaultStartDate = {

}

export const DefaultEndDate = {

}

export enum Status {
    Draft = 1,
    Pending = 2,
    Approved = 3,
    Deleted = 4
}

export enum Controllers {
    Hierarchy,
    DecisionTree,
    QuestionSet
}

export enum ReturnObjectType {
    Hierarchy,
    Plan,
    PCN,
    Group
}

export const DefaultClient = {
    id: 'ABCA',
    name: 'Abarca Health'
}



