import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPaths } from '../environments/routes';
import { environment, Controllers } from '../environments/environments';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CopyService {
    constructor(private http: HttpClient, private authService: AuthService) { }

    private getHeaders() {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public copyRecord(id: number, clientId: string, name: string, controller: Controllers): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();
        var url = environment.baseUrl;
        console.log('copy service');

        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientId);

        switch (controller) {
            case Controllers.Hierarchy: {
                queryParams = queryParams.append('HierarchyID', id);
                queryParams = queryParams.append('HierarchyName', name);
                url = url + ApiPaths.CopyHierarchy
                break;
            }
            case Controllers.DecisionTree: {
                queryParams = queryParams.append('DecisionTreeID', id);
                queryParams = queryParams.append('DecisionTreeName', name);
                url = url + ApiPaths.CopyDecisionTree
                break;
            }
            case Controllers.QuestionSet: {
                queryParams = queryParams.append('QuestionSetID', id);
                queryParams = queryParams.append('QuestionSetName', name);
                url = url + ApiPaths.CopyQuestionSet
                break;
            }
            default: {
                break;
            }
        }
        return this.http.post(url, null, { headers: httpHeaders, params: queryParams, observe: 'response' })
            .pipe(map((response: any) => response));
        //    return this.http.post<any>(url, null, { headers: httpHeaders, params: queryParams, observe: 'response' }).subscribe(
        //        result => {
        //            return result;
        //        }, error => {
        //            return error;
        //        });
        //}
    }
}
