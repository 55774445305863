<div class="actionResult actionResult-{{actionResult.actionType}}"
     style="width:unset;display:inline;"
     title="Action Result
Patient text:{{actionResult.actionTextPatient}}
Pharamcy text:{{actionResult.actionTextPharmacy}}
Provider text:{{actionResult.actionTextProvider}}

{{actionResult.routing}}
{{actionResult.comments}}">
    {{actionResult.actionType}}:
    {{actionResult.actionName}}

    <button class="btn btn-sm btn-primary" (click)="openActionResultModal()">Edit</button>
    <button class="btn btn-sm btn-danger" (click)="remove()">X</button>
</div>
