<!--<svg id="svgQuestionDisplay"

     oncontextmenu="return false;">
    <g id="svgGroup"></g>
    <g id="svgControls"></g>
</svg>-->
<div style="position:relative">
    <div style="position:absolute;bottom:25px; left:0px; background-color:#fff; z-index:44;">
        <button mat-button style="font-size: 24pt;" (click)="scaleUp()">
            <span class="material-icons">add</span>

        </button>
        {{ scale * 100 }}%
        <button mat-button style="font-size:24pt;" (click)="scaleDown()">
            <span class="material-icons">remove</span>
        </button>
    </div>
    <div id="treeDisplay" style="width: 100%;height: calc(100vh - 120px );overflow: auto;"
         oncontextmenu="return false;">
        <!--oncontextmenu="return false;"-->

        <div id="treeParent"
             style="transform: scale({{scale}}); transform-origin: 0% 0% 0px;">
            <!--flex-direction: column;">-->
            <section *ngFor="let qsc of this.p.questionSetControls" style="display:block">
                <div style="{{qsc.getContainerHeight()}}; ">
                    <div style="position:relative;" id="treearea-qs-{{qsc.initialOrder}}">
                        <section *ngFor="let q of qsc.GetQuestions() | sortBySequence">
                            <section>
                                <section>
                                    <div id="dc{{qsc.initialOrder}}-{{q.id}}"
                                         class="treenode"
                                         [class.invalid]="!qsc.ValidateQuestion(q)"
                                         (click)="jumpToQuestion(qsc.initialOrder ,q.id)">
                                        <!--style="{{qsc.getPositionForQuestion(q.id)}}"-->
                                        <div id="topS{{qsc.initialOrder}}Q{{q.id}}"
                                             class="topcard"></div>
                                        <span class="content">{{(q.internalOrder + qsc.internalOrderOffset)}} - {{q.text}}</span>
                                        <div id="botS{{qsc.initialOrder}}Q{{q.id}}"
                                             [class.bottomOfSet]="q.defaultNextQuestionID == 'End'"
                                             class="botcard"></div>
                                    </div>
                                </section>
                            </section>
                            <section>
                                <section *ngFor="let a of q.action">
                                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'|| a.actionType == '2Rph'"
                                         [attr.data-question-id]="q.id"
                                         class="treenode decision">
                                        <div id="topS{{qsc.initialOrder}}Q{{q.id}}A"
                                             [attr.data-question-connection]="qsc.initialOrder+'Q'+q.id"
                                             [attr.data-decision-text]="'*'"
                                             [attr.data-decision-label]="'Default'"
                                             class="topcard"></div>
                                        <span class="content">
                                            <app-decision-selection [action]="a"></app-decision-selection>
                                        </span>
                                    </div>
                                </section>
                            </section>
                            <section *ngFor="let answerChoice of qsc.GetSelectType(q).choice">
                                <section *ngFor="let a of answerChoice.action">
                                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval' ||a.actionType == '2Rph'"
                                         [attr.data-question-id]="q.id"
                                         class="treenode decision">
                                        <div id="topS{{qsc.initialOrder}}Q{{q.id}}A{{answerChoice.id}}"
                                             [attr.data-question-connection]="qsc.initialOrder+'Q'+q.id"
                                             [attr.data-decision-text]="answerChoice.id"
                                             [attr.data-decision-label]="answerChoice.choiceText"
                                             class="topcard"></div>
                                        <span class="content">
                                            <app-decision-selection [action]="a"></app-decision-selection>
                                        </span>
                                    </div>
                                </section>
                            </section>
                            <section *ngIf="qsc.GetQuestionTypeFromQuestion(q) == 'Numeric'">
                                <section *ngFor="let nqc of qsc.GetNumericType(q).nextQuestionCondition">
                                    <section *ngFor="let a of  nqc.comparison.action, let i= index">
                                        <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' || a.actionType == 'Partial Approval' || a.actionType == '2Rph'"
                                             [attr.data-question-id]="q.id"
                                             class="treenode decision">
                                            <div id="topS{{qsc.initialOrder}}Q{{q.id}}A{{i}}"
                                                 [attr.data-question-connection]="qsc.initialOrder+'Q'+q.id"
                                                 [attr.data-decision-text]="'x>y'"
                                                 [attr.data-decision-label]="'Question condition satisified'"
                                                 class="topcard"></div>
                                            <span class="content">
                                                <app-decision-selection [action]="a"></app-decision-selection>
                                            </span>
                                        </div>
                                    </section>
                                </section>
                            </section>
                            <section *ngIf="qsc.GetQuestionTypeFromQuestion(q) == 'Date'">
                                <section *ngFor="let nqc of qsc.GetDateType(q).nextQuestionCondition">
                                    <section *ngFor="let a of  nqc.comparison.action, let i= index">
                                        <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'|| a.actionType == '2Rph'"
                                             [attr.data-question-id]="q.id"
                                             class="treenode decision">

                                            <div id="topS{{qsc.initialOrder}}Q{{q.id}}A{{i}}"
                                                 [attr.data-question-connection]="qsc.initialOrder+'Q'+q.id"
                                                 [attr.data-decision-text]="'x>y'"
                                                 [attr.data-decision-label]="'Question condition satisified'"
                                                 class="topcard"></div>
                                            <span class="content">
                                                <app-decision-selection [action]="a"></app-decision-selection>
                                            </span>
                                        </div>
                                    </section>
                                </section>
                            </section>
                            <section *ngFor="let nqc of qsc.GetSelectType(q).nextQuestionCondition">
                                <section *ngFor="let a of  nqc.comparison.action, let i= index">
                                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'|| a.actionType == '2Rph'"
                                         [attr.data-question-id]="q.id"
                                         class="treenode decision">

                                        <div id="topS{{qsc.initialOrder}}Q{{q.id}}A{{i}}"
                                             [attr.data-question-connection]="qsc.initialOrder+'Q'+q.id"
                                             [attr.data-decision-text]="'x>y'"
                                             [attr.data-decision-label]="'Question condition satisified'"
                                             class="topcard"></div>
                                        <span class="content">
                                            <app-decision-selection [action]="a"></app-decision-selection>
                                        </span>
                                    </div>
                                </section>
                            </section>
                        </section>
                    </div>
                </div>
                {{qsc.allowTreeDraw()}}
                <!--{{qsc.addLines()}}    {{qsc.addDecisions()}}    {{qsc.addSetConnections()}}-->
            </section>
        </div>
    </div>
</div>