<h2 mat-dialog-title style="width:400px;">{{title}}</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>


<mat-dialog-content class="dialog-detail">
    <p *ngIf="dialogText != ''">{{dialogText}}</p>
    <mat-form-field *ngIf="showComment" class="full-width" appearance="outline">
        <mat-label for="text">{{labelForComment}}</mat-label>
        <textarea matInput class="full-width" id="text"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAuthsizeMinRows="1"
                  cdkAuthsizeMaxRows="10"
                  [(ngModel)]="comment" name="text"></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="close()" style="border-radius:24px;">
        Cancel
    </button>
    <button mat-flat-button color="accent" (click)="save()"  style="border-radius:24px;">
        {{submitButtonText}}
    </button>
</mat-dialog-actions>
