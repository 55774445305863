<div class="table-dashboard">

    <section class="column-6">
        <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput (keyup)="applyFilter($event)" [(ngModel)]="searchInput" placeholder="Ex. Name of PCN" #input>
        </mat-form-field>
    </section>

    <section class="column-6">
        <mat-form-field appearance="outline">
            <mat-label>Client</mat-label>
            <mat-select [(ngModel)]="selectedClient" name="selectedClient" (selectionChange)="onSelectClient()">
                <mat-option *ngFor="let c of clientsList" [value]="c">
                    {{c.clientName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </section>

    <section>
        <!--<button mat-stroked-button color="primary" class="column-3" (click)="openAddModal()">Add&nbsp;New</button>-->
        <button mat-stroked-button color="primary" class="column-3" (click)="exportTable()">Export&nbsp;Table</button>
        <button mat-stroked-button color="primary" class="column-3" (click)="exportTiesClient(selectedClient)">Export&nbsp;Client Ties</button>
        <button mat-stroked-button color="primary" class="column-3" [routerLink]="['../hierarchy/test']">Test</button>
    </section>

    <section>
        <div class="">
            <table mat-table [dataSource]="dataSource" matSort>

                <!-- Client Column -->
                <ng-container matColumnDef="clientCompany" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                    <td mat-cell *matCellDef="let row"> {{row.clientCompany}} </td>
                </ng-container>

                <!--description Column-->
                <ng-container matColumnDef="desc" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.desc}} </td>
                </ng-container>

                <!--pcn Column-->
                <ng-container matColumnDef="pcn" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Line of Business </th>
                    <td mat-cell *matCellDef="let row"> {{row.lobPcn}} </td>
                </ng-container>

                <!--plan Column-->
                <ng-container matColumnDef="plan" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Plan </th>
                    <td mat-cell *matCellDef="let row"> {{row.plan}} </td>
                </ng-container>

                <!--group Column-->
                <ng-container matColumnDef="group" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>
                    <td mat-cell *matCellDef="let row"> {{row.group}} </td>
                </ng-container>

                <!--start Column
                <ng-container matColumnDef="start" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Start</h4> </th>
                    <td mat-cell *matCellDef="let row"> {{row.startDate  | date: 'MM/dd/YYYY'}} </td>
                </ng-container>

                <!--end Column
                <ng-container matColumnDef="end" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>End</h4> </th>
                    <td mat-cell *matCellDef="let row"> {{row.endDate  | date: 'MM/dd/YYYY'}} </td>
                </ng-container>

                <!--details Column-->
                <ng-container matColumnDef="details" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> &nbsp; </th>
                    <td mat-cell *matCellDef="let row"> <button mat-button color="primary" (click)="openDetailsModal(row)">View ties</button> </td>
                </ng-container>

                <!--export Column-->
                <ng-container matColumnDef="export" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> &nbsp; </th>
                    <td mat-cell *matCellDef="let row"> <button mat-button color="primary" (click)="exportTies(row)">Export</button> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of hierarchies"></mat-paginator>
        </div>
    </section>
</div>
