import { ApplicationRef, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { User } from '../../classes/user';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { QuestionSetControl } from '../../shared/question-set.control';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ApplicationStatus, QuestionLanguage, QuestionSetHeader, QuestionTypeSelect } from '../../classes/questionset';
import { SVGQuestionDisplay } from '../../shared/question-set-display';
//import { RoutingModalComponent } from './routing-modal/routing-modal.component';
import { AddQuestionModalComponent } from './add-question-modal/add-question-modal.component';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { CommentDialogComponent } from '../../shared/comment-dialog/comment-dialog.component';

declare var startQuestionSetReview: any;
//declare var svgHandler: any;


@Component({
    selector: 'app-question-edit',
    templateUrl: './question-edit.component.html',
    styleUrls: ['./question-edit.component.scss'],
    //standalone: true,
    //imports: [MatTabsModule]
})
export class QuestionEditComponent {
    EditID: number = 0;
    headerInfo: QuestionSetHeader | undefined;
    closeResult = '';
    clients: (string | undefined)[] | undefined;
    active = 1;
    questionLanguage: QuestionLanguage[] = [];
    applicationStatusList: ApplicationStatus[] = [];
    svgQuestionDisplay: SVGQuestionDisplay;
    addNewQuestionForm = this.fb.group({
        label: ['', Validators.required],
        selectQuestionType: ['', Validators.required]
    });
    enabledApplicationForm = this.fb.group({
        applicationList: []
    });
    constructor(
        private location: Location,
        public questionSetService: QuestionSetService,
        public questionSetControl: QuestionSetControl,
        /*this.modalService,*/
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private dialog: MatDialog,
    ) {
        this.svgQuestionDisplay = new SVGQuestionDisplay();
    }
    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.EditID = params['id'];
        });
        this.questionSetService.getQuestionSetDetailFromService(this.EditID).subscribe(
            resp => {
                this.headerInfo = new QuestionSetHeader(
                    resp.body.questionSetHeader
                );
                var qsJson = JSON.parse(resp.body.questionSet.questionSetData);
                console.log("Load >>>", qsJson);
                this.questionLanguage = resp.body.questionSetLanguage;
                console.log("Language load:::",this.questionLanguage);
                //debugger;
                this.questionSetControl.LoadQuestionSet(qsJson);
                this.applicationStatusList = [];
                resp.body.applicationDetails.forEach((e: any) => {
                    this.applicationStatusList.push(new ApplicationStatus(e))
                });
                
                this.loadDisplay();
            }
        );
    }
    loadDisplay() {
        this.svgQuestionDisplay.SetCanvas("svgGroup", "svgControls")
        this.svgQuestionDisplay.LoadCanvas(this.questionSetControl.GetQuestionDetails());
        this.svgQuestionDisplay.LoadControls();
        //svgHandler(this.svgQuestionDisplay);
    }
    reloadChanges() {
        this.svgQuestionDisplay.LoadCanvas(this.questionSetControl.GetQuestionDetails());
    }
    openinteractiveQuestionAnswerModal() {
        var qs = this.questionSetControl.GetQuestionSet();
        startQuestionSetReview(qs?.questionSetHeader?.title, qs);
    }
    openAddQuestionModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: null,
            questionSetControl: this.questionSetControl
        }
        const dialogRef = this.dialog.open(AddQuestionModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    saveQuestionSet() {

        let langResult: any = null;
        
        let langRoot: any = document.getElementById("tree-view-language");
        if (langRoot !== null) {
            langResult = [];
            let inputArray: any = langRoot.querySelectorAll("input");
            inputArray.forEach((e: any) => {
                if (e.value != '') {
                    langResult.push({
                        languageTextGuid: e.dataset.guid,
                        languageText: e.value,
                        languageCode: e.dataset.code,
                        recipient: e.dataset.recipient,
                    });
                }
            });
        }
        var appsEnabled: number[] = [];
        document.getElementsByName("applicationList").forEach((e: any) => {
            if (e.checked) appsEnabled.push(e.value);
        });
        var qs = this.questionSetControl.GetQuestionSet();
        console.log(this.enabledApplicationForm.value);
        if (qs !== undefined) {
            console.log("SAVING " + this.EditID)
            this.questionSetService.SaveQuestionSet(this.EditID, appsEnabled, qs, langResult).subscribe(resp => {
                if (resp.body.message != "" && resp.body.message != null) {
                    alert("Save Failed");
                } else {
                    this.EditID = resp.body.result;
                    this.location.replaceState('/questionset/edit?id=' + this.EditID);
                    //console.log("SAVED", this.EditID);
                    var updates = JSON.parse(resp.body.extension);
                    //console.log(updates);
                    if (updates != null) {
                        Object.keys(updates).forEach((e: any) => {
                            var t = updates[e].split("-");
                            var q = this.questionSetControl.GetQuestionByID(t[1]);
                            //console.log(t, q);
                            if (q != null) {
                                //console.log(t[0]);
                                switch (t[0]) {
                                    case "q":
                                        console.log(t[0] + " language set to " + e);
                                        q.languageGuid = e;
                                        break;
                                    case "qc":
                                        var choice = (<QuestionTypeSelect>q.questionType).choice.filter(
                                            (c: any) => { return t[2] == c.id; })
                                        console.log(t[0] + " Choice " + t[2] + " language set to " + e);
                                        if (choice != null) {
                                            choice[0].choiceTextGuid = e;
                                        }
                                        break;
                                    default:
                                        console.log("Error loading language guid")
                                        break;
                                }
                            }
                        });
                    }
                }
            });
        }
    }
    approveQuestionSet() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Approval Comment"
        }
        const dialogRef = this.dialog.open(CommentDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != "0") {
                    this.approve(data)
                }
            }
        );
    }
    approve(comment:string) {

        let langResult: any = null;

        let langRoot: any = document.getElementById("tree-view-language");
        if (langRoot !== null) {
            langResult = [];
            let inputArray: any = langRoot.querySelectorAll("input");
            inputArray.forEach((e: any) => {
                if (e.value != '') {
                    langResult.push({
                        languageTextGuid: e.dataset.guid,
                        languageText: e.value,
                        languageCode: e.dataset.code,
                        recipient: e.dataset.recipient,
                    });
                }
            });
        }
        var appsEnabled: number[] = [];
        document.getElementsByName("applicationList").forEach((e: any) => {
            if (e.checked) appsEnabled.push(e.value);
        });
        var qs = this.questionSetControl.GetQuestionSet();
        console.log(this.enabledApplicationForm.value);
        if (qs !== undefined) {
            console.log("SAVING " + this.EditID)
            this.questionSetService.ApproveQuestionSet(this.EditID, appsEnabled, comment, qs, langResult).subscribe(resp => {
                if (resp.body.message != "" && resp.body.message != null) {
                    var msg = resp.body.message.replaceAll(",", "\n");
                    alert("Approve failed: " + msg);
                } else {
                    this.EditID = resp.body.result;
                    this.location.replaceState('/questionset/edit?id=' + this.EditID);
                    console.log("SAVED", this.EditID);

                    var updates = JSON.parse(resp.body.extension);
                    console.log(updates);
                    if (updates != null) {
                        Object.keys(updates).forEach((e: any) => {
                            var t = updates[e].split("-");
                            var q = this.questionSetControl.GetQuestionByID(t[1]);
                            console.log(t, q);
                            if (q != null) {
                                console.log(t[0]);
                                switch (t[0]) {
                                    case "q":
                                        console.log(t[0] + " language set to " + e);
                                        q.languageGuid = e;
                                        break;
                                    case "qc":
                                        var choice = (<QuestionTypeSelect>q.questionType).choice.filter(
                                            (c: any) => { return t[2] == c.id; })
                                        console.log(t[0] + " Choice " + t[2] + " language set to " + e);
                                        if (choice != null) {
                                            choice[0].choiceTextGuid = e;
                                        }
                                        break;
                                    default:
                                        console.log("Error loading language guid")
                                        break;
                                }
                            }
                        });
                    }
                    alert("Approved");
                }
            });
        }
    }
    
}
