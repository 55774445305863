import { Component, Input } from '@angular/core';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionResult } from '../../../classes/questionset';
import { ActionresultModalComponent } from '../actionresult-modal/actionresult-modal.component';


@Component({
  selector: 'app-actionresult-template',
  templateUrl: './actionresult-template.component.html',
  styleUrls: ['./actionresult-template.component.scss']
})
export class ActionresultTemplateComponent {
    @Input() actionResult!: ActionResult;
    @Input() p!: any;
    closeResult = '';
    constructor(
        /*private modalService: NgbModal*/
    ) { }
    openActionResultModal() {
        //const modalRef = this.modalService.open(ActionresultModalComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.p = this;
        //modalRef.componentInstance.actionResult = this.actionResult;
        //modalRef.result.then((result) => { this.closeResult = 'Closed'; }, (reason) => { this.closeResult = `Dismissed`; });
    }
    remove() {
        console.log(this.p);
        this.p.RemoveActionResult(this.actionResult);
    }


}
