<h2 mat-dialog-title>Authorization Period</h2>
<div mat-dialog-content>
    <span style="font-weight:400;font-size:24px; line-height:32px">
        Enter Custom Period
    </span>
    <div class="column-4">
        <mat-form-field appearance="outline" class="full-width" color="accent">
            <mat-label>Quantity</mat-label>
            <input matInput required [(ngModel)]="customPeriod.value" type="number">
            <mat-error style="white-space: nowrap;">Add quantity</mat-error>
        </mat-form-field>
    </div>
    <div class="column-8">
        <mat-form-field appearance="outline" class="full-width" color="accent">
            <mat-label>Unit</mat-label>
            <mat-select [(ngModel)]="customPeriod.unit">
                <mat-option value="Hours">Hours</mat-option>
                <mat-option value="Days">Days</mat-option>
                <mat-option value="Weeks">Weeks</mat-option>
                <mat-option value="Months">Months</mat-option>
                <mat-option value="Year">Year</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="accent" (click)="addPeriod()"
            [disabled]="customPeriod.value == ''"
            >
        Add
    </button>
</mat-dialog-actions>
