
<h4>Justification Details</h4>
<section>
    <form [formGroup]="detailForm">
        <div class="column-12">
            <mat-form-field appearance="outline" class=" full-width">
                <mat-label>Description</mat-label>
                <input matInput formControlName="desc" type="text">
            </mat-form-field>
        </div>
        <div class="column-4">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Decision</mat-label>
                <mat-select formControlName="decision" (selectionChange)="onDecisionChange()" placeholder="Select a decision">
                    <mat-option value="Approve">Approve</mat-option>
                    <mat-option value="Deny">Deny</mat-option>
                    <mat-option value="Partial Approval">Partial Approval</mat-option>
                    <mat-option value="RFI">Missing Information</mat-option>
                    <mat-option value="2Rph">Needs Pharmacist Review</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="column-8">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Decision Reason</mat-label>
                <mat-select formControlName="decisionReason" placeholder="Select a decision reason">
                    <mat-option value="Reason1">Reason 1</mat-option>
                    <mat-option value="Reason2">Reason 2</mat-option>
                    <mat-option value="Reason3">Reason 3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="column-6">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Client</mat-label>
                <mat-select name="clientList" formControlName="client" (selectionChange)="updateClient()" placeholder="Select a client">
                    <mat-option *ngFor="let c of clients; index as i; first as isFirst" [value]="c">
                        {{c.clientName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="column-6">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Line of Business</mat-label>
                <mat-select name="pcnList" formControlName="pcn" (selectionChange)="updatePCN()" placeholder="Select a line of business">
                    <mat-option *ngFor="let p of pcns; index as i; first as isFirst" [value]="p">
                        {{p.pcnDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</section>
