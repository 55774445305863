import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuestionSetService } from '../../shared/services/question-set.service';

@Component({
  selector: 'app-question-set-to-decision-tree-usage-dialog',
  templateUrl: './question-set-to-decision-tree-usage-dialog.component.html',
  styleUrls: ['./question-set-to-decision-tree-usage-dialog.component.scss']
})
export class QuestionSetToDecisionTreeUsageDialogComponent {
    public usageResults: any;
    public currentVersion: number;
    public loaded: boolean = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<QuestionSetToDecisionTreeUsageDialogComponent>,
        public questionSetService: QuestionSetService
    ) {
        this.currentVersion = data.currentVersion;
        this.questionSetService.getQuestionSetUsage(data.questionSetHeaderID).subscribe(
            (resp:any) => {
                this.usageResults = resp.body;
                console.log(this.usageResults);
                this.loaded = true;
            }
        );
    }
    close() {
        this.dialogRef.close("0");
    }
}
