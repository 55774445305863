<div class="answerChoice" [attr.data-choiceid]="answerChoice.id">
    <div class="column-6">
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>
                {{answerChoice.sequence}}
            </mat-label>
            <input matInput type="text" class="full-width" id="choiceText"
                   [class.i-am-error-border]
                   required placeholder="Enter answer choice text"
                   [(ngModel)]="answerChoice.choiceText" name="text" />
            <mat-hint *ngIf="answerChoice.choiceText?.length == 0" class="i-am-error error-inline">
                Please provide an Answer.
            </mat-hint>
        </mat-form-field>
        
    </div>
    <div class="column-2">
        <mat-form-field class="full-width" appearance="outline">
            <mat-select class="full-width" id="additionalFreeTextIndicator"
                        required
                        [(ngModel)]="answerChoice.additionalFreeTextIndicator" name="additionalFreeTextIndicator">
                <mat-option value="NA">N/A</mat-option>
                <mat-option value="O">Optional</mat-option>
                <mat-option value="MA">Required</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="column-3">
        <button mat-flat-button color="accent" class="fake-select" (click)="openRoutingModal()">
            {{GetNextQuestionLabel()}} <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>
    </div>
    <div class="column-1">
        <button mat-flat-button [matMenuTriggerFor]="answerchoicemenu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #answerchoicemenu="matMenu">
            <button mat-flat-button color="basic" class="full-width" (click)="openActionResultModal()">Add Action</button>
            <button mat-flat-button color="basic" class="full-width" (click)="openCodedReferenceModal()">Add Coded Reference</button>
            <button mat-flat-button color="basic" class="full-width" disabled>Move</button>
            <button mat-flat-button color="warn" class="full-width" (click)="remove()">Remove</button>
        </mat-menu>
    </div>
    <span *ngFor="let cr of answerChoice.codedReference" style="width:unset;margin:4px;">
        <app-codedreference-template [codedReference]="cr" [p]="answerChoice"></app-codedreference-template>
    </span>
    <div *ngFor="let ar of answerChoice.action" style="width: unset; margin: 4px;">
        <app-actionresult-template [actionResult]="ar" [p]="this"></app-actionresult-template>
    </div>
</div>


