import { Component, Inject, Input } from '@angular/core';
import { Question } from '../../../classes/questionset';
import { QuestionSetControl } from '../../../shared/question-set.control';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent {
    @Input() question!: Question;
    @Input() questionSetControl!: QuestionSetControl;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<QuestionModalComponent>,
    ) {
        if (data !== undefined) {
            this.question = data.question;
            this.questionSetControl = data.questionSetControl;
        }
    }
    close() {
        this.dialogRef.close("0");
    }
}
