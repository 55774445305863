import { Component, Input } from '@angular/core';
import { Client } from '../../classes/client';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';

@Component({
  selector: 'justification',
  templateUrl: './justification.component.html',
  styleUrls: ['./justification.component.scss']
})
export class JustificationComponent {
    @Input() justification!: JustificationSettings;
    @Input() clients!: Client[];
    @Input() p!: any;


}
