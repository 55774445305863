<h2 mat-dialog-title>Add To Catalog</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>


<mat-dialog-content>
    <form id="addNewQuestionSetForm" [formGroup]="addNewQuestionSetForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="clientSelect">Client</label>
            <select class="form-select" id="clientSelect" formControlName="clientSelect">
                <option *ngFor="let c of clients; index as i; first as isFirst" value="{{c.clientID}}">{{c.clientName}}</option>
            </select>
        </div>

        <div class="form-group">
            <label for="questionSetName">Name:</label>
            <input type="text" autocomplete="off" class="full-width" id="questionSetName" formControlName="questionSetName" placeholder="Enter a name">
        </div>
        <div class="form-group">
            <label for="questionSetDescription">Description:</label>
            <input type="text" autocomplete="off" class="full-width" id="questionSetDescription" formControlName="questionSetDescription" placeholder="Enter a description">
        </div>
        <div class="form-group">
            <label for="questionSetTypeID">Type</label>
            <select class="form-select" id="questionSetTypeID" formControlName="questionSetTypeID">
                <option value="1">PA</option> <!--DT CoMpOnEnT shuold be replaced by lookup-->
                <option value="2">1st Appeal</option>
                <option value="3">2nd Appeal</option>
                <option value="4">3rd Appeal</option>
            </select>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="addNewQuestionSetForm" 
            mat-raised-button color="primary">Add</button>
    <!--[disabled]="!importForm.valid"-->
</mat-dialog-actions>