import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, forkJoin, map, Observable, of, switchMap, tap, toArray } from 'rxjs';
import { Client } from '../../classes/client';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { JustificationsService } from '../justifications.service';

@Component({
  selector: 'justification-delete-modal',
  templateUrl: './justification-delete-modal.component.html',
  styleUrls: ['./justification-delete-modal.component.scss']
})
export class JustificationDeleteModalComponent {
    currentFilteredClient!: Client;
    justificationToDelete!: JustificationSettings;
    justificationTemplateID: number | undefined;
    @Output() event: EventEmitter<any> = new EventEmitter();
    user!: User;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<JustificationDeleteModalComponent>,
        private authService: AuthService,
        private justificationService: JustificationsService
    ) {
        this.justificationTemplateID = data.justificationTemplateID;
        this.currentFilteredClient = data.currentFilteredClient;
        this.user = this.authService.getUserLoginInfo();
        this.getJustificationTemplate();
    }

    getJustificationTemplate() {
        this.justificationService.getJustificationByTemplateID(this.justificationTemplateID!).subscribe(resp => {
            if (resp.body) {
                this.justificationToDelete = this.justificationService.mapJustificationSettingsFromResponse(resp.body);
                console.log(this.justificationToDelete, 'justification settings');
            }
        });
    }

    confirmDelete(){
        this.justificationService.deleteJustificationTemplate(this.justificationToDelete.JustificationTemplateID!, this.user.id!).subscribe(resp => {
            if (resp.body) {
                this.dialogRef.close("0");
            }
        });
    }

    close() {
        this.dialogRef.close("0");
    }
}
