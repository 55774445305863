<h2 mat-dialog-title>Coded Reference</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content style="width:800px;">
    <label>Select coded reference to add:</label>
    <mat-form-field class="full-width" appearance="outline">
        <input matInput (keyup)="applyFilter(filterValue)" placeholder="Search..." [(ngModel)]="filterValue">
    </mat-form-field>
    <div class="full-width" style="text-align:center;" *ngIf="filteredCodedReferences.length ==0">
        Please enter search term to find avaialble coded references.
    </div>
        <div class="table-responsive" *ngIf="filteredCodedReferences.length >=1">
            <table class="table table-striped table-sm">
                <thead>
                    <tr>
                        <th scope="col">Qualifier</th>
                        <th scope="col">Reference</th>
                        <th scope="col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cr of filteredCodedReferences; index as i; first as isFirst">
                        <td>{{cr.qualifier}}</td>
                        <td>{{cr.description}}</td>
                        <td>
                            <button mat-raised-button (click)="AddCodedReference(cr)">
                                <span class="material-icons" style="">add</span>
                                Add
                            </button>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
</mat-dialog-actions>
