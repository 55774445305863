<div class="cntr" (dragover)="dragOver($event)" (drop)="dragDrop($event)">
    <div id="leftSide" class=" lside">
        <div class="infoSection">
            
        </div>
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false" mat-align-tabs="start" color="accent" id="questionlisttabs">
            <mat-tab label="Questions">
                <div class="questionSection">
                    <div *ngIf="questionSetControls.length == 0" style="text-align:center;background-color:white; width:100%;height:100%">
                        <br />
                        <button mat-button color="accent" (click)="addNewQuestionSet()">
                            <mat-icon>add</mat-icon> Add question
                        </button><br />
                        or<br />
                        <button mat-button (click)="openCatalog()" color="accent">
                            Choose from Questions Catalog
                        </button><br />
                    </div>
                    <div *ngFor="let qsc of questionSetControls">
                        <app-edit-questions-list [p]="this" [questionSetControl]="qsc"></app-edit-questions-list>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Decision">
                <div class="questionSection">
                    <div *ngFor="let qsc of questionSetControls | sortByOrder">
                        <app-edit-question-decision-list [questionSetControl]="qsc"></app-edit-question-decision-list>
                    </div>
                </div>
            </mat-tab>
            <mat-tab class="runner" disabled>
            </mat-tab>
            <mat-tab disabled>
                <ng-template mat-tab-label>
                    <button mat-button (click)="openCatalog()" color="accent">
                        <mat-icon>search</mat-icon> Questions Catalog
                    </button>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
        <div class="decison_tree_actions" style="width:100%; text-align:right;margin-top:3px;"
             *ngIf="decisionTree != null">
            <button (click)="showDecisions()">switch to decision view</button>
            <button (click)="showQuestions()">switch to Questions view</button>
            <button mat-stroked-button color="accent" (click)="approve()"
                    *ngIf="decisionTree.decisionTreeStatus == 2">
                Approve
            </button> &nbsp;
            <button mat-stroked-button color="accent" (click)="unsetApprovalRequest()"
                    *ngIf="decisionTree.decisionTreeStatus == 2">
                Deny Approval
            </button> &nbsp;
            <button mat-stroked-button color="accent" (click)="approveRequest()"
                    *ngIf="decisionTree.decisionTreeStatus == 1">
                Submit For Approval
            </button> &nbsp;
            <button mat-raised-button color="accent" (click)="save()"
                    *ngIf="decisionTree.decisionTreeStatus == 1">
                Save
            </button> &nbsp;
            <button mat-raised-button color="accent" (click)="save()"
                    *ngIf="decisionTree.decisionTreeStatus == 3">
                Edit
            </button> &nbsp;
            <button mat-raised-button color="primary" (click)="test()">
                Test
            </button> &nbsp; &nbsp; &nbsp;



        </div>

    </div>
    <div class=" mvr" draggable="true" (dragstart)="dragStart($event)">
        <div class="dotz">
            ..<br />
            ..<br />
            ..<br />
        </div>
    </div>
    <div id="rightSide" class=" rside">
        <app-edit-questions-tree [p]="this"></app-edit-questions-tree>
    </div>
</div>
<div id="interactiveQuestionAnswerModal">
    <div id="modalOperationControls">
        <button class="btn btn-sm btn-outline-primary" onclick='hideReviewQuestionArea()'>X</button>
    </div>
    <div id="interactiveQuestionAnswerSection"></div>
    <div class="simpleright">
        <button class="reset-button" onclick='resetReviewQuestionArea()'>
            Reset
        </button>
        <button class="close-button" onclick='hideReviewQuestionArea()'>
            Close
        </button>
    </div>
    <!--<button onclick='alert(JSON.stringify(getDecisionTreeAnswers())); return false;'>Test Save</button>-->
</div>