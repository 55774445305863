import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortByMajorVersion',
    standalone: true
})
export class SortByMajorVersionPipe implements PipeTransform {

    transform(value: any): any {
        return value.sort((n1: any, n2: any) => {
            //return n2.majorVersion - n1.majorVersion;
            var a = parseInt(n2.majorVersion) + (.0001 * parseInt(n2.minorVersion));
            var b = parseInt(n1.majorVersion) + (.0001 * parseInt(n1.minorVersion));
            return a - b;
        });
  }

}
