import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DecisionTree, DecisionTreeHeader } from '../../classes/decisiontree';
import { Hierarchy, HierarchyTies } from '../../classes/hierarchy';
import { QuestionSetData, QuestionSetTable } from '../../classes/questionsetdashboard';
import { User } from '../../classes/user';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { AuthService } from '../../shared/auth/auth.service';
import { QuestionSetControl } from '../../shared/question-set.control';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { DecisionTreeService } from '../decision-tree.service';
import { TestDecisiontreeModalComponent } from '../test-decisiontree-modal/test-decisiontree-modal.component';
import { Status } from '../../shared/environments/environments';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Client } from '../../classes/client';
import { DateService } from '../../shared/services/date.service';
import { MatIconModule } from '@angular/material/icon';

declare var startQuestionSetReview: any;
const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
    selector: 'app-decision-tree-edit',
    standalone: true,
    imports: [MatPaginatorModule, MatSelectModule, MatIconModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatNativeDateModule, MatDatepickerModule, MatCheckboxModule, MatButtonModule, MatDividerModule, MatCardModule, CommonModule, NgIf, NgFor, FormsModule, ReactiveFormsModule],
    templateUrl: './decision-tree-edit.component.html',
    styleUrls: ['./decision-tree-edit.component.scss']
})
export class DecisionTreeEditComponent implements OnInit {
    title = 'Edit Decision Tree';

    displayedColumnsHT: string[] = ['client', 'qualifier', 'drugid', 'case', 'subtype', 'tier', 'start', 'end'];

    dataSourceHT: MatTableDataSource<HierarchyTies> = new MatTableDataSource<HierarchyTies>([]);

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;

    user!: User;
    EditID: number = 0;
    questionSet: QuestionSetTable[] | undefined = [];
    clientSelection = new FormControl('');
    clientsList: Client[] = [];
    selectedClient!: Client;
    searchInput: string = '';


    closeResult = '';


    decisionTree: DecisionTree | undefined;
    decisionTreeHeader: DecisionTreeHeader | undefined;
    questionset: QuestionSetData[] = [];
    hierarchyTies: HierarchyTies[] = [];
    filteredHierarchyTies: HierarchyTies[] = [];
    availableQuestions: QuestionSetTable[] | undefined;
    //hoveredDate: NgbDate | null = null;
    selectedApplication: number | undefined;
    dtStatus = Status;

    //fromDate: NgbDate | null = null;
    //toDate: NgbDate | null = null;

    editForm = this.fb.group({
        comments: [''],
        start: [new Date(), Validators.required],
        end: [new Date(), Validators.required],
        questions: this.fb.array([])
    });
    effectiveDate = this.fb.group({
        past: true,
        current: true,
        future: true,
    });
    status = this.fb.group({
        pending: true,
        draft: true,
        approved: true,
        /*deleted: true,*/
    });

    constructor(
        public decisiontreeService: DecisionTreeService,
        public questionsetDashboardService: QuestionSetDashboardService,
        public authService: AuthService,
        public questionSetControl: QuestionSetControl,
        public hierarchyService: HierarchyService,
        public questionSetService: QuestionSetService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private dateService: DateService
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.route.queryParams.subscribe((params: { [x: string]: number; }) => {
            this.EditID = params['id'];
        });
        this.getDecisionTree();
    }


    ngOnInit() {

    }

    getDecisionTree() {
        this.decisiontreeService.getDecisionTreeByID(this.EditID).subscribe(resp => {
            if (resp.body) {
                this.decisionTree = this.decisiontreeService.mapSingleDecisionTreeResponse(resp);
                //get the header with the id from the fetched decision tree
                if (this.decisionTree != undefined) {
                    console.log(this.decisionTree);
                    this.editForm.controls.start.patchValue(this.decisionTree.startDate!);
                    console.log(this.decisionTree.startDate!);
                    this.editForm.controls.end.patchValue(this.decisionTree.endDate!);
                    console.log(this.decisionTree.endDate!);
                    this.getHierarchyTies(this.decisionTree);
                    this.getDecisionTreeHeader(this.decisionTree);
                }
            }
        });
    }

    getDecisionTreeHeader(decisionTree: DecisionTree) {
        this.decisiontreeService.getDecisionTreeHeaderByID(decisionTree.decisionTreeHeaderId!).subscribe(resp => {
            if (resp.body) {
                this.decisionTreeHeader = this.decisiontreeService.mapSingleDecisionTreeHeaderResponse(resp);
                this.decisionTree = this.decisiontreeService.applyHeader(this.decisionTree!, this.decisionTreeHeader);
                this.getQuestionSet(this.decisionTree);
            }
        });
    }

    getQuestionSet(decisionTree: DecisionTree) {
        this.decisiontreeService.getQuestionSetsByDecisionTreeID(decisionTree.decisionTreeID!).subscribe(resp => {
            if (resp.body) {
                this.questionset = this.decisiontreeService.mapQuestionSetDataToDecisionTreeResponse(resp);
                console.log("questions on the tree");
                console.log(this.questionset);
                this.populateQuestionSetList();
            }
        });
    }

    getHierarchyTies(decisionTree: DecisionTree) {
        this.decisiontreeService.getHierarchyTiesByID(decisionTree.decisionTreeID!).subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.hierarchyTies = this.hierarchyService.mapHierarchyTiesResponse(resp);
                //console.log(this.hierarchyTies);
                this.dataSourceHT = new MatTableDataSource(this.hierarchyTies);
                this.dataSourceHT.paginator = this.paginator;
                this.dataSourceHT.sort = this.sort;
            }
        });
    }

    populateQuestionSetList() {
        this.questionsetDashboardService.getAllQuestionSetDashboardFromService(this.decisionTree!.clientID!).subscribe(resp => {
            if (resp.body) {
                this.availableQuestions = this.questionsetDashboardService.mapQuestionSetDashboardResponse(resp);
                console.log('available questions');
                console.log(this.availableQuestions);
                this.createQuestionInputs(this.questionset);
            }
        });
    }

    createQuestionInputs(qs: QuestionSetData[]) {
        for (let i = 0; i < qs.length; i++) {
            const q = qs[i];
            this.addQuestionSet(q.questionSetID);

            //const questionGroup = this.questions.at(i) as FormGroup;
            //questionGroup.patchValue({ 'questionSetID': q.questionSetID });
            //questionGroup.addControl('questionSetID', new FormControl(q.questionSetID)); // Add the control to the form group
        }
    }

    get questions() {
        return this.editForm.controls["questions"] as FormArray;
    }

    addQuestionSet(input: number | undefined = undefined) {
        const questionSetForm = this.fb.group({
            questionSetID: [input]
        });
        //questionSetForm.patchValue({ 'questionSetID': input });
        this.questions.push(questionSetForm);
    }

    deleteQuestionSet(index: number) {
        this.questions.removeAt(index);
    }

    toGroup(absCtrl: AbstractControl): FormGroup {
        const ctrl = absCtrl as FormGroup;
        return ctrl;
    }

    openinteractiveQuestionAnswerModal(d: DecisionTree) {
        alert("MODAL");

    }

    openActualinteractiveQuestionAnswerModal() {
        if (this.EditID) {
            this.decisiontreeService.getCompletedDecisionTree(this.EditID, this.selectedApplication!)
                .subscribe(
                    resp => {
                        debugger;
                        if (resp.body) {
                            this.questionSetControl.LoadQuestionSet(resp.body);
                            startQuestionSetReview(this.decisionTree?.decisionTreeName, this.questionSetControl.GetQuestionSet());
                        } else {
                            alert('No question set available for the selected application');
                        }
                    },
                    (e) => {
                        alert('An error occurred generating Test for Application');
                    }
                );
        }
        //startQuestionSetReview("DTNAME",
        //    new QuestionSet(resp.body)
        //    //this.questionSetControl.GetQuestionSet()
        //);
    }
    /*
    approveTree() {
        const parsedFromDate = this.decisionTree?.startDate!.toString();
        const parsedToDate = this.decisionTree?.endDate!.toString();
        this.decisiontreeService.approveDecisionTree(this.decisionTree?.decisionTreeID!, this.user.id!, parsedFromDate!, parsedToDate!).subscribe(
            (data) => {
                if ((data.body.message != "" && data.body.message != null) || data.body[0].result == -1) {
                    if (data.body != null) {
                        alert(data.body[0].message);
                    } else {
                        alert("Approval failed");
                    }
                } else {
                    console.log("SAVED");
                    //this.router.navigate(['decisiontree/edit'], { queryParams: { id: data.body.result }, replaceUrl: true });
                    //TODO refresh the page
                    this.refresh(this.decisionTree?.decisionTreeID!, false);
                }
            },  //changed
            (err) => {
                console.log(err)
            },
            () => console.log("Done")
        );
    }
    
     */
    save(isTest: boolean = false) {
        const start = new Date(this.editForm.value.start!);
        const parsedFromDate = start.toISOString();
        const end = new Date(this.editForm.value.end!);
        const parsedToDate = end.toISOString();
        if (!isTest) {
            this.decisiontreeService.saveDecisionTree(
                this.decisionTree?.decisionTreeID!, this.user.id!,
                this.editForm.value.comments!, parsedFromDate!, parsedToDate!, this.editForm.value.questions!).subscribe(
                    (data) => {
                        if (data.body.message != "" && data.body.message != null) {
                            alert("Save Failed");
                        } else {
                            console.log("SAVED", data.body.result);
                            this.router.navigate(['decisiontree/edit'], { queryParams: { id: data.body.result }, replaceUrl: true });
                            //TODO refresh the page
                            this.refresh(data.body.result, isTest);
                        }
                    },  //changed
                    (err) => {
                        console.log(err)
                    },
                    () => console.log("Done")
                );
        } else {
            this.refresh(this.decisionTree?.decisionTreeID!, isTest);
        }

    }

    refresh(id: number, isTest: boolean = false) {
        console.log("refresh");
        this.EditID = id;
        this.editForm.controls.questions = this.fb.array([]);
        this.getDecisionTree();
        if (isTest) {
            this.openActualinteractiveQuestionAnswerModal();
        }
    }

    applyFilter(event: MatCheckboxChange | Event) {
        const filterValue = this.searchInput.toLowerCase();
        const pastEffective = this.effectiveDate.get('past')?.value;
        const currentEffective = this.effectiveDate.get('current')?.value;
        const futureEffective = this.effectiveDate.get('future')?.value;

        this.dataSourceHT.filterPredicate = (data: HierarchyTies, filter: string): boolean => {
            const isPast = this.dateService.isPastEffective(data);
            const isCurrent = this.dateService.isCurrentlyEffective(data);
            const isFuture = this.dateService.isFutureEffective(data);

            // Filter by search input
            const includesSearch = filter === '' || (data.decisionTreeName?.toLowerCase().includes(filter) ?? false);

            // Filter by effective date checkboxes
            const meetsEffectiveDateCriteria =
                ((pastEffective && isPast) ||
                    (currentEffective && isCurrent) ||
                    (futureEffective && isFuture)) ?? false;

            return includesSearch && meetsEffectiveDateCriteria;
        };

        this.dataSourceHT.filter = filterValue.trim();

        // Manually trigger the filter update
        this.dataSourceHT._filterData = (data: HierarchyTies[]) => {
            this.dataSourceHT.filteredData = data.filter(obj => this.dataSourceHT.filterPredicate(obj, this.dataSourceHT.filter));
            return this.dataSourceHT.filteredData;
        };
        this.dataSourceHT._updateChangeSubscription();

        if (this.dataSourceHT.paginator) {
            this.dataSourceHT.paginator.firstPage();
        }
    }

    onEffectiveDateCheckboxChange(event: MatCheckboxChange) {
        // Trigger filterPredicate when checkbox state changes
        this.applyFilter(event);
    }

    //onDateSelection(date: NgbDate) {
    //    if (!this.fromDate && !this.toDate) {
    //        this.fromDate = date;
    //        this.editForm.value.fromDate = this.fromDate;
    //        this.editForm.controls['fromDate'].setErrors(null);
    //        this.dateToString(this.fromDate);
    //    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
    //        this.toDate = date;
    //        this.editForm.value.toDate = this.toDate;
    //        this.editForm.controls['toDate'].valid;
    //        this.editForm.controls['toDate'].setErrors(null);
    //    } else {
    //        this.toDate = null;
    //        this.editForm.value.toDate = null;
    //        this.fromDate = date;
    //        this.editForm.value.fromDate = this.fromDate;
    //        this.editForm.controls['fromDate'].setErrors(null);
    //        this.dateToString(this.fromDate);
    //    }
    //}
    //
    //isHovered(date: NgbDate) {
    //    return (
    //        this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    //    );
    //}
    //
    //isInside(date: NgbDate) {
    //    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    //}
    //
    //isRange(date: NgbDate) {
    //    return (
    //        date.equals(this.fromDate) ||
    //        (this.toDate && date.equals(this.toDate)) ||
    //        this.isInside(date) ||
    //        this.isHovered(date)
    //    );
    //}
    //
    //validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    //    const parsed = this.formatter.parse(input);
    //    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    //}

    //private dateToString(date: NgbDate) {
    //    return date.day + '/' + date.month + '/' + date.year;
    //}
    //private dateToYYYMMDD(date: NgbDate) {
    //    if (date.month < 10) {
    //        return date.year + '-0' + date.month + '-' + date.day;
    //    } else {
    //        return date.year + '-' + date.month + '-' + date.day;
    //    }
    //}


    openEdit() {
        this.router.navigate(['decisiontree/editquestions'], { queryParams: { dt: this.EditID } });
    }
}
