<div *ngIf="getDecisionLabel() == ''">
    <button mat-stroked-button class="fake-select"
            style="height: 50px;"
            [class.i-am-error-border]="isError"
            [matMenuTriggerFor]="decisionMenu">
        Select Decision <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <!--<div class="decision-icon-addon">
        <mat-icon>arrow_drop_down</mat-icon>
    </div>-->
</div>
<div *ngIf="getDecisionLabel() == 'Approve'" class="full-width">
    <button mat-stroked-button class="full-width" style="background-color: #fff; height: 50px; line-height: 12px;"
            [matMenuTriggerFor]="decisionMenu"
            [class.display-only]="actionContainer == null">
        <mat-icon style="color:green">check_circle</mat-icon>
        <span class="decision-label">Approved</span>
    </button>
</div>
<div *ngIf="getDecisionLabel() == 'Deny'">
    <button mat-stroked-button class="full-width" style="background-color: #fff; height: 50px;"
            [matMenuTriggerFor]="decisionMenu"
            [class.display-only]="actionContainer == null">
        <mat-icon style="color:#d93f15">block</mat-icon>
        <span class="decision-label">Denied</span>
    </button>
</div>
<div *ngIf="getDecisionLabel() == 'RFI'">
    <button mat-stroked-button class="full-width" style="background-color: #fff; height: 50px;"
            [matMenuTriggerFor]="decisionMenu"
            [class.display-only]="actionContainer == null">
        <mat-icon style="color:#800088">info</mat-icon>
        <span class="decision-label">Missing Information</span>
    </button>
</div>
<div *ngIf="getDecisionLabel() == '2Rph'">
    <button mat-stroked-button class="full-width" style="background-color: #fff; height: 50px;"
            [matMenuTriggerFor]="decisionMenu"
            [class.display-only]="actionContainer == null">
        <mat-icon style="color:#800088">manage_search</mat-icon>
        <span class="decision-label">Needs Pharmacist Review</span>
    </button>
</div>
<div *ngIf="getDecisionLabel() == 'Partial Approval'">
    <button mat-stroked-button class="full-width" style="background-color: #fff; height: 50px;"
            [matMenuTriggerFor]="decisionMenu"
            [class.display-only]="actionContainer == null">
        <mat-icon style="color: #d93f15">task_alt</mat-icon>
        <span class="decision-label">Partial Approval</span>
    </button>
</div>

<mat-menu #decisionMenu="matMenu">

    <button mat-button (click)="setDecision('')" class="full-width" style="justify-content:unset">
        Remove Decision
    </button><br />
    <button mat-button (click)="setDecision('Deny')" class="full-width" style="justify-content:unset">
        <mat-icon style="color:#d93f15">block</mat-icon>
        Denied
    </button><br />
    <button mat-button (click)="setDecision('Approve')" class="full-width" style="justify-content:unset">
        <mat-icon style="color:green">check_circle</mat-icon>
        Approved
    </button><br />
    <button mat-button (click)="setDecision('RFI')" class="full-width" style="justify-content:unset">
        <mat-icon style="color:#800088">info</mat-icon>
        Missing Information
    </button><br />
    <button mat-button (click)="setDecision('2Rph')" class="full-width" style="justify-content:unset">
        <mat-icon style="color:#800088">manage_search</mat-icon>
        Needs Pharmacist Review
    </button><br />
    <button mat-button (click)="setDecision('Partial Approval')" class="full-width" style="justify-content:unset">
        <mat-icon style="color: #d93f15">task_alt</mat-icon>
        Partial Approval
    </button>

</mat-menu>
