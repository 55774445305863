import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../../classes/client';
import { QuestionSetDashboardService } from '../question-set-dashboard.service';
import { ClientService } from '../../../shared/services/client.service';
import { QuestionSet } from '../../../classes/questionset';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../shared/auth/auth.service';
import { User } from '../../../classes/user';

@Component({
  selector: 'app-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportQSModalComponent {
    @Output() event: EventEmitter<any> = new EventEmitter();
    p: any;
    clients: Client[] = [];
    filename: string | undefined;
    questionSetData: QuestionSet | undefined;
    importQSForm = this.fb.group({
        newName: ['', Validators.required],
        clientSelect: ['', Validators.required],
        typeSelect: ['', Validators.required],
        //fu: ['', Validators.required]
    });

    constructor(
        private authService: AuthService,
        private clientService: ClientService,
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ImportQSModalComponent>,
        public questionSetDashboardService: QuestionSetDashboardService,
        private fb: FormBuilder
    ) {
        if (data !== undefined) {
            this.p = data.p;
        //    this. = data.;
        }
    }
    ngOnInit() {
        this.populateClients();
        this.importQSForm.controls['typeSelect'].setValue("1", { onlySelf: true });
        //this.importQSForm.controls['clientSelect'].setValue("ASES", { onlySelf: true });
        
        this.importQSForm.controls['clientSelect'].setValue(
            this.authService.user.lastSelectedClientID ?? ""
            , { onlySelf: true });
    }
    close() {
        this.dialogRef.close("0");
    }

    populateClients() {
        this.clients = this.clientService.getClients();
        //this.clientService.getClients().subscribe(resp => {
        //    if (resp.body) {
        //        this.clients = this.clientService.mapClientResponse(resp);
        //        //this.onSelectClient();
        //    }
        //});
    }
    onSelectClient() {
        var clientId = this.importQSForm.value.clientSelect ?? "";
        this.p.selectedClient = this.clients.find(x => x.clientID?.toLowerCase() === clientId.toLowerCase())!;
        this.authService.setClient(this.p.selectedClient.clientID!);
        this.p.getQuestionSetTable();
    }
    onFileSelected(event:any) {
        const file: File = event.target.files[0];
        var self = this;
        if (file) {
            this.filename = file.name;
            var fileReader = new FileReader();
            fileReader.onload = function () {
                var data = fileReader.result as string;
                var json = JSON.parse(data);
                self.questionSetData = new QuestionSet(
                    json
                );
            }
            fileReader.readAsText(event.target.files[0]);
            //quick load
            var iety = <HTMLInputElement>document.getElementById("newName");
            this.importQSForm.value.newName = iety.value = this.filename;
             
            console.log("-----------------------------")
            console.log(this.importQSForm.value);
            console.log("-----------------------------")

            //console.log(this.filename);
            //console.log(this.questionSetData?.questions?.length);
        }
    }
    onSubmit(): void {
        console.log("-----------------------------")
        console.log(this.importQSForm);
        console.log("-----------------------------")
        this.questionSetDashboardService.importQuestionSet(
            this.importQSForm.value.newName!, this.importQSForm.value.clientSelect!,
            <QuestionSet>this.questionSetData,
            parseInt(this.importQSForm.value.typeSelect!),
            this.filename ?? ""
        )
            .subscribe(
                (data) => {
                    if (data.status == 200) {
                        if (data.body.result != -1) {
                            //successfully saved
                            this.dialogRef.close("1");
                        } else {
                            alert(data.body.message);
                        }
                    }
                },  //changed
                (err) => {
                    console.log(err)
                },
                () => console.log("Done")
            );
    }
}
