<!--<app-header></app-header>-->
<div  *ngIf="shouldDisplayLayout()">
    <div class="column-2">
        <h1>{{title}}</h1>
    </div>
    <div class="column-6">
        <nav mat-tab-nav-bar color="accent" [tabPanel]="tabPanel"
             *ngIf="navLinks.length > 0">
            <a mat-tab-link color="accent"
               *ngFor="let link of navLinks"
               (click)="activeLink = link.link"
               [active]="activeLink == link.link"
               [routerLink]="[link.link]">
                {{ link.label }}
            </a>
        </nav>
    </div>
    
    <br />
    <mat-tab-nav-panel #tabPanel>
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>
</div>
<div *ngIf="!shouldDisplayLayout()">
    <div style="position:absolute; margin-top:-25px;">
        <button mat-button (click)="goBack()">
            <mat-icon style="margin-top:-4px">
                home
            </mat-icon>
            Home
        </button>
        <ng-container *ngIf="currentRoute">
            <mat-icon style="padding-top:6px;">chevron_right</mat-icon>
            {{ getCurrentRouteTitle() }}
        </ng-container>
    </div>

    <router-outlet></router-outlet>
</div>
<!--<app-footer></app-footer>-->
