import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Client } from '../../classes/client';
import { ClientService } from '../../shared/services/client.service';
import { DT, QuestionSet, TreeOnly } from '../../classes/questionset';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../shared/auth/auth.service';
import { User } from '../../classes/user';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { DecisionTreeService } from '../decision-tree.service';

@Component({
    selector: 'app-import-dt-modal',
    templateUrl: './import-dt-modal.component.html',
    styleUrls: ['./import-dt-modal.component.scss']
})

export class ImportDTModalComponent {
    user!: User;

    @Output() event: EventEmitter<any> = new EventEmitter();
    clients: Client[] = [];
    selectedClient!: Client;
    filename: string | undefined;
    jsonData: string | undefined;
    decisionTreeData: DT | undefined;
    treeOnly: TreeOnly[] = [];
    //questionSetData: QuestionSet | undefined;
    questionSetNames: string[] = [];
    selectedFile: any = null;
    isQuestionSetNamesPopulated: boolean = false;
    importDTForm = this.fb.group({
        newName: ['', Validators.required],
        clientSelect: ['', Validators.required],
        file: ['', Validators.required],
        questions: this.fb.array([])
    });

    constructor(
        private clientService: ClientService,
        private authService: AuthService,
        private decisionTreeService: DecisionTreeService,
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ImportDTModalComponent>,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserLoginInfo();
    }

    ngOnInit() {
        this.populateClients();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.importDTForm.controls['clientSelect'].setValue(this.selectedClient.clientID!, { onlySelf: true });
        console.log("ing")
    }

    close() {
        this.dialogRef.close("0");
    }

    populateClients() {
        this.clients = this.clientService.getClients()
        this.onSelectClient();
        //    .subscribe(resp => {
        //    if (resp.body) {
        //        this.clients = this.clientService.mapClientResponse(resp);
        //        this.onSelectClient();
        //    }
        //});
    }

    onSelectClient() {
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.importDTForm.value.clientSelect!.toLowerCase())!;
        this.authService.setClient(this.selectedClient.clientID!);
    }

    onFileSelected(event: any) {
        console.log("++++++++++++++ File Selected")
        this.isQuestionSetNamesPopulated = false;
        this.selectedFile = event.target.files[0] ?? null;
        var self = this;
        if (this.selectedFile) {
            this.filename = this.selectedFile.name;
            var fileReader = new FileReader();
            fileReader.onload = function () {
                var data = fileReader.result as string;
                self.jsonData = data;

                var jsonDataObject = JSON.parse(data);

                if (jsonDataObject.dts) {
                    // Use the data under the 'dts' node if it exists
                    self.decisionTreeData = new DT(JSON.parse(jsonDataObject.dts));
                } else {
                    // Use the top-level data if 'dts' node is not present
                    self.decisionTreeData = new DT(jsonDataObject);
                }
                self.questionSetNames = [];
                self.decisionTreeData.decisionTreeToQuestionSet.forEach((q: any) => {
                    self.questionSetNames.push(q.questionSet.questionSetHeader.title);
                });
                console.log(self.questionSetNames);
                console.log("DecisionTreeData", self.decisionTreeData)
                self.createCheckboxInputs();
            }
            fileReader.readAsText(event.target.files[0]);
            //quick load
            var iety = <HTMLInputElement>document.getElementById("newName");
            const name = iety.value = this.filename!;
            this.importDTForm.get('newName')?.patchValue(name);
            this.importDTForm.get('newName')?.updateValueAndValidity();
            console.log("-----------------------------")
            console.log(this.importDTForm.value);
            console.log("-----------------------------")
        }
    }
    onSubmit(): void {
        console.log("--------------------------")
        console.log(this.importDTForm);
        console.log("--------------------------")

        //Collect the tree only values;
        this.treeOnly = this.createTreeOnlyArray();

        this.decisionTreeService.importDecisionTree(
            this.importDTForm.value.newName!, this.importDTForm.value.clientSelect!,
            this.decisionTreeData!, this.treeOnly!,
            this.filename ?? ""
        )
            .subscribe(
                (data) => {
                    if (data.status == 200) {
                        if (data.body.result != -1) {
                            //successfully saved
                            this.dialogRef.close("1");
                        } else {
                            alert(data.body.message);
                        }
                    }
                },  //changed
                (err) => {
                    console.log(err)
                },
                () => console.log("Done")
            );
    }

    get questionFormArray(): FormArray {
        return this.importDTForm.get('questions') as FormArray;
    }

    createCheckboxInputs() {
        this.questionFormArray.clear();
        var i = 0;
        this.questionSetNames.forEach(() => {
            var e = false;
            if (!this.questionSetNames[i].toLowerCase().includes('exception')) {
                e = true;
            }
            this.questionFormArray.push(this.fb.group({
                question: e
            }));
            i++;
        });
        this.isQuestionSetNamesPopulated = true;
    }

    createTreeOnlyArray(): TreeOnly[] {
        const formArray = this.importDTForm.get('questions') as FormArray;
        const treeOnlyArray: TreeOnly[] = [];
        var i = 0;
        formArray.controls.forEach((control) => {
            const treeOnly = new TreeOnly({
                questionName: this.questionSetNames[i],
                isTreeOnly: control.value.question,
            });
            treeOnlyArray.push(treeOnly);
            i++;
        });

        return treeOnlyArray;
    }

}
