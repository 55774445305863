import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ActionResult, AnswerChoiceCommonType, NextQuestionCondition, Question, QuestionTypeDate, QuestionTypeFreeText, QuestionTypeNumeric, QuestionTypeSelect } from '../../classes/questionset';
//import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormsModule } from '@angular/forms';
import { QuestionSetControl, QuestionSetRouting } from '../../shared/question-set.control';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RoutingModalComponent } from '../../question/question-edit/routing-modal/routing-modal.component';
import { CodedreferenceModalComponent } from '../../question/codedreference-modal/codedreference-modal.component';
import { ActionresultModalComponent } from '../../question/question-edit/actionresult-modal/actionresult-modal.component';
import { EditQuestionDecisionJustificationModalComponent } from '../edit-question-decision-justification-modal/edit-question-decision-justification-modal.component';
import { TooltipPosition } from '@angular/material/tooltip';
import { SortAnswerBySequencePipe } from '../../shared/pipes/sort-answer-by-sequence.pipe';
import { DeleteConfirmDialogComponent } from '../../shared/delete-confirm-dialog/delete-confirm-dialog.component';

@Component({
  selector: 'app-edit-question-list-item',
  templateUrl: './edit-question-list-item.component.html',
  styleUrls: ['./edit-question-list-item.component.scss']
})
export class EditQuestionListItemComponent {
    @Input() question!: Question;
    @Input() questionSetControl!: QuestionSetControl;
    @Input() p!: any;
    @Input() questionSetRoutingList?: QuestionSetRouting[];
    showComment: boolean = false;
    closeResult = '';
    positionOptions: TooltipPosition[] = ['above'];
    position = new FormControl(this.positionOptions[0]);
    public showQuestionDetail = true;

    constructor(
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
    }
    ngOnInit(): void {
        //console.log(this.questionSetControl);
        //console.log(this.question);
        this.showComment = (this.question.questionComment?? "" ).length > 0;
    }
    selectQuestion() {
        document.querySelector(".tree-item-focused")?.classList.remove('tree-item-focused')
        var io = this.questionSetControl.initialOrder;
        var id = this.question.id;
        var target = "dc" + io + "-" + id;
        var treeElement = document.getElementById(target);
        treeElement?.focus();
        if (treeElement !== undefined && treeElement !== null) {
            treeElement.classList.add('tree-item-focused')
            treeElement.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
        }
        this.questionSetControl.selectQuestionForFocus(io!, id);
    }

    addNewQuestionSet() {
        this.p.addNewQuestionSet()
    }

    move(direction: string) {
        let order = this.question.internalOrder;
        if (direction == "up") {
            if (order == 1) return;
            let a = this.question;
            let b = this.questionSetControl.GetQuestions().filter((q: Question) => { return q.internalOrder == (order - 1); });
            if (b.length == 1) {
                a.internalOrder! -= 1;
                b[0].internalOrder! += 1;
            }
        }
        if (direction == "down") {
            if (order == (this.questionSetControl.GetQuestions().length + 1)) return;
            let a = this.question;
            let b = this.questionSetControl.GetQuestions().filter((q: Question) => { return q.internalOrder == (order + 1); });
            if (b.length == 1) {
                a.internalOrder! += 1;
                b[0].internalOrder! -= 1;
            }
        }
        this.questionSetControl.jumpToQuestion(this.question.id);
        //var selector = 'question-anchor-' + this.questionSetControl.initialOrder + '-'+ this.question.id;
        //var e = document.getElementById(selector);
        //setTimeout(() => {
        //    if (e != null) {
        //        e.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        //    }
        //}, 50);
    }
    GetNextQuestionLabel(): string {
        let label = "";
        let nextId = this.question.defaultNextQuestionID;
        if (nextId == "" || nextId == null) {
            label = "Select Path";
        }
        else if (nextId.startsWith("next-")) {

            var qsGuid = nextId.substr(5);
            var qsr = this.questionSetRoutingList?.filter(f => f.qsGuid == qsGuid);
            if (qsr === undefined || qsr == null || qsr!.length > 1 || qsr!.length == 0) {
                return "SET ERROR " + qsGuid;
            }

            //let v = "+" + qsr![0].title;
            //if (v.length > 150) {
            //    v = v.substring(0, 147) + "...";
            //}
            //return (qsr![0].internalOrderOffset + 1) + ": " + v;

            label = "Question " + (qsr![0].internalOrderOffset + 1);
        }
        else if (nextId == "End") {
            label = "Next Question Set";
        }
        if (nextId == "Final") {
            label = "End Decision Tree";

        } else {
            var q = this.questionSetControl.GetQuestionByID(nextId);
            if (q != null) {
                //var v: string;
                //v = q.text;
                //if (v.length > 25) {
                //    v = v.substring(0, 22) + "...";
                //}
                //label = (q.internalOrder + this.questionSetControl.internalOrderOffset) + ": " + v;
                label = "Question " + (q.internalOrder + this.questionSetControl.internalOrderOffset);
            }
        }
        return label;
    }
    jumpToQuestionDecision() {
        //TODO:
        alert("jumpToQuestionDecision() pending");
    }
    showPathwayAlert(): boolean {
        var isRouted = true;
        
        if (this.question.questionType instanceof QuestionTypeSelect) {
            var qts = (<QuestionTypeSelect>this.question.questionType);
            qts.choice.forEach(
                (ac: AnswerChoiceCommonType) => {
                    if (ac.nextQuestionID == null || ac.nextQuestionID == '' || ac.nextQuestionID == 'Default') {
                    isRouted = false;
                }
            });
        } else if (this.question.questionType instanceof QuestionTypeFreeText) {
            isRouted = false;
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            isRouted = false;
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            isRouted = false;
        }
        return !isRouted;
    }
    GetQuestionType() {
        var type = "";
        if (this.question.questionType instanceof QuestionTypeSelect) {
            type = "Select";
        } else if (this.question.questionType instanceof QuestionTypeFreeText) {
            type = "FreeText";
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            type = "Date";
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            type = "Numeric";
        }
        return type;
    }
    GetQuestionTypeFull() {
        var type = "";
        if (this.question.questionType instanceof QuestionTypeSelect) {
            var qts = (<QuestionTypeSelect>this.question.questionType);
            if (qts.isMultiple) {
                type = "Multi-Select";
            } else {
                if (qts.isYesNoInternalOnly) {
                    type = "yesNo";
                } else {
                    type = "Select";
                }
            }
        } else if (this.question.questionType instanceof QuestionTypeFreeText) {
            type = "FreeText";
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            if ((<QuestionTypeDate>this.question.questionType).isDateTimeRequired) {
                type = "Datetime";
            } else {
                type = "Date";
            }
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            type = "Numeric";
        }
        return type;
    }
    GetQuestionTypeFullLabel() {
        var type = "";
        if (this.question.questionType instanceof QuestionTypeSelect) {
            var qts = (<QuestionTypeSelect>this.question.questionType);
            if (qts.isMultiple) {
                type = "Multiple Selection";
            } else {
                if (qts.isYesNoInternalOnly) {
                    type = "Yes/No";
                } else {
                    type = "Single Select";
                }
            }
        } else if (this.question.questionType instanceof QuestionTypeFreeText) {
            type = "Text";
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            if ((<QuestionTypeDate>this.question.questionType).isDateTimeRequired) {
                type = "Date & Time";
            } else {
                type = "Date";
            }
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            type = "Number";
        }
        return type;
    }
    openCommentText() {
        if (this.question.questionComment == "" || this.question.questionComment == undefined) {
            this.question.questionComment = "  ";
        }
    }
    RemoveQuestion() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "delete-confirm-dialog";
        dialogConfig.data = {
            showDelete: true,
            submitButtonText: "Yes, Delete",
            title: "Are you sure you want to delete this question?",
            dialogText: "This action cannot be undone, and the question will be permantently deleted from the Decision Tree."
        };
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != 0) {
                    this.questionSetControl.RemoveQuestionByID(this.question.id);
                    this.p.p.setInternalOrderOffsets();
                    this.p.p.setQuestionSetRouting();
                }
            }
        );
    }
    RemoveActionResult(ar: ActionResult) {
        this.question.action = this.question.action.filter((f:any) => { return ar != f });
    }
    openRoutingModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = "routing-dialog";
        dialogConfig.data = {
            nextId: this.question.defaultNextQuestionID,
            currentQuestionID: this.question.id,
            p: this,
            questionSetRoutingList: this.questionSetRoutingList
        }
        const dialogRef = this.dialog.open(RoutingModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                //console.log(data)
            }
        );
    }
    openAddDecisionJustificationModal(a: ActionResult) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            questionSetControl: this.questionSetControl,
            question: this.question,
            action: a,
            answerChoice: undefined,
            comparison: undefined,
        }
        const dialogRef = this.dialog.open(EditQuestionDecisionJustificationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                /*console.log(data)*/
                this.cdr.detectChanges();
            }
        );
    }
    openCodedReferenceModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            p: this,
            currentQuestionID: this.question.id
        }
        const dialogRef = this.dialog.open(CodedreferenceModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    openActionResultModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            p: this
        }
        const dialogRef = this.dialog.open(ActionresultModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    AddActionResult(ar: ActionResult) {
        this.question.action.push(ar);
    }
    SetNextId(id: string) {
        this.question.defaultNextQuestionID = id;
        this.questionSetControl.CalculateQuestionDetail();
        this.cdr.detectChanges();
    }
   

    /* **************************************************************** */
    GetSelectType() {
        return <QuestionTypeSelect>this.question.questionType;
    }
    removeAnswerChoiceByID(cid: string) {
        (<QuestionTypeSelect>this.question.questionType).choice =
            (<QuestionTypeSelect>this.question.questionType).choice.filter((e: any) => { return e.id != cid });
        var seq = 1;
        (<QuestionTypeSelect>this.question.questionType).choice.forEach((e:any) => { e.sequence = seq++; });
    }
    AddAnswer() {
        let qts = (<QuestionTypeSelect>this.question.questionType);
        let max = 1;
        qts.choice.forEach(e => {
            let n = parseInt(e.id.replace(/[^0-9]/g, ''));
            if (n > max) { max = n; }
        });
        qts.choice.push(
            new AnswerChoiceCommonType({
                id: "c" + (max + 1),
                choiceText: "",
                nextQuestionID: "Default",
                sequence: qts.choice.length + 1,
                codedReference: [],
                action: [],
                additionalFreeTextIndicator: "NA"
            })
        );
    }
    AddAnswerForOther() {
        let qts = (<QuestionTypeSelect>this.question.questionType);
        let max = 1;
        qts.choice.forEach(e => {
            let n = parseInt(e.id.replace(/[^0-9]/g, ''));
            if (n > max) { max = n; }
        });
        qts.choice.push(
            new AnswerChoiceCommonType({
                id: "c" + (max + 1),
                choiceText: "Other",
                nextQuestionID: "Default",
                sequence: qts.choice.length + 1,
                codedReference: [],
                action: [],
                additionalFreeTextIndicator: "O"
            })
        );
    }
    AddNextQuestionCondition(type: string) {
        let cmp: any = { nextQuestionID: 'End' };
        if (type == 'Single') {
            cmp.comparisonValue = "";
            cmp.comparisonOperator = "GT";
        } else {
            cmp.lowerBoundComparisonValue = "";
            cmp.lowerBoundComparisonOperator = "GT";
            cmp.upperBoundComparisonValue = "";
            cmp.upperBoundComparisonOperator = "LT";
        }
        let data: any = { type: type, comparison: cmp };
        let nqc = new NextQuestionCondition(data);
        if (this.question.questionType instanceof QuestionTypeSelect) {
            (<QuestionTypeSelect>this.question.questionType).nextQuestionCondition.push(nqc);
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            (<QuestionTypeDate>this.question.questionType).nextQuestionCondition.push(nqc);
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            (<QuestionTypeNumeric>this.question.questionType).nextQuestionCondition.push(nqc);
        }
    }
    moveAnswerDown(sequence: number) {
        let choices = (<QuestionTypeSelect>this.question.questionType).choice;
        var a = choices.filter(f => f.sequence == sequence);
        var b = choices.filter(f => f.sequence == (sequence + 1));
        console.log("Choice move ", a, b);
        if (a.length > 0 && b.length > 0) {
            a[0].sequence = sequence + 1;
            b[0].sequence = sequence;
        }
    }
    /* **************************************************************** */
    GetDateType() {
        return <QuestionTypeDate>this.question.questionType;
    }
    GetNumericType() {
        return <QuestionTypeNumeric>this.question.questionType;
    }
    GetFreeTextType() {
        return <QuestionTypeFreeText>this.question.questionType;
    }
    ChangeQuestionType(e: any) {
        console.log(e);
        switch (e.value) {
            case "Multi-Select":
                if (this.GetQuestionType() == "Select" && !this.GetSelectType().isYesNoInternalOnly) {
                    var t = this.question.questionType;
                    var type = (<QuestionTypeSelect>t);
                    type.isMultiple = true;
                    type.isYesNoInternalOnly = false;
                } else {
                  this.question.questionType = new QuestionTypeSelect({ isMultiple: true, isYesNoInternalOnly: false });
                }
                break;
            case "Select":
                if (this.GetQuestionType() == "Select" && !this.GetSelectType().isYesNoInternalOnly) {
                    var t = this.question.questionType;
                    var type = (<QuestionTypeSelect>t);
                    type.isMultiple = false;
                    type.isYesNoInternalOnly = false;
                } else {
                  this.question.questionType = new QuestionTypeSelect({ isMultiple: false, isYesNoInternalOnly: false });
                }
                break;
            case "yesNo":
                this.question.questionType = new QuestionTypeSelect({
                    isYesNoInternalOnly: true,
                    isMultiple: false, "choice": [
                        { "id": "c1", "choiceText": "Yes", "nextQuestionID": "Default", "sequenceNumber": "1", "additionalFreeTextIndicator": "NA" },
                        { "id": "c2", "choiceText": "No", "nextQuestionID": "Default", "sequenceNumber": "2", "additionalFreeTextIndicator": "NA" }
                    ] });
                break;
            case "FreeText":
                this.question.questionType = new QuestionTypeFreeText({});
                break;
            case "Datetime":
                this.question.questionType = new QuestionTypeDate({ isDateTimeRequired: true });
                break;
            case "Date":
                this.question.questionType = new QuestionTypeDate({ isDateTimeRequired: false });
                break;
            case "Numeric":
                this.question.questionType = new QuestionTypeNumeric({});
                break;
            default:
                console.error("Missing change target for select");
                break;
        }
        console.log(this.question.questionType);
    }
    CopyQuestion() {
        //Cheating
        var jss = JSON.stringify(this.question);
        let qn = new Question(JSON.parse(jss));
        qn.sequenceNumber = 99999;
        qn.internalOrder = this.question.internalOrder + 0.5;
        qn.id = this.questionSetControl.GetNextQuestionID().toString();
        this.questionSetControl.GetQuestions().push(qn);
        this.questionSetControl.recalculateInternalOrder();
        this.questionSetControl.CalculateQuestionDetail();
        this.p.p.setInternalOrderOffsets();
        this.questionSetControl.jumpToQuestion(qn.id);
    }
    updateShowComment() {
        if (!this.showComment) {
            this.question.questionComment = "";
        }
    }

    //ngOnChanges(changes: SimpleChanges) {
    //    console.log(changes);
    //}
}
