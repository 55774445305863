import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Case, CaseSubType } from '../../classes/case';
import { ApiPaths } from '../environments/routes';
import { environment } from '../environments/environments';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CaseService {

    constructor(private http: HttpClient, private authService: AuthService) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getCases(): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetPCNCaseType, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapCases(response: HttpResponse<any>) {
        const cases: Case[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const c = new Case();
            if (v instanceof Object) {
                c.pcnCaseTypeID = parseInt(v['pcnCaseTypeID' as keyof typeof v].toString());
                c.caseType = v['caseType' as keyof typeof v].toString();

                cases.push(c);
            }
        }
        return cases;
    }

    public getCaseSubTypes(): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetPCNCaseSubType, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public observeSubTypes(response: HttpResponse<any>): Observable<any> {
        const cases = this.mapCaseSubTypes(response);
        return of(cases);
    }

    public mapCaseSubTypes(response: HttpResponse<any>) {
        const cases: CaseSubType[] = [];
        var caseSubTypeAllID = -1;
        for (var [k, v] of Object.entries(response.body)) {
            const c = new CaseSubType();
            if (v instanceof Object) {
                const pcnCaseTypeID = parseInt(v['pcnCaseTypeID' as keyof typeof v].toString());
                if (!cases.find(x => x.pcnCaseTypeID == pcnCaseTypeID && x.caseSubType == 'All')) {
                    const all = new CaseSubType();
                    all.pcnCaseTypeID = pcnCaseTypeID;
                    all.pcnCaseSubTypeID = caseSubTypeAllID;
                    all.caseSubTypeValue = "";
                    all.caseSubType = 'All';
                    caseSubTypeAllID--;
                    cases.push(all);
                }
                
                c.pcnCaseTypeID = pcnCaseTypeID;
                c.pcnCaseSubTypeID = parseInt(v['pcnCaseSubTypeID' as keyof typeof v].toString());
                c.caseSubType = v['caseSubType' as keyof typeof v].toString();
                c.caseSubTypeValue = c.caseSubType;

                cases.push(c);
            }
        }
        return cases;
    }
}
