import { Pipe, PipeTransform } from '@angular/core';
import { Question } from '../../classes/questionset';

@Pipe({
    name: 'sortByInternalOrder',
    pure:false
})
export class SortByInternalOrderPipe implements PipeTransform {

    transform(value: Question[]): Question[] {
        return value.sort((n1, n2) => {
            return n1.internalOrder - n2.internalOrder;
        });
  }

}
