import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Case, CaseSubType } from '../../classes/case';
import { CaseSettings, DecisionTreeSettings, DrugSettings, HierarchySettings } from '../../classes/decisiontreesettings';
import { CaseService } from '../services/case.service';

@Component({
  selector: 'case-selection',
  templateUrl: './case-selection.component.html',
  styleUrls: ['./case-selection.component.scss']
})
export class CaseSelectionComponent {
    @Input() tree!: DecisionTreeSettings;

    @Input() p!: any;
    @Input() hierarchyId?: number = undefined;
    @Input() applyToAll: boolean = true;
    cases: Case[] = [];
    caseSubtypes: CaseSubType[] = [];
    caseSettings: CaseSettings[] = [];
    @Input() hierarchyToApply?: HierarchySettings;
    selectedValues: string[][] = [];
    notAdmin: boolean = false;

    constructor(
        private caseService: CaseService,
    ) {
        //console.log('case constructor');
        this.fetchCases();
    }

    ngOnInit(): void {
        //if you have a specific hierarchy, apply the already selected determination types
    }

    fetchCases() {
        this.caseService.getCases().subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.cases = this.caseService.mapCases(resp);
                this.fetchCaseSubTypes();
            }
        });
    }

    fetchCaseSubTypes() {
        this.caseService.getCaseSubTypes().subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.caseSubtypes = this.caseService.mapCaseSubTypes(resp);
                //if you have a specific hierarchy, apply the already selected case types
                if (this.hierarchyToApply) {
                    var h = this.findHierarchyToWorkWith();
                    this.caseSettings = h.cases;
                    this.populateSelectedValues();
                }
            }
        });
    }

    getCaseSubTypes(caseTypeId: number): CaseSubType[] {
        return this.caseSubtypes.filter(subType => subType.pcnCaseTypeID === caseTypeId);
    }

    onSelectChange(): void {
        this.caseSettings = [];

        for (let i = 0; i < this.cases.length; i++) {
            if (i < this.selectedValues.length) {
                for (let j = 0; j < this.selectedValues[i].length; j++) {
                    const selectedValue = this.selectedValues[i][j];
                    const caseSubType = this.caseSubtypes.find(subType => subType.caseSubTypeValue === selectedValue);
                    
                    if (caseSubType) {
                        const caseSetting = new CaseSettings();
                        caseSetting.caseType = this.cases[i].caseType;
                        caseSetting.caseSubType = caseSubType.caseSubType;
                        this.caseSettings.push(caseSetting);
                    }
                }
            }
        }

        this.updateHierarchy();
    }

    updateHierarchy() {
        if (this.applyToAll) {
            for (var h of this.tree.hierarchy) {
                h.cases = this.caseSettings;
            }
        } else {
            var h = this.findHierarchyToWorkWith();
            h.cases = this.caseSettings;
        }
    }

    setAdmin() {
        if (this.applyToAll) {
            this.notAdmin = !this.notAdmin;
            if (this.notAdmin) {
                this.tree.noCase = true;
                this.caseSettings = [];
                this.selectedValues = [];
                this.updateHierarchy();
            }
        } else {
            this.notAdmin = !this.notAdmin;
            if (this.notAdmin) {
                var h = this.findHierarchyToWorkWith();
                h.noCase = this.notAdmin;
            }
            this.caseSettings = [];
            this.selectedValues = [];
        }
        
    }

    populateSelectedValues() {
        this.selectedValues = [];
        
        if (this.caseSettings.length > 0) {
            for (let i = 0; i < this.cases.length; i++) {
                
                const selectedValuesForCase: string[] = [];
                const caseSettingsForCase = this.caseSettings.filter(setting => setting.caseType === this.cases[i].caseType);
                for (const setting of caseSettingsForCase) {
                    const caseSubType = this.caseSubtypes.find(subType => subType.caseSubTypeValue === setting.caseSubType);
                    if (caseSubType) {
                        selectedValuesForCase.push(caseSubType.caseSubTypeValue!);
                    }
                }
                this.selectedValues.push(selectedValuesForCase);
            }
        } else {
            this.notAdmin = true;
            var h = this.findHierarchyToWorkWith();
            h.noCase = true;
            this.caseSettings = [];
            this.selectedValues = [];
        }
    }

    findHierarchyToWorkWith(): HierarchySettings {
        var hierarchySettings = this.tree.hierarchy.find(hierarchy => {
            // Customize the conditions based on your matching criteria
            return (
                (hierarchy.hierarchyId === this.hierarchyToApply?.hierarchyId && this.hierarchyToApply?.hierarchyId != undefined) ||
                (hierarchy.pcnReferenceID === this.hierarchyToApply?.pcnReferenceID &&
                    hierarchy.planReferenceID === this.hierarchyToApply?.planReferenceID &&
                    hierarchy.groupReferenceID === this.hierarchyToApply?.groupReferenceID)
            );
        });
        return hierarchySettings!;
    }
}