
<div class="nextquestioncondition" style="display: flex; flex-direction:row; background-color: #eff1f1; margin-top: 3px; padding: 3px; max-height: 58px;">
    <div style="flex: 25px 0 0; display: flex; align-items: center; height: 50px;">
        {{orderIndex+1}} If
    </div>
    <div style="flex:45px 0 0;  align-items: center; height: 50px;">
        <!--<mat-form-field color="accent" style="width:58px; background-color:#fff;" appearance="outline">-->
        <select id="comparisonOperator" [(ngModel)]="operator"
                class="nqc-row-input" maxlength="1" (change)="changeOptionValue($event)">
            <option value="EQ">&nbsp;  =        &nbsp; (equals)</option>
            <option value="NE">&nbsp;  &ne;     &nbsp; (does not equal)</option>
            <option value="LT">&nbsp;  &lt;     &nbsp; (less then)</option>
            <option value="GT">&nbsp;  &gt;     &nbsp; (greater then)</option>
            <option value="LE">&nbsp;  &le;     &nbsp; (less then or equal to)</option>
            <option value="GE">&nbsp;  &ge;     &nbsp; (greater then or equal to)</option>
            <option value="B"> &nbsp; &epsilon; &nbsp; (is between)</option>
        </select>
        <!--</mat-form-field>-->
    </div>
    <!-------------------------------------------------------------------->
    <div style="flex: 100px 1 1;  align-items: center; height: 50px;" 
         *ngIf="GetComparisonType() == 'Single' && getComparisonInputType() != 'Date'&& getComparisonInputType() != 'Select'">
        <input type="{{getComparisonInputType()}}" id="comparisonValue"
               required class="nqc-row-input"
               [(ngModel)]="GetComparisonDetail().comparisonValue" name="comparisonValue">
    </div>
    <div style="flex: 100px 1 1; align-items: center; height: 50px; display: flex;" 
         *ngIf="GetComparisonType() == 'Single' && getComparisonInputType() == 'Date'">
        <input matInput [matDatepicker]="picker" [(ngModel)]="defaultStartDate"  name="comparisonValue"
               class="nqc-dp" (dateChange)="updateCompare($event)"/>
        <mat-datepicker-toggle matIconSuffix [for]="picker" class="nqc-dp-toggler"></mat-datepicker-toggle>
        <mat-datepicker #picker ></mat-datepicker>
    </div>
    <div style="flex: 100px 1 1; align-items: center; height: 50px; display: flex;" *ngIf="GetComparisonType() == 'Single' && getComparisonInputType() == 'Select'">
        <select id="comparisonValue"
               required class="nqc-row-input"
               [(ngModel)]="GetComparisonDetail().comparisonValue" name="comparisonValue">
            <option *ngFor="let sn of selectableNumbers" value="{{sn}}">{{sn}} selected</option>
        </select>
    </div>
    <!-------------------------------------------------------------------->
    <div *ngIf="GetComparisonType() == 'Range' && getComparisonInputType() == 'Date'" 
         style="flex: 150px 1 1; flex-direction: row; display: flex; align-items: center;">
        <mat-date-range-input [rangePicker]="rpicker" class="nqc-dp">
            <input matStartDate [(ngModel)]="defaultStartDate" name="lowerBoundComparisonValue" (dateChange)="updateLower($event)"/>
            <input matEndDate [(ngModel)]="defaultEndDate" name="upperBoundComparisonValue" (dateChange)="updateUpper($event)"/>
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="rpicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rpicker ></mat-date-range-picker>

    </div>
    <div *ngIf="GetComparisonType() == 'Range' && getComparisonInputType() == 'datetime-local'" style="flex: 150px 1 1; flex-direction: column; display: flex; align-items: center;">
        <input type="{{getComparisonInputType()}}" id="lowerBoundComparisonValue"
               required class="nqc-row-input" style="height:24px;display:block"
               [(ngModel)]="GetComparisonDetail().lowerBoundComparisonValue" name="lowerBoundComparisonValue">

        <input type="{{getComparisonInputType()}}" id="upperBoundComparisonValue"
               required class="nqc-row-input" style="height: 24px; display: block"
               [(ngModel)]="GetComparisonDetail().upperBoundComparisonValue" name="upperBoundComparisonValue">
    </div>
    <div *ngIf="GetComparisonType() == 'Range' && getComparisonInputType() == 'Select'" style="flex: 150px 1 1; flex-direction: row; display: flex; align-items: center;">
        <select id="lowerBoundComparisonValue"
                required class="nqc-row-input"
                [(ngModel)]="GetComparisonDetail().lowerBoundComparisonValue" name="lowerBoundComparisonValue">
            <option *ngFor="let sn of selectableNumbers" option="sn">{{sn}}</option>
        </select>
        and
        <select id="upperBoundComparisonValue"
                required class="nqc-row-input"
                [(ngModel)]="GetComparisonDetail().upperBoundComparisonValue" name="upperBoundComparisonValue">
            <option *ngFor="let sn of selectableNumbers" option="sn">{{sn}}</option>
        </select>
    </div>
    <div *ngIf="GetComparisonType() == 'Range' && getComparisonInputType() == 'Numeric'" style="flex: 150px 1 1; flex-direction: row; display: flex; align-items: center;">
        <div style="flex: 1 1 1; height: 50px; ">
            <!--<mat-form-field color="accent" class="full-width" appearance="outline">
                <mat-label for="lowerBoundComparisonValue">Lower Value</mat-label>-->
            <input type="{{getComparisonInputType()}}" id="lowerBoundComparisonValue"
                   required class="nqc-row-input"
                   [(ngModel)]="GetComparisonDetail().lowerBoundComparisonValue"
                   name="lowerBoundComparisonValue">
            <!--</mat-form-field>-->
        </div>
        <div style="flex: 40px 0 0; display: flex; align-items: center; height: 50px; justify-content: center;">
            and
        </div>
        <div style="flex: 1 1 1;  align-items: center; ">
            <!--<mat-form-field color="accent" class="full-width" appearance="outline">
                <mat-label for="upperBoundComparisonValue">Upper Value</mat-label>-->
            <input type="{{getComparisonInputType()}}" id="upperBoundComparisonValue"
                   required class="nqc-row-input"
                   [(ngModel)]="GetComparisonDetail().upperBoundComparisonValue" name="upperBoundComparisonValue">
            <!--</mat-form-field>-->
        </div>
    </div>
    <!-------------------------------------------------------------------->
    <div style="flex: 40px 0 0; display: flex; align-items: center; height: 50px; white-space: nowrap">
        &nbsp;go to&nbsp;
    </div>
    <div style="flex: 150px 0 0;">
        <button mat-sroked-button class="fake-select" (click)="openRoutingModal()">
            {{GetNextQuestionLabel()}} <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
        </button>
        <!--<div class="path-icon-addon" (click)="openRoutingModal()">
            <mat-icon>arrow_drop_down</mat-icon>
        </div>-->
    </div>
    <div style="flex: 150px 0 0;">
        <app-decision-selection [p]="this" [actionContainer]="GetComparisonDetail()"></app-decision-selection>
    </div>
    <div style="flex: 25px 0 0; align-items: center; height: 50px;">
        <button mat-button-icon style="border:0px;" (click)="remove()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>

<div *ngIf="!isValid()" class="i-am-error error-text-color">
    The comparison is invalid.
</div>


<div class="row">
    <div class="column-1">
    </div>
    <div class="column-11">
        <div *ngFor="let ar of nextQuestionCondition.comparison.action" class="add-justification-message">
            <div *ngIf="ar.actionName == '' || ar.actionName == null" class="bluealert">
                <div class="title" style=" ">Add Justification Message</div>
                <button (click)="openAddDecisionJustificationModal(ar)">Add</button>
            </div>
            <div *ngIf="ar.actionName != '' && ar.actionName != null" class="greenalert">
                <div class="title" style=" ">Edit Justification Message</div>
                <button (click)="openAddDecisionJustificationModal(ar)">Edit</button>
            </div>
        </div>
    </div>
</div>

