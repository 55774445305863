
<h2 mat-dialog-title>Set End Date</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <form id="terminateForm" [formGroup]="terminateForm" (ngSubmit)="save()">
        <mat-form-field appearance="outline">
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="end">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <p>Form Status: {{ terminateForm.status }}</p>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="terminateForm" [disabled]="!terminateForm.valid"
            mat-raised-button color="primary">Save</button>
</mat-dialog-actions>