import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionSetTable, QuestionSetTableVersionItem } from '../../classes/questionsetdashboard';
import { ApiPaths } from '../../shared/environments/routes';
import { environment } from '../../shared/environments/environments';
import { QuestionSet } from '../../classes/questionset';
import { AuthService } from '../../shared/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionSetDashboardService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    //public getAllQuestionSetDashboardFromService(): Observable<HttpResponse<any>> {
    public getAllQuestionSetDashboardFromService(clientID: string): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetQuestionSetTableViewList, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapQuestionSetDashboardResponse(response: HttpResponse<any>) {
        const questionsettables: QuestionSetTable[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const qs = new QuestionSetTable();
            if (v instanceof Object) {
                qs.questionSetHeaderId = parseInt(v['questionSetHeaderId' as keyof typeof v].toString());
                qs.questionSetName = v['questionSetName' as keyof typeof v].toString();
                qs.clientID = v['clientID' as keyof typeof v].toString();
                qs.questionSetDescription = v['questionSetDescription' as keyof typeof v].toString();
                qs.questionSetTypeName = v['questionSetTypeName' as keyof typeof v].toString();
                qs.questionSetId = parseInt(v['questionSetId' as keyof typeof v].toString());
                qs.majorVersion = parseInt(v['majorVersion' as keyof typeof v].toString());
                qs.minorVersion = parseInt(v['minorVersion' as keyof typeof v].toString());
                qs.questionSetStatus = parseInt(v['questionSetStatus' as keyof typeof v].toString());
                qs.applicationsEnabled = v['applicationsEnabled' as keyof typeof v].toString();
                questionsettables.push(qs);
            }
        }
        return questionsettables;
    }

    public mapQuestionSetDashboardResponseVersionMerge(response: HttpResponse<any>) {
        const questionsettables: QuestionSetTable[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            let qsv = new QuestionSetTableVersionItem();
            let qs = new QuestionSetTable();
            if (v instanceof Object) {
                let qhid = parseInt(v['questionSetHeaderId' as keyof typeof v].toString());
                let fqs: QuestionSetTable[] = questionsettables.filter(i => { return i.questionSetHeaderId == qhid; });
                qsv.questionSetId = parseInt(v['questionSetId' as keyof typeof v].toString());
                qsv.majorVersion = parseInt(v['majorVersion' as keyof typeof v].toString());
                qsv.minorVersion = parseInt(v['minorVersion' as keyof typeof v].toString());
                qsv.questionSetStatus = parseInt(v['questionSetStatus' as keyof typeof v].toString());
                //console.log("fqs", fqs);
                if (fqs != null && fqs.length > 0) {
                    qs = fqs[0];
                    qs.versionItems!.push(qsv);
                    if (qs.questionSetId! < qsv.questionSetId)
                        qs.questionSetId = qsv.questionSetId;
                    if (qsv.questionSetStatus != 3)
                        qs.questionSetStatus = qsv.questionSetStatus;
                } else {
                    qs.questionSetId = qsv.questionSetId;
                    qs.questionSetHeaderId = qhid;
                    qs.questionSetName = v['questionSetName' as keyof typeof v].toString();
                    qs.clientID = v['clientID' as keyof typeof v].toString();
                    qs.questionSetDescription = v['questionSetDescription' as keyof typeof v].toString();
                    qs.applicationsEnabled = v['applicationsEnabled' as keyof typeof v].toString();
                    qs.questionSetTypeName = v['questionSetTypeName' as keyof typeof v].toString();
                    qs.questionSetStatus = qsv.questionSetStatus;
                    qs.versionItems = [];
                    qs.versionItems.push(qsv)
                    questionsettables.push(qs);
                    //console.log("IDVA", questionsettables);
                }

            }
            //questionsettables.push(qs);
        }
        //console.log("Question Set Table MAPPED", questionsettables);
        return questionsettables;
    }
    public exportTable(clientID: string) {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);
        return this.http.get(
            environment.baseUrl + ApiPaths.ExportQuestionSetTableViewList,
            {
                headers: this.getHeaders(),
                params: queryParams,
                observe: 'events',
                responseType: 'blob'
            }
        );
    }

    public exportQuestionSetToJSONByID(questionSetID: number) {
        var url = environment.baseUrl + ApiPaths.GetQuestionSetJsonByID;
        var queryParams = new HttpParams();
        queryParams = queryParams.append('QuestionSetID', questionSetID);

        return this.http.post(url, '', {
            headers: this.getHeaders(),
            params: queryParams,
            observe: 'events',
            responseType: 'blob'
        }).pipe(map((response: any) => response));
    }

    public exportQuestionSetToPDFByID(questionSetID: number) {
        var url = environment.baseUrl + ApiPaths.GetQuestionSetPDFByID;
        var queryParams = new HttpParams();
        queryParams = queryParams.append('QuestionSetID', questionSetID);

        return this.http.post(url, '', {
            headers: this.getHeaders(),
            params: queryParams,
            observe: 'events',
            responseType: 'blob'
        }).pipe(map((response: any) => response));
    }

    public importQuestionSet(questionSetName: string, clientId: string, questionSetData: QuestionSet
        , questionTypeID: number, filename: string): Observable<HttpResponse<any>> {

        var url = environment.baseUrl + ApiPaths.ImportQuestionSet;
        const body = {
            'ClientID': clientId,
            'questionSetName': questionSetName,
            'qss': JSON.stringify(questionSetData),
            'questionTypeID': questionTypeID,
            'filename': filename,
            'UserId': this.authService.user.id!
        };
        var headers = new HttpHeaders({'Content-Type': "application/json"});

        return this.http.post(url, body, { headers: headers, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public addQuestionSet(
        clientId: string,
        questionSetName: string,
        questionSetDescription: string,
        questionSetTypeId: number
    ): Observable<HttpResponse<any>> {

        var url = environment.baseUrl + ApiPaths.AddQuestionSet;
        const body = {
            'ClientID': clientId,
            'questionSetName': questionSetName,
            'questionSetDescription': questionSetDescription,
            'questionSetTypeId': questionSetTypeId,
            'UserId': this.authService.user.id!
        };
        var headers = new HttpHeaders({'Content-Type': "application/json"});

        return this.http.post(url, body, { headers: headers, observe: 'response' })
            .pipe(map((response: any) => response));
    }

}
