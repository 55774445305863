import { Component, Input } from '@angular/core';
import { QuestionSetControl } from '../../question-set.control';
import { InlineQuestionTestComponent } from '../inline-question-test.component';
import { Question } from '../../../classes/questionset';

@Component({
    selector: 'app-inline-question-set',
    templateUrl: './inline-question-set.component.html',
    styleUrls: ['./inline-question-set.component.scss']
})
export class InlineQuestionSetComponent {
    @Input() qsc!: QuestionSetControl;
    @Input() p!: InlineQuestionTestComponent;
    public qsGuid: string = "";
    constructor() {

    }
    ngOnInit() {
        this.qsGuid = this.qsc.GetQuestionSet()!.qsGuid!;
    }

    requestEvaluate() {
        this.p.evaluateQuestions();
    }
    checkChanges(target: string, nextId:string | undefined) {
        console.log(target)
        
        var selected = document.querySelectorAll("[data-question-selector='" + target + "'] input:checked");
        console.log(selected)
        if (selected.length == 0) {
            document.querySelectorAll("[data-question-selector='" + target + "'] input").forEach(n => {
                var par = n.closest("mat-checkbox")!;
                par.classList.remove('multiselect-limit-disabled');
            });
        } else {
            document.querySelectorAll("[data-question-selector='" + target + "'] input").forEach(n => {
                var par = n.closest("mat-checkbox")!;
                console.log(nextId, par.getAttribute("data-next-id"), n)
                if (par.getAttribute("data-next-id") != nextId) {
                    par.classList.add('multiselect-limit-disabled');
                }
            });
        }
    }
}
