import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

    constructor() { }

    // Helper method to check if a record is past effective
    isPastEffective(data: any): boolean {
        const endDate = new Date(data.endDate || '');
        const today = new Date();
        return endDate < today;
    }

    // Helper method to check if a record is currently effective
    isCurrentlyEffective(data: any): boolean {
        const startDate = new Date(data.startDate || '');
        const endDate = new Date(data.endDate || '');
        const today = new Date();
        return startDate <= today && today <= endDate;
    }

    // Helper method to check if a record is future effective
    isFutureEffective(data: any): boolean {
        const startDate = new Date(data.startDate || '');
        const today = new Date();
        return startDate > today;
    }
}
