import { DecisionTree } from "./decisiontree";

const hashBool = { Text: 'Is Free Text', Select: 'Is Multiple', Date: 'Require Time', Numeric: 'Is Numeric' }
type NextQuestionConditionTypes = "Range" | "Single";
type RangeComparisonOperators = "LT" | "GT" | "LE" | "GE";
type SingleComparisonOperators = "LT" | "GT" | "EQ" | "NE" | "LE" | "GE";
type FreeTextIndicatorOptions =
    "NA" | 	//Non applicable, no text fill in available
    "M" | 	//Mandatory, Text fill in is required to be filled in
    "O";	//Optional text fill in, not required but available

export class ActionResult {
    actionTextGuid: string = "";
    actionName: string | undefined; //name
    actionType: string | undefined; //approval or denial
    routing: string | undefined; //decision
    actionTextPharmacy: string | undefined; //english texts
    actionTextProvider: string | undefined;
    actionTextPatient: string | undefined;
    overrides: Override[] = [];
    overrideCriteria: OverrideCriteria[] = [];
    comments: string | undefined;
    constructor(data: any) {
        var self = this;
        self.actionName = data.actionName;
        self.actionType = data.actionType;
        self.routing = data.routing;
        self.actionTextGuid = data.actionTextGuid;
        self.comments = data.comments ?? data.techHandling;
        self.actionTextPharmacy = data.actionTextPharmacy;
        self.actionTextProvider = data.actionTextProvider;
        self.actionTextPatient = data.actionTextPatient ?? data.actionText;
        self.overrides = [];
        data.overrides?.forEach(
            function (d: any) {
                let n = new Override(d);
                self.overrides!.push(n);
            }
        )
        self.overrideCriteria = [];
        data.overrideCriteria?.forEach(
            function (d: any) {
                let n = new OverrideCriteria(d);
                self.overrideCriteria!.push(n);
            }
        )
    }
}

export class Override {
    overrideCode: string | undefined;
    authorizationPeriod: string | undefined;
    oneTime: boolean = false;
    constructor(data: any) {
        var self = this;
        self.overrideCode = data.overrideCode;
        self.authorizationPeriod = data.authorizationPeriod;
        self.oneTime = data.oneTime;
    }
}

export class OverrideCriteria {
    title: string = "";
    value: string = "";
    constructor(data: any) {
        var self = this;
        self.title = data.title;
        self.value = data.value;
    }
}

export class Comparison {
    nextQuestionID: string | undefined;
    action: ActionResult[] = [];
    constructor(data: any) {
        var self = this;
        self.nextQuestionID = data.nextQuestionID;
        self.action = [];
        data.action?.forEach(
            function (d: any) {
                let n = new ActionResult(d);
                self.action!.push(n);
            }
        )
    }
}

export class SingleComparison extends Comparison {
    comparisonOperator: SingleComparisonOperators | undefined; 			// comparison to be performed
    comparisonValue: any; 								// indicates the value to which the responder’s answer is compared
    constructor(data: any) {
        super(data);
        var self = this;
        self.comparisonOperator = data.comparisonOperator;
        self.comparisonValue = data.comparisonValue;
    }
}

export class RangeComparison extends Comparison {
    lowerBoundComparisonValue: any; 						// indicates the range starting value to which the responder’s answer is compared.
    lowerBoundComparisonOperator: RangeComparisonOperators | undefined; 	// gives the comparison to be performed with respect to the range start. Supported values are
    upperBoundComparisonValue: any;				 		//indicates the range ending value to which the responder’s answer is compared.
    upperBoundComparisonOperator: RangeComparisonOperators | undefined; 	//gives the comparison to be performed with respect to the range end. Supported values are
    constructor(data: any) {
        super(data);
        var self = this;
        self.lowerBoundComparisonValue =    data.lowerBoundComparisonValue;
        self.lowerBoundComparisonOperator = data.lowerBoundComparisonOperator;
        self.upperBoundComparisonValue = data.upperBoundComparisonValue;
        self.upperBoundComparisonOperator = data.upperBoundComparisonOperator;
    }
}

export class NextQuestionCondition {
    type!: NextQuestionConditionTypes;
    comparison!: Comparison;
    constructor(data: any) {
        var self = this;
        self.type = data.type;
        if (self.type == "Single") {
            self.comparison = new SingleComparison(data.comparison);
        }
        if (self.type == "Range") {
            self.comparison = new RangeComparison(data.comparison);
        }
    }
}

export class CodedReference {
    id!: number;
    codeSystemVersion: string | undefined;
    qualifier: string | undefined;
    code: string | undefined;
    description: string | undefined;
    //private control:Control;
    constructor(data: any) {
        var self = this;
        //console.log(data)
        self.id = data.codedReferenceID ?? data.id;
        self.codeSystemVersion = data.codeSystemVersion;
        self.qualifier = data.qualifier;
        self.code = data.code;
        self.description = data.description ?? data.codedReferenceDescription;
    }
}

export class AnswerChoiceCommonType {
    id!: string;
    nextQuestionID: string | undefined;					// Next question to go to once answered, required on Date and Text types, 
    choiceText: string | undefined;
    choiceTextGuid: string = "";
    languageGuid: string | undefined;
    additionalFreeTextIndicator!: string;
    sequence: number = 9997; 							// Standard sequence
    action: ActionResult[] = [];						    // Internal Handling
    codedReference: CodedReference[] = [];		  // Complex type for PA Coded Reference
    constructor(data: any) {
        var self = this;
        self.id = data.id ?? data.choiceID;
        self.nextQuestionID = data.nextQuestionID;
        self.choiceText = data.choiceText;
        self.choiceTextGuid = data.choiceTextGuid;
        self.languageGuid = data.languageGuid;
        self.additionalFreeTextIndicator = data.additionalFreeTextIndicator;
        self.sequence = data.sequence | data.sequenceNumber;
        //self.action = data.selectAction;
        self.codedReference = [];
        data.codedReference?.forEach(function (d: any) {
            let n = new CodedReference(d);
            self.codedReference!.push(n);
        });
        self.action = [];
        data.action?.forEach(
            function (d: any) {
                let n = new ActionResult(d);
                self.action!.push(n);
            }
        )
    }
}

export class QuestionType {

}

export class QuestionTypeFreeText extends QuestionType {
    isFreeText!: boolean;
    answer: AnswerForFreeText | undefined;
    constructor(data: any) {
        super();
        //console.log("FT ",data)
        var self = this;
        self.isFreeText = true;
        if (data.answer != null && data.answer !== undefined) {
            self.answer = new AnswerForFreeText(data.answer);
        }
    }
}

export class QuestionTypeNumeric extends QuestionType {
    isNumeric!: boolean;
    nextQuestionCondition: NextQuestionCondition[] = [];
    answer: AnswerForNumeric | undefined;
    constructor(data: any) {
        super();
        var self = this;
        self.isNumeric = true;
        if (data.answer != null && data.answer !== undefined) {
            self.answer = new AnswerForNumeric(data.answer);
        }
        self.nextQuestionCondition = [];
        data.nextQuestionCondition?.forEach(function (d: any) {
            let n = new NextQuestionCondition(d);
            self.nextQuestionCondition!.push(n);
        });
    }
}

export class QuestionTypeDate extends QuestionType {
    isDateTimeRequired!: boolean;
    nextQuestionCondition: NextQuestionCondition[] = [];
    answer: AnswerForDate | undefined;
    constructor(data: any) {
        super();
        var self = this;
        self.isDateTimeRequired = data.isDateTimeRequired;
        self.nextQuestionCondition = [];
        if (data.answer != null && data.answer !== undefined) {
            self.answer = new AnswerForDate(data.answer);
        }
        data.nextQuestionCondition?.forEach(function (d: any) {
            let n = new NextQuestionCondition(d);
            self.nextQuestionCondition!.push(n);
        });
    }
}

export class QuestionTypeSelect extends QuestionType {
    isMultiple!: boolean;
    isYesNoInternalOnly: boolean = false;
    choice: AnswerChoiceCommonType[] = [];
    nextQuestionCondition: NextQuestionCondition[] = [];
    answer: AnswerForSelect | undefined;
    constructor(data: any) {
        super();
        var self = this;
        self.isMultiple = data.isMultiple;
        self.isYesNoInternalOnly = data.isYesNoInternalOnly ?? false;
        self.nextQuestionCondition = [];
        if (data.answer != null && data.answer !== undefined) {
            self.answer = new AnswerForSelect(data.answer);
        }
        data.nextQuestionCondition?.forEach(function (d: any) {
            let n = new NextQuestionCondition(d);
            self.nextQuestionCondition!.push(n);
        });
        self.choice = [];
        data.choice?.forEach(function (d: any) {
            let a = new AnswerChoiceCommonType(d);
            self.choice!.push(a);
        });
    }
}

export class Question {
    internalOrder: number = 999997;
    label: string | undefined;
    id!: string; 					// Incrementing sequence or GUID to reference question element itself
    sequenceNumber: number = 99998; 			// Standard sequence
    level: number | undefined;				// Level
    text: string = "";				// Text of question eg "Who Am I?"
    languageGuid: string = "";
    questionComment: string | undefined;
    defaultNextQuestionID: string | undefined;// Next question to go to once answered, required on Date and Text types, 
    codedReference: CodedReference[] = [];
    questionType!: QuestionType;
    //nextQuestionCondition: NextQuestionCondition[] = [];
    action: ActionResult[] = [];				// Internal Handling
    constructor(data: any) {
        var self = this;
        var missingOrderCounter = 9000;
        self.id = data.id;
        self.sequenceNumber = data.sequencenumber | data.sequenceNumber;
        self.level = data.level;
        self.text = data.text;
        self.languageGuid = data.languageGuid;
        self.questionComment = data.questionComment ?? "";
        self.defaultNextQuestionID = data.defaultNextQuestionID;
        self.label = data.label;
        self.internalOrder = data.internalOrder ?? data.InternalOrder ?? self.sequenceNumber ?? missingOrderCounter++;
        self.codedReference = [];
        data.codedReference?.forEach(function (d: any) {
            let n = new CodedReference(d);
            self.codedReference!.push(n);
        });
        //self.nextQuestionCondition = [];
        //data.nextQuestionCondition?.forEach(function (d: any) {
        //    let n = new NextQuestionCondition(d);
        //    self.nextQuestionCondition!.push(n);
        //});
        self.action = [];
        data.action?.forEach(
            function (d: any) {
                let n = new ActionResult(d);
                self.action!.push(n);
            }
        )
        //console.log("data load test ", data)
        //TODO: Remove TEst
        if (data.questionType === undefined) {
            data.questionType = { isFreeText: true };
        }
        //TEST
        if (data.questionType.isMultiple !== undefined) {
            self.questionType = new QuestionTypeSelect(data.questionType);
        } else if (data.questionType.isFreeText !== undefined) {
            self.questionType = new QuestionTypeFreeText(data.questionType);
        } else if (data.questionType.isDateTimeRequired !== undefined) {
            self.questionType = new QuestionTypeDate(data.questionType);
        } else if (data.questionType.isNumeric !== undefined) {
            self.questionType = new QuestionTypeNumeric(data.questionType);
        }
    }
}

export class TreeOnly {
    questionName: string = "";
    isTreeOnly: boolean = false;
    constructor(data: any) {
        var self = this;
        self.questionName = data.questionName ?? "";
        self.isTreeOnly = data.isTreeOnly ?? false;
    }
}

export class DT {
    decisionTreeName: string = "";
    decisionTreeDescription: string = "";
    decisionTreeToQuestionSet: DecisionTreeToQuestionSet[] = [];
    treeOnly: TreeOnly[] = [];
    constructor(data: any) {
        var self = this;
        self.decisionTreeName = data.decisionTreeName ?? data.DecisionTreeName ??"";
        self.decisionTreeDescription = data.decisionTreeDescription ?? data.DecisionTreeDescription ?? "";
        self.decisionTreeToQuestionSet = [];
        let dt2qs = data.decisionTreeToQuestionSet ?? data.DecisionTreeToQuestionSet;
        dt2qs.forEach(function (d: any) {
            let n = new DecisionTreeToQuestionSet(d);
            self.decisionTreeToQuestionSet!.push(n);
        });
        self.treeOnly = []
        let treeOnly = data.treeOnly ?? data.TreeOnly;
        treeOnly?.forEach(function (d: any) {
            let n = new TreeOnly(d);
            self.treeOnly!.push(n);
        });
    }
}

export class DecisionTreeToQuestionSet {
    order!: number;
    questionSet: QuestionSet | undefined;
    constructor(data: any) {
        var self = this;
        self.order = data.order ?? 1;
        self.questionSet = new QuestionSet(data.questionSet);
    }
}

export class QuestionSetHeader {
    id!: number;
    title: string ="";
    description: string | undefined;
    comments: string[] | undefined;
    attachmentRequired!: boolean;
    constructor(data: any) {
        var self = this;
        self.id = data.id ?? data.questionSetHeaderID;
        self.title = data.title ?? data.questionSetName;
        self.description = data.description ?? "";
        self.comments = data.comments ?? "";
        data.attachmentRequired = data.attachmentRequired;
    }
}

export class QuestionSet {
    questionSetHeader: QuestionSetHeader | undefined;
    questions: Question[] = [];
    qsGuid: string | undefined;
    constructor(data: any) {
        var self = this;
        //console.log(data);
        //console.log("-----------------------------")
        //console.log(data.questionSetHeader);
        //console.log("-----------------------------")
        self.questionSetHeader = new QuestionSetHeader(data.questionSetHeader);
        if (data.qsGuid) {
            self.qsGuid = data.qsGuid
        } else {
            self.qsGuid = URL.createObjectURL(new Blob()).substr(-36);
        }
        //console.log("self.qsGuid",self.qsGuid);
        
        self.questions = [];
        data.questions?.forEach(function (d: any) {
            let n = new Question(d);
            self.questions!.push(n);
        });
    }
}


export class AttachmentWithControlNumber {
    attachmentControlNumber: number | undefined;
    attachmentSource: string | undefined;
    attachmentData: string | undefined;
    mimeType: string | undefined;
    constructor(data: any) {
        var self = this;
        self.attachmentControlNumber = data.attachmentControlNumber;
        self.attachmentSource = data.attachmentSource;
        self.attachmentData = data.attachmentData;
        self.mimeType = data.mimeType;

    }
}
export class SelectAnswer {
    choiceID: string;
    additionalFreeText: string | undefined;
    constructor(data: any) {
        this.choiceID = data.choiceID;
        this.additionalFreeText = data.additionalFreeText;

    }
}
export class DateAnswer { // must be true to specification do not over think
    date: Date | undefined;
    dateTime: Date | undefined;
    constructor(data: any) {
        var self = this;
        self.date = data.date;
        self.dateTime = data.dateTime;

    }
}
export class CodedReferenceAnswer {
    seeTransactionLevelAttachmentControlNumber: number | undefined;
    answerValue: string | undefined;
    attachment: AttachmentWithControlNumber | undefined;
    numericAnswer: number | undefined;
    select: SelectAnswer[] | undefined;
    date: DateAnswer | undefined;
    constructor(data: any) {
        var self = this;
        self.seeTransactionLevelAttachmentControlNumber = data.seeTransactionLevelAttachmentControlNumber;
        self.answerValue = data.answerValue;
        self.attachment = data.attachment;
        self.numericAnswer = data.numericAnswer;
        data.select?.forEach(function (d: any) {
            let n = new SelectAnswer(d);
            self.select!.push(n);
        });

        self.date = data.date;

    }
}
export class AnswerForFreeText {
    codedReferenceAnswer: CodedReferenceAnswer | undefined;
    submitterProvidedAnswer: string | undefined;
    answeredBy: string | undefined;
    answerComment: string | undefined;
    constructor(data: any) {
        var self = this;
        self.codedReferenceAnswer = data.codedReferenceAnswer;
        self.submitterProvidedAnswer = data.submitterProvidedAnswer;
        self.answeredBy = data.answeredBy;
        self.answerComment = data.answerComment;

    }
}
export class AnswerForNumeric {
    codedReferenceAnswer: CodedReferenceAnswer | undefined;
    submitterProvidedNumericAnswer: number | undefined;
    answeredBy: string | undefined;
    answerComment: string | undefined;
    constructor(data: any) {
        var self = this;
        self.codedReferenceAnswer = data.codedReferenceAnswer;
        self.submitterProvidedNumericAnswer = data.submitterProvidedNumericAnswer;
        self.answeredBy = data.answeredBy;
        self.answerComment = data.answerComment;

    }
}
export class AnswerForSelect {
    codedReferenceAnswer: CodedReferenceAnswer | undefined;
    select: SelectAnswer[] | undefined;
    answeredBy: string | undefined;
    answerComment: string | undefined;
    constructor(data: any) {
        var self = this;
        self.codedReferenceAnswer = data.codedReferenceAnswer;
        self.answeredBy = data.answeredBy;
        self.answerComment = data.answerComment;
        self.select = [];
        data.select?.forEach(function (d: any) {
            let n = new SelectAnswer(d);
            self.select!.push(n);
        });
    }
}
export class AnswerForDate {
    codedReferenceAnswer: CodedReferenceAnswer | undefined;
    date: DateAnswer | undefined;
    answeredBy: string | undefined;
    answerComment: string | undefined;
    constructor(data: any) {
        var self = this;
        self.codedReferenceAnswer = data.codedReferenceAnswer;
        self.date = new DateAnswer(data.date);
        self.answeredBy = data.answeredBy;
        self.answerComment = data.answerComment;
    }
}

export class ApplicationStatus {
    id!: number;
    description: string = "";
    name!: string;
    enabled: boolean = false;
    constructor(data: any) {
        //console.log("ApplicationStatus Data", data)
        this.id = data.applicationID;
        this.description = data.applicationDescription;
        this.name = data.applicationName;
        this.enabled = data.enabled;
    }
}
export class QuestionLanguage {
    languageTextGuid: string;
    languageText: string;
    languageCode: string;
    recipient: string;
    constructor(data: any) {
        this.languageTextGuid = data.LanguageTextGuid;
        this.languageText =     data.LanguageText;    
        this.languageCode =     data.LanguageCode;   
        this.recipient =        data.recipient;
    }
}