<!--{{questionSetControl.GetQuestions().length}}-->

<div *ngIf="!questionSetControl.cacheInitialized">
    Loading
</div>
<section *ngIf="questionSetControl.cacheInitialized == true">


    <div id="qInList{{questionSetControl.initialOrder}}-{{question.id}}"
         class="question"
         [class]="'type'+GetQuestionTypeFull()"
         [class.error]="!questionSetControl.ValidateQuestion(question)"
         [attr.data-questionid]="question.id"
         [attr.data-languageguid]="question.languageGuid"
         (click)="selectQuestion()">

        <div id="question-anchor-{{questionSetControl.initialOrder}}-{{question.id}}"
             class="question-anchor"> </div>
        <div style="display:flex;align-items:start; margin-bottom: 10px;">

            <button mat-icon-button color="accent" (click)="move('up')" class="action-button"><mat-icon>arrow_upward</mat-icon></button>
            <button mat-icon-button color="accent" (click)="move('down')" class="action-button"><mat-icon>arrow_downward</mat-icon></button>
            <div style="display:flex; flex-basis:100px; flex-grow:100; height:40px;align-items:center;">
                <!--{{question.internalOrder}} to {{questionSetControl.internalOrderOffset}} --->
                {{question.internalOrder + questionSetControl.internalOrderOffset}}.
                {{GetQuestionTypeFullLabel()}}
                
            </div>
            
                <button mat-mini-fab class="show-question-detail-button" *ngIf="showQuestionDetail == true" (click)="showQuestionDetail = false">
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button mat-mini-fab class="show-question-detail-button" *ngIf="showQuestionDetail == false" (click)="showQuestionDetail = true">
                    <mat-icon>arrow_drop_up</mat-icon>
                </button>
            
        </div>
        <div *ngIf="GetQuestionType() == 'Select' && GetSelectType().choice.length < 2" class="i-am-error-alert">
            <mat-icon>info</mat-icon> &nbsp;
            Please provide more then 1 possible answer
        </div>
        <div *ngIf="!questionSetControl.ValidateQuestionInTree(question)" class="i-am-error-alert">
            <mat-icon>info</mat-icon>
            The question is not part of the flow, please connect it to other questions.
        </div>



        <mat-form-field color="accent" class="full-width" appearance="outline"
                        [class.i-am-error-border]="(question.text.length  < 3)">
            <mat-label for="text">Question Text</mat-label>
            <!--id="text"-->
            <textarea matInput class="full-width"
                      required
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAuthsizeMinRows="1"
                      cdkAuthsizeMaxRows="10"
                      [(ngModel)]="question.text" name="text"></textarea>

            <mat-error *ngIf="(question.text.length ?? 0) < 3" class="i-am-error error-inline ">
                Provide a question with more then 3 characters.
            </mat-error>
        </mat-form-field>
        <span *ngIf="(question.text.length ?? 0) < 3" class="i-am-error error-inline ">

        </span>
        <div *ngIf="showQuestionDetail" class="question-detail">
            <mat-form-field color="accent" class="full-width" appearance="outline"
                            *ngIf="showComment">
                <mat-label for="questionComment">Description for internal users only</mat-label>
                <textarea matInput type="text" class="full-width" id="questionComment"
                          required
                          cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize"
                          cdkAuthsizeMinRows="1"
                          cdkAuthsizeMaxRows="10"
                          [(ngModel)]="question.questionComment" name="questionComment"></textarea>
            </mat-form-field>
            <div style="display:flex; flex-direction:row;">
                <div style="flex: 175px 0 0">
                    <mat-form-field color="accent" class="full-width" appearance="outline">
                        <mat-label for="questionTypeSelect">Question Type</mat-label>
                        <mat-select class="full-width" id="questionTypeSelect"
                                    required value="{{GetQuestionTypeFull()}}"
                                    name="questionTypeSelect"
                                    (selectionChange)="ChangeQuestionType($event)">

                            <mat-option value="yesNo">
                                Yes / No
                            </mat-option>
                            <mat-option value="Multi-Select">
                                <mat-icon>check_box</mat-icon>
                                Multiple Selection
                            </mat-option>
                            <mat-option value="Select">
                                <mat-icon>radio_button_checked</mat-icon>
                                Single Selection
                            </mat-option>
                            <mat-option value="Date">
                                <mat-icon>calendar_month</mat-icon>
                                Date
                            </mat-option>
                            <mat-option value="Datetime">
                                <mat-icon>calendar_today</mat-icon>
                                DateTime
                            </mat-option>
                            <mat-option value="FreeText">
                                <mat-icon>format_align_left</mat-icon>
                                Free Text
                            </mat-option>
                            <mat-option value="Numeric">
                                <mat-icon>tag</mat-icon>
                                Numeric
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div style="flex: 100px 100 100;"></div>

                <div style="flex: 340px 0 0; display: flex;">
                    <div style="flex: 40px 0 0; display:flex; align-items: center; height: 50px; white-space:nowrap">
                        &nbsp;go to&nbsp;
                    </div>
                    <div style="flex: 150px 0 0">
                        <button mat-stroked-button class="fake-select" (click)="openRoutingModal()"
                                [class.i-am-error-border]="!(question.defaultNextQuestionID != null && question.defaultNextQuestionID != '') && showPathwayAlert()">
                            {{GetNextQuestionLabel()}} <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
                        </button>
                        <div *ngIf="!(question.defaultNextQuestionID != null && question.defaultNextQuestionID != '') && showPathwayAlert()"
                             class="i-am-error error-inline ">
                            Please select a path.
                        </div>

                    </div>
                    <!--"openActionResultModal()"-->
                    <div style="flex: 150px 0 0">
                        <app-decision-selection [p]="this" [actionContainer]="question" [isError]="question.defaultNextQuestionID == 'Final' && ( question.action.length == 0) && questionSetControl.questionErrorMessages[question.id].length > 0"></app-decision-selection>
                        <div *ngIf="question.defaultNextQuestionID == 'Final' && ( question.action.length == 0)
                             && questionSetControl.questionErrorMessages[question.id].length>
                            0" class="i-am-error error-inline ">
                            Please select a decision.
                        </div>
                    </div>

                </div>
                <div style="flex: 25px 0 0;display:none;">
                    <button mat-flat-button [matMenuTriggerFor]="qmenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #qmenu="matMenu">
                        <button class="full-width" mat-raised-button (click)="openCodedReferenceModal()">Add Coded Reference</button>
                    </mat-menu>
                </div>
            </div>
            <span *ngFor="let cr of question.codedReference" style="width:unset;margin:4px;">
                <app-codedreference-template [codedReference]="cr" [p]="question"></app-codedreference-template>
            </span>
            <div *ngFor="let ar of question.action" class="add-justification-message">
                <div *ngIf="ar.actionName == '' || ar.actionName == null" class="bluealert">
                    <div class="title" style=" ">Add Justification Message</div>
                    <button (click)="openAddDecisionJustificationModal(ar)">Add</button>
                </div>
                <div *ngIf="ar.actionName != '' && ar.actionName != null" class="greenalert">
                    <div class="title" style=" ">Edit Justification Message</div>
                    <button (click)="openAddDecisionJustificationModal(ar)">Edit</button>
                </div>
            </div>

            <section *ngIf="GetQuestionType() == 'Select'">
                <section *ngFor="let c of GetSelectType().choice | sortAnswerBySequence" class="{{GetQuestionTypeFull()}}">
                    <app-edit-question-answer-choice [answerChoice]="c" [p]="this" [questionSetControl]="questionSetControl" [questionSetRoutingList]="questionSetRoutingList"></app-edit-question-answer-choice>
                </section>

                <div *ngIf="!GetSelectType().isYesNoInternalOnly">
                    <span *ngIf="!GetSelectType().isMultiple">
                        <mat-icon>radio_button_unchecked</mat-icon>
                    </span>
                    <span *ngIf="GetSelectType().isMultiple">
                        <mat-icon>check_box_outline_blank</mat-icon>
                    </span>
                    &nbsp; <button mat-stroked-button (click)="AddAnswer()">Add Answer</button>
                    or
                    <button mat-button color="accent" (click)="AddAnswerForOther()"><mat-icon>add</mat-icon>Add "Other"</button>
                </div>
                <!--<div *ngIf="GetSelectType().nextQuestionCondition.length > 0">
                    <p style="margin-left:4vw;">Comparison based routing</p>
                </div>-->
                <div *ngFor="let nqc of GetSelectType().nextQuestionCondition; ; let idx=index" class="">
                    <app-nextquestioncondition-template [selectableCount]="GetSelectType().choice.length" [nextQuestionCondition]="nqc" [comparisonSource]="GetQuestionType()" [question]="question" [questionSetControl]="questionSetControl" [orderIndex]="idx" [questionSetRoutingList]="questionSetRoutingList"></app-nextquestioncondition-template>
                </div>
            </section>
            <section *ngIf="GetQuestionType() == 'FreeText'">
                <div style="height: 40px; padding: 8px 0px 8px 16px; border-radius: 4px; margin-bottom:5px;">
                    <div style="height: 24px; opacity: 38%; font-weight: 400; font-size: 16px; line-height: 24px; border-bottom: 1px dashed grey; ">
                        Text answer
                    </div>
                </div>
            </section>
            <section *ngIf="GetQuestionType() == 'Date'">
                <div style="height: 40px; width:200px; padding: 8px 0px 8px 16px; border-radius: 4px; margin-bottom:5px;">
                    <div style="display: inline; height: 24px; opacity: 38%; font-weight: 400; font-size: 16px; line-height: 24px; border-bottom: 1px dashed grey; ">
                        MM/DD/YY <mat-icon style="height: 21px; width: 21px;">today</mat-icon>
                    </div>
                </div>
                <div *ngFor="let nqc of  GetDateType().nextQuestionCondition; ; let idx=index" class="">
                    <app-nextquestioncondition-template [nextQuestionCondition]="nqc" [comparisonSource]="GetQuestionType()" [timerequired]="GetDateType().isDateTimeRequired" [question]="question" [questionSetControl]="questionSetControl" [orderIndex]="idx" [questionSetRoutingList]="questionSetRoutingList"></app-nextquestioncondition-template>
                </div>
            </section>
            <section *ngIf="GetQuestionType() == 'Numeric'">
                <div style="height: 40px; width:200px; padding: 8px 0px 8px 16px; border-radius: 4px; margin-bottom:5px;">
                    <div style="height: 24px; opacity: 38%; font-weight: 400; font-size: 16px; line-height: 24px; border-bottom: 1px dashed grey; ">
                        Numeric answer
                    </div>
                </div>
                <div *ngFor="let nqc of  GetNumericType().nextQuestionCondition; ; let idx=index" class="">
                    <app-nextquestioncondition-template [nextQuestionCondition]="nqc" [comparisonSource]="GetQuestionType()" [question]="question" [questionSetControl]="questionSetControl" [orderIndex]="idx" [questionSetRoutingList]="questionSetRoutingList"></app-nextquestioncondition-template>
                </div>
            </section>
            <section *ngIf="GetQuestionType() == 'Select'">
                <section *ngIf="GetSelectType().isMultiple">
                    <button mat-button color="accent" (click)="AddNextQuestionCondition('Single')"><mat-icon>add</mat-icon>Add Condition</button>
                    <!--<button mat-button color="accent" (click)="AddNextQuestionCondition('Range')"><mat-icon>add</mat-icon> Range Compare Condition</button>-->
                </section>
            </section>
            <section *ngIf="GetQuestionType() == 'Numeric'">
                <button mat-button color="accent" (click)="AddNextQuestionCondition('Single')"><mat-icon>add</mat-icon> Add Condition</button>
                <!--<button mat-button color="accent" (click)="AddNextQuestionCondition('Range')"><mat-icon>add</mat-icon> Range Compare Condition</button>-->
            </section>
            <section *ngIf="GetQuestionType() == 'Date'">
                <button mat-button color="accent" (click)="AddNextQuestionCondition('Single')"><mat-icon>add</mat-icon> Add Condition</button>
                <!--<button mat-button color="accent" (click)="AddNextQuestionCondition('Range')"><mat-icon>add</mat-icon> Range Compare Condition</button>-->
            </section>

            <mat-divider></mat-divider>
            <div class="full-width" style="text-align:right;">
                <span>
                    <mat-checkbox [(ngModel)]="showComment" id="sd{{question.id}}"></mat-checkbox>
                    <label for="sd{{question.id}}">Show Description</label>
                </span>
                <span #tooltip="matTooltip"
                      matTooltip="Copy"
                      [matTooltipPosition]="position.value!"
                      matTooltipHideDelay="300"
                      style="vertical-align: middle; margin-left: 10px; cursor: pointer;">
                    <mat-icon (click)="CopyQuestion()" title="Copy." style="font-weight:500; " color="accent">file_copy</mat-icon>
                </span>
                <span #tooltip="matTooltip"
                      matTooltip="Delete"
                      [matTooltipPosition]="position.value!"
                      matTooltipHideDelay="300"
                      style="vertical-align: middle; margin-left: 10px; cursor: pointer;">
                    <mat-icon (click)="RemoveQuestion()" title="Remove question." color="warn">delete</mat-icon>
                </span>
            </div>
        </div>
    </div>

</section>