import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Controllers, Status } from '../../shared/environments/environments';
import { Client } from '../../classes/client';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { SortService } from '../../shared/services/sort.service';
import { QuestionSetDashboardService } from './question-set-dashboard.service';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { QuestionSetTable } from '../../classes/questionsetdashboard';
import { QuestionSetControl } from '../../shared/question-set.control';
import { CopyModalComponent } from '../../shared/copy-modal/copy-modal.component';
import { AddQuestionSetComponent } from './add-question-set/add-question-set.component';
import { DateService } from '../../shared/services/date.service';
import { ImportQSModalComponent } from './import-modal/import-modal.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { StatusClassPipe } from '../../shared/pipes/status-class.pipe';
import { StatusPipe } from '../../shared/pipes/status.pipe';
import { SortByMajorVersionPipe } from '../../shared/pipes/sort-by-major-version.pipe';

declare var startQuestionSetReview: any;

@Component({
    selector: 'app-question-set',
    standalone: true,
    imports: [MatPaginatorModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatCheckboxModule, MatButtonModule, MatDividerModule, MatIconModule, CommonModule, NgIf, NgFor, FormsModule, ReactiveFormsModule, MatDividerModule
        , MatButtonToggleModule, MatRadioModule, MatMenuModule
        , StatusPipe, StatusClassPipe, SortByMajorVersionPipe],
    templateUrl: './question-set-dashboard.component.html',
    styleUrls: ['./question-set-dashboard.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class QuestionSetDashboardComponent implements AfterViewInit, OnInit {
    title = 'Question Catalog';
    showFilters: boolean = true;
    redCrumbs: any;
    displayedColumns: string[] = ['name', 'desc', 'type', 'status', 'applications', 'action'];
    //'version', 'status',
    dataSource: MatTableDataSource<QuestionSetTable> = new MatTableDataSource<QuestionSetTable>([]);
    columnsToDisplayWithExpand = ['expand',...this.displayedColumns];
    expandedRow?: QuestionSetTable | null;


    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;
    
    user!: User;
    questionSet: QuestionSetTable[] | undefined = [];
    clientSelection = new FormControl('');
    clientsList: Client[] = [];
    selectedClient!: Client;
    searchInput: string = '';

    effectiveDateFilter = this.fb.group({
        past: true,
        current: true,
        future: true,
    });
    statusFilter = this.fb.group({
        pending: true,
        draft: true,
        approved: true,
        /*deleted: false,*/
    });
    typeFilter = this.fb.group({
        pa: true,
        template: true,
        firstappeal: true,
        secondappeal: true,
    });
    constructor(
        private cdr: ChangeDetectorRef,
        public questionsetDashboardService: QuestionSetDashboardService,
        public questionSetService: QuestionSetService,
        public questionSetControl: QuestionSetControl,
        private authService: AuthService,
        private dateService: DateService,
        private dialog: MatDialog,
        private router: Router,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.populateClients();
        this.getQuestionSetTable();
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    toggleChange(event: any) {
        this.redCrumbs = "";
        let toggle = event.source;
        //console.log(toggle);
        if (toggle) {
            let group = toggle.buttonToggleGroup;
            if (event.value.some((item: any) => item == toggle.value)) {
                group.value = [toggle.value];
                this.redCrumbs = toggle.value;
            }
        }
    }

    getQuestionSetTable() {
        this.questionsetDashboardService.getAllQuestionSetDashboardFromService(this.selectedClient.clientID!).subscribe(resp => {
            if (resp.body) {
                this.questionSet = this.questionsetDashboardService.mapQuestionSetDashboardResponseVersionMerge(resp);
                if (this.questionSet.length >= 0) {
                    this.dataSource = new MatTableDataSource(this.questionSet);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.applyFilter(null);
                }
            }
        });
    }

    export() {
        this.questionsetDashboardService.exportTable(this.selectedClient.clientID!).subscribe(
            (event) => {
                let data = event as HttpResponse<Blob>;
                console.log(data);
                const downloadedFile = new Blob([data.body as BlobPart], {
                    type: data.body?.type
                });
                if (downloadedFile.type != "") {
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = "QuestionSetList.xlsx";
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            }
        );
    }
    openImportModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: this
        }
        const dialogRef = this.dialog.open(ImportQSModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) this.getQuestionSetTable(); }
        );
    }
    openAddModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient.clientName
            }
        const dialogRef = this.dialog.open(AddQuestionSetComponent, dialogConfig );
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1 ) this.getQuestionSetTable(); }
        );
        //const modalRef = this.modalService.open(AddQuestionSetComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.currentFilteredClient = this.selectedClient.clientName;
        //modalRef.result.then((result) => {
        //    this.closeResult = 'Closed';
        //    console.log(this.closeResult);
        //    this.getQuestionSetTable();
        //},
        //    (reason) => {
        //        this.closeResult = `Dismissed`;
        //        console.log(this.closeResult);
        //        this.getQuestionSetTable();
        //    });
    }
    openEdit(qs: QuestionSetTable) {
        this.router.navigate(['questionset/edit'], { queryParams: { id: qs.questionSetId } });
    }

    openInteractiveQuestionAnswerModal(qs: QuestionSetTable) {
        if (qs.questionSetId !== undefined) {
            this.questionSetService.getQuestionSetDataFromService(qs.questionSetId).subscribe(resp => {
                console.log(resp)
                this.questionSetControl.LoadQuestionSet(resp.body);
                startQuestionSetReview(qs.questionSetName, this.questionSetControl.GetQuestionSet());
            });
        }
    }

    openCopyModal(qs: QuestionSetTable) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            controllerType: Controllers.QuestionSet,
            id: qs.questionSetId,
            titleToCopy: qs.questionSetName,
        }
        const dialogRef = this.dialog.open(CopyModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) this.getQuestionSetTable(); }
        );
    }

    populateClients() {
        console.log(">>>",this.user.clients);
        this.clientsList = this.user.clients!;
        this.onSelectClient();
    }
    selectClient(c: Client) {
        this.selectedClient = c;
        this.onSelectClient()
    }
    onSelectClient() {
        this.redCrumbs = "";
        if (this.clientsList === undefined) {
            console.error("Client List Undefined");
            return;
        }
        this.selectedClient = this.clientsList.find(x => x.clientID?.toLowerCase() === this.selectedClient.clientID!.toLowerCase())!;
        this.authService.setClient(this.selectedClient.clientID!);
        this.getQuestionSetTable();
    }

    applyFilter(event: MatCheckboxChange | Event | null) {
        const filterValue = this.searchInput.toLowerCase();

        const pendingStatus = this.statusFilter.get('pending')?.value;
        const draftStatus = this.statusFilter.get('draft')?.value;
        const approvedStatus = this.statusFilter.get('approved')?.value;

        const paType = this.typeFilter.get('pa')?.value;
        const templateType = this.typeFilter.get('template')?.value;
        const firstAppealType = this.typeFilter.get('firstappeal')?.value;
        const secondAppealType = this.typeFilter.get('secondappeal')?.value;

        var fullDataset = new MatTableDataSource(this.questionSet);

        const filteredData = fullDataset.data.filter(data => {
            const meetsTypeCriteria =
                ((paType && data.questionSetTypeName === 'PA')) ||
                ((templateType && data.questionSetTypeName === 'Template')) ||
                ((firstAppealType && data.questionSetTypeName === 'Appeal 1')) ||
                ((secondAppealType && data.questionSetTypeName === 'Appeal 2'));

            const meetsStatusCriteria =
                ((pendingStatus && data.questionSetStatus === Status.Pending)) ||
                ((draftStatus && data.questionSetStatus === Status.Draft)) ||
                ((approvedStatus && data.questionSetStatus === Status.Approved));

            const includesSearch = filterValue === '' || (data.questionSetName?.toLowerCase().includes(filterValue) ?? false);

            return meetsTypeCriteria && meetsStatusCriteria && includesSearch;
        });
        // Create a new MatTableDataSource instance with the updated data
        const newDataSource = new MatTableDataSource(filteredData);

        // Assign the new data source to the existing table's data source
        this.dataSource = newDataSource;

        // If you want to trigger sorting manually
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortData(this.dataSource.data, this.sort);

        // Manually trigger the filter update
        this.dataSource._updateChangeSubscription();

        // Update paginator
        const paginator = this.dataSource.paginator;
        if (paginator) {
            paginator.length = filteredData.length;
            paginator.firstPage();
        }

        // Trigger change detection
        this.cdr.detectChanges();
    }

    onEffectiveDateCheckboxChange(event: MatCheckboxChange) {
        // Trigger filterPredicate when checkbox state changes
        this.applyFilter(event);
    }
    onFocus() {
        //console.log("infocus")
        //this.showFilters = true;
    }
    onBlur(e: any) {
        var y = e.srcElement.closest(".filter-section");
        //if (y == null) {
        //    this.showFilters = false;
        //}
    }
    onActionButtonClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
