<div mat-dialog-title style="width:400px; font-weight:700;">Select Application</div>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <!--<form id="testForm" class="form-group" (ngSubmit)="runTest()">-->
        <mat-radio-group aria-label="Select an application to test under"
                         [(ngModel)]="selectedChoice" >
            <mat-radio-button *ngFor="let a of applications" value="{{a.id}}">
                {{a.name}}
            </mat-radio-button>
        </mat-radio-group>
    <!--</form>-->
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" (click)="close()">
        Close
    </button>
    <button type="submit" form="testForm"
            mat-raised-button color="accent" (click)="runTest()">
        Test
    </button>
    <!--  -->
</mat-dialog-actions>