import { Component, Input } from '@angular/core';
import { QuestionSetControl } from '../../shared/question-set.control';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormControl } from '@angular/forms';
import { QuestionSetDashboardService } from '../question-set/question-set-dashboard.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-catalog-list',
  templateUrl: './edit-catalog-list.component.html',
  styleUrls: ['./edit-catalog-list.component.scss']
})
export class EditCatalogListComponent {
    @Input() questionSetControl!: QuestionSetControl;
    @Input() p: any;
    positionOptions: TooltipPosition[] = ['above'];
    position = new FormControl(this.positionOptions[0]);

    constructor(
        public questionSetDashboardService: QuestionSetDashboardService,
        private dialog: MatDialog
    ) { }
    addQuestion(internalOrder: number) {
        this.questionSetControl.addQuestionQuick(internalOrder);
        this.p.setInternalOrderOffsets();
    }
}
