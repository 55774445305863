<h2 mat-dialog-title>Add New Hierarchy</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <form id="addForm" [formGroup]="addForm" (ngSubmit)="save()">
        <section class="column-12" style="min-width:450px;">
            <label for="clientSelect">Client:</label>
            <p *ngIf="this.selectedClient != undefined">{{ this.selectedClient.clientName }}</p>
        </section>
        <section class="column-12">
            <mat-form-field class="column-4" appearance="outline">
                <mat-label>PCN</mat-label>
                <mat-select formControlName="pcnList" name="pcnList" (selectionChange)="onSelectPCN()" placeholder="Select a PCN" >
                    <mat-option *ngFor="let p of pcns; index as i; first as isFirst" [value]="p.pcnReferenceID">
                        {{p.pcnDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.selectedPCN != undefined" class="column-4" appearance="outline">
                <mat-label>Plan</mat-label>
                <mat-select formControlName="planList" name="planList" (selectionChange)="onSelectPlan()" placeholder="Select a Plan" >
                    <mat-option *ngFor="let p of plans; index as i; first as isFirst" [value]="p.planReferenceID">
                        {{p.planDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="this.selectedPlan != undefined" class="column-4" appearance="outline">
                <mat-label>Group</mat-label>
                <mat-select formControlName="groupList" name="groupList" (selectionChange)="onSelectGroup()" placeholder="Select a Group" >
                    <mat-option *ngFor="let g of groups; index as i; first as isFirst" [value]="g.benefitGroupId">
                        {{g.groupName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
        
        
        <p>Form Status: {{ addForm.status }}</p>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="addForm" [disabled]="!addForm.valid"
            mat-raised-button color="primary">Save
    </button>
</mat-dialog-actions>
