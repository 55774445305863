import { Component, Input } from '@angular/core';
import { ActionResult } from '../../../classes/questionset';

@Component({
  selector: 'app-decision-selection',
  templateUrl: './decision-selection.component.html',
  styleUrls: ['./decision-selection.component.scss']
})
export class DecisionSelectionComponent {
    @Input() p: any | undefined;
    @Input() actionContainer: any | undefined;
    @Input() action: ActionResult | undefined;
    @Input() isError: boolean = false;

    public decisionOptions:any = {
        "Approve": "Approval",
        "Deny": "Denial",
        "RFI": "Missing Info Request",
        "2Rph": "Needs Pharmacist Review",
        "Partial Approval": "Partial Approval",
    }
    

    setDecision(type: string) {
        if (type == '') {
            ///if (confirm("Clear current decision?")) {
                this.p.SetNextId(null);
                this.clearDecisions();
            ///}
        } else {
            let hasDecision: boolean = false;
            this.actionContainer.action.forEach((a: ActionResult) => {
                if (a.actionType == "Approve" || a.actionType == "Deny"
                    || a.actionType == "Partial Approval" || a.actionType == "RFI" || a.actionType == "2Rph") {
                    hasDecision = true;
                }
            });
            if (hasDecision) {
                let typeDescription = this.decisionOptions[type];

                //if (confirm("Clear current decision and set decsion to " + typeDescription + "?")) {
                    this.clearDecisions()
                    this.p.SetNextId("Final");
                    this.actionContainer.action.push(new ActionResult({
                        actionType: type,
                        routing: "decision",
                        name: "",
                        description: "",
                    }));
                    
                //}
            } else {
                this.p.SetNextId("Final");
                this.actionContainer.action.push(new ActionResult({
                    name: "",
                    description: "",
                    actionType: type
                }));
            }
        }
    }
    clearDecisions() {
        this.actionContainer.action = this.actionContainer.action.filter((a: ActionResult) => {
            return !(a.actionType == "Approve" || a.actionType == "Deny" || a.actionType == "Partial Approval" || a.actionType == "RFI" || a.actionType == "2Rph");
        });
    }
    
    getDecisionLabel(): string {
        if (this.action !== undefined) {
            return this.action.actionType!;
        }
        let hasApproval = false;
        let hasDenial = false;
        let hasPartial = false;
        let hasRFI = false;
        let has2Rph = false;
        this.actionContainer.action.forEach((a: ActionResult) => {
            if (a.actionType == "Approve") { hasApproval = true; }
            if (a.actionType == "Deny") { hasDenial = true; }
            if (a.actionType == "Partial Approval") { hasPartial = true; }
            if (a.actionType == "RFI") { hasRFI = true; }
            if (a.actionType == "2Rph") { has2Rph = true; }
        });
        
        if (hasDenial) { return "Deny" }
        if (hasRFI) { return "RFI" }
        if (hasPartial) { return "Partial Approval" }
        if (hasApproval) { return "Approve" }
        if (has2Rph) { return "2Rph" }
        return "";
    }
}
