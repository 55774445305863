
<h2 mat-dialog-title>Create New Decision Tree</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <form id="addDecisionTreeForm" [formGroup]="addDtForm" (ngSubmit)="save()">
        <section>
            <label for="clientSelect">Client:</label>
            <p *ngIf="this.currentFilteredClient != undefined">{{ this.currentFilteredClient.clientName }}</p>
        </section>
        <mat-form-field class="column-12" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput id="nameInput" formControlName="name" placeholder="Enter a name for the tree" #input>
        </mat-form-field>
        <!--<mat-form-field class="column-12" appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput id="descInput" formControlName="description" placeholder="Enter a description of the tree" #input>
        </mat-form-field>-->
        <!--<mat-form-field class="full-width">
            <mat-label>Leave a comment</mat-label>
            <textarea matInput placeholder="Any comments?" id="commentsInput" formControlName="comments"></textarea>
        </mat-form-field>-->
        <mat-form-field class="column-6" appearance="outline">
            <mat-label>Enter a date range for the Decision Tree</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="start">
                <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div formArrayName="questions">
            <div *ngFor="let l of questions.controls; let i = index">
                <div class="column-10">
                    <mat-form-field [formGroup]="toGroup(l)" class="full-width" appearance="outline">
                        <mat-label>Question Set {{i + 1}}:</mat-label>
                        <mat-select id="questionSetSelect" id="questionSetSelect" formControlName="questionSetID">
                            <mat-option *ngFor="let qs of questionset; index as i; first as isFirst" [value]="qs.questionSetId">
                                {{qs.questionSetName}} {{qs.majorVersion}}.{{qs.minorVersion}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button type="button" class="column-2" mat-icon-button (click)="deleteQuestionSet(i)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>
        <section>
            <button type="button" mat-raised-button color="primary" class="full-width" (click)="addQuestionSet()">
                Add A Question Set
            </button>
        </section>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="addDecisionTreeForm" [disabled]="!addDtForm.valid"
            mat-raised-button color="primary">Save</button>
</mat-dialog-actions>