import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActionResult, Override, OverrideCriteria } from '../../classes/questionset';
import { QuestionSetControl } from '../../shared/question-set.control';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { EditQuestionDecisionItemOverrideAuthPeriodModalComponent } from '../edit-question-decision-item-override-auth-period-modal/edit-question-decision-item-override-auth-period-modal.component';

@Component({
  selector: 'app-edit-question-decision-item-override',
  templateUrl: './edit-question-decision-item-override.component.html',
  styleUrls: ['./edit-question-decision-item-override.component.scss']
})
export class EditQuestionDecisionItemOverrideComponent {
    @Input() questionSetControl!: QuestionSetControl;
    @Input() actionResult!: ActionResult;
    @Input() supportedLanguages!: any[];
    newOverride: Override = new Override({});

    overrideCriteria: string[] = [
        "Number of Refills",
        "Max Days Supply",
        "Max Quantity Displensed",
        "Min Days Supply",
        "Threshhold"
    ];

    public providerLanguage: any = {};
    public patientLanguage: any = {};
    public pharmacyLanguage: any = {};

    possibleAuthorizationPeriods: { label: string, value: string }[] = [
        { label: '7 days', value: '7 days' },
        { label: '12 weeks', value: '12 weeks' },
        { label: '1 month', value: '1 month' },
        { label: 'End of current year', value: 'End of ' + this.getCurrentYear() }
    ];

    selectedCustomPeriod: { value: string, unit: string } = { value: '', unit: '' };


    constructor(
        public dialog: MatDialog,
        public questionSetService: QuestionSetService
    ) {
        
        
    }
    ngOnInit() {
        this.overrideCriteria.forEach(c => {
            var ia = this.actionResult.overrideCriteria.filter(f => f.title == c)
            if (ia.length == 0) {
                var oc = new OverrideCriteria({ title: c, value: "" });
                this.actionResult.overrideCriteria.push(oc);
            }
        });
    }
    addNewOverride() {
        this.actionResult.overrides.push({ ...this.newOverride });
        this.newOverride = new Override({});
    }

    deleteOverride(index: number) {
        this.actionResult.overrides.splice(index, 1);
    }

    getCurrentYear() {
        return new Date().getFullYear();
    }

    openCustomPeriodModal(index: number) {
        const dialogRef = this.dialog.open(EditQuestionDecisionItemOverrideAuthPeriodModalComponent, {
            width: '300px',
            data: { overrideIndex: index, customPeriod: this.selectedCustomPeriod }, 
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.actionResult.overrides[index].authorizationPeriod = result.value + ' ' + result.unit;
            }
        });
    }
}
