export class PCNSimple {
    id: number | undefined;
    desc: string | undefined;
}

export class PCN {
    pcnReferenceID: number | undefined;
    pcn: string | undefined;
    pcnDescription: string | undefined;
}


//"pcnReferenceID": 0,
//    "tenantID": 0,
//        "pcn": "string",
//            "pcnDescription": "string",
//                "clientID": "string",
//                    "createDate": "2023-04-03T17:32:19.223Z",
//                        "createUser": 0,
//                            "updateDate": "2023-04-03T17:32:19.223Z",
//                                "updateUser": 0,
//                                    "isDeleted": true,
//                                        "deletionDate": "2023-04-03T17:32:19.223Z"
