<form [formGroup]="medicareForm">
    <mat-button-toggle-group appearance="standard" formControlName="part" (click)="setMedicare()">
        <mat-button-toggle value="">
            Any
        </mat-button-toggle>
        <mat-button-toggle value="Part D">
            Part D
        </mat-button-toggle>
        <mat-button-toggle value="Part B">
            Part B
        </mat-button-toggle>
    </mat-button-toggle-group>
</form>