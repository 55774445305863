import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { AnswerChoiceCommonType, QuestionLanguage } from '../../classes/questionset';

@Component({
  selector: 'app-edit-question-decision-justification-modal',
  templateUrl: './edit-question-decision-justification-modal.component.html',
  styleUrls: ['./edit-question-decision-justification-modal.component.scss']
})
export class EditQuestionDecisionJustificationModalComponent {
    @Input() questionSetControl!: any;
    @Input() question!: any;
    @Input() action!: any;
    @Input() answerChoice: AnswerChoiceCommonType | undefined;
    @Input() comparison: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<EditQuestionDecisionJustificationModalComponent>,
        private dialog: MatDialog,
    ) {
        if (data !== undefined) {
            this.questionSetControl = data.questionSetControl;
            this.question           = data.question          ;
            this.action             = data.action            ;
            this.answerChoice       = data.answerChoice      ;
            this.comparison = data.comparison;
        }
    }
    close() {
        this.dialogRef.close("0");
    }
}

