<h2 mat-dialog-title>Questions Catalog</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-divider style="width:800px;"></mat-divider>
<mat-dialog-content>
    <div class="column-11">
        Questions
    </div>
    <div class="column-1">
        Version
    </div>
    <div *ngFor="let qst of questionSet">
        <div class="column-11">
            <input type="checkbox" name="catalogSelectionItem" value="{{qst.questionSetId}}" />
            {{qst.questionSetName}}
        </div>
        <div class="column-1">
            {{qst.majorVersion}}
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="button"
            mat-raised-button color="primary" (click)="add()">
        Add
    </button>
</mat-dialog-actions>