<div style="display: flex; gap: 10px;">
    <section>
        <mat-chip-listbox>
            <mat-chip-option [selected]="noDrug"
                             (click)="setNoDrug()"
                             [selectable]="true">
                No Drug
            </mat-chip-option>
        </mat-chip-listbox>
    </section>
    <section *ngIf="!noDrug">
        <mat-chip-listbox aria-label="Qualifier Selection" multiple [(ngModel)]="drugResultFilter">
            <mat-chip-option color="accent" [value]="DrugType.GPI">GPI</mat-chip-option>
            <mat-chip-option color="accent" [value]="DrugType.NDC">NDC</mat-chip-option>
        </mat-chip-listbox>
    </section>
</div>
<section *ngIf="!noDrug">
    <div class="column-12">
        <mat-form-field appearance="outline" class="full-width"  color="accent">
            <input #searchInput type="text" matInput placeholder="Search by GPI, NDC or Drug name" [(ngModel)]="drugSearchTerm" [matAutocomplete]="auto" (input)="openAutocomplete()" [disabled]="noDrug" #autocompleteTrigger>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="displayDrugDescription">
                <mat-option *ngFor="let drug of solrDrugs" [value]="drug">
                    <span *ngIf="drug.type == DrugType.GPI">
                        {{ drug.desc }} -
                        <small>  GPI: {{drug.gpi}}</small>
                    </span>
                    <span *ngIf="drug.type == DrugType.NDC">
                        {{ drug.desc }} -
                        <small>  NDC: {{drug.ndc}}</small>
                    </span>

                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</section>
<section *ngIf="selectedDrugs.length > 0">
    <table mat-table [dataSource]="dataSource" class="drug-list-table">
        <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->
        <!-- Returned GPI Column -->
        <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef> Drug Name </th>
            <td mat-cell *matCellDef="let r"> {{r.drugDisplay.drugDesc}} </td>
        </ng-container>
        <ng-container matColumnDef="gpi">
            <th mat-header-cell *matHeaderCellDef> Drug ID </th>
            <td mat-cell *matCellDef="let r">
                <span *ngIf="r.drugDisplay.type == DrugType.GPI">{{r.drugDisplay.drugDisplayGPI}} </span>
                <span *ngIf="r.drugDisplay.type == DrugType.NDC">{{r.drugDisplay.drugNDC}}</span>
            </td>
        </ng-container>
        <!-- Description Column -->
        <!-- Selected GPI Column -->
        <ng-container matColumnDef="gpiSelection">
            <th mat-header-cell *matHeaderCellDef> GPI Level </th>
            <td mat-cell *matCellDef="let r">
                <span *ngIf="r.drugDisplay.type == DrugType.GPI">
                    <!--<div class="settings-hide-in-edit" (click)="setInEditMode($event)">
                        {{r.drugIDQualifier}}
                    </div>
                    <div class="settings-show-in-edit">-->
                        <mat-form-field  color="accent">
                            <!--appearance="outline"<mat-label>Drug Qualifier</mat-label>-->
                            <mat-select id="drugQualifierInput" [(value)]="r.drugIDQualifier" (selectionChange)="onChangeGPI(r.drugID, $event.value)"
                                        >
                                <mat-option value="GPI-2">2</mat-option>
                                <mat-option value="GPI-4" [disabled]="r.drugDisplay.drugGPI.length < 4">4</mat-option>
                                <mat-option value="GPI-6" [disabled]="r.drugDisplay.drugGPI.length < 6">6</mat-option>
                                <mat-option value="GPI-8" [disabled]="r.drugDisplay.drugGPI.length < 8">8</mat-option>
                                <mat-option value="GPI-10" [disabled]="r.drugDisplay.drugGPI.length < 10">10</mat-option>
                                <mat-option value="GPI-12" [disabled]="r.drugDisplay.drugGPI.length < 12">12</mat-option>
                                <mat-option value="GPI-14" [disabled]="r.drugDisplay.drugGPI.length < 14">14</mat-option>
                            </mat-select>
                        </mat-form-field>
                    <!--</div>-->
                </span>
                <span *ngIf="r.drugDisplay.type == DrugType.NDC">
                    NDC
                </span>
            </td>
        </ng-container>


        <ng-container matColumnDef="mony">
            <th mat-header-cell *matHeaderCellDef> Multi-Source Code </th>
            <td mat-cell *matCellDef="let r">
                <span *ngIf="r.drugDisplay.type == DrugType.GPI">
                    <!--<div class="settings-hide-in-edit" (click)="setInEditMode($event)">
                        {{r.multiSourceCodes.length > 0 ? r.multiSourceCodes : "M, O, N, Y"}}
                    </div>
                    <div class="settings-show-in-edit">-->
                        <mat-form-field color="accent">
                            <mat-select multiple panelClass="fit-content"
                                        placeholder="M, O, N, Y"
                                        [(value)]="r.multiSourceCodes">
                                <mat-option value="M" class="ms-m-desc">M</mat-option>
                                <mat-option value="O" class="ms-o-desc">O</mat-option>
                                <mat-option value="N" class="ms-n-desc">N</mat-option>
                                <mat-option value="Y" class="ms-y-desc">Y</mat-option>
                            </mat-select>
                        </mat-form-field>
                    <!--</div>-->
                </span>
            </td>
        </ng-container>
        <ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Effective Date </th>
            <td mat-cell *matCellDef="let r">
                <!--<div class="settings-hide-in-edit" (click)="setInEditMode($event)">
                    {{r.startDate | date:'M/d/yyyy'}}
                </div>
                <div class="settings-show-in-edit">-->
                    <mat-form-field color="accent">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="r.startDate"
                               (dateChange)="onChangeStartDate(r.drugID, $event)"
                              >
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker panelClass="pickey-date" ></mat-datepicker>
                    </mat-form-field>
                <!--</div>-->
            </td>
        </ng-container>
        <ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
            <td mat-cell *matCellDef="let r">
                <!--<div class="settings-hide-in-edit" (click)="setInEditMode($event)">
                    {{r.endDate | date:'M/d/yyyy'}}
                </div>
                <div class="settings-show-in-edit">-->
                    <mat-form-field color="accent">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="r.endDate"
                               (dateChange)="onChangeEndDate(r.drugID, $event)" >
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker panelClass="pickey-date" #picker></mat-datepicker>
                    </mat-form-field>
                <!--</div>-->
            </td>
        </ng-container>

        <!-- Deletion Column -->
        <ng-container matColumnDef="remove" >
            <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
            <td mat-cell *matCellDef="let r">
                <span class="material-icons clickable" (click)="removeDrug(r)">delete</span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>
    <!--<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>-->
</section>

