

<div *ngIf="questionSetControl.GetQuestionSet() !== undefined"
     [class.catalogQuestion]="questionSetControl.questionSetTypeId != 0">
    <div [id]="questionSetControl.getLabel()" class="questionSetHeader" [class.catalogQuestion]="questionSetControl.questionSetTypeId != 0">
        <div style="display: flex; background-color: #800088; color: white; justify-content: left; align-items: center; margin-bottom:10px;">
            <button mat-icon-button (click)="moveSet('up')"><mat-icon>arrow_upward</mat-icon></button>
            <button mat-icon-button (click)="moveSet('down')"><mat-icon>arrow_downward</mat-icon></button>
            <div class="label-set" style="display: inline-flex; justify-content: center; align-items: center; margin-left: 5px;">
                Section {{questionSetControl.initialOrder}} of {{questionSetRoutingList!.length}}
            </div>
            <div *ngIf="questionSetControl.questionSetTypeId != 0"
                 style="background-color: #FFA9FF; padding: 2px 8px; margin-left: 5px; display: flex; justify-content: center; align-items: center; border-radius: 4px; color: black; height:24px;">
                <span #tooltip="matTooltip"
                      matTooltip="This question set is attached to the original. Any changes made to the original copy will be automatically reflected in the set."
                      [matTooltipPosition]="position.value!"
                      matTooltipHideDelay="1000">
                    <mat-icon>link</mat-icon>
                </span>
                Attached
            </div>

            <!--//<span *ngIf="row.ties == 0" class="material-icons tie-alert" style="font-size: 1.5em;"


                  [matTooltipPosition]="position.value!"
                  matTooltipHideDelay="1000">
                priority_high
            </span>-->
        </div>
        <!--{{questionSetControl.initialOrder}}-->
        <div style="padding:5px 10px;">
            <div *ngIf="questionSetControl.GetQuestionSet()!.questions.length < 1" class="i-am-error-alert">
                <mat-icon>info</mat-icon> &nbsp;
                You need to have at least one question to create a decision tree.
            </div>
            <div style="display:flex">
                <div class="leftHeader">
                    <div *ngIf="questionSetControl.questionSetTypeId == 0">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>Description</mat-label>
                            <input matInput id="descInput" placeholder=""
                                   [(ngModel)]="questionSetControl.GetQuestionSet()!.questionSetHeader!.title"
                                   (change)="setQuestionSetRouting()"
                                   #input>
                        </mat-form-field>
                    </div>
                    <div *ngIf="questionSetControl.questionSetTypeId != 0">
                        {{questionSetControl.getTitle()}}
                    </div>
                </div>
                <div class="rightHeader">
                    <button mat-mini-fab color="accent" class="show-set-questions-button" *ngIf="!questionSetControl.showQuestions" (click)="toggleShowQuestions(true)"><mat-icon>arrow_drop_up</mat-icon></button>
                    <button mat-mini-fab color="accent" class="show-set-questions-button" *ngIf="questionSetControl.showQuestions" (click)="toggleShowQuestions(false)"><mat-icon>arrow_drop_down</mat-icon></button>
                </div>
            </div>

            <div class="inactiveForCatalog" style="display:inline" *ngFor="let a of questionSetControl.applicationStatusList">
                <mat-chip-option color="accent" [selected]="a.enabled"
                                 (click)="a.enabled=!a.enabled">
                    {{a.name}}
                </mat-chip-option> &nbsp;

                <!--[(ngModel)]="a.enabled" does not work, listbox seems useless -->
                <!--</mat-chip>-->

            </div>

            <mat-divider></mat-divider>

            <div class="full-width overrideOpacity" style="display:flex;align-items:center;justify-content:flex-end;">
                <span *ngIf="questionSetControl.questionSetTypeId != 0" class="lockedCatalogNotification">
                    <button mat-button color="accent" (click)="unlock()">
                        Detach Question Section
                    </button>
                </span>
                <span #tooltip="matTooltip"
                      matTooltip="Copy Question Set."
                      [matTooltipPosition]="position.value!"
                      matTooltipHideDelay="300"
                      style="cursor:pointer;">
                    <mat-icon (click)="copyQuestionSet()" title="Copy Question Set." color="accent">file_copy</mat-icon>
                </span>
                <span #tooltip="matTooltip"
                      matTooltip="Remove Question Set."
                      [matTooltipPosition]="position.value!"
                      matTooltipHideDelay="300"
                      style="cursor:pointer;">

                    <mat-icon (click)="removeQuestionSet()" color="warn">delete</mat-icon>
                </span>

            </div>
        </div>
    </div>
    <div [hidden]="!questionSetControl.showQuestions">
        <div class="inactiveForCatalog" [class.catalogQuestion]="questionSetControl.questionSetTypeId != 0">
            <div style="width:100%; text-align:center;" *ngIf="(questionSetControl.GetQuestions().length == 0) && (questionSetControl.questionSetTypeId == 0)">
                <button mat-mini-fab color="accent" (click)="addQuestion(0)">
                    <span class="material-icons">add</span>
                </button>
            </div>
            <!-- initial handle-->
            <div style="width:100%; text-align:center;">
                <app-hover-menu style="display:inline-block;"
                                *ngIf="questionSetControl.questionSetTypeId == 0 && questionSetControl.GetQuestions().length > 0">
                    <div trigger>
                        <section>
                            <button mat-mini-fab color="accent" (click)="addQuestion(0)"
                                    style="pointer-events:auto; opacity:unset">
                                <span class="material-icons">add</span>
                            </button>
                        </section>
                    </div>
                    <div content>
                        <section>
                            <span mat-menu-item>
                                <button mat-button (click)="addQuestion(0)">
                                    <span class="material-icons" style="color:#9c29a1">add</span> Add Question
                                </button>
                            </span>
                        </section>
                        <section>
                            <span mat-menu-item>
                                <button mat-button (click)="splitQuestionSet(0)">
                                    <span class="material-icons" style="color:#9c29a1">add</span> Add Section
                                </button>
                            </span>
                        </section>
                    </div>
                </app-hover-menu>
            </div>
            <!-- initial handle-->

            <div *ngFor="let q of questionSetControl.GetQuestions() | sortByInternalOrder">
                <div class="question" tabindex="0">
                    <app-edit-question-list-item [question]="q" [p]="this"
                                                 [questionSetControl]="questionSetControl"
                                                 [questionSetRoutingList]="questionSetRoutingList">
                    </app-edit-question-list-item>
                </div>
                <div *ngIf="p.decisionTree != null && p.decisionTree.decisionTreeStatus != null && p.decisionTree.decisionTreeStatus == 1" style="width:100%; text-align:center;">
                    <div>
                        <app-hover-menu style="display:inline-block;"
                                        *ngIf="questionSetControl.questionSetTypeId == 0 || q.internalOrder == questionSetControl.GetQuestions().length">
                            <div trigger>
                                <section *ngIf="questionSetControl.questionSetTypeId == 0">
                                    <button mat-mini-fab color="accent" (click)="addQuestion(q.internalOrder)"
                                            style="pointer-events:auto; opacity:unset">
                                        <span class="material-icons">add</span>
                                    </button>
                                </section>
                                <section *ngIf="questionSetControl.questionSetTypeId != 0">
                                    <button mat-mini-fab color="accent"
                                            style="pointer-events:auto; opacity:unset">
                                        <span class="material-icons">add</span>
                                    </button>
                                </section>
                            </div>
                            <div content>
                                <section *ngIf="questionSetControl.questionSetTypeId == 0">
                                    <span mat-menu-item>
                                        <button mat-button (click)="addQuestion(q.internalOrder)">
                                            <span class="material-icons" style="color:#9c29a1">add</span> Add Question
                                        </button>
                                    </span>
                                </section>
                                <section *ngIf="q.internalOrder < questionSetControl.GetQuestions().length && questionSetControl.questionSetTypeId == 0">
                                    <span mat-menu-item>
                                        <button mat-button (click)="splitQuestionSet(q.internalOrder)">
                                            <span class="material-icons" style="color:#9c29a1">add</span> Add Section
                                        </button>
                                    </span>
                                </section>
                                <section *ngIf="q.internalOrder == questionSetControl.GetQuestions().length">
                                    <span mat-menu-item>
                                        <button mat-button (click)="addNewQuestionSet()">
                                            <span class="material-icons" style="color:#9c29a1">add</span> Add Section
                                        </button>
                                    </span>
                                </section>
                            </div>
                        </app-hover-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
