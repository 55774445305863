import { Client } from "./client";
import { ApplicationStatus } from "./questionset";

export class User {
    id: number | undefined;
    oauthUserName: string | undefined;
    userName: string | undefined;
    oauthToken: string | undefined;
    oauthID: number | undefined;
    /*applicationUserID: number | undefined;*/
    clients: Client[] | undefined;
    applications: ApplicationStatus[] | undefined;
    lastSelectedClientID: string | undefined;
}
