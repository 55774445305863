import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Case } from '../../classes/case';
import { Client } from '../../classes/client';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';
import { PCN } from '../../classes/pcn';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { CaseService } from '../../shared/services/case.service';
import { ClientService } from '../../shared/services/client.service';
import { JustificationsService } from '../justifications.service';

@Component({
    selector: 'justification-add-modal',
    templateUrl: './justification-add-modal.component.html',
    styleUrls: ['./justification-add-modal.component.scss']
})
export class JustificationAddModalComponent {
    title = 'Create Justification';
    currentFilteredClient!: Client;
    isCreateMode = true;
    justificationTemplateID: number | undefined;
    clients: Client[] = [];
    pcns: PCN[] = [];
    existingJustification?: Justification;
    justification: JustificationSettings = new JustificationSettings;
    @Output() event: EventEmitter<any> = new EventEmitter();
    user!: User;
    justificationAddForm: FormGroup;
    casesForm: FormGroup;
    levelForm: FormGroup;
    decisionJustificationsForm: FormGroup;
    cases: Case[] = [];
    dynamicCaseControls: FormControl[] = [];
    allCasesChecked = false;
    isViewMode: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<JustificationAddModalComponent>,
        private authService: AuthService,
        private clientService: ClientService,
        private caseService: CaseService,
        private justificationService: JustificationsService,
        private fb: FormBuilder,
    ) {
        this.justificationTemplateID = data.justificationTemplateID;
        this.currentFilteredClient = data.currentFilteredClient;
        this.user = this.authService.getUserLoginInfo();
        
        this.justificationAddForm = this.fb.group({
            desc: ['', Validators.required],
            decision: ['', Validators.required],
            decisionReason: [{ value: '', disabled: true }], // Initially disabled
            client: ['', Validators.required],
            pcn: [{ value: '', disabled: true }],
        });

        this.casesForm = this.fb.group({
            allCases: new FormControl(false) // Create a standalone control for 'all'
        });

        this.levelForm = this.fb.group({
            allLevels: [false],
            firstDeterminationChecked: [false],
            firstAppealChecked: [false],
            secondAppealChecked: [false],
            thirdAppealChecked: [false],
        });

        this.decisionJustificationsForm = this.fb.group({
            prescriberEng: [''],
            pharmacyEng: [''],
            beneficiaryEng: [''],
            prescriberSpa: [''],
            pharmacySpa: [''],
            beneficiarySpa: [''],
        });

        this.populateClients();
        this.fetchCases();

        if (this.currentFilteredClient) {
            this.justificationAddForm.get('client')?.setValue(this.currentFilteredClient.clientID);
            // Update the pcn list with the available pcns for that client
            this.updateClient(this.currentFilteredClient.clientID!);
        }

        if (this.justificationTemplateID) {
            this.isCreateMode = false;
            this.isViewMode = true;
            this.title = 'View Justification';
            this.getJustificationTemplate();
            // Disable the form fields here if needed
            this.justificationAddForm.disable();
            this.casesForm.disable();
            this.levelForm.disable();
            this.decisionJustificationsForm.disable();
            
          
        } else {
            this.currentFilteredClient = data.currentFilteredClient;
            this.justification.ClientID = this.currentFilteredClient.clientID;
            console.log('justification clients', this.clients);
        }
    }

    toggleEditMode() {
        this.isViewMode = !this.isViewMode;
        if (this.isViewMode) {
            this.justificationAddForm.disable();
            this.casesForm.disable();
            this.setDynamicCaseControlsState();
            this.levelForm.disable();
            this.decisionJustificationsForm.disable();
        } else {
            this.title = 'Edit Justification';
            this.justificationAddForm.enable();
            this.casesForm.enable();
            this.setDynamicCaseControlsState();
            this.levelForm.enable();
            this.decisionJustificationsForm.enable();
        }
    }

    getJustificationTemplate() {
        this.justificationService.getJustificationByTemplateID(this.justificationTemplateID!).subscribe(resp => {
            if (resp.body) {
                this.justification = this.justificationService.mapJustificationSettingsFromResponse(resp.body);
                this.setFormFromSettings(this.justification, this.justificationAddForm, this.casesForm, this.levelForm, this.decisionJustificationsForm);
                //this.justifications = this.justificationService.mapJustificationsFromApiResponse(resp);
                ////console.log(this.decisionTree);
                ////console.log(this.decisionTree);
                //if (this.justifications.length >= 0) {
                //    this.dataSource = new MatTableDataSource(this.justifications);
                //    this.dataSource.paginator = this.paginator;
                //    this.dataSource.sort = this.sort;
                //    console.log(this.justifications);
                //    //this.populateCase();
                //    //this.applyFilter(null);

                //}
            }
        });
    }

    populateClients() {
        this.clients = this.user.clients!;
        if (this.currentFilteredClient) {
            this.justificationAddForm.get('client')!.patchValue(this.currentFilteredClient.clientID);
            this.populatePCNs();
        }
    }

    updateClient(clientId: string) {
        this.currentFilteredClient = this.clients!.find(x => x.clientID?.toLowerCase() === clientId!.toLowerCase())!;
        this.populatePCNs();

        // Enable/disable the "Line of Business" dropdown based on the selected client
        const pcnControl = this.justificationAddForm.get('pcn');
        if (this.currentFilteredClient) {
            pcnControl!.enable();
        } else {
            pcnControl!.disable();
            pcnControl!.setValue(''); // Clear the selected PCN
        }
    }

    populatePCNs() {
        this.pcns = [];
        this.clientService.getPCNByClient(this.currentFilteredClient?.clientID!).subscribe(resp => {
            if (resp.body) {
                const pcns = this.clientService.mapPCNResponse(resp, false);
                this.pcns = [...this.pcns, ...pcns];
                //console.log(this.pcns);
                if (this.justification.pcnReferenceID!) {
                    this.justificationAddForm.get('pcn')!.patchValue(this.justification.pcnReferenceID);
                }
            }
        });
    }

    updatePCN() {
        // Handle PCN selection change here
    }

    onDecisionChange() {
        const decisionControl = this.justificationAddForm.get('decision');
        const decisionReasonControl = this.justificationAddForm.get('decisionReason');

        if (decisionControl!.value) {
            // Enable the "Decision Reason" dropdown if a decision is selected
            decisionReasonControl!.enable();
        } else {
            // Disable and reset the "Decision Reason" dropdown if no decision is selected
            decisionReasonControl!.disable();
            decisionReasonControl!.setValue('');
        }
    }

    fetchCases() {
        this.caseService.getCases().subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.cases = this.caseService.mapCases(resp);
                //console.log(this.cases);

                // Dynamically create form controls for each case in the casesForm
                this.cases.forEach((caseItem) => {
                    const control = new FormControl(false);
                    this.casesForm.addControl(`case-${caseItem.pcnCaseTypeID}`, control);
                    this.dynamicCaseControls.push(control);
                });

                // Disable or enable the controls based on isEditMode
                this.setDynamicCaseControlsState();
            }
        });
    }

    setFormFromSettings(settings: JustificationSettings, addForm: FormGroup, caseForm: FormGroup, levelForm: FormGroup, justificationForm: FormGroup) {
        this.setJustificationAddFormValues(settings, addForm);
        this.setCasesFormValues(settings, caseForm)
        this.setLevelFormValues(settings, levelForm);
        this.setDecisionJustificationsFormValues(settings, justificationForm);
    }

    setJustificationAddFormValues(settings: JustificationSettings, form: FormGroup) {
        form.patchValue({
            desc: settings.JustificationDescription,
            decision: settings.JustificationDecision,
            decisionReason: settings.JustificationDecisionReason,
            client: settings.ClientID,
            pcn: settings.pcnReferenceID,
        });

        // Find the selected PCN from the settings object and set it as the value
        const selectedPCN = settings.pcnReferenceID;

        // Set the selected PCN value in the form
        form.get('pcn')!.patchValue(selectedPCN);
    }

    setCasesFormValues(settings: JustificationSettings, form: FormGroup) {
        // Iterate through each case type and set the corresponding form control
        settings.CaseType.forEach((caseType) => {
            const controlName = `case-${caseType.CaseTypeID}`;
            console.log('controlName', controlName)
            if (form.get(controlName)) {
                form.get(controlName)!.setValue(true);
            }
        });

        // Check if all case type checkboxes are selected
        // Remove allCases form control
        let allCasesSelected = true;
        for (const field in this.casesForm.controls) {
            console.log(field, this.casesForm.controls[field].value)
            if (this.casesForm.controls[field].value === false && field != "allCases") {
                allCasesSelected = false;
                break;
            }
        }

        // Set the "All" checkbox based on the result
        form.get('allCases')!.setValue(allCasesSelected);
    }

    // Function to set values in levelForm
    setLevelFormValues(settings: JustificationSettings, form: FormGroup) {
        form.patchValue({
            firstDeterminationChecked: settings.DeterminationLevel.some((level) => level.DeterminationLevelID === 1),
            firstAppealChecked: settings.DeterminationLevel.some((level) => level.DeterminationLevelID === 2),
            secondAppealChecked: settings.DeterminationLevel.some((level) => level.DeterminationLevelID === 3),
            thirdAppealChecked: settings.DeterminationLevel.some((level) => level.DeterminationLevelID === 4),
            allLevels: settings.DeterminationLevel.length === 4,
        });
    }

    setDecisionJustificationsFormValues(settings: JustificationSettings, form: FormGroup) {
        // Parse the JSON string from JustificationReasonJson
        const justificationReasons = JSON.parse(settings.JustificationReasonJson || '{}');

        // Set the values in the form controls
        form.patchValue({
            prescriberEng: justificationReasons.prescriberEng || '',
            pharmacyEng: justificationReasons.pharmacyEng || '',
            beneficiaryEng: justificationReasons.beneficiaryEng || '',
            prescriberSpa: justificationReasons.prescriberSpa || '',
            pharmacySpa: justificationReasons.pharmacySpa || '',
            beneficiarySpa: justificationReasons.beneficiarySpa || '',
        });
    }

    setDynamicCaseControlsState() {
        if (!this.isViewMode) {
            this.dynamicCaseControls.forEach((control) => {
                control.enable();
            });
        } else {
            this.dynamicCaseControls.forEach((control) => {
                control.disable();
            });
        }
    }

    // Function to handle the "All" checkbox for cases
    toggleAllCases() {
        console.log('all Cases');
        this.allCasesChecked = !this.allCasesChecked;
        Object.keys(this.casesForm.controls).forEach((controlName) => {
            this.casesForm.get(controlName)?.setValue(this.allCasesChecked);
        });
    }

    toggleAllLevels() {
        const isChecked = this.levelForm.get('allLevels')?.value;
        this.levelForm.patchValue({
            firstDeterminationChecked: isChecked,
            firstAppealChecked: isChecked,
            secondAppealChecked: isChecked,
            thirdAppealChecked: isChecked,
        });
    }

    applyToAll(language: 'eng' | 'spa') {
        const sourceControlName = `prescriber${language.charAt(0).toUpperCase() + language.slice(1)}`;
        const targetControls = ['pharmacy', 'beneficiary'];

        // Get the source control value
        const sourceValue = this.decisionJustificationsForm.get(sourceControlName)?.value;

        // Update target controls with the source value
        targetControls.forEach(control => {
            const targetControlName = `${control}${language.charAt(0).toUpperCase() + language.slice(1)}`;
            this.decisionJustificationsForm.get(targetControlName)?.setValue(sourceValue);
        });
    }

    sameAsPrescriber(language: 'eng' | 'spa', targetControlName: string) {
        const prescriberControlName = `prescriber${language.charAt(0).toUpperCase() + language.slice(1)}`;

        // Get the value from the prescriber input
        const prescriberValue = this.decisionJustificationsForm.get(prescriberControlName)?.value;

        // Set the value in the specified target control
        this.decisionJustificationsForm.get(targetControlName)?.setValue(prescriberValue);
    }

    close() {
        this.dialogRef.close("0");
    }


    update() {
        // Handle form submission here
        console.log("Justification ID " + this.justificationTemplateID)
        //debugger;
        if (this.isFormsValid()) {
            //Populate the settings object
            const justificationSettings: JustificationSettings = this.populateJustificationSettings();

            // Call the API method and pass the values from justificationSettings
            this.justificationService.updateJustification(
                this.justificationTemplateID!,
                justificationSettings.JustificationDescription!,
                justificationSettings.JustificationDecision!,
                justificationSettings.JustificationDecisionReason,
                justificationSettings.ClientID!,
                justificationSettings.pcnReferenceID,
                justificationSettings.JustificationReasonJson,
                justificationSettings.OverridesJson,
                justificationSettings.OverrideCriteria,
                this.user.id!
            ).subscribe(
                (response) => {
                    // Handle the response from the API
                    console.log('API response:', response);
                    if (response.body != null && response.body.result != -1) {
                        //Delete any justification case types that don't exist on the current tree
                        this.deleteCaseTypesForJustification(this.user.id!);
                        //Save any justification case types that do exist on the form
                        this.saveJustificationForSelectedCaseTypes(response.body.result!, this.user.id!);
                        
                        //Delete any justification determination levels that don't exist on the current tree
                        this.deleteDeterminationLevelsForJustification(this.user.id!)
                        //Save any justification determination levels that doe exist on the form
                        this.saveJustificationForSelectedLevels(response.body.result!, this.user.id!);
                    }

                    // Close the dialog or perform any other necessary actions
                    this.dialogRef.close("0");
                },
                (error) => {
                    // Handle API error
                    console.error('API error:', error);
                    // You can also display an error message to the user
                }
            );
        }
    }

    save() {
        // Handle form submission here
        if (this.isFormsValid()) {
            //Populate the settings object
            this.justification = this.populateJustificationSettings();
            const justificationSettings: JustificationSettings = this.populateJustificationSettings();

            // Call the API method and pass the values from justificationSettings
            this.justificationService.addJustification(
                justificationSettings.JustificationDescription!,
                justificationSettings.JustificationDecision!,
                justificationSettings.JustificationDecisionReason,
                justificationSettings.ClientID!,
                justificationSettings.pcnReferenceID,
                justificationSettings.JustificationReasonJson,
                justificationSettings.OverridesJson,
                justificationSettings.OverrideCriteria,
                this.user.id!
            ).subscribe(
                (response) => {
                    // Handle the response from the API
                    console.log('API response:', response);
                    if (response.body != null) {
                        const justificationID = response.body.result;
                        this.saveJustificationForSelectedCaseTypes(justificationID, this.user.id!);
                        this.saveJustificationForSelectedLevels(justificationID, this.user.id!);
                    }

                    // Close the dialog or perform any other necessary actions
                    this.dialogRef.close("0");
                },
                (error) => {
                    // Handle API error
                    console.error('API error:', error);
                    // You can also display an error message to the user
                }
            );
        }
    }

    deleteCaseTypesForJustification(applicationUserID: number) {
        const caseTypes = this.cases; // Assuming you have an array of case types

        caseTypes.forEach((caseType) => {
            const checkbox = this.casesForm.get(`case-${caseType.pcnCaseTypeID}`);

            if (checkbox!.value) {
                const id = this.justification.CaseType.find(
                    (ct) => ct.CaseTypeID === caseType.pcnCaseTypeID
                )?.JustificationTemplateToCaseTypeID;

                if (id) {
                    this.justificationService
                        .deleteJustificationFromCaseType(id, applicationUserID)
                        .subscribe(
                            (response) => {
                                console.log(`Delete justification for case type: ${id}`);
                            },
                            (error) => {
                                // Handle API error
                                console.error('API error:', error);
                                // You can also display an error message to the user
                            }
                        );
                }
            }
        });
    }


    saveJustificationForSelectedCaseTypes(justificationID: number, applicationUserID: number) {
        const selectedCaseTypeControls = Object.keys(this.casesForm.controls)
            .filter((controlName) => {
                return controlName !== 'allCases' && this.casesForm.controls[controlName].value === true;
            });

        selectedCaseTypeControls.forEach((controlName) => {
            const pcnCaseTypeID = parseInt(controlName.split('-')[1], 10);
            console.log(pcnCaseTypeID)
            // if (!this.caseExists(pcnCaseTypeID)) {
                this.justificationService
                    .saveJustificationToCaseType(justificationID, pcnCaseTypeID, applicationUserID)
                    .subscribe(
                        (response) => {
                            console.log(`Saved justification for case type ID: ${pcnCaseTypeID}`);
                        },
                        (error) => {
                            // Handle API error
                            console.error('API error:', error);
                            // You can also display an error message to the user
                        }
                    );
            // }
        });
    }

    deleteDeterminationLevelsForJustification(applicationUserID: number) {
        const determinationLevels = [
            { level: '1', checkbox: this.levelForm.get('firstDeterminationChecked') },
            { level: '2', checkbox: this.levelForm.get('firstAppealChecked') },
            { level: '3', checkbox: this.levelForm.get('secondAppealChecked') },
            { level: '4', checkbox: this.levelForm.get('thirdAppealChecked') },
        ];

        determinationLevels.forEach(({ level, checkbox }) => {
            if (checkbox!.value) {
                const id = this.justification.DeterminationLevel.find(
                    (lvl) => lvl.DeterminationLevel === level
                )?.JustificationTemplateToDeterminationLevelID;

                if (id) {
                    this.justificationService
                        .deleteJustificationFromDeterminationLevel(id, applicationUserID)
                        .subscribe(
                            (response) => {
                                console.log(`Delete justification for determination level: ${id}`);
                            },
                            (error) => {
                                // Handle API error
                                console.error('API error:', error);
                                // You can also display an error message to the user
                            }
                        );
                }
            }
        });
    }


    saveJustificationForSelectedLevels(justificationID: number, applicationUserID: number) {
        const selectedLevels = [];

        if (this.levelForm.get('firstDeterminationChecked')!.value) {
            // if (!this.levelExists(1)) {
                selectedLevels.push(1); // Value for "First Determination"
            // }
        }

        if (this.levelForm.get('firstAppealChecked')!.value) {
            // if (!this.levelExists(2)) {
                selectedLevels.push(2); // Value for "First Appeal"
            // }
        }

        if (this.levelForm.get('secondAppealChecked')!.value) {
            // if (!this.levelExists(3)) {
                selectedLevels.push(3); // Value for "Second Appeal"
            // }
        }

        if (this.levelForm.get('thirdAppealChecked')!.value) {
            // if (!this.levelExists(4)) {
                selectedLevels.push(4); // Value for "Third Appeal"
            // }
        }

        selectedLevels.forEach((level) => {
            this.justificationService
                .saveJustificationToDeterminationLevel(justificationID, level, applicationUserID)
                .subscribe(
                    (response) => {
                        console.log(`Saved justification for determination level: ${level}`);
                    },
                    (error) => {
                        // Handle API error
                        console.error('API error:', error);
                        // You can also display an error message to the user
                    }
                );
        });
    }

    levelExists(levelID: number): boolean {
        return this.justification.DeterminationLevel.some(
            (level) => level.DeterminationLevelID === levelID
        );
    }

    caseExists(caseTypeID: number): boolean {
        return this.justification.CaseType.some(
            (caseType) => caseType.CaseTypeID === caseTypeID
        );
    }

    populateJustificationSettings(): JustificationSettings {
        const justificationSettings: JustificationSettings = new JustificationSettings();

        // Populate the object properties
        justificationSettings.JustificationDescription = this.justificationAddForm.get('desc')?.value;
        justificationSettings.JustificationDecision = this.justificationAddForm.get('decision')?.value;
        justificationSettings.JustificationDecisionReason = this.justificationAddForm.get('decisionReason')?.value;
        justificationSettings.ClientID = this.currentFilteredClient.clientID;
        justificationSettings.pcnReferenceID = this.justificationAddForm.get('pcn')?.value || -1;
        if (justificationSettings.pcnReferenceID != -1) {
            justificationSettings.PCNDescription = this.pcns.find(p => p.pcnReferenceID === justificationSettings.pcnReferenceID)?.pcnDescription;
            justificationSettings.PCN = this.pcns.find(p => p.pcnReferenceID === justificationSettings.pcnReferenceID)?.pcn;
        } else {
            justificationSettings.PCNDescription = '';
            justificationSettings.PCN = '';
        }
        

        const justificationReasonJson = {
            prescriberEng: this.decisionJustificationsForm.get('prescriberEng')?.value,
            pharmacyEng: this.decisionJustificationsForm.get('pharmacyEng')?.value,
            beneficiaryEng: this.decisionJustificationsForm.get('beneficiaryEng')?.value,
            prescriberSpa: this.decisionJustificationsForm.get('prescriberSpa')?.value,
            pharmacySpa: this.decisionJustificationsForm.get('pharmacySpa')?.value,
            beneficiarySpa: this.decisionJustificationsForm.get('beneficiarySpa')?.value,
        };
        justificationSettings.JustificationReasonJson = JSON.stringify(justificationReasonJson);

        return justificationSettings;
    }

    isFormsValid(): boolean {
        const formsToCheck: FormGroup[] = [
            this.justificationAddForm,
            this.casesForm,
            this.levelForm,
            this.decisionJustificationsForm,
        ];

        // Check if all forms are valid
        return formsToCheck.every(form => form.valid);
    }
}
