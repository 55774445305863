<h2 mat-dialog-title style="width:400px;">{{title}}</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-dialog-content class="dialog-detail">
    <p *ngIf="dialogText != ''">{{dialogText}}</p>
    <!--{{errorList}}-->
    <ul>
        <li *ngFor="let e of errorList">
            {{e}}
        </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="close()" style="border-radius:24px;">
        {{submitButtonText}}
    </button>
</mat-dialog-actions>

