import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { every, map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OverrideCode } from '../../../classes/overrides';

/**
 * @title Chips Autocomplete
 */
@Component({
    selector: 'app-override-chips',
    templateUrl: './override-chips.component.html',
    styleUrls: ['./override-chips.component.scss']

})
export class OverrideChipsComponent {


    @Input() overrides: OverrideCode[] = [];//['Lemon'];
    @Input() allOverrides: OverrideCode[] = [];//['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    overrideCtrl = new FormControl('');
    filteredOverrides: Observable<OverrideCode[]>;
    @ViewChild(MatAutocompleteTrigger)
    autocompleteTrigger!: MatAutocompleteTrigger;
    @ViewChild('overrideInput')
    overrideInput!: ElementRef<HTMLInputElement>;

    announcer = inject(LiveAnnouncer);

    constructor() {
        this.filteredOverrides = this.overrideCtrl.valueChanges
            .pipe(
                startWith(''),
                map((override: string | null) => (
                    override ? this._filter(override) : this.allOverrides.slice())
                ),
                map(sorter => sorter.sort((a, b) => a.Reason.localeCompare(b.Reason))),
            );
    }
    ngOnInit() {
        this.overrideCtrl.setValue("");
    }


    add(event: MatChipInputEvent): void {
        const self = this;
        const value = (event.value || '').trim();
        // Add our override
        if (value) {
            var targetOverride = this.allOverrides.filter(f => f.Code == value)[0];
            this.overrides.push(targetOverride);
        }
        // Clear the input value
        //event.chipInput!.clear();
        this.overrideCtrl.setValue(this.overrideCtrl.value);
        //setTimeout(self.autocompleteTrigger.openPanel(),1);
    }

    remove(override: OverrideCode): void {
        const index = this.overrides.indexOf(override);

        if (index >= 0) {
            this.overrides.splice(index, 1);

            this.announcer.announce(`Removed ${override}`);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const self = this;
        var targetOverride = this.allOverrides.filter(f => f.Code == event.option.value)[0];
        this.overrides.push(targetOverride);
        //this.overrideInput.nativeElement.value = '';
        //this.overrideCtrl.setValue(null);
        this.overrideInput.nativeElement.value = this.overrideInput.nativeElement.value;
        this.overrideCtrl.setValue(this.overrideCtrl.value);
        
        setTimeout(function () { self.autocompleteTrigger.openPanel() }, 1);
    }

    private _filter(value: string): OverrideCode[] {
        console.log("_filter applied")
        const filterValue = this.overrideInput.nativeElement.value.toLowerCase();//value.toLowerCase();
        var selItems = this.overrides.map(a => a.Code);

        var unselected = this.allOverrides.filter(
            f => {
                return selItems.indexOf(f.Code) == -1;
            }
        );
        console.log(this.allOverrides.length + " > " + unselected.length);
        return unselected.filter(override =>
            override.Reason.toLowerCase().includes(filterValue)
            || override.Code.toLowerCase().includes(filterValue)

        );
    }
    
}