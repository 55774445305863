import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ActionResult, AnswerChoiceCommonType, NextQuestionCondition, Question, QuestionTypeDate, QuestionTypeFreeText, QuestionTypeNumeric, QuestionTypeSelect } from '../../../classes/questionset';
//import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RoutingModalComponent } from '../routing-modal/routing-modal.component';
import { QuestionSetControl, QuestionSetRouting } from '../../../shared/question-set.control';
import { CodedreferenceModalComponent } from '../../codedreference-modal/codedreference-modal.component';
import { ActionresultModalComponent } from '../actionresult-modal/actionresult-modal.component';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-template',
  templateUrl: './question-template.component.html',
  styleUrls: ['./question-template.component.scss']
})


export class QuestionTemplateComponent
    //implements OnChanges
{ // implements OnChanges no apparent usage
    @Input() question!: Question;
    @Input() questionSetControl!: QuestionSetControl;
    @Input() questionSetRoutingList?: QuestionSetRouting[];
    closeResult = '';
    constructor(
        private dialog: MatDialog
    ) {
    }
    ngOnInit(): void {
        //console.log(this.questionSetControl);
        //console.log(this.question);
    }
    GetNextQuestionLabel():string {
        let nextId = this.question.defaultNextQuestionID;
        if (nextId == "" || nextId == null) { return "Select Path"; }
        if (nextId == "End") {
            return "Next Question Set";
        }
        if (nextId == "Final") {
            return "End Decision Tree";

        } else {
            let label = "";
            var q = this.questionSetControl.GetQuestionByID(nextId);
            if (q != null) {
                var v: string;
                v = q.text;
                if (v.length > 25) {
                    v = v.substring(0, 22) + "...";
                }
                label = (q.internalOrder + this.questionSetControl.internalOrderOffset) + ": " + v;
            }
            return label;
        }
    }
    GetQuestionType() {
        var type = "";
        if (this.question.questionType instanceof QuestionTypeSelect) {
            type = "Select";
        } else if (this.question.questionType instanceof QuestionTypeFreeText) {
            type = "FreeText";
        } else if (this.question.questionType instanceof QuestionTypeDate) {
              type = "Date";
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            type = "Numeric";
        }
        return type;
    }
    openCommentText() {
        if (this.question.questionComment == "" || this.question.questionComment == undefined) {
            this.question.questionComment = "  ";
        }
    }
    RemoveQuestion() {
        this.questionSetControl.RemoveQuestionByID(this.question.id)
    }
    RemoveActionResult( ar:ActionResult ) {
        this.question.action = this.question.action.filter(f => { return ar != f });
    }
    openRoutingModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "routing-dialog";
        dialogConfig.data = {
            nextId: this.question.defaultNextQuestionID,
            currentQuestionID: this.question.id,
            p: this,
            questionSetRoutingList: this.questionSetRoutingList
        }
        const dialogRef = this.dialog.open(RoutingModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    openCodedReferenceModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: this,
            currentQuestionID: this.question.id
        }
        const dialogRef = this.dialog.open(CodedreferenceModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
        //const modalRef = this.modalService.open(CodedreferenceModalComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.currentQuestionID = this.question.id;
        //modalRef.componentInstance.p = this;
        //modalRef.result.then((result) => {
        //    this.closeResult = 'Closed';
        //    console.log(this.closeResult);
        //
        //},
        //    (reason) => {
        //        this.closeResult = `Dismissed`;
        //        console.log(this.closeResult);
        //    });
    }
    openActionResultModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: this
        }
        const dialogRef = this.dialog.open(ActionresultModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
        //const modalRef = this.modalService.open(ActionresultModalComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.p = this;
        //modalRef.result.then((result) => {this.closeResult = 'Closed';}, (reason) => {this.closeResult = `Dismissed`;});
    }
    AddActionResult(ar: ActionResult) {
        this.question.action.push(ar);
    }
    SetNextId(id:string) {
        this.question.defaultNextQuestionID = id;
        this.questionSetControl.CalculateQuestionDetail();
    }
    /* **************************************************************** */
    GetSelectType() {
        return <QuestionTypeSelect>this.question.questionType;
    }
    removeAnswerChoiceByID(cid: string) {
        (<QuestionTypeSelect>this.question.questionType).choice =
            (<QuestionTypeSelect>this.question.questionType).choice.filter(e => { return e.id != cid });
        var seq = 1;
        (<QuestionTypeSelect>this.question.questionType).choice.forEach(e => { e.sequence = seq++; });
    }
    AddAnswer() {
        let qts = (<QuestionTypeSelect>this.question.questionType);
        let max = 1;
        qts.choice.forEach(e => {
            let n = parseInt(e.id.replace(/[^0-9]/g, ''));
            if (n > max) { max = n; }
        });
        qts.choice.push(
            new AnswerChoiceCommonType({
                id: "c" + (max + 1),
                choiceText: "",
                nextQuestionID: "Default",
                sequence: qts.choice.length + 1,
                codedReference: [],
                action: [],
                additionalFreeTextIndicator: "NA"
            })
        );
    }
    AddNextQuestionCondition(type:string) {
        let cmp: any = { nextQuestionID: 'End' };
        if (type == 'Single') {
            cmp.comparisonValue = "";
            cmp.comparisonOperator = "GT";
        } else {
            cmp.lowerBoundComparisonValue = "";
            cmp.lowerBoundComparisonOperator = "GT";
            cmp.upperBoundComparisonValue = "";
            cmp.upperBoundComparisonOperator = "LT";
        }
        let data: any = { type: type, comparison: cmp };
        let nqc = new NextQuestionCondition(data);
        if (this.question.questionType instanceof QuestionTypeSelect) {
            (<QuestionTypeSelect>this.question.questionType).nextQuestionCondition.push(nqc);
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            (<QuestionTypeDate>this.question.questionType).nextQuestionCondition.push(nqc);
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            (<QuestionTypeNumeric>this.question.questionType).nextQuestionCondition.push(nqc);
        }
    }
    /* **************************************************************** */
    GetDateType() {
        return <QuestionTypeDate>this.question.questionType;
    }
    GetNumericType() {
        return <QuestionTypeNumeric>this.question.questionType;
    }
    GetFreeTextType() {
        return <QuestionTypeFreeText>this.question.questionType;
    }
    //ngOnChanges(changes: SimpleChanges) {
    //    console.log(changes);
    //}
}
