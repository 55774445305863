<h2 mat-dialog-title>Custom Authorization Period</h2>
<div mat-dialog-content>
    <div class="column-4">
        <mat-form-field appearance="outline" class="full-width">
            <input matInput [(ngModel)]="customPeriod.value">
        </mat-form-field>
    </div>
    <div class="column-8">
        <mat-form-field appearance="outline" class="full-width">
            <mat-select [(ngModel)]="customPeriod.unit">
                <mat-option value="Hours">Hours</mat-option>
                <mat-option value="Days">Days</mat-option>
                <mat-option value="Weeks">Weeks</mat-option>
                <mat-option value="Months">Months</mat-option>
                <mat-option value="Years">Years</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button color="primary" (click)="addPeriod()">Add</button>
</div>