import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../../classes/client';
import { QuestionSetDashboardService } from '../question-set-dashboard.service';
import { ClientService } from '../../../shared/services/client.service';
import { QuestionSet } from '../../../classes/questionset';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-question-set',
    templateUrl: './add-question-set.component.html',
    styleUrls: ['./add-question-set.component.scss']
})
export class AddQuestionSetComponent {
    @Output() event: EventEmitter<any> = new EventEmitter();
    clients: Client[] = [];
    currentFilteredClient: string = "";
    addNewQuestionSetForm = this.fb.group({
        questionSetName: ['', Validators.required],
        questionSetDescription: [''],
        clientSelect: ['', Validators.required],
        questionSetTypeID: ['', Validators.required],
    });
    constructor(
        private clientService: ClientService,
        public questionSetDashboardService: QuestionSetDashboardService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<AddQuestionSetComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
    ) {
        this.currentFilteredClient = data.currentFilteredClient
    }
    ngOnInit() {
        this.populateClients();
        this.addNewQuestionSetForm.controls['questionSetTypeID'].setValue("1", { onlySelf: true });
        this.addNewQuestionSetForm.controls['clientSelect'].setValue(this.currentFilteredClient, { onlySelf: true });
    }
    close() {
        this.dialogRef.close("0");
    }
    onSubmit(): void {
        this.questionSetDashboardService.addQuestionSet(
            this.addNewQuestionSetForm.value.clientSelect!,
            this.addNewQuestionSetForm.value.questionSetName!,
            this.addNewQuestionSetForm.value.questionSetDescription!,
            parseInt(this.addNewQuestionSetForm.value.questionSetTypeID!),
        )
            .subscribe(
                (data) => {
                    if (data.status == 200) {
                        if (data.body.result != -1) {
                            //successfully saved
                            this.dialogRef.close("1");
                        } else {
                            alert(data.body.message);
                        }
                    }
                },  //changed
                (err) => {
                    console.log(err)
                },
                () => console.log("Done")
            );
    }
    populateClients() {
        this.clients = this.clientService.getClients();
        //this.clientService.getClients().subscribe(resp => {
        //    if (resp.body) {
        //        this.clients = this.clientService.mapClientResponse(resp);
        //        //this.onSelectClient();
        //    }
        //});
    }
}

