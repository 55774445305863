import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CodedReference } from '../../classes/questionset';
import { ApiPaths } from '../environments/routes';
import { environment } from '../environments/environments';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CodedReferenceService {

    constructor(private http: HttpClient, private authService: AuthService,) { }

    private getHeaders() {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getAllCodedReferences(): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetCodedReferences, { headers: httpHeaders, observe: 'response' });
    }

    public mapCodedReferencesResponse(response: HttpResponse<any>) {
        const code: CodedReference[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            if (v instanceof Object) {
                const c = new CodedReference(v);

                code.push(c);
            }
        }
        return code;
    }
}
