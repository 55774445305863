export class Case {
    pcnCaseTypeID: number | undefined;
    caseType: string | undefined;
}

export class CaseSubType {
    pcnCaseSubTypeID: number | undefined;
    pcnCaseTypeID: number | undefined;
    caseSubType: string | undefined;
    caseSubTypeValue: string | undefined;
}

export class DeterminationLevel {
    determinationLevelID: number | undefined;
    determinationLevel: string | undefined;
}