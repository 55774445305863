import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service';
import { ApiPaths } from '../../shared/environments/routes';
import { environment } from '../../shared/environments/environments';

@Injectable({
    providedIn: 'root'
})

export class TestService {

    constructor(private http: HttpClient, private authService: AuthService) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }
    public setCaseToDecisionTree(CaseID:number, DecsionTreeID:number, DeterminationLevel: string) {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});

        const body = {

            CaseID: CaseID,
            DecisionTreeID: DecsionTreeID,
            //DeterminationLevel: DeterminationLevel,
            ClientID:               "?",
            PCN:                    "?",
            PCNDescription:         "?",
            PlanId:                 "-1",
            PlanDescription:        "?",
            GroupId:                "-1",
            GroupName:              "?",
            DrugID:                 "?",
            DrugIDQualifier: "?",
            
        }

        return this.http.post<any>(
            environment.baseUrl + '/rxdm/setcasetodecisiontree', body, {
                headers: httpHeaders,  observe: 'response'
        });
    }
    public getDecisionTreeForCase(CaseID: number, DeterminationLevel: string) {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});
        const body = {
            CaseID: CaseID
            /*, DeterminationLevel: DeterminationLevel*/
        }
        return this.http.post<any>(
            environment.baseUrl + '/rxdm/getdecisiontreeforcase', body, {
            headers: httpHeaders, observe: 'response'
        });
    }
    public savedecisiontreeresults(req: any) {
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });
        
        const body = {
            CaseID: req.CaseID,
            Results: req.Results


        };
        return this.http.post<any>(
            environment.baseUrl + '/rxdm/savedecisiontreeresults', body, {
            headers: httpHeaders, observe: 'response'
        });
    }
    //getdecisiontreeforcase
    //savedecisiontreeresults
    public mapCaseToDecisionTree() {
        console.log("I SHOULD BE DOING SOMETHING");
    }
    public getRXDMDecisionTree(clientID: string, pcn: string, benefitPlanID: string
        , benefitGroupID: string, type: string, subtype: string
        , drugIDType: string, drugID: string
        , DeterminationLevel: string
        , requestDate?: string) {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});

        var queryParams = new HttpParams();
        //queryParams = queryParams.append('DeterminationLevel', DeterminationLevel);
        queryParams = queryParams.append('ClientID', clientID);
        queryParams = queryParams.append('PCN', pcn);
        queryParams = queryParams.append('BenefitPlanID', benefitPlanID);
        queryParams = queryParams.append('BenefitGroupID', benefitGroupID);
        queryParams = queryParams.append('Type', type);
        queryParams = queryParams.append('Subtype', subtype);
        queryParams = queryParams.append('Drugidtype', drugIDType);
        queryParams = queryParams.append('Drugid', drugID);
        queryParams = queryParams.append('RequestDate', requestDate ?? "");
        return this.http.get<any>(
            environment.baseUrl + '/rxdm/getdecisiontreelist', { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
    public mapRXDMDecisionTree(response: HttpResponse<any>) {
        const trees: DecisionTreeRXDM[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const d = new DecisionTreeRXDM();
            if (v instanceof Object) {
                d.hierarchyID = parseInt(v['hierarchyID' as keyof typeof v].toString());
                d.clientID = v['clientID' as keyof typeof v].toString();
                d.pcnReferenceID = parseInt(v['pcnReferenceID' as keyof typeof v].toString());
                d.planReferenceID = parseInt(v['planReferenceID' as keyof typeof v].toString());
                d.groupReferenceID = parseInt(v['groupReferenceID' as keyof typeof v].toString());
                d.hierarchyDescription = v['hierarchyDescription' as keyof typeof v].toString();
                d.startDate = v['startDate' as keyof typeof v].toString();
                d.endDate = v['startDate' as keyof typeof v].toString();
                d.clientName = v['clientName' as keyof typeof v].toString();
                d.pcn = v['pcn' as keyof typeof v].toString();
                d.pcnDescription = v['pcnDescription' as keyof typeof v].toString();
                d.benefitPlanID = v['benefitPlanID' as keyof typeof v].toString();
                
                d.planDescription = v['planDescription' as keyof typeof v].toString();
                d.benefitGroupID = v['benefitGroupID' as keyof typeof v].toString();
                
                d.groupName = v['groupName' as keyof typeof v].toString();
                d.hierarchyToDecisionTreeID = parseInt(v['hierarchyToDecisionTreeID' as keyof typeof v].toString());
                d.decisionTreeHeaderID = parseInt(v['decisionTreeHeaderID' as keyof typeof v].toString());
                d.drugIDQualifier = v['drugIDQualifier' as keyof typeof v].toString();
                d.drugID = v['drugID' as keyof typeof v].toString() ?? "";
                d.caseType = v['caseType' as keyof typeof v].toString();
                d.caseSubType = v['caseSubType' as keyof typeof v].toString();
                d.tier = v['tier' as keyof typeof v].toString();
                d.priority = parseInt(v['priority' as keyof typeof v].toString());
                d.decisionTreeDescription = v['decisionTreeDescription' as keyof typeof v].toString();
                d.decisionTreeName = v['decisionTreeName' as keyof typeof v].toString();
                d.decisionTreeID = v['decisionTreeID' as keyof typeof v].toString();
                trees.push(d);
            }
        }
        return trees;
    }

    public getLegacyDecisionTreeQP(clientID: string, pcn: string, benefitPlanID: string, benefitGroupID: string, type: string, subtype: string, drugIDType: string, drugID: string, determinationLevel:string, coverageDeterminationID:string,  requestDate?: string) {
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });

        var body: any = {};

        body.clientID = clientID;
        //body.determinationLevel = determinationLevel;
        body.coverageDeterminationID = coverageDeterminationID;
        body.pcn = pcn;
        body.benefitPlanID = benefitPlanID;
        body.benefitGroupID = benefitGroupID;
        body.type = type;
        body.subtype =  subtype;
        body.drugidtype =  drugIDType;
        body.drugid = drugID;
        //body.requestDate = requestDate;

        return this.http.post<any>(
            environment.baseUrl + '/legacyCD/getdecisiontree', body, { headers: httpHeaders, observe: 'response' });
    }
    public getLegacyDecisionTree(clientID: string, pcn: string, benefitPlanID: string, benefitGroupID: string, type: string, subtype: string, drugIDType: string, drugID: string, determinationLevel: string, coverageDeterminationID: string, requestDate?: string) {
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });
        
        var body = {
            'ClientID': clientID,
            //'DeterminationLevel': determinationLevel,
            'coverageDeterminationID': coverageDeterminationID,
            'PCN': pcn,
            'BenefitPlanID': benefitPlanID,
            'BenefitGroupID': benefitGroupID,
            'Type': type,
            'Subtype': subtype,
            'Drugidtype': drugIDType,
            'Drugid': drugID,
            'RequestDate': requestDate ?? null
        };
        return this.http.post<any>(
            environment.baseUrl + '/legacyCD/getdecisiontree', body, { headers: httpHeaders, observe: 'response' });
    }
   
    public savelegacydecisiontree(req: any) {
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });
        const body = req;
        return this.http.post<any>(
            environment.baseUrl + '/legacyCD/savedecisiontree', body, {
            headers: httpHeaders, observe: 'response'
        });
    }
}

export class DecisionTreeRXDM {
    hierarchyID: number | undefined;
    clientID: string | undefined;
    pcnReferenceID: number | undefined;
    planReferenceID: number | undefined;
    groupReferenceID: number | undefined;
    hierarchyDescription: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    isDeleted: boolean | undefined;
    clientName: string | undefined;
    pcn: string | undefined;
    pcnDescription: string | undefined;
    benefitPlanID: string | undefined;
    
    planDescription: string | undefined;
    benefitGroupID: string | undefined;
    
    groupName: string | undefined;
    hierarchyToDecisionTreeID: number | undefined;
    decisionTreeHeaderID: number | undefined;
    drugIDQualifier: string | undefined;
    drugID: string | undefined;
    caseType: string | undefined;
    caseSubType: string | undefined;
    tier: string | undefined;
    priority: number | undefined;
    decisionTreeName: string | undefined;
    decisionTreeDescription: string | undefined;
    decisionTreeID: string | undefined;
}
