<section *ngIf="!isSettings">
    <mat-form-field appearance="outline" color="accent">
        <mat-label>Client</mat-label>
        <mat-select name="groupList" [(ngModel)]="selectedClient" (selectionChange)="updateClient()" placeholder="Select a client">
            <mat-option *ngFor="let c of clients; index as i; first as isFirst" [value]="c">
                {{c.clientName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</section>
<mat-form-field appearance="outline"  color="accent">
    <mat-label>PCN</mat-label>
    <mat-select name="pcnList" (selectionChange)="onSelectPCN($event.value)" [(ngModel)]="selectedPCN" placeholder="Select a PCN">
        <mat-option *ngFor="let p of pcns; index as i; first as isFirst" [value]="p">
            {{p.pcnDescription}}
        </mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="outline"  color="accent">
    <mat-label>Plan</mat-label>
    <mat-select name="planList" (selectionChange)="onSelectPlan($event.value)" [(ngModel)]="selectedPlan" placeholder="Select a Plan">
        <mat-option *ngFor="let p of plans; index as i; first as isFirst" [value]="p">
            {{p.planDescription}}
        </mat-option>
    </mat-select>
</mat-form-field>
<mat-form-field appearance="outline" color="accent">
    <mat-label>Group</mat-label>
    <mat-select name="groupList" (selectionChange)="onSelectGroup($event.value)" [(ngModel)]="selectedGroup" placeholder="Select a Group">
        <mat-option *ngFor="let g of groups; index as i; first as isFirst" [value]="g">
            {{g.groupName}}
        </mat-option>
    </mat-select>
</mat-form-field>
<button onclick="console.log('NO')" style="display:none;"></button>
<button mat-stroked-button color="accent" (click)="addConfiguration()">Add</button>
<div id="ccConfig" style="overflow-y:auto; max-height:calc(80vh - 300px)">
    <table mat-table matSort [dataSource]="dataSource" class="">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="isSelected"> &nbsp; </th>
            <td mat-cell *matCellDef="let r"> <mat-checkbox [(ngModel)]="r.isSelected" (change)="setHierarchy(r)"></mat-checkbox> </td>
        </ng-container>
        <!-- PCN Column -->
        <ng-container matColumnDef="pcn">
            <th mat-header-cell *matHeaderCellDef> PCN </th>
            <td mat-cell *matCellDef="let r"> {{r.pcn}} {{r.pcnDescription}} </td>
        </ng-container>

        <!-- Plan Column -->
        <ng-container matColumnDef="plan">
            <th mat-header-cell *matHeaderCellDef> Plan </th>
            <td mat-cell *matCellDef="let r"> {{r.plan}} </td>
        </ng-container>

        <!-- Group Column -->
        <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef> Group </th>
            <td mat-cell *matCellDef="let r"> {{r.group}} </td>
        </ng-container>

    <!-- Start Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> Effectiveness </th>
        <td mat-cell *matCellDef="let r">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Effectiveness Range</mat-label>
                <mat-date-range-input [rangePicker]="picker" [disabled]="!r.isSelected">
                    <input matStartDate placeholder="Start date" [(ngModel)]="r.settingsStartDate" (dateChange)="updateDate(r)">
                    <input matEndDate placeholder="End date" [(ngModel)]="r.settingsEndDate" (dateChange)="updateDate(r)">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </td>
    </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height:86px!important"></tr>
    </table>
</div>
<!--<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>-->


