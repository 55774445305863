<h2 mat-dialog-title>Add Question</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>


<mat-dialog-content>
    <form id="addNewQuestionForm" [formGroup]="addNewQuestionForm" (ngSubmit)="AddQuestion()">
        <div class="form-group">
            <label for="label">Label:</label>
            <input type="text" class="full-width" id="label" formControlName="label" placeholder="Enter a label">
        </div>
        <div class="form-group">
            <label for="label">Question Type:</label><br />
            <input id="sqt1" type="radio" name="selectQuestionType" formControlName="selectQuestionType" value="Select" />       <label for="sqt1">  Select      </label><br />
            <input id="sqt2" type="radio" name="selectQuestionType" formControlName="selectQuestionType" value="Multi-Select" /> <label for="sqt2">  Multi-Select</label><br />
            <input id="sqt3" type="radio" name="selectQuestionType" formControlName="selectQuestionType" value="Date" />         <label for="sqt3">  Date        </label><br />
            <input id="sqt4" type="radio" name="selectQuestionType" formControlName="selectQuestionType" value="DateTime" />     <label for="sqt4">  DateTime    </label><br />
            <input id="sqt5" type="radio" name="selectQuestionType" formControlName="selectQuestionType" value="Text" />         <label for="sqt5">  Text        </label><br />
            <input id="sqt6" type="radio" name="selectQuestionType" formControlName="selectQuestionType" value="Numeric" />      <label for="sqt6">  Numeric     </label>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="addNewQuestionForm" [disabled]="!addNewQuestionForm.valid" 
            mat-raised-button color="primary">Add</button>
</mat-dialog-actions>
