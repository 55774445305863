<div id="guided-modal">
    <h2 mat-dialog-title>Settings</h2>
    <span class="material-icons modal-cLose" (click)="close()">cancel</span>
    <mat-divider></mat-divider>
    <mat-dialog-content>
        <!--{{activeStepIndex}} {{requestedStepIndex}}-->
        <config-error [tree]="tree" [isSettings]="true" [hidden]="activeStepIndex >= requestedStepIndex"></config-error>
        <form [formGroup]="settingsForm">
            <div [hidden]="activeStepIndex !== 0">
                <h4><span class="number-icon">1</span>Decision tree name</h4>
                <section>
                    <div class="column-8">
                        <mat-form-field appearance="outline" class=" full-width" color="accent">
                            <mat-label>Decision Tree Name</mat-label>
                            <input matInput formControlName="name" type="text">
                            <mat-error *ngIf="settingsForm.controls['name'].invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="column-8">
                        <mat-form-field appearance="outline" class="full-width"  color="accent">
                            <mat-label>Description</mat-label>
                            <input matInput formControlName="desc" type="text">
                        </mat-form-field>
                    </div>
                </section>
            </div>
            <div [hidden]="activeStepIndex !== 1">
                <h4><span class="number-icon">2</span>{{tree.client.clientName}} Client Configuration</h4>
                <section *ngIf="tree.client.clientID">
                    <client-configuration [tree]="tree" [clients]="clients" [p]="p" [isSettings]=true (hierarchyAdded)="refreshChildComponents()"></client-configuration>
                </section>
            </div>
            <div [hidden]="activeStepIndex !== 2">
                <h4><span class="number-icon">3</span>RxPath Configuration</h4>
                <p *ngIf="!showClientConfig">No Client Configuration Selected!</p>
                <section *ngIf="tree.client.clientID && showClientConfig">
                    <rxdm-configuration #rdmConfiguration [tree]="tree" [p]="p"></rxdm-configuration>
                </section>
            </div>
            <div [hidden]="activeStepIndex !== 3">
                <h4><span class="number-icon">4</span>Drug Configuration</h4>
                <p *ngIf="!showClientConfig">No Client Configuration Selected!</p>
                <section *ngIf="tree.client.clientID && showClientConfig">
                    <drug-configuration #drugConfiguration [tree]="tree" [p]="p"></drug-configuration>
                </section>
            </div>
        </form>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="center" style="min-width:800px;">

        <button mat-stroked-button color="accent" (click)="previousStep()" [disabled]="activeStepIndex == 0"
                style="border-radius: 24px; top: -10px;">
            Back
        </button>

        <mat-horizontal-stepper color="accent" labelPosition="bottom" #stepper [selectedIndex]="activeStepIndex" (selectionChange)="stepSelectionChanged($event)" class="center-icon-stepper">
            <!-- Add mat-step components as needed -->
            <mat-step [completed]="checkStep1Validation()" />
            <mat-step [completed]="checkStep2Validation()" />
            <mat-step [completed]="checkStep3Validation()" />
            <mat-step [completed]="checkStep4Validation()" />
            <!-- Add more steps as needed -->
        </mat-horizontal-stepper>
        <button mat-flat-button color="accent" *ngIf="activeStepIndex != 3" (click)="nextStep()"
                style="border-radius: 24px; top: -10px;">
            Next
        </button>
        <button mat-flat-button color="accent" *ngIf="activeStepIndex === 3" [disabled]="!validateTree()" (click)="save()" style="border-radius: 24px; top: -10px;">
            Save
        </button>
        <!--<button type="submit"
                mat-raised-button color="primary">
            Save
        </button>-->
    </mat-dialog-actions>
</div>