import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../classes/user';
import { AuthService } from '../shared/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    user: User | undefined;
    isAuthenticated = false;
    checkSessionChanged$!: Observable<boolean>;
    checkSessionChanged: any;
    showConfig!: boolean;

    constructor(private authService: AuthService) { }

    ngOnInit() {
        this.showConfig = false;
        /// DO NOT DO THISthis.login();
        this.isAuthenticated = this.authService.isAuthenticated();
        this.user = this.authService.user;
  }

  login() {
      this.authService.login();
      this.isAuthenticated = this.authService.isAuthenticated();
      this.user = this.authService.user;
  }

  logout() {
      this.authService.logout();
  }

  toggleConfig() {
    this.showConfig = !this.showConfig;
  }
}
