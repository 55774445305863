<h2 mat-dialog-title>View Hierarchy Ties</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <section *ngIf="showDetails">
        <p>
            {{hierarchy.clientCompany}} > {{hierarchy.plan}} > {{hierarchy.lobPcn}} > {{hierarchy.group}} >
        </p>
        <!--<section class="effectiveDate column-12" [formGroup]="effectiveDate">
            <p class="column-3"><mat-checkbox formControlName="past" (change)="onEffectiveDateCheckboxChange($event)">Past Effective</mat-checkbox></p>
            <p class="column-3"><mat-checkbox formControlName="current" (change)="onEffectiveDateCheckboxChange($event)">Currently Effective</mat-checkbox></p>
            <p class="column-3"><mat-checkbox formControlName="future" (change)="onEffectiveDateCheckboxChange($event)">Future Effective</mat-checkbox></p>
        </section>-->
        <section>
            <div class="">
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Tree Column -->
                    <ng-container matColumnDef="tree" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tree </th>
                        <td mat-cell *matCellDef="let row"> {{row.decisionTreeName}} </td>
                    </ng-container>

                    <!--Qualifier Column-->
                    <ng-container matColumnDef="qualifier" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Qualifier </th>
                        <td mat-cell *matCellDef="let row"> {{row.drugIDQualifier}} </td>
                    </ng-container>

                    <!--ID Column-->
                    <ng-container matColumnDef="id" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.drugID}} </td>
                    </ng-container>

                    <!--Type Column-->
                    <ng-container matColumnDef="type" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
                        <td mat-cell *matCellDef="let row"> {{row.caseType}} </td>
                    </ng-container>

                    <!--Subtype Column-->
                    <ng-container matColumnDef="subtype" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> SubType </th>
                        <td mat-cell *matCellDef="let row"> {{row.caseSubType}} </td>
                    </ng-container>

                    <!--Tier Column-->
                    <ng-container matColumnDef="tier" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tier </th>
                        <td mat-cell *matCellDef="let row"> {{row.tier}} </td>
                    </ng-container>

                    <!--start Column-->
                    <ng-container matColumnDef="start" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start </th>
                        <td mat-cell *matCellDef="let row"> {{row.startDate  | date: 'dd/MM/YYYY'}} </td>
                    </ng-container>

                    <!--end Column-->
                    <ng-container matColumnDef="end" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> End </th>
                        <td mat-cell *matCellDef="let row"> {{row.endDate  | date: 'dd/MM/YYYY'}} </td>
                    </ng-container>

                    <!--Terminate Column-->
                    <ng-container matColumnDef="terminate" class="dash-container">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> &nbsp; </th>
                        <td mat-cell *matCellDef="let row"> 
                            <button mat-button color="primary" style="display:none" (click)="openTerminateModal(row)">Terminate</button> 
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No Results</td>
                    </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of hierarchies"></mat-paginator>
            </div>
        </section>
    </section>
    <section *ngIf="!showDetails">
        <p>
            Add Hierarchy Tie:
        </p>
        <section *ngIf="showDrugSearch">
            <form id="solrSearchForm" [formGroup]="solrSearchForm" (ngSubmit)="searchDrugs()">
                <div class="form-group">
                    <mat-form-field appearance="outline">
                        <mat-label>Drug Search Term:</mat-label>
                        <input matInput formControlName="drugSearchTerm" placeholder="Enter a drug to search for" #input>
                    </mat-form-field>
                </div>
            </form>
            <button type="submit" form="solrSearchForm" *ngIf="!showDetails" [disabled]="!solrSearchForm.valid"
                    mat-raised-button color="primary">
                Search
            </button>
            <button type="button" *ngIf="!showDetails"
                    mat-raised-button color="primary" (click)="onSelectNoDrug()">
                No Drug
            </button>
            <section *ngIf="solrDrugs.length >= 1">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of solrDrugs; index as i; first as isFirst">
                            <td>{{d.desc}}</td>
                            <td><button mat-stroked-button color="primary" (click)="onSelectDrug(d)">Select</button></td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </section>
        <section *ngIf="showAdd" class="column-12">
            <form id="addTieForm" [formGroup]="addTieForm" (ngSubmit)="save()">
                <mat-form-field class="column-12" appearance="outline">
                    <mat-label>Decision Tree</mat-label>
                    <mat-select formControlName="decisionTreeList" name="selectedClient" (selectionChange)="onSelectDecisionTree()">
                        <mat-option *ngFor="let d of decisionTrees" [value]="d.decisionTreeID">
                            {{d.decisionTreeName}} {{d.majorVersion}}.{{d.minorVersion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="column-6" appearance="outline">
                    <mat-label>Drug Qualifier</mat-label>
                    <mat-select id="drugQualifierInput" formControlName="drugQualifierInput">
                        <mat-option value="N/A">N/A</mat-option>
                        <mat-option value="GPI-2">GPI-2 - Drug Group</mat-option>
                        <mat-option value="GPI-4">GPI-4 - Drug Class</mat-option>
                        <mat-option value="GPI-8">GPI-8 - Drug Base Name</mat-option>
                        <mat-option value="GPI-10">GPI-10 - Drug Name (Extension)</mat-option>
                        <mat-option value="GPI-12">GPI-12 - Drug Name and Dosage Form</mat-option>
                        <mat-option value="GPI-14">GPI-14 - Full GPI with Dosage Form and Drug Strength</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="column-6" appearance="outline">
                    <mat-label>Drug ID</mat-label>
                    <input matInput id="drugIDInput" formControlName="drugIDInput" placeholder="ID of the selected Drug" #input>
                </mat-form-field>
                <mat-form-field class="column-4" appearance="outline">
                    <mat-label>Case Type</mat-label>
                    <input matInput id="caseInput" formControlName="caseInput" placeholder="The case" #input>
                </mat-form-field>
                <mat-form-field class="column-4" appearance="outline">
                    <mat-label>Case SubType</mat-label>
                    <input matInput id="caseSubTypeInput" formControlName="caseSubTypeInput" placeholder="The case subtype" #input>
                </mat-form-field>
                <mat-form-field class="column-4" appearance="outline">
                    <mat-label>Tier</mat-label>
                    <input matInput id="tierInput" formControlName="tierInput" placeholder="The tier" #input>
                </mat-form-field>
                <mat-form-field class="column-6" appearance="outline">
                    <mat-label>Enter a date range for the Hierarchy</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" formControlName="start">
                        <input matEndDate placeholder="End date" formControlName="end">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <p>Form Status: {{ addTieForm.status }}</p>
            </form>
        </section>
    </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button style="display:none;" mat-raised-button color="primary" type="button" class="btn btn-outline-secondary" (click)="switchForm()">
        {{showDetails === true ? "Add Tie To Decision Tree" : "View Ties"}}
    </button>
    <button type="submit" form="addTieForm" [disabled]="!addTieForm.valid" *ngIf="!showDetails"
            mat-raised-button color="primary">
        Add
    </button>
</mat-dialog-actions>


            
