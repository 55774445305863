import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../environments/environments';


export const authConfig: AuthConfig = {
  issuer: environment.openid.issuer,
  redirectUri: window.location.origin + environment.openid.redirectUriPath,
  tokenEndpoint: environment.openid.tokenUrl,
  clientId: environment.openid.clientId,
  scope: environment.openid.scope,
  responseType: environment.openid.responseType,
  disablePKCE: environment.openid.disablePKCE,
  showDebugInformation: environment.openid.showDebugInformation,
  disableIdTokenTimer: environment.openid.disableIdTokenTimer,
  requestAccessToken: environment.openid.requestAccessToken,
    dummyClientSecret: environment.openid.clientSecret,
    requireHttps: environment.openid.requirehttps,
} 
