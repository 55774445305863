import { NgModule } from '@angular/core';
import { LayoutModule } from 'abca-layout';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { QuestionSetDetailsComponent } from './question/question-set/question-set-details/question-set-details.component';
import { QuestionEditComponent } from './question/question-edit/question-edit.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HierarchyDetailsModalComponent } from './hierarchy/hierarchy-details-modal/hierarchy-details-modal.component';
import { HierarchyAddModalComponent } from './hierarchy/hierarchy-add-modal/hierarchy-add-modal.component';
import { QuestionTemplateComponent } from './question/question-edit/question-template/question-template.component';
import { ActionresultTemplateComponent } from './question/question-edit/actionresult-template/actionresult-template.component';
import { NextquestionconditionTemplateComponent } from './question/question-edit/nextquestioncondition-template/nextquestioncondition-template.component';
import { AnswerchoiceTemplateComponent } from './question/question-edit/answerchoice-template/answerchoice-template.component';
import { CodedreferenceTemplateComponent } from './question/question-edit/codedreference-template/codedreference-template.component';
import { ExportModalComponent } from './shared/export-modal/export-modal.component';
import { CopyModalComponent } from './shared/copy-modal/copy-modal.component';
import { QuestionModalComponent } from './question/question-edit/question-modal/question-modal.component';
import { ImportQSModalComponent } from './question/question-set/import-modal/import-modal.component';
import { ImportDTModalComponent } from './decision-tree/import-dt-modal/import-dt-modal.component';
import { AddDecisiontreeModalComponent } from './decision-tree/add-decisiontree-modal/add-decisiontree-modal.component';
import { RoutingModalComponent } from './question/question-edit/routing-modal/routing-modal.component';
import { AddQuestionModalComponent } from './question/question-edit/add-question-modal/add-question-modal.component';
import { AddQuestionSetComponent } from './question/question-set/add-question-set/add-question-set.component';
import { SortBySequencePipe } from './shared/pipes/sort-by-sequence.pipe';
import { SortByOrderPipe } from './shared/pipes/sort-by-order.pipe';
import { SortByMajorVersionPipe } from './shared/pipes/sort-by-major-version.pipe';
import { TestDecisiontreeModalComponent } from './decision-tree/test-decisiontree-modal/test-decisiontree-modal.component';
import { CodedreferenceModalComponent } from './question/codedreference-modal/codedreference-modal.component';
import { QuestionLanguageComponent } from './question/question-edit/question-language/question-language.component';
import { ActionresultModalComponent } from './question/question-edit/actionresult-modal/actionresult-modal.component';
import { TestComponent } from './rxdm/test/test.component';
import { HierarchyTerminateModalComponent } from './hierarchy/hierarchy-terminate-modal/hierarchy-terminate-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CookieService } from 'ngx-cookie-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { EditQuestionsComponent } from './decision-tree/edit-questions/edit-questions.component';
import { EditQuestionsTreeComponent } from './decision-tree/edit-questions-tree/edit-questions-tree.component';
import { EditQuestionsListComponent } from './decision-tree/edit-questions-list/edit-questions-list.component';
import { StatusPipe } from './shared/pipes/status.pipe';
import { StatusClassPipe } from './shared/pipes/status-class.pipe';
import { EditQuestionListItemComponent } from './decision-tree/edit-question-list-item/edit-question-list-item.component';
import { GuidedCreateDecisiontreeModalComponent } from './decision-tree/guided-create-decisiontree-modal/guided-create-decisiontree-modal.component';
import { MatDivider, MatDividerModule } from '@angular/material/divider';
import { EditQuestionAnswerChoiceComponent } from './decision-tree/edit-question-answer-choice/edit-question-answer-choice.component';
import { ClientConfigurationComponent } from './shared/client-configuration/client-configuration.component';
import { EditQuestionDisplayComponent } from './decision-tree/edit-question-display/edit-question-display.component';
import { CommentDialogComponent } from './shared/comment-dialog/comment-dialog.component';
import { SolrSearchComponent } from './shared/solr-search/solr-search.component';
import { EditQuestionDecisionListComponent } from './decision-tree/edit-question-decision-list/edit-question-decision-list.component';
import { EditQuestionDecisionItemComponent } from './decision-tree/edit-question-decision-item/edit-question-decision-item.component';
import { EditQuestionDecisionItemJustificationComponent } from './decision-tree/edit-question-decision-item-justification/edit-question-decision-item-justification.component';
import { CaseSelectionComponent } from './shared/case-selection/case-selection.component';
import { EditQuestionSettingsModalComponent } from './decision-tree/edit-question-settings-modal/edit-question-settings-modal.component';

import { EditQuestionsCatalogModalComponent } from './decision-tree/edit-questions-catalog-modal/edit-questions-catalog-modal.component';
import { RxdmConfigurationComponent } from './shared/rxdm-configuration/rxdm-configuration.component';
import { DrugConfigurationComponent } from './shared/drug-configuration/drug-configuration.component';
import { ApproveDialogComponent } from './shared/approve-dialog/approve-dialog.component';
import { DeterminationConfigurationComponent } from './shared/determination-configuration/determination-configuration.component';
import { SortByInternalOrderPipe } from './shared/pipes/sort-by-internal-order.pipe';
import { DecisionSelectionComponent } from './question/question-edit/decision-selection/decision-selection.component';
import { EditQuestionSetComponent } from './question/edit-question-set/edit-question-set.component';
import { EditQuestionDecisionJustificationModalComponent } from './decision-tree/edit-question-decision-justification-modal/edit-question-decision-justification-modal.component';
import { EditHistoryModalComponent } from './decision-tree/edit-history-modal/edit-history-modal.component';
import { ConfigErrorComponent } from './shared/config-error/config-error.component';
import { AuthModule } from './shared/auth/auth.module';
import { environment } from './shared/environments/environments';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { JustificationsDashboardComponent } from './justifications/justifications-dashboard.component';
import { JustificationAddModalComponent } from './justifications/justification-add-modal/justification-add-modal.component';
import { JustificationDetailComponent } from './justifications/justification-detail/justification-detail.component';
import { JustificationDeleteModalComponent } from './justifications/justification-delete-modal/justification-delete-modal.component';
import { JustificationComponent } from './justifications/justification/justification.component';
import { JustificationCaseComponent } from './justifications/justification-case/justification-case.component';
import { JustificationDeterminationComponent } from './justifications/justification-determination/justification-determination.component';
import { JustificationDecisionsComponent } from './justifications/justification-decisions/justification-decisions.component';
import { EditQuestionDecisionItemOverrideComponent } from './decision-tree/edit-question-decision-item-override/edit-question-decision-item-override.component';
import { EditQuestionDecisionItemOverrideAuthPeriodModalComponent } from './decision-tree/edit-question-decision-item-override-auth-period-modal/edit-question-decision-item-override-auth-period-modal.component';
import { SortAnswerBySequencePipe } from './shared/pipes/sort-answer-by-sequence.pipe';
import { JustificationTypeAheadDirective } from './shared/directives/justification-type-ahead.directive';
import { MedicareConfigurationComponent } from './shared/medicare-configuration/medicare-configuration.component';
import { DeleteConfirmDialogComponent } from './shared/delete-confirm-dialog/delete-confirm-dialog.component';
import { EditCatalogComponent } from './question/edit-catalog/edit-catalog.component';
import { EditCatalogListComponent } from './question/edit-catalog-list/edit-catalog-list.component';
import { QuestionSetToDecisionTreeUsageDialogComponent } from './question/question-set-to-decision-tree-usage-dialog/question-set-to-decision-tree-usage-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { InlineQuestionTestComponent } from './shared/inline-question-test/inline-question-test.component';
import { InlineQuestionSetComponent } from './shared/inline-question-test/inline-question-set/inline-question-set.component';
import { HoverMenuComponent } from './shared/hover-menu/hover-menu.component';
import { OverrideListComponent } from './decision-tree/override-list/override-list.component';
import { OverrideItemComponent } from './decision-tree/override-list/override-item/override-item.component';
import { AuthPeriodComponent } from './decision-tree/override-list/auth-period/auth-period.component';
import { MedicationChipsComponent } from './decision-tree/override-list/medication-chips/medication-chips.component';
import { OverrideChipsComponent } from './decision-tree/override-list/override-chips/override-chips.component';


@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        NavigationComponent,
        FooterComponent,
        HeaderComponent,
        LoginComponent,
        HierarchyDetailsModalComponent,
        HierarchyAddModalComponent,
        QuestionSetDetailsComponent,
        AddDecisiontreeModalComponent,
        QuestionTemplateComponent,
        ActionresultTemplateComponent,
        NextquestionconditionTemplateComponent,
        AnswerchoiceTemplateComponent,
        CodedreferenceTemplateComponent,
        HierarchyAddModalComponent,
        ExportModalComponent,
        CopyModalComponent,
        QuestionModalComponent,
        ImportQSModalComponent,
        ImportDTModalComponent,
        RoutingModalComponent,
        AddQuestionModalComponent,
        AddQuestionSetComponent,
        SortBySequencePipe,
        SortByInternalOrderPipe,
        SortByOrderPipe,
        TestDecisiontreeModalComponent,
        CodedreferenceModalComponent,
        QuestionLanguageComponent,
        ActionresultModalComponent,
        TestComponent,
        HierarchyTerminateModalComponent,
        QuestionEditComponent,
        EditQuestionsTreeComponent,
        EditQuestionsListComponent,
        EditQuestionsComponent,
        EditQuestionListItemComponent,
        GuidedCreateDecisiontreeModalComponent,
        EditQuestionAnswerChoiceComponent,
        ClientConfigurationComponent,
        EditQuestionDisplayComponent,
        CommentDialogComponent,
        SolrSearchComponent,
        EditQuestionDecisionListComponent,
        EditQuestionDecisionItemComponent,
        EditQuestionDecisionItemJustificationComponent,
        CaseSelectionComponent,
        EditQuestionSettingsModalComponent,
        EditQuestionsCatalogModalComponent,
        RxdmConfigurationComponent,
        DrugConfigurationComponent,
        DeterminationConfigurationComponent,
        ApproveDialogComponent,
        DecisionSelectionComponent,
        EditQuestionSetComponent,
        EditQuestionDecisionJustificationModalComponent,
        EditHistoryModalComponent,
        ConfigErrorComponent,
        UnauthorizedComponent,
        JustificationsDashboardComponent,
        JustificationAddModalComponent,
        JustificationDetailComponent,
        JustificationDeleteModalComponent,
        JustificationComponent,
        JustificationCaseComponent,
        JustificationDeterminationComponent,
        JustificationDecisionsComponent,
        EditQuestionDecisionItemOverrideComponent,
        EditQuestionDecisionItemOverrideAuthPeriodModalComponent,
        SortAnswerBySequencePipe,
        JustificationTypeAheadDirective,
        MedicareConfigurationComponent,
        DeleteConfirmDialogComponent,
        EditCatalogComponent,
        EditCatalogListComponent,
        QuestionSetToDecisionTreeUsageDialogComponent,
        ErrorDialogComponent,
        InlineQuestionTestComponent,
        InlineQuestionSetComponent,
        HoverMenuComponent,
        OverrideListComponent,
        OverrideItemComponent,
        AuthPeriodComponent,
        MedicationChipsComponent,
        OverrideChipsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        LayoutModule.forRoot({
            layoutApiUrl: environment.layoutApiUrl,
            assetsApiUrl: './assets/theme/themeVars.json',
            version: environment.version,
            securityApiUrl: environment.layoutSecurityApiUrl,
            layoutOriginPathUrl: environment.layoutOriginPathUrl
        }),
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatNativeDateModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatSortModule,
        MatPaginatorModule,
        MatTableModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatRadioModule,
        MatTooltipModule,
        MatGridListModule,
        MatExpansionModule,
        MatStepperModule,
        MatDividerModule,
        MatChipsModule,
        MatAutocompleteModule, 
        AuthModule,
        BrowserAnimationsModule,
        StatusClassPipe,
        StatusPipe,
        SortByMajorVersionPipe,
        NgxSkeletonLoaderModule,
        /*QuestionEditComponent,*/
    ],
    providers: [
        CookieService,
        { provide: OAuthStorage, useValue: localStorage },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
  }