<div class="delete-confirm-dialog"></div>
<mat-dialog-content style="width:400px;text-align:center;">
    <mat-icon *ngIf="showDelete" color="primary">delete</mat-icon>
    <div style="font-weight:400; font-size:24px; margin:16px;">{{title}}</div>
    <p *ngIf="dialogText != ''" style="text-align:left;">{{dialogText}}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-basic-button tabindex="-1" (click)="confirm()" style="color:#800088;border:none;background-color:white">
        {{submitButtonText}}
    </button> &nbsp;
    <button mat-flat-button tabindex="1" color="accent" (click)="close()" style="border-radius:24px;">
        Cancel
    </button>

</mat-dialog-actions>
