import { ChangeDetectorRef, Component, ElementRef, Inject, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs';
import { Case, CaseSubType } from '../../classes/case';
import { Client } from '../../classes/client';
import { CaseSettings, DecisionTreeSettings, DeterminationSettings } from '../../classes/decisiontreesettings';
import { SolrDrug } from '../../classes/solr-drug';
import { User } from '../../classes/user';
import { DrugConfigurationComponent } from '../../shared/drug-configuration/drug-configuration.component';
import { RxdmConfigurationComponent } from '../../shared/rxdm-configuration/rxdm-configuration.component';
import { AuthService } from '../../shared/auth/auth.service';
import { DecisionTreeService } from '../decision-tree.service';
import { DecisionTreeNameValidator } from '../../shared/validators/decision-tree-name-validator';

@Component({
    selector: 'app-guided-create-decisiontree-modal',
    templateUrl: './guided-create-decisiontree-modal.component.html',
    styleUrls: ['./guided-create-decisiontree-modal.component.scss']
})
export class GuidedCreateDecisiontreeModalComponent {
    p: any;

    @ViewChild('drugConfiguration', { static: false })
    drugConfiguration!: ElementRef<DrugConfigurationComponent>;
    @ViewChild('rdmConfiguration', { static: false })
    rdmConfiguration!: ElementRef<RxdmConfigurationComponent>;

    @ViewChild('stepper')
    stepper!: MatStepper;

    currentFilteredClient!: Client;
    clients: Client[] = [];
    user!: User;
    tree!: DecisionTreeSettings;
    requestedStepIndex: number = 0;
    activeStepIndex: number = 0;
    currentDate = new Date();
    nextDay = new Date(this.currentDate).setDate(this.currentDate.getDate() + 1);
    futureDate = new Date(this.currentDate).setFullYear(this.currentDate.getFullYear() + 100);

    solrDrugs: SolrDrug[] = [];
    selectedDrug: SolrDrug | undefined;
    cases: Case[] = [];
    caseSubtypes: CaseSubType[] = [];

    createForm: FormGroup & { [key: string]: FormControl };
    clientChanged = false;
    completionMessage = 'TEST';
    
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<GuidedCreateDecisiontreeModalComponent>,
        private authService: AuthService,
        private decisionTreeService: DecisionTreeService,
        private fb: FormBuilder,
        private router: Router,
        private dtValidator: DecisionTreeNameValidator,
        private route: ActivatedRoute,
    ) {
        this.user = this.authService.getUserObject();

        this.createForm = this.fb.group({
            name: ['', Validators.required],
            desc: [''],
            start: [new Date()],
            end: [new Date()],
            client: ['', Validators.required],
        }) as unknown as FormGroup & { [key: string]: FormControl };

        if (data !== undefined) {
            this.currentFilteredClient = data.currentFilteredClient;
            this.tree = data.tree;
            this.tree.client = this.currentFilteredClient;
            if (data.copyFrom) {
                this.tree.copyfrom = data.copyFrom;
            }
            this.createForm.controls['client'].patchValue(this.currentFilteredClient.clientID!);
            this.createForm.get('name')?.setAsyncValidators([this.dtValidator.validateDocumentNameExists(this.currentFilteredClient?.clientID)]);

            this.tree.applicationUserID = this.user.id;
            this.p = data.p;
        }
        this.populateClients();
        const next = new Date(this.currentDate.getTime() + 24 * 60 * 60 * 1000)
        const future = new Date(this.currentDate.getFullYear() + 100, this.currentDate.getMonth(), this.currentDate.getDate())
        this.createForm.controls['start'].patchValue(next);
        this.createForm.controls['end'].patchValue(future);
        console.log('tree check', this.tree);
    }

    getErrorMessage() {
        if (this.createForm.controls['name'].hasError('required')) {
            return 'You must enter a value';
        }
        if (this.createForm.controls['name'].hasError('decisionTreeNameExists')) {
            return 'A decision tree already exist with that name, please enter a new one';
        }
        if (this.createForm.controls['start'].hasError('required')) {
            return 'You must enter a value';
        }
        if (this.createForm.controls['end'].hasError('required')) {
            return 'You must enter a value';
        }
        if (this.createForm.controls['client'].hasError('required')) {
            return 'You must enter a value';
        }
        
        return '';
    }

    stepSelectionChanged(event: any) {
        this.activeStepIndex = event.selectedIndex;
        this.populateTreeValues();
    }

    checkStep1Validation() {
        if (this.createForm.controls['name'].valid && this.createForm.controls['start'].valid && this.createForm.controls['end'].valid) {
            return true;
        } else {
            return false;
        }
    }

    checkStep2Validation() {
        if (this.tree.hierarchy.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    checkStep3Validation() {
        var hasCase = false;
        var noCaseSet = false;
        var hasDetermination = false;

        if (this.tree.noCase) {
            noCaseSet = true;
        }

        if (this.tree.hierarchy.length > 0) {
            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                if (this.tree.hierarchy[i].cases.length > 0) {
                    if (noCaseSet) {
                        this.tree.hierarchy[i].cases = [];
                    }
                    if (!hasCase) {
                        hasCase = true;
                    }
                }
                if (this.tree.hierarchy[i].determination.length > 0) {
                    if (!hasDetermination) {
                        hasDetermination = true;
                    }
                }
            }
        }

        if ((hasCase || noCaseSet) && hasDetermination) {
            return true;
        } else {
            return false;
        }
    }

    checkStep4Validation() {
        var validDrugSelection = false;
        if (this.tree.noDrug) {
            if (this.tree.hierarchy.length > 0) {
                for (let i = 0; i < this.tree.hierarchy.length; i++) {
                    if (this.tree.hierarchy[i].drugs.length == 0) {
                        validDrugSelection = true;
                    }
                }
            }
        } else {
            if (this.tree.hierarchy.length > 0) {
                for (let i = 0; i < this.tree.hierarchy.length; i++) {
                    if (this.tree.hierarchy[i].drugs.length > 0) {
                        validDrugSelection = true;
                    }
                }
            }
        }
        return validDrugSelection;
    }

    checkClients() {
        if (this.tree.hierarchy.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    setActiveStep(index: number) {
        this.activeStepIndex = index;
    }

    nextStep() {
        this.requestedStepIndex = this.activeStepIndex + 1;
        if (this.stepper.selectedIndex < this.stepper.steps.length - 1) {
            this.populateTreeValues();
            this.stepper.next();
        }
    }

    previousStep() {
        if (this.stepper.selectedIndex > 0) {
            this.populateTreeValues();
            this.stepper.previous();
        }
    }

    close() {
        if (this.clientChanged) {
            this.dialogRef.close("1");
        } else {
            this.dialogRef.close("0");
        }
    }

    populateClients() {
        this.clients = this.user.clients!;
    }

    populateTreeValues() {
        this.tree.decisionTree.decisionTreeName = this.createForm.controls['name'].value!;
        this.tree.decisionTree.decisionTreeDescription = this.createForm.controls['desc'].value!;
        this.tree.decisionTree.startDate = this.createForm.controls['start'].value!;
        this.tree.decisionTree.endDate = this.createForm.controls['end'].value!;
    }

    validateTree() {
        if (this.checkStep1Validation() && this.checkStep2Validation() && this.checkStep3Validation() && this.checkStep4Validation()) {
            return true;
        } else {
            return false;
        }
    }

    save() {
        //Populate the tree object
        this.populateTreeValues();
        
        this.decisionTreeService.addGuidedDecisionTree(this.tree!).subscribe(
            (data) => {
                if (data.status == 200) {
                    //successfully made call
                    if (data.body.result != -1) {
                        //successfully saved
                        this.dialogRef.close("200");
                        this.router.navigate(['decisiontree/editquestions'], { queryParams: { dt: data.body.result, snc:'Y' }, replaceUrl: true });
                    }
                    //error
                }
                else {
                    //error
                    this.dialogRef.close("1");
                }
            },  //changed
            (err) => {
                console.log(err)
            },
            () => console.log("Done")
        );
    }

    refreshChildComponents() {
        //If I need to refresh the child components
    }
}
