<h2 mat-dialog-title>Export {{title}}</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>



<mat-dialog-content>
    <form id="exportForm" [formGroup]="exportForm" (ngSubmit)="onSubmit()">
        <section class="column-10">
            <mat-form-field class="column-12" appearance="outline">
                <mat-label>File Name</mat-label>
                <input matInput type="text" autocomplete="off" id="fileNameInput" formControlName="fileName" placeholder="Enter a file name">
            </mat-form-field>
        </section>
        <section class="column-10">
            <label id="fileRadioGroupLabel">Pick a file format</label>
            <mat-radio-group aria-label="Select a file format" class="column-12" id="fileRadioGroup" formControlName="fileRadio">
                <mat-radio-button value="json" name="fileRadio">JSON</mat-radio-button>
                <mat-radio-button value="pdf"  name="fileRadio">PDF</mat-radio-button>
            </mat-radio-group>
        </section>
        <section class="column-10" *ngIf="applications.length > 0">
            <label id="appRadioGroupLabel">Pick an application</label>
            <mat-radio-group aria-label="Select an application" class="column-12" id="appRadioGroup" formControlName="applicationRadio">
                <mat-radio-button name="fileRadio" [value]="a.id" *ngFor="let a of applications">{{a.name}}</mat-radio-button>
            </mat-radio-group>
        </section>
        
        
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="exportForm" [disabled]="!exportForm.valid"
            mat-raised-button color="primary">Download</button>
</mat-dialog-actions>