<section>
    <div *ngFor="let override of actionResult.overrides; let i = index">
        <div class="column-6">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Override Code</mat-label>
                <mat-select [(ngModel)]="override.overrideCode">
                    <mat-option value="One">One</mat-option>
                    <mat-option value="Two">Two</mat-option>
                    <mat-option value="Three">Three</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="column-3">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Authorization Period</mat-label>
                <mat-select [(ngModel)]="override.authorizationPeriod">
                    <mat-option *ngFor="let period of possibleAuthorizationPeriods" [value]="period.value">
                        {{ period.label }}
                    </mat-option>
                    <mat-option value="Custom" (click)="openCustomPeriodModal(i)">Custom</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="column-2">
            <mat-checkbox [(ngModel)]="override.oneTime">One Time</mat-checkbox>
        </div>
        <div class="column-1">
            <button mat-icon-button color="primary" (click)="deleteOverride(i)"><mat-icon>delete</mat-icon></button>
        </div>

    </div>
    <button (click)="addNewOverride()" mat-button color="accent">+ Add Override</button>
</section>

<mat-divider></mat-divider>
<br/>
<section>
    <div *ngFor="let criteria of actionResult.overrideCriteria; let i = index"
         style="width:20%;margin-left:4%; display:inline-block;">
        
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ criteria.title }}</mat-label>
            <input matInput [(ngModel)]="criteria.value">
        </mat-form-field>
        
    </div>
    
</section>