import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { HttpResponse } from '@angular/common/http';
import { RouterLink } from '@angular/router';

import { User } from '../classes/user';
import { Hierarchy } from '../classes/hierarchy';
import { Client } from '../classes/client';
import { ReturnObjectType } from '../shared/environments/environments';
import { AuthService } from '../shared/auth/auth.service';
import { DateService } from '../shared/services/date.service';

import { HierarchyDetailsModalComponent } from './hierarchy-details-modal/hierarchy-details-modal.component';
import { HierarchyAddModalComponent } from './hierarchy-add-modal/hierarchy-add-modal.component';
import { HierarchyService } from './hierarchy.service';




@Component({
  selector: 'app-hierarchy-dashboard',
  standalone: true,
    imports: [MatPaginatorModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule, MatCheckboxModule, MatButtonModule, MatDividerModule, CommonModule, NgIf, NgFor, FormsModule, ReactiveFormsModule, RouterLink],
  templateUrl: './hierarchy-dashboard.component.html',
  styleUrls: ['./hierarchy-dashboard.component.scss']
})
export class HierarchyDashboardComponent implements AfterViewInit, OnInit{
    title = 'Client Configuration';

    displayedColumns: string[] = ['clientCompany', 'pcn', 'plan', 'group', 'details', 'export'];
    dataSource: MatTableDataSource<Hierarchy> = new MatTableDataSource<Hierarchy>([]);

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;
    
    user!: User;
    hierarchy: Hierarchy[] | undefined = [];
    clientSelection = new FormControl('');
    clientsList: Client[] = [];
    selectedClient!: Client;
    searchInput: string = '';
    returnType = ReturnObjectType.Hierarchy;

    effectiveDate = this.fb.group({
        past: true,
        current: true,
        future: true,
    });

    constructor(
        private authService: AuthService,
        private hierarchyService: HierarchyService,
        private dateService: DateService,
        private dialog: MatDialog,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.populateClients();
        this.getHierarchy();
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    getHierarchy() {
        this.hierarchy = [];
        this.hierarchyService.getAllHierarchyFromService(this.selectedClient.clientID!).subscribe(resp => {
            if (resp.body) {
                this.hierarchy = this.hierarchyService.mapHierarchyResponse(resp);
                if (this.hierarchy.length >= 0) {
                    this.dataSource = new MatTableDataSource(this.hierarchy);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                }
            }
        });
    }

    onSelectClient() {
        //set the users selected client
        this.authService.setClient(this.selectedClient.clientID!);
        this.getHierarchy();
    }

    populateClients() {
        this.clientsList = this.user.clients!;
    }

    applyFilter(event: MatCheckboxChange | Event) {
        const filterValue = this.searchInput.toLowerCase();
        const pastEffective = this.effectiveDate.get('past')?.value;
        const currentEffective = this.effectiveDate.get('current')?.value;
        const futureEffective = this.effectiveDate.get('future')?.value;

        this.dataSource.filterPredicate = (data: Hierarchy, filter: string): boolean => {
            const isPast = this.dateService.isPastEffective(data);
            const isCurrent = this.dateService.isCurrentlyEffective(data);
            const isFuture = this.dateService.isFutureEffective(data);

            // Filter by search input
            const includesSearch = filter === '' || (data.desc?.toLowerCase().includes(filter) ?? false);

            // Filter by effective date checkboxes
            const meetsEffectiveDateCriteria =
                ((pastEffective && isPast) ||
                (currentEffective && isCurrent) ||
                (futureEffective && isFuture)) ?? false;

            return includesSearch && meetsEffectiveDateCriteria;
        };

        this.dataSource.filter = filterValue.trim();

        // Manually trigger the filter update
        this.dataSource._filterData = (data: Hierarchy[]) => {
            this.dataSource.filteredData = data.filter(obj => this.dataSource.filterPredicate(obj, this.dataSource.filter));
            return this.dataSource.filteredData;
        };
        this.dataSource._updateChangeSubscription();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    onEffectiveDateCheckboxChange(event: MatCheckboxChange) {
        // Trigger filterPredicate when checkbox state changes
        this.applyFilter(event);
    }

    openDetailsModal(h: Hierarchy) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            hierarchy: h,
            client: this.selectedClient
        }
        const dialogRef = this.dialog.open(HierarchyDetailsModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) console.log("success"); }
        );
        //const modalRef = this.modalService.open(HierarchyDetailsModalComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.hierarchy = h;
        //modalRef.componentInstance.client = this.selectedClient;
        //modalRef.result.then((result) => {
        //    console.log(result);
        //},
        //(reason) => {
        //    this.closeResult = `Dismissed`;
        //    console.log(this.closeResult);
        //});
    }

    openExportModal(h: any) {
        alert('MODAL');
    }
    openAddModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient
        }
        const dialogRef = this.dialog.open(HierarchyAddModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) { this.getHierarchy() } }
        );
        
    }

    exportTable() {
        this.hierarchyService.exportTable(this.selectedClient.clientID!).subscribe(
            (event) => {
                let data = event as HttpResponse<Blob>;
                console.log(data);
                const downloadedFile = new Blob([data.body as BlobPart], {
                    type: data.body?.type
                });
                if (downloadedFile.type != "") {
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = "HierarchyList.xlsx";
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            }
        );
    }

    exportTies(h: Hierarchy) {
        const today: Date = new Date();
        const formattedDate: string = today.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '_');

        const fileName = h.clientCompany + "Ties" + formattedDate + ".xlsx";

        this.hierarchyService.exportTiesTable(h.hierarchyId!).subscribe(
            (event) => {
                let data = event as HttpResponse<Blob>;
                console.log(data);
                const downloadedFile = new Blob([data.body as BlobPart], {
                    type: data.body?.type
                });
                if (downloadedFile.type != "") {
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = fileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            }
        );
    }

    exportTiesClient(c: Client) {
        const today: Date = new Date();
        const formattedDate: string = today.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '_');

        const fileName = c.clientName + "Ties" + formattedDate + ".xlsx";

        this.hierarchyService.exportTiesTableByClient(c.clientID!).subscribe(
            (event) => {
                let data = event as HttpResponse<Blob>;
                console.log(data);
                const downloadedFile = new Blob([data.body as BlobPart], {
                    type: data.body?.type
                });
                if (downloadedFile.type != "") {
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = fileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            }
        );
    }
}