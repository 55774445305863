import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Case } from '../../classes/case';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';
import { CaseService } from '../../shared/services/case.service';

@Component({
    selector: 'justification-case',
    templateUrl: './justification-case.component.html',
    styleUrls: ['./justification-case.component.scss']
})
export class JustificationCaseComponent {
    @Input() justification!: JustificationSettings;
    @Input() p!: any;
    cases: Case[] = [];
    caseForm: FormGroup; // Define a FormGroup for the checkboxes
    allChecked = false;

    constructor(
        private fb: FormBuilder,
        private caseService: CaseService,
    ) {
        //console.log('justification case constructor');
        this.caseForm = this.fb.group({
            all: new FormControl(false) // Create a standalone control for 'all'
        });
        this.fetchCases();
    }

    ngOnInit(): void {

    }

    fetchCases() {
        this.caseService.getCases().subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.cases = this.caseService.mapCases(resp);
                //console.log(this.cases);

                // Dynamically create form controls for each case
                this.cases.forEach((caseItem) => {
                    this.caseForm.addControl(`case-${caseItem.pcnCaseTypeID}`, new FormControl(false));
                });
            }
        });
    }

    // Function to handle the "All" checkbox
    toggleAll() {
        this.allChecked = !this.allChecked;
        Object.keys(this.caseForm.controls).forEach((controlName) => {
            this.caseForm.get(controlName)?.setValue(this.allChecked);
        });
    }
}
