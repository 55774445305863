import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Client } from '../../classes/client';
import { DecisionTreeSettings, DeterminationSettings, HierarchySettings } from '../../classes/decisiontreesettings';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';

@Component({
  selector: 'determination-configuration',
  templateUrl: './determination-configuration.component.html',
  styleUrls: ['./determination-configuration.component.scss']
})
export class DeterminationConfigurationComponent implements OnInit {
    @Input() tree!: DecisionTreeSettings;
    client!: Client;
    @Input() p!: any;
    @Input() applyToAll: boolean = true;
    @Input() hierarchyToApply?: HierarchySettings;
    @Input() rower: any;
    

    determinationForm = this.fb.group({
        level: [[''], Validators.required],
    });

    constructor(
        private clientService: ClientService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {
        ///console.log('determination level config constructor');
    }

    ngOnInit(): void {
        //if you have a specific hierarchy, apply the already selected determination types
        if (this.hierarchyToApply) {
            var h = this.findHierarchyToWorkWith();
            if (h.determination.length >= 1) {
                const determinationLevels = h.determination.map(
                    (determination) => determination.determinationLevel!.toString()
                );
                this.determinationForm.controls.level.patchValue(determinationLevels);
            }
        }
    }


    setDetermination() {
        let selectedDetermination: DeterminationSettings[] = [];

        //Prep the dt levels
        const selectedDeterminationValues = this.determinationForm.get('level')?.value as unknown as string[];

        selectedDeterminationValues!.forEach((value: string) => {
            const d = new DeterminationSettings;
            d.determinationLevel = parseInt(value);
            selectedDetermination.push(d);
        });

        if (this.applyToAll) {
            //Add  dt level
            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                this.tree.hierarchy[i].determination = selectedDetermination;
            }
        } else {
            //apply to single client config
            var h = this.findHierarchyToWorkWith();
            h!.determination = selectedDetermination;
        }
        
    }
    findHierarchyToWorkWith(): HierarchySettings {
        var hierarchySettings = this.tree.hierarchy.find(hierarchy => {
            // Customize the conditions based on your matching criteria
            return (
                (hierarchy.hierarchyId === this.hierarchyToApply?.hierarchyId && this.hierarchyToApply?.hierarchyId != undefined) ||
                (hierarchy.pcnReferenceID === this.hierarchyToApply?.pcnReferenceID &&
                    hierarchy.planReferenceID === this.hierarchyToApply?.planReferenceID &&
                    hierarchy.groupReferenceID === this.hierarchyToApply?.groupReferenceID)
            );
        });
        return hierarchySettings!;
    }
}
