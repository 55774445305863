export class QuestionSetTable {
    questionSetHeaderId: number | undefined;
    questionSetName: string | undefined;
    questionSetDescription: string | undefined;
    questionSetTypeName: string | undefined;
    clientID: string | undefined;
    questionSetId: number | undefined;
    majorVersion: number | undefined;
    minorVersion: number | undefined;
    questionSetStatus: number | undefined;
    applicationsEnabled: string | undefined;
    versionItems: QuestionSetTableVersionItem[] | undefined;
}
export class QuestionSetTableVersionItem {
    questionSetHeaderId: number | undefined;
    questionSetName: string | undefined;
    questionSetDescription: string | undefined;
    questionSetTypeName: string | undefined;
    clientID: string | undefined;
    questionSetId: number | undefined;
    majorVersion: number | undefined;
    minorVersion: number | undefined;
    questionSetStatus: number | undefined;
    applicationsEnabled: string | undefined;
}

export class QuestionSetData {
    decisionTreeToQuestionSetID: number | undefined;
    decisionTreeID: number | undefined;
    questionSetHeaderID: number | undefined;
    questionSetID!: number;
    initialOrder: number | undefined;
    questionSetTypeID: number | undefined;
    clientID: string | undefined;
    questionSetName: string | undefined;
    questionSetDescription: string | undefined;
    questionSetData: string | undefined;
    majorVersion: number | undefined;
    minorVersion: number | undefined;
    questionSetStatus: number | undefined;
    comments: string | undefined;
    applicationsEnabledList: number[] | undefined;
    questionSetLanguage?: any[] ;
}