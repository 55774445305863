<section *ngFor="let q of qsc.GetQuestions()">

    <pre style="display:none;">
         {{qsc.GetQuestionSet()!.qsGuid}}-{{q.id}}
         Active status: {{p.activeQuestions.indexOf(qsGuid + '-' + q.id)}}
         IDX: {{p.currentComputedIndex}}
         Current Index: {{q.internalOrder}}
     </pre>
    <!--( (p.activeQuestions.indexOf(qsGuid + '-' + q.id)>=0) || (p.showAll && (p.showSkipped || ((p.inPathQuestions.indexOf(qsGuid + '-' + q.id)>=0) || (p.currentComputedIndex < (qsc.internalOrderOffset + q.sequenceNumber))))) )-->
    <div *ngIf="p.showQuestion(qsGuid, q.id)"
         [attr.data-question-selector]="qsc.GetQuestionSet()!.qsGuid+'-'+q.id"
         [class.inactive-question]="p.activeQuestions.indexOf(qsGuid + '-'+ q.id) ==-1"
         class="inline-question ">

        <div class="skipped-notification"
             *ngIf="(p.currentComputedIndex > (qsc.internalOrderOffset + q.sequenceNumber)) && (p.activeQuestions.indexOf(qsGuid + '-'+ q.id) ==-1)">Skipped</div>


        <span>{{qsc.internalOrderOffset + q.internalOrder}}. {{q.text}}</span>
        <div class="jump-to-question" (click)="qsc.jumpToQuestion(q.id);">Go to Question</div>

        <div *ngIf="!qsc.ValidateQuestionInTree(q)" class="i-am-error-inline">
            <mat-icon>info</mat-icon>
            <div class="message-inline">The question is not part of the flow, please connect it to other questions.</div>
        </div>

        <div *ngIf="qsc.GetQuestionTypeFromQuestion(q) == 'Select'">
            <section *ngIf="qsc.GetSelectType(q).isMultiple" class="answer-choice-group">
                <div *ngFor="let ac of qsc.GetSelectType(q).choice | sortAnswerBySequence" class="answer">
                    <div [class.answer-choice-error-inline]="q.defaultNextQuestionID == '' && ac.nextQuestionID == 'Default'">
                        <mat-checkbox [value]="ac.id" [attr.data-next-id]="ac.nextQuestionID"
                                      (click)="checkChanges(qsc.GetQuestionSet()!.qsGuid+'-'+q.id, ac.nextQuestionID)">
                            {{ac.choiceText}}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="q.defaultNextQuestionID == '' && ac.nextQuestionID == 'Default'" class="selected-answer-error-inline">
                        <mat-icon>info</mat-icon>
                        <div class="message-inline" style="font-size:12px; line-height:16px;">Add a path to this answer.</div>
                    </div>
                </div>
            </section>
            <section *ngIf="!qsc.GetSelectType(q).isMultiple">
                <mat-radio-group class="answer-choice-group">
                    <div *ngFor="let ac of qsc.GetSelectType(q).choice | sortAnswerBySequence" class="answer">
                        <div [class.answer-choice-error-inline]="q.defaultNextQuestionID == '' && ac.nextQuestionID == 'Default'">
                            <mat-radio-button [value]="ac.id" (click)="requestEvaluate()">
                                {{ac.choiceText}}<br />
                            </mat-radio-button>
                        </div>
                        <div *ngIf="q.defaultNextQuestionID == '' && ac.nextQuestionID == 'Default'" class="selected-answer-error-inline">
                            <mat-icon>info</mat-icon>
                            <div class="message-inline" style="font-size:12px; line-height:16px;">Add a path to this answer.</div>
                        </div>
                    </div>
                </mat-radio-group>
            </section>

            <button *ngIf="qsc.GetSelectType(q).isMultiple && p.activeQuestions.indexOf(qsGuid + '-'+ q.id) >=0"
                    mat-raised-button class="done-button"
                    (click)="requestEvaluate()">
                Done
            </button>

        </div>
        <div *ngIf="qsc.GetQuestionTypeFromQuestion(q) == 'FreeText'" class="answer">
            <mat-form-field appearance="outline" shape="large" class="full-width" color="accent">
                <input matInput type="text" placeholder="abc123" />
            </mat-form-field>
            <div *ngIf="q.defaultNextQuestionID == ''" class="selected-answer-error-inline">
                <mat-icon>info</mat-icon>
                <div class="message-inline" style="font-size:12px; line-height:16px;">Add a path to this answer.</div>
            </div>
            <button mat-raised-button class="done-button" (click)="requestEvaluate()"
                    *ngIf="p.activeQuestions.indexOf(qsGuid + '-'+ q.id) >=0">
                Done
            </button>
            <br />
        </div>
        <div *ngIf="qsc.GetQuestionTypeFromQuestion(q) == 'DateTime'" class="answer">
            <mat-form-field appearance="outline" shape="large" class="full-width" color="accent">
                <input matInput type="date" placeholder="1/1/2024" />
            </mat-form-field>
            <div *ngIf="q.defaultNextQuestionID == ''" class="selected-answer-error-inline">
                <mat-icon>info</mat-icon>
                <div class="message-inline" style="font-size:12px; line-height:16px;">Add a path to this answer.</div>
            </div>
            <button mat-raised-button class="done-button" (click)="requestEvaluate()"
                    *ngIf="p.activeQuestions.indexOf(qsGuid + '-'+ q.id) >=0">
                Done
            </button>
            <br />
        </div>
        <div *ngIf="qsc.GetQuestionTypeFromQuestion(q) == 'Numeric'" class="answer">
            <mat-form-field appearance="outline" shape="large" class="full-width" color="accent">
                <input matInput type="number" placeholder="1234" />
            </mat-form-field>
            <div *ngIf="q.defaultNextQuestionID == ''" class="selected-answer-error-inline">
                <mat-icon>info</mat-icon>
                <div class="message-inline" style="font-size:12px; line-height:16px;">Add a path to this answer.</div>
            </div>
            <button mat-raised-button class="done-button" (click)="requestEvaluate()"
                    *ngIf="p.activeQuestions.indexOf(qsGuid + '-'+ q.id) >=0">
                Done
            </button>
            <br />
        </div>
    </div>
</section>
