import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-auth-period',
  templateUrl: './auth-period.component.html',
  styleUrls: ['./auth-period.component.scss']
})
export class AuthPeriodComponent {
    customPeriod: { value: string, unit: string } = { value: '', unit: '' };

    constructor(
        public dialogRef: MatDialogRef<AuthPeriodComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { this.customPeriod.unit = 'Hours' }

    addPeriod(): void {
        this.dialogRef.close(this.customPeriod);
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
