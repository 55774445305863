
<h2 mat-dialog-title>Action</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>


<mat-dialog-content>
    <form id="actionResultForm" [formGroup]="actionResultForm" (ngSubmit)="save()">
        <div class="form-group">
            <label for="actionName">Name:</label>
            <input type="text" class="full-width" id="actionName" formControlName="actionName" placeholder="Enter a name"
                   value="actionName" />
        </div>
        <div class="form-group">
            <label for="label">Action Type:</label>
            <select class="full-width" id="actionType" formControlName="actionType">
                <option>Approve</option>
                <option>Deny</option>
                <option>Other</option>
                <option>Missing Info</option>
            </select>
        </div>
        <div class="form-group">
            <label for="actionName">routing:</label>
            <input type="text" class="full-width" id="routing" formControlName="routing" placeholder="">
        </div>
        <div class="form-group">
            <label for="actionName">comments:</label>
            <input type="text" class="full-width" id="comments" formControlName="comments" placeholder="">
        </div>
        <div class="form-group">
            <label for="actionName">Patient Notification Text:</label>
            <input type="text" class="full-width" id="actionTextPatient" formControlName="actionTextPatient" placeholder="">
        </div>
        <div class="form-group">
            <label for="actionName">Pharmacy Notification Text:</label>
            <input type="text" class="full-width" id="actionTextPharmacy" formControlName="actionTextPharmacy" placeholder="">
        </div>
        <div class="form-group">
            <label for="actionName">Provider Notification Text:</label>
            <input type="text" class="full-width" id="actionTextProvider" formControlName="actionTextProvider" placeholder="">
        </div>

        {{actionResultForm.valid}}
    </form>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="actionResultForm" [disabled]="!actionResultForm.valid" 
            mat-raised-button color="primary" (click)="save">
        Okay
    </button>
</mat-dialog-actions>
