<div *ngIf="questionSetControl.GetQuestionSet() !== undefined"
     [class.catalogQuestion]="questionSetControl.questionSetTypeId != 0 && questionSetControl.inCatalog != true"
     >
    <div class="questionSetHeader">
        <h2>{{questionSetControl.getTitle()}} </h2>
    </div>
    <div *ngFor="let q of questionSetControl.GetQuestions() | sortByInternalOrder">
        <div *ngFor="let a of q.action">
            <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'|| a.actionType == '2Rph'">
                <app-edit-question-decision-item [question]="q"
                                                 [questionSetControl]="questionSetControl"
                                                 [action]="a"
                                                 >
                </app-edit-question-decision-item>
            </div>
        </div>
        <div *ngIf="questionSetControl.GetQuestionTypeFromQuestion(q) == 'Select'">
            <div *ngFor="let answerChoice of questionSetControl.GetSelectType(q).choice">
                <div *ngFor="let a of answerChoice.action">
                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval' ||a.actionType == '2Rph'">
                        <app-edit-question-decision-item [question]="q"
                                                         [questionSetControl]="questionSetControl"
                                                         [action]="a"
                                                         [answerChoice]="answerChoice"
                                                         >
                        </app-edit-question-decision-item>
                    </div>
                </div>
            </div>

            <div *ngFor="let nqc of questionSetControl.GetSelectType(q).nextQuestionCondition">
                <div *ngFor="let a of  nqc.comparison.action">
                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'|| a.actionType == '2Rph'">
                        <app-edit-question-decision-item [question]="q"
                                                         [questionSetControl]="questionSetControl"
                                                         [action]="a"
                                                         
                                                         [comparison]="nqc.comparison"
                                                         >
                        </app-edit-question-decision-item>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="questionSetControl.GetQuestionTypeFromQuestion(q) == 'Date'">
            <div *ngFor="let nqc of questionSetControl.GetDateType(q).nextQuestionCondition">
                <div *ngFor="let a of  nqc.comparison.action">
                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'">
                        <app-edit-question-decision-item [question]="q"
                                                         [questionSetControl]="questionSetControl"
                                                         [action]="a"
                                                         
                                                         [comparison]="nqc.comparison"
                                                         >
                        </app-edit-question-decision-item>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="questionSetControl.GetQuestionTypeFromQuestion(q) == 'Numeric'">
            <div *ngFor="let nqc of questionSetControl.GetNumericType(q).nextQuestionCondition">
                <div *ngFor="let a of  nqc.comparison.action">
                    <div *ngIf="a.actionType == 'Approve' || a.actionType == 'Deny' || a.actionType == 'RFI' ||a.actionType == 'Partial Approval'|| a.actionType == '2Rph'">
                        <app-edit-question-decision-item [question]="q"
                                                         [questionSetControl]="questionSetControl"
                                                         [action]="a"
                                                         
                                                         [comparison]="nqc.comparison"
                                                         >
                        </app-edit-question-decision-item>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
