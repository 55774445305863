import { Component, Input, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Question, QuestionSet } from '../../../classes/questionset';
import { QuestionSetControl } from '../../../shared/question-set.control';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.scss']
})
export class AddQuestionModalComponent {
    @Input() questionSetControl!: QuestionSetControl;
    @Input() p: any | undefined;

    addNewQuestionForm = this.fb.group({
        label: ['', Validators.required],
        selectQuestionType: ['', Validators.required]
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<AddQuestionModalComponent>,
        private fb: FormBuilder
    ) {
        if (data !== undefined) {
            this.questionSetControl = data.questionSetControl;
            this.p = data.p;
        }
    }
    close() {
        this.dialogRef.close("0");
    }
    AddQuestion() {
        //console.log(this.addNewQuestionForm);
        let data: any = {};
        data.text = data.label = this.addNewQuestionForm.value.label;
        data.id = this.questionSetControl.GetNextQuestionID();
        var qs = (<QuestionSet>this.questionSetControl.GetQuestionSet());
        console.log("CQS", qs);
        var length = qs.questions.length;
        data.sequenceNumber = length + 1;
        data.level = 1;
        data.QuestionComment = "";
        data.defaultNextQuestionID = "End";
        
        if (this.addNewQuestionForm.value.selectQuestionType == "Select") {
            data.questionType = { isMultiple: false };
        } else if (this.addNewQuestionForm.value.selectQuestionType == "Multi-Select") {
            data.questionType = { isMultiple: true };
        } else if (this.addNewQuestionForm.value.selectQuestionType == "Date") {
            data.questionType = { isDateTimeRequired: false };
        } else if (this.addNewQuestionForm.value.selectQuestionType == "DateTime") {
            data.questionType = { isDateTimeRequired: true };
        } else if (this.addNewQuestionForm.value.selectQuestionType == "Text") {
            data.questionType = { isFreeText: true };
        } else if (this.addNewQuestionForm.value.selectQuestionType == "Numeric") {
            data.questionType = { isNumeric: true };
        }
        let nq = new Question(data);
        console.log(nq)
        this.questionSetControl.GetQuestionSet()?.questions.push(nq);
        // Include Ties as necessary
        if (this.p !== undefined && this.p != null) {
            this.dialogRef.close("1");
            this.p.SetNextId(nq.id);
            //console.log("Attemping to close the modal ")
        }

        this.questionSetControl.CalculateQuestionDetail();
    }
}
