import { ChangeDetectorRef, Component, Input } from '@angular/core';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionResult, NextQuestionCondition, Question, QuestionTypeDate, QuestionTypeNumeric, QuestionTypeSelect, RangeComparison, SingleComparison } from '../../../classes/questionset';
import { QuestionSetControl, QuestionSetRouting } from '../../../shared/question-set.control';
import { ActionresultModalComponent } from '../actionresult-modal/actionresult-modal.component';
import { RoutingModalComponent } from '../routing-modal/routing-modal.component';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditQuestionDecisionJustificationModalComponent } from '../../../decision-tree/edit-question-decision-justification-modal/edit-question-decision-justification-modal.component';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-nextquestioncondition-template',
  templateUrl: './nextquestioncondition-template.component.html',
  styleUrls: ['./nextquestioncondition-template.component.scss']
})
export class NextquestionconditionTemplateComponent {
    @Input() nextQuestionCondition!: NextQuestionCondition;
    @Input() comparisonSource!: string;
    @Input() timerequired: boolean = false;
    @Input() question!: Question;
    @Input() questionSetControl!: QuestionSetControl;
    @Input() orderIndex!: number;
    @Input() selectableCount: number | undefined;
    //@Input() decisionTreeheaderID: number | undefined;
    @Input() questionSetRoutingList?: QuestionSetRouting[];
    selectableNumbers: number[] = [];
    
    
    defaultStartDate: Date = new Date();
    defaultEndDate: Date = new Date();
    
    
    
    closeResult: string = "";
    operator: string = "";
    constructor(
        private dialog: MatDialog,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
    ) {
    }
    ngOnInit() {
        if (this.GetComparisonType() == "Single") {
            this.operator = this.GetComparisonDetail().comparisonOperator ?? "";
            if (this.getComparisonInputTypeLabel() == "Date" && this.GetComparisonDetail().comparisonValue != "") {
                this.defaultStartDate = new Date(this.GetComparisonDetail().comparisonValue);
            }
        } else {
            this.operator = "B";
            if (this.getComparisonInputTypeLabel() == "Date" ){
                this.defaultStartDate = new Date(this.GetComparisonDetail().lowerBoundComparisonValue);
                this.defaultEndDate = new Date(this.GetComparisonDetail().upperBoundComparisonValue);
            }
        }
        
        this.selectableNumbers = Array((this.selectableCount ?? 0) + 1 ).fill(0).map((x, i) => i);
    }
    ngOnChanges() {
        //console.log("Changes");
        this.selectableNumbers = Array((this.selectableCount ?? 0) + 1).fill(0).map((x, i) => i);
    }
    updateCompare(e: any) {
        let newVal: string = this.defaultStartDate.toISOString();
        //console.log(newVal);
        this.GetComparisonDetail().comparisonValue = newVal;
    }
    updateLower(e: any) {
        let newVal: string = this.defaultStartDate.toISOString();
        //console.log(newVal);
        this.GetComparisonDetail().lowerBoundComparisonValue = newVal;
    }
    updateUpper(e: any) {
        if (this.defaultEndDate != null) {
            let newVal: string = this.defaultEndDate.toISOString();
            //console.log(newVal);
            this.GetComparisonDetail().upperBoundComparisonValue = newVal;
        }
    }
    changeOptionValue(e: any) {
        console.log("select option change event", this.operator, e.target.value, this.nextQuestionCondition);
        let newVal: string = e.target.value;
        if (newVal == "B") {
            if (this.nextQuestionCondition.comparison instanceof SingleComparison) {
                let data = {
                    lowerBoundComparisonValue :"",
                    lowerBoundComparisonOperator : "LE",
                    upperBoundComparisonValue : "",
                    upperBoundComparisonOperator : "GE"
                };
                this.nextQuestionCondition.comparison = new RangeComparison(data);
                this.nextQuestionCondition.type = "Range";
            }
        } else {
            if (this.nextQuestionCondition.comparison instanceof SingleComparison) {
                this.GetComparisonDetail().comparisonOperator = newVal;
            } else {
                let data = {
                    comparisonOperator: newVal,
                    comparisonValue: ""
                };
                this.nextQuestionCondition.comparison = new SingleComparison(data);
                this.nextQuestionCondition.type = "Single";
            }
        }
        console.log("select option change event", this.operator, e.target.value, this.nextQuestionCondition);
    }

    GetComparisonType() {
        var type = "";
        if (this.nextQuestionCondition.comparison instanceof RangeComparison) {
            type = "Range";
        } else if (this.nextQuestionCondition.comparison instanceof SingleComparison) {
            type = "Single";
        }
        return type;
    }
    GetComparisonDetail() :any{
        if (this.GetComparisonType() == "Single") {
            return <SingleComparison>this.nextQuestionCondition.comparison
        } else {
            return <RangeComparison>this.nextQuestionCondition.comparison
        }
    }
    
    remove() {
        //var nqcArray: NextQuestionCondition[];
        //console.log("Remove condi", this.nextQuestionCondition);
        if (this.question.questionType instanceof QuestionTypeSelect) {
            (<QuestionTypeSelect>this.question.questionType).nextQuestionCondition
                = (<QuestionTypeSelect>this.question.questionType).nextQuestionCondition.filter(test => {
                    return test != this.nextQuestionCondition;
                });
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            (<QuestionTypeDate>this.question.questionType).nextQuestionCondition
                = (<QuestionTypeDate>this.question.questionType).nextQuestionCondition.filter(test => {
                    return test != this.nextQuestionCondition;
                });
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            (<QuestionTypeNumeric>this.question.questionType).nextQuestionCondition
                = (<QuestionTypeNumeric>this.question.questionType).nextQuestionCondition.filter(test => {
                    return test != this.nextQuestionCondition;
                });
        }
    }
    RemoveActionResult(ar: ActionResult) {
        this.nextQuestionCondition.comparison.action = this.nextQuestionCondition.comparison.action.filter(f => { return ar != f });
    }
    getComparisonInputTypeLabel() {
        return this.timerequired ? "Date Time" : this.comparisonSource;
    }
    getComparisonInputType() {
        return this.timerequired ? "datetime-local" : this.comparisonSource;
    }
    GetNextQuestionLabel(): string {
        let label = "";
        let nextId = this.nextQuestionCondition.comparison.nextQuestionID;
        if (nextId == "Default") {
            nextId = this.question.defaultNextQuestionID;
        }
        if (nextId == "" || nextId == null) { return "Select Path"; }
        if (nextId == "" || nextId == null) {
            label = "Select Path";
        }
        else if (nextId.startsWith("next-")) {

            var qsGuid = nextId.substr(5);
            var qsr = this.questionSetRoutingList?.filter(f => f.qsGuid == qsGuid);
            if (qsr === undefined || qsr == null || qsr!.length > 1 || qsr!.length == 0) {
                return "SET ERROR " + qsGuid;
            }

            //let v = "+" + qsr![0].title;
            //if (v.length > 150) {
            //    v = v.substring(0, 147) + "...";
            //}
            //return (qsr![0].internalOrderOffset + 1) + ": " + v;

            label = "Question " + (qsr![0].internalOrderOffset + 1);
        }
        else if (nextId == "End") {
            label = "Next Question Set";
        }
        if (nextId == "Final") {
            label = "End Decision Tree";

        } else {
            var q = this.questionSetControl.GetQuestionByID(nextId);
            if (q != null) {
                //var v: string;
                //v = q.text;
                //if (v.length > 25) {
                //    v = v.substring(0, 22) + "...";
                //}
                //label = (q.internalOrder + this.questionSetControl.internalOrderOffset) + ": " + v;
                label = "Question " + (q.internalOrder + this.questionSetControl.internalOrderOffset);
            }
        }
        return label;
    }
    jumpToNQCDecision() {
        //TODO:
        alert("jumpToNQCDecision() pending")
    }
    openAddDecisionJustificationModal(a:ActionResult) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            questionSetControl: this.questionSetControl,
            question: this.question,
            action: a,
            answerChoice: undefined,
            comparison: this.nextQuestionCondition.comparison
        }
        const dialogRef = this.dialog.open(EditQuestionDecisionJustificationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                /*console.log(data)*/
                this.cdr.detectChanges();
            }
        );
    }
    openRoutingModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "routing-dialog";
        dialogConfig.data = {
            nextId: this.nextQuestionCondition.comparison.nextQuestionID,
            currentQuestionID: this.question.id,
            p: this,
            showDefault: true,
            questionSetRoutingList: this.questionSetRoutingList
        }
        const dialogRef = this.dialog.open(RoutingModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {  }
        );
    }
    openActionResultModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: this
        }
        const dialogRef = this.dialog.open(ActionresultModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
        //const modalRef = this.modalService.open(ActionresultModalComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.p = this;
        //modalRef.result.then((result) => { this.closeResult = 'Closed'; }, (reason) => { this.closeResult = `Dismissed`; });
    }
    AddActionResult(ar: ActionResult) {
        this.nextQuestionCondition.comparison.action.push(ar);
    }
    SetNextId(id: string) {
        this.nextQuestionCondition.comparison.nextQuestionID = id;
        this.questionSetControl.CalculateQuestionDetail();
        this.cdr.detectChanges();
    }
    isValid() {
        var dataType = this.comparisonSource == "Numeric" || this.comparisonSource == "Select" ? "Numeric" : "Date"
        return this.questionSetControl.GetNextQuestionConditionErrormessage(this.nextQuestionCondition, dataType) == "";
    }
}
