
<div class="usage-dialog"></div>
<mat-dialog-content style="width:800px;text-align:center;">
    <div style="font-weight:800; font-size:2em;">Decision Tree Usage</div>
    <hr />
    <div *ngIf="!loaded">
        Loading ...
    </div>
    <div *ngIf="loaded && usageResults.length == 0">
        <div style="font-weight:800;">Not linked to any trees</div>
    </div>
    <div *ngIf="loaded && usageResults.length > 0">
        <table style="width:100%;max-height:75%;overflow-y:auto">
            <thead>
                <tr>
                    <th>Decision Tree</th>
                    <th>Status</th>
                    <th>Question Set Version</th>
                    <th></th>
                </tr>
            </thead>
            <tbody *ngFor="let i of usageResults">
                <tr>
                    <th>{{i.decisionTreeName}} v{{i.majorVersion}}</th>
                    <th>
                        <span [class]="'status-pill ' + (i.decisionTreeStatus | statusClass: i.startDate : i.endDate )">
                            {{ i.decisionTreeStatus | status: i.startDate: i.endDate}}
                        </span>
                    </th>
                    <th>
                        Version {{i.questionSetMajorVersion}}
                    </th>
                    <th>
                        <a href="/decisiontree/editquestions?dt={{i.decisionTreeID}}" target="_blank">
                            <mat-icon>open_in_new</mat-icon>
                        </a>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
    <hr />
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="close()" style="border-radius:24px;">
        Close
    </button>

</mat-dialog-actions>