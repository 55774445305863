export enum ApiPaths  {
    GetCurrentUserInfo = '/userinfo/getcurrentuserinfo',
    GetAdditionComments = '/additionalcomment/getactiveadditionalcomments',
    DeactivateAdditionalComment = '/additionalcomment/deactivateadditionalcomment',

    CopyHierarchy = '/hierarchy/copyhierarchy',
    GetHierarchyTableViewList = '/hierarchy/gettableview',
    GetHierarchyToDecisionTreeTableViewList = '/hierarchy/GetHierarchyToDecisionTreeTableView',
    AddHierarchy = '/hierarchy/addhierarchy',
    AddHierarchyToDecisionTree = '/hierarchy/addhierarchytodecisiontree',
    TerminateHierarchyToDecisionTree = '/hierarchy/terminatehierarchytodecisiontree',

    CopyDecisionTree = '/decisiontree/copydecisiontree',
    GetDecisionTreeTableViewList = '/decisiontree/gettableview',
    AddDecisionTree = '/decisiontree/adddecisiontree',
    AddGuidedDecisionTree = '/decisiontree/adddecisiontreesettings',
    UpdateGuidedDecisionTree = '/decisiontree/updatedecisiontreesettings',
    ApproveDecisionTree = '/decisiontree/approve',
    ImportDecisionTree = '/decisiontree/import',
    SaveDecisionTree = '/decisiontree/savedecisiontree',
    GetDecisionTreeByID = '/decisiontree/getdecisiontreebyid',
    GetDecisionTreeHeaderByID = '/decisiontree/getdecisiontreeheaderbyid',
    GetDecisionTreeOverrides = '/decisiontree/getdecisiontreeoverrides',
    UpdateDecisionTreeOverrides = '/decisiontree/updatedecisiontreeoverrides',
    GetApplicationList = '/questionset/getapplicationlist',
    GetDecisionTreeDataByID = '/decisiontree/getdecisiontreedatabyid',

    GetDecisionTreeHistory = '/decisiontree/GetDecisionTreeHistory',
    GetDecisionTreeVersionHistory = '/decisiontree/GetDecisionTreeVersionHistory',

    GetQuestionSetByDecisionTreeID = '/decisiontree/getquestionsetsbydecisiontreeid',
    GetHierarchyTiesToDecisionTreeByID = '/decisiontree/gethierarchytodecisiontreetableviewbydecisiontreeid',
    GetCompleteDecisionTreeQuesitonSet = '/decisiontree/getcompletedecisiontreequestionset',
    GetDecisionTreeJsonByID = '/decisiontree/export',
    GetPCNCaseType = '/hierarchy/getpcncasetype',
    GetPCNCaseSubType = '/hierarchy/getpcncasesubtype',
    GetDecisionTreeSettingsByID = '/decisiontree/getdecisiontreesettings',

    CopyQuestionSet = '/questionset/copyquestionset',
    GetQuestionSetTableViewList = '/questionset/gettableview',
    GetQuestionSetData = '/questionset/getdatabyid',
    GetQuestionSetEditDetail = '/questionset/GetEditDetails',
    GetLanguageTextGUID = '/questionset/GetLanguageTextGUID',
    GetQuestionSetUsage = '/questionset/usage',

    ImportQuestionSet = '/questionset/import',
    SaveQuestionSet = '/questionset/save',
    ApproveQuestionSet = '/questionset/approve',
    AddQuestionSet = '/questionset/add',
    GetQuestionSetJsonByID = '/questionset/export',

    GetCodedReferences = '/questionset/getcodedreferencelist',

    GetDecisionTreeRXDM = '/rxdm/getdecisiontreelist',

    getSolrQuery = '/solr/solrquery',

    ExportHierarchyTableViewList = '/Reporting/HierarchyList',
    ExportDecisionTreeTableViewList = '/Reporting/DecisionTreeList',
    ExportQuestionSetTableViewList = '/Reporting/QuestionSetList',
    ExportHierarchyTiesTableViewList = '/Reporting/HierarchyToDecisionTreeList',
    ExportHierarchyTiesTableViewByClientList = '/Reporting/HierarchyToDecisionTreeTableListByClientId',
    GetQuestionSetPDFByID = '/Reporting/GenerateQuestionSetPDFFromID',
    GetDecisionTreePDFByID = '/Reporting/GenerateDecisionTreePDFFromID',

    GetSolrDrugs = '/drugs/query',

    //GetClients = '/hierarchy/getclientsforuser',
    GetPCNByClient = '/hierarchy/getpcnlistbyclientid',
    GetPlanBenefitByPCN = '/hierarchy/getplanbenefitlistbypcn',
    GetGroupBenefitByPlanAndPCN = '/hierarchy/getgroupbenefitlistbypcnandplans',
    SubmitForApproval = "/decisiontree/SubmitForApproval",
    addDecisionTreeForEdit = "/decisiontree/addDecisionTreeForEdit",
    UnsetApprovalRequest = "/decisiontree/UnsetApprovalSubmit",
    DeleteUnapprovedTree = "/decisiontree/DeleteUnapprovedTree",

    GetJustificationTemplateDashboard = "/justification/getjustificationtemplatetableviewlist",
    RetrieveJustifications = "/justification/retreivejustificationlist",
    SearchJustiificationReason = "/justification/searchjustificationreason",
    GetJustificationTemplate = "/justification/getjustificationtemplatedto",
    GetJustificationTemplateByID = "/justification/getjustificationtemplatebyid",
    SaveJustification = "/justification/save",
    UpdateJustification = "/justification/update",
    SaveJustificationToCaseType = "/justification/savejustificationtemplatetocasetype",
    DeleteJustificationFromCaseType = "/justification/deletejustificationtemplatetocasetype",
    SaveJustificationToDeterminationLevel = "/justification/savejustificationtemplatetodeterminationlevel",
    DeleteJustificationFromDeterminationLevel = "/justification/deletejustificationtemplatetodeterminationlevel",
    DeleteJustificationTemplate = "/justification/deletejustificationtemplate",
    
    OverrideSearchRXP = 'lookup/getOverrides' //GET
};