<h2 mat-dialog-title style="min-width:800px;">Add Justification</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<app-edit-question-decision-item [question]="question"
                                 [questionSetControl]="questionSetControl"
                                 [action]="action"
                                 [answerChoice]="answerChoice"
                                 [comparison]="comparison"
                                 class="edit-question-decision-content"
                                 >
</app-edit-question-decision-item>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
</mat-dialog-actions>