<div class="table-dashboard">

    <section>
        <form id="testForm" class="form-container" [formGroup]="testForm" (ngSubmit)="runTest()">
            <section class="column-12">
                <mat-form-field appearance="outline">
                    <mat-label>Client</mat-label>
                    <mat-select formControlName="client" name="selectedClient" (selectionChange)="onSelectClient()">
                        <mat-option *ngFor="let c of clientsList" [value]="c.clientID">
                            {{c.clientName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </section>
            <section class="column-12">
                <div class="column-4">
                    <mat-form-field appearance="outline">
                        <mat-label>PCN</mat-label>
                        <input matInput placeholder="Enter a PCN" formControlName="pcn">
                    </mat-form-field>
                </div>
                <div class="column-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Plan</mat-label>
                        <input matInput placeholder="Enter a Plan" formControlName="plan">
                    </mat-form-field>
                </div>
                <div class="column-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Group</mat-label>
                        <input matInput placeholder="Enter a Group" formControlName="group">
                    </mat-form-field>
                </div>
            </section>
            <section>
                <div class="column-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Type</mat-label>
                        <input matInput placeholder="Enter a Type" formControlName="type">
                    </mat-form-field>
                </div>
                <div class="column-6">
                    <mat-form-field appearance="outline">
                        <mat-label>SubType</mat-label>
                        <input matInput placeholder="Enter a Subtype" formControlName="subtype">
                    </mat-form-field>
                </div>
            </section>
            <section>
                <div class="column-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Drug Qualifier</mat-label>
                        <mat-select formControlName="drugqualifier">
                            <mat-option value="GPI">GPI</mat-option>
                            <mat-option value="NDC">NDC</mat-option>
                            <mat-option value="DDID">DDID</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="column-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Drug ID</mat-label>
                        <input matInput placeholder="Enter a Drug ID" formControlName="drug">
                    </mat-form-field>
                </div>
            </section>
            <section class="column-6">
                <mat-form-field appearance="outline">
                    <mat-label>Request Date</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="requestDate"
                           data-date-format='YYYY-MM-DD'>
                    <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            </section>
            <button type="submit" mat-button form="testForm" [disabled]="!testForm.valid" class="column-6">
                Submit
            </button>
        </form>
    </section>
    <mat-divider></mat-divider>
    <section>
        <div class="mat-elevation-z8" *ngIf="!noResults">
            <table mat-table [dataSource]="dataSource" matSort>

                <!-- Priority Column -->
                <ng-container matColumnDef="priority" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Priority </th>
                    <td mat-cell *matCellDef="let row"> {{row.priority}} </td>
                </ng-container>

                <!--Hierarchy Column-->
                <ng-container matColumnDef="hierarchy" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Hierarchy </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.clientName}} >
                        {{row.pcn}} >
                        {{row.planDescription}} >
                        {{row.groupName}}
                    </td>
                </ng-container>

                <!--Decision Tree Column-->
                <ng-container matColumnDef="tree" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Tree </th>
                    <td mat-cell *matCellDef="let row"> <button mat-button color="accent" class="table-row-button" (click)="openEdit(row.decisionTreeID)">{{row.decisionTreeName}}</button> </td>
                </ng-container>

                <!--start Column-->
                <ng-container matColumnDef="start" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start </th>
                    <td mat-cell *matCellDef="let row"> {{row.startDate  | date: 'dd/MM/YYYY'}} </td>
                </ng-container>

                <!--end Column-->
                <ng-container matColumnDef="end" class="dash-container">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> End </th>
                    <td mat-cell *matCellDef="let row"> {{row.endDate  | date: 'dd/MM/YYYY'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No results</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of hierarchies"></mat-paginator>
        </div>
    </section>
</div>

