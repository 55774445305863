import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from '../../classes/client';
import { DecisionTreeSettings, HierarchySettings } from '../../classes/decisiontreesettings';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';

@Component({
  selector: 'rxdm-configuration',
  templateUrl: './rxdm-configuration.component.html',
    styleUrls: ['./rxdm-configuration.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class RxdmConfigurationComponent implements OnInit {
    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    displayedColumns: string[] = ['client', 'lob']; //, 'start', 'end'
    columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
    dataSource: MatTableDataSource<HierarchySettings> = new MatTableDataSource<HierarchySettings>([]);
    expandedRow?: HierarchySettings | null;

    @Input() tree!: DecisionTreeSettings;
    client!: Client;
    @Input() p!: any;

    constructor (
        private clientService: ClientService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        //console.log('rxdm config constructor');
    }

    ngOnInit(): void {
        this.refreshDatasource();
    }

    refreshDatasource() {
        this.client = this.tree.client;
        this.dataSource = new MatTableDataSource(this.tree.hierarchy);
        //console.log("ds", this.dataSource.data)
        if (this.dataSource.data.length == 1) {
            this.expandedRow = this.dataSource.data[0];
        }
    }
}
