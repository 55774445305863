import { Component, Input } from '@angular/core';
import { DecisionTreeSettings } from '../../classes/decisiontreesettings';
import { AuthorizationPeriod, DecisionTreeOverride, OverrideCode } from '../../classes/overrides';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthPeriodComponent } from './auth-period/auth-period.component';
import { DecisionTreeService } from '../decision-tree.service';
import { DeleteConfirmDialogComponent } from '../../shared/delete-confirm-dialog/delete-confirm-dialog.component';
import { Override } from '../../classes/questionset';

declare const overrideCodeStaticList: any;

@Component({
    selector: 'app-override-list',
    templateUrl: './override-list.component.html',
    styleUrls: ['./override-list.component.scss']
})
export class OverrideListComponent {
    @Input() settings: DecisionTreeSettings | undefined;
    @Input() decisionTreeOverrides: DecisionTreeOverride[] = [];
    public errorMessages: {index:number, message:string}[] = [];
    overrideCodeList: OverrideCode[] = [];
    showInputError: string = '';
    possibleAuthorizationPeriods: { label: string, value: string, unit: string }[] = [
        { label: '7 Days', value: '7', unit: 'Days' },
        { label: '30 Days', value: '30', unit: "Days" },
        { label: '12 Weeks', value: '12', unit: "Weeks" },
        { label: '1 Year', value: '1', unit: "Year" },
        { label: 'End of Year', value: '-1', unit: "End of Year" }
    ];
    constructor(
        //private cdr: ChangeDetectorRef,
        private decisionTreeService: DecisionTreeService,
        private dialog: MatDialog
    ) {

    }
    ngOnInit() {
        this.decisionTreeService.fetchRXPOverrideResults()
            .subscribe(
                resp => {
                    if (resp.body) {
                        resp.body.forEach((fe: any) => {
                            this.overrideCodeList.push(new OverrideCode(fe))
                        });
                    }
                },
                e => {
                    //debugger;
                    overrideCodeStaticList
                        .forEach((fe: any) => {
                            //console.log(fe.Code, fe.Reason)
                            this.overrideCodeList.push(new OverrideCode(fe))
                        });
                }
            );
            
    }
    addNewOverride() {
        this.decisionTreeOverrides.push(new DecisionTreeOverride({}));
        console.log(this.decisionTreeOverrides);
    }
    openCustomPeriodModal(i: DecisionTreeOverride) {
        var temp = i.authPeriodLabel;
        i.authPeriodLabel = "";
        var selectedCustomPeriod: { value: string, unit: string } = { value: '', unit: '' };
        const dialogRef = this.dialog.open(AuthPeriodComponent, {
            width: '300px',
            panelClass: "custom-period",
            data: { customPeriod: selectedCustomPeriod },
        });
        
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                i.AuthorizationPeriod = new AuthorizationPeriod(result);
                i.authPeriodLabel = 'Custom';
            } else {
                i.authPeriodLabel = temp;
            }
        });
    }
    remove(i: DecisionTreeOverride) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "delete-confirm-dialog";
        dialogConfig.data = {
            title: "Are you sure",
            dialogText: "Are you sure you want to delete this override?",
            submitButtonText: "Delete",
            showComment: false
        }
        const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != "0") {
                    const index = this.decisionTreeOverrides.indexOf(i);
                    if (index >= 0) {
                        this.decisionTreeOverrides.splice(index, 1);
                    }
                }
            }
        );
    }
    authPeriodChange(event: any, i: DecisionTreeOverride) {
        //console.log("EV", event.value)
        //debugger;
        var selected = this.possibleAuthorizationPeriods.filter(
            (f: any) => {
                //console.log(f.label + " " + (f.label == event.value) + " " + event.value)
                return f.label == event.value
            }
        );
        if (selected.length == 1) {
            i.AuthorizationPeriod.Unit = selected[0].unit;
            i.AuthorizationPeriod.Value = selected[0].value;
        }
        //console.log(i.AuthorizationPeriod);
        //debugger;
    }
    validateOverrides() {
        document.querySelectorAll(".show-override-error-indication").forEach(fe => { fe.classList.remove("show-override-error-indication"); })
        this.errorMessages = [];
        for (var i = 0; i < this.decisionTreeOverrides.length; i++) { 
            var o = this.decisionTreeOverrides[i];
            if (o.OverrideDescription == "") {
                var msg = "Please enter a description for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
            }
            if (!o.AllDrugs && o.DrugDetails.length == 0) {
                var msg = "Please Select drug for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                let te = document.querySelectorAll("[data-override-index='" + i + "'] .drugselections");
                if (te.length > 0) te[0].classList.add("show-override-error-indication")

            }
            let overrideError = false;
            if (!o.OverrideDetails.enableMultiTimeOverrideCode && !o.OverrideDetails.enableOneTimeOverrideCode) {
                var msg = "Please Select overrides for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            } else if (o.OverrideDetails.enableOneTimeOverrideCode && o.OverrideDetails.OneTimeOverrideCodes.length == 0) {
                var msg = "Please Select overrides for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            } else if (o.OverrideDetails.enableMultiTimeOverrideCode && o.OverrideDetails.MultipleTimeOverrideCodes.length == 0) {
                var msg = "Please Select overrides for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.MaxDaysSupply !== undefined && o.OverrideDetails.MaxDaysSupply != undefined &&
                (o.OverrideDetails.MaxDaysSupply > 99999 || o.OverrideDetails.MaxDaysSupply < 1)
            ) {
                var msg = "Max Days Supply error for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.MinDaysSupply !== undefined && o.OverrideDetails.MinDaysSupply != null &&
                (o.OverrideDetails.MinDaysSupply > 99999 || o.OverrideDetails.MinDaysSupply < 1)
            ) {
                var msg = "Min Days Supply error for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.Threshold !== undefined && o.OverrideDetails.Threshold != null &&
                (o.OverrideDetails.Threshold > 999 || o.OverrideDetails.Threshold < 1)
            ) {
                var msg = "Threshold error for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.NumberOfRefills !== undefined && o.OverrideDetails.NumberOfRefills != null &&
                (o.OverrideDetails.NumberOfRefills > 20 || o.OverrideDetails.NumberOfRefills < 1)
            ) {
                var msg = "Number Of Refills error for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.MaxQuantityDispensed !== undefined && o.OverrideDetails.MaxQuantityDispensed != null &&
                (o.OverrideDetails.MaxQuantityDispensed > 99999 || o.OverrideDetails.MaxQuantityDispensed < 0.01)
            ) {
                var msg = "Max Quantity Dispensed error for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.MaxDaysSupply !== undefined && o.OverrideDetails.MinDaysSupply !== undefined && 
                (o.OverrideDetails.MinDaysSupply > o.OverrideDetails.MaxDaysSupply)
            ) {
                var msg = "Min Days Supply cannot exceed Max Days Supply for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.AuthorizationPeriod.Value === undefined || o.AuthorizationPeriod.Value == null || o.AuthorizationPeriod.Value == "") {
                var msg = "Please enter Authorization Period for Override " + (i + 1);
                this.errorMessages.push({ index: i, message: msg });
                overrideError = true;
            }
            if (o.OverrideDetails.MultipleTimeOverrideCodes
                    .filter(f => f.Code.startsWith("DURMED")).length > 0
                || o.OverrideDetails.OneTimeOverrideCodes
                    .filter(f => f.Code.startsWith("DURMED")).length > 0
            ) {
                if (o.OverrideDetails.Threshold === undefined || o.OverrideDetails.Threshold == null) {
                    var msg = "Please enter a Threshold quantity for Override " + (i + 1);
                    this.errorMessages.push({ index: i, message: msg });
                    overrideError = true;

                }
            }
            if (overrideError) {
                let te = document.querySelectorAll("[data-override-index='" + i + "'] .overrideconfiguration");
                if (te.length > 0) te[0].classList.add("show-override-error-indication")
                document.querySelector('.override-error-comments')?.classList.remove('hide')
            }
            if (this.errorMessages.length > 0) {
                this.showOverrideErrorsSnak();
            }
        };
    }
    keyDownChuck(ev: any, length: number) {
        this.showInputError = '';
        if (ev.key == 'e' || ev.key == 'E' || ev.key == '-') {
            ev.stopPropagation();
            ev.preventDefault();
            ev.currentTarget.value.replace(ev.key, '');
        }
        //console.log(event.target.value, event.target.value.length, length);
        if (ev.currentTarget.value.length == length
            && (/[0-9]/i.test(ev.key) )
        ) {
            ev.stopPropagation();
            ev.preventDefault();
            /*console.log(ev);*/
            /*console.lev.target.labels[0].innerTextget.labels[0].innerText);*/
            this.showInputError = ev.target.labels[0].innerText
            //ev.currentTarget.value = ev.currentTarget.value.substring(0,length);
        }
        
    }
    // only show threshold for DURMED codes
    showThreshold(oli: DecisionTreeOverride): boolean {
        return oli.OverrideDetails.MultipleTimeOverrideCodes
            .filter(f => f.Code.startsWith('DURMED')).length > 0
        || oli.OverrideDetails.OneTimeOverrideCodes
            .filter(f => f.Code.startsWith('DURMED')).length > 0
    }
    // Only show MinDaysSupply when code attributed to Days Supply
    showMinDaysSupply(oli: DecisionTreeOverride): boolean {
        return oli.OverrideDetails.MultipleTimeOverrideCodes
            //.filter(f => f.Reason.toLowerCase().indexOf('days supply') >= 0).length > 0
            .filter(f => f.Code == "DS" || f.Code == "DURMED-DS").length > 0
            ||
            oli.OverrideDetails.OneTimeOverrideCodes
                //.filter(f => f.Reason.toLowerCase().indexOf('days supply') >= 0).length > 0
            .filter(f => f.Code == "DS" || f.Code == "DURMED-DS").length > 0
    }
    scrollToOverride(idx: number) {
        var te = document.querySelector("[data-override-index='" + idx + "']");
        if (te != null) {
            te.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'center' });
            document.querySelector('.override-error-comments')?.classList.add('hide')
        }
    }
    showOverrideErrorsSnak() {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.style.backgroundColor = '#2d3131';
        fakesnak.style.color = '#eff1f1';
        fakesnak.innerHTML = ''
            + '<div class="snakcontent" style="font-size:14px;">'
            + 'Overrides ar missing required fields</div>'
            
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
        var t1 = new Date().getTime();
        /*console.warn("triggering timeout.......................", t1);*/
        setTimeout(function () {
            var t2 = new Date().getTime();
            //console.warn("timeout happened.......................", t2, ((t2 - t1) / 1000));
            document.querySelector(".fakesnak")?.remove();
        }, 5 * 1000);
    }
}
