import { Component, Input } from '@angular/core';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';

@Component({
  selector: 'justification-decisions',
  templateUrl: './justification-decisions.component.html',
  styleUrls: ['./justification-decisions.component.scss']
})
export class JustificationDecisionsComponent {
    @Input() justification!: JustificationSettings;
    @Input() p!: any;

    constructor(
        
    ) {
        console.log('justification decision constructor');
    }

    ngOnInit(): void {

    }
}
