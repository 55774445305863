<div class="title">Preview</div>
<div>Select the application to preview</div>
<mat-radio-group [(ngModel)]="selectedApp">
    <mat-radio-button class="app-select" *ngFor="let a of p.applications" [value]="a.id" (change)="clearAnswers(a.id)">
        {{a.name}}
    </mat-radio-button>
</mat-radio-group>
<hr />
<div>
    <span>
        Show all questions
        <mat-slide-toggle [checked]="showAll" (change)="showAll=!showAll"></mat-slide-toggle>
    </span>
    <span *ngIf="showAll">
        Show skipped
        <mat-slide-toggle [checked]="showSkipped" (change)="showSkipped=!showSkipped"></mat-slide-toggle>
    </span>
</div>
<div class="inline-preview-area">
    <div *ngFor="let qsc of p.questionSetControls" [attr.data-qs-guid]="qsc.GetQuestionSet()!.qsGuid">
        <section *ngIf="isEnabledForApplicationPreview(qsc)">
            <app-inline-question-set [qsc]="qsc" [p]="this"></app-inline-question-set>
        </section>
    </div>


    <div *ngIf="decision != undefined">
        Decision:
        <span class="resultAction{{decision.actionType}}">
            <span *ngIf="decision.actionType=='Approve'">
                Approved
            </span>
            <span *ngIf="decision.actionType=='Deny'">
                Denied
            </span>
            <span *ngIf="decision.actionType=='RFI'">
                Missing Info Request
            </span>
            <span *ngIf="decision.actionType=='2Rph'">
                Needs Pharmacist Review
            </span>
            <span *ngIf="decision.actionType=='Partial Approval'">
                Partial Approve
            </span>

        <!--{{decision.actionType}}-->

        </span>

    </div>
</div>
<hr />

<div class="preview-footer">
    <button class="reset-button" mat-stroked-button color="accent" (click)="clearAnswers(-1)">
        Reset
    </button>
</div>
