import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Case } from '../../classes/case';
import { DecisionTreeSettings, JustificationSettings } from '../../classes/decisiontreesettings';
import { ActionResult, AnswerChoiceCommonType, Comparison, NextQuestionCondition, QuestionLanguage, QuestionTypeDate, QuestionTypeNumeric, QuestionTypeSelect, RangeComparison, SingleComparison } from '../../classes/questionset';
import { JustificationTypeAheadDirective } from '../../shared/directives/justification-type-ahead.directive';
import { CaseService } from '../../shared/services/case.service';
import { DecisionTreeService } from '../decision-tree.service';

@Component({
    selector: 'app-edit-question-decision-item',
    templateUrl: './edit-question-decision-item.component.html',
    styleUrls: ['./edit-question-decision-item.component.scss']
})
export class EditQuestionDecisionItemComponent {
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
    @Input() questionSetControl!: any;
    @Input() question!: any;
    @Input() action!: ActionResult;
    @Input() answerChoice: AnswerChoiceCommonType | undefined;
    @Input() comparison: any;
    //settings: DecisionTreeSettings | undefined;
    showDecisionScenario: boolean = false;
    showDecisionJustification: boolean = true;
    showDecisionOverride: boolean = false;
    availableCases: Case[] = [];
    supportedLanguages: any = [
        { code: "SPA", description: "Spanish" }//,
        //{ code: "FRA", description: "French" }
    ];

   parameters = {
        clientID: '',
        determinationLevel: '',
        decision: '',
        pcn: '',
        caseType: ''
    };
    justificationSettings: JustificationSettings[] = [];
    j: JustificationSettings | null = null;

    constructor(private decisionTreeService: DecisionTreeService, private caseService: CaseService, private cdr: ChangeDetectorRef) {
        
    }

    ngOnInit() {
        // console.log("Constructing language...", this.questionLanguage);
        this.showDecisionJustification = this.questionSetControl.questionSetTypeId == 0;
        //console.log('qs control', this.questionSetControl);
        //console.log('passed decision tree id', this.decisionTreeID);
        //this.getTreeSettings();
        //debugger;
        this.mapParameters(this.questionSetControl.settings);
        this.fetchCases();
    }

    fetchCases() {
        this.caseService.getCases().subscribe(resp => {
            if (resp.body) {
                //console.log(resp.body);
                this.availableCases = this.caseService.mapCases(resp);
            }
        });
    }

    onJustificationDataReceived(data: JustificationSettings[]) {
        //console.log('justification data received', data);
        this.justificationSettings = data;
        // Manually open the autocomplete dropdown
        this.autocompleteTrigger.openPanel();
        this.cdr.detectChanges();
    }

    //getTreeSettings() {
    //    this.settings = this.questionSetControl.settings;
    //    if (this.settings) {
    //        this.mapParameters(this.settings);
    //    }
    //    //console.log("DTI Settings", this.settings);
    //    //this.decisionTreeService.getDecisionTreeSettingsByID(decisionTreeID).subscribe(resp => {
    //    //    if (resp.body) {
    //    //        this.tree = this.decisionTreeService.mapDecisionTreeSettingsFromDecisionTree(resp);
    //    //        //console.log(this.tree);
    //    //        if (this.tree) {
    //    //            this.mapParameters(this.tree);
    //    //        }
    //    //    }
    //    //});
    //}

    getCaseDisplayString(j: JustificationSettings): string {
        const caseTypes = j.CaseType.map(c => c.CaseType).join(', ');
        return caseTypes || 'All';
    }

    mapParameters(tree: DecisionTreeSettings) {
        
        //map parameters
        this.parameters.clientID = tree.client.clientID!;
        this.parameters.decision = this.action.actionType!;
        //Map PCN
        if (tree.hierarchy.length >= 1) {
            this.parameters.pcn = tree.hierarchy.map(item => item.pcnReferenceID).join(',');
            if (!this.parameters.pcn.includes('-1')) {
                this.parameters.pcn = this.parameters.pcn + ',-1';
            } else {
                this.parameters.pcn = this.parameters.pcn;
            }
        } else {
            this.parameters.pcn = '-1';
        }
        //Map Determination
        if (tree.hierarchy.length >= 1) {
            const uniqueDeterminations = new Set();

            tree.hierarchy.forEach(hierarchyItem => {
                hierarchyItem.determination.forEach(level => {
                    uniqueDeterminations.add(level.determinationLevel);
                });
            });

            this.parameters.determinationLevel = Array.from(uniqueDeterminations).join(',');
        }
        //Map Case
        if (tree.hierarchy.length >= 1) {
            const uniqueCaseTypes = new Set();

            tree.hierarchy.forEach(hierarchyItem => {
                hierarchyItem.cases.forEach(caseItem => {
                    // Find the corresponding case object in availableCases
                    const matchingCase = this.availableCases.find(ac => ac.caseType === caseItem.caseType);

                    if (matchingCase) {
                        uniqueCaseTypes.add(matchingCase.pcnCaseTypeID);
                    }
                });
            });

            this.parameters.caseType = Array.from(uniqueCaseTypes).join(',');
        }
    }

    GetComparisonToString(cmp: Comparison) {
        if (cmp instanceof RangeComparison) {
            let c = <RangeComparison>cmp;
            return c.lowerBoundComparisonValue + " " + c.lowerBoundComparisonOperator
                + " [VALUE] "
                + c.upperBoundComparisonOperator + " " + c.upperBoundComparisonValue;
        } else {
            let c = <SingleComparison>cmp;
            return "[VALUE] " + c.comparisonOperator + " " + c.comparisonValue;
        }
    }

    updateJustification(justification: JustificationSettings | null) {
        if (justification !== null) {
            this.j = justification;
            this.action.actionName = justification.JustificationDescription;

            // Parse the JSON
            const justificationReasonJson = JSON.parse(justification.JustificationReasonJson!);

            // Update the actionResult
            this.action.actionTextProvider = justificationReasonJson.prescriberEng!;
            this.action.actionTextPharmacy = justificationReasonJson.pharmacyEng;
            this.action.actionTextPatient = justificationReasonJson.beneficiaryEng;

            // Update the dynamic patientLanguage based on lang.code (e.g., 'spa')
            this.supportedLanguages.forEach((lang: { code: string; }): void => {
                if (lang.code == 'SPA') {

                    const spaPrescriber = justificationReasonJson.prescriberSpa;
                    const spaPharmacy = justificationReasonJson.pharmacySpa;
                    const spaBeneficiary = justificationReasonJson.beneficiarySpa;

                    var provider:any = document.querySelectorAll("[data-guid='" + this.action.actionTextGuid + "'][data-recipient='provider'][data-code='"+ lang.code +"']")[0];
                    var patient: any = document.querySelectorAll("[data-guid='" + this.action.actionTextGuid + "'][data-recipient='patient'][data-code='" + lang.code +"']")[0];
                    var pharmacy: any = document.querySelectorAll("[data-guid='" + this.action.actionTextGuid + "'][data-recipient='pharmacy'][data-code='" + lang.code + "']")[0];
                    if (provider) {
                        provider.value = spaPrescriber;
                    }
                    if (patient) {
                        patient.value = spaPharmacy;
                    }
                    if (pharmacy) {
                        pharmacy.value = spaBeneficiary;
                    }
                }
            });
            this.justificationSettings = [];
        }
        this.autocompleteTrigger.closePanel();
    }
}
