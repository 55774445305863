import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AnswerChoiceCommonType, Comparison, NextQuestionCondition, Question, QuestionLanguage, QuestionTypeDate, QuestionTypeFreeText, QuestionTypeNumeric, QuestionTypeSelect, RangeComparison, SingleComparison } from '../../../classes/questionset';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { FormsModule } from '@angular/forms';
import { QuestionSetControl } from '../../../shared/question-set.control';


@Component({
    selector: 'app-question-language',
    templateUrl: './question-language.component.html',
    styleUrls: ['./question-language.component.scss'],
    
})
export class QuestionLanguageComponent {
    @Input() question!: Question;
    @Input() questionSetControl!: QuestionSetControl;
    @Input() questionLanguage!: QuestionLanguage[];
    supportedLanguages: any = [
        { code: "SPA", description: "Spanish" }//,
        //{ code: "FRA", description: "French" }
    ];
    loaded: boolean = false;
    constructor() {
        //console.log("Constructing language");
        //console.log(this.questionLanguage);
        
    }
    GetQuestionType() {
        var type = "";
        if (this.question.questionType instanceof QuestionTypeSelect) {
            type = "Select";
        } else if (this.question.questionType instanceof QuestionTypeFreeText) {
            type = "FreeText";
        } else if (this.question.questionType instanceof QuestionTypeDate) {
            type = "Date";
        } else if (this.question.questionType instanceof QuestionTypeNumeric) {
            type = "Numeric";
        }
        return type;
    }
    ngOnInit() {
        //console.log("Constructing language...", this.questionLanguage);
    }
    GetSelectType() {
        return <QuestionTypeSelect>this.question.questionType;
    }
    GetDateType() {
        return <QuestionTypeDate>this.question.questionType;
    }
    GetNumericType() {
        return <QuestionTypeNumeric>this.question.questionType;
    }
    GetComparisonToString(cmp: Comparison) {
        if (cmp instanceof RangeComparison) {
            let c = <RangeComparison>cmp;
            return c.lowerBoundComparisonValue + " " + c.lowerBoundComparisonOperator
                + " [VALUE] "
                + c.upperBoundComparisonOperator + " " + c.upperBoundComparisonValue;
        } else {
            let c = <SingleComparison>cmp;
            return "[VALUE] " + c.comparisonOperator + " " + c.comparisonValue;
        }
    }
    GetCurrentValueForTextField(code: string, recipient: string, guid: string, currentInput: string = '') {
        console.log("GetCurrentValueForTextField: " + recipient + ":" + currentInput)
        var r = this.questionLanguage.filter(f => {
            return f.languageTextGuid == guid
                && (f.languageCode ?? "").toUpperCase() == (code ?? "").toUpperCase()
                && (f.recipient ?? "").toUpperCase() == (recipient ?? "").toUpperCase()
        });
        //console.log(this.questionLanguage, r);

        return r.length >= 1 ? r[0].languageText : "";
    }
}
