<div id="drugConfig" style="overflow-y:auto; max-height:calc(80vh - 200px)">
    <table mat-table [dataSource]="dataSource" class="" multiTemplateDataRows>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button aria-label="expand row" (click)="(expandedRow = expandedRow === row ? null : row); $event.stopPropagation()">
                    <mat-icon *ngIf="expandedRow !== row">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="expandedRow === row">keyboard_arrow_up</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Client Column -->
        <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef> Client </th>
            <td mat-cell *matCellDef="let r">{{client.clientName}}  </td>
        </ng-container>

        <!-- PCN GROUP PLAN Column -->
        <ng-container matColumnDef="lob">
            <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
            <td mat-cell *matCellDef="let r"> {{r.pcn}} - {{r.plan}} - {{r.group}} </td>
        </ng-container>

        <!-- Config Start Date Column -->
        <!--<ng-container matColumnDef="start">
            <th mat-header-cell *matHeaderCellDef> Start </th>
            <td mat-cell *matCellDef="let r"> </td>
        </ng-container>-->
        <!-- Config End Date Column -->
        <!--<ng-container matColumnDef="end">
            <th mat-header-cell *matHeaderCellDef> End </th>
            <td mat-cell *matCellDef="let r">  </td>
        </ng-container>-->

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
                    <solr-search [tree]="tree" [hierarchyToApply]="row" [applyToAll]=false></solr-search>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;"
            class="example-element-row"
            [class.example-expanded-row]="expandedRow === row"
            (click)="expandedRow = expandedRow === row ? null : row"></tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

    </table>
</div>