<h2 mat-dialog-title>Are you sure?</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <p>Are you sure you want to delete this Justification?</p>
    <p>This action cannot be undone, and the justification will be permanently removed from the catalog.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button 
            mat-flat-button color="accent" (click)="confirmDelete()" style="border-radius: 24px;">
        Yes, delete
    </button>
    <button  mat-flat-button color="accent" (click)="close()" style="border-radius: 24px;">
        Cancel
    </button>

</mat-dialog-actions>
