<div class="cntr" (dragover)="dragOver($event)" (drop)="dragDrop($event)">
    <div id="leftSide" class=" lside" *ngIf="questionSetRecord != undefined">
        <div style="font-size:22px; font-weight: 400;display:inline-flex;align-items:center;">
            {{questionSetRecord.questionSetHeader.questionSetName}} v{{version}}
        </div>
        <div>
            <div style="display:inline" *ngFor="let a of applications">
                <mat-chip-option color="accent" [selected]="a.enabled"
                                 (click)="a.enabled=!a.enabled">
                    {{a.name}}
                </mat-chip-option> &nbsp;
            </div>
        </div>
        <div id="questionEditArea" style="position:relative; margin-top:-15px;">
            <mat-tab-group [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false" mat-align-tabs="start" color="accent" id="questionlisttabs">
                <mat-tab label="Questions">
                    <div class="questionSection"><!--(click)="showPendingApprovalBlockEditSnak()"-->
                        <div *ngFor="let qsc of questionSetControls">
                            <!--{{qsc.GetQuestions().length}} {{qsc.cacheInitialized}}-->
                            <app-edit-catalog-list [p]="this" [questionSetControl]="qsc"></app-edit-catalog-list>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="Decision">
                    <div class="questionSection">
                        <div *ngFor="let qsc of questionSetControls | sortByOrder">
                            <app-edit-question-decision-list [questionSetControl]="qsc"></app-edit-question-decision-list>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div style="position:absolute; top:0px; right:0px;display:flex; height:48px; align-items:center; justify-content:center;">
                <button mat-button color="accent" (click)="usage()" style="height: 48px; ">
                    View Usage
                </button>
                <button mat-button color="accent" (click)="test()" style="height: 48px; ">
                    Test
                </button>
            </div>
        </div>
        <div class="decison_tree_actions" style="width:100%; text-align:right;margin-top:3px;">
            <button mat-flat-button style="background-color:#7BF8D9" (click)="approveQuestionSet()">
                Approve
            </button> &nbsp;
            <button mat-raised-button color="accent" (click)="save()">
                Save
            </button> &nbsp;
        </div>
    </div>
    <div class=" mvr" draggable="true" (dragstart)="dragStart($event)">
        <div class="dotz">
            ..<br />
            ..<br />
            ..<br />
        </div>
    </div>
    <div id="rightSide" class=" rside">
        <div class="errorComments" *ngIf="errorMessages.length > 0">
            <div class="header">
                <span style="flex: 24px 0 0" class="material-icons">info</span>
                <span style="flex: 30px 1 1"> &nbsp; Decision Tree Errors</span>
                <span style="flex: 24px 0 0" class="material-icons midown" onclick="document.querySelector('.errorComments').classList.remove('hide')">arrow_drop_up</span>
                <span style="flex: 24px 0 0" class="material-icons miup" onclick="document.querySelector('.errorComments').classList.add('hide')">arrow_drop_down</span>
            </div>
            <div class="content" style="position:absolute">
                <div *ngFor="let i of errorMessages">
                    <div style="display:flex;align-items:center; padding:2px;margin:5px;">
                        <span style="flex: 24px 0 0;" class="material-icons">circle</span>
                        <span style="flex: 100px 1 1;"> &nbsp; {{i}}</span>
                    </div>
                </div>
            </div>
        </div>
        <app-edit-questions-tree [p]="this"></app-edit-questions-tree>
    </div>

</div>

<div id="interactiveQuestionAnswerModal">
    <div id="modalOperationControls">
        <button mat-icon-button color="accent" class="btn btn-sm btn-outline-primary" onclick='hideReviewQuestionArea()'>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>

    <div id="interactiveQuestionAnswerSection"></div>
    <hr />
    <div class="simpleright">
        <button class="reset-button" onclick='resetReviewQuestionArea()'>
            Reset
        </button>
        <button class="close-button" onclick='hideReviewQuestionArea()'>
            Close
        </button>
    </div>

</div>
