import { query } from '@angular/animations';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { QuestionSet } from '../../classes/questionset';
import { ApiPaths } from '../environments/routes';
import { environment } from '../environments/environments';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionSetService {
    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getQuestionSetDataFromService(QuestionSetID: number): Observable<any> {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('QuestionSetID', QuestionSetID);
        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetQuestionSetData, { headers: this.getHeaders(), params: queryParams, observe: 'response' });
    }
    public getQuestionSetDetailFromService(QuestionSetID: number): Observable<any> {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('QuestionSetID', QuestionSetID);
        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetQuestionSetEditDetail, { headers: this.getHeaders(), params: queryParams, observe: 'response' });
    }
    public SaveQuestionSet(questionSetID: number, appsEnabled: number[], questionSetData: QuestionSet,langResult:any
    ): Observable<HttpResponse<any>> {
        //console.log("Save", questionSetData);
        //console.log(this.authService);
        var url = environment.baseUrl + ApiPaths.SaveQuestionSet;
        const body = {
            'questionSetID': questionSetID,
            'qss': JSON.stringify(questionSetData),
            'EnabledApplications': appsEnabled,
            'AlternateQuestionLanguage': langResult ?? [],
            'UserId': this.authService.user.id!
        };
        var headers = new HttpHeaders({'Content-Type': "application/json"});

        return this.http.post(url, body, { headers: headers, observe: 'response' })
            .pipe(map((response: any) => response));
    }
    public ApproveQuestionSet(questionSetID: number, appsEnabled: number[], comment:string
        , questionSetData: QuestionSet, langResult: any
    ): Observable<HttpResponse<any>> {
        console.log("Approve", questionSetData);
        var url = environment.baseUrl + ApiPaths.ApproveQuestionSet;
        const body = {
            'questionSetID': questionSetID,
            'qss': JSON.stringify(questionSetData),
            'EnabledApplications': appsEnabled,
            'AlternateQuestionLanguage': langResult ?? [],
            'UserId': this.authService.user.id!,
            "Comments": comment
        };
        var headers = new HttpHeaders({'Content-Type': "application/json"});

        return this.http.post(url, body, { headers: headers, observe: 'response' })
            .pipe(map((response: any) => response));
    }
    public GetLanguageTextGUID(questionSetHeaderID: number) {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('QuestionSetHeaderID', questionSetHeaderID);
        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetLanguageTextGUID, { headers: this.getHeaders(), params: queryParams, observe: 'response' });
    }
    public getQuestionSetUsage(questionSetHeaderID: number): Observable<any> {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('QuestionSetHeaderID', questionSetHeaderID);
        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetQuestionSetUsage, { headers: this.getHeaders(), params: queryParams, observe: 'response' });
    }
}
