<form [formGroup]="determinationForm">
    <mat-button-toggle-group appearance="standard" multiple formControlName="level" (click)="setDetermination()">
        <!-- First input -->
        <mat-button-toggle value="1">
            First Determination
        </mat-button-toggle>

        <!-- Second input -->
        <mat-button-toggle value="2">
            First Appeal
        </mat-button-toggle>

        <!-- Third input -->
        <mat-button-toggle value="3">
            Second Appeal
        </mat-button-toggle>

        <!-- Third input -->
        <mat-button-toggle value="4">
            Third Appeal
        </mat-button-toggle>
    </mat-button-toggle-group>
</form>
