import { Pipe, PipeTransform } from '@angular/core';
import { Question } from '../../classes/questionset';

@Pipe({
    name: 'sortBySequence',
    pure: false
})
export class SortBySequencePipe implements PipeTransform {

    transform(value: Question[]): Question[] {
        return value.sort((n1, n2) => {
            return n1.sequenceNumber - n2.sequenceNumber;
        });
    }

}
