<!--:::{{actionResult.actionTextGuid}}:::-->

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" color="accent">
    <mat-tab label="English">
        <br />
        <mat-form-field color="accent" class="full-width" appearance="outline">
            <mat-label>Decision Justification for Prescriber</mat-label> <!--for="actionTextProvider"-->
            <textarea matInput 
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAuthsizeMinRows="1"
                      cdkAuthsizeMaxRows="10"
                      [(ngModel)]="actionResult.actionTextProvider" name="actionTextProvider"></textarea>
        </mat-form-field>
        <mat-form-field color="accent" class="full-width" appearance="outline">
            <mat-label>Decision Justification for Pharmacy</mat-label> <!--for="actionTextPharmacy"-->
            <textarea matInput 
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAuthsizeMinRows="1"
                      cdkAuthsizeMaxRows="10"
                      [(ngModel)]="actionResult.actionTextPharmacy" name="actionTextPharmacy"></textarea>
        </mat-form-field>

        <mat-form-field color="accent" class="full-width" appearance="outline">
            <mat-label>Decision Justification for Beneficiary / Representative</mat-label> <!--for="actionTextPatient"-->
            <textarea matInput 
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAuthsizeMinRows="1"
                      cdkAuthsizeMaxRows="10"
                      [(ngModel)]="actionResult.actionTextPatient" name="text"></textarea>
        </mat-form-field>
    </mat-tab>

    <section *ngFor="let lang of supportedLanguages; let i = index">

        <mat-tab label="{{lang.description}}">
            <br />
            <div #targetElement [hidden]="(actionResult!.actionTextGuid === null || actionResult!.actionTextGuid === undefined || (actionResult!.actionTextGuid!.length <= 0))" class="row">

                <div class="full-width">
                    <mat-form-field class="full-width" appearance="outline"
                        *ngIf="providerLanguage[lang.code] != undefined">
                        <mat-label> {{lang.description}} Decision Justification for Prescriber</mat-label>
                        <textarea matInput
                                  cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAuthsizeMinRows="1"
                                  cdkAuthsizeMaxRows="10"
                                  [(ngModel)]="providerLanguage[lang.code].languageText"
                                  name="actionTextProvider{{lang.code}}" [attr.data-code]="lang.code"
                                  [attr.data-guid]="actionResult.actionTextGuid" data-recipient="provider"></textarea>
                    </mat-form-field>
                </div>
                <div class="full-width">
                    <mat-form-field class="full-width" appearance="outline"
                        *ngIf="pharmacyLanguage[lang.code] != undefined">
                        <mat-label> {{lang.description}} Decision Justification for Pharmacy</mat-label>
                        <textarea matInput
                                  cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAuthsizeMinRows="1"
                                  cdkAuthsizeMaxRows="10"
                                  [(ngModel)]="pharmacyLanguage[lang.code].languageText"
                                  [attr.data-code]="lang.code"
                                  [attr.data-guid]="actionResult.actionTextGuid" data-recipient="pharmacy"></textarea>
                    </mat-form-field>
                </div>
                <div class="full-width">
                    <mat-form-field class="full-width" appearance="outline"
                        *ngIf="patientLanguage[lang.code] != undefined">
                        <mat-label> {{lang.description}} Decision Justification for Beneficiary / Representative</mat-label>
                        <textarea matInput
                                  cdkTextareaAutosize
                                  #autosize="cdkTextareaAutosize"
                                  cdkAuthsizeMinRows="1"
                                  cdkAuthsizeMaxRows="10"
                                  [(ngModel)]="patientLanguage[lang.code].languageText"
                                  [attr.data-code]="lang.code" [attr.data-guid]="actionResult.actionTextGuid" data-recipient="patient">
                        </textarea>
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>
    </section>

</mat-tab-group>