import { Client } from "./client";
import { PCN } from "./pcn";

export class Justification {
	JustificantionTemplateID: number | undefined;
	JustificationDescription: string | undefined;
	JustificationDecision: string | undefined;
	JustificationDecisionReason: string | undefined;
	ClientID: string | undefined;
	Client: Client | undefined;
	PCN: string | undefined;
	PCNDescription: string | undefined;
	JustificationReasonJson: string | undefined;
	OverridesJson: string | undefined;
	OverrideCriteria: string | undefined;
}


