
<h3 mat-dialog-title id="guided-modal">Create New Decision Tree</h3>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>
<mat-divider ></mat-divider>
<mat-dialog-content>
    <config-error [tree]="tree" [hidden]="activeStepIndex >= requestedStepIndex"></config-error>
    <form [formGroup]="createForm">
        <div [hidden]="activeStepIndex !== 0">
            <h4><span class="number-icon">1</span>Decision tree name and period of effectiveness</h4>
            <section>
                <div class="column-8">
                    <mat-form-field appearance="outline" class=" full-width" color="accent">
                        <mat-label>Decision Tree Name</mat-label>
                        <input matInput formControlName="name" type="text">
                        <mat-error *ngIf="createForm.controls['name'].invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="column-8">
                    <mat-form-field appearance="outline" class="full-width" color="accent">
                        <mat-label>Description</mat-label>
                        <input matInput formControlName="desc" type="text">
                    </mat-form-field>
                </div>
                <div class="column-6">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>Default Effectiveness Range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" formControlName="start">
                            <input matEndDate placeholder="End date" formControlName="end">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>
            </section>
        </div>
        <div [hidden]="activeStepIndex !== 1">
            <h4>
                <span class="number-icon">2</span>{{tree.client.clientName}} Client Configuration
            </h4>
            <section>
                <client-configuration [tree]="tree" [clients]="clients" [p]="p" (hierarchyAdded)="refreshChildComponents()"></client-configuration>
            </section>
        </div>
        <div [hidden]="activeStepIndex !== 2">
            <h4><span class="number-icon">3</span>Type and Determination</h4>
            <section>
                <h5>Decision Tree Case Type & Case Sub Type</h5>
                <case-selection [tree]="tree" [applyToAll]=true></case-selection>
                <h5>Determination Level</h5>
                <determination-configuration [tree]="tree" [applyToAll]=true></determination-configuration>
                <br/>
                <h5>Medicare Part</h5>
                <medicare-configuration [tree]="tree" [applyToAll]=true></medicare-configuration>
            </section>
        </div>
        <div [hidden]="activeStepIndex !== 3">
            <h4><span class="number-icon">4</span>Drug Configuration</h4>
            <section>
                <solr-search [tree]="tree" [applyToAll]=true></solr-search>
            </section>
        </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center" >
    <button mat-stroked-button color="accent" (click)="previousStep()" [disabled]="activeStepIndex == 0"
            style="border-radius: 24px; top: -10px;">
        Back
    </button>
    <mat-horizontal-stepper [linear]="true" color="accent" labelPosition="bottom" #stepper [selectedIndex]="activeStepIndex" (selectionChange)="stepSelectionChanged($event)" class="center-icon-stepper">
        <!-- Add mat-step components as needed -->
        <mat-step [completed]="checkStep1Validation()">
        </mat-step>
        <mat-step [completed]="checkStep2Validation()">
            <!-- Step 2 content -->
        </mat-step>
        <mat-step [completed]="checkStep3Validation()">
            <!-- Step 3 content -->
        </mat-step>
        <mat-step [completed]="checkStep4Validation()">
            <!-- Step 4 content -->
        </mat-step>
        <!-- Add more steps as needed -->
    </mat-horizontal-stepper>
    <button mat-flat-button color="accent" *ngIf="activeStepIndex != 3" (click)="nextStep()"
            style="border-radius: 24px; top: -10px;">
        Next
    </button>
    <button mat-flat-button color="accent" *ngIf="activeStepIndex === 3" [disabled]="!validateTree()" (click)="save()" style="border-radius: 24px; top: -10px;">
        Create
    </button>
    <!--<button type="submit"
            mat-raised-button color="primary">
        Save
    </button>-->
</mat-dialog-actions>

