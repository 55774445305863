import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Controllers } from '../environments/environments';
import { CopyService } from '../services/copy.service';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { Client, ClientSimple } from '../../classes/client';
import { AuthService } from '../auth/auth.service';
import { User } from '../../classes/user';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-copy-modal',
  templateUrl: './copy-modal.component.html',
  styleUrls: ['./copy-modal.component.scss']
})
export class CopyModalComponent {
    @Input() controllerType!: Controllers;
    @Input() id!: number;
    @Input() titleToCopy!: string;
    @Output() event: EventEmitter<any> = new EventEmitter();
    clients: ClientSimple[] | undefined;
    user!: User;
    selectedClient?: Client;
    copyForm = this.fb.group({
        newName: ['', Validators.required],
        clientSelect: ['', Validators.required]
    });


    constructor(
        public copyService: CopyService,
        public hierarchyService: HierarchyService,
        private authService: AuthService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<CopyModalComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        if (data !== undefined) {
            this.controllerType = data.controllerType;
            this.id =  data.id;
            this.titleToCopy = data.titleToCopy;
        }
        this.user = this.authService.getUserObject();
    }

    ngOnInit() {
        console.log(this.user);
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        var c = this.user.clients!;
        this.clients = [];
        for (let i = 0; i < c.length; i++) {
            const cToAdd = new ClientSimple;
            cToAdd.id = c[i].clientID;
            cToAdd.name = c[i].clientName;
            this.clients.push(cToAdd);
        }
    }

    onSubmit(): void {
        this.copyService.copyRecord(this.id, this.copyForm.value.clientSelect!, this.copyForm.value.newName!, this.controllerType)
            .subscribe(
                (data) => {
                    if (data.status == 200) {
                        //successfully saved
                        this.dialogRef.close("1");
                    }
                },  //changed
                (err) => {
                    console.log(err)
                },
                () => console.log("Done")
            );
    }

    onSelectClient() {
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.copyForm.value.clientSelect!.toLowerCase())!;
        this.authService.setClient(this.selectedClient.clientID!);
    }

    close() {
        this.dialogRef.close("0");
    }
}
