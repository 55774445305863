import { group } from '@angular/animations';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged, fromEvent, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { DecisionTreeSettings, DrugDisplay, DrugSettings, HierarchySettings } from '../../classes/decisiontreesettings';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DrugType, SolrDrug } from '../../classes/solr-drug';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { ClientService } from '../services/client.service';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
    selector: 'solr-search',
    templateUrl: './solr-search.component.html',
    styleUrls: ['./solr-search.component.scss']
})
export class SolrSearchComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild(MatAutocompleteTrigger) autocompleteTrigger!: MatAutocompleteTrigger;
    @ViewChild('searchInput', { static: true }) searchInput!: ElementRef<HTMLInputElement>;

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    drugSearchTerm$!: Observable<Event>;

    private destroy$ = new Subject<void>();
    @Input() tree!: DecisionTreeSettings;
    @Input() hierarchyToApply?: HierarchySettings;
    @Input() applyToAll: boolean = true;
    @Input() p!: any;
    displayedColumns: string[] =
        this.applyToAll ? 
        ['desc','gpi','gpiSelection','mony','start','end','remove']
        : ['desc','gpi','gpiSelection','mony', 'start', 'end'];
    
    DrugType = DrugType;

    dataSource: MatTableDataSource<DrugSettings> = new MatTableDataSource<DrugSettings>([]);

 

    drugSearchTerm: string = '';
    solrDrugs: SolrDrug[] = [];
    selectedDrugs: DrugSettings[] = [];
    noDrug: boolean = false;
    drugResultFilter: DrugType[] = [DrugType.GPI, DrugType.NDC];

    constructor(
        private clientService: ClientService,
        private hierarchyService: HierarchyService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        console.log('solr drug constructor');
    }

    ngOnInit(): void {
        //if you have a specific hierarchy, apply the already selected determination types
        if (this.hierarchyToApply) {
            var h = this.findHierarchyToWorkWith();
            if (h.drugs.some(item => item.drugID === "")) {
                this.setNoDrug();
            } else {
                this.selectedDrugs = h.drugs;
            }
            this.dataSource.data = this.selectedDrugs;
            this.dataSource.paginator = this.paginator;
        }
    }

    ngAfterViewInit(): void {
        if (this.searchInput) {
            this.drugSearchTerm$ = fromEvent(this.searchInput.nativeElement, 'input').pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy$)
            );

            this.drugSearchTerm$.subscribe(() => {
                this.searchDrugs();
            });
        } else {
            // Delay the subscription until the searchInput is available
            setTimeout(() => {
                
                if (this.searchInput !== undefined) {
                    this.drugSearchTerm$ = fromEvent(this.searchInput.nativeElement, 'input').pipe(
                        debounceTime(300),
                        distinctUntilChanged(),
                        takeUntil(this.destroy$)
                    );

                    this.drugSearchTerm$.subscribe(() => {
                        this.searchDrugs();
                    });

                    // Manually trigger change detection after the searchInput is available
                    this.changeDetectorRef.detectChanges();
                } 
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openAutocomplete() {
        console.log('autocomplete');
        this.searchDrugs();
        this.autocompleteTrigger.openPanel();
    }

    searchDrugs() {
        const searchTerm = this.drugSearchTerm;

        if (searchTerm) {
            this.hierarchyService.searchSolrDrugs(searchTerm).subscribe(resp => {
                if (resp.body) {
                    //console.log(resp.body);
                    this.solrDrugs = this.hierarchyService.mapSolrDrugsResponse(resp);
                    //Filter the resultset
                    if (this.drugResultFilter.length >= 1) {
                        this.solrDrugs = this.solrDrugs.filter(drug => this.drugResultFilter.includes(drug!.type!));
                    }

                    //this.dataSource.data = this.selectedDrugs;
                    //this.dataSource.paginator = this.paginator;
                }
            });
        } else {
            //this.solrDrugs = [];
            //this.dataSource.data = [];
        }
    }

    displayDrugDescription(drug: SolrDrug): string {
        return drug ? drug.desc! : '';
    }

    optionSelected(event: MatAutocompleteSelectedEvent) {
        const selectedDrug: SolrDrug = event.option.value;
        //const selectedDrugSetting = new DrugSettings;
        console.log(this.tree);
        var h = this.findHierarchyToWorkWith();
        if (this.applyToAll) {
            h = this.tree.hierarchy[0];
        }

        //Check to see if the drug is GPI or NDC
        if (selectedDrug.type ===  DrugType.GPI) {
            var addedDrug = this.selectedDrugs.find(x => (x.drugID == selectedDrug!.id!.toString() && x.drugDisplay?.type === DrugType.GPI));
            if (addedDrug) {
                addedDrug!.drugDisplay = new DrugDisplay;
                addedDrug!.drugDisplay.drugDesc = selectedDrug!.desc!;
                addedDrug!.drugID = 
                addedDrug!.drugDisplay.drugGPI = selectedDrug!.gpi!.toString();
                addedDrug!.drugDisplay.drugDisplayGPI = selectedDrug!.gpi!.toString();
                addedDrug!.drugDisplay!.type = DrugType.GPI;
                addedDrug!.drugIDQualifier = 'GPI-14';


            } else {
                let cd = new Date();
                let minStartDate = new Date(cd.getFullYear(), cd.getMonth(), cd.getDate());
                const drugToAdd = new DrugSettings;
                drugToAdd!.drugDisplay = new DrugDisplay;
                drugToAdd.drugDisplay!.drugDesc = selectedDrug!.desc!;
                drugToAdd.drugID = 
                drugToAdd.drugDisplay!.drugGPI = selectedDrug!.gpi!.toString();
                drugToAdd!.drugDisplay!.drugDisplayGPI = selectedDrug!.gpi!.toString();
                drugToAdd!.drugDisplay!.type = DrugType.GPI;
                drugToAdd.drugIDQualifier = 'GPI-14';
                
                drugToAdd!.startDate = this.applyToAll ? h.settingsStartDate : minStartDate;
                drugToAdd!.endDate = h.settingsEndDate;
                drugToAdd!.multiSourceCodes = [];
                this.selectedDrugs.push(drugToAdd);
            }
        } else if (selectedDrug.type ===  DrugType.NDC) {
            var addedDrug = this.selectedDrugs.find(x => (x.drugID == selectedDrug!.id!.toString() && x.drugDisplay?.type === DrugType.NDC));
            if (addedDrug) {
                addedDrug!.drugDisplay = new DrugDisplay;
                addedDrug!.drugDisplay.drugDesc = selectedDrug!.desc!;
                addedDrug!.drugID = 
                addedDrug!.drugDisplay.drugNDC = selectedDrug!.ndc!.toString();
                addedDrug!.drugDisplay!.type = DrugType.NDC;
                addedDrug!.drugIDQualifier = 'NDC';
            } else {
                let cd = new Date();
                let minStartDate = new Date(cd.getFullYear(), cd.getMonth(), cd.getDate());
                const drugToAdd = new DrugSettings;
                drugToAdd!.drugDisplay = new DrugDisplay;
                drugToAdd.drugDisplay!.drugDesc = selectedDrug!.desc!;
                drugToAdd.drugID = 
                drugToAdd.drugDisplay!.drugNDC = selectedDrug!.ndc!.toString();
                drugToAdd!.drugDisplay!.type = DrugType.NDC;
                drugToAdd.drugIDQualifier = 'NDC';
                drugToAdd!.startDate = this.applyToAll ? h.settingsStartDate : minStartDate;
                drugToAdd!.endDate = h.settingsEndDate;
                drugToAdd!.multiSourceCodes = [];
                this.selectedDrugs.push(drugToAdd);
            }
        }
        

        if (this.applyToAll) {
            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                this.tree.hierarchy[i].drugs = this.selectedDrugs;
            }
            
        } else {
            //Set for the specific hierarchy
            
            h.drugs = this.selectedDrugs;
        }
        this.dataSource.data = this.selectedDrugs;
        this.dataSource.paginator = this.paginator;
    }

    setNoDrug() {
        this.noDrug = !this.noDrug;
        this.updateData();
    }

    private updateData() {
        this.drugSearchTerm = '';
        this.selectedDrugs = [];

        if (this.applyToAll) {
            this.tree.noDrug = this.noDrug;
            if (this.tree.noDrug) {
                for (let i = 0; i < this.tree.hierarchy.length; i++) {
                    this.tree.hierarchy[i].drugs = this.selectedDrugs;
                }
            }
        } else {
            // Apply to the specific hierarchy
            const h = this.findHierarchyToWorkWith();
            h.noDrug = this.noDrug;
            h.drugs = this.selectedDrugs;
        }

        this.dataSource.data = this.selectedDrugs;
        this.dataSource.paginator = this.paginator;
    }

    onChangeGPI(drugID: string, gpiLevel: string) {
        var drugToChange = this.selectedDrugs.find(x => x.drugID == drugID);
        drugToChange!.drugID = 
        drugToChange!.drugDisplay!.drugDisplayGPI = this.sliceGPI(drugToChange!.drugDisplay!.drugGPI!, gpiLevel);
        this.clearSetInRow();
    }

    onChangeStartDate(drugID: string, date: any) {
        var drugToChange = this.selectedDrugs.find(x => x.drugID == drugID);
        drugToChange!.startDate = date.value;
        this.clearSetInRow();
    }
    onChangeEndDate(drugID: string, date: any) {
        var drugToChange = this.selectedDrugs.find(x => x.drugID == drugID);
        drugToChange!.endDate = date.value;
        this.clearSetInRow();
    }
    sliceGPI(gpi: string, gpiLevel: string) {
        const qualifier = parseInt(gpiLevel.slice(4));
        return gpi.slice(0, qualifier);
    }

    removeDrug(d: DrugSettings) {
        this.selectedDrugs = this.selectedDrugs.filter(
            x => !(x.drugID == d.drugID
                && x.drugIDQualifier == d.drugIDQualifier
            )
        );

        if (this.applyToAll) {
            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                this.tree.hierarchy[i].drugs = this.selectedDrugs;
            }
        } else {
            var h = this.findHierarchyToWorkWith();
            h.drugs = this.selectedDrugs;
        }

        this.dataSource.data = this.selectedDrugs;
        
    }

    findHierarchyToWorkWith(): HierarchySettings {
        var hierarchySettings = this.tree.hierarchy.find(hierarchy => {
            // Customize the conditions based on your matching criteria
            return (
                (hierarchy.hierarchyId === this.hierarchyToApply?.hierarchyId && this.hierarchyToApply?.hierarchyId != undefined) ||
                (hierarchy.pcnReferenceID === this.hierarchyToApply?.pcnReferenceID &&
                    hierarchy.planReferenceID === this.hierarchyToApply?.planReferenceID &&
                    hierarchy.groupReferenceID === this.hierarchyToApply?.groupReferenceID)
            );
        });
        return hierarchySettings!;
    }
    setInEditMode(e: any) {
        document.querySelector(".settings-in-edit")?.classList.remove('settings-in-edit')
        e.target.parentElement.classList.add("settings-in-edit");
        //var e2: HTMLElement = <HTMLElement>document.querySelector(".settings-in-edit input");
        //if (e2 != null)
        //    e2.focus();
        
    }
    clearSetInRow() {
        
        document.querySelector(".settings-in-edit")?.classList.remove('settings-in-edit')
    }
}
