export class DecisionTreeOverrideRequest {
	DecisionTreeHeaderID!: number;
	DecisionTreeOverride!: DecisionTreeOverride[];
}


export class DecisionTreeOverride {
	DecisionTreeOverrideID: number | undefined;
	AllDrugs: boolean = false;
	DrugDetails: OverrideDrugDetail[] = [];
	AuthorizationPeriod!: AuthorizationPeriod;
	authPeriodLabel: string | undefined;
	OverrideDetails!: OverrideDetail ;
	OverrideDescription: string = "";
	constructor(data: any) {
		//console.log("NEW",data)
		var self = this;
		self.OverrideDescription = data.OverrideDescription ?? data.overrideDescription ?? "";
		self.DecisionTreeOverrideID = data.decisionTreeOverrideID;
		self.AllDrugs = data.allDrugs;
		if (data.drugDetails != undefined) {
			var dd = JSON.parse(data.drugDetails);
			dd?.forEach(
				function (d: any) {
					let n = new OverrideDrugDetail(d);
					self.DrugDetails!.push(n);
				}
			)
		}
		self.AuthorizationPeriod = new AuthorizationPeriod({
			value: data.authorizationPeriod,
			unit: data.authorizationPeriodUnit
		});
		var od;
		if (data.overrideDetails != undefined && data.overrideDetails.length > 2 )
			od = JSON.parse(data.overrideDetails);
		self.OverrideDetails = new OverrideDetail(od);

		if (self.AuthorizationPeriod.Unit != '' && self.AuthorizationPeriod.Unit != undefined) {
			self.authPeriodLabel = self.AuthorizationPeriod.Value + " " + self.AuthorizationPeriod.Unit;
			var labelArray = ['7 Days', '12 Weeks', '1 Year', '30 Days']
			if (!(labelArray.indexOf(self.authPeriodLabel) > -1)) {
				if (self.AuthorizationPeriod.Unit == 'End of Year') {
					self.authPeriodLabel = 'End of Year';
				} else {
					self.authPeriodLabel = 'Custom';
				}
			}
			
			
		}
	}
}
export class AuthorizationPeriod {
	Value: string = "";
	Unit: string = "";
	constructor (data: any)	{
		this.Value = (data?.value ?? "").toString();
		this.Unit = data?.unit ?? "";
	}
}
export class OverrideDrugDetail {
	ID: string = "";
	Qualifier: string | "";
	Desc: string | "";
	value: string | "";
	constructor(data: any) {
		this.ID = data.ID ?? data.drugID;
		this.Qualifier = data.drugIDQualifier ?? data.Qualifier;
		this.Desc = data.Desc ?? data.drugDisplay.drugDesc;
		this.value = this.Qualifier + "_" + this.ID
	}

}
export class OverrideDetail {
	MinDaysSupply: number | undefined;
	MaxDaysSupply: number | undefined;
	MaxQuantityDispensed: number | undefined;
	Threshold: number | undefined;
	NumberOfRefills: number | undefined;
	enableOneTimeOverrideCode: boolean = false;
	enableMultiTimeOverrideCode: boolean = false;
	MultipleTimeOverrideCodes: OverrideCode[] = [];
	OneTimeOverrideCodes: OverrideCode[] = [];
	constructor(data: any) {
		var self = this;
		//console.log("CON OD", data);
		self.MinDaysSupply = data?.MinDaysSupply;
		self.MaxDaysSupply = data?.MaxDaysSupply;
		self.MaxQuantityDispensed = data?.MaxQuantityDispensed;
		self.Threshold = data?.Threshold;
		self.NumberOfRefills = data?.NumberOfRefills;
		data?.MultipleTimeOverrideCodes?.forEach(
			(fe:any) => { self.MultipleTimeOverrideCodes.push(new OverrideCode(fe))}
		);
		data?.OneTimeOverrideCodes?.forEach(
			(fe: any) => { self.OneTimeOverrideCodes.push(new OverrideCode(fe)) }
		);
		self.enableOneTimeOverrideCode = self.OneTimeOverrideCodes.length > 0;
		self.enableMultiTimeOverrideCode = self.MultipleTimeOverrideCodes.length > 0;
	}
}
export class OverrideCode {
	Code: string = "";
	Reason: string ="";
	constructor(data: any) {
		this.Code = data.Code;
		this.Reason = data.Reason;
	}
}
