import { Component, ElementRef } from '@angular/core';
import { QuestionSetService } from '../../shared/services/question-set.service';
import { QuestionSetControl } from '../../shared/question-set.control';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ApplicationStatus, QuestionSetHeader } from '../../classes/questionset';
import { CommentDialogComponent } from '../../shared/comment-dialog/comment-dialog.component';
import { DecisionTreeSettings } from '../../classes/decisiontreesettings';
import { Client } from '../../classes/client';
import { AuthService } from '../../shared/auth/auth.service';
import { ApproveDialogComponent } from '../../shared/approve-dialog/approve-dialog.component';
import { StatusPipe } from '../../shared/pipes/status.pipe';
import { QuestionSetToDecisionTreeUsageDialogComponent } from '../question-set-to-decision-tree-usage-dialog/question-set-to-decision-tree-usage-dialog.component';

declare var startQuestionSetReview: any;

@Component({
  selector: 'app-edit-catalog',
  templateUrl: './edit-catalog.component.html',
  styleUrls: ['./edit-catalog.component.scss']
})
export class EditCatalogComponent {
    EditID: number = 0;
    public questionSetRecord: any;
    public questionSetControls: QuestionSetControl[] = [];
    public version: number = 0;
    public selectedTabIndex: number = 0;
    public errorMessages: string[] = [];
    public applications: ApplicationStatus[] = [];
    headerInfo: QuestionSetHeader | undefined;

    constructor(
        private location: Location,
        public questionSetService: QuestionSetService,
        /*this.modalService,*/
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private dialog: MatDialog,
        private e: ElementRef,
        private authService: AuthService,
    ) {
    }
    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.EditID = params['id'];
        });

        this.authService.getApplicationList().subscribe(resp => {
            if (resp.body) {
                this.applications = [];
                var a = [];
                a = resp.body;
                a.forEach((e: any) => { this.applications.push(new ApplicationStatus(e)) });
            }
            this.LoadQuestionSet();

        });
    }
    LoadQuestionSet() {
        var self = this;
        var qsc = new QuestionSetControl(this.dialog);
        this.questionSetControls.push(qsc);
        this.questionSetService.getQuestionSetDetailFromService(this.EditID).subscribe(
            resp => {
                this.questionSetRecord = resp.body;
                this.headerInfo = new QuestionSetHeader(
                    resp.body.questionSetHeader
                );
                var qsJson = JSON.parse(resp.body.questionSet.questionSetData);
                qsc.LoadQuestionSet(qsJson);
                qsc.initialOrder = 0;
                qsc.internalOrderOffset = 0;
                qsc.inCatalog = true;
                qsc.questionSetTypeId = resp.body.questionSet.typeID;
                qsc.questionSetHeaderId = resp.body.questionSet.questionSetHeaderID;
                this.version = resp.body.questionSet.majorVersion;
                qsc.settings = new DecisionTreeSettings()
                qsc.settings.client = new Client;
                qsc.settings.client.clientID = this.questionSetRecord.questionSetHeader.clientID;
                qsc.settings.hierarchy = [];
                var a: any[] = [];
                qsc.questionSetLanguage = this.questionSetRecord.questionSetLanguage ?? a;
                qsc.settings.noDrug = true;
                qsc.settings.noCase = true;
                qsc.CalculateQuestionDetail();
                //this.setInternalOrderOffsets();
                setTimeout(function () {
                    self.setErrorMessageList();
                }, 1000);
                var ad = (this.questionSetRecord.applicationDetails ?? []);
                this.applications.forEach((al) => {
                    var curapp = ad.filter((f: any) => f.applicationID == al.id);
                    if (curapp.length > 0 && curapp[0].enabled)
                        al.enabled = true;
                });
            }
        );
    } 
    setErrorMessageList() {
        this.questionSetControls[0].CalculateQuestionDetail();
        var self = this;
        var em: string[] = [];
        self.questionSetControls.forEach(qsc => {
            if (qsc.GetQuestions().length == 0) {
                em.push("You need to have at least 1 question to appove a decision tree.");
            }

            //console.log(qsc.initialOrder, qsc.questionErrorMessages);
            Object.keys(qsc.questionErrorMessages)
                .forEach(k => {
                    em.push.apply(em, qsc.questionErrorMessages[k])
                });
        });
        self.errorMessages = em;
    }

    test() {
        var qs = this.questionSetControls[0].GetQuestionSet();
        startQuestionSetReview(qs?.questionSetHeader?.title, qs);
    }

    approveQuestionSet() {
        this.setErrorMessageList();
        if (this.errorMessages.length > 0) {
            this.showErrorSnak();
            return;
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            title: "Approval Comment",
            submitButtonText: "Approve",
            labelForComment: "Comment"
        }
        const dialogRef = this.dialog.open(CommentDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != "0") {
                    this.approve(data)
                }
            }
        );
    }
    approve(comment: string) {
        
        var appsEnabled: number[] = [];
        this.applications.forEach(
            (ali: ApplicationStatus) => {
                if (ali.enabled) appsEnabled.push(ali.id);
            }
        )
        var qs = this.questionSetControls[0].GetQuestionSet();
        let langResult: any = this.questionSetControls[0].questionSetLanguage;
        if (qs !== undefined) {
            console.log("Approval");
            console.log(qs);
            console.log(appsEnabled);
            this.questionSetService.ApproveQuestionSet(this.EditID, appsEnabled, comment, qs, langResult).subscribe(resp => {
                if (resp.body.message != "" && resp.body.message != null) {
                    var msg = resp.body.message.replaceAll(",", "\n");
                    alert("Approve failed: " + msg);
                } else {
                    this.EditID = resp.body.result;
                    if (this.questionSetRecord.questionSetHeader.questionSetStatus == 3) {
                        this.version++;
                    }
                    this.questionSetRecord.questionSetHeader.questionSetStatus = 3;
                    
                    this.location.replaceState('/questionset/edit?id=' + this.EditID);
                    alert("Approved");
                }
            });
            this.questionSetControls[0].CalculateQuestionDetail();

            
        }
    }
    save() {
        var appsEnabled: number[] = [];
        this.applications.forEach(
            (ali: ApplicationStatus) => {
                if (ali.enabled) appsEnabled.push(ali.id);
            }
        )
        let langResult: any = this.questionSetControls[0].questionSetLanguage;
        var qs = this.questionSetControls[0].GetQuestionSet();
        
        if (qs !== undefined) {
            console.log("SAVING " + this.EditID)
            console.log(qs);
            console.log(appsEnabled);
            this.questionSetService.SaveQuestionSet(this.EditID, appsEnabled, qs, langResult).subscribe(resp => {
                if (resp.body.message != "" && resp.body.message != null) {
                    alert("Save Failed");
                } else {
                    if (this.questionSetRecord.questionSetHeader.questionSetStatus == 3) {
                        this.version++;
                    }
                    this.questionSetRecord.questionSetHeader.questionSetStatus = 1;
                    this.EditID = resp.body.result;
                    this.location.replaceState('/questionset/edit?id=' + this.EditID);
                }
            });
            
            //this.setInternalOrderOffsets();
            this.setErrorMessageList();
        }
    }
    
    showErrorSnak() {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.innerHTML = '<span class="material-icons">check_circle</span>'
            + '<div class="snakcontent">'
            + 'Tree has errors approval blocked &nbsp;'
            + '</div>'
            + '<span class="material-icons postfix" style="" onclick="document.querySelector(\'.fakesnak\').remove()">close</span>';
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
        setTimeout(function () {
            document.querySelector(".fakesnak")?.remove();
        }, 4000);
    }
    usage() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "rounded-dialog";
        dialogConfig.data = {
            questionSetHeaderID: this.questionSetControls[0].questionSetHeaderId,
            currentVersion: this.version
        }
        const dialogRef = this.dialog.open(QuestionSetToDecisionTreeUsageDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data != "0") {
                    
                }
            }
        );
    }
    setInternalOrderOffsets() {

    }
    dragOver(event: any) {
        let eox = event.pageX - 10;
        if (eox < 670) {
            eox = 650;
        }
        this.e.nativeElement.querySelector("#leftSide").style.width = eox + "px";
    }

    dragStart(event: any) {
    }

    dragDrop(event: any) {
    }
}
