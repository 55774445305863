import { Component, Input } from '@angular/core';
import { DecisionTreeSettings } from '../../classes/decisiontreesettings';

@Component({
  selector: 'config-error',
  templateUrl: './config-error.component.html',
  styleUrls: ['./config-error.component.scss']
})
export class ConfigErrorComponent {
    @Input() tree!: DecisionTreeSettings;
    @Input() isSettings: boolean = false;
    @Input() p!: any;
    isTriggered = false;
    hasErrors = false;
    completionMessage = '';

    constructor() {
        console.log('Config error constructor');
    }

    checkErrors() {
        if (this.checkClients()) {
            if (this.checkPath() && this.checkDrugs()) {
                return true;
            } else if (!this.checkPath() && this.checkDrugs()) {
                //path is false, drugs are valid
                this.completionMessage = "complete the Path information";
                return false;
            } else if (this.checkPath() && !this.checkDrugs()) {
                //path is valid, drugs are false
                this.completionMessage = "complete the Drug information";
                return false;
            } else {
                this.completionMessage = "complete the Path and Drug information";
                return false;
            }
        } else {
            this.completionMessage = "complete the client selection"
            return false;
        }
    }

    checkClients() {
        if (this.tree.hierarchy.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    checkPath() {
        if (this.isSettings) {
            let validCase = false;
            let hasDetermination = false;

            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                if (this.tree.hierarchy[i].cases.length > 0 || this.tree.hierarchy[i].noCase) {
                    validCase = true;
                }

                if (this.tree.hierarchy[i].determination.length > 0) {
                    hasDetermination = true;
                }
            }

            return validCase && hasDetermination;
        } else {
            let hasCase = false;
            let hasDetermination = false;

            if (this.tree.noCase) {
                hasCase = true;
            }

            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                if (this.tree.hierarchy[i].cases.length > 0) {
                    if (this.tree.noCase) {
                        this.tree.hierarchy[i].cases = [];
                    }
                    hasCase = true;
                }

                if (this.tree.hierarchy[i].determination.length > 0) {
                    hasDetermination = true;
                }
            }

            return (hasCase || this.tree.noCase) && hasDetermination;
        }
    }


    checkDrugs() {
        if (this.isSettings) {
            var validDrugSelection = false;
            if (this.tree.noDrug) {
                if (this.tree.hierarchy.length > 0) {
                    for (let i = 0; i < this.tree.hierarchy.length; i++) {
                        if (this.tree.hierarchy[i].drugs.length == 0) {
                            validDrugSelection = true;
                        }
                    }
                }
            } else {
                if (this.tree.hierarchy.length > 0) {
                    for (let i = 0; i < this.tree.hierarchy.length; i++) {
                        if (this.tree.hierarchy[i].drugs.length > 0) {
                            validDrugSelection = true;
                        } else {
                            if (this.tree.hierarchy[i].noDrug) {
                                validDrugSelection = true;
                            }
                        }
                    }
                }
            }
            return validDrugSelection;
        } else {
            var validDrugSelection = false;
            if (this.tree.noDrug) {
                if (this.tree.hierarchy.length > 0) {
                    for (let i = 0; i < this.tree.hierarchy.length; i++) {
                        if (this.tree.hierarchy[i].drugs.length == 0) {
                            validDrugSelection = true;
                        }
                    }
                }
            } else {
                if (this.tree.hierarchy.length > 0) {
                    for (let i = 0; i < this.tree.hierarchy.length; i++) {
                        if (this.tree.hierarchy[i].drugs.length > 0) {
                            validDrugSelection = true;
                        }
                    }
                }
            }
            return validDrugSelection;
        }
    }
}
