<form>
<mat-form-field class="override-chip-list" style="width:100%" appearance="outline" color="accent">
    <mat-label>Overrides</mat-label>
    <mat-chip-grid #chipGrid aria-label="override selection">
        <!--@for (override of overrides; track override) {-->
        <section *ngFor="let override of overrides">
            <mat-chip-row (removed)="remove(override)">
                {{override.Code}} - {{override.Reason}}
                <button matChipRemove [attr.aria-label]="'remove ' + override">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-chip-row>
        </section>
        <!--}-->
    </mat-chip-grid>
    <input placeholder="New override..." #overrideInput [formControl]="overrideCtrl"
           [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="add($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"
                      disableCloseOnSelect>
        <!--@for (override of filteredoverrides | async; track override) {-->
        <section *ngFor="let override of filteredOverrides | async ">
            <mat-option [value]="override.Code">{{override.Code}} - {{override.Reason}}</mat-option>
        </section>
    </mat-autocomplete>
</mat-form-field>
</form>
