import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../classes/client';
import { Group, GroupSimple } from '../../classes/group';
import { Hierarchy, HierarchyTies } from '../../classes/hierarchy';
import { PCN, PCNSimple } from '../../classes/pcn';
import { Plan, PlanSimple } from '../../classes/plan';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { ClientService } from '../../shared/services/client.service';
import { HierarchyService } from '../hierarchy.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditiveOperatorOrHigher } from 'typescript';

@Component({
    selector: 'app-hierarchy-add-modal',
    templateUrl: './hierarchy-add-modal.component.html',
    styleUrls: ['./hierarchy-add-modal.component.scss']
})
export class HierarchyAddModalComponent {
    currentFilteredClient!: Client;
    @Output() event: EventEmitter<any> = new EventEmitter();
    user!: User;
    hierarchyTies: HierarchyTies[] = [];
    submitted: boolean = false;
    hierarchy: Hierarchy = new Hierarchy();
    clients: Client[] = [];
    selectedClient!: Client;
    pcns: PCN[] = [];
    selectedPCN!: PCN;
    plans: Plan[] = [];
    selectedPlan!: Plan;
    groups: Group[] = [];
    selectedGroup!: Group;

    addForm = this.fb.group({
        description: [''],
        pcnList: [''],
        planList: [''],
        groupList: [''],
        start: [new Date(), Validators.required],
        end: [new Date(), Validators.required],
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<HierarchyAddModalComponent>,
        private authService: AuthService,
        private hierarchyService: HierarchyService,
        private clientService: ClientService,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.currentFilteredClient = data.currentFilteredClient;
    }

    ngOnInit() {
        this.populateLists();
    }

    populateLists() {
        this.populateClients();
    }

    populateClients() {
        this.clients = this.clientService.getClients();
        this.selectedClient = this.clients.find(x => x.clientID === this.currentFilteredClient.clientID)!;
        this.populatePCNs();
        //this.clientService.getClients().subscribe(resp => {
        //    if (resp.body) {
        //        this.clients = this.clientService.mapClientResponse(resp);
        //        this.selectedClient = this.clients.find(x => x.clientID === this.currentFilteredClient.clientID)!;
        //        console.log(this.selectedClient);
        //        this.populatePCNs();
        //    }
        //});
    }

    populatePCNs() {
        this.clientService.getPCNByClient(this.selectedClient.clientID!).subscribe(resp => {
            if (resp.body) {
                this.pcns = this.clientService.mapPCNResponse(resp);
                //console.log(this.pcns);
            }
        });
    }

    onSelectPCN() {
        //console.log(this.addForm.value.pcnList);
        this.selectedPCN = this.pcns.find(x => x.pcnReferenceID?.toString() == this.addForm.value.pcnList)!;
        this.populatePlans();
    }

    populatePlans() {
        if (this.selectedPCN) {
            this.clientService.getPlanBenefitByPCN(this.selectedPCN.pcn!).subscribe(resp => {
                if (resp.body) {
                    this.plans = this.clientService.mapPlanResponse(resp);
                    console.log(this.plans);
                }
            });
        }
    }

    onSelectPlan() {
        console.log(this.addForm.value.planList);
        this.selectedPlan = this.plans.find(x => x.planReferenceID?.toString() == this.addForm.value.planList)!;
        this.populateGroups();
    }

    populateGroups() {
        if (this.selectedPlan && this.selectedPCN) {
            console.log("plan: " + this.selectedPlan.benefitPlanId);
            console.log("pcn: " + this.selectedPCN.pcn);
            this.clientService.getGroupBenefitByPlanAndPCN(this.selectedPlan.benefitPlanId!, this.selectedPCN.pcn!).subscribe(resp => {
                if (resp.body) {
                    this.groups = this.clientService.mapGroupResponse(resp);
                    console.log(this.groups);
                }
            });
        }
    }

    onSelectGroup() {
        this.selectedGroup = this.groups.find(x => x.benefitGroupId == this.addForm.value.groupList)!;
        console.log(this.selectedGroup);
    }

    save() {
        console.log(this.findInvalidControlsRecursive(this.addForm));
        //Check the dates
        if (this.addForm.valid && this.selectedClient) {

            const parsedFromDate = this.addForm.value.start?.toISOString();
            const parsedToDate = this.addForm.value.end?.toISOString();


            if (this.selectedPCN == undefined) {
                this.selectedPCN = new PCN();
                this.selectedPCN.pcnDescription = "All"
                this.selectedPCN.pcn = "-1";
            }
            if (this.selectedPlan == undefined) {
                this.selectedPlan = new Plan();
                this.selectedPlan.planDescription = "All"
                this.selectedPlan.benefitPlanId = '';
            }
            if (this.selectedGroup == undefined) {
                this.selectedGroup = new Group();
                this.selectedGroup.groupName = "All"
                this.selectedGroup.benefitGroupId = '';
            }
            this.hierarchyService.addHierarchy(
                this.selectedClient.clientID!, this.selectedPCN.pcn!, this.selectedPCN.pcnDescription!,
                this.selectedPlan.benefitPlanId!, this.selectedPlan.planDescription!, this.selectedGroup.benefitGroupId!, this.selectedGroup.groupName!,
                this.user.id!, "2023-01-01", "2999-12-31")
                .subscribe(
                    (data) => {
                        if (data.status == 200) {
                            //successfully saved
                            this.dialogRef.close("1");
                        }
                    },  //changed
                    (err) => {
                        console.log(err)
                    },
                    () => console.log("Done")
                );
        } else {
            alert("Missing submission information!");
        }
    }
    close() {
        this.hierarchy = new Hierarchy();
        this.dialogRef.close("0");
    }

    public findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
        var invalidControls: string[] = [];
        let recursiveFunc = (form: FormGroup | FormArray) => {
            Object.keys(form.controls).forEach(field => {
                const control = form.get(field);
                if (control!.invalid) invalidControls.push(field);
                if (control instanceof FormGroup) {
                    recursiveFunc(control);
                } else if (control instanceof FormArray) {
                    recursiveFunc(control);
                }
            });
        }
        recursiveFunc(formToInvestigate);
        return invalidControls;
    }
}
