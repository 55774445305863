import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
    constructor(public auth: AuthService, public router: Router) { }
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLoggedIn = this.auth.isAuthenticated();
        if (!isLoggedIn) {
            return this.router.parseUrl('/unauthorized');
        }
        return isLoggedIn;
    }

    //private async canActivatePage(route: ActivatedRouteSnapshot): Promise<boolean> {
    //    var pageName = route.routeConfig!.path;
    //    if (pageName!.indexOf('/') > 0) {
    //        pageName = pageName!.substring(0, pageName!.indexOf('/'));
    //    }

    //    return await this.securityService.HasPermission(Module.BaseAngularAppModule, pageName as Permission);
    //}
}
