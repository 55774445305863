

<div style="width:30%;display:inline-block;">
    <h3>{{headerInfo?.title ?? ""}}</h3>
    <p>{{headerInfo?.description ?? ""}}</p>
</div>
<div style="width:30%;display:inline-block;">
    <form id="enabledApplicationForm" [formGroup]="enabledApplicationForm" (ngSubmit)="saveQuestionSet()">
        <span *ngFor="let a of applicationStatusList">
            <input [id]="'app_'+a.id" formcontrolname="applicationList" name="applicationList" type="checkbox" value="{{a.id}}" [checked]="a.enabled == true" /> <label for="">{{a.name}}</label>
        </span>
    </form>
</div>
<div style="width:30%;display:inline-block;text-align:right;">
    <button class="btn btn-outline-secondary" id="tree-update" type="button" role="tab" aria-selected="false" (click)="reloadChanges()">Update Display</button>
    &nbsp;
    <button class="btn btn-primary" id="tree-save" (click)="saveQuestionSet()">SAVE</button>
    &nbsp;
    <button class="btn btn-primary" id="tree-approve" (click)="approveQuestionSet()">APPROVE</button>
    &nbsp;
    <button class="btn btn-primary" id="test-view" (click)="openinteractiveQuestionAnswerModal()">Test</button>
</div>
<div class="col">
    <mat-tab-group dynamicHeight>
        <!--TODO: Determine Loading issue    background-color: #FFDDDD;-->
        <mat-tab label="Tree">
            <svg id="svgQuestionDisplay"
                 style="width: 99%; min-height: 600px; border: 1px solid #AAAAAA; background-color: #f2f2f2"
                 oncontextmenu="return false;">
                <g id="svgGroup"></g>
                <g id="svgControls"></g>
            </svg>
            <button mat-raised-button color="primary" id="tree-save" (click)="openAddQuestionModal()">Add Question</button>
        </mat-tab>
        <mat-tab label="Question List">
            <div *ngIf="questionSetControl.GetQuestionSet() !== undefined">
                <div *ngFor="let q of questionSetControl.GetQuestions() | sortBySequence">
                    <!--<app-question-template [question]="q" [questionSetControl]="questionSetControl"></app-question-template>-->
                    <app-edit-question-list-item [question]="q" [p]="this"
                                                 [questionSetControl]="questionSetControl">
                    </app-edit-question-list-item>
                </div>
            </div>
            <button mat-raised-button color="primary" id="tree-save" (click)="openAddQuestionModal()">Add Question</button>
        </mat-tab>
        <!--<mat-tab label="Language">
            <div id="tree-view-language" style=" padding: 10px;">
                <div *ngFor="let q of questionSetControl.GetQuestions() | sortBySequence">
                    <app-question-language [question]="q" [questionSetControl]="questionSetControl" ></app-question-language>
                </div>
            </div>
        </mat-tab>-->
    </mat-tab-group>
    <div id="interactiveQuestionAnswerModal">
        <div id="modalOperationControls">
            <button class="btn btn-sm btn-outline-primary" onclick='hideReviewQuestionArea()'>X</button>
        </div>
        <div id="interactiveQuestionAnswerSection"></div>
        <button onclick='alert(JSON.stringify(getDecisionTreeAnswers())); return false;'>Test Save</button>
    </div>
</div>
<div class="col-md-12" style="text-align:right;">
    <button class="btn btn-primary" id="tree-save" type="button" role="tab" aria-selected="false" (click)="saveQuestionSet()">SAVE</button>
</div>




