import { ResolveFn } from '@angular/router';

import { inject } from '@angular/core';
import { combineLatest, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from './auth.service';

export const permissionResolver: ResolveFn<boolean> = (route, state) => {
    const authService = inject(AuthService);
    if (localStorage.getItem('permissions') === null) {
        return authService.isAuthenticated$
            .pipe(
                switchMap(_ => authService.getPermissions()
                    .pipe(
                        tap((t) => {
                            localStorage.setItem('permissions', JSON.stringify(t))
                        })
                    )),

                map((t) => {
                    return t !== null;
                })
            )
    } else {
        return of(true);
    }

};
