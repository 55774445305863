<h2 mat-dialog-title>Import Catalog Item</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>

<mat-dialog-content>
    <form id="importForm" [formGroup]="importQSForm" (ngSubmit)="onSubmit()">

        <div class="row">
            <label for="fu">File</label>
            <input type="file" class="full-width"
                   (change)="onFileSelected($event)" />
        </div>

        <div class="form-group">
            <label for="newName">Name:</label>
            <input type="text" autocomplete="off" class="full-width" id="newName" formControlName="newName" placeholder="Enter a name">
        </div>

        <div class="form-group">
            <label for="typeSelect">Type</label>
            <select class="form-select" id="typeSelect" formControlName="typeSelect">
                <option value="1">PA</option>
                <option value="2">1st Appeal</option>
                <option value="3">2nd Appeal</option>
                <option value="4">3rd Appeal</option>
            </select>
        </div>

        <div class="form-group">
            <label for="clientSelect">Client</label>
            <select class="form-select" id="clientSelect" formControlName="clientSelect"  (change)="onSelectClient()">
                <option *ngFor="let c of clients; index as i; first as isFirst" value="{{c.clientID}}">{{c.clientName}}</option>
            </select>
        </div>



        <p>Form Status: {{ importQSForm.status }}</p>



    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="importForm" 
            mat-raised-button color="primary">Import</button>
    <!--[disabled]="!importForm.valid"-->
</mat-dialog-actions>
