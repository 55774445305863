import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
    public title: string = "Comment";
    public labelForComment: string = "labelForComment";
    public submitButtonText: string = "submitButtonText";
    public dialogText: string = "";
    public errorList: string[] = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ErrorDialogComponent>
    ) {
        if (Object.keys(data).indexOf("title") > -1) {
            this.title = data.title;
        }
        if (Object.keys(data).indexOf("labelForComment") > -1) {
            this.labelForComment = data.labelForComment;
        }
        if (Object.keys(data).indexOf("dialogText") > -1) {
            this.dialogText = data.dialogText;
        }
        if (Object.keys(data).indexOf("submitButtonText") > -1) {
            this.submitButtonText = data.submitButtonText;
        }
        if (Object.keys(data).indexOf("errorList") > -1) {
            this.errorList = data.errorList;
        }
        //console.log("this.errorList", this.errorList);
    }
    close() {
        this.dialogRef.close("0");
    }
    
}
