import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from '../../classes/client';
import { Group } from '../../classes/group';
import { PCN } from '../../classes/pcn';
import { Plan } from '../../classes/plan';
import { ApiPaths } from '../environments/routes';
import { environment } from '../environments/environments';
import { AuthService } from '../auth/auth.service';
import { User } from '../../classes/user';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
    user!: User;
    constructor(private http: HttpClient, private authService: AuthService) {
        this.user = this.authService.getUserLoginInfo();
        
    }

    private getHeaders() {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getClients(): Client[] {


        var clients: Client[] = [];
        this.user.clients!.forEach(fe => {
            var c = new Client()
            c.clientID = fe.clientID;
            c.clientName = fe.clientName;
            clients.push(c);
        });
        //var httpHeaders = this.getHeaders();
        //
        //return this.http.get<any>(
        //    environment.baseUrl + ApiPaths.GetClients, { headers: httpHeaders, observe: 'response' });
        return clients;
    }

    //public mapClientResponse(response: HttpResponse<any>, fromUser = false) {
    //    const clients: Client[] = [];
    //    if (fromUser) {
    //        for (var [k, v] of Object.entries(response.body['clientInfoList'])) {
    //            const c = new Client();
    //            if (v instanceof Object) {
    //                c.clientID = v['clientID' as keyof typeof v].toString();
    //                c.clientName = v['clientName' as keyof typeof v].toString();
    //                clients.push(c);
    //            }
    //        }
    //    } else {
    //        for (var [k, v] of Object.entries(response.body)) {
    //            const c = new Client();
    //            if (v instanceof Object) {
    //                c.clientID = v['clientID' as keyof typeof v].toString();
    //                c.clientName = v['clientName' as keyof typeof v].toString();
    //                clients.push(c);
    //            }
    //        }
    //    }
    //
    //    return clients;
    //}

    public getPCNByClient(clientID: string): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetPCNByClient, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapPCNResponse(response: HttpResponse<any>, insertAll: boolean = true) {
        const pcns: PCN[] = [];
        if (insertAll) {
            const all = new PCN();
            all.pcnDescription = "All";
            all.pcn = "-1";
            all.pcnReferenceID = -1;
            pcns.push(all);
        }
        for (var [k, v] of Object.entries(response.body)) {
            const p = new PCN();
            if (v instanceof Object) {
                p.pcnReferenceID = parseInt(v['pcnReferenceID' as keyof typeof v].toString());
                p.pcn = v['pcn' as keyof typeof v].toString();
                p.pcnDescription = v['pcnDescription' as keyof typeof v].toString();
                pcns.push(p);
            }
        }
        return pcns;
    }

    public getPlanBenefitByPCN(pcn: string): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('PCN', pcn);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetPlanBenefitByPCN, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapPlanResponse(response: HttpResponse<any>, insertAll: boolean = true) {
        const plans: Plan[] = [];
        if (insertAll) {
            const all = new Plan();
            all.planDescription = "All";
            all.planReferenceID = -1;
            all.benefitPlanId = '';
            plans.push(all);
        }
        for (var [k, v] of Object.entries(response.body)) {
            const p = new Plan();
            if (v instanceof Object) {
                p.planReferenceID = parseInt(v['planReferenceID' as keyof typeof v].toString());
                p.benefitPlanId = v['benefitPlanID' as keyof typeof v].toString();
                p.planDescription = v['planDescription' as keyof typeof v].toString();
                p.pcn = v['pcn' as keyof typeof v].toString();

                plans.push(p);
            }
        }
        return plans;
    }

    public getGroupBenefitByPlanAndPCN(benefitPlanId: string, pcn: string): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('benefitPlanId', benefitPlanId);
        queryParams = queryParams.append('PCN', pcn);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetGroupBenefitByPlanAndPCN, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapGroupResponse(response: HttpResponse<any>, insertAll: boolean = true) {
        const groups: Group[] = [];
        if (insertAll) {
            const all = new Group();
            all.groupName = "All";
            all.benefitGroupId = '';

            groups.push(all);
        }
        for (var [k, v] of Object.entries(response.body)) {
            const g = new Group();
            if (v instanceof Object) {
                g.groupReferenceID = parseInt(v['groupReferenceID' as keyof typeof v].toString());
                g.benefitGroupId = v['benefitGroupID' as keyof typeof v].toString();
                g.groupName = v['groupName' as keyof typeof v].toString();
                g.benefitPlanId = v['benefitPlanID' as keyof typeof v].toString();
                g.pcn = v['pcn' as keyof typeof v].toString();
                groups.push(g);
            }
        }
        return groups;
    }
}
