import { Injectable, EventEmitter, Output } from '@angular/core';
import { AnswerChoiceCommonType, ApplicationStatus, NextQuestionCondition, Question, QuestionSet, QuestionTypeDate, QuestionTypeFreeText, QuestionTypeNumeric, QuestionTypeSelect, RangeComparison, SingleComparison } from '../classes/questionset';
import { QuestionModalComponent } from '../question/question-edit/question-modal/question-modal.component';
//import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddQuestionModalComponent } from '../question/question-edit/add-question-modal/add-question-modal.component';
import { SafeHtml } from '@angular/platform-browser';
import { environment } from './environments/environments';
import { DecisionTreeSettings } from '../classes/decisiontreesettings';

//declare var controlHandler: any;


@Injectable({
    providedIn: 'root'
})

export class QuestionSetControl {
    private cacheDetails?: QuestionDetails;
    public questionErrorMessages: Record<string, string[]> = {};
    public cacheInitialized: boolean = false;
    private questionSet: QuestionSet | undefined;
    initialOrder: number | undefined;
    questionSetTypeId: number | undefined;
    closeResult = '';
    public showQuestions: boolean = true;
    private debugQSC = environment.openid.showDebugInformation;
    private lines: any[] = [];
    public applicationStatusList: ApplicationStatus[] = [];
    public questionSetLanguage: any[] = [];
    public settings: DecisionTreeSettings | undefined;
    public questionSetHeaderId: number | undefined;
    private canDrawTree: boolean = false;;
    public inCatalog = false;
    public internalOrderOffset: number = 0;
    constructor(
        private dialog: MatDialog,
    ) {
        //controlHandler(this);
    }
    GetNextQuestionID() {
        if (this.questionSet !== undefined && this.questionSet.questions !== undefined && this.questionSet.questions.length > 0) {
            var sqs = this.questionSet.questions.sort((a, b) => { return parseInt(b.id) - parseInt(a.id) });
            var q1 = sqs[0];
            var nxtid = (parseInt(q1.id) + 1).toString();
            console.log("Next for set is " + nxtid);
            return nxtid;
        } else {
            return "101";
        }
    }
    GetQuestionSet() {
        return this.questionSet;// ?? new QuestionSet({ questionSetHeader:{id:-1, title:"Nothing loaded"}});
    }
    getTitle() {
        if (this.questionSet !== undefined && this.questionSet?.questionSetHeader !== undefined) {
            return this.questionSet?.questionSetHeader.title;
        }
        return "[header name missing]";
    }
    getLabel(): string {
        return "question-set-" + this.initialOrder;
    }
    GetQuestions() {
        var qa: Question[] = [];
        return this.questionSet?.questions ?? qa;// ?? new QuestionSet({ questionSetHeader:{id:-1, title:"Nothing loaded"}});
    }
    LoadQuestionSet(data: any) {
        this.questionSet = new QuestionSet(data);
        return;
    }
    GetQuestionByID(id: any) {
        if (this.questionSet != null && this.questionSet.questions != null) {
            var qa = this.questionSet.questions.filter(q => q.id == id);
            if (qa.length == 1) {
                return qa[0];
            }
        }
        return null;
    }
    RemoveQuestionByID(id: any, clearNew:boolean = false) {
        if (this.questionSet == null) { return; }
        var q = this.GetQuestionByID(id);
        var newId = q?.defaultNextQuestionID ?? "";
        if (clearNew) {
            newId = "";
        }
        if (q?.sequenceNumber == 1 && (q?.defaultNextQuestionID != "End" && q?.defaultNextQuestionID != "Final")) {
            var newFirstQuestion = this.GetQuestionByID(q?.defaultNextQuestionID);
            if (newFirstQuestion != null) {
                newFirstQuestion.sequenceNumber = 1;
            }
        }

        //console.log(this.cacheDetails, "ID in ", id, " to ", newId);
        var self = this;
        //TODO: review for poetntial conflict where question is removed change causes a pointer to itself
        // Should not ever occur
        this.cacheDetails?.reverseMap[id]?.forEach(function (correctiveID: string) {
            var currentQuestion = self.GetQuestionByID(correctiveID);
            if (currentQuestion != null) {
                if (currentQuestion.defaultNextQuestionID == id) {
                    currentQuestion.defaultNextQuestionID = newId;
                }
                if (self.GetQuestionTypeFromQuestion(currentQuestion) == "Select") {
                    var qts = (<QuestionTypeSelect>currentQuestion.questionType);
                    qts.choice.forEach(
                        function (currentChoice: AnswerChoiceCommonType) {
                            if (currentChoice.nextQuestionID == id) {
                                currentChoice.nextQuestionID = newId;
                            }
                        }
                    );
                    self.reassignNQCEndpoints(qts.nextQuestionCondition, id, newId);
                } else
                    if (self.GetQuestionTypeFromQuestion(currentQuestion) == "DateTime") {
                        var qtd = <QuestionTypeDate>currentQuestion.questionType;
                        self.reassignNQCEndpoints(qtd.nextQuestionCondition, id, newId);
                    } else
                        if (self.GetQuestionTypeFromQuestion(currentQuestion) == "Numeric") {
                            var qtn = <QuestionTypeNumeric>currentQuestion.questionType;
                            self.reassignNQCEndpoints(qtn.nextQuestionCondition, id, newId);
                        }
            }
        });
        // Reassignments complete NOW DELETE
        this.questionErrorMessages[id] = [];
            
        this.questionSet.questions =
            this.questionSet.questions.filter(q => q.id != id);
        this.recalculateInternalOrder();
        this.CalculateQuestionDetail();
        
        if (this.debugQSC) console.log(this.cacheDetails);
    }
    private reassignNQCEndpoints(nqcList: NextQuestionCondition[], currentId: string, newId: string) {
        nqcList.forEach(function (nqc: NextQuestionCondition) {
            if (nqc.comparison.nextQuestionID == currentId) {
                nqc.comparison.nextQuestionID = newId;
            }
        });
    }
    GetQuestionTypeFromQuestion(question: Question) {
        if (question.questionType instanceof QuestionTypeSelect) {
            return "Select";
        } else if (question.questionType instanceof QuestionTypeFreeText) {
            return "FreeText";
        } else if (question.questionType instanceof QuestionTypeDate) {
            return "DateTime";
        } else if (question.questionType instanceof QuestionTypeNumeric) {
            return "Numeric";
        }
        return undefined;
    }
    GetSelectType(q: Question) {
        return <QuestionTypeSelect>q.questionType;
    }
    GetDateType(q: Question) {
        return <QuestionTypeDate>q.questionType;
    }
    GetNumericType(q: Question) {
        return <QuestionTypeNumeric>q.questionType;
    }
    // Specific test condition for right side show;
    ClearNextSetTo(nextQSGuid: string): boolean{
        var change = false;
        if (nextQSGuid != "") {
            var clearTarget = "next-" + nextQSGuid;
            this.questionSet?.questions.forEach((q: Question) => {
                if (q.defaultNextQuestionID == clearTarget) { q.defaultNextQuestionID = ""; change = true; }
                if (this.GetQuestionTypeFromQuestion(q) == "Select") {
                    var qts = (<QuestionTypeSelect>q.questionType);
                    qts.choice.forEach((c: AnswerChoiceCommonType) => {
                        if (c.nextQuestionID == clearTarget) { c.nextQuestionID = "Default"; change = true; }
                    });
                    qts.nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
                        if (nqc.comparison.nextQuestionID == clearTarget) { nqc.comparison.nextQuestionID = ""; change = true; }
                    });
                } else if (this.GetQuestionTypeFromQuestion(q) == "Numeric") {
                    (<QuestionTypeNumeric>q.questionType).nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
                        if (nqc.comparison.nextQuestionID == clearTarget) { nqc.comparison.nextQuestionID = ""; change = true; }
                    });
                } else if (this.GetQuestionTypeFromQuestion(q) == "DateTime") {
                    (<QuestionTypeDate>q.questionType).nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
                        if (nqc.comparison.nextQuestionID == clearTarget) { nqc.comparison.nextQuestionID = ""; change = true; }
                    });
                }
            });
        }
        return change;
    }
    ValidateQuestion(question: Question) {
        //console.log("Validation of Question " + question.internalOrder + " " + question.text)
        var valid = true;
        var errorMessages = [];
        var questionOrder = question.internalOrder + this.internalOrderOffset; 
        var potentialPathways = 0;
        var pathwayDecisions = 0;
        var totalNonQuestionPathways = 0;
        if (!this.ValidateQuestionInTree(question)) {
            errorMessages.push("Question " + questionOrder + " is not part of the flow. Please connect it to other questions.")
            valid = false;
        }

        var treeHasPath = this.ValidateQuestionConnectsToTree(question);

        if (!treeHasPath) {
            errorMessages.push("An Answer from question " + questionOrder + " doesn't have a path. Please select a path.")
            valid = false;
        }
        if (question.text.length <= 2) {
            errorMessages.push(" Question " + questionOrder + " is to short. Please provide a longer answer.")
            valid = false;
        }
        
        if (this.GetQuestionTypeFromQuestion(question) == "Select") {
            var qts = (<QuestionTypeSelect>question.questionType);
            if (qts.choice.length < 2) {
                errorMessages.push("Question " + questionOrder + " requires a minimum of 2 choices. Please provide more choices.")
                valid = false;
            }
            qts.choice.forEach((c: AnswerChoiceCommonType) => {
                potentialPathways++;
                if (c.nextQuestionID != "Default") {
                    totalNonQuestionPathways++;
                }

                if ((c.choiceText ?? "").length < 1) {
                    errorMessages.push("An Answer from Question " + questionOrder + " is to short. Please Provide a longer answer.")
                    valid = false;
                }
                if (c.nextQuestionID == "Final" && c.action.length == 0) {
                    //console.log("final on answer does not have decision")
                    errorMessages.push("An Answer from Question " + questionOrder + " does not have decision. Please provide a Decision.")
                    valid = false;
                } else if (c.action.length > 0) {
                    pathwayDecisions++;
                }
            });
            qts.nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
                potentialPathways++;
                if (nqc.comparison.nextQuestionID != "Default") {
                    totalNonQuestionPathways++;
                }
                var nqcEMessage = this.GetNextQuestionConditionErrormessage(nqc, "Numeric");
                if (nqcEMessage != "") {
                    errorMessages.push("A Condition from Question " + questionOrder + nqcEMessage)
                    valid = false;
                }
                if (nqc.comparison.nextQuestionID == "Final" && nqc.comparison.action.length == 0) {
                    //console.log("NQC final on answer does not have decision")
                    errorMessages.push("A Condition from Question " + questionOrder + " does not have decision. Please provide a Decision.")
                    valid = false;
                } else if (nqc.comparison.action.length > 0) {
                    pathwayDecisions++;
                }
            })
        } else if (this.GetQuestionTypeFromQuestion(question) == "Numeric") {
            (<QuestionTypeNumeric>question.questionType).nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
                potentialPathways++;
                if (nqc.comparison.nextQuestionID != "Default") {
                    totalNonQuestionPathways++;
                }
                var nqcEMessage = this.GetNextQuestionConditionErrormessage(nqc, "Numeric");
                if (nqcEMessage != "") {
                    errorMessages.push("A Condition from Question " + questionOrder + nqcEMessage)
                    valid = false;
                }
                if (nqc.comparison.nextQuestionID == "Final" && nqc.comparison.action.length == 0) {
                    errorMessages.push("A Condition from Question " + questionOrder + " does not have decision. Please provide a Decision.")
                    valid = false;
                } else if (nqc.comparison.action.length > 0) {
                    pathwayDecisions++;
                }
            });
        } else if (this.GetQuestionTypeFromQuestion(question) == "DateTime") {
            (<QuestionTypeDate>question.questionType).nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
                potentialPathways++;
                if (nqc.comparison.nextQuestionID != "Default") {
                    totalNonQuestionPathways++;
                }
                var nqcEMessage = this.GetNextQuestionConditionErrormessage(nqc, "Date");
                if (nqcEMessage != "") {
                    errorMessages.push("A Condition from Question " + questionOrder + nqcEMessage)
                    valid = false;
                }

                if (nqc.comparison.nextQuestionID == "Final" && nqc.comparison.action.length == 0) {
                    errorMessages.push("A Condition from Question " + questionOrder + " does not have decision. Please provide a Decision.")
                    valid = false;
                } else if (nqc.comparison.action.length > 0){
                    pathwayDecisions++;
                }
            });
        }
        /*  Question Level */
        //console.log("Decision compare " + pathwayDecisions + " " + potentialPathways)
        if (question.defaultNextQuestionID == "Final" && question.action.length == 0
            && (pathwayDecisions < potentialPathways && totalNonQuestionPathways < potentialPathways)
        ) {
            //debugger;
            errorMessages.push("Question " + questionOrder + " does not have decision. Please provide a Decision.")
            valid = false;
        }
        this.questionErrorMessages[question.id] = errorMessages;
        return valid;
    }
    GetNextQuestionConditionErrormessage(nqc: NextQuestionCondition, dataType:string) {
        var msg = "";
        if (nqc.comparison instanceof RangeComparison) {
            var rc = <RangeComparison>nqc.comparison;
            if ((rc).upperBoundComparisonValue == "") {
                msg = " has no value.";
            } else if ((rc).lowerBoundComparisonValue == "") {
                msg = " has no value.";
            } else if (dataType == "Date") {
                let a = new Date((rc).upperBoundComparisonValue).toISOString();
                let b = new Date((rc).lowerBoundComparisonValue).toISOString();
                //console.log(a, '<',  b)
                if (a < b) {
                    msg = " comparison is invalid.";
                }
            } else if (dataType == "Numeric") {
                let a = parseFloat((rc).upperBoundComparisonValue);
                let b = parseFloat((rc).lowerBoundComparisonValue);
                if (a < b) {
                    msg = " comparison is invalid.";
                }
            }

        } else {
            if ((<SingleComparison>nqc.comparison).comparisonValue == "") {
                msg = " has no value.";
            }
        }
        return msg;

    }

    ValidateQuestionInTree(q: Question) {
        //debugger;
        var valid = Object.keys(this.cacheDetails?.reverseMap).indexOf(q.id.toString()) > -1;
        //console.log("validating question in tree: " + q.id + " " + valid);
        return valid;
    }
    ValidateQuestionConnectsToTree(q: Question) {
        var idx = Object.keys(this.cacheDetails?.linkMap).indexOf(q.id.toString());
        if (idx < 0) {
            return false;
        }
        var lm = this.cacheDetails?.linkMap[q.id];
        return !(lm[0] == '' && lm.length == 1);
    }
    GetQuestionDetails() {
        if (this.cacheDetails == null) {
            if (this.debugQSC) console.log("<<<<<<<<<<<<< Calling Calculate");
            this.CalculateQuestionDetail();
        }
        return this.cacheDetails;
    }
    recalculateInternalOrder() {
        let ql = (<QuestionSet>this.questionSet).questions;
        let sortedql = ql.sort(function (a, b) { return a.internalOrder - b.internalOrder });
        let c = 0;
        sortedql.forEach((q: Question) => {
            if (q.internalOrder != ++c) {
                //if (this.debugQSC)
                console.log("internal order update", q.internalOrder, c);
                q.internalOrder = c;
            }
        });

    }
    CalculateQuestionDetail() {
        if (this.debugQSC) console.log(">>>>>>>> Calculate Called");
        let details = <QuestionDetails>{
            linkMap: {},
            reverseMap: {},
            linkToLevel: {},
            linkToLevelPositions: [],
            sequence: [],
            linkDetails: [],
            questionDetails: {},
            questionCount: 0,
            unlinkedQuestions: [],
            errorMessages: []

        };
        let ql = (<QuestionSet>this.questionSet).questions;
        details.questionCount = ql.length;
        // Primary link map generation
        ql.forEach((q: Question) => {
            // sequence ini
            details.sequence.push(String(q.id));
            // end sequence init
            details.questionDetails[q.id] = {};
            details.questionDetails[q.id].label = q.label ?? (q.text.substring(0, 75));
            //details.questionDetails[q.id].isValid = this.ValidateQuestion(q);
            //
            details.linkMap[q.id] = [];
            if (q.defaultNextQuestionID != null) {
                details.linkMap[q.id].push(q.defaultNextQuestionID);
                details.linkDetails.push({ "link": q.id + "-" + q.defaultNextQuestionID, "label": "Default", "reason": "Default Routing", action: q.action[0]?.actionName });
            }

            //q.nextQuestionCondition.forEach((nqc: NextQuestionCondition) => {
            //    if (nqc.comparison.nextQuestionID !== undefined && nqc.comparison.nextQuestionID != "" && nqc.comparison.nextQuestionID != "Default") {
            //        details.linkMap[q.id].push(nqc.comparison.nextQuestionID);
            //        details.linkDetails.push(({ "link": q.id + "-" + nqc.comparison.nextQuestionID, "reason": "Numeric condition", action: nqc.comparison.action[0]?.actionName }));
            //    }
            //});
            var type = this.GetQuestionTypeFromQuestion(q);
            if (type == "Numeric") {
                (<QuestionTypeNumeric>q.questionType).nextQuestionCondition.forEach(nqc => {
                    if (nqc.comparison.nextQuestionID !== undefined && nqc.comparison.nextQuestionID != "" && nqc.comparison.nextQuestionID != "Default") {
                        details.linkMap[q.id].push(nqc.comparison.nextQuestionID);
                        details.linkDetails.push(({ "link": q.id + "-" + nqc.comparison.nextQuestionID, "label": "x>y", "reason": "Numeric condition", action: nqc.comparison.action[0]?.actionName }));
                    }
                });
            } else if (type == "Select") {
                (<QuestionTypeSelect>q.questionType).nextQuestionCondition.forEach(nqc => {
                    if (nqc.comparison.nextQuestionID !== undefined && nqc.comparison.nextQuestionID != "" && nqc.comparison.nextQuestionID != "Default") {
                        details.linkMap[q.id].push(nqc.comparison.nextQuestionID);
                        details.linkDetails.push(({ "link": q.id + "-" + nqc.comparison.nextQuestionID, "label": "x>y", "reason": "Numeric condition", action: nqc.comparison.action[0]?.actionName }));
                    }
                });
                (<QuestionTypeSelect>q.questionType).choice.forEach(c => {
                    if (c.nextQuestionID !== undefined && c.nextQuestionID != "" && c.nextQuestionID != "Default") {
                        details.linkMap[q.id].push(c.nextQuestionID);
                    }
                    details.linkDetails.push(({
                        "link": q.id + "-" + (c.nextQuestionID == "Default" ? q.defaultNextQuestionID : c.nextQuestionID),
                        "label": c.sequence!.toString(), "reason": "Answered " + c.choiceText, action: c.action[0]?.actionName
                    }));
                });
            } else if (type == "DateTime") {
                (<QuestionTypeDate>q.questionType).nextQuestionCondition.forEach(nqc => {
                    if (nqc.comparison.nextQuestionID !== undefined && nqc.comparison.nextQuestionID != "" && nqc.comparison.nextQuestionID != "Default") {
                        details.linkMap[q.id].push(nqc.comparison.nextQuestionID);
                        details.linkDetails.push((
                            {
                                "link": q.id + "-" + nqc.comparison.nextQuestionID,
                                "label": "x>y",
                                "reason": "Numeric condition", action: nqc.comparison.action[0]?.actionName
                            }));
                    }
                });
            } else if (type == "FreeText") {
                // Default handling only
            }
        });
        // Reverse Map generation
        for (var l in details.linkMap) {
            details.linkMap[l].forEach((e: any) => {
                if (details.reverseMap[e] === undefined) details.reverseMap[e] = [];
                if (details.reverseMap[e].indexOf(l) == -1) details.reverseMap[e].push(l);
            });
        }


        if (this.debugQSC) { console.log("2 LinkMAP>> ", details.linkMap); }
        if (this.debugQSC) { console.log("2 RvrsMAP>> ", details.reverseMap); }

        details.linkMap["Start"] = [];
        let firstQuestionId: any = null;
        if (details.questionCount > 0) {
            var ioSorted = ql.sort(function (a, b) { return a.internalOrder - b.internalOrder });
            var seq = 2;
            ioSorted.forEach(s => {
                //debugger;
                if (firstQuestionId == null && details.reverseMap[s.id] === undefined) {
                    firstQuestionId = s.id;
                    s.sequenceNumber = 1;
                } else {
                    s.sequenceNumber = seq++;
                }
            });
            //firstQuestionId = ql.sort(function (a, b) { return a.internalOrder - b.internalOrder })[0].id;
            //if (this.debugQSC) console.log("src 1st question " + firstQuestionId)
            //while (details.reverseMap[firstQuestionId] !== undefined) {
            //    ql.filter(q => q.id == <string>firstQuestionId)[0].sequenceNumber = 9000;
            //    firstQuestionId = details.reverseMap[firstQuestionId][0];
            //    if (this.debugQSC) console.log("new 1st question " + firstQuestionId)
            //}
            //using first
            ql.filter(q => q.id == <string>firstQuestionId)[0].sequenceNumber = 1;
            details.linkMap["Start"].push(firstQuestionId);
            details.reverseMap[firstQuestionId] = [];
            details.reverseMap[firstQuestionId].push("Start");

            details.linkDetails.push(
                {
                    "link": "Start" + "-" + firstQuestionId,
                    "label": "",
                    "reason": "Initial Question", action: undefined
                });
        }



        if (this.debugQSC) { console.log("3 LinkMAP>> ", details.linkMap); }
        if (this.debugQSC) { console.log("3 RvrsMAP>> ", details.reverseMap); }
        // Question order May be futher optimized using levelmap
        //details.sequence.sort(() => Math.random() - 0.5);
        if (this.debugQSC) console.log("ini seq ", details.sequence);

        let outOfSequence: any[] = [];
        //details.sequence.forEach(e => { if (details.reverseMap[e] === undefined) { outOfSequence.push(e); } });
        details.sequence.forEach(e => { if (details.reverseMap[e] === undefined && e != firstQuestionId) { outOfSequence.push(e); } });
        details.unlinkedQuestions = outOfSequence;
        outOfSequence.forEach(e => {
            details.sequence.splice(details.sequence.indexOf(e), 1);
            details.sequence.unshift(e);
        });
        if (this.debugQSC) console.log("1st q in seq", firstQuestionId)
        if (firstQuestionId != null) {
            details.sequence.splice(details.sequence.indexOf(firstQuestionId), 1);
            details.sequence.unshift(firstQuestionId);
        }
        if (this.debugQSC) console.log("seq 2", details.sequence);
        var index = 0; var i = 0;
        while (index < details.questionCount && i < (details.questionCount * details.questionCount)) {
            var checkid = details.sequence[index];
            var isValid = true;
            details.reverseMap[checkid]?.forEach((e: string) => {
                var tpos = details.sequence.indexOf(e);
                //console.log("Evaluate ", checkid, index, e, tpos, details.sequence)
                if (tpos > index) {
                    isValid = false;
                }
            });
            if (isValid) {
                index++;
            } else {
                details.sequence.splice(index, 1);
                details.sequence.push(checkid);
            }
            i++;
        }
        if (this.debugQSC) console.log("Pre Out Of Seq", details.sequence);
        if (this.debugQSC) console.log("outOfSequence ", outOfSequence);
        outOfSequence.forEach(e => {
            details.sequence.splice(details.sequence.indexOf(e), 1);
            details.sequence.push(e);
        });
        // Resequence based on order
        if (this.debugQSC) console.log("Sequencing ", details.sequence);
        ql.forEach(q => {
            q.sequenceNumber = details.sequence.indexOf(String(q.id)) + 1;
        });
        //debugger;
        // link to level
        if (details.sequence.length > 0) {
            details.linkToLevel[details.sequence[0]] = 1;
            for (var i = 0; i < details.questionCount; i++) {
                var nextLevel = details.linkToLevel[details.sequence[i]] + 1;
                details.linkMap[details.sequence[i]].forEach((e: any) => {
                    if ((details.linkToLevel[e] ?? 0) < nextLevel) {
                        details.linkToLevel[e] = nextLevel;
                    }
                });
            }
        }
        if (this.debugQSC) console.log(details.linkToLevel);
        this.cacheDetails = details;

        Object.keys(details.linkToLevel).forEach(e => {
            if (details.linkToLevelPositions[details.linkToLevel[e]] === undefined) { details.linkToLevelPositions[details.linkToLevel[e]] = [] }
            details.linkToLevelPositions[details.linkToLevel[e]].push(e);
        });
        //this.cacheDetails.errorMessages = this.CalculateValidateQuestionSet();
        this.cacheInitialized = true;

        if (this.debugQSC) console.log("Computed:", this.cacheDetails);

        console.log(">>>>>>>> Calc Complete")
        if (this.canDrawTree) {
            var self = this;
            //Defermen mechanism to allow angular components to be added
            setTimeout(function () {
                self.drawTree();
            }, 500);
        }
    }
    getContainerHeight() {
        //console.log(this.initialOrder + " l2lP " + this.cacheDetails?.linkToLevelPositions)
        return "height:" + (150 * (this.cacheDetails?.linkToLevelPositions.length - 1)) + "px";
    }
    openQuestionModal(id: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            question: this.questionSet?.questions.filter(q => q.id == id)[0],
            questionSetControl: this

        }
        const dialogRef = this.dialog.open(QuestionModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    openAddQuestionModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: null,
            questionSetControl: this

        }
        const dialogRef = this.dialog.open(AddQuestionModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    addQuestionQuick(priorQuestionInternalOrder: number): Question {
        let newOrderBase = priorQuestionInternalOrder + .5;
        if (this.debugQSC) console.log("trying to add question")
        let id = this.GetNextQuestionID();
        let q = new Question(
            {
                "id": id,
                "label": "",
                "text": "",
                "level": "1",
                "internalOrder": newOrderBase,
                "sequenceNumber": "9999",
                "defaultNextQuestionID": "",
                "questionType": {
                    "isYesNoInternalOnly": true,
                    "isMultiple": false,
                    "choice": [
                        { "id": "c1", "choiceText": "Yes", "nextQuestionID": "Default", "sequenceNumber": "1", "additionalFreeTextIndicator": "NA" },
                        { "id": "c2", "choiceText": "No", "nextQuestionID": "Default", "sequenceNumber": "2", "additionalFreeTextIndicator": "NA" }
                    ]
                }
            }
        );
        this.questionSet?.questions.push(q);
        this.recalculateInternalOrder();
        console.log("QSC Order " + this.initialOrder);

        setTimeout(() => {
            var target = "question-anchor-" + this.initialOrder + "-" + q.id;
            console.log("jumpToQuestion " + target);
            var element = document.getElementById(target);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
            //Target the input element
        }, 50); // 1000 milliseconds (1 second)
        this.CalculateQuestionDetail();
        return q;
    }

    allowTreeDraw() {
        if (this.canDrawTree) return;
        //console.log("Draw Tree, can draw tree")
        this.canDrawTree = true;
        var self = this;
        //Defermen mechanism to allow angular components to be added
        setTimeout(function () {
            self.drawTree();
        }, 500);
    }

    drawTree() {
        //console.log("drawtree ", this.canDrawTree)
        if (!this.canDrawTree) {
            return;
        }
        this.questionSet?.questions.forEach(q => {
            //console.log("relocating " + q.id)
            var s = this.getPositionForQuestion(q.id);
            var t = "dc" + this.initialOrder + "-" + q.id;
            var qd: any = document.getElementById(t)
            if (qd != null) {
                qd!.style = s;
            } else {
                console.log("relocating " + q.id + " failed no object " + t);
            }
        });
        this.addLines()
        this.addDecisions()
        this.addSetConnections()
    }
    clearLines() {
        this.lines.forEach((line: any) => {
            //console.log("Removing ", line);
            document.getElementById(line)?.remove();
        })
    }
    redrawLines() {
        console.log("redrawing lines " + this.initialOrder)
        if (!this.canDrawTree) {
            return;
        }
        this.addLines()
        this.addSetConnections()
    }
    getPositionForQuestion(qid: string) {
        var top = 50;
        var left = 50;
        var level = this.cacheDetails?.linkToLevel[qid];
        //console.log("Detail", qid, level, this.cacheDetails?.linkToLevel, this.cacheDetails?.linkToLevelPositions)
        var positionInLevel = -1;//this.cacheDetails?.linkToLevelPositions.length + 1;
        try {
            positionInLevel = this.cacheDetails?.linkToLevelPositions[level].indexOf(qid.toString());
            //console.log("B", positionInLevel)
        } catch (er) {
            //if (this.debugQSC)
            console.log("Unpositioned " + qid);
            positionInLevel = -1;
            var ca = this.cacheDetails?.unlinkedQuestions;
            if (ca !== undefined) {
                level = ca.indexOf(qid.toString()) + 1;
            } else {
                level = 0;
            }
            //if (this.debugQSC)
            console.log("Unpositioned " + qid, positionInLevel, level);
            //isValid = false;
        }
        top = 150 * (level) - 100;
        left = 250 + (225 * (positionInLevel));
        var styleTag = "top:" + top + "px;left:" + left + "px;";
        //console.log("Position ", qid, positionInLevel, styleTag);
        return styleTag;
    }
    connect(s: string, d: string, option: string, reason: string): any {
        //console.log("Connect: " + s + " to " + d);

        if (option.startsWith("c")) {
            option = option.substring(1);
        }

        var newId = "line-" + s + "-to-" + d;
        var startDiv = document.getElementById("botS" + s); // bottom of source card
        var endDiv = document.getElementById("topS" + d); // top of destination card
        //console.log("line botS" + s + " to topS" + d);
        if (startDiv == undefined || endDiv == undefined) {
            //if (startDiv == undefined) { console.log("NO START FOR REQUEST " + s) }
            //if (startDiv == undefined) { console.log("NO END FOR REQUEST " + d) }
            return;
        }
        var startX =
            //startDiv.parentElement!.parentElement!.offsetLeft
            0
            + startDiv.parentElement!.offsetLeft
            + startDiv.offsetLeft + 3;
        var endX =
            //endDiv.parentElement!.parentElement!.parentElement!.offsetLeft
            0
            + endDiv.parentElement!.offsetLeft
            + endDiv.offsetLeft + 3;



        var startY =
            startDiv.parentElement!.parentElement!.parentElement!.parentElement!.parentElement!.offsetTop
            +
            startDiv.parentElement!.offsetTop
            +
            startDiv.offsetTop + 6;
        var endY =
            endDiv.parentElement!.parentElement!.parentElement!.parentElement!.parentElement!.offsetTop
            +
            endDiv.parentElement!.offsetTop
            +
            endDiv.offsetTop;
        //console.log("Ys", startY, endY)
        //debugger;

        var newlinebox = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        newlinebox.setAttribute("id", newId);
        //if (this.debugQSC) {
        //    newlinebox.style.border = "1px solid green";
        //}
        newlinebox.style.position = "absolute";
        newlinebox.style.zIndex = "5";
        newlinebox.style.top = startY.toString();
        var h = (endY - startY);
        newlinebox.style.height = h.toString();
        //console.log(s, d, startX, startY, endX, endY);
        var x1, x2, xc;
        if (startX < endX) {
            newlinebox.style.left = (startX - 50).toString();
            var w = (endX - startX + 100);
            //console.log(endX, startX, w)
            newlinebox.style.width = w.toString();
            x1 = 50; x2 = w - 50; xc = w / 2;
        } else {
            newlinebox.style.left = (endX - 50).toString();
            var w = (startX - endX + 100);
            //console.log(endX, startX, w)
            newlinebox.style.width = w.toString();
            x2 = 50; x1 = w - 50; xc = w / 2;
        }
        var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        line.setAttribute('x1', (x1 + 0).toString());
        line.setAttribute('x2', (x2 - 0).toString());
        line.setAttribute('y1', (0).toString());
        line.setAttribute('y2', (h).toString());
        line.setAttribute("stroke", "black");
        line.setAttribute("stroke-width", (startX == endX ? "4" : "2"));
        //console.log(line);
        newlinebox.appendChild(line);
        if (reason != "") {
            var rt = option.substring(0, 11).trim();

            var tl = rt.length;
            var tw = (tl * 6) + 8;
            var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            rect.setAttribute('x', (xc - (tl * 3) - 5).toString());
            rect.setAttribute('y', ((h / 2) - 12).toString());
            rect.setAttribute('width', (tw).toString());
            rect.setAttribute('height', '20');
            rect.setAttribute('fill', '#fff');
            rect.setAttribute('stroke', '#000');
            rect.setAttribute('stroke-width', '1');
            rect.setAttribute('rx', '2');

            var title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
            title.textContent = reason;
            newlinebox.appendChild(rect);
            var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
            text.setAttribute('x', (xc - (tl * 3)).toString());
            text.setAttribute('y', (h / 2).toString());
            text.setAttribute("font-size", "10");
            text.setAttribute("font-family", "courier");
            text.setAttribute("font-weight", "normal");
            text.setAttribute("fill", "black");
            text.setAttribute("stroke", "black");
            text.setAttribute("stroke-width", "1");
            text.textContent = rt;
            text.appendChild(title);
            newlinebox.appendChild(text);
        }
        document.getElementById("treeParent")!.appendChild(newlinebox);
        //console.log("DRAWING DONE")
        return newId;
    }
    addLines() {
        //console.log("Add Lines");
        this.lines.forEach((line: any) => {
            //console.log("Removing ", line);
            document.getElementById(line)?.remove();
        })
        this.lines = [];
        if (this.cacheDetails != null) {
            Object.keys(this.cacheDetails!.linkMap).forEach((cid: any) => {
                if (cid != "Start") {
                    var t1 = this.initialOrder + "Q" + cid;
                    this.cacheDetails!.linkMap[cid].forEach((nid: any) => {
                        if (nid != "End" && nid != "Final") {
                            var label = "*";
                            var reason = "*";
                            var cd = this.cacheDetails?.linkDetails.filter((f: LinkDetail) => { return f.link == cid + "-" + nid });
                            //console.log(cd);
                            if (cd != null) {
                                let ls: string[] = [];
                                let rs: string[] = [];
                                cd.forEach(cdi => { ls.push(cdi.label) })
                                cd.forEach(cdi => { rs.push(cdi.reason) })
                                label = ls.join(",");
                                reason = rs.join("\r\n");
                            }

                            var t2 = this.initialOrder + "Q" + nid;
                            var line = this.connect(t1, t2, label, reason);
                            this.lines.push(line);
                        }
                    });
                }
            })
        }
    }
    addDecisions() {
        // decision Controls
        if (this.cacheDetails == null) { return; }
        if (this.cacheDetails!.linkToLevel.length == 0) { return; }
        var offsetLeft: any = {};
        //console.log("+++++++++++++++++++ iterating decisions " + this.initialOrder);
        var treearea = document.getElementById('treearea-qs-' + this.initialOrder);
        if (treearea == null) { return; }
        var decisionnodes: any = treearea.querySelectorAll('.treenode.decision');
        if (decisionnodes == null || decisionnodes.length == 0) { return; }
        //console.log("DTN", decisionnodes);
        decisionnodes.forEach(
            (node: any) => {
                var qid = node.getAttribute('data-question-id')
                //console.log(qid);
                if (qid != null) {
                    var level = (this.cacheDetails!.linkToLevel[qid] ?? 0) + 1;
                    //console.log(qid, level, this.cacheDetails!.linkToLevel);
                    var top = ((150 * (level)) - 100).toString();
                    var basePosition = 0;
                    if (Object.keys(this.cacheDetails!.linkToLevelPositions).indexOf(level.toString()) >= 0) {
                        basePosition = this.cacheDetails!.linkToLevelPositions[level.toString()].length;
                    }
                    //console.log("Base Position ", qid, level, basePosition);
                    if (Object.keys(offsetLeft).indexOf(level.toString()) < 0) {
                        offsetLeft[level.toString()] = 0;
                    } else {
                        offsetLeft[level.toString()] = offsetLeft[level.toString()] + 1;
                    }
                    //console.log(offsetLeft);
                    var positionInLevel = basePosition + offsetLeft[level.toString()];
                    //console.log(qid + " " + level + " BsePosition:" + basePosition, offsetLeft[level.toString()], positionInLevel);
                    var left = 250 + (225 * (positionInLevel));
                    node.style.top = top + "px";
                    node.style.left = left + "px";
                    //console.log(qid, top, left)
                }
                //console.log(qid);
            }
        );
        document.querySelectorAll('.treenode.decision .topcard').forEach(
            (node: any) => {
                var start = node.getAttribute('data-question-connection')
                var dest = node.getAttribute('id').replace("topS", "");
                //console.log(start, dest);
                var text = node.getAttribute('data-decision-text')
                var label = node.getAttribute('data-decision-label')
                var line = this.connect(start, dest, text, label);
                this.lines.push(line);
            }
        );
        //debugger
    }
    addSetConnections() {
        if (this.cacheDetails == null) { return; }
        document.querySelectorAll('.bottomOfSet').forEach(
            (node: any) => {
                var nodeId = node.getAttribute('id').replace("botS", "");
                var priorSet = this.initialOrder! - 1;
                if (nodeId.startsWith(priorSet + "Q")) {
                    var sn = this.cacheDetails?.sequence[0];
                    var start = nodeId;
                    var dest = this.initialOrder + "Q" + sn;
                    var line = this.connect(start, dest, 'Next', 'Next Question Set');
                    this.lines.push(line);
                }

            }
        );
    }
    
    focus(id: any) {
        var target: any = "#qInList" + (this.initialOrder + "") + "-" + id + " input";
        var e = document.querySelector(target!);
        e.focus();
        this.selectQuestionForFocus(this.initialOrder!, id);
    }

    jumpToQuestion(id: string) {
        document.querySelector(".tree-item-focused")?.classList.remove('tree-item-focused')
        //#TODO Fix to dynamically open and display
        if (!this.showQuestions) {
            alert("Please expand " + this.getTitle() + " panel to jump to this question")
        }
        var target = "question-anchor-" + (this.initialOrder + "") + "-" + id;
        setTimeout(() => {
            var questionAnchorElement = document.getElementById(target);
            questionAnchorElement?.scrollIntoView({ behavior: "smooth" });
            questionAnchorElement?.focus();
            this.selectQuestionForFocus(this.initialOrder!, id);
            document.getElementById("dc" + this.initialOrder + "-" + id)?.classList.add('tree-item-focused')
        }, 50); // 1000 milliseconds (1 second)
        
    }
    
    selectQuestionForFocus(sOrder:number, id: string) {
        document.querySelector(".question-last-focused")?.classList.remove("question-last-focused");
        document.getElementById("qInList" + sOrder + "-" + id)?.classList.add("question-last-focused");
    }
    
}
interface QuestionDetails {
    questionCount: number;
    linkMap: any;
    reverseMap: any;
    linkToLevel: any;
    linkToLevelPositions: any;
    sequence: string[];
    linkDetails: LinkDetail[];
    questionDetails: any;
    unlinkedQuestions: string[];
    errorMessages: string[];
};
interface LinkDetail {
    link: string;
    label: string;
    reason: string;
    action: string | undefined;
}
export class QuestionSetRouting {
    qsIndex: number;
    qsGuid: string;
    title: string;
    internalOrderOffset: number;
    constructor(data:any) {
        this.qsIndex = data.qsIndex;
        this.qsGuid = data.qsGuid;
        this.title = data.title;
        this.internalOrderOffset = data.       internalOrderOffset;



    }

}