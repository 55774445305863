import { Pipe, PipeTransform } from '@angular/core';
import { AnswerChoiceCommonType } from '../../classes/questionset';

@Pipe({
    name: 'sortAnswerBySequence',
    pure: false
})
export class SortAnswerBySequencePipe implements PipeTransform {

    transform(value: AnswerChoiceCommonType[]): AnswerChoiceCommonType[] {
        return value.sort((n1, n2) => {
            return n1.sequence - n2.sequence;
        });
    }

}
