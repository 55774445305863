<h2 mat-dialog-title>Copy {{titleToCopy}}</h2>
<span class="material-icons modal-cLose" (click)="close()">cancel</span>


<mat-dialog-content>
    <form id="copyForm" [formGroup]="copyForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Name</mat-label>
            <input matInput type="text" autocomplete="off" id="newName" formControlName="newName" placeholder="Enter a name">
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Client</mat-label>
            <mat-select  formControlName="clientSelect" name="selectedClient" (selectionChange)="onSelectClient()" >
                <mat-option *ngFor="let c of clients" [value]="c.id">
                    {{c.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="close()">
        Close
    </button>
    <button type="submit" form="copyForm" [disabled]="!copyForm.valid" 
            mat-raised-button color="primary">Copy</button>
</mat-dialog-actions>