import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionSetTable } from '../../classes/questionsetdashboard';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';

@Component({
  selector: 'app-edit-questions-catalog-modal',
  templateUrl: './edit-questions-catalog-modal.component.html',
  styleUrls: ['./edit-questions-catalog-modal.component.scss']
})
export class EditQuestionsCatalogModalComponent {
    public clientId: string = "";
    questionSet: QuestionSetTable[] | undefined = [];
    constructor(
        public questionsetDashboardService: QuestionSetDashboardService,
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<EditQuestionsCatalogModalComponent>
    ) {
        this.clientId = data.clientId;
        this.populateQuestionSetList();
    }
    private populateQuestionSetList() {
        this.questionsetDashboardService.getAllQuestionSetDashboardFromService(this.clientId!).subscribe(resp => {
            if (resp.body) {
                this.questionSet = [];

                var respMapped = this.questionsetDashboardService.mapQuestionSetDashboardResponse(resp);
                respMapped = respMapped.filter((f: QuestionSetTable) => { return f.questionSetStatus == 3; });
                respMapped = respMapped.sort((a, b) => (
                    (a.questionSetName! == b.questionSetName!) ?
                        (a.majorVersion! > b.majorVersion! ? -1 : 1)
                        : (a.questionSetName! > b.questionSetName! ? 1 : -1)
                ));
                respMapped.forEach((fe) => {
                    if (this.questionSet!.filter(f => f.questionSetHeaderId == fe.questionSetHeaderId).length == 0) {
                        this.questionSet!.push(fe);
                    }
                });
            }
        });
    }
    close() {
        this.dialogRef.close([]);
    }
    add() {
        let catalogList:number[] = [];
        document.querySelectorAll('input[name=catalogSelectionItem]:checked').forEach((t:any) => { catalogList.push(t.value) });
        console.log("catalogList", catalogList);
        this.dialogRef.close(catalogList);
    }

}
