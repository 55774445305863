import { Component, Input } from '@angular/core';
import { QuestionLanguage } from '../../classes/questionset';

@Component({
  selector: 'app-edit-question-decision-list',
  templateUrl: './edit-question-decision-list.component.html',
  styleUrls: ['./edit-question-decision-list.component.scss']
})
export class EditQuestionDecisionListComponent {
    @Input() questionSetControl!: any;
    constructor() {
    }
    
    
}
