import { Component, Input, Inject } from '@angular/core';
import { ActionResult } from '../../../classes/questionset';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-actionresult-modal',
    templateUrl: './actionresult-modal.component.html',
    styleUrls: ['./actionresult-modal.component.scss']
})
export class ActionresultModalComponent {
    @Input() actionResult?: ActionResult;
    @Input() p!: any;

    actionResultForm = this.fb.group({
        actionName: ['', Validators.required],
        actionType: ['', Validators.required],
        routing: [''],
        comments: [''],
        actionTextPharmacy: [''],
        actionTextProvider: [''],
        actionTextPatient: ['']
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<ActionresultModalComponent>,
        private fb: FormBuilder
    ) {
        if (data !== undefined) {
            this.actionResult = data.actionResult;
            this.p = data.p;
        }
    }

    ngOnInit(): void {
        if (this.actionResult !== undefined && this.actionResult !== null) {
            this.actionResultForm.patchValue({
                actionName : this.actionResult.actionName ?? "",
                actionType : this.actionResult.actionType ?? "",
                routing : this.actionResult.routing ?? "",
                comments : this.actionResult.comments ?? "",
                actionTextPharmacy : this.actionResult.actionTextPharmacy ?? "",
                actionTextProvider : this.actionResult.actionTextProvider ?? "",
                actionTextPatient : this.actionResult.actionTextPatient ?? ""
            });
        }
    }
    save() {
        if (this.actionResult !== undefined && this.actionResult !== null) {
            this.actionResult.actionName    = this.actionResultForm.value.actionName ?? "";
            this.actionResult.actionType = this.actionResultForm.value.actionType ?? "";
            this.actionResult.routing = this.actionResultForm.value.routing ?? "";
            this.actionResult.comments = this.actionResultForm.value.comments ?? "";
            this.actionResult.actionTextPharmacy = this.actionResultForm.value.actionTextPharmacy ?? "";
            this.actionResult.actionTextProvider = this.actionResultForm.value.actionTextProvider ?? "";
            this.actionResult.actionTextPatient = this.actionResultForm.value.actionTextPatient ?? "";
            this.dialogRef.close("1");
        } else {
            //add new action result
            var ar = new ActionResult(this.actionResultForm.value);
            this.p.AddActionResult(ar);
            this.dialogRef.close("1");
        }
    }
    close() {
        this.dialogRef.close("0");
    }
}
