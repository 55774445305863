<div class="row">
    <main class="form-signin" *ngIf="!isAuthenticated;">
        <form (ngSubmit)="login()">
            <h1 class="h3 mb-3 fw-normal">Please sign in</h1>
            <button mat-flat-button color="accent" type="submit">
                Sign In
            </button>
        </form>
    </main>
    <main class="form-signin" *ngIf="isAuthenticated;">
        <form>
            <h1 class="h3 mb-3 fw-normal">Welcome {{this.user!.userName}}</h1>
            <!--<button mat-flat-button color="accent" type="button">
                Sign Out
            </button>-->
        </form>
        
    </main>

</div>
