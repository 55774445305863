import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from '../classes/client';
import { Justification } from '../classes/justification';
import { User } from '../classes/user';
import { AuthService } from '../shared/auth/auth.service';
import { JustificationAddModalComponent } from './justification-add-modal/justification-add-modal.component';
import { JustificationDeleteModalComponent } from './justification-delete-modal/justification-delete-modal.component';
import { JustificationsService } from './justifications.service';

@Component({
  selector: 'justifications-dashboard',
  templateUrl: './justifications-dashboard.component.html',
  styleUrls: ['./justifications-dashboard.component.scss']
})
export class JustificationsDashboardComponent {
    title = 'Justification Catalog';

    displayedColumns: string[] = ['desc', 'decision', 'client', 'lob', 'action'];
    dataSource: MatTableDataSource<Justification> = new MatTableDataSource<Justification>([]);

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort)
    sort!: MatSort;

    user!: User;
    justifications: Justification[] | undefined = [];
    selectedClient!: Client;
    clientsList: Client[] = [];
    searchInput: string = '';

    constructor(
        private authService: AuthService,
        private justificationService: JustificationsService,
        private dialog: MatDialog,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.populateClients();
        this.getJustifications();
    }

    onSelectClient() {
        //set the users selected client
        this.authService.setClient(this.selectedClient.clientID!);
        this.getJustifications();
    }

    populateClients() {
        this.clientsList = this.user.clients!;
    }

    onActionButtonClick(event: MouseEvent): void {
        event.stopPropagation();
    }

    openCreateJustificationModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient,
            p: this
        }
        const dialogRef = this.dialog.open(JustificationAddModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                this.getJustifications();
            }
        );
    }

    openAddJustificationViewModeModal(justificationTemplateID: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient,
            justificationTemplateID: justificationTemplateID, // Pass the template ID to the modal
            p: this
        };
        const dialogRef = this.dialog.open(JustificationAddModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                this.getJustifications();
            }
        );
    }

    openDeleteJustificationModal(justificationTemplateID: number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient,
            justificationTemplateID: justificationTemplateID
        }
        const dialogRef = this.dialog.open(JustificationDeleteModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                this.getJustifications();
            }
        );
    }

    getJustifications() {

        this.justificationService.getAllJustificationFromService(this.selectedClient.clientID!).subscribe(resp => {
            if (resp.body) {
                this.justifications = this.justificationService.mapJustificationsFromApiResponse(resp);
                //console.log(this.decisionTree);
                //console.log(this.decisionTree);
                if (this.justifications.length >= 0) {
                    this.dataSource = new MatTableDataSource(this.justifications);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    console.log("Data Source", this.dataSource)
                    console.log(this.justifications);
                    //this.populateCase();
                    //this.applyFilter(null);

                }
            }
        });
    }

    applyFilter(event: MatCheckboxChange | Event) {
        const filterValue = this.searchInput.toLowerCase();

        this.dataSource.filterPredicate = (data: Justification, filter: string): boolean => {

            // Filter by search input
            const includesSearch = filter === '' || (data.JustificationDescription?.toLowerCase().includes(filter) ?? false);


            return includesSearch;
        };

        this.dataSource.filter = filterValue.trim();

        // Manually trigger the filter update
        this.dataSource._filterData = (data: Justification[]) => {
            this.dataSource.filteredData = data.filter(obj => this.dataSource.filterPredicate(obj, this.dataSource.filter));
            return this.dataSource.filteredData;
        };
        this.dataSource._updateChangeSubscription();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

}
