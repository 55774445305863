import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Client } from '../../classes/client';
import { DecisionTreeSettings } from '../../classes/decisiontreesettings';
import { User } from '../../classes/user';
import { DrugConfigurationComponent } from '../../shared/drug-configuration/drug-configuration.component';
import { RxdmConfigurationComponent } from '../../shared/rxdm-configuration/rxdm-configuration.component';
import { AuthService } from '../../shared/auth/auth.service';
import { DecisionTreeService } from '../decision-tree.service';
import { DecisionTreeNameValidator } from '../../shared/validators/decision-tree-name-validator';

@Component({
  selector: 'app-edit-question-settings-modal',
  templateUrl: './edit-question-settings-modal.component.html',
  styleUrls: ['./edit-question-settings-modal.component.scss']
})
export class EditQuestionSettingsModalComponent implements OnInit {
    p: any;
    treeId!: number;
    user!: User;

    @ViewChild('drugConfiguration', { static: false })
    drugConfiguration!: DrugConfigurationComponent;
    @ViewChild('rdmConfiguration', { static: false })
    rdmConfiguration!: RxdmConfigurationComponent;

    @ViewChild('stepper')
    stepper!: MatStepper;

    tree!: DecisionTreeSettings;
    settingsForm: FormGroup & { [key: string]: FormControl };

    activeStepIndex: number = 0;
    requestedStepIndex: number = 0;
    currentDate = new Date();
    nextDay = new Date(this.currentDate).setDate(this.currentDate.getDate() + 1);
    futureDate = new Date(this.currentDate).setFullYear(this.currentDate.getFullYear() + 100);
    clients!: Client[];
    clientChanged = false;
    showClientConfig = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<EditQuestionSettingsModalComponent>,
        private authService: AuthService,
        private decisionTreeService: DecisionTreeService,
        private dtValidator: DecisionTreeNameValidator,
        private fb: FormBuilder,) {
        this.user = this.authService.getUserObject();
        this.tree = new DecisionTreeSettings;

        this.settingsForm = this.fb.group({
            name: ['', Validators.required],
            desc: [''],
            start: [new Date()],
            end: [new Date()],
            client: ['', Validators.required]
        }) as unknown as FormGroup & { [key: string]: FormControl };

        this.clients = this.user.clients!;
        this.settingsForm.controls['start'].patchValue(undefined);
        this.settingsForm.controls['end'].patchValue(undefined);
        if (data !== undefined) {
            this.treeId = data.treeId;
            this.getTreeSettings();
        }
    }
    ngOnInit(): void {
        
    }

    getErrorMessage() {
        if (this.settingsForm.controls['name'].hasError('required')) {
            return 'You must enter a value';
        }
        if (this.settingsForm.controls['name'].hasError('decisionTreeNameExists')) {
            return 'A decision tree already exist with that name, please enter a new one';
        }
        if (this.settingsForm.controls['start'].hasError('required')) {
            return 'You must enter a value';
        }
        if (this.settingsForm.controls['end'].hasError('required')) {
            return 'You must enter a value';
        }
        if (this.settingsForm.controls['client'].hasError('required')) {
            return 'You must enter a value';
        }

        return '';
    }

    getTreeSettings() {
        this.decisionTreeService.getDecisionTreeSettingsByID(this.treeId!).subscribe(resp => {
            if (resp.body) {
                this.tree = this.decisionTreeService.mapDecisionTreeSettingsFromDecisionTree(resp);
                console.log(this.tree);
                if (this.tree) {
                    this.populateForm();
                }
            }
        });
    }

    populateForm() {
        this.settingsForm.controls['name'].patchValue(this.tree.decisionTree.decisionTreeName);
        this.settingsForm.controls['desc'].patchValue(this.tree.decisionTree.decisionTreeDescription);

        //Check the dates
        const startDate = this.tree.decisionTree.startDate;
        if (startDate!.toISOString() === "0001-01-01T04:32:36.000Z") {
            // It is a default date
            this.tree.decisionTree.startDate = undefined;
        }
        const endDate = this.tree.decisionTree.endDate;

        if (endDate!.toISOString() === "0001-01-01T04:32:36.000Z") {
            // It is a default date
            this.tree.decisionTree.endDate = undefined;
        }
        this.settingsForm.controls['start'].patchValue(this.tree.decisionTree.startDate);
        this.settingsForm.controls['end'].patchValue(this.tree.decisionTree.endDate);
        this.settingsForm.controls['client'].patchValue(this.tree.client.clientID!);

        this.settingsForm.get('name')?.setAsyncValidators([this.dtValidator.validateDocumentNameExists(this.tree.client.clientID!, this.tree.decisionTree.decisionTreeName)]);
        if (this.tree.hierarchy.length > 0) {
            this.showClientConfig = true;
        }
    }

    stepSelectionChanged(event: any) {
        this.activeStepIndex = event.selectedIndex;
    }

    checkStep1Validation() {
        if (this.settingsForm.controls['name'].valid && this.settingsForm.controls['start'].valid && this.settingsForm.controls['end'].valid) {
            return true;
        } else {
            return false;
        }
    }

    checkStep2Validation() {
        if (this.tree.hierarchy.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    checkStep3Validation() {
        var validCase = true;
        var hasDetermination = false;

        if (this.tree.hierarchy.length > 0) {
            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                if (this.tree.hierarchy[i].cases.length === 0 && !this.tree.hierarchy[i].noCase) {
                    validCase = false;
                    break;
                }

                if (this.tree.hierarchy[i].cases.length > 0 || this.tree.hierarchy[i].noCase) {
                    validCase = true;
                }

                if (this.tree.hierarchy[i].determination.length > 0) {
                    hasDetermination = true;
                }
            }
        }

        return validCase && hasDetermination;
    }

    checkStep4Validation() {
        var validDrugSelection = false;
        if (this.tree.noDrug) {
            if (this.tree.hierarchy.length > 0) {
                for (let i = 0; i < this.tree.hierarchy.length; i++) {
                    if (this.tree.hierarchy[i].drugs.length == 0) {
                        validDrugSelection = true;
                    }
                }
            }
        } else {
            if (this.tree.hierarchy.length > 0) {
                for (let i = 0; i < this.tree.hierarchy.length; i++) {
                    if (this.tree.hierarchy[i].drugs.length > 0) {
                        validDrugSelection = true;
                    } else {
                        if (this.tree.hierarchy[i].noDrug) {
                            validDrugSelection = true;
                        }
                    }
                }
            }
        }
        return validDrugSelection;
    }

    validateTree() {
        if (this.checkStep1Validation() && this.checkStep2Validation() && this.checkStep3Validation() && this.checkStep4Validation()) {
            return true;
        } else {
            return false;
        }
    }

    setActiveStep(index: number) {
        this.activeStepIndex = index;
    }

    nextStep() {
        this.requestedStepIndex = this.activeStepIndex + 1;
        if (this.stepper.selectedIndex < this.stepper.steps.length - 1) {
            this.stepper.next();
        }
    }

    previousStep() {
        if (this.stepper.selectedIndex > 0) {
            this.stepper.previous();
        }
    }

    populateTreeValues() {
        this.tree.decisionTree.decisionTreeName = this.settingsForm.controls['name'].value!;
        this.tree.decisionTree.decisionTreeDescription = this.settingsForm.controls['desc'].value!;
        this.tree.decisionTree.startDate = this.settingsForm.controls['start'].value!;
        this.tree.decisionTree.startDate = this.settingsForm.controls['end'].value!;
        
    }

    save() {
        //Populate the tree object
        this.populateTreeValues();
        console.log(this.tree);
        this.decisionTreeService.updateGuidedDecisionTree(this.tree!).subscribe(
            (data) => {
                if (data.status == 200) {
                    //successfully made call
                    if (data.body.result != -1) {
                        //successfully saved
                        this.dialogRef.close("200");
                    }
                    //error
                }
                else {
                    //error
                    this.dialogRef.close("1");
                }
            },  //changed
            (err) => {
                console.log(err)
            },
            () => console.log("Done")
        );
    }

    close() {
        if (this.clientChanged) {
            this.dialogRef.close("1");
        } else {
            this.dialogRef.close("0");
        }
    }

    refreshChildComponents() {
        if (this.tree.hierarchy.length > 0) {
            this.showClientConfig = true;
            if (this.rdmConfiguration && this.drugConfiguration) {
                this.rdmConfiguration.refreshDatasource();
                this.drugConfiguration.refreshDatasource();
            }
        } else {
            if (this.rdmConfiguration && this.drugConfiguration) {
                this.rdmConfiguration.refreshDatasource();
                this.drugConfiguration.refreshDatasource();
            }
            this.showClientConfig = false;
        }
        
    }
}
