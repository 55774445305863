<div *ngIf="question.languageGuid!= null && question.languageGuid.length > 0">
    <div *ngFor="let lang of supportedLanguages" class="row">
        <!--<div class=" col-md-12"> >> {{question.languageGuid}} << </div>-->
        <div class="col-md-1" style="text-align:right">
            {{lang.description}}
        </div>
        <div class="col-md-19">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{question.internalOrder}}) {{question.text}}</mat-label>
                <input matInput type="text" id="lang-qt-{{question.id}}-{{lang.code}}"
                       data-recipient="" [attr.data-code]="lang.code"
                       [attr.data-guid]="question.languageGuid"
                       class="full-width"
                       [value]="GetCurrentValueForTextField(lang.code,'',question.languageGuid)" />
            </mat-form-field>
            <div *ngFor="let a of question.action" class="row">
                <!--<div class="col-md-12"> >> {{a.actionTextGuid}} << </div>-->
                <div *ngIf="a.actionTextGuid != null && a.actionTextGuid.length > 0" class="row">
                    <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-2">Patient Text</div>
                    <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-18">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{a.actionTextPatient}}</mat-label>
                            <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="patient" placeholder="{{a.actionTextPatient}}"
                                   [value]="GetCurrentValueForTextField(lang.code,'patient',a.actionTextGuid)" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-2">Pharmacy Text</div>
                    <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-18">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{a.actionTextPharmacy}}</mat-label>
                            <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="pharmacy" placeholder="{{a.actionTextPharmacy}}"
                                   [value]="GetCurrentValueForTextField(lang.code,'pharmacy',a.actionTextGuid)" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-2">Provider Text</div>
                    <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-18">
                        <mat-form-field class="full-width" appearance="outline">
                            <mat-label>{{a.actionTextProvider}}</mat-label>
                            <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="provider" placeholder="{{a.actionTextProvider}}"
                                   [value]="GetCurrentValueForTextField(lang.code,'provider',a.actionTextGuid)" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div *ngIf="GetQuestionType() == 'Select'" class="row">
                <div *ngFor="let c of GetSelectType().choice" class="row">
                    <div *ngIf="c.choiceTextGuid != null && c.choiceTextGuid.length > 0" class="row">
                        <div class="col-md-1" style="text-align:right">
                        </div>
                        <div class="col-md-19">
                            <!--<div class="col-md-12"> >> {{c.choiceTextGuid}} << </div>-->
                            <mat-form-field class="full-width" appearance="outline">
                                <mat-label>{{c.sequence}}) {{c.choiceText}}</mat-label>
                                <input matInput id="lang-ct-{{question.id}}-{{c.id}}-{{lang.code}}" data-recipient="" [attr.data-code]="lang.code" [attr.data-guid]="c.choiceTextGuid" type="text" class="full-width" placeholder="{{c.choiceText}}"
                                       [value]="GetCurrentValueForTextField(lang.code,'',c.choiceTextGuid)" />
                            </mat-form-field>
                            <div *ngFor="let a of c.action" class="row">
                                <!--<div class="col-md-12"> >> {{a.actionTextGuid}} << </div>-->
                                <div *ngIf="a.actionTextGuid != null && a.actionTextGuid.length > 0" class="row">
                                    <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-2">Patient Text</div>
                                    <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-18">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>{{a.actionTextPatient}}</mat-label>
                                            <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="patient" placeholder="{{a.actionTextPatient}}"
                                                   [value]="GetCurrentValueForTextField(lang.code,'patient',a.actionTextGuid)" />
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-2">Pharmacy Text</div>
                                    <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-18">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>{{a.actionTextPharmacy}}</mat-label>
                                            <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="pharmacy" placeholder="{{a.actionTextPharmacy}}"
                                                   [value]="GetCurrentValueForTextField(lang.code,'pharmacy',a.actionTextGuid)" />
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-2">Provider Text</div>
                                    <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-18">
                                        <mat-form-field class="full-width" appearance="outline">
                                            <mat-label>{{a.actionTextProvider}}</mat-label>
                                            <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="provider" placeholder="{{a.actionTextProvider}}"
                                                   [value]="GetCurrentValueForTextField(lang.code,'provider',a.actionTextGuid)" />
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let nqc of GetSelectType().nextQuestionCondition" class="row">

                                <!--<h1>action {{nqc.comparison.action.length}}</h1>-->
                                <div *ngFor="let a of nqc.comparison.action" class="row">
                                    <!--<div class="col-md-12"> >> {{a.actionTextGuid}} << </div>-->
                                    <div *ngIf="a.actionTextGuid != null && a.actionTextGuid.length > 0" class="row">
                                        <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-2">Patient Text</div>
                                        <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-18">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{a.actionTextPatient}}</mat-label>
                                                <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="patient" placeholder="{{a.actionTextPatient}}"
                                                       [value]="GetCurrentValueForTextField(lang.code,'patient',a.actionTextGuid)" />
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-2">Pharmacy Text</div>
                                        <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-18">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{a.actionTextPharmacy}}</mat-label>
                                                <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="pharmacy" placeholder="{{a.actionTextPharmacy}}"
                                                       [value]="GetCurrentValueForTextField(lang.code,'pharmacy',a.actionTextGuid)" />
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-2">Provider Text</div>
                                        <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-18">
                                            <mat-form-field class="full-width" appearance="outline">
                                                <mat-label>{{a.actionTextProvider}}</mat-label>
                                                <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="provider" placeholder="{{a.actionTextProvider}}"
                                                       [value]="GetCurrentValueForTextField(lang.code,'provider',a.actionTextGuid)" />
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="GetQuestionType() == 'Date'" class="row">

                <!--<h1>DATE {{GetDateType().nextQuestionCondition.length}}</h1>-->
                <div *ngFor="let nqc of GetDateType().nextQuestionCondition" class="row">
                    <div *ngFor="let a of nqc.comparison.action">
                        <!--<div class="col-md-12"> >> {{a.actionTextGuid}} << </div>-->
                        <div *ngIf="a.actionTextGuid != null && a.actionTextGuid.length > 0" class="row">
                            <div class=" col-md-12">
                                {{GetComparisonToString(nqc.comparison)}}
                            </div>
                            <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-2">Patient Text</div>
                            <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-18">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{a.actionTextPatient}}</mat-label>
                                    <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="patient" placeholder="{{a.actionTextPatient}}"
                                           [value]="GetCurrentValueForTextField(lang.code,'patient',a.actionTextGuid)" />
                                </mat-form-field>
                            </div>
                            <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-2">Pharmacy Text</div>
                            <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-18">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{a.actionTextPharmacy}}</mat-label>
                                    <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="pharmacy" placeholder="{{a.actionTextPharmacy}}"
                                           [value]="GetCurrentValueForTextField(lang.code,'pharmacy',a.actionTextGuid)" />
                                </mat-form-field>
                            </div>
                            <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-2">Provider Text</div>
                            <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-18">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{a.actionTextProvider}}</mat-label>
                                    <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="provider" placeholder="{{a.actionTextProvider}}"
                                           [value]="GetCurrentValueForTextField(lang.code,'provider',a.actionTextGuid)" />
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="GetQuestionType() == 'Numeric'" class="row">
                <!--<h1>NUMBER {{GetNumericType().nextQuestionCondition.length}}</h1>-->
                <div *ngFor="let nqc of GetNumericType().nextQuestionCondition" class="row">
                    <div *ngFor="let a of nqc.comparison.action" class="row">
                        <!--<div class="col-md-12"> >> {{a.actionTextGuid}} << </div>-->
                        <div *ngIf="a.actionTextGuid != null && a.actionTextGuid.length > 0" class="row">
                            <div class=" col-md-12">
                                {{GetComparisonToString(nqc.comparison)}}
                            </div>
                            <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-2">Patient Text</div>
                            <div *ngIf="(a.actionTextPatient ?? '').length > 0" class="col-md-18">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{a.actionTextPatient}}</mat-label>
                                    <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="patient" placeholder="{{a.actionTextPatient}}"
                                           [value]="GetCurrentValueForTextField(lang.code,'patient',a.actionTextGuid)" />
                                </mat-form-field>
                            </div>
                            <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-2">Pharmacy Text</div>
                            <div *ngIf="(a.actionTextPharmacy ?? '').length > 0" class="col-md-18">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{a.actionTextPharmacy}}</mat-label>
                                    <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="pharmacy" placeholder="{{a.actionTextPharmacy}}"
                                           [value]="GetCurrentValueForTextField(lang.code,'pharmacy',a.actionTextGuid)" />
                                </mat-form-field>
                            </div>
                            <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-2">Provider Text</div>
                            <div *ngIf="(a.actionTextProvider ?? '').length > 0" class="col-md-18">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>{{a.actionTextProvider}}</mat-label>
                                    <input matInput type="text" class="full-width" [attr.data-code]="lang.code" [attr.data-guid]="a.actionTextGuid" data-recipient="provider" placeholder="{{a.actionTextProvider}}"
                                           [value]="GetCurrentValueForTextField(lang.code,'provider',a.actionTextGuid)" />
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
