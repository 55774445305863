import { Component } from '@angular/core';

@Component({
  selector: 'app-question-set-details',
  templateUrl: './question-set-details.component.html',
  styleUrls: ['./question-set-details.component.scss']
})
export class QuestionSetDetailsComponent {

}
