import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { DecisionTreeService } from '../../decision-tree/decision-tree.service';

@Injectable({ providedIn: 'root' })
export class DecisionTreeNameValidator {
    constructor(private decisionTreeService: DecisionTreeService) { }

    validateDocumentNameExists(clientID: string | undefined, initialName: string = ''): AsyncValidatorFn {
        return (control: AbstractControl): Promise<ValidationErrors | null> => {
            const name = control.value;
            // Check if clientID is undefined
            if (clientID === undefined) {
                return Promise.resolve(null);
            }

            // Check if the entered name is the same as the initial name
            if (name === initialName) {
                return Promise.resolve(null); // Name matches the initial name, so it's valid.
            }

            return this.decisionTreeService.getAllDecisionTreeFromService(clientID).toPromise()
                .then(resp => {
                    if (resp.body) {
                        var trees = this.decisionTreeService.mapDecisionTreeResponseVersionMerge(resp);
                        const nameExists = trees.some(t => t.decisionTreeName === name);

                        if (trees.length >= 0 && nameExists) {
                            return { decisionTreeNameExists: true };
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                })
                .catch(() => null); // Handle errors if needed
        };
    }
}