import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { User } from '../classes/user';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
    @Input() user!: User;

    navLinks: any[];
    breadcrumbMapping: { [key: string]: string } = {
        '/decisiontree/editquestions': 'Decision Tree Details',
        '/questionset/edit': 'Question Catalog Edit',
        '/rxdm': 'RXDM'
    };

    activeLinkIndex = -1;
    currentRoute: string = "";
    activeLink: string = "";
    title: string = "Decision Tree";

    constructor(private router: Router, private location: Location) {
        this.navLinks = [
            {
                label: 'List View',
                link: '/decisiontree',
            },
            {
                label: 'Client Configuration',
                link: '/hierarchy',
            },
            //{
            //    label: 'Configuration Validation',
            //    link: '/hierarchy/test',
            //},
            {
                label: 'Question Catalog',
                link: '/questionset',
            },
            {
                label: 'Justification Catalog',
                link: '/justification',
            },
        ];
        
    }
    ngOnInit(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.urlAfterRedirects;
                this.activeLinkIndex = this.navLinks.findIndex((tab) =>
                    this.isRouteMatch(tab.link, this.currentRoute)
                );
                if (this.activeLinkIndex !== -1) {
                    this.activeLink = this.navLinks[this.activeLinkIndex].link;
                }
            }
        });
    }

    isRouteMatch(link: string, currentRoute: string): boolean {
        // Normalize and clean both routes for comparison
        const normalizedCurrentRoute = this.normalizeRoute(currentRoute);
        const normalizedLink = this.normalizeRoute(link);
        return normalizedCurrentRoute === normalizedLink;
    }

    normalizeRoute(route: string): string {
        return route.split('?')[0].replace(/\/+$/, '');
    }

    shouldDisplayLayout(): boolean {
        // Get the current route
        const currentRoute = this.router.url;

        // Check if the current route exists in navLinks
        return this.navLinks.some(link => this.isRouteMatch(link.link, currentRoute));
    }

    getCurrentRouteTitle(): string {
        let modifiedCurrentRoute = this.normalizeRoute(this.currentRoute);
        if (this.breadcrumbMapping.hasOwnProperty(modifiedCurrentRoute)) {
            return this.breadcrumbMapping[modifiedCurrentRoute];
        }

        const currentRouteObject = this.navLinks.find((tab) =>
            this.isRouteMatch(tab.link, modifiedCurrentRoute)
        );

        return currentRouteObject ? currentRouteObject.label : 'Unknown';
    }

    goBack(): void {
        // Get the current URL
        const currentUrl = this.router.url;

        // Check if it's a sub-page URL
        if (currentUrl.includes('/decisiontree/editquestions')) {
            // Navigate to the main page URL
            this.router.navigateByUrl('/decisiontree');
        }
        if (currentUrl.includes('/questionset/edit')) {
            this.router.navigateByUrl('/questionset');
        }
        if (currentUrl.includes('/hierarchy/test')) {
            this.router.navigateByUrl('/hierarchy');
        }
    }
}
