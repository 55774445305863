<div *ngIf="questionSetControl.GetQuestionSet() !== undefined">
    <div [hidden]="!questionSetControl.showQuestions">
        <div class="inactiveForCatalog">
            <div style="width:100%; text-align:center;" *ngIf="(questionSetControl.GetQuestions().length == 0)">
                <button mat-mini-fab color="accent" (click)="addQuestion(0)">
                    <span class="material-icons">add</span>
                </button>
            </div>

            <div *ngFor="let q of questionSetControl.GetQuestions() | sortByInternalOrder">
                <div class="question" tabindex="0">
                    <app-edit-question-list-item [question]="q" [p]="this"
                                                 [questionSetControl]="questionSetControl">
                    </app-edit-question-list-item>
                </div>
                <div style="width:100%; text-align:center;">
                    <button mat-mini-fab color="accent" 
                            (click)="addQuestion(q.internalOrder)"
                            style="pointer-events:auto; opacity:unset">
                        <span class="material-icons">add</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>