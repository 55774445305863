export class SolrDrug {
    selected: boolean = false;
    id: number | undefined;
    ddid: number | undefined;
    desc: string | undefined;
    gpi: string | undefined;
    ndc: string | undefined;
    genericCode: string | undefined;
    type: DrugType | undefined;
    selectedGpi: string = 'GPI-14';
}

export enum DrugType {
    NDC,
    GPI
}