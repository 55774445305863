<section>
    <h2>{{title}}</h2>
    
</section>

<mat-divider></mat-divider>

<section *ngIf="decisionTreeHeader">
    <mat-card class="dt-edit-card">
        <mat-card-header>
            <div style="position:fixed;top:50;right:0;text-align:right;width:100%;">
                <button class="column-1" mat-flat-button color="primary" type="submit" form="editForm" [disabled]="!editForm.valid">Save</button>
                <button class="column-1" mat-flat-button color="accent" (click)="openActualinteractiveQuestionAnswerModal()">Test</button>
                <!--<button class="column-1" mat-flat-button color="accent" (click)="approveTree()" [disabled]="!(decisionTree?.decisionTreeStatus! == 1 || decisionTree?.decisionTreeStatus! == 2)">Approve</button>-->
                <button class="column-1" mat-flat-button color="primary" (click)="openEdit()">Review new interface</button>
            </div>
            <div class="card-header-section">
                <mat-card-title class="card-header-title">{{decisionTree!.decisionTreeName}} - {{decisionTree!.majorVersion}}.{{decisionTree!.minorVersion}}</mat-card-title>
                <mat-card-subtitle>{{decisionTree!.decisionTreeDescription}}</mat-card-subtitle>
            </div>
        </mat-card-header>
        <mat-card-content>
            <p>{{decisionTree!.comments}}</p>
            <div *ngIf="hierarchyTies.length >= 0">
                <section class="effectiveDate" [formGroup]="effectiveDate">
                    <p class="column-4"><mat-checkbox formControlName="past" (change)="onEffectiveDateCheckboxChange($event)">Past Effective</mat-checkbox></p>
                    <p class="column-4"><mat-checkbox formControlName="current" (change)="onEffectiveDateCheckboxChange($event)">Currently Effective</mat-checkbox></p>
                    <p class="column-4"><mat-checkbox formControlName="future" (change)="onEffectiveDateCheckboxChange($event)">Future Effective</mat-checkbox></p>
                </section>
                <section>
                    <div class="">
                        <table mat-table [dataSource]="dataSourceHT" matSort>

                            <!-- client Column -->
                            <ng-container matColumnDef="client">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Client</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.clientName}} </td>
                            </ng-container>

                            <!--qualifier Column-->
                            <ng-container matColumnDef="qualifier">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Qualifier</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.drugIDQualifier}} </td>
                            </ng-container>

                            <!--drug id Column-->
                            <ng-container matColumnDef="drugid">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Drug ID</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.drugID}} </td>
                            </ng-container>

                            <!--case Column-->
                            <ng-container matColumnDef="case">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Case</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.caseType}} </td>
                            </ng-container>

                            <!--subtype Column-->
                            <ng-container matColumnDef="subtype">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>SubType</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.caseSubType}} </td>
                            </ng-container>

                            <!--tier Column-->
                            <ng-container matColumnDef="tier">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Tier</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.tier}} </td>
                            </ng-container>

                            <!--start Column-->
                            <ng-container matColumnDef="start">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>Start</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.startDate  | date: 'MM/dd/YYYY'}} </td>
                            </ng-container>

                            <!--end Column-->
                            <ng-container matColumnDef="end">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> <h4>End</h4> </th>
                                <td mat-cell *matCellDef="let row"> {{row.endDate  | date: 'MM/dd/YYYY'}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsHT"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsHT;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <!--<td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of ties"></mat-paginator>
                    </div>
                </section>
                <br />
            </div>
            <mat-divider></mat-divider>
            <form id="editForm" class="form-container" [formGroup]="editForm" (ngSubmit)="save()">
                <section>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Leave a comment</mat-label>
                        <textarea matInput placeholder="Any comments?" formControlName="comments"></textarea>
                    </mat-form-field>
                </section>
                <section>
                    <mat-form-field appearance="outline">
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input matStartDate placeholder="Start date" formControlName="start">
                            <input matEndDate placeholder="End date" formControlName="end">
                        </mat-date-range-input>
                        <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </section>
                <br />
                <div *ngIf="questionset.length < 1">
                    <p>No current question sets, please add some.</p>
                </div>

                <div formArrayName="questions">
                    <div *ngFor="let l of questions.controls; let i = index">
                        <div class="column-10">
                            <mat-form-field [formGroup]="toGroup(l)" class="full-width" appearance="outline">
                                <mat-label>Question Set {{i + 1}}:</mat-label>
                                <mat-select id="questionSetSelect" formControlName="questionSetID">
                                    <mat-option *ngFor="let qs of availableQuestions; index as i; first as isFirst" [value]="qs.questionSetId">
                                        {{qs.questionSetName}} {{qs.majorVersion}}.{{qs.minorVersion}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <button class="column-2" mat-icon-button (click)="deleteQuestionSet(i)">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" class="column-4" (click)="addQuestionSet()">
                Add A Question Set
            </button>
        </mat-card-actions>
    </mat-card>
</section>

<div id="interactiveQuestionAnswerModal">
    <div id="modalOperationControls">
        <button mat-icon color="accent" class="btn btn-sm btn-outline-primary" onclick='hideReviewQuestionArea()'>
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
    <div id="interactiveQuestionAnswerSection"></div>
    <button  class="reset-button" onclick='resetReviewQuestionArea()'>
        Reset
    </button>
    <button  class="close-button" onclick='hideReviewQuestionArea()'>
        Close
    </button>
    <!--<button onclick='alert(JSON.stringify(getDecisionTreeAnswers())); return false;'>Test Save</button>-->
</div>