import { Component, Input } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { QuestionSetControl } from '../question-set.control';
import { ActionResult, NextQuestionCondition, Question, RangeComparison, SingleComparison } from '../../classes/questionset';
import { timeout } from 'rxjs';

@Component({
    selector: 'app-inline-question-test',
    templateUrl: './inline-question-test.component.html',
    styleUrls: ['./inline-question-test.component.scss']
})
export class InlineQuestionTestComponent {
    @Input() p!: any;
    public decision: ActionResult | undefined;
    public showAll: boolean = true;
    public showSkipped: boolean = false;
    public selectedApp: number = 99;
    public activeQuestions: string[] = [];
    public inPathQuestions: string[] = [];
    public currentComputedIndex = 0;
    constructor() {

    }
    ngOnInit() {
        const self = this;
        //set initial app id
        var sortedQSC = this.p.questionSetControls.sort((a: QuestionSetControl, b: QuestionSetControl) => { return a.initialOrder! < b.initialOrder!; });
        sortedQSC.forEach((fe: QuestionSetControl) => {
            fe.applicationStatusList.forEach(a => { if (a.enabled && a.id < this.selectedApp) this.selectedApp = a.id; })
            //console.log(fe.initialOrder, fe.GetQuestionSet()?.qsGuid);
        });
        this.resetActiveList();
        setTimeout(function () {
            self.evaluateQuestions();
        }, 50);
        //console.log("Default App", this.selectedApp);
    }
    clearAnswers(selectedUpdate: number) {
        if (selectedUpdate !== -1) {
            this.selectedApp = selectedUpdate;
        }
        var answers = document.querySelector(".inline-preview-area");
        this.decision = undefined;
        this.clearChildren(answers);
        //this.resetActiveList();
        this.evaluateQuestions();
    }
    clearChildren(element: any) {
        for (var i = 0; i < element.childNodes.length; i++) {
            var e = element.childNodes[i];
            //console.log("ELEMENT", e, e.tagName);
            //if (e.tagName?.toLower() == "mat-radio-button" && e.classList.contains("mat-mdc-radio-checked")) {
            //    console.log("ELEMENT HAS CHECKED", e);
            //    e.classList.remove("mat-mdc-radio-checked")
            //}


            if (e.tagName) switch (e.tagName.toLowerCase()) {
                case 'mat-checkbox':
                    if (e.classList.contains('multiselect-limit-disabled')) {
                        e.classList.remove('multiselect-limit-disabled');
                    }
                    this.clearChildren(e);
                    break;
                case 'mat-radio-button':
                    if (e.classList.contains("mat-mdc-radio-checked")) {
                        e.classList.remove("mat-mdc-radio-checked");
                    }
                    //mat-mdc-radio-button mat-accent mat-mdc-radio-checked
                    //mat-mdc-radio-button mat-accent
                    this.clearChildren(e);
                    break;
                case 'input':
                    switch (e.type) {
                        case "radio":
                        case "checkbox": e.checked = false; break;
                        case "button":
                        case "submit":
                        case "image": break;
                        default: e.value = ''; break;
                    }
                    break;
                case 'select': e.selectedIndex = 0; break;
                case 'textarea': e.innerHTML = ''; break;
                default: this.clearChildren(e);
            }
        }
    }

    resetActiveList(): string {

        this.currentComputedIndex = 0;
        this.activeQuestions = [];
        this.inPathQuestions = [];
        var currentAppQS = this.p.questionSetControls
            .filter((f: QuestionSetControl) =>
                f.applicationStatusList.filter(asl => asl.id == this.selectedApp && asl.enabled).length > 0
            );
        //console.log("Reset active list result ",currentAppQS);

        var sortedQSC = currentAppQS.sort((a: QuestionSetControl, b: QuestionSetControl) => { return a.initialOrder! < b.initialOrder!; });
        if (sortedQSC.length > 0 && sortedQSC[0].GetQuestions().length > 0) {
            var qsGuid = sortedQSC[0].GetQuestionSet()?.qsGuid;
            //console.log("1st set ", qsGuid, sortedQSC[0].GetQuestionSet()?.questionSetHeader.title);
            var firstQuestion = sortedQSC[0].GetQuestions().filter((f: Question) => { return f.sequenceNumber == 1 })[0];
            //console.log("1st set Question", firstQuestion.text, firstQuestion.id, firstQuestion);
            this.activeQuestions.push(qsGuid + "-" + firstQuestion.id);
            //console.log(this.activeQuestions);
            this.currentComputedIndex = firstQuestion.internalOrder - 1;
        } else {
            console.log("No questions to determine application.")
            //this.notifySnak("No questions for Application", 10000);
        }
        return qsGuid;
    }
    isEnabledForApplicationPreview(qsc: QuestionSetControl) {
        var asid = qsc.applicationStatusList.filter(f => { return f.id == this.selectedApp });
        return asid.length > 0 ? asid[0].enabled : false;
    }
    evaluateQuestions() {
        //console.log("Evaluation ", this.activeQuestions);
        var firstQSGuid = this.resetActiveList();
        var firstQSC = this.getQuestionSetControlFromGuid(firstQSGuid);
        var id = this.getFirstQuestionForQuestionSet(firstQSC);
        this.evaluateQuestion(firstQSGuid, id, firstQSC);
        //this.notifySnak("Change Detected " + this.currentComputedIndex);
        //console.log("Change Detected in evaluate.");
    }
    getQuestionSetControlFromGuid(qsGuid: string) {
        return this.p.questionSetControls.filter((f: QuestionSetControl) => { return f.GetQuestionSet()!.qsGuid == qsGuid })[0];
    }
    getFirstQuestionForQuestionSet(qsc: QuestionSetControl): string {
        var first = "";
        var firstQuestion = qsc.GetQuestions().filter((f: Question) => f.sequenceNumber == 1)[0];
        if (firstQuestion !== undefined) {
            first = firstQuestion.id
        }
        return first;
    }
    evaluateQuestion(qsGuid: string, questionId: string, qsc: QuestionSetControl) {
        const self = this;
        document.querySelectorAll(".in-path")?.forEach(n => n.classList.remove("in-path"));
        document.querySelectorAll(".current-answer")?.forEach(n => n.classList.remove("current-answer"));
        var q = qsc.GetQuestionByID(questionId);
        this.currentComputedIndex = qsc.internalOrderOffset + q!.internalOrder;
        var nextId = this.getNextIDFromAnswer(qsGuid, q!, qsc);
        //console.log("checking answer for [" + nextId + "]");
        if (nextId == "") {
            //console.log("Invalid next");
            //if (q != null) {
            //    setTimeout(function () {
            self.evaluateInPath(qsGuid, q!, qsc);
            //    }, 50);
            //}
        } else if (nextId == "Final") {
            //show decision
            this.currentComputedIndex = 9999;
            if (this.decision === undefined) {
                this.decision = new ActionResult({ actionType: "Missing Decision" });
            }
        } else if (nextId == "End") {
            //console.log("get next QS in sequence")
            let newOrder = qsc.initialOrder! + 1;
            let nextQSC = this.p.questionSetControls.filter((f: QuestionSetControl) => { return f.initialOrder == newOrder })[0];
            let nextQSGuid = nextQSC.GetQuestionSet()?.qsGuid;
            let firstQuestion = nextQSC.GetQuestions().filter((f: Question) => { return f.sequenceNumber == 1 })[0];
            this.activeQuestions.push(nextQSGuid + "-" + firstQuestion.id);
            this.currentComputedIndex = nextQSC.internalOrderOffset + firstQuestion.sequenceNumber;
            this.evaluateQuestion(nextQSGuid, firstQuestion.id, nextQSC)

        } else if (nextId.startsWith("next-")) {
            //console.log("lookup function for QSGuid")
            let nextQSGuid = nextId.substring(5);
            let nextQSC = this.p.questionSetControls.filter((f: QuestionSetControl) => { return f.GetQuestionSet()!.qsGuid == nextQSGuid })[0];
            let firstQuestion = nextQSC.GetQuestions().filter((f: Question) => { return f.sequenceNumber == 1 })[0];
            this.activeQuestions.push(nextQSGuid + "-" + firstQuestion.id);
            this.currentComputedIndex = nextQSC.internalOrderOffset + firstQuestion.sequenceNumber;
            this.evaluateQuestion(nextQSGuid, firstQuestion.id, nextQSC)
        } else {
            this.activeQuestions.push(qsGuid + "-" + nextId);
            //console.log(this.activeQuestions);
            this.evaluateQuestion(qsGuid, nextId, qsc)
        }
    }
    getNextIDFromAnswer(qsGuid: string, q: Question, qsc: QuestionSetControl): string {
        var nextId = "";
        var actionResult: ActionResult | undefined;
        if (qsc.GetQuestionTypeFromQuestion(q) == 'Select') {
            var selectType = qsc.GetSelectType(q);
            var selctor = '[data-question-selector="' + qsGuid + '-' + q.id + '"] input:checked'
            var questionNode: any = document.querySelectorAll(selctor);

            questionNode.forEach((fen: any) => {
                //console.log("fen", fen);
                var par = fen.closest(".answer");
                //console.log("parent 1", par );
                //console.log("parent 2", par.className);
                par.className += " current-answer";
            });
            let selectedCount = questionNode.length;
            if (selectedCount > 0) {
                if (selectType.isMultiple) {
                    var answer = questionNode[0].value;
                    if (answer != "" && answer !== undefined) {
                        let selectedChoice = selectType.choice.filter(f => { return f.id == answer })[0];
                        if (selectedChoice.action != null) {
                            actionResult = selectedChoice.action[0];
                        } else if (q.action != null) {
                            actionResult = q.action[0];
                        }
                        nextId = selectedChoice.nextQuestionID ?? "";
                    }
                    let ncqs = selectType;
                    if (ncqs.nextQuestionCondition.length > 0) {
                        ncqs.nextQuestionCondition.forEach(nqc => {
                            if (nqc.comparison.nextQuestionID !== undefined
                                && this.evaluateNextQuestionConditionComplete(nqc, selectedCount, true)
                            ) {
                                nextId = nqc.comparison.nextQuestionID;
                                if (nqc.comparison.action.length > 0) {
                                    actionResult = nqc.comparison.action[0];
                                } else if (q.action.length > 0) {
                                    actionResult = q.action[0];
                                }
                            }
                        });
                    }
                    if ((nextId == "" || nextId == "Default") && selectedCount > 0) {
                        nextId = q.defaultNextQuestionID ?? "";
                        if (q.action.length > 0) {
                            actionResult = q.action[0];
                        }
                    }
                } else {
                    var answer = questionNode[0].value;
                    if (answer != "" && answer !== undefined) {
                        let selectedChoice = selectType.choice.filter(f => { return f.id == answer })[0];
                        nextId = selectedChoice.nextQuestionID ?? "";
                        if (nextId == "" || nextId == "Default") {
                            nextId = q.defaultNextQuestionID ?? "";
                        }
                        //console.log("AR ", selectedChoice.action, q.action)
                        if (selectedChoice.action.length > 0) {
                            actionResult = selectedChoice.action[0];
                        } else if (q.action.length > 0) {
                            actionResult = q.action[0];
                        }

                    }
                }
            }
        } else if (qsc.GetQuestionTypeFromQuestion(q) == 'Numeric') {
            let selctor = '[data-question-selector="' + qsGuid + '-' + q.id + '"] input';
            let questionNode: any = document.querySelector(selctor);

            //console.log(questionNode);
            let par = questionNode.closest(".answer");
            //console.log("parent 1", par);
            //console.log("parent 2", par.className);
            par.className += " current-answer";

            let value = questionNode.value;
            //console.log("Numeric ", selctor, value);
            let ncqs = qsc.GetNumericType(q);
            if (ncqs.nextQuestionCondition.length > 0) {
                ncqs.nextQuestionCondition.forEach(nqc => {
                    if (nqc.comparison.nextQuestionID !== undefined
                        && this.evaluateNextQuestionConditionComplete(nqc, value, true)
                    ) {
                        nextId = nqc.comparison.nextQuestionID;
                        if (nqc.comparison.action.length > 0) {
                            actionResult = nqc.comparison.action[0];
                        } else if (q.action.length > 0) {
                            actionResult = q.action[0];
                        }
                    }
                });
            }
            if (nextId == "" && value != "") {
                nextId = q.defaultNextQuestionID ?? "";
                if (q.action.length > 0) {
                    actionResult = q.action[0];
                }
            }

        } else if (qsc.GetQuestionTypeFromQuestion(q) == 'DateTime') {
            let selctor = '[data-question-selector="' + qsGuid + '-' + q.id + '"] input';
            //console.log("Date Selector", selctor);
            let questionNode: any = document.querySelector(selctor);

            //console.log(questionNode);
            let par = questionNode.closest(".answer");
            //console.log("parent 1", par);
            //console.log("parent 2", par.className);
            par.className += " current-answer";

            let value = questionNode.value;
            //console.log("Date ", selctor, value);
            let ncqs = qsc.GetDateType(q);
            if (ncqs.nextQuestionCondition.length > 0) {
                ncqs.nextQuestionCondition.forEach(nqc => {
                    if (nqc.comparison.nextQuestionID !== undefined
                        && this.evaluateNextQuestionConditionComplete(nqc, value, false)
                    ) {
                        nextId = nqc.comparison.nextQuestionID;
                        if (nqc.comparison.action.length > 0) {
                            actionResult = nqc.comparison.action[0];
                        } else if (q.action.length > 0) {
                            actionResult = q.action[0];
                        }
                    }
                });
            }
            if (nextId == "" && value != "") {
                nextId = q.defaultNextQuestionID ?? "";
                if (q.action.length > 0) {
                    actionResult = q.action[0];
                }
            }

        } else if (qsc.GetQuestionTypeFromQuestion(q) == 'FreeText') {
            let selctor = '[data-question-selector="' + qsGuid + '-' + q.id + '"] input';
            let questionNode: any = document.querySelector(selctor);
            if (questionNode != null) {
                //console.log(questionNode);
                let par = questionNode.closest(".answer");
                //console.log("parent 1", par);
                //console.log("parent 2", par.className);
                par.className += " current-answer";


                let value = questionNode.value;
                //console.log("Text", value);
                if (value != "") {
                    nextId = q.defaultNextQuestionID ?? "";
                    if (q.action.length > 0) {
                        actionResult = q.action[0];
                    }
                }
            }
        } else {
            alert("Missing type")
        }
        // add others

        this.decision = actionResult;
        //console.log("Decision", actionResult);

        return nextId;
    }
    evaluateNextQuestionConditionComplete(nqc: NextQuestionCondition, value: any, parseNumeric: boolean) {
        var conditionComplete: boolean = false;
        var answerValue: any;
        if (parseNumeric) {
            answerValue = parseFloat(value);
        } else {
            var d = new Date(Date.parse(value));
            answerValue = d.toISOString();

        }
        if (nqc.type == "Single") {
            var comparisonValue;
            let comp = <SingleComparison>nqc.comparison;
            if (parseNumeric) comparisonValue = comp.comparisonValue;
            else comparisonValue = new Date(Date.parse(comp.comparisonValue)).toISOString();
            //console.log("Comparing '" + answerValue + "' and '" + comparisonValue);
            switch (comp.comparisonOperator) {
                case "LT": conditionComplete = answerValue < comparisonValue; break;
                case "GT": conditionComplete = answerValue > comparisonValue; break;
                case "EQ": conditionComplete = answerValue == comparisonValue; break;
                case "NE": conditionComplete = answerValue != comparisonValue; break;
                case "LE": conditionComplete = answerValue <= comparisonValue; break;
                case "GE": conditionComplete = answerValue >= comparisonValue; break;
            }
            //console.log("SC " + answerValue + " " + nqc.comparison.comparisonOperator + " " + comparisonValue)
        } else if (nqc.type == "Range") {
            let conditionCompleteLower: boolean = false;
            let conditionCompleteUpper: boolean = false;
            var comparisonValue: any;
            let comp = <RangeComparison>nqc.comparison;
            if (parseNumeric) comparisonValue = parseFloat(comp.lowerBoundComparisonValue);
            else comparisonValue = new Date(Date.parse(comp.lowerBoundComparisonValue)).toISOString();
            switch (comp.lowerBoundComparisonOperator) {
                case "LT": conditionCompleteLower = answerValue < comparisonValue; break;
                case "GT": conditionCompleteLower = answerValue > comparisonValue; break;
                case "LE": conditionCompleteLower = answerValue <= comparisonValue; break;
                case "GE": conditionCompleteLower = answerValue >= comparisonValue; break;
            }
            //console.log("LC " + answerValue + " " + comp.lowerBoundComparisonOperator + " " + comparisonValue + " = " + conditionCompleteLower)
            if (parseNumeric) comparisonValue = parseFloat(comp.upperBoundComparisonValue);
            else comparisonValue = new Date(Date.parse(comp.upperBoundComparisonValue)).toISOString();
            switch (comp.upperBoundComparisonOperator) {
                case "LT": conditionCompleteUpper = answerValue < comparisonValue; break;
                case "GT": conditionCompleteUpper = answerValue > comparisonValue; break;
                case "LE": conditionCompleteUpper = answerValue <= comparisonValue; break;
                case "GE": conditionCompleteUpper = answerValue >= comparisonValue; break;
            }
            //console.log("UC " + answerValue + " " + nqc.comparison.upperBoundComparisonOperator + " " + comparisonValue + " = " + conditionCompleteUpper)
            conditionComplete = conditionCompleteUpper && conditionCompleteLower;
        }
        //console.log("Condition check result ", conditionComplete);
        return conditionComplete;

    }

    visitPath(qsGuid: string, id: string, qsc: QuestionSetControl): any {
        var r: any = {};
        if (id == "" || id == "Final" || id == "default") {
            // No further visiting
        } else if (id == "End") {
            console.log("get next QS in sequence")
            let newOrder = qsc.initialOrder! + 1;
            r.qsc = this.p.questionSetControls.filter((f: QuestionSetControl) => { return f.initialOrder == newOrder })[0];
            r.qsGuid = r.qsc.GetQuestionSet()?.qsGuid;
            r.q = r.qsc.GetQuestions().filter((f: Question) => { return f.sequenceNumber == 1 })[0];
        } else if (id.startsWith("next-")) {
            r.qsGuid = id.substring(5);
            r.qsc = this.p.questionSetControls.filter((f: QuestionSetControl) => { return f.GetQuestionSet()!.qsGuid == r.qsGuid })[0];
            r.q = r.qsc.GetQuestions().filter((f: Question) => { return f.sequenceNumber == 1 })[0];
        } else {
            r.qsGuid = qsGuid;
            r.qsc = qsc;
            r.q = r.qsc.GetQuestions().filter((f: Question) => { return f.id == id })[0];
        }
        if (r.q !== undefined) {
            //console.log("Visiting ", qsGuid, id, r);
            this.inPathQuestions.push(r.qsGuid + "-" + r.q.id);
            //var selctor = '[data-question-selector="' + r.qsGuid + '-' + r.q.id + '"]';
            //var e = document.querySelector(selctor);
            //if (e == undefined) {
            //    debugger;
            //}
            //console.log("Question in path ", selctor, e);
            //e?.classList.add("in-path");
            this.evaluateInPath(r.qsGuid, r.q, r.qsc);
        }
        //cb44551c-6f46-4f3a-9d3c-d3ecdcd6d2a3 105
        //cb44551c-6f46-4f3a-9d3c-d3ecdcd6d2a3 105
    }
    evaluateInPath(qsGuid: string, q: Question, qsc: QuestionSetControl) {
        if (q.defaultNextQuestionID) {
            this.visitPath(qsGuid, q.defaultNextQuestionID, qsc);
        }
        if (qsc.GetQuestionTypeFromQuestion(q) == 'Select') {
            var selectType = qsc.GetSelectType(q);
            selectType.choice.forEach(c => {
                if (c.nextQuestionID != undefined) {
                    this.visitPath(qsGuid, c.nextQuestionID, qsc);
                }
            });
            selectType.nextQuestionCondition.forEach(nqc => {
                if (nqc.comparison.nextQuestionID !== undefined) {
                    this.visitPath(qsGuid, nqc.comparison.nextQuestionID, qsc);
                }
            });

        } else if (qsc.GetQuestionTypeFromQuestion(q) == 'Numeric') {
            let numericType = qsc.GetNumericType(q);
            numericType.nextQuestionCondition.forEach(nqc => {
                if (nqc.comparison.nextQuestionID !== undefined) {
                    this.visitPath(qsGuid, nqc.comparison.nextQuestionID, qsc);
                }
            });
        } else if (qsc.GetQuestionTypeFromQuestion(q) == 'DateTime') {
            let dateType = qsc.GetDateType(q);
            dateType.nextQuestionCondition.forEach(nqc => {
                if (nqc.comparison.nextQuestionID !== undefined) {
                    this.visitPath(qsGuid, nqc.comparison.nextQuestionID, qsc);
                }
            });
        } else if (qsc.GetQuestionTypeFromQuestion(q) == 'FreeText') {
        } else {
            console.log("Missing type")
        }
        // add others

    }
    showQuestion(qsGuid: string, id: string) {
        var show: boolean = this.activeQuestions.indexOf(qsGuid + '-' + id) >= 0
            || (this.showSkipped && this.showAll);
        if (!show && this.showAll) {
            show = this.inPathQuestions.indexOf(qsGuid + '-' + id) >= 0;
        }
        return show;
        //(p.showAll && (p.showSkipped || ((p.inPathQuestions.indexOf(qsGuid + '-' + q.id) >= 0) || (p.currentComputedIndex < (qsc.internalOrderOffset + q.sequenceNumber))))))
    }
    notifySnak(text: string, timeToClose = 2000) {
        document.querySelector(".fakesnak")?.remove();
        var fakesnak = document.createElement("div");
        fakesnak.innerHTML = '<span class="material-icons">check_circle</span>'
            + '<div class="snakcontent">' + text + '</div>'
            + '<span class="material-icons postfix" onclick="document.querySelector(\'.fakesnak\').remove()">cancel</span>';
        fakesnak.classList.add("fakesnak");
        document.body.appendChild(fakesnak);
        setTimeout(function () {
            document.querySelector(".fakesnak")?.remove();
        }, timeToClose);
    }

}
