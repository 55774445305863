import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-approve-dialog',
    templateUrl: './approve-dialog.component.html',
    styleUrls: ['./approve-dialog.component.scss']
})
export class ApproveDialogComponent {
    public title: string = "Approve Decision Tree";
    public submitButtonText: string = "Approve";
    public dialogText: string = "";
    public minStartDate: Date;
    public showComment: boolean = true;
    public approveForm: FormGroup & { [key: string]: FormControl };
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<ApproveDialogComponent>
    ) {
        var cd = new Date();
        this.minStartDate = new Date(cd.getFullYear(), cd.getMonth(), cd.getDate());
        let defaultStartDate: Date;
        let defaultEndDate: Date;
        defaultEndDate = new Date(2099, 12, 31)
        if (data !== undefined) {
            //if (data.minStartTomorrow) {
            //    this.minStartDate.setDate(this.minStartDate.getDate() + 1);
            //}
            defaultStartDate = new Date(data.defaultStartDate);
            defaultEndDate = new Date(data.defaultEndDate);
            if (this.minStartDate < defaultStartDate) {
                this.minStartDate = defaultStartDate;
            }
            if (defaultEndDate < this.minStartDate) {
                defaultEndDate = new Date(2099, 12, 31)
            }
            if (Object.keys(data).indexOf("title") > -1) {
                this.title = data.title;
            }
            if (Object.keys(data).indexOf("submitButtonText") > -1) {
                this.submitButtonText = data.submitButtonText;
            }
            if (Object.keys(data).indexOf("dialogText") > -1) {
                this.dialogText = data.dialogText;
            }
            if (Object.keys(data).indexOf("showComment") > -1) {
                this.showComment = data.showComment;
            }
        }
        this.approveForm = this.fb.group({
            start: [new Date(), Validators.required],
            end: [new Date(), Validators.required],
            comment: [''],
        }) as unknown as FormGroup & { [key: string]: FormControl };
        this.approveForm.controls['start'].patchValue(this.minStartDate);
        this.approveForm.controls['end'].patchValue(defaultEndDate);
    }
    close() {
        this.dialogRef.close("0");
    }
    save() {
        this.dialogRef.close({
            start: this.approveForm.controls['start'].value!,
            end: this.approveForm.controls['end'].value!,
            comment: this.approveForm.controls['comment'].value ?? ""!
        });
    }
}
