import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { JustificationCaseSettings, JustificationDeterminationLevelSettings, JustificationSettings } from '../classes/decisiontreesettings';
import { Justification } from '../classes/justification';
import { AuthService } from '../shared/auth/auth.service';
import { ApiPaths } from '../shared/environments/routes';
import { environment } from '../shared/environments/environments';

@Injectable({
    providedIn: 'root'
})
export class JustificationsService {

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getAllJustificationFromService(clientID: string, desc: string = '', decision: string = '', pcn: string = '', pcnDesc: string = ''): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('ClientID', clientID);
        queryParams = queryParams.append('JustificationDescription', desc);
        queryParams = queryParams.append('JustificationDecision', decision);
        queryParams = queryParams.append('pcn', pcn);
        queryParams = queryParams.append('PCNDescription', pcnDesc);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetJustificationTemplateDashboard, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public searchJustifications (clientID: string, determinationLevelList: string, decision: string, desc: string = '', pcnList: string = '', caseTypeList: string = ''): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('ClientID', clientID);
        queryParams = queryParams.append('PCNReferenceIDDelimitedList', pcnList);
        queryParams = queryParams.append('DeterminationLevelDelimitedString', determinationLevelList);
        queryParams = queryParams.append('CaseTypesDelimitedString', caseTypeList);
        queryParams = queryParams.append('JustificationDescription', desc);
        queryParams = queryParams.append('JustificationDecision', decision);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.SearchJustiificationReason, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public saveJustificationToCaseType(justificationID: number, caseTypeID: number, applicationUserID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('JustificationTemplateID', justificationID);
        queryParams = queryParams.append('CaseTypeID', caseTypeID);
        queryParams = queryParams.append('ApplicationUserID', applicationUserID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.SaveJustificationToCaseType, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public saveJustificationToDeterminationLevel(justificationID: number, determinationLevelID: number, applicationUserID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('JustificationTemplateID', justificationID);
        queryParams = queryParams.append('DeterminationLevelID', determinationLevelID);
        queryParams = queryParams.append('ApplicationUserID', applicationUserID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.SaveJustificationToDeterminationLevel, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapJustificationsFromApiResponse(response: HttpResponse<any>): Justification[] {
        const justifications: Justification[] = [];

        if (response.body) {
            for (const data of response.body) {
                const justification = new Justification();
                justification.JustificantionTemplateID = data.justificationTemplateID;
                justification.JustificationDescription = data.justificationDescription;
                justification.JustificationDecision = data.justificationDecision;
                justification.ClientID = data.clientID;
                justification.PCN = data.pcn;
                justification.PCNDescription = data.pcnDescription;

                justifications.push(justification);
            }
        }

        return justifications;
    }

    public getJustificationByTemplateID(justificationTemplateID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('JustificationTemplateID', justificationTemplateID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetJustificationTemplate, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    mapJustificationSettingsFromResponse(response: any): JustificationSettings {
        const justificationSettings = new JustificationSettings();

        // Map properties from the response to the justificationSettings object
        justificationSettings.JustificationTemplateID = response.justificationTemplate.justificationTemplateID;
        justificationSettings.JustificationDescription = response.justificationTemplate.justificationDescription;
        justificationSettings.JustificationDecision = response.justificationTemplate.justificationDecision;
        justificationSettings.JustificationDecisionReason = response.justificationTemplate.justificationDecisionReason;
        justificationSettings.ClientID = response.justificationTemplate.clientID;
        justificationSettings.pcnReferenceID = response.justificationTemplate.pcnReferenceID;
        justificationSettings.PCNDescription = response.justificationTemplate.PCNDescription;
        justificationSettings.PCN = response.justificationTemplate.PCN;
        justificationSettings.JustificationReasonJson = response.justificationTemplate.justificationReasonJson;
        justificationSettings.OverridesJson = response.justificationTemplate.overridesJson;
        justificationSettings.OverrideCriteria = response.justificationTemplate.overrideCriteria;

        // Map justificationTemplateToCaseTypeList
        justificationSettings.CaseType = response.justificationTemplateToCaseTypeList.map((item: any) => {
            const caseSettings = new JustificationCaseSettings();
            caseSettings.JustificationTemplateToCaseTypeID = item.justificationTemplateToCaseTypeID;
            caseSettings.CaseTypeID = item.caseTypeID;
            // You can add more mappings here if needed
            return caseSettings;
        });

        // Map justificationTemplateToDeterminationLevelList
        justificationSettings.DeterminationLevel = response.justificationTemplateToDeterminationLevelList.map((item: any) => {
            const determinationLevelSettings = new JustificationDeterminationLevelSettings();
            determinationLevelSettings.JustificationTemplateToDeterminationLevelID = item.justificationTemplateToDeterminationLevelID;
            determinationLevelSettings.DeterminationLevelID = item.determinationLevelID;
            // You can add more mappings here if needed
            return determinationLevelSettings;
        });

        return justificationSettings;
    }

    mapJustificationSettingsArrayFromResponse(response: any[]): JustificationSettings[] {
        return response.map(item => {
            const justificationSettings = new JustificationSettings();
            justificationSettings.JustificationTemplateID = item.justificationTemplateID!;
            justificationSettings.JustificationDescription = item.justificationDescription;
            justificationSettings.JustificationDecision = item.justificationDecision;
            justificationSettings.JustificationDecisionReason = item.justificationDecisionReason;
            justificationSettings.ClientID = item.clientID;
            justificationSettings.pcnReferenceID = item.pcnReferenceID;
            justificationSettings.PCNDescription = item.PCNDescription;
            justificationSettings.PCN = item.PCN;
            justificationSettings.JustificationReasonJson = item.justificationReasonJson;
            justificationSettings.OverridesJson = item.overridesJson;
            justificationSettings.OverrideCriteria = item.overrideCriteria;

            if (item.justificationTemplateToCaseTypeList) {
                justificationSettings.CaseType = item.justificationTemplateToCaseTypeList.map((caseItem: { justificationTemplateToCaseTypeID: number | undefined; caseTypeID: number | undefined; }) => {
                    const caseSettings = new JustificationCaseSettings();
                    caseSettings.JustificationTemplateToCaseTypeID = caseItem.justificationTemplateToCaseTypeID;
                    caseSettings.CaseTypeID = caseItem.caseTypeID;
                    // You can add more mappings here if needed
                    return caseSettings;
                });
            } else {
                justificationSettings.CaseType = [];
            }

            if (item.justificationTemplateToDeterminationLevelList) {
                justificationSettings.DeterminationLevel = item.justificationTemplateToDeterminationLevelList.map((levelItem: { justificationTemplateToDeterminationLevelID: number | undefined; determinationLevelID: number | undefined; }) => {
                    const determinationLevelSettings = new JustificationDeterminationLevelSettings();
                    determinationLevelSettings.JustificationTemplateToDeterminationLevelID = levelItem.justificationTemplateToDeterminationLevelID;
                    determinationLevelSettings.DeterminationLevelID = levelItem.determinationLevelID;
                    // You can add more mappings here if needed
                    return determinationLevelSettings;
                });
            } else {
                justificationSettings.DeterminationLevel = [];
            }
            

            return justificationSettings;
        });
    }



    public addJustification(JustificationDescription: string, JustificationDecision: string, JustificationDecisionReason: string | undefined | null, ClientID: string, pcnReferenceID: number | undefined | null, JustificationReasonJson: string | undefined | null, OverridesJson: string | undefined | null = "", OverrideCriteria: string | undefined | null = "", ApplicationUserID: number) {
        //var httpHeaders = this.getHeaders();
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});

        const body = {
            justificationDescription: JustificationDescription,
            justificationDecision: JustificationDecision,
            justificationDecisionReason: JustificationDecisionReason,
            clientID: ClientID,
            pcnReferenceID: pcnReferenceID,
            justificationReasonJson: JustificationReasonJson,
            overridesJson: OverridesJson,
            overrideCriteria: OverrideCriteria,
            applicationUserID: ApplicationUserID
        };

        return this.http.post(environment.baseUrl + ApiPaths.SaveJustification, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }
    public updateJustification(id:number, JustificationDescription: string, JustificationDecision: string, JustificationDecisionReason: string | undefined | null, ClientID: string, pcnReferenceID: number | undefined | null, JustificationReasonJson: string | undefined | null, OverridesJson: string | undefined | null = "", OverrideCriteria: string | undefined | null = "", ApplicationUserID: number) {
        //var httpHeaders = this.getHeaders();
        var httpHeaders = new HttpHeaders({ 'Content-Type': "application/json" });

        const body = {
            justificationTemplateID: id,
            justificationDescription: JustificationDescription,
            justificationDecision: JustificationDecision,
            justificationDecisionReason: JustificationDecisionReason,
            clientID: ClientID,
            pcnReferenceID: pcnReferenceID,
            justificationReasonJson: JustificationReasonJson,
            overridesJson: OverridesJson,
            overrideCriteria: OverrideCriteria,
            applicationUserID: ApplicationUserID
        };

        return this.http.post(environment.baseUrl + ApiPaths.UpdateJustification, body, { headers: httpHeaders, observe: 'response' })
            .pipe(map((response: any) => response));
    }
    public deleteJustificationFromCaseType(justificationTemplateID: number, justificationTemplateToCaseTypeID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('JustificationTemplateID', justificationTemplateID);
        queryParams = queryParams.append('JustificationTemplateToCaseTypeID', justificationTemplateToCaseTypeID);
        

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.DeleteJustificationFromCaseType, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public deleteJustificationFromDeterminationLevel(justificationTemplateID: number, justificationTemplateToDeterminationLevelID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('JustificationTemplateID', justificationTemplateID);
        queryParams = queryParams.append('JustificationTemplateToDeterminationLevelID', justificationTemplateToDeterminationLevelID);
        

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.DeleteJustificationFromDeterminationLevel, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public deleteJustificationTemplate(justificationTemplateID: number, applicationUserID: number): Observable<any> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();

        queryParams = queryParams.append('JustificationTemplateID', justificationTemplateID);
        queryParams = queryParams.append('ApplicationUserID', applicationUserID);


        return this.http.get<any>(
            environment.baseUrl + ApiPaths.DeleteJustificationTemplate, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }
}
