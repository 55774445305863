import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CodedReference } from '../../classes/questionset';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { CodedReferenceService } from '../../shared/services/coded-reference.service';
import { SortService } from '../../shared/services/sort.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-codedreference-modal',
  templateUrl: './codedreference-modal.component.html',
  styleUrls: ['./codedreference-modal.component.scss']
})
export class CodedreferenceModalComponent {
    @Input() currentQuestionID!: string;
    @Input() p!: any;
    @Output() event: EventEmitter<any> = new EventEmitter();
    user!: User;
    codedReferences: CodedReference[] = [];
    filteredCodedReferences: CodedReference[] = [];
    filterValue: string = "";

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<CodedreferenceModalComponent>,
        private authService: AuthService,
        //public formatter: NgbDateParserFormatter,
        private codedReferenceService: CodedReferenceService,
        private sortService: SortService,
        private fb: FormBuilder)
    {
        this.user = this.authService.getUserObject();
        if (data !== undefined) {
            this.currentQuestionID = data.currentQuestionID;
            this.p = data.p;
        }
    }

    ngOnInit() {
        this.PopulateCodedReferences();
    }

    PopulateCodedReferences() {
        this.codedReferences = [];
        this.codedReferenceService.getAllCodedReferences().subscribe(resp => {
            if (resp.body) {
                this.codedReferences = this.codedReferenceService.mapCodedReferencesResponse(resp);
                console.log(this.codedReferences);
                if (this.codedReferences.length >= 1) {
                    this.codedReferences = this.codedReferences.sort(this.sortService.sortByMultiple(['description'], 'desc'));
                }
            }
        });
    }

    AddCodedReference(c: CodedReference) {
        //check if it is an answer
        if (this.p.answerChoice) {
            console.log("answer");
            this.p.answerChoice.codedReference.push(c);
        } else if (this.p.question) {
            console.log("question");
            this.p.question.codedReference.push(c)
        }
        this.dialogRef.close("1");
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Convert to lowercase
        this.filteredCodedReferences = this.codedReferences.filter(cr => {
            return cr.code!.toLowerCase().includes(filterValue) || cr.description!.toLowerCase().includes(filterValue) || cr.qualifier!.toLowerCase().includes(filterValue);
        });
        this.filteredCodedReferences = this.filteredCodedReferences?.sort(this.sortService.sortByMultiple(['description'], 'desc'));
    }

    close() {
        this.dialogRef.close("0");
    }
}
