<div class="answerChoice" [attr.data-choiceid]="answerChoice.id"
     style="display: flex; background-color: #eff1f1; margin-top: 3px; padding: 3px; ">

    <div class="answer-move-icon" (click)="moveDown()">
        <mat-icon class="single_select_option_outline">radio_button_unchecked</mat-icon>
        <mat-icon class="multi_select_option_outline ">check_box_outline_blank</mat-icon>
        <mat-icon class="move-question-arrow" style="display:none">arrow_drop_down</mat-icon>
    </div>
    <div style="flex: 1 0 0; display:flex; align-items: center;height:50px;">
        &nbsp;{{answerChoice.sequence}}&nbsp;
    </div>

    <div style="flex: 100 100 auto" *ngIf="!p.question.questionType.isYesNoInternalOnly">
        <mat-form-field color="accent" class="full-width answerchoicetext"
                        appearance="outline">
            <mat-label>
            </mat-label>
            <!--id="choiceText"-->
            <input matInput type="text" class="full-width"
                   required placeholder="Enter answer choice text"
                   [(ngModel)]="answerChoice.choiceText" name="text" />

            <mat-hint *ngIf="answerChoice.choiceText?.length == 0" class="i-am-error error-inline">
                Please provide an Answer.
            </mat-hint>
        </mat-form-field>
    </div>
    <div style="display: flex; flex: 100 100 auto; height:50px; align-items: center;" *ngIf="p.question.questionType.isYesNoInternalOnly">
        {{answerChoice.choiceText}}
    </div>

    <div style="flex: 340px 0 0; display: flex;"><!--*ngIf="isSingleSelect()"-->
        <div style="flex: 40px 0 0; display:flex; align-items: center; height: 50px; white-space:nowrap">
            &nbsp;go to&nbsp;
        </div>
        <div style="flex: 150px 0 0">
            <button mat-stroked-button class="fake-select" 
                    (click)="openRoutingModal()">
                {{GetNextQuestionLabel()}} <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
            </button>
            <!--<div class="path-icon-addon" (click)="openRoutingModal()">
            <mat-icon>arrow_drop_down</mat-icon>
        </div>-->
        </div>
        <!--"openActionResultModal()"-->
        <div style="flex: 150px 0 0">
            <app-decision-selection [p]="this" [actionContainer]="answerChoice" [isError]="answerChoice.nextQuestionID == 'Final' && ( answerChoice.action.length == 0)"></app-decision-selection>
            <div *ngIf="answerChoice.nextQuestionID == 'Final' && ( answerChoice.action.length == 0)" class="i-am-error error-inline ">
                Please select a decision.
            </div>
        </div>
    </div>
    <div style="flex: 40px 0 0; align-items: center; height: 50px;" >

        <button mat-icon-button (click)="remove()" class="remove-button">
            <mat-icon>close</mat-icon>
        </button>

        <button mat-icon-button style="border:0px;display:none" [matMenuTriggerFor]="answerchoicemenu">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <mat-menu #answerchoicemenu="matMenu">
        <button mat-flat-button color="basic" class="full-width" (click)="openCodedReferenceModal()">Add Coded Reference</button>
        <button mat-flat-button color="basic" class="full-width" disabled>Move</button>

    </mat-menu>

</div>

<div *ngIf="answerChoice.additionalFreeTextIndicator != 'NA'" style="height: 40px; padding: 8px 0px 8px 16px; border-radius: 4px; margin-bottom: 5px; background-color: #eff1f1;" >
    <div style="margin-left: 30px; margin-right: 60px; height: 24px; color: gray; font-weight: 400; font-size: 16px; line-height: 24px;  background-color: #fff;">
        &nbsp; Enter "Other"
    </div>
</div>


<span *ngFor="let cr of answerChoice.codedReference" style="width:unset;margin:4px;">
    <app-codedreference-template [codedReference]="cr" [p]="answerChoice"></app-codedreference-template>
</span>
<div *ngFor="let ar of answerChoice.action" class="add-justification-message">
    <!--<app-actionresult-template [actionResult]="ar" [p]="this"></app-actionresult-template>-->
    <div *ngIf="ar.actionName == '' || ar.actionName == null" class="bluealert">
        <div class="title" style=" ">Add Justification Message</div>
        <button (click)="openAddDecisionJustificationModal(ar)">Add</button>
    </div>
    <div *ngIf="ar.actionName != '' && ar.actionName != null" class="greenalert">
        <div class="title" style=" ">Edit Justification Message</div>
        <button (click)="openAddDecisionJustificationModal(ar)">Edit</button>
    </div>
</div>





