import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-comment-dialog',
  templateUrl: './comment-dialog.component.html',
  styleUrls: ['./comment-dialog.component.scss']
})
export class CommentDialogComponent {
    public showComment: boolean = true;
    public comment: string = "";
    public title: string = "Comment";
    public labelForComment: string = "labelForComment";
    public submitButtonText: string = "submitButtonText";
    public dialogText: string = "";
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<CommentDialogComponent>
    ) {
        if (Object.keys(data).indexOf("title") > -1) {
            this.title = data.title;
        }
        if (Object.keys(data).indexOf("labelForComment") > -1) {
            this.labelForComment = data.labelForComment;
        }
        if (Object.keys(data).indexOf("submitButtonText") > -1) {
            this.submitButtonText = data.submitButtonText;
        }
        if (Object.keys(data).indexOf("dialogText") > -1) {
            this.dialogText = data.dialogText;
        }
        if (Object.keys(data).indexOf("showComment") > -1) {
            this.showComment = data.showComment;
        }

    }
    close() {
        this.dialogRef.close("0");
    }
    save() {
        this.dialogRef.close(this.comment);
    }
}
