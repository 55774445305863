import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-edit-question-decision-item-override-auth-period-modal',
    templateUrl: './edit-question-decision-item-override-auth-period-modal.component.html',
    styleUrls: ['./edit-question-decision-item-override-auth-period-modal.component.scss']
})
export class EditQuestionDecisionItemOverrideAuthPeriodModalComponent {
    customPeriod: { value: string, unit: string } = { value: '', unit: '' };

    constructor(
        public dialogRef: MatDialogRef<EditQuestionDecisionItemOverrideAuthPeriodModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { this.customPeriod.unit = 'Hours' }

    addPeriod(): void {
        this.dialogRef.close(this.customPeriod);
    }

    cancel(): void {
        this.dialogRef.close();
    }
}