import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { DecisionTreeService } from '../decision-tree.service';

@Component({
  selector: 'app-edit-history-modal',
  templateUrl: './edit-history-modal.component.html',
  styleUrls: ['./edit-history-modal.component.scss']
})
export class EditHistoryModalComponent {
    public targetTreeHeaderID: number = 0;
    public records: any[] = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<EditHistoryModalComponent>,
        private dialog: MatDialog,
        private decisionTreeService: DecisionTreeService
    ) {
        if (data !== undefined) {
            if (data.decisionTreeHeaderID) {
                this.targetTreeHeaderID = data.decisionTreeHeaderID;
                this.decisionTreeService.getDecisionTreeHistoryByDecsionTreeHeaderID(data.decisionTreeHeaderID)
                    .subscribe((resp:any) => {
                        if (resp.body) {
                            console.log(resp.body);
                            try {
                                this.records = resp.body.sort((a: any, b: any)=>a.updateDate < b.updateDate ? 1 : -1);
                            } catch (e) {
                                console.log(e);
                                alert("Failed to access history");
                            }
                            // load history object here ...
                        }
                    });
            }
        }
    }
    close() {
        this.dialogRef.close("0");
    }
}
