

export class SVGQuestionDisplay {
    private xCord = 0;
    private yCord = 0;
    private strokeWidth = 4;
    private yHeight = 50;
    private yOffset = 150;
    private xOffset = 210;
    private moveLength = 200;
    private scale = 1;
    private svgGroup: HTMLElement;
    private svgControls: HTMLElement;
    private positions: any = {};
    private debugSVG = false;
    private arbarcaPurple = "#800088";
    constructor() {
        this.svgGroup = document.createElementNS("", "g") as HTMLElement;
        this.svgControls = document.createElementNS("", "g") as HTMLElement;
    }

    SetCanvas(svgId: string, svgControls: string) {
        var el = document.getElementById(svgId);
        if (el != null) {
            this.svgGroup = el;
        } else {
            if (this.debugSVG) { console.log("ELEMENT NOT FOUND " + svgId); }
        }
        var el2 = document.getElementById(svgControls);
        if (el2 != null) {
            this.svgControls = el2;
        } else {
            if (this.debugSVG)
            console.log("ELEMENT NOT FOUND " + svgControls)
        }
        this.xCord = this.xOffset * 2;
        
    }
    LoadControls() {
        //console.log("Controllering", this)
        let svgObjects: any = {};
        let upCircle = this.GetSvgElementCircle("UP", 60, 0, this.arbarcaPurple);
        upCircle.setAttribute("onclick", "svgg.upSVG();");
        svgObjects[0] = upCircle;
        let downCircle = this.GetSvgElementCircle("DOWN", 60, 50, this.arbarcaPurple);
        downCircle.setAttribute("onclick", "svgg.downSVG();");
        svgObjects[1] = downCircle;
        let leftCircle = this.GetSvgElementCircle("LEFT", 25, 25, this.arbarcaPurple);
        leftCircle.setAttribute("onclick", "svgg.leftSVG();");
        svgObjects[2] = leftCircle;
        let rightCircle = this.GetSvgElementCircle("RIGHT", 95, 25, this.arbarcaPurple);
        rightCircle.setAttribute("onclick", "svgg.rightSVG();");
        svgObjects[3] = rightCircle;
        for (var o in svgObjects) {
            this.svgControls.appendChild(svgObjects[o]);
        }
        this.svgControls.innerHTML += "";
    }
    LoadCanvas(qd: any) {
        this.svgGroup.innerHTML = "";
        var svgObjects: any = {};
        var y = 5;
        var uy = 0;
        var startCircle = this.GetStartEndSvgElement("Start", 0, y);
        this.positions["Start"] = { x: 0, y: y - 5 };
        svgObjects[0] = startCircle;
        var hasEnd = qd.reverseMap.length == qd.linkMap.length;
        var unlinkedQuestionIndex = 2;
        var levelOffset = {};
        console.log("qd sequence list", qd.sequence);
        qd.sequence.forEach((id: any) => {
            let yt: number;
            let xt: number;
            if (qd.reverseMap[id] !== undefined) {
                yt = qd.linkToLevel[id] * this.yOffset;
                var mp = Object.keys(qd.linkToLevel).filter(k => qd.linkToLevel[k] === qd.linkToLevel[id]);
                //levelOffset[mp.id]
                xt = mp.indexOf(id) * this.xOffset + (hasEnd ? this.xOffset : 0);
                qd.reverseMap[id].forEach((testQ: any) => {
                    if (testQ != "Start") {
                        var k = qd.sequence.indexOf(testQ);
                        //console.log( "from  "+ testQ + " to " + id + " Start Index " + k);
                        while (qd.sequence[k] != id && k < 10000) {
                            //console.log(" >> " + qd.sequence[k]);
                            //console.log(k, qd.sequence[k], this.positions[qd.sequence[k]])
                            try {
                                if (this.positions[qd.sequence[k]].x == xt && qd.sequence[k] != testQ) {
                                    xt += this.xOffset;
                                }
                            } catch {
                                if (this.debugSVG)
                                    console.log(testQ + " " + qd.sequence[k] + "Missing Position");
                            }
                            //}
                            k++;
                        }
                    }
                });
            } else {
                xt = -1 * this.xOffset;
                yt = this.yOffset * unlinkedQuestionIndex++;
                qd.questionDetails[id].isValid = false;
            }

            this.positions[id] = { x: xt, y: yt }
            if (!hasEnd && (qd.linkMap[id].indexOf("End") > -1) || qd.linkMap[id].indexOf("Final") > -1 ) {
                hasEnd = true;
            }
        });
        qd.sequence.forEach((id: any) => {
            if (this.debugSVG)
                console.log(id, qd.questionDetails[id], this.positions[id].x, this.positions[id].y)
            svgObjects[id] = this.GetSvgElement(id, qd.questionDetails[id], this.positions[id].x, this.positions[id].y);
        });


        this.positions["End"] = { x: 0, y: (qd.linkToLevel["End"] * this.yOffset) };
        var endCircle = this.GetStartEndSvgElement("End", 0, this.positions["End"].y);
            //this.GetSvgElementCircle("End", 0, this.positions["End"].y, "#000");
        //endCircle.setAttribute("onclick", "qsc.openAddQuestionModal()");
        svgObjects[999999] = endCircle;
        for (var o in svgObjects) {
            this.svgGroup.appendChild(svgObjects[o]);
        }
        this.LinkRecords(qd.linkMap, qd.linkDetails, []);
        this.RedrawCanvas();
    }
    JumpTo(id: any) {
        //console.log(this.xCord, this.yCord);
        this.xCord = 0 - this.positions[id].x + this.xOffset * 2;
        this.yCord = 0 - this.positions[id].y + this.yOffset * 2;
        this.RedrawCanvas();
    }

    LinkRecords(links: any, linkDetail: any, linkToColor: any) {
        var index = 0;
        for (var startPoint in links) {
            links[startPoint].forEach((endPoint: any) => {
                if (endPoint == "" || endPoint == null || endPoint === undefined) {
                    return;
                }
                var key = startPoint + "-" + endPoint;
                var color = "black";
                var reasons = "";
                linkDetail.filter(((r: any) => r.link == key)).forEach((e: any) => {
                    //console.log(e);
                    reasons += e.reason + "\r\n";
                    if (e.action == "Deny") {
                        color = "red";
                    }
                    if (e.action == "Route" && color == "black") {
                        color = "orange";
                    }
                    if (e.action == "Approve" && color == "black") {
                        color = "green";
                    }
                });
                
                var translatedEndPoint = endPoint;
                if (translatedEndPoint == "Final") {
                    translatedEndPoint = "End";
                }
                //console.log("SP", startPoint, "EP", endPoint, "TE", translatedEndPoint);
                if (this.positions[startPoint].x == this.positions[translatedEndPoint].x) {
                    var newLine = document.createElementNS('', 'line');
                    newLine.setAttribute('id', startPoint + "to" + translatedEndPoint);
                    newLine.setAttribute('display-label', startPoint + " to " + translatedEndPoint);
                    var x1 = this.positions[startPoint].x;
                    var y1 = this.positions[startPoint].y + this.yHeight;
                    var x2 = this.positions[translatedEndPoint].x;
                    var y2 = this.positions[startPoint].y + this.yOffset;
                    newLine.setAttribute('x1', x1);
                    newLine.setAttribute('y1', y1);
                    newLine.setAttribute('x2', x2);
                    newLine.setAttribute('y2', y2);
                    newLine.setAttribute("stroke-width", this.strokeWidth.toString());
                    newLine.setAttribute("stroke", color);//colors[++index %colors.length]);
                    var title = document.createElementNS('', 'title');
                    title.textContent = reasons;
                    newLine.appendChild(title)
                    this.svgGroup.appendChild(newLine);
                } else {
                    var newLine = document.createElementNS('', 'path');
                    newLine.setAttribute('id', startPoint + "to" + translatedEndPoint);
                    newLine.setAttribute('display-label', startPoint + " to " + translatedEndPoint);
                    var x1 = this.positions[startPoint].x;
                    var y1 = this.positions[startPoint].y + this.yHeight;
                    var x2 = this.positions[translatedEndPoint].x;
                    var y2 = this.positions[startPoint].y + this.yOffset;
                    var x3 = ((x2 - x1) / 2);
                    var y3 = this.positions[startPoint].y + 75;
                    //console.log(x1,y1,":", x3,y3,":",x2,y2)
                    var attr = "M " + x1 + " " + y1 //start
                        + " q " + (x1 > x2 ? -(this.yOffset / 4) : (this.yOffset / 4)) + " " + (this.yOffset / 4)
                        + ", " + x3 + " " + (this.yOffset / 4)  // MID
                        + " T " + (x2) + " " + (y2); // END
                    //console.log(attr);
                    newLine.setAttribute('fill', "none");
                    newLine.setAttributeNS('', 'd', attr);
                    newLine.setAttribute("stroke-width", this.strokeWidth.toString());
                    newLine.setAttribute("stroke", color);//s[++index %colors.length]);
                    var title = document.createElementNS('', 'title');
                    title.textContent = reasons;
                    newLine.appendChild(title)
                    this.svgGroup.appendChild(newLine)
                }
                if (this.positions[startPoint].y + this.yOffset < this.positions[translatedEndPoint].y) {
                    var dropline = document.createElementNS('', 'line');
                    dropline.setAttribute('id', startPoint + "to" + translatedEndPoint + "Drop");
                    dropline.setAttribute('display-label', startPoint + " to " + translatedEndPoint);
                    dropline.setAttribute('x1', this.positions[translatedEndPoint].x);
                    dropline.setAttribute('y1', this.positions[startPoint].y + this.yOffset);
                    dropline.setAttribute('x2', this.positions[translatedEndPoint].x);
                    dropline.setAttribute('y2', this.positions[translatedEndPoint].y);
                    dropline.setAttribute("stroke-width", this.strokeWidth.toString());
                    dropline.setAttribute("stroke", color);//s[index  %colors.length]);
                    var title = document.createElementNS('', 'title');
                    title.textContent = reasons;
                    dropline.appendChild(title)
                    this.svgGroup.appendChild(dropline)
                }


            });
        };
    }

    RedrawCanvas() {
        //console.log("redrawing")
        this.svgGroup.setAttribute("transform", "translate(" + this.xCord + "," + this.yCord + ")  scale(" + this.scale + ")");// scale(0.5)");
        
        this.svgGroup.innerHTML += "";
        
        return false;
    }
    GetSvgElementCircle(label: any, x: any, y: any, fill: any) {
        var g = document.createElementNS("", "g");
        g.setAttribute("transform", "translate(" + x + "," + y + ")");
        var c = document.createElementNS("", "circle");
        c.setAttribute("cx", (0).toString());
        c.setAttribute("cy", (25).toString());
        c.setAttribute("r", (20).toString());
        c.setAttribute("fill", fill);
        var ic = document.createElementNS("", "path");
        ic.setAttribute("fill", "#FFF");
        ic.setAttribute("viewBox", "0 0 16 16"); 
        ic.setAttribute("transform", "translate(" + -16 + "," + 8 + ")  scale(" + 2 + ")");
        //ic.setAttributeNS(null, "d", "M170.666667 640l341.333333-341.333333 341.333333 341.333333-85.333333 85.333333-256-256-256 256z");
        if (label == "UP") {
            ic.setAttributeNS(null, "d", "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z");
        }
        if (label == "DOWN") {
            ic.setAttributeNS(null, "d", "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z");
        }
        if (label == "LEFT") {
            ic.setAttributeNS(null, "d", "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z");
        }
        if (label == "RIGHT") {
            ic.setAttributeNS(null, "d", "M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z");
        }
        //var txt = document.createElementNS("", "text");
        //txt.setAttribute("fill", "#FFF");
        //txt.setAttribute("x", "0");
        //txt.setAttribute("y", "27");
        //txt.setAttribute("alignment-baseline", "middle");
        //txt.setAttribute("text-anchor", "middle");
        //txt.textContent = label;
        g.appendChild(c);
        g.appendChild(ic);
        return g;
    }
    GetStartEndSvgElement(label: any, x: any, y: any) {
        var g = document.createElementNS("", "g");
        g.setAttribute("transform", "translate(" + x + "," + y + ")");
        //g.setAttribute("onclick", "editQuestionModal('" + id + "')");
        var nr = document.createElementNS("", "rect");
        nr.setAttribute("x", "-100");
        nr.setAttribute("y", "0");
        nr.setAttribute("rx", "5");
        nr.setAttribute("ry", "5");
        nr.setAttribute("width", "200");
        nr.setAttribute("height", this.yHeight.toString());
        nr.setAttribute("stroke", "#FFF");
        nr.setAttribute("stroke-width", "3");
        nr.setAttribute("fill", this.arbarcaPurple);
        var txt = document.createElementNS("", "text");
        txt.setAttribute("stroke", "#FFF");
        txt.setAttribute("x", "0");
        txt.setAttribute("y", "25");
        txt.setAttribute("alignment-baseline", "middle");
        txt.setAttribute("text-anchor", "middle");
        txt.textContent = label;// id + ":" + detail.label;
        g.appendChild(nr);
        g.appendChild(txt);
        g.setAttribute("onclick", "qsc.openAddQuestionModal()");
        //g.setAttribute("onclick", "qsc.openQuestionModal(" + id + ")");
        return g;
    };


    GetSvgElement(id: any, detail: any, x: any, y: any) {
        var g = document.createElementNS("", "g");
        g.setAttribute("transform", "translate(" + x + "," + y + ")");
        //g.setAttribute("onclick", "editQuestionModal('" + id + "')");
        var nr = document.createElementNS("", "rect");
        nr.setAttribute("x", "-100");
        nr.setAttribute("y", "0");
        nr.setAttribute("rx", "5");
        nr.setAttribute("ry", "5");
        nr.setAttribute("width", "200");
        nr.setAttribute("height", this.yHeight.toString());
        nr.setAttribute("stroke", "#000");
        nr.setAttribute("stroke-width", "3");
        if (detail.isValid) {
            nr.setAttribute("fill", "#FFF");
        } else {
            nr.setAttribute("fill", "#F99");
        }
        var txt = document.createElementNS("", "text");
        txt.setAttribute("x", "0");
        txt.setAttribute("y", "25");
        txt.setAttribute("alignment-baseline", "middle");
        txt.setAttribute("text-anchor", "middle");
        txt.textContent = id + " " + detail.label;//":" + detail.label;
        g.appendChild(nr);
        g.appendChild(txt);
        g.setAttribute("onclick", "qsc.openQuestionModal("+id+")");
        return g;
    };
    Move(mx: number, my: number) {
        this.xCord = this.xCord + mx;
        this.yCord = this.yCord + my;
        this.RedrawCanvas();
        return false;
    }
    upSVG() {
        this.xCord = this.xCord;
        this.yCord = this.yCord - this.moveLength;
        this.RedrawCanvas();
        return false;
    }
    downSVG() {
        this.xCord = this.xCord;
        this.yCord = this.yCord + this.moveLength;
        this.RedrawCanvas();
        return false;
    }

    leftSVG() {
        this.xCord = this.xCord - this.moveLength;
        this.yCord = this.yCord;
        this.RedrawCanvas();
        return false;
    }
    rightSVG() {
        this.xCord = this.xCord + this.moveLength;
        this.yCord = this.yCord;
        this.RedrawCanvas();
        return false;
    }
}
