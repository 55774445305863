import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Client } from '../../classes/client';
import { QuestionSet } from '../../classes/questionset';
import { QuestionSetTable } from '../../classes/questionsetdashboard';
import { User } from '../../classes/user';
import { QuestionSetDashboardService } from '../../question/question-set/question-set-dashboard.service';
import { AuthService } from '../../shared/auth/auth.service';
import { DecisionTreeService } from '../decision-tree.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-decisiontree-modal',
  templateUrl: './add-decisiontree-modal.component.html',
  styleUrls: ['./add-decisiontree-modal.component.scss']
})
export class AddDecisiontreeModalComponent {
    currentFilteredClient!: Client;

    user!: User;
    questionset: QuestionSetTable[] | undefined;

    addDtForm = this.fb.group({
        name: ['', Validators.required],
        description: [''],
        comments: [''],
        start: [new Date(), Validators.required],
        end: [new Date(), Validators.required],
        questions: this.fb.array([])
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<AddDecisiontreeModalComponent>,
        private authService: AuthService,
        private questionsetDashboardService: QuestionSetDashboardService,
        private decisionTreeService: DecisionTreeService,
        private fb: FormBuilder
    ) {
        this.user = this.authService.getUserObject();
        if (data !== undefined) {
            this.currentFilteredClient = data.currentFilteredClient;
        }
    }

    ngOnInit() {
        this.populateQuestionSetList();
        this.addQuestionSet();
    }

    populateQuestionSetList() {
        this.questionsetDashboardService.getAllQuestionSetDashboardFromService(this.currentFilteredClient.clientID!).subscribe(resp => {
            if (resp.body) {
                this.questionset = this.questionsetDashboardService.mapQuestionSetDashboardResponse(resp);
                console.log(this.questionset);
            }
        });
    }

    save() {
        //check that there is at least one question in the question set and that the question set has an ID

        if (this.addDtForm.value.questions! && this.addDtForm.value.questions!.length >= 1) {
            const questions = this.addDtForm.get('questions') as FormArray;
            const hasValidQuestionSetID = questions.controls.every(q => {
                q = this.toGroup(q);
                const questionSetID = q.get('questionSetID')!.value;
                return questionSetID !== '' && questionSetID !== null;
            });
            if (hasValidQuestionSetID && this.addDtForm.valid) {
                const parsedFromDate = this.addDtForm.value.start?.toISOString();
                const parsedToDate = this.addDtForm.value.end?.toISOString();
                console.log(this.addDtForm.value.questions);
                this.decisionTreeService.addDecisionTree(
                    this.user.id!, this.currentFilteredClient.clientID!, this.addDtForm.value.name!, this.addDtForm.value.description!,
                    this.addDtForm.value.comments!, parsedFromDate!, parsedToDate!, this.addDtForm.value.questions!).subscribe(
                        (data) => {
                            if (data.status == 200) {
                                //successfully saved
                                this.questionset = [];
                                this.dialogRef.close("1");
                            }
                        },  //changed
                        (err) => {
                            console.log(err)
                        },
                        () => console.log("Done")
                    );
            } else {
                alert('error');
            }
        } else {
            alert('error');
        }
        
        
        
        //this.decisionTreeService.addDecisionTree()
    }

    checkFormValidity() {
        Object.keys(this.addDtForm.controls).forEach(key => {
            const control = this.addDtForm.get(key);
            console.log(`${key}: ${control!.errors}`);
        });
    }

    get questions() {
        return this.addDtForm.controls["questions"] as FormArray;
    }

    addQuestionSet() {
        const questionSetForm = this.fb.group({
            questionSetID: ['']
        });
        this.questions.push(questionSetForm);
    }

    deleteQuestionSet(index: number) {
        this.questions.removeAt(index);
    }

    toGroup(absCtrl: AbstractControl): FormGroup {
        const ctrl = absCtrl as FormGroup;
        return ctrl;
    }

    close() {
        this.questionset = [];
        this.dialogRef.close("0");
    }
}
