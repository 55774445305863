import { query } from '@angular/animations';
import cli from '@angular/cli';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DecisionTreeRXDM } from '../classes/decisiontree';
import { HierarchySettings } from '../classes/decisiontreesettings';
import { Hierarchy, HierarchyTies } from '../classes/hierarchy';
import { DrugType, SolrDrug } from '../classes/solr-drug';
import { AuthService } from '../shared/auth/auth.service';
import { ApiPaths } from '../shared/environments/routes';
import { environment } from '../shared/environments/environments';

@Injectable({
  providedIn: 'root'
})
export class HierarchyService {

    constructor(private http: HttpClient, private authService: AuthService) { }

    private getHeaders(ct?: string | undefined) {
        var httpHeaders;
        var token = this.authService.getToken();
        httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
            'Authorization': `Bearer ${token}`,
        });
        return httpHeaders;
    }

    public getAllHierarchyToDecisionTreeFromService(hierarchyID: number): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('HierarchyID', hierarchyID);
        

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetHierarchyToDecisionTreeTableViewList, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public getAllHierarchyFromService(clientID: string): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetHierarchyTableViewList, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public addHierarchy( ClientID: string, PCN: string, PCNDescription: string, benefitPlanId: string,
        PlanDescription: string, benefitGroupId: string, GroupName: string, ApplicationUserID: number, StartDate: string,
                        EndDate: string): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        
        queryParams = queryParams.append('ClientID', ClientID);
        queryParams = queryParams.append('PCN', PCN);
        queryParams = queryParams.append('PCNDescription', PCNDescription);
        queryParams = queryParams.append('benefitPlanId', benefitPlanId);
        queryParams = queryParams.append('PlanDescription', PlanDescription);
        queryParams = queryParams.append('benefitGroupId', benefitGroupId);
        queryParams = queryParams.append('GroupName', GroupName);
        queryParams = queryParams.append('ApplicationUserID', ApplicationUserID);
        queryParams = queryParams.append('StartDate', StartDate);
        queryParams = queryParams.append('EndDate', EndDate);
        

        return this.http.post(environment.baseUrl + ApiPaths.AddHierarchy, null, { headers: httpHeaders, params: queryParams, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public addHierarchyToDecisionTree(HierarchyID: number, DecisionTreeHeaderID: number, DrugIDQualifier: string, DrugID: string | undefined | null, CaseType: string | undefined | null,
        CaseSubType: string | undefined | null, Tier: string | undefined | null, ApplicationUserID: number, StartDate: string, EndDate: string) {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('HierarchyID', HierarchyID);
        queryParams = queryParams.append('DecisionTreeHeaderID', DecisionTreeHeaderID);
        queryParams = queryParams.append('DrugIDQualifier', DrugIDQualifier);
        queryParams = queryParams.append('DrugID', DrugID ?? "");
        queryParams = queryParams.append('CaseType', CaseType ?? "");
        queryParams = queryParams.append('CaseSubType', CaseSubType ?? "");
        queryParams = queryParams.append('Tier', Tier ?? "");
        queryParams = queryParams.append('ApplicationUserID', ApplicationUserID);
        queryParams = queryParams.append('StartDate', StartDate);
        queryParams = queryParams.append('EndDate', EndDate);

        return this.http.post(environment.baseUrl + ApiPaths.AddHierarchyToDecisionTree, null, { headers: httpHeaders, params: queryParams, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public terminateHierarchy(HierarchyID: number, DecisionTreeHeaderID: number,  ApplicationUserID: number, EndDate: string) {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('HierarchyID', HierarchyID);
        queryParams = queryParams.append('DecisionTreeHeaderID', DecisionTreeHeaderID);
        queryParams = queryParams.append('ApplicationUserID', ApplicationUserID);
        queryParams = queryParams.append('EndDate', EndDate);

        return this.http.post(environment.baseUrl + ApiPaths.TerminateHierarchyToDecisionTree, null, { headers: httpHeaders, params: queryParams, observe: 'response' })
            .pipe(map((response: any) => response));
    }

    public getRXDMDecisionTree(clientID: string, pcn: string, benefitPlanID: string, benefitGroupID: string, type: string, subtype: string, drugIDType: string, drugID: string, requestDate?: string) {
        var httpHeaders = new HttpHeaders({'Content-Type': "application/json"});

        var queryParams = new HttpParams();
        
        queryParams = queryParams.append('ClientID', clientID);
        queryParams = queryParams.append('PCN', pcn);
        queryParams = queryParams.append('BenefitPlanID', benefitPlanID);
        queryParams = queryParams.append('BenefitGroupID', benefitGroupID);
        queryParams = queryParams.append('Type', type);
        queryParams = queryParams.append('Subtype', subtype);
        queryParams = queryParams.append('Drugidtype', drugIDType);
        queryParams = queryParams.append('Drugid', drugID);
        queryParams = queryParams.append('RequestDate', requestDate ?? "");

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.GetDecisionTreeRXDM, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapRXDMDecisionTree(response: HttpResponse<any>) {
        const trees: DecisionTreeRXDM[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const d = new DecisionTreeRXDM();
            if (v instanceof Object) {
                d.hierarchyID = parseInt(v['hierarchyID' as keyof typeof v].toString());
                d.clientID = v['clientID' as keyof typeof v].toString();
                d.pcnReferenceID = parseInt(v['pcnReferenceID' as keyof typeof v].toString());
                d.planReferenceID = parseInt(v['planReferenceID' as keyof typeof v].toString());
                d.groupReferenceID = parseInt(v['groupReferenceID' as keyof typeof v].toString());
                d.hierarchyDescription = v['hierarchyDescription' as keyof typeof v].toString();
                d.startDate = v['startDate' as keyof typeof v].toString();
                d.endDate  = v['startDate' as keyof typeof v].toString();
                d.clientName = v['clientName' as keyof typeof v].toString();
                d.pcn = v['pcn' as keyof typeof v].toString();
                d.pcnDescription = v['pcnDescription' as keyof typeof v].toString();
                d.benefitPlanID = v['benefitPlanID' as keyof typeof v].toString();
                
                d.planDescription = v['planDescription' as keyof typeof v].toString();
                d.benefitGroupID = v['benefitGroupID' as keyof typeof v].toString();
                
                d.groupName = v['groupName' as keyof typeof v].toString();
                d.hierarchyToDecisionTreeID = parseInt(v['hierarchyToDecisionTreeID' as keyof typeof v].toString());
                d.decisionTreeHeaderID = parseInt(v['decisionTreeHeaderID' as keyof typeof v].toString());
                d.drugIDQualifier = v['drugIDQualifier' as keyof typeof v].toString();
                d.drugID = v['drugID' as keyof typeof v].toString() ?? "";
                d.caseType = v['caseType' as keyof typeof v].toString();
                d.caseSubType = v['caseSubType' as keyof typeof v].toString();
                d.tier = v['tier' as keyof typeof v].toString();
                d.priority = parseInt(v['priority' as keyof typeof v].toString());
                d.decisionTreeDescription = v['decisionTreeDescription' as keyof typeof v].toString();
                d.decisionTreeName = v['decisionTreeName' as keyof typeof v].toString();
                d.decisionTreeID = v['decisionTreeID' as keyof typeof v].toString();
                trees.push(d);
            }
        }
        return trees;
    }

    public searchSolrDrugs(searchValue: string) {
        var httpHeaders = this.getHeaders();
        searchValue = searchValue.trim();
        let searchString: string = 'productdescabbrev:*' + searchValue + "*";
        searchString += "\ngpi:*" + searchValue + "*";
        if (/^\d+$/.test(searchValue)) {
            searchString += "\nndc:*" + searchValue + "*";
        }
        var queryParams = new HttpParams();
        queryParams = queryParams.append('EncodedQuery', searchString );

        return this.http.get<any>(
            environment.baseUrl + ApiPaths.getSolrQuery, { headers: httpHeaders, params: queryParams, observe: 'response' });
    }

    public mapSolrDrugsResponse(response: HttpResponse<any>) {
        const drugs: SolrDrug[] = [];
        const gpiMap: { [id: string]: SolrDrug } = {};
        const ndcMap: { [id: string]: SolrDrug } = {};

        for (var [k, v] of Object.entries(response.body)) {
            const d = new SolrDrug();

            if (v instanceof Object) {
                d.id = parseInt(v['id' as keyof typeof v].toString());
                d.ddid = parseInt(v['ddid' as keyof typeof v].toString());
                d.gpi = v['gpi' as keyof typeof v].toString();
                d.desc = v['descDisplay' as keyof typeof v].toString();
                d.ndc = v['ndc' as keyof typeof v].toString();
                d.genericCode = v['genericCode' as keyof typeof v].toString();

                if (d.id && !gpiMap[d.gpi.toString()]) {
                    console.log("Adding GPI " + d.gpi);
                    const gpiDrug = new SolrDrug();
                    gpiDrug.id = d.id;
                    gpiDrug.gpi = d.gpi;
                    gpiDrug.desc = d.desc;
                    gpiDrug.ddid = d.ddid;
                    gpiDrug.type = DrugType.GPI;
                    gpiMap[d.gpi.toString()] = gpiDrug;
                    drugs.push(gpiDrug);
                }

                if (d.ndc && !ndcMap[d.ndc.toString()]) {
                    const ndcDrug = new SolrDrug();
                    ndcDrug.id = d.id;
                    ndcDrug.ndc = d.ndc;
                    ndcDrug.desc = d.desc;
                    ndcDrug.ddid = d.ddid;
                    ndcDrug.type = DrugType.NDC;
                    ndcMap[d.ndc.toString()] = ndcDrug;
                    drugs.push(ndcDrug);
                }
            }
        }
        return drugs;
    }

    public mapHierarchyTiesResponse(response: HttpResponse<any>) {
        const hierarchyTies: HierarchyTies[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const h = new HierarchyTies();
            if (v instanceof Object) {
                h.hierarchyId = parseInt(v['hierarchyId' as keyof typeof v].toString());
                h.clientName = v['clientName' as keyof typeof v].toString();
                h.pcn = v['pcn' as keyof typeof v].toString();
                h.pcnDescription = v['pcnDescription' as keyof typeof v].toString();
                h.planDescription = v['planDescription' as keyof typeof v].toString();
                h.groupName = v['groupName' as keyof typeof v].toString();
                h.decisionTreeHeaderID = parseInt(v['decisionTreeHeaderID' as keyof typeof v].toString());
                h.decisionTreeName = v['decisionTreeName' as keyof typeof v].toString();
                h.drugIDQualifier = v['drugIDQualifier' as keyof typeof v].toString();
                var drugID = v['drugID' as keyof typeof v] ?? "";
                h.drugID = drugID.toString();
                h.caseType = v['caseType' as keyof typeof v]?.toString();
                h.caseSubType = v['caseSubType' as keyof typeof v]?.toString();
                h.tier = v['tier' as keyof typeof v]?.toString();
                h.startDate = v['startDate' as keyof typeof v].toString();
                h.endDate = v['endDate' as keyof typeof v].toString();
                hierarchyTies.push(h);
            }
        }
        return hierarchyTies;
    }

    public mapHierarchyResponse(response: HttpResponse<any>) {
        const hierarchies:  Hierarchy[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const h = new Hierarchy();
            if (v instanceof Object) {
                //console.log("v", v)
                h.hierarchyId = parseInt(v['hierarchyID' as keyof typeof v].toString());
                h.clientCompany = v['clientName' as keyof typeof v].toString();
                h.desc = v['hierarchyDescription' as keyof typeof v].toString();
                h.startDate = v['startDate' as keyof typeof v].toString();
                h.endDate = v['endDate' as keyof typeof v].toString();
                
                var benefitGroupID = v['benefitGroupID' as keyof typeof v] ?? "";
                h.benefitGroupId = benefitGroupID.toString();
                h.group = v['groupName' as keyof typeof v].toString() != "All"
                    ? benefitGroupID.toString() + " " + v['groupName' as keyof typeof v].toString()
                    : "All";
                h.lobPcn = v['pcnDescription' as keyof typeof v].toString() != "All"
                    ? v['pcn' as keyof typeof v]?.toString() + " " + v['pcnDescription' as keyof typeof v].toString()
                    : "All";
                var benefitPlanID = v['benefitPlanID' as keyof typeof v] ?? "";
                h.benefitPlanId = benefitPlanID.toString();
                h.plan = v['planDescription' as keyof typeof v].toString() != "All"
                    ? benefitPlanID.toString() + " " + v['planDescription' as keyof typeof v].toString()
                    : "All";
                h.ties = [];
                hierarchies.push(h);
            }
        }

        return hierarchies;
    }

    public mapHierarchySettingsResponse(response: HttpResponse<any>) {
        const hierarchies: HierarchySettings[] = [];
        for (var [k, v] of Object.entries(response.body)) {
            const h = new HierarchySettings();
            if (v instanceof Object) {
                //console.log("v", v)
                h.hierarchyId = parseInt(v['hierarchyID' as keyof typeof v].toString());

                var benefitGroupID = v['benefitGroupID' as keyof typeof v] ?? "";;
                h.benefitGroupId = benefitGroupID.toString();
                h.group = v['groupName' as keyof typeof v].toString();
                h.pcn = v['pcn' as keyof typeof v]?.toString();
                h.pcnDescription = v['pcnDescription' as keyof typeof v].toString();
                h.pcnReferenceID = parseInt(v['pcnReferenceID' as keyof typeof v].toString());
                var benefitPlanID = v['benefitPlanID' as keyof typeof v] ?? "";;
                h.benefitPlanId = benefitPlanID.toString();
                h.plan = v['planDescription' as keyof typeof v].toString();
                h.planReferenceID = parseInt(v['planReferenceID' as keyof typeof v].toString());
                h.groupReferenceID = parseInt(v['groupReferenceID' as keyof typeof v].toString());
                //h.settingsStartDate = new Date(v['startDate' as keyof typeof v].toString());
                //h.settingsEndDate = new Date(v['endDate' as keyof typeof v].toString());
                hierarchies.push(h);
            }
        }

        return hierarchies;
    }

    public exportTable(clientID: string) {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('ClientID', clientID);
        return this.http.get(
            environment.baseUrl + ApiPaths.ExportHierarchyTableViewList,
            {
                headers: this.getHeaders(),
                params: queryParams,
                observe: 'events',
                responseType: 'blob'
            }
        );
    }

    public exportTiesTable(hierarchyID: number) {
        var queryParams = new HttpParams();
        queryParams = queryParams.append('hierarchyID', hierarchyID);
        return this.http.get(
            environment.baseUrl + ApiPaths.ExportHierarchyTiesTableViewList,
            {
                headers: this.getHeaders(),
                params: queryParams,
                observe: 'events',
                responseType: 'blob'
            }
        );
    }

    public exportTiesTableByClient(clientID: string) {
        var httpHeaders = this.getHeaders();

        var queryParams = new HttpParams();
        queryParams = queryParams.append('clientID', clientID);
        return this.http.get(
            environment.baseUrl + ApiPaths.ExportHierarchyTiesTableViewByClientList,
            {
                headers: httpHeaders,
                params: queryParams,
                observe: 'events',
                responseType: 'blob'
            }
        );
    }

    public getDrugListFromSolr(): Observable<HttpResponse<any>> {
        var httpHeaders = this.getHeaders();

        return this.http.get<any>(
            environment.solrUrl + ApiPaths.GetSolrDrugs, { headers: httpHeaders, observe: 'response' });
    }

    public fetchHierarchy(): Hierarchy[] | undefined {
        const hierarchies: Hierarchy[] = [];
        var h = new Hierarchy();
        h.ties = [];
        h.clientCompany = "Abarca";
        h.lobPcn = "Lob r";
        h.plan = "Plan 1";
        h.group = "257";
        h.activeLinks = "25";
        h.futureLinks = "5";


        hierarchies.push(h);

        return hierarchies;
    }

    private MapList(headers: string[], list: Array<Array<string>>) {
    var result: {}[] = [];
    var hl = headers.length;
    list.forEach(e => {
        var row = Array<any>(); 
        var offset = e.length - hl;
        for (var i = 0; i < hl; i++) {
        var header = headers[i];
            row[header as keyof typeof row] = e[i + offset];
        }
        result.push(row);
    });
    return result;
    }
}
