import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { CommonModule, NgIf, NgFor } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportDTModalComponent } from './import-dt-modal/import-dt-modal.component';
import { CopyModalComponent } from '../shared/copy-modal/copy-modal.component';
import { AddDecisiontreeModalComponent } from './add-decisiontree-modal/add-decisiontree-modal.component';
import { ExportModalComponent } from '../shared/export-modal/export-modal.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule, TooltipPosition } from '@angular/material/tooltip';

import { User } from '../classes/user';
import { Controllers, ReturnObjectType, Status } from '../shared/environments/environments';
import { Client } from '../classes/client';
import { DecisionTree } from '../classes/decisiontree';

import { AuthService } from '../shared/auth/auth.service';
import { DateService } from '../shared/services/date.service';
import { DecisionTreeService } from './decision-tree.service';


import { Router } from '@angular/router';
import { ClientService } from '../shared/services/client.service';
import { PCN } from '../classes/pcn';
import { Case, CaseSubType, DeterminationLevel } from '../classes/case';
import { CaseService } from '../shared/services/case.service';
import { StatusClassPipe } from '../shared/pipes/status-class.pipe';
import { StatusPipe } from '../shared/pipes/status.pipe';

import { MatIconModule } from '@angular/material/icon';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { GuidedCreateDecisiontreeModalComponent } from './guided-create-decisiontree-modal/guided-create-decisiontree-modal.component';
import { DecisionTreeSettings } from '../classes/decisiontreesettings';
import { SortByMajorVersionPipe } from '../shared/pipes/sort-by-major-version.pipe';

import { environment } from '../shared/environments/environments';




@Component({

    selector: 'app-decision-tree-dashboard',
    standalone: true,
    imports: [MatPaginatorModule, MatSelectModule, MatFormFieldModule, MatInputModule, MatTableModule
        , MatSortModule, MatCheckboxModule, MatButtonModule, MatDividerModule, CommonModule, NgIf, NgFor
        , FormsModule, ReactiveFormsModule, MatButtonToggleModule, MatRadioModule, MatMenuModule, MatIconModule, MatTooltipModule
        , StatusClassPipe, StatusPipe, SortByMajorVersionPipe],
    templateUrl: './decision-tree-dashboard.component.html',
    styleUrls: ['./decision-tree-dashboard.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: []
})
export class DecisionTreeDashboardComponent implements AfterViewInit, OnInit {
    title = 'Decision Tree';

    displayedColumns: string[] = ['name',  'start', 'end', 'action'];
    columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
    expandedRow?: DecisionTree | null;
    dataSource: MatTableDataSource<DecisionTree> = new MatTableDataSource<DecisionTree>([]);

    positionOptions: TooltipPosition[] = ['left'];
    position = new FormControl(this.positionOptions[0]);

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort!: MatSort;
    user!: User;

    decisionTree: DecisionTree[] | undefined = [];
    pcns: PCN[] = [];
    cases: Case[] = [];
    selectedCases: Case[] = [];
    caseSubTypes: CaseSubType[] = [];
    determinationLevels: DeterminationLevel[] = [];
    seletedDeterminationLevel: DeterminationLevel[] = [];
    clientSelection = new FormControl('');
    clientsList: Client[] = [];
    selectedClient!: Client;
    //selectedPCN!: PCN;
    searchInput: string = '';
    redCrumbs?: string;
    effectiveDateFilter = this.fb.group({
        past: false,
        current: true,
        future: true,
    });
    statusFilter = this.fb.group({
        pending: true,
        draft: true,
        approved: true,
        /*deleted: false,*/
    });
    showFilters: boolean = false;
    
    


    constructor(
        private cdr: ChangeDetectorRef,
        private authService: AuthService,
        private clientService: ClientService,
        private caseService: CaseService,
        private decisionTreeService: DecisionTreeService,
        private dateService: DateService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private router: Router
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.selectedClient = this.user.clients!.find(x => x.clientID?.toLowerCase() === this.user.lastSelectedClientID!.toLowerCase())!;
        this.populateClients();
        this.populateCase();
    }

    ngOnInit() {
        //debugger;
        console.log("-------------------------------");
        console.log(environment.baseUrl);
        console.log("-------------------------------");
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        //this.dataSource.sort.sortChange.subscribe(() => {
        //    // Call the renderRows() method to refresh the table after sorting
        //    this.dataSource._updateChangeSubscription();
        //});
    }

    toggleChange(event: any) {
        this.redCrumbs = "";
        let toggle = event.source;
        //console.log(toggle);
        if (toggle) {
            let group = toggle.buttonToggleGroup;
            if (event.value.some((item: any) => item == toggle.value)) {
                group.value = [toggle.value];
                this.redCrumbs = toggle.value;
            }
        }
    }

    getDecisionTree() {
        console.log("Getting decison tree", this.selectedClient.clientID);
        this.decisionTree = [];
        let pcnList: number[] = [];
        document.querySelectorAll('input[name=pcnListItem]:checked').forEach((t: any) => { pcnList.push(t.value) });
        //console.log("pcnList", pcnList);
        let caseList: string[] = [];
        document.querySelectorAll('input[name=caseListItem]:checked').forEach((t: any) => { caseList.push(t.value) });
        let dtList: number[] = [];
        document.querySelectorAll('input[name=determinationListItem]:checked').forEach((t: any) => { dtList.push(t.value) });
        //if (this.selectedPCN) {
        //    pcns.push(this.selectedPCN.pcn!);
        //}
        this.decisionTreeService.getAllDecisionTreeFromService(this.selectedClient.clientID!, pcnList!, caseList!, dtList!).subscribe(resp => {
            if (resp.body) {
                this.decisionTree = this.decisionTreeService.mapDecisionTreeResponseVersionMerge(resp);
                //console.log(this.decisionTree);
                //console.log(this.decisionTree);
                if (this.decisionTree.length >= 0) {
                    this.dataSource = new MatTableDataSource(this.decisionTree);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    //this.populateCase();
                    this.applyFilter(null);

                }
            }
        });
    }
    selectClient(c: Client) {
        this.selectedClient = c;
        this.onSelectClient()
    }
    onSelectClient() {
        this.redCrumbs = "";
        //set the users selected client
        this.authService.setClient(this.selectedClient.clientID!);
        document.querySelectorAll('input[name=pcnListItem]:checked').forEach((t: any) => { t.remove() });
        this.populatePCNs();
        this.populateCase();
        this.getDecisionTree();
    }
    onSelectLOB() {

    }

    populateCase() {
        this.caseService.getCases().subscribe(resp => {
            if (resp.body) {
                this.cases = this.caseService.mapCases(resp);
                console.log(this.cases);
            }
        });
    }

    populateCaseSubType() {
        this.caseService.getCaseSubTypes().subscribe(resp => {
            if (resp.body) {
                this.caseSubTypes = this.caseService.mapCaseSubTypes(resp);
            }
        });
    }

    populateClients() {
        this.clientsList = this.user.clients!;
        this.populatePCNs();
    }

    applyFilter(event: MatCheckboxChange | Event | null) {
        const filterValue = this.searchInput.toLowerCase();
        const pastEffective = this.effectiveDateFilter.get('past')?.value;
        const currentEffective = this.effectiveDateFilter.get('current')?.value;
        const futureEffective = this.effectiveDateFilter.get('future')?.value;

        const pendingStatus = this.statusFilter.get('pending')?.value;
        const draftStatus   = this.statusFilter.get('draft')?.value;
        const approvedStatus = this.statusFilter.get('approved')?.value;

        var fullDataset = new MatTableDataSource(this.decisionTree);

        const filteredData = fullDataset.data.filter(data => {
            const isPast = this.dateService.isPastEffective(data);
            const isCurrent = this.dateService.isCurrentlyEffective(data);
            const isFuture = this.dateService.isFutureEffective(data);

            const meetsEffectiveDateCriteria =
                (pastEffective && isPast)
                || (currentEffective && isCurrent)
                || (futureEffective && isFuture);

            const meetsStatusCriteria =
                ((pendingStatus &&      data.decisionTreeStatus === Status.Pending)) ||
                ( (draftStatus &&    data.decisionTreeStatus === Status.Draft)) ||
                ( (approvedStatus && data.decisionTreeStatus === Status.Approved));

            const includesSearch = filterValue === '' || (data.decisionTreeName?.toLowerCase().includes(filterValue) ?? false);

            return meetsEffectiveDateCriteria && meetsStatusCriteria && includesSearch;
        });
        // Create a new MatTableDataSource instance with the updated data
        const newDataSource = new MatTableDataSource(filteredData);

        // Assign the new data source to the existing table's data source
        this.dataSource = newDataSource;

        // If you want to trigger sorting manually
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortData(this.dataSource.data, this.sort);

        // Manually trigger the filter update
        this.dataSource._updateChangeSubscription();

        // Update paginator
        const paginator = this.dataSource.paginator;
        if (paginator) {
            paginator.length = filteredData.length;
            paginator.firstPage();
        }

        // Trigger change detection
        this.cdr.detectChanges();
    }


    onEffectiveDateCheckboxChange(event: MatCheckboxChange) {
        // Trigger filterPredicate when checkbox state changes
        this.applyFilter(event);
    }

    openEdit(dt: any) {
        this.router.navigate(['decisiontree/editquestions'], { queryParams: { dt: dt.decisionTreeID } });
    }

    export() {
        this.decisionTreeService.exportTable(this.selectedClient.clientID!).subscribe(
            (event) => {
                let data = event as HttpResponse<Blob>;
                console.log(data);
                const downloadedFile = new Blob([data.body as BlobPart], {
                    type: data.body?.type
                });
                if (downloadedFile.type != "") {
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = "DecisionTreeList.xlsx";
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            }
        );
    }

    openAddModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient
        }
        const dialogRef = this.dialog.open(AddDecisiontreeModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data == 1) {
                    //this.decisiontree = [];
                    //this.filteredDecisiontree = [];
                    this.getDecisionTree();
                }
            }
        );
    }

    openGuidedCreateModal(copyFrom?:number) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient, 
            tree: new DecisionTreeSettings,
            copyFrom: copyFrom,
            p: this
        }
        console.log("GuidedCreate open", dialogConfig.data)
        const dialogRef = this.dialog.open(GuidedCreateDecisiontreeModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data == 1) {
                    //this.decisiontree = [];
                    //this.filteredDecisiontree = [];
                    this.getDecisionTree();
                }
            }
        );
    }

    openExportModal(d: DecisionTree) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            inputObject: d,
            inputID: d.decisionTreeID,
            title: d.decisionTreeName,
            isQS: false,
            isDT: true
        }
        const dialogRef = this.dialog.open(ExportModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) { this.getDecisionTree(); } }
        );
    }

    openImportModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            currentFilteredClient: this.selectedClient
        }
        const dialogRef = this.dialog.open(ImportDTModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) { this.getDecisionTree(); } }
        );
    }

    populatePCNs() {
        this.clientService.getPCNByClient(this.selectedClient.clientID!).subscribe(resp => {
            if (resp.body) {
                this.pcns = this.clientService.mapPCNResponse(resp);
                this.pcns = this.pcns.filter((x) => x.pcnReferenceID !== -1);
                //console.log(">>>>PCN???",this.pcns);
                this.getDecisionTree();
            }
        });
    }

    openCopyModal(d: DecisionTree) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            controllerType: Controllers.DecisionTree,
            id: d.decisionTreeID,
            titleToCopy: d.decisionTreeName,
        }
        const dialogRef = this.dialog.open(CopyModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { if (data == 1) this.getDecisionTree(); }
        );
    }
    onFocus() {
        console.log("infocus")
        this.showFilters = true;
    }
    onBlur(e: any) {
        var y = e.srcElement.closest(".filter-section");
        if (y == null) {
            this.showFilters = false;
        }
    }
    onActionButtonClick(event: MouseEvent): void {
        event.stopPropagation();
    }
    sortChange(sortState: Sort) {
        this.dataSource.sort = this.sort; // Update the sort reference
        this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort);
        this.dataSource.sort.sortChange.subscribe(() => {
            // Call the renderRows() method to refresh the table after sorting
            this.dataSource._updateChangeSubscription();
        });
    }
}
