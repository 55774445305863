import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from '../../classes/client';
import { DecisionTreeSettings, HierarchySettings } from '../../classes/decisiontreesettings';
import { Group } from '../../classes/group';
import { PCN } from '../../classes/pcn';
import { Plan } from '../../classes/plan';
import { User } from '../../classes/user';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';

@Component({
    selector: 'client-configuration',
    templateUrl: './client-configuration.component.html',
    styleUrls: ['./client-configuration.component.scss']
})
export class ClientConfigurationComponent implements OnInit {
    @Output() hierarchyAdded: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(MatPaginator)
    paginator!: MatPaginator;
    @ViewChild(MatSort, { static: true })
    sort!: MatSort;
    displayedColumns: string[] = ['selected', 'pcn', 'plan', 'group', 'date'];
    dataSource: MatTableDataSource<HierarchySettings> = new MatTableDataSource<HierarchySettings>([]);
    user!: User;

    @Input() tree!: DecisionTreeSettings;
    @Input() clients!: Client[];
    @Input() p!: any;
    @Input() isSettings: boolean = false;
    pcns: PCN[] = [];
    selectedPCN?: PCN;
    plans: Plan[] = [];
    selectedPlan?: Plan;
    groups: Group[] = [];
    selectedGroup?: Group;
    existingHierarchy?: HierarchySettings[];
    selectedClient?: Client;
    currentDate = new Date();
    nextDay = new Date();
    futureDate = new Date("2999-12-31T00:00:00");

    constructor(
        private clientService: ClientService,
        private hierarchyService: HierarchyService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        //console.log('client config constructor');
        //Push the default values to the pcn, plan and group lists
        this.nextDay.setDate(this.currentDate.getDate() + 1);
        this.futureDate.setFullYear(this.currentDate.getFullYear() + 100);
        this.clearAndSetAllInputs()
    }

    ngOnInit() {
        if (!this.existingHierarchy) {
            this.getExistingHierarchies();
        }
        this.populatePCNs();
        this.selectedClient = this.clients.find(x => x.clientID == this.tree.client.clientID);
    }

    getExistingHierarchies() {
        this.hierarchyService.getAllHierarchyFromService(this.tree.client.clientID!).subscribe(resp => {
            if (resp.body) {
                this.existingHierarchy = this.hierarchyService.mapHierarchySettingsResponse(resp);
                if (this.existingHierarchy.length >= 0) {
                    //Add any missing hierarcies from the tree object, and set the isSelected to true for any that exist
                    for (let i = 0; i < this.tree.hierarchy.length; i++) {
                        const matchingRecord = this.checkExistingHierarchy(this.tree.hierarchy[i].pcnDescription!, this.tree.hierarchy[i].plan!, this.tree.hierarchy[i].group!, this.existingHierarchy!);
                        this.addOrUpdate(matchingRecord, this.tree.hierarchy[i]);
                    }
                    this.existingHierarchy.sort((a, b) => {
                        if (a.isSelected && !b.isSelected) {
                            return -1; // a comes before b
                        } else if (!a.isSelected && b.isSelected) {
                            return 1; // b comes before a
                        } else {
                            return 0; // order unchanged
                        }
                    });
                    this.dataSource = new MatTableDataSource(this.existingHierarchy);
                    //this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    //console.log(this.existingHierarchy);
                }
            }
        });
    }

    populatePCNs() {
        this.clientService.getPCNByClient(this.tree.client.clientID!).subscribe(resp => {
            if (resp.body) {
                this.clearAndSetAllInputs();
                const pcns = this.clientService.mapPCNResponse(resp, false);
                this.pcns = [...this.pcns, ...pcns];
                //console.log(this.pcns);
            }
        });
    }

    onSelectPCN(p: PCN) {
        this.selectedPCN = p;
        this.populatePlans();
    }

    populatePlans() {
        if (this.selectedPCN) {
            this.clearPlans();
            this.clearGroups();
            this.clientService.getPlanBenefitByPCN(this.selectedPCN.pcn!).subscribe(resp => {
                if (resp.body) {
                    const p = this.clientService.mapPlanResponse(resp, false);
                    this.plans = [...this.plans, ...p];
                    console.log(this.plans);
                }
            });
        }
    }

    onSelectPlan(p: Plan) {
        this.selectedPlan = p!;
        this.populateGroups();
    }

    populateGroups() {
        if (this.selectedPlan && this.selectedPCN) {
            this.clearGroups();
            console.log("plan: " + this.selectedPlan.benefitPlanId);
            console.log("pcn: " + this.selectedPCN.pcn);
            this.clientService.getGroupBenefitByPlanAndPCN(this.selectedPlan.benefitPlanId!, this.selectedPCN.pcn!).subscribe(resp => {
                if (resp.body) {
                    const g = this.clientService.mapGroupResponse(resp, false);
                    this.groups = [...this.groups, ...g];
                    console.log(this.groups);
                }
            });
        }
    }

    onSelectGroup(g: Group) {
        this.selectedGroup = g;
        console.log(this.selectedGroup);
    }

    updateClient() {
        this.tree.client = this.selectedClient!;

        this.getExistingHierarchies();
        this.populatePCNs();
    }

    addConfiguration() {
        //Check if it already exists in the list of returned hierarchy objects
        const matchingRecord = this.checkExistingHierarchy(this.selectedPCN?.pcnDescription!, this.selectedPlan?.planDescription!, this.selectedGroup?.groupName!, this.existingHierarchy!);

        this.addOrUpdate(matchingRecord, undefined);
        const selectedH = this.existingHierarchy!.filter(item => item.isSelected === true);
        if (selectedH.length > 0) {
            this.tree.hierarchy = selectedH;
        }
        this.dataSource = new MatTableDataSource(this.existingHierarchy);
        //this.dataSource.paginator = this.paginator;
        this.hierarchyAdded.emit();
    }

    addOrUpdate(r: HierarchySettings | undefined, dts: HierarchySettings | undefined) {
        if (r !== undefined) {
            // Update the matching record
            r.isSelected = true;
            if (dts !== undefined) {
                r.settingsEndDate = dts.settingsEndDate;
                r.settingsStartDate = dts.settingsStartDate;
            } else {
                //Request pending to use Date range from page 1 in this form on new adds
                r.settingsEndDate = this.futureDate;
                r.settingsStartDate = this.nextDay;
            }
            // ... perform other updates on the record
            //console.log('Matching record:', r);
            //console.log(this.existingHierarchy);

        } else {
            //console.log('No matching record found.');
            const newHierarchy = new HierarchySettings;
            newHierarchy.isSelected = true;
            newHierarchy.benefitGroupId = this.selectedGroup?.benefitGroupId?.toString();
            newHierarchy.group = this.selectedGroup?.groupName?.toString();
            newHierarchy.benefitPlanId = this.selectedPlan?.benefitPlanId?.toString();
            newHierarchy.pcnReferenceID = this.selectedPCN?.pcnReferenceID;
            newHierarchy.plan = this.selectedPlan?.planDescription;

            newHierarchy.planReferenceID = this.selectedPlan?.planReferenceID;

            newHierarchy.pcnDescription = this.selectedPCN?.pcnDescription;
            newHierarchy.pcn = this.selectedPCN?.pcn;
            newHierarchy.groupReferenceID = this.selectedGroup?.groupReferenceID;
            newHierarchy.pcnDescription = this.selectedPCN?.pcnDescription;
            newHierarchy.settingsStartDate = this.nextDay;
            newHierarchy.settingsEndDate = this.futureDate;
            this.existingHierarchy?.push(newHierarchy);
            //console.log(this.existingHierarchy);
        }
    }

    updateDate(row: any) {
        var h = this.tree.hierarchy.filter(
            item =>
                row.pcn == item.pcn
                && (row.benefitPlanId ?? "") == (item.benefitPlanId ?? "")
                && (row.benefitGroupId ?? "") == (item.benefitGroupId ?? "")
        )[0];
        h.settingsStartDate = row.settingsStartDate;
        h.settingsEndDate = row.settingsEndDate;
    }

    setHierarchy(row: any) {
        console.log(row);
        console.log("Pre check ", this.tree.hierarchy);
        if (row.isSelected == false) {
            
            this.tree.hierarchy = this.tree.hierarchy.filter(item =>
                !(row.pcn == item.pcn
                    && (row.benefitPlanId ?? "") == (item.benefitPlanId ?? "")
                    && (row.benefitGroupId ?? "") == (item.benefitGroupId ?? "")
                ));

        } else {
            const selectedRow = this.tree.hierarchy.filter(item =>
                row.pcn == item.pcn
                && row.benefitPlanId == (item.benefitPlanId ?? "")
                && row.benefitGroupId == (item.benefitGroupId ?? "")
            );
            if (selectedRow.length > 0) {
                selectedRow[0].isSelected = true;
                if (row.settingsStartDate === undefined) row.settingsStartDate = this.tree.decisionTree.startDate;
                if (row.settingsEndDate === undefined) row.settingsEndDate = this.tree.decisionTree.endDate;
            } else {
                var newH = row;
                newH.isSelected = true
                this.tree.hierarchy.push(newH);
                if (row.settingsStartDate === undefined) row.settingsStartDate = this.tree.decisionTree.startDate;
                if (row.settingsEndDate === undefined) row.settingsEndDate = this.tree.decisionTree.endDate;
            }
        }
        this.hierarchyAdded.emit();
        console.log('tree hierarchy update', this.tree.hierarchy);
    }

    checkExistingHierarchy(pcn: string, plan: string, group: string, h: HierarchySettings[]): HierarchySettings | undefined {
        return h!.find((item) => item.pcnDescription === pcn && item.plan === plan && item.group === group);
    }

    clearGroups() {
        this.groups = [];
        const allG = new Group();
        allG.groupName = "All";
        allG.benefitGroupId = '';
        allG.groupReferenceID = -1;
        this.groups.push(allG);
        this.selectedGroup = this.groups.find(x => x.groupReferenceID == -1);
    }

    clearPlans() {
        this.plans = [];
        const allPl = new Plan();
        allPl.planDescription = "All";
        allPl.planReferenceID = -1;
        allPl.benefitPlanId = '';
        this.plans.push(allPl);
        this.selectedPlan = this.plans.find(x => x.planReferenceID == -1);
    }

    clearPCNs() {
        this.pcns = [];
        const allP = new PCN();
        allP.pcnDescription = "All";
        allP.pcn = "-1";
        allP.pcnReferenceID = -1;
        this.pcns.push(allP);
        this.selectedPCN = this.pcns.find(x => x.pcnReferenceID == -1);
    }

    clearAndSetAllInputs() {
        this.clearPCNs();
        this.clearPlans();
        this.clearGroups();
    }
}
