<div class="table-dashboard" (click)="onBlur($event)">


   
    <!--<section [hidden]="!(redCrumbs=='type'   && this.showFilters)" class="dd-filter typeFilter filter-section" [formGroup]="typeFilter">
        TYPE
        <p class="column-3"><mat-checkbox formControlName="pa" (change)="onEffectiveDateCheckboxChange($event)">PA</mat-checkbox></p>
        <p class="column-3"><mat-checkbox formControlName="template" (change)="onEffectiveDateCheckboxChange($event)">Template</mat-checkbox></p>
        <p class="column-3"><mat-checkbox formControlName="firstappeal" (change)="onEffectiveDateCheckboxChange($event)">First Appeal</mat-checkbox></p>
        <p class="column-3"><mat-checkbox formControlName="secondappeal" (change)="onEffectiveDateCheckboxChange($event)">Second Appeal</mat-checkbox></p>
    </section>-->
    

    <div class="column-4 filter-section">
        <mat-form-field appearance="outline">
            <mat-icon matPrefix class="material-icons">search</mat-icon>
            <input matInput class="full-width" (focus)="onFocus()" (keyup)="applyFilter($event)" [(ngModel)]="searchInput" #input>
        </mat-form-field>
    </div>
    <div class="column-4 filter-section" style="position: relative; display: inline;">
        <!--*ngIf="this.showFilters"-->
        <mat-button-toggle-group multiple (change)="toggleChange($event)">
            <mat-button-toggle value="client">
                {{selectedClient.clientID}}
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
            <mat-button-toggle value="status">
                Status
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <div [hidden]="!(redCrumbs=='client')" class="dd-filter clientFilter">
            <mat-radio-group class=" client-radio-group">
                <mat-radio-button *ngFor="let c of clientsList" [value]="c" class="client-radio-button" (click)="selectClient(c)">
                    <span class="column-12">{{c.clientName}}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div [hidden]="!(redCrumbs=='status')" class="dd-filter statusFilter" [formGroup]="statusFilter">
            <!--<p class="column-3"><mat-checkbox formControlName="pending" (change)="onEffectiveDateCheckboxChange($event)">Pending Approval</mat-checkbox></p>-->
            <p class="column-3"><mat-checkbox formControlName="draft" (change)="onEffectiveDateCheckboxChange($event)">Draft</mat-checkbox></p>
            <p class="column-3"><mat-checkbox formControlName="approved" (change)="onEffectiveDateCheckboxChange($event)">Active</mat-checkbox></p>
        </div>
    </div>



    <div class="column-right">
        <button mat-flat-button color="accent" class="createbutton" (click)="openAddModal()">
            <span class="material-icons">add</span>
            Catalog Item
        </button>
        <button mat-button [matMenuTriggerFor]="dashboardmenu">
            <span class="material-icons" style="font-size: 3em;">more_vert</span>
        </button>
        <mat-menu #dashboardmenu="matMenu" class="column-4">
            <button mat-stroked-button color="primary" class="full-width" (click)="openImportModal()">Import</button>
            <button mat-stroked-button color="primary" class="full-width" (click)="export()">Export&nbsp;Table</button>
        </mat-menu>
    </div>
    <mat-divider></mat-divider>
    <br />
    <br />
    <section>
        <div>
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button aria-label="expand row" (click)="(expandedRow = expandedRow === row ? null : row); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedRow !== row">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedRow === row">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="questionSetName"> Catalog </th>
                    <td mat-cell *matCellDef="let row"> <button mat-button color="accent" class="table-row-button" (click)="openEdit(row)">{{row.questionSetName}}</button> </td>
                </ng-container>
                <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="questionSetDescription"> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.questionSetDescription}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="questionSetTypeName"> Type </th>
                    <td mat-cell *matCellDef="let row"> {{row.questionSetTypeName}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="questionSetStatus"> Status </th>
                    <td mat-cell *matCellDef="let row"> <span [class]="'status-pill ' + (row.questionSetStatus | statusClass : row.startDate:row.endDate)">{{row.questionSetStatus | status: row.startDate:row.endDate}}</span> </td>
                </ng-container>
                <ng-container matColumnDef="applications">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="applicationsEnabled"> Applications </th>
                    <td mat-cell *matCellDef="let row"> {{row.applicationsEnabled}} </td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button [matMenuTriggerFor]="rowmenu" (click)="onActionButtonClick($event)">
                            <span class="material-icons" style="font-size: 3em;">more_vert</span>
                        </button>
                        <mat-menu #rowmenu="matMenu" class="column-4">
                            <button mat-button color="primary" (click)="openInteractiveQuestionAnswerModal(row)">Test</button>
                            <button mat-button color="primary" (click)="openCopyModal(row)">Copy</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
                            <div class="column-2"></div>
                            <div class="column-2"><b>Version</b></div>
                            <div class="column-2"><b>Status</b></div>
                            <section *ngFor="let v of row.versionItems | sortByMajorVersion" class="collapse-row">
                                <div class="column-2"></div>
                                <div class="column-2">{{v.majorVersion}}.{{v.minorVersion}}.{{v.questionSetId}}</div>
                                <div class="column-2"><span [class]="'status-pill ' + (v.questionSetStatus | statusClass: v.startDate:v.endDate)">{{v.questionSetStatus | status: v.startDate:v.endDate}}</span></div>
                            </section>

                        </div>
                    </td>
                </ng-container>


                <!--version Column
                <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
                    <td mat-cell *matCellDef="let row"> {{row.majorVersion}}.{{row.minorVersion}}.{{row.questionSetId}} </td>
                </ng-container>
                plan Column
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                        <td mat-cell *matCellDef="let row"> {{row.questionSetStatus}} </td>
                    </ng-container>
                -->


                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedRow === row"
                    (click)="expandedRow = expandedRow === row ? null : row"></tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of questions"></mat-paginator>
        </div>
    </section>


    <div id="interactiveQuestionAnswerModal">
        <div id="modalOperationControls">
            <button class="btn btn-sm btn-outline-primary" onclick='hideReviewQuestionArea()'>X</button>
        </div>
        <div id="interactiveQuestionAnswerSection"></div>
        <div class="simpleright">
            <button class="reset-button" onclick='resetReviewQuestionArea()'>
                Reset
            </button>
            <button class="close-button" onclick='hideReviewQuestionArea()'>
                Close
            </button>
        </div>
        <!--<button onclick='alert(JSON.stringify(getDecisionTreeAnswers())); return false;'>Test Save</button>-->
    </div>
</div>