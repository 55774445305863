import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from './auth.service';
import { HttpBaseInterceptor } from './interceptor/http.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environments';

@NgModule({
  declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [
                    environment.rXPlatformUrl,
                    environment.assetsApiUrl,
                    environment.baseUrl,
                    environment.securityApiUrl,
                    environment.layoutSecurityApiUrl,
                    environment.layoutApiUrl,
                    environment.openid.issuer,
                    environment.openid.tokenUrl,
                    environment.allowedUrlHttp,
                    environment.allowedUrlHttps
                ],
                sendAccessToken: environment.openid.sendAccessToken,
            }
        }),
        TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBaseInterceptor,
      multi: true
    }
  ],
})
export class AuthModule { }



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


