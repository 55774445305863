import { Component, Input, OnInit } from '@angular/core';
import { HierarchyService } from '../../hierarchy/hierarchy.service';
import { Hierarchy } from '../../classes/hierarchy';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { User } from '../../classes/user';
import { AuthService } from '../../shared/auth/auth.service';
import { Client } from '../../classes/client';
import { DecisionTreeRXDM } from '../../classes/decisiontree';
import { SortService } from '../../shared/services/sort.service';
import { TestService } from './test.service';

declare var startQuestionSetReview: any;
declare var setCaseNumber: any;
declare var setDeterminationLevel: any;
declare var getDecisionTreeAnswers: any;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})

export class TestComponent {

    //GET DECISIONTREE STUFF
    user!: User;
    clients: Client[] = [];
    hierarchy: Hierarchy[] | undefined = []; //full hierarchy list
    filteredHierarchy: Hierarchy[] | undefined = []; //filtered hierarchy list
    selectedClientID!: string;
    selectedClient!: Client;
    rxdmTreeList: DecisionTreeRXDM[] = [];

    testForm = this.fb.group({
        clientList: ['', Validators.required],
        pcn: [''],
        plan: [''],
        group: [''],
        type: [''],
        subtype: [''],
        drugQualifierInput: ['GPI-14'],
        drug: [''],
        determinationLevel: ['1', Validators.required]
    });

    testFormLCD = this.fb.group({
        client: ['ACAA', Validators.required],
        CoverageDeterminationID: ['', Validators.required],
        pcn: [''],
        plan: [''],
        group: [''],
        type: [''],
        subtype: [''],
        determinationLevel: ['1'],
        drugQualifierInput: ['DDID'],
        drug: ['208358'],
    });

    caseToDecisionTreeForm = this.fb.group({
        CaseID: ['', Validators.required],
        DTID: ['', Validators.required]
    })

    getDecisionTreeForCaseForm = this.fb.group({
        CaseID: ['', Validators.required],
    })
    
    constructor(public hierarchyService: HierarchyService,
        public testService: TestService,
        /*private modalService: NgbModal,*/
        private authService: AuthService,
        private fb: FormBuilder, private sortService: SortService
    ) {
        this.user = this.authService.getUserLoginInfo();
        this.testForm.controls.clientList.patchValue(this.user.lastSelectedClientID!);
    }

    ngOnInit(): void {

        this.testForm.patchValue({
            clientList: "ASES",
            pcn: "007",
            plan: "ACAA01",
            group: "FOO",
            type: "",
            subtype: "",
            drugQualifierInput: "GPI",
            drug: "90669902803725",
        });
        this.caseToDecisionTreeForm.patchValue({
            CaseID: String(new Date().getDate() * -1),
            DTID: "82"
        });
        this.getDecisionTreeForCaseForm.patchValue({
            CaseID: String(new Date().getDate() * -1)
        });
        this.testFormLCD.patchValue({
            CoverageDeterminationID: String(new Date().getDate() * -1)
        });

        var self = this;
        if (document.getElementById("interactiveQuestionAnswerSection") != null) {
            console.log("Q&A answer bound");
            document.getElementById("interactiveQuestionAnswerSection")!
                .addEventListener("questionAnswered", function (event) {
                    console.log("SAVED CALLED ON TARGET, do some angular thing", event);
                    self.SaveDecisionTreeAnswers();

                });
        }

    }

    onSelectClient() {
        if (this.clients === undefined) {
            console.error("Client List Undefined");
            return;
        }
        this.selectedClient = this.clients.find(x => x.clientID?.toLowerCase() === this.selectedClientID.toLowerCase())!;
        //set the users selected client
        this.authService.setClient(this.selectedClient.clientID!);
        console.log(this.user);
        //this.getHierarchy();
    }
    runTest() {
        if (this.testForm.valid) {
            this.testService.getRXDMDecisionTree(
                this.testForm.value.clientList!, this.testForm.value.pcn ?? ""
                , this.testForm.value.plan ?? "", this.testForm.value.group ?? "", this.testForm.value.type ?? ""
                , this.testForm.value.subtype ?? "", this.testForm.value.drugQualifierInput!, this.testForm.value.drug ?? ""
                , this.testForm.value.determinationLevel ?? "1"
            ).subscribe(resp => {
                if (resp.body) {
                    this.rxdmTreeList = this.testService.mapRXDMDecisionTree(resp);
                    console.log(this.rxdmTreeList);
                    if (this.rxdmTreeList.length >= 1) {
                        this.rxdmTreeList = this.rxdmTreeList?.sort(this.sortService.sortByMultiple(['priority'], 'desc'));
                    }
                }
            });
        }
    }
    SetDTIDInput(id:any) {
        this.caseToDecisionTreeForm.patchValue({
            DTID: id
        });
    }
    SetCaseToDecisionTree() {
        if (this.testForm.valid) {
            let cid = parseInt(this.caseToDecisionTreeForm.value.CaseID ?? "0");
            let dtid = parseInt(this.caseToDecisionTreeForm.value.DTID ?? "0");
            this.testService.setCaseToDecisionTree(
                cid, dtid, this.testForm.value.determinationLevel ?? "1"
            ).subscribe(resp => {
                if (resp.body) {
                    //this.rxdmTreeList = this.testService.mapCaseToDecisionTree(resp);
                    console.log("++++response from request: ", resp.body);
                    var qs = resp.body;
                    this.OpenTest(cid, qs, 1);
                }
            });
        }
    }
    GetDecisionTreeForCase() {
        if (this.testForm.valid) {
            let cid = parseInt(this.getDecisionTreeForCaseForm.value.CaseID ?? "0");
            this.testService.getDecisionTreeForCase(
                cid, this.testForm.value.determinationLevel ?? "1"
            ).subscribe(resp => {
                if (resp.body) {
                    //this.rxdmTreeList = this.testService.mapCaseToDecisionTree(resp);
                    console.log("++++response from request: ", resp.body);
                    var qs = resp.body;
                    this.OpenTest(cid, qs,1 );
                }
            });
        }
    }
    OpenTest(id: any, qs: any, lev: any) {
        setCaseNumber(id);
        setDeterminationLevel(lev)
        startQuestionSetReview("Title", qs);
    }
    SaveDecisionTreeAnswers() {
        var answers = getDecisionTreeAnswers();
        answers.DeterminationLevel = this.testForm.value.determinationLevel ?? "1";
        this.testService.savedecisiontreeresults(
            answers
        ).subscribe(resp => {
            if (resp.body) {
                console.log("++++response from request: ", resp.body);
            }
        });
    }


    runTestLCD() {
        if (this.testForm.valid) {
            this.testService.getLegacyDecisionTree(
                this.testFormLCD.value.client!, this.testFormLCD.value.pcn ?? ""
                , this.testFormLCD.value.plan ?? "", this.testFormLCD.value.group ?? "", this.testFormLCD.value.type ?? ""
                , this.testFormLCD.value.subtype ?? "", this.testFormLCD.value.drugQualifierInput!, this.testFormLCD.value.drug ?? ""
                , this.testFormLCD.value.determinationLevel!, this.testFormLCD.value.CoverageDeterminationID!
            ).subscribe(resp => {
                console.log(resp);
                if (resp.body) {
                    var qs = resp.body;
                    this.OpenTest(this.testFormLCD.value.CoverageDeterminationID!, qs, this.testFormLCD.value.determinationLevel);
                }
            });
        }
    }

    SaveDecisionTreeLCD() {
        var n: any = document.getElementById("DecisionTreeResults");
        var data = n.value;
        var answers = JSON.parse(data);

        this.testService.savelegacydecisiontree(
            answers
        ).subscribe(resp => {
            if (resp.body) {
                console.log("++++response from request: ", resp.body);
            }
        });
    }
}
