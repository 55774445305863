import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';

@Component({
  selector: 'justification-determination',
  templateUrl: './justification-determination.component.html',
  styleUrls: ['./justification-determination.component.scss']
})
export class JustificationDeterminationComponent {
    @Input() justification!: JustificationSettings;
    @Input() p!: any;

    determinationForm: FormGroup;

    constructor(
        private fb: FormBuilder,
    ) {
        console.log('justification determination constructor');
        this.determinationForm = this.fb.group({
            allChecked: [false],
            firstDeterminationChecked: [false],
            firstAppealChecked: [false],
            secondAppealChecked: [false],
            thirdAppealChecked: [false],
        });
    }

    ngOnInit(): void {

    }

    toggleAll() {
        const isChecked = this.determinationForm.get('allChecked')?.value;
        this.determinationForm.patchValue({
            firstDeterminationChecked: isChecked,
            firstAppealChecked: isChecked,
            secondAppealChecked: isChecked,
            thirdAppealChecked: isChecked,
        });
    }
}
