import { Component, Input } from '@angular/core';
import { Question } from '../../classes/questionset';
import { QuestionSetControl } from '../../shared/question-set.control';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

declare var treeHandler: any;

@Component({
    selector: 'app-edit-questions-tree',
    templateUrl: './edit-questions-tree.component.html',
    styleUrls: ['./edit-questions-tree.component.scss']
})
export class EditQuestionsTreeComponent {
    
    @Input() p!: any;
    public scaleIndex: number = 2;
    
    public scales: number[] = [
        .25, .5, .75
        , 1
        , 1.25, 1.5, 1.75, 2
    ];
    public marginoffsets: number[] = [
        10, 15, 20
        , 30
        , 35, 36, 50, 70
    ];

    public scale: number = this.scales[this.scaleIndex];
    public marginoffset: number = 0;
    constructor() {
        
    }
    ngOnInit() {
        treeHandler("treeDisplay");
        //this.calcOffset();
    }
    scaleUp() {
        if (this.scaleIndex < (this.scales.length -1)) {
            this.scale = this.scales[++this.scaleIndex];
            this.marginoffset = this.marginoffsets[this.scaleIndex];
        }
    }
    scaleDown() {
        if (this.scaleIndex > 0) {
            this.scale = this.scales[--this.scaleIndex];
            this.marginoffset = this.marginoffsets[this.scaleIndex];
        }
    }
    
    jumpToQuestion(o: any, id: any) {

        var qsc = this.p.questionSetControls.filter((f: any) => { return f.initialOrder == o });
        qsc[0].jumpToQuestion(id);

    }
}
