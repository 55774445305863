import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DecisionTreeSettings } from '../../../classes/decisiontreesettings';
import { OverrideDrugDetail } from '../../../classes/overrides';

/**
 * @title Chips Autocomplete
 */
@Component({
    selector: 'app-medication-chips',
    templateUrl: './medication-chips.component.html',
    styleUrls: ['./medication-chips.component.scss'],
    
})
export class MedicationChipsComponent {
    @Input() settings: DecisionTreeSettings | undefined;
    @Input() medications: OverrideDrugDetail[] = [];//['MEDLemon'];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    
    medicationCtrl = new FormControl('');
    filteredmedications: Observable<OverrideDrugDetail[]>;
    
    allmedications: OverrideDrugDetail[] = [];//['Apple', 'MEDLemon', 'Lime', 'Orange', 'Strawberry'];
    @ViewChild(MatAutocompleteTrigger)
    autocompleteTrigger!: MatAutocompleteTrigger;
    @ViewChild('medicationInput') medicationInput!: ElementRef<HTMLInputElement>;

    announcer = inject(LiveAnnouncer);

    constructor() {
        this.filteredmedications = this.medicationCtrl.valueChanges.pipe(
            startWith(null),
            map((medication: string | null) => (
                medication ? this._filter(medication) : this.allmedications.slice())
            ),
        );
    }
    ngOnInit() {
        this.allmedications = this.getMedicationOverrideDisplay();
    }

    remove(medication: OverrideDrugDetail): void {
        const index = this.medications.indexOf(medication);
        if (index >= 0) {
            this.medications.splice(index, 1);
            this.announcer.announce(`Removed ${medication}`);
        }
    }

    add(event: MatChipInputEvent): void {
        //debugger;
        const value = (event.value || '').trim();
        // Add our medication
        if (value) {
            var targetMed = this.allmedications.filter(f => f.value == value)[0];
            this.medications.push(targetMed);
            
        }
        // Clear the input value
        event.chipInput!.clear();
        //this.medicationCtrl.setValue(this.medicationCtrl.value);
        this.medicationCtrl.setValue(null);
    }
    selected(event: MatAutocompleteSelectedEvent): void {
        
        //console.log("selected open", event.option);
        var targetMed = this.allmedications.filter(f => f.value == event.option.value)[0];
        this.medications.push(targetMed);

        this.medicationInput.nativeElement.value = '';
        this.medicationCtrl.setValue(null);
        //this.medicationCtrl.setValue(this.medicationCtrl.value);
        //this.autocompleteTrigger.openPanel();
    
        
    }

    private _filter(value: string): OverrideDrugDetail[] {
        console.log("_filter do");
        const filterValue = this.medicationInput.nativeElement.value.toLowerCase();//value.toLowerCase();
        this.allmedications = this.getMedicationOverrideDisplay();
        console.log(this.allmedications);
        return this.allmedications.filter(
            medication =>
                medication.Desc.toLowerCase().includes(filterValue)
                || medication.ID.toLowerCase().includes(filterValue)
        );
    }
    focusInput() {
        var t = this.medicationCtrl.value ?? "";
        this.medicationCtrl.setValue('  ', { emitEvent: true });
        this.medicationCtrl.setValue(t, { emitEvent: true });
    }
    private getMedicationOverrideDisplay(): OverrideDrugDetail[]{
        console.log("Get Meds")
        let details: OverrideDrugDetail[] = [];
        if (this.settings == null) {
        } else {
            this.settings.hierarchy.forEach(feh => {
                feh.drugs.forEach(fed => {
                    var inList = this.medications?.filter(f => {
                        var ev = (f.ID == fed.drugID && f.Qualifier == fed.drugIDQualifier);
                        return ev;
                    });
                    if (inList.length == 0) {
                        details.push(new OverrideDrugDetail(fed));
                    }
                })
            })
        }
        return details;
    }
}
