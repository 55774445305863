import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { JustificationsService } from '../../justifications/justifications.service';

@Directive({
    selector: '[justificationTypeAhead]'
})
export class JustificationTypeAheadDirective implements OnInit {
    @Input('matAutocomplete') autocomplete!: MatAutocomplete;
    @Input('matAutocompleteTrigger') autocompleteTrigger!: MatAutocompleteTrigger;
    @Output() justificationData: EventEmitter<JustificationSettings[]> = new EventEmitter<JustificationSettings[]>();

    justificationSettings: JustificationSettings[] = [];
    @Input() parameters!: {
        clientID: string;
        determinationLevel: string;
        decision: string;
        pcn: string;
        caseType: string;
    };

    constructor(
        private el: ElementRef,
        private http: HttpClient,
        private justificationService: JustificationsService
    ) { }

    ngOnInit() {
        // Handle initialization or setup tasks if needed
        //console.log('type ahead directive', this.parameters);
    }

    @HostListener('input', ['$event'])
    onInput(event: InputEvent) {
        const element = this.el.nativeElement;
        const inputValue = element.value;

        if (inputValue.startsWith('/') && inputValue.length >= 4) {
            this.fetchAutocompleteData(inputValue);
        } else {
            this.autocompleteTrigger?.closePanel();
        }
    }

    fetchAutocompleteData(inputValue: string) {
        //remove trigger character
        inputValue = inputValue.replace(/^\//, '');
        this.justificationService
            .searchJustifications(
                this.parameters.clientID,
                this.parameters.determinationLevel,
                this.parameters.decision,
                inputValue,
                this.parameters.pcn,
                this.parameters.caseType
            )
            .subscribe(resp => {
                if (resp.body) {

                    console.log('directive trigger', resp.body);
                    this.justificationSettings = this.justificationService.mapJustificationSettingsArrayFromResponse(resp.body);
                    this.justificationData.emit(this.justificationSettings);
                }
            });
    }
}
