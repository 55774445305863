import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Client } from '../../classes/client';
import { DecisionTreeSettings, DeterminationSettings, HierarchySettings } from '../../classes/decisiontreesettings';
import { AuthService } from '../auth/auth.service';
import { ClientService } from '../services/client.service';

@Component({
  selector: 'medicare-configuration',
  templateUrl: './medicare-configuration.component.html',
  styleUrls: ['./medicare-configuration.component.scss']
})
export class MedicareConfigurationComponent {
    @Input() tree!: DecisionTreeSettings;
    client!: Client;
    @Input() p!: any;
    @Input() applyToAll: boolean = true;
    @Input() hierarchyToApply?: HierarchySettings;
    @Input() rower: any;

    medicareForm = this.fb.group({
        part: ['', Validators.required],
    });

    constructor(
        private clientService: ClientService,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder
    ) {
        //console.log('medicare config constructor');
    }

    ngOnInit(): void {
        //if you have a specific hierarchy, apply the already selected determination types
        if (this.hierarchyToApply) {
            var h = this.findHierarchyToWorkWith();
            if (h.medicarePart != '') {
                this.medicareForm.controls.part.patchValue(h.medicarePart!);
            }
        }
    }

    findHierarchyToWorkWith(): HierarchySettings {
        var hierarchySettings = this.tree.hierarchy.find(hierarchy => {
            // Customize the conditions based on your matching criteria
            return (
                (hierarchy.hierarchyId === this.hierarchyToApply?.hierarchyId && this.hierarchyToApply?.hierarchyId != undefined) ||
                (hierarchy.pcnReferenceID === this.hierarchyToApply?.pcnReferenceID &&
                    hierarchy.planReferenceID === this.hierarchyToApply?.planReferenceID &&
                    hierarchy.groupReferenceID === this.hierarchyToApply?.groupReferenceID)
            );
        });
        return hierarchySettings!;
    }

    setMedicare() {
        if (this.applyToAll) {
            //Add  dt level
            for (let i = 0; i < this.tree.hierarchy.length; i++) {
                this.tree.hierarchy[i].medicarePart = this.medicareForm.controls['part'].value!;
            }
        } else {
            //apply to single client config
            var h = this.findHierarchyToWorkWith();
            h!.medicarePart = this.medicareForm.controls['part'].value!;
        }

    }
}
