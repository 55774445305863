
<div class="decision">
    <div style="width:300px;pointer-events:none;margin-bottom:10px;">
        <app-decision-selection [action]="action"></app-decision-selection>
    </div>
    <div class="decision-body">
        <mat-form-field color="accent" class="full-width" appearance="outline" style="margin-bottom:10px;">
            <mat-label for="text">Description</mat-label>
            <textarea matInput
                      class="full-width"
                      id="text"
                      required
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAuthsizeMinRows="1"
                      cdkAuthsizeMaxRows="10"
                      [(ngModel)]="action.actionName"
                      name="text"
                      justificationTypeAhead
                      [parameters]="parameters"
                      (justificationData)="onJustificationDataReceived($event)"
                      [matAutocomplete]="auto"></textarea>
            <mat-hint>Type / to search for an existing justification</mat-hint>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateJustification($event.option.value)">
                <mat-option *ngFor="let j of justificationSettings" [value]="j">
                    {{ j.JustificationDescription }} - {{ j.JustificationDecision }} - {{ getCaseDisplayString(j) }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="card">
            <div class="carret">
                <button mat-icon-button *ngIf="showDecisionScenario == true" (click)="showDecisionScenario = false">
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button mat-icon-button *ngIf="showDecisionScenario == false" (click)="showDecisionScenario = true">
                    <mat-icon>arrow_drop_up</mat-icon>
                </button>
            </div>
            <div class="section-title">
                Decision Scenario
            </div>
            <section *ngIf="showDecisionScenario == true">
                <p>
                    Question {{questionSetControl.getTitle()}} {{question.internalOrder}}
                </p>
                <mat-form-field color="accent" class="full-width" appearance="outline">
                    <mat-label for="text">Question</mat-label>
                    <textarea matInput id="text"
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAuthsizeMinRows="1"
                              cdkAuthsizeMaxRows="10"
                              [(ngModel)]="question.text" name="text"></textarea>
                </mat-form-field>

                <section *ngIf="comparison != undefined">
                    &nbsp; &nbsp; &nbsp; &nbsp; {{GetComparisonToString(comparison)}}
                </section>

                <section *ngIf="answerChoice != undefined">
                    <div style="display:flex">
                        <div style="flex: 1 0 0; display:flex; align-items: center; height: 50px;">
                            {{answerChoice.sequence}}&nbsp;
                        </div>
                        <div style="flex: 100 100 auto">
                            <mat-form-field color="accent" class="full-width" appearance="outline"
                                            style="max-height: 50px;">
                                <mat-label>
                                </mat-label>
                                <input matInput type="text" class="full-width" id="choiceText"
                                       style="background-color: #fff; "
                                       required placeholder="Enter answer choice text"
                                       [(ngModel)]="answerChoice.choiceText" name="text" />
                            </mat-form-field>
                        </div>
                    </div>
                </section>
            </section>
        </div>
        <div class="card">
            <div class="carret">
                <!--{{action.actionTextGuid}}-->
                <button mat-icon-button *ngIf="showDecisionJustification == true" (click)="showDecisionJustification = false">
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button mat-icon-button *ngIf="showDecisionJustification == false" (click)="showDecisionJustification = true">
                    <mat-icon>arrow_drop_up</mat-icon>
                </button>
            </div>
            <div class="section-title">
                Decision Justification
            </div>
            <app-edit-question-decision-item-justification [hidden]="showDecisionJustification==false"
                                                           [actionResult]="action"
                                                           [questionSetControl]="questionSetControl"
                                                           [supportedLanguages]="supportedLanguages"></app-edit-question-decision-item-justification>
        </div>
        <!--<div class="card">
            <div class="carret">
                <button mat-icon-button *ngIf="showDecisionOverride == true" (click)="showDecisionOverride = false">
                    <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <button mat-icon-button *ngIf="showDecisionOverride == false" (click)="showDecisionOverride = true">
                    <mat-icon>arrow_drop_up</mat-icon>
                </button>
            </div>
            <div class="section-title">
                Overrides
            </div>
            <app-edit-question-decision-item-override [hidden]="showDecisionOverride==false"
                                                      [actionResult]="action" [questionSetControl]="questionSetControl" [supportedLanguages]="supportedLanguages"></app-edit-question-decision-item-override>
        </div>-->
    </div>
</div>