import { Component, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { QuestionSetRouting } from '../../../shared/question-set.control';


@Component({
    selector: 'app-routing-modal',
    templateUrl: './routing-modal.component.html',
    styleUrls: ['./routing-modal.component.scss']
})
export class RoutingModalComponent {
    @Input() nextId!: string;
    @Input() currentQuestionID!: string;
    @Input() p!: any;
    @Input() showDefault: boolean = false;
    @Input() questionSetRoutingList?: QuestionSetRouting[];
    closeResult = '';
    currrentEndpointLabel: string | undefined;
    routeList: RoutingListItem[] = [];
    routeSearch: string = "";
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<RoutingModalComponent>,
        private dialog: MatDialog,
    ) {
        if (data !== undefined) {
            this.nextId = data.nextId;
            this.p = data.p;
            this.showDefault = data.showDefault;
            this.currentQuestionID = data.currentQuestionID;
            if (data.questionSetRoutingList !== undefined && data.questionSetRoutingList.length > 0) {
                this.questionSetRoutingList = data.questionSetRoutingList;
            }
        }

        this.currrentEndpointLabel = this.nextId;

        if (this.nextId == "Default") {
            this.nextId = this.p.questionSetControl.GetQuestionByID(this.currentQuestionID).defaultNextQuestionID;
        }

        if (this.nextId == "End" || this.nextId == "Final" || this.nextId == null) {
        } else {
            this.currrentEndpointLabel = this.p.questionSetControl.GetQuestionByID(this.nextId)?.text;
        }
        let det = this.p.questionSetControl.GetQuestionDetails();
        //console.log(det);
        var minLevel = det.linkToLevel[this.currentQuestionID];
        //console.log(det.reverseMap);
        var transversed: string[] = this.visit(det.reverseMap, this.currentQuestionID);
        //console.log("ID", this.currentQuestionID.toString());
        //console.log("Transversal", transversed)

        det.sequence.forEach((e: string) => {
            var q = this.p.questionSetControl.GetQuestionByID(e);
            let rli = new RoutingListItem;
            rli.key = e;
            rli.enabled = transversed.indexOf(e.toString()) == -1;
            rli.isCurrentQuestion = this.currentQuestionID == q.id;
            let v = q.text;
            if (v.length > 150) {
                v = v.substring(0, 147) + "...";
            }
            rli.val = v;
            rli.order = (q.internalOrder + this.p.questionSetControl.internalOrderOffset);
            this.routeList.push(rli);
        });
        this.questionSetRoutingList?.forEach(qsri => {
            let rli = new RoutingListItem;
            //if (qsri.qsIndex == this.p.questionSetControl.initialOrder) {
            //    // question in this set
            //} else {
                rli.key = "next-" + qsri.qsGuid ?? "";
                rli.enabled = qsri.qsIndex > this.p.questionSetControl.initialOrder;
                
                    //&& qsri.qsIndex != this.p.questionSetControl.initialOrder;
                let v = qsri.title;
                if (v.length > 150) {
                    v = v.substring(0, 147) + "...";
                }
                rli.val = "Section " + qsri.qsIndex + " of " + this.questionSetRoutingList?.length + ": " + v;
                rli.isSection = true;
                rli.order = qsri.internalOrderOffset + 1;//(q.internalOrder + );
                this.routeList.push(rli);
            //}
        });




        this.routeList = this.routeList.sort((a, b) => ((a.order ?? 909) > (b.order ?? 909)) ? 1 : -1);
    }
    visit(r: any, id: string): string[] {
        var sa: any = [];
        if (id == "Start") { return sa; }
        //console.log("Adding ", id);
        sa.push(id.toString());

        var t: string[] = r[id];
        if (t !== undefined) {
            t.forEach((fe) => {
                //console.log("visiting ", fe)
                var na = this.visit(r, fe);
                na.forEach(nai => sa.push(nai))
            });
        }
        return sa;
    }
    ngOnInit(): void {


    }

    SetNextId(id: string) {
        //console.log("rli key id ",id);
        this.p.SetNextId(id);
        this.p.questionSetControl.focus(this.currentQuestionID);
        this.dialogRef.close("1");
    }
    AddQuestion() {
        let internalOrder = this.p.questionSetControl.GetQuestionByID(this.currentQuestionID).internalOrder;
        var newQuestion = this.p.questionSetControl.addQuestionQuick(internalOrder);
        //console.log(newQuestion);
        newQuestion.defaultNextQuestionID = this.nextId;
        //console.log(newQuestion);
        this.SetNextId(newQuestion.id);
        this.p.questionSetControl.jumpToQuestion(newQuestion.id);
    }
    close() {
        this.dialogRef.close("0");
    }
    filter(s: any) {
        var l: any = document.querySelectorAll(".option-filterable");
        l.forEach((fe: any) => {
            var text = fe.innerText;
            //console.log(text, this.routeSearch, text.includes(this.routeSearch));
            if (this.routeSearch.length > 0 && !text.includes(this.routeSearch)) {
                fe.classList.add('filterhide')
            } else {
                fe.classList.remove('filterhide')

            }
        });
        //console.log(s);
    }
}
class RoutingListItem {
    key!: string;
    val!: string;
    order!: number;
    enabled: boolean = false;
    isSection: boolean = false;
    isCurrentQuestion: boolean = false;
}
