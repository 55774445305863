import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../classes/client';
import { JustificationSettings } from '../../classes/decisiontreesettings';
import { Justification } from '../../classes/justification';
import { PCN } from '../../classes/pcn';
import { ClientService } from '../../shared/services/client.service';

@Component({
  selector: 'justification-detail',
  templateUrl: './justification-detail.component.html',
  styleUrls: ['./justification-detail.component.scss']
})
export class JustificationDetailComponent {
    @Input() justification!: JustificationSettings;
    @Input() clients!: Client[];
    @Input() p!: any;

    detailForm: FormGroup & { [key: string]: FormControl };
    pcns: PCN[] = [];
    selectedPCN?: PCN;
    selectedClient?: Client;

    constructor(
        private fb: FormBuilder,
        private clientService: ClientService,
    ) {
        this.detailForm = this.fb.group({
            desc: ['', Validators.required],
            decision: [''],
            decisionReason: [{ value: '', disabled: true }, Validators.required], // Initially disabled
            client: ['', Validators.required],
            pcn: [{ value: '', disabled: true }, Validators.required], // Initially disabled
        }) as unknown as FormGroup & { [key: string]: FormControl };

       
    }

    ngOnInit() {
        if (this.justification) {
            this.selectedClient = this.clients!.find(x => x.clientID?.toLowerCase() === this.selectedClient?.clientID?.toLowerCase())!;
        }
        console.log('justification client', this.selectedClient);
        //if (this.selectedClient !== null && this.selectedClient !== undefined) {
        //    this.detailForm.patchValue({
        //        client: this.selectedClient
        //    });
        //}
    }

    updateClient() {
        this.populatePCNs();

        // Enable/disable the "Line of Business" dropdown based on the selected client
        const pcnControl = this.detailForm.get('pcn');
        if (this.selectedClient) {
            pcnControl!.enable();
        } else {
            pcnControl!.disable();
            pcnControl!.setValue(''); // Clear the selected PCN
        }
    }

    populatePCNs() {
        this.clientService.getPCNByClient(this.selectedClient?.clientID!).subscribe(resp => {
            if (resp.body) {
                const pcns = this.clientService.mapPCNResponse(resp, false);
                this.pcns = [...this.pcns, ...pcns];
                //console.log(this.pcns);
            }
        });
    }

    updatePCN() {
        
    }

    onDecisionChange() {
        const decisionControl = this.detailForm.get('decision');
        const decisionReasonControl = this.detailForm.get('decisionReason');

        if (decisionControl!.value) {
            // Enable the "Decision Reason" dropdown if a decision is selected
            decisionReasonControl!.enable();
        } else {
            // Disable and reset the "Decision Reason" dropdown if no decision is selected
            decisionReasonControl!.disable();
            decisionReasonControl!.setValue('');
        }
    }
}
