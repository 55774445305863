import { Component, Input } from '@angular/core';
import { CodedReference } from '../../../classes/questionset';

@Component({
  selector: 'app-codedreference-template',
  templateUrl: './codedreference-template.component.html',
  styleUrls: ['./codedreference-template.component.scss']
})
export class CodedreferenceTemplateComponent {
    @Input() codedReference!: CodedReference;
    @Input() p!: any;

    removeReference(cr: CodedReference) {
        if (this.p.codedReference) {
            this.p.codedReference = this.removeObjectByCode(this.p.codedReference!, cr.code!);
        }
    }

    removeObjectByCode(arr: any[], code: string): any[] {
        return arr.filter(obj => obj.code !== code);
    }
}
