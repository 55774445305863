import { Component, EventEmitter, Input, Output, Inject, OnInit} from '@angular/core';
import { DecisionTree } from '../../classes/decisiontree';
import { User } from '../../classes/user';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../shared/auth/auth.service';
import { ApplicationStatus } from '../../classes/questionset';

@Component({
  selector: 'app-test-decisiontree-modal',
  templateUrl: './test-decisiontree-modal.component.html',
  styleUrls: ['./test-decisiontree-modal.component.scss']
})
export class TestDecisiontreeModalComponent implements OnInit {
    decisionTree!: DecisionTree;
    selectedChoice: number | undefined;
    user!: User;
    @Input() applications: ApplicationStatus[] = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) data: any,
        private dialogRef: MatDialogRef<TestDecisiontreeModalComponent>,
        private authService: AuthService
    ) {
        if (data !== undefined) {
            if (data.applications) {
                this.applications = data.applications;
            }
        }
    }

    ngOnInit() {

    }

    runTest() {
        if (this.selectedChoice !== undefined && this.selectedChoice > 0) {
            this.dialogRef.close(this.selectedChoice);
        }
    }

    close() {
        this.dialogRef.close("0");
    }
}
