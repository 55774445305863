<div style="display: inline;">
    <mat-button-toggle name="admin" appearance="standard" (click)="setAdmin()" style="margin-top: 10px!important; padding-top:2px!important; padding-bottom:2px!important; padding-left:12px!important; padding-right:12px!important; margin-bottom: 10px!important;" [checked]="notAdmin">
        Administration
    </mat-button-toggle>
</div>
<div *ngIf="(cases && cases.length > 0)" style="display:inline;margin:10px;">
    <mat-form-field appearance="outline"  color="accent"
                    *ngFor="let caseItem of cases; let i = index" style="margin:10px;" >
        <mat-label>{{ caseItem.caseType }}</mat-label>
        <mat-select multiple [(ngModel)]="selectedValues[i]" (ngModelChange)="onSelectChange()" class="custom-input" [disabled]="notAdmin">
            <mat-option *ngFor="let subType of getCaseSubTypes(caseItem.pcnCaseTypeID!)" [value]="subType.caseSubTypeValue">
                {{ subType.caseSubType }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>




<!--<mat-checkbox (change)="updateSelectedCases($event.checked, caseItem)">{{ caseItem.caseType }}</mat-checkbox>-->