import { ChangeDetectorRef, Component, Input, NgModule } from '@angular/core';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionResult, AnswerChoiceCommonType, Question, QuestionType, QuestionTypeSelect } from '../../classes/questionset';
import { QuestionSetControl, QuestionSetRouting } from '../../shared/question-set.control';
import { QuestionTemplateComponent } from '../../question/question-edit/question-template/question-template.component';
import { RoutingModalComponent } from '../../question/question-edit//routing-modal/routing-modal.component';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionresultModalComponent } from '../../question/question-edit/actionresult-modal/actionresult-modal.component';
import { CodedreferenceModalComponent } from '../../question/codedreference-modal/codedreference-modal.component';
import { EditQuestionDecisionJustificationModalComponent } from '../edit-question-decision-justification-modal/edit-question-decision-justification-modal.component';


@Component({
  selector: 'app-edit-question-answer-choice',
  templateUrl: './edit-question-answer-choice.component.html',
  styleUrls: ['./edit-question-answer-choice.component.scss']
})
export class EditQuestionAnswerChoiceComponent {
    @Input() answerChoice!: AnswerChoiceCommonType;
    @Input() p!: any;
    @Input() questionSetControl!: QuestionSetControl;
    @Input() questionSetRoutingList?: QuestionSetRouting[];
    closeResult: string = "";
    constructor(
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) { }
    ngOnInit(): void {
    }
    GetNextQuestionLabel(): string {
        let label = "";
        let nextId = this.answerChoice.nextQuestionID;
        if (nextId == "Default") {
            nextId = this.p.question.defaultNextQuestionID;
        }
        if (nextId == "" || nextId == null) {
            label = "Select Path";
        }
        else if (nextId.startsWith("next-")) {
            
            var qsGuid = nextId.substr(5);
            var qsr = this.questionSetRoutingList?.filter(f => f.qsGuid == qsGuid);
            if (qsr === undefined || qsr == null || qsr!.length > 1 || qsr!.length == 0) {
                return "SET ERROR " + qsGuid;
            }
            
            //let v = "+" + qsr![0].title;
            //if (v.length > 150) {
            //    v = v.substring(0, 147) + "...";
            //}
            //return (qsr![0].internalOrderOffset + 1) + ": " + v;

            label = "Question " + (qsr![0].internalOrderOffset + 1);
        }
        else if (nextId == "End") {
            label = "Next Question Set";
        }
        if (nextId == "Final") {
            label = "End Decision Tree";

        } else {
            var q = this.questionSetControl.GetQuestionByID(nextId);
            if (q != null) {
                //var v: string;
                //v = q.text;
                //if (v.length > 25) {
                //    v = v.substring(0, 22) + "...";
                //}
                //label = (q.internalOrder + this.questionSetControl.internalOrderOffset) + ": " + v;
                label = "Question " + (q.internalOrder + this.questionSetControl.internalOrderOffset);
            }
        }
        return label;
    }
    jumpToChoiceDecision() {
        //TODO:
        //this.p.p.p.showDecisions();
        alert("GO TO DECISION SECTION PENDING");
    }
    remove() {
        let cid = this.answerChoice.id;
        this.p.removeAnswerChoiceByID(cid);
    }
    openRoutingModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.panelClass = "routing-dialog";
        dialogConfig.data = {
            nextId: this.answerChoice.nextQuestionID,
            currentQuestionID: this.p.question.id,
            p: this,
            questionSetRoutingList: this.questionSetRoutingList,
            showDefault: true
        }
        const dialogRef = this.dialog.open(RoutingModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                /*console.log(data)*/
            }
        );
    }
    openActionResultModal() {
        console.log("action add")
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: this
        }
        const dialogRef = this.dialog.open(ActionresultModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
        //const modalRef = this.modalService.open(ActionresultModalComponent, { size: 'lg', backdrop: 'static' });
        //modalRef.componentInstance.p = this;
        //modalRef.result.then((result) => { this.closeResult = 'Closed'; }, (reason) => { this.closeResult = `Dismissed`; });
    }
    AddActionResult(ar: ActionResult) {
        this.answerChoice.action.push(ar);
    }
    RemoveActionResult(ar: ActionResult) {
        this.answerChoice.action = this.answerChoice.action.filter(f => { return ar != f });
    }
    SetNextId(id: string) {
        this.answerChoice.nextQuestionID = id;
        this.questionSetControl.CalculateQuestionDetail();
        this.cdr.detectChanges();
    }
    openAddDecisionJustificationModal(a: ActionResult) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        //debugger;
        dialogConfig.data = {
            questionSetControl: this.questionSetControl,
            question: this.p.question,
            action: a,
            answerChoice: this.answerChoice,
            comparison: undefined
        }
        const dialogRef = this.dialog.open(EditQuestionDecisionJustificationModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                /*console.log(data)*/
                this.cdr.detectChanges();
            }
        );
    }
    openCodedReferenceModal() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            p: this,
            currentQuestionID: this.p.question.id
        }
        const dialogRef = this.dialog.open(CodedreferenceModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => { console.log(data) }
        );
    }
    isSingleSelect() {
        return !(<QuestionTypeSelect>this.p.question.questionType).isMultiple;
    }
    moveDown() {
        this.p.moveAnswerDown(this.answerChoice.sequence);
    }
}
