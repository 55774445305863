<div class="table-dashboard" (click)="onBlur($event)">


    <section [hidden]="!(redCrumbs=='client' )" class="dd-filter clientFilter filter-section">
        <mat-radio-group class=" client-radio-group">
            <mat-radio-button *ngFor="let c of clientsList" [value]="c" class="client-radio-button" (click)="selectClient(c)">
                <span class="column-12">{{c.clientName}}</span>
            </mat-radio-button>
        </mat-radio-group>
    </section>
    <section [hidden]="!(redCrumbs=='lob'  )" class="dd-filter lobFilter filter-section">
        <mat-checkbox *ngFor="let pcn of pcns" [value]="pcn.pcnReferenceID!.toString()" class="client-radio-button" name="pcnListItem" (click)="getDecisionTree()">
            {{pcn.pcn}} - {{pcn.pcnDescription}}
        </mat-checkbox>
        <!--<mat-radio-group class=" client-radio-group">
        <mat-radio-button *ngFor="let l of pcns" [value]="l" class="client-radio-button" >-->
        <!--(click)="selectLOB(l)"-->
        <!--<span class="column-12">{{l.pcnDescription}}</span>
            </mat-radio-button>
        </mat-radio-group>-->
    </section>
    <section [hidden]="!(redCrumbs=='type'  )" class="dd-filter typeFilter filter-section">
        <mat-checkbox *ngFor="let case of cases" [value]="case.pcnCaseTypeID!.toString()" class="client-radio-button" name="caseListItem" (click)="getDecisionTree()">
            {{case.caseType}}
        </mat-checkbox>
        <!--<mat-radio-group class=" client-radio-group">
        <mat-radio-button *ngFor="let l of pcns" [value]="l" class="client-radio-button" >-->
        <!--(click)="selectLOB(l)"-->
        <!--<span class="column-12">{{l.pcnDescription}}</span>
            </mat-radio-button>
        </mat-radio-group>-->
    </section>
    <section [hidden]="!(redCrumbs=='determinationlevel' )" class="dd-filter determinationlevelFilter filter-section">

        <mat-checkbox value="1" class="client-radio-button" name="determinationListItem" (click)="getDecisionTree()">
            PA
        </mat-checkbox>
        <mat-checkbox value="2" class="client-radio-button" name="determinationListItem" (click)="getDecisionTree()">
            Appeal 1
        </mat-checkbox>
        <mat-checkbox value="3" class="client-radio-button" name="determinationListItem" (click)="getDecisionTree()">
            Appeal 2
        </mat-checkbox>
        <mat-checkbox value="4" class="client-radio-button" name="determinationListItem" (click)="getDecisionTree()">
            Appeal 3
        </mat-checkbox>

    </section>
    <section [hidden]="!(redCrumbs=='effective')" class="dd-filter effectiveDate filter-section" [formGroup]="effectiveDateFilter">
        <mat-checkbox formControlName="past" (change)="onEffectiveDateCheckboxChange($event)">Past Effective</mat-checkbox>
        <mat-checkbox formControlName="current" (change)="onEffectiveDateCheckboxChange($event)">Currently Effective</mat-checkbox>
        <mat-checkbox formControlName="future" (change)="onEffectiveDateCheckboxChange($event)">Future Effective</mat-checkbox>
    </section>
    <section *ngIf="redCrumbs=='status'" class="dd-filter statusFilter filter-section" [formGroup]="statusFilter">
        <p class="column-3"><mat-checkbox formControlName="pending" (change)="onEffectiveDateCheckboxChange($event)">Pending Approval</mat-checkbox></p>
        <p class="column-3"><mat-checkbox formControlName="draft" (change)="onEffectiveDateCheckboxChange($event)">Draft</mat-checkbox></p>
        <p class="column-3"><mat-checkbox formControlName="approved" (change)="onEffectiveDateCheckboxChange($event)">Approved</mat-checkbox></p>
        <!--<p class="column-3"><mat-checkbox formControlName="deleted" (change)="onEffectiveDateCheckboxChange($event)">Deleted</mat-checkbox></p>-->
    </section>
    <div style="display:inline-block;margin-top: 10px; margin-right: 10px;width:300px;">
        <mat-form-field appearance="outline" shape="large" class="search">
            <mat-icon matPrefix class="material-icons">search</mat-icon>
            <input matInput class="full-width" (focus)="onFocus()" (keyup)="applyFilter($event)" [(ngModel)]="searchInput" #input>
        </mat-form-field>

    </div>
    <div style="display:inline">
        <!--ngIf="this.showFilters" class="dropdown"-->
        <mat-button-toggle-group multiple (change)="toggleChange($event)">
            <mat-button-toggle value="client">
                {{selectedClient.clientID}}
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
            <mat-button-toggle value="lob">
                LOB
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
            <mat-button-toggle value="type">
                Type
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
            <mat-button-toggle value="determinationlevel">
                Determination Level
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
            <mat-button-toggle value="status">
                Status
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
            <mat-button-toggle value="effective">
                Effective
                <span class="material-icons">arrow_drop_down</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>


    <div class="column-right" style="margin-top: 10px; margin-right: 10px;">
        <!--<button mat-stroked-button color="warn" class="createbutton" (click)="openAddModal()">
            <span class="material-icons">add</span>
            Create Decision Tree
        </button>-->
        <button mat-flat-button color="accent" class="createbutton" (click)="openGuidedCreateModal()">
            <span class="material-icons">add</span>
            Create Decision Tree
        </button>
        <button mat-button [matMenuTriggerFor]="dashboardmenu">
            <span class="material-icons table-more-icon" style="font-size: 1.75em;">more_vert</span>
        </button>
        <mat-menu #dashboardmenu="matMenu" class="column-4">
            <button mat-stroked-button color="primary" class="full-width" (click)="openImportModal()">Import</button>
            <button mat-stroked-button color="primary" class="full-width" (click)="export()">Export&nbsp;Table</button>
        </mat-menu>
    </div>
    <br /><br />


    <section>
        <div class="">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows (matSortChange)="sortChange($event)">
                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions"
                        style="width:50px;">
                        &nbsp;
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button aria-label="expand row" (click)="(expandedRow = expandedRow === row ? null : row); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedRow !== row">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedRow === row">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <!-- name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="decisionTreeName"> Decision Tree </th>
                    <td mat-cell *matCellDef="let row"> <button mat-button color="accent" class="table-row-button" (click)="openEdit(row)">{{row.decisionTreeName}}</button> </td>
                </ng-container>

                <!--description Column
                <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.decisionTreeDescription}} </td>
                </ng-container>

                <!--version Column
                <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
                    <td mat-cell *matCellDef="let row"> {{row.majorVersion}}.{{row.minorVersion}} </td>
                </ng-container>-->
                <!--status Column
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="decisionTreeStatus"> Status </th>

                    <td mat-cell *matCellDef="let row">
                        <span [class]="'status-pill ' + (row.decisionTreeStatus | statusClass: row.startDate : row.endDate)">
                            {{row.decisionTreeStatus | status: row.startDate : row.endDate}}
                        </span>
                    </td>

                </ng-container>
                    -->
                <!--start Column-->
                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="startDate"> Effective Date </th>
                    <td mat-cell *matCellDef="let row" class="tree-table-text"> {{row.startDate  | date: 'MM/dd/YYYY'}} </td>
                </ng-container>

                <!--end Column-->
                <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate"> Expiration Date </th>

                    <td mat-cell *matCellDef="let row" class="tree-table-text"> {{row.endDate  | date: 'MM/dd/YYYY'}} </td>
                </ng-container>

                <!--copy Column-->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> &nbsp; </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="right-align">
                            <span *ngIf="row.ties == 0" class="material-icons tie-alert" style="font-size: 1.5em;"
                                  #tooltip="matTooltip"
                                  matTooltip="Decision Tree is not linked to a client configuration"
                                  [matTooltipPosition]="position.value!"
                                  matTooltipHideDelay="1000">
                                priority_high
                            </span>
                            <!--<button mat-button [matMenuTriggerFor]="tableactionmenu" (click)="onActionButtonClick($event)">
                                <span class="material-icons table-more-icon" style="font-size: 1.75em;">more_vert</span>
                            </button>
                            <mat-menu #tableactionmenu="matMenu" class="column-3">
                                <button mat-button color="primary" class="full-width" (click)="openGuidedCreateModal(row.decisionTreeID)">Duplicate</button>
                                <button mat-button color="primary" class="full-width" (click)="openExportModal(row)">Export</button>
                            </mat-menu>-->
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
                            <div style="background-color: #f2f4f4; width: 100%; padding: 5px; padding-left: 50px;">
                                <div class="column-2"><b>Version</b></div>
                                <div class="column-2"><b>Status</b></div>
                                <div class="column-2"><b>Effective Date</b></div>
                                <div class="column-2"><b>Expiration Date</b></div>
                            </div>
                            <section *ngFor="let v of row.versionItems | sortByMajorVersion as vi; let idx = index;" class="collapse-row"
                                     style="padding-left: 50px;">

                                <div class="column-2">
                                    <span *ngIf="idx == 0 || vi[idx - 1].majorVersion != v.majorVersion"
                                          style="text-decoration:underline; color:#800088; cursor:pointer;" (click)="openEdit(v)">{{v.majorVersion}}</span>
                                </div>
                                <div class="column-2">
                                    <span [class]="'status-pill ' + (v.decisionTreeStatus | statusClass: v.startDate : v.endDate)">
                                        {{v.decisionTreeStatus | status: v.startDate : v.endDate}}
                                    </span>
                                </div>
                                <div class="column-2">
                                    <span *ngIf="v.decisionTreeStatus != 3">-</span>
                                    <span *ngIf="v.decisionTreeStatus == 3">
                                        {{v.startDate  | date: 'MM/dd/YYYY'}}
                                    </span>
                                </div>
                                <div class="column-2">
                                    <span *ngIf="v.decisionTreeStatus != 3">-</span>
                                    <span *ngIf="v.decisionTreeStatus == 3">
                                        {{v.endDate  | date: 'MM/dd/YYYY'}}
                                    </span>
                                </div>
                                <div class="column-4" style="text-align:right;">
                                    <button mat-button [matMenuTriggerFor]="tableactionmenu" (click)="onActionButtonClick($event)">
                                        <span class="material-icons table-more-icon" style="font-size: 1.75em;">more_vert</span>
                                    </button>
                                    <mat-menu #tableactionmenu="matMenu" class="column-3">
                                        <button mat-button color="primary" class="full-width" (click)="openGuidedCreateModal(v.decisionTreeID)">Duplicate</button>
                                        <button mat-button color="primary" class="full-width" (click)="openExportModal(v)">Export</button>
                                    </mat-menu>
                                </div>

                            </section>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedRow === row"
                    (click)="expandedRow = expandedRow === row ? null : row"></tr>

                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                <!--<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter</td>
                </tr>
            </table>

            <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of trees"></mat-paginator>
        </div>
    </section>


    <!--<td>
        <a class="table-link" (click)="openDetailsModal(h)">View Ties</a>
    </td>
    <td>
        <a class="table-link" (click)="exportTies(h)">Export Ties</a>
    </td>-->
</div>